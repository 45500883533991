import React, { createRef, useCallback, useEffect, useState } from 'react';
import { keyEventInInput } from './helpers';

export function useDebouncedCall<T>(
  callback: (value: T, timer: React.MutableRefObject<number | null>) => void,
  delay = 500
) {
  const timerRef = React.useRef<number | null>(null);

  const fireFunc = React.useMemo(
    () => async (value: T) => {
      if (timerRef.current) window.clearTimeout(timerRef.current);
      timerRef.current = window.setTimeout(async () => callback(value, timerRef), delay);
    },
    [callback, delay]
  );

  return fireFunc;
}

// Note: We listen for key events on our own results scroller and also at the top level of the
// document. If an arrow key, etc. is not handled by the time it gets to the top of the doc,
// (it's "defaultPrevented" = false), and it's not inside an input or textarea, we handle it
// and focus the results area so the focus indicator turns green.
export const useKeyDownListeners = (onAction: (direction: string) => void) => {
  const resultsScrollerRef = React.useRef<HTMLDivElement | null>(null);

  const onKeyDown = (e: React.KeyboardEvent<any> | KeyboardEvent) => {
    if (keyEventInInput(e) || e.defaultPrevented) {
      return;
    }
    if (e.metaKey || e.ctrlKey || e.shiftKey || e.altKey) {
      return; // don't mess with copy-paste
    }

    if (!resultsScrollerRef.current?.contains(e.currentTarget)) {
      resultsScrollerRef.current?.focus();
    }
    e.preventDefault();
    onAction(e.key);
  };

  const onKeyDownRef = React.useRef(onKeyDown);
  onKeyDownRef.current = onKeyDown;

  React.useEffect(() => {
    const cb = (e: React.KeyboardEvent<any> | KeyboardEvent) => {
      onKeyDownRef.current(e);
    };
    document.body.addEventListener('keydown', cb);
    return () => {
      document.body.removeEventListener('keydown', cb);
    };
  }, []);

  return onKeyDown;
};

export const useIntervalInForeground = (fn: () => any, ms: number) => {
  React.useEffect(() => {
    const interval = setInterval(() => {
      if ('hidden' in document && document.hidden) {
        // Tab is hidden, don't waste juice
        return;
      }
      fn();
    }, ms);
    return () => clearInterval(interval);
  }, [fn, ms]);
};

export function useStateWithStorage<T>(key: string, defaultValue: T) {
  const [value, _setValue] = useState<T>(
    (JSON.parse(window.localStorage.getItem(key) || 'null') as T) ?? defaultValue
  );

  const dispatching = React.useRef(false);
  const defaultValueStr = JSON.stringify(defaultValue);
  React.useEffect(() => {
    const listener = () => {
      if (dispatching.current) {
        dispatching.current = false;
      } else {
        _setValue(
          (JSON.parse(window.localStorage.getItem(key) || 'null') as T) ??
            JSON.parse(defaultValueStr)
        );
      }
    };
    document.addEventListener(`local-storage:${key}`, listener);
    return () => {
      document.removeEventListener(`local-storage:${key}`, listener);
    };
  }, [key, defaultValueStr]);

  const setValue = useCallback(
    (v: T) => {
      window.localStorage.setItem(key, JSON.stringify(v));
      dispatching.current = true;
      document.dispatchEvent(new CustomEvent(`local-storage:${key}`));
      _setValue(v);
    },
    [_setValue, key]
  );

  return [value, setValue] as const;
}

export function useScrollToItem(selected: boolean) {
  const ref: any = createRef();

  useEffect(() => {
    if (selected && !!ref) {
      ref.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  }, [ref, selected]);

  return ref;
}
