import { getNonDefault } from '../shared/helpers';
import { useQueryPersistedState } from '../shared/useQueryPersistedState';
import { EMPTY_SEARCH } from './NetworkFiltering';

type NetworkSearchHistory = CoreAPI.MakeOptional<NetworkAPI.SearchHistory, 'id'>;

function encodeNetworkFilters(next: NetworkSearchHistory) {
  const search = JSON.stringify(getNonDefault(next || EMPTY_SEARCH, EMPTY_SEARCH), (key, value) => {
    if (!value) return undefined;
    if (Array.isArray(value) && value.length === 0) return undefined;
    if (typeof value === 'object' && Object.keys(value).length === 0) return undefined;
    return value;
  });
  return { search };
}

function decodeNetworkFilters(qs: { [key: string]: any }): NetworkSearchHistory {
  let search = { ...EMPTY_SEARCH };
  if ('search' in qs) {
    try {
      search = JSON.parse(qs.search);
    } catch (err) {
      // no-op
    }
  }
  return search;
}

export function useNetworkFiltersState() {
  return useQueryPersistedState<NetworkSearchHistory>({
    encode: encodeNetworkFilters,
    decode: decodeNetworkFilters,
  });
}
