import { TooltipPlacement } from 'antd/lib/tooltip';
import React, { useContext, useState } from 'react';
import styled from 'styled-components/macro';
import { OrganizationContext } from '../../contexts/Organization';
import { MinimalExtProfile, sanitizeProfileName } from '../../shared/profile-helpers';
import { InitialsView } from '../../shared/ProfileImage';
import { useResource } from '../../shared/Resource';
import { ScrollableContainer } from '../../shared/ScrollableContainer';
import { Colors } from '../../shared/Theme';
import { InfoButton } from '../Button';
import { WorkAndEducation } from '../CandidateProfile';
import { SeqPopover } from '../Popover';
import { Profile } from '../Profile';
import { Body2, CondensedTextCSS } from '../Text';

interface CandidatePopoverProps<T> {
  candidate: T;
  disabled?: boolean;
  tooltipPlacement?: TooltipPlacement;
  children: React.ReactElement;
}

const { SEQUOIA_BLACK } = Colors.Static;

export const CandidatePopover = <T extends MinimalExtProfile>({
  candidate,
  disabled = false,
  tooltipPlacement = 'rightBottom',
  children,
}: CandidatePopoverProps<T>) => {
  const { me } = useContext(OrganizationContext);
  const [popoverVisible, setPopoverVisible] = useState<boolean>(false);
  const [details] = useResource<CoreAPI.ProfileDetails>(
    `/api/${me.organizationId}/profile-details/${candidate.identifier}`
  );
  const candidateDetails = 'details' in candidate ? candidate.details : details;

  return (
    <SeqPopover
      trigger={'click'}
      contentStyle={{ width: 540 }}
      content={
        <div style={{ width: 500 }}>
          <Profile
            style={{
              flex: 'unset',
              alignItems: 'flex-start',
              paddingBottom: 14,
              borderBottom: `1px solid ${SEQUOIA_BLACK}`,
            }}
            profile={candidate}
            size="large"
          />
          <ScrollableContainer height={350} coverBarOpts={{ whiteBg: true }} style={{ margin: 0 }}>
            {candidateDetails && <WorkAndEducation details={candidateDetails} />}
          </ScrollableContainer>
        </div>
      }
      placement={tooltipPlacement}
      open={popoverVisible}
      onOpenChange={() => setPopoverVisible(v => (disabled ? false : !v))}
      arrow={{ pointAtCenter: true }}
    >
      {children}
    </SeqPopover>
  );
};

export const CandidateInfoButton = React.memo(
  ({
    candidate,
    tooltipPlacement = 'right',
  }: {
    candidate: NetworkAPI.NetworkProfile | ExternalAPI.Profile;
    tooltipPlacement?: TooltipPlacement;
  }) => (
    <CandidatePopover candidate={candidate} tooltipPlacement={tooltipPlacement}>
      <InfoButton onClick={e => e.stopPropagation()} style={{ marginRight: 6 }}>
        <InitialsView name={candidate.name} style={{ width: 24, minWidth: 24, height: 24 }} />
        <ProfileName>{sanitizeProfileName(candidate.name)}</ProfileName>
      </InfoButton>
    </CandidatePopover>
  )
);

const ProfileName = styled(Body2)`
  ${CondensedTextCSS}
`;
