import {
  EditableExcludeRulesMap,
  EditableRuleIds,
  EditorInput,
  Explanatory,
  HeaderRow,
  invalid,
  onChangeWithValidation,
  Rule,
  RuleEditorProps,
  useDescription,
  valid,
  ValidationInfo,
} from './RuleEditorCommon';

export const HasBachelorsDegreeBetweenEditor: React.FC<RuleEditorProps> = ({
  rule,
  onChange,
  style,
  title,
  ruleType,
}) => {
  const replaceParam = (value: string | null, index: number) => {
    if (value === null) {
      onChangeWithValidation(
        { ...rule, parameters: rule.parameters.filter((_, idx) => idx !== index) },
        onChange,
        validate
      );
    } else {
      const replaced = [...rule.parameters];
      replaced[index] = value;
      onChangeWithValidation({ ...rule, parameters: replaced }, onChange, validate);
    }
  };

  const description = useDescription(
    { rule, ruleType },
    {
      include: `Person will receive a boost if they have a bachelor's degree between:`,
      exclude: `Person will be excluded if they have a bachelor's degree between:`,
      invert: `Only include people who received a bachelor's degree between:`,
    }
  );

  return (
    <div style={style}>
      {title && <HeaderRow label={title} onChange={onChange} rule={rule} />}
      <Explanatory>{description}</Explanatory>
      <div style={{ display: 'flex', marginTop: 25, alignItems: 'baseline' }}>
        <div>
          <EditorInput
            style={{ width: 50 }}
            placeholder={'Years'}
            value={rule.parameters[0]}
            onChange={e => replaceParam(e.currentTarget.value, 0)}
          />
        </div>
        <div style={{ width: 40 }}>and</div>
        <div>
          <EditorInput
            style={{ width: 50 }}
            placeholder={'Years'}
            value={rule.parameters[1]}
            onChange={e => replaceParam(e.currentTarget.value, 1)}
          />
        </div>
        <div>years ago</div>
      </div>
    </div>
  );
};

const validate = (rule: Rule): ValidationInfo => {
  if (
    !rule.parameters.length ||
    rule.parameters.length !== 2 ||
    rule.parameters.some(p => isNaN(Number(p))) ||
    Number(rule.parameters[0]) < 0 ||
    Number(rule.parameters[1]) < 0
  ) {
    const ruleLabel = EditableExcludeRulesMap[rule.id as EditableRuleIds]?.label || '';
    return invalid(`${ruleLabel} requires a minimum and maximum number of years to evaluate.`);
  }

  if (Number(rule.parameters[0]) >= Number(rule.parameters[1])) {
    const ruleLabel = EditableExcludeRulesMap[rule.id as EditableRuleIds]?.label || '';
    return invalid(
      `${ruleLabel} the first parameter should be less than second, (like between 3 and 10 years ago).`
    );
  }

  return valid();
};
