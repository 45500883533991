import { Empty } from 'antd';
import moment from 'moment';
import React, { useContext, useEffect, useMemo } from 'react';
import { SEQUOIA_ORG_ID } from '../App';
import { Column, HorizontalDivider } from '../components/Common';
import { Body2Serif, Body3Serif, Body4 } from '../components/Text';
import { NotificationContext } from '../contexts/Notification';
import { OrganizationContext } from '../contexts/Organization';
import { ClickableItem, NotificationDot } from '../shared/Common';
import { InitialsView } from '../shared/ProfileImage';
import { ResourceOps, makeRequest, useResource } from '../shared/Resource';
import { ScrollingContainer } from '../shared/ScrollableContainer';
import { Colors } from '../shared/Theme';
import { EXTERNAL_HOST, pluralize } from '../shared/helpers';
import { RecommendationButton } from './RecommendationButton';

const { SEQUOIA_DARK_GREEN, SEQUOIA_WHITE } = Colors.Static;

export const RecommendedSection: React.FC<{
  isAdmin: boolean;
  organizationId: number;
  contentfulTitles: ConfigJSON.ContentfulTitleData[];
  recommendations: OrganizationAPI.ContentRecommendation[];
  recommendationOps: ResourceOps<
    OrganizationAPI.ContentRecommendation[],
    (OrganizationAPI.ContentRecommendation | undefined)[]
  >;
}> = ({ isAdmin, organizationId, contentfulTitles, recommendations, recommendationOps }) => {
  const { me } = useContext(OrganizationContext);
  const [admins = []] = useResource<ExternalAPI.SequoiaUserSummary[]>(`/api/users/seq-team`);
  const resourcesFeed = useMemo(
    () =>
      [
        ...recommendations
          .filter(rec => (isAdmin ? rec.senderId === me.id : rec.recipientIds.includes(me.id)))
          .map(r => ({
            ...r,
            createdAt: r.emailSentAt || new Date(Date.now() + r.id),
          })),
      ].sort((a, b) => moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf()),
    [recommendations, isAdmin, me.id]
  );

  const {
    notifications: {
      features: { resources },
    },
    actions: { refresh },
  } = useContext(NotificationContext);

  useEffect(() => {
    const interval = setInterval(async () => {
      await refresh();
      await recommendationOps.refresh();
    }, 15000);
    return () => {
      clearInterval(interval);
    };
  }, [recommendationOps, refresh]);

  const onSaveRecommendation = async (recommendation: OrganizationAPI.ContentRecommendation) => {
    const saved = await makeRequest<OrganizationAPI.ContentRecommendation>(
      `/api/content-recommendations/${organizationId}`,
      'POST',
      recommendation
    );
    await recommendationOps.refresh();
    return saved;
  };

  return (
    <>
      {resourcesFeed.length ? (
        <ScrollingContainer style={{ flex: 1 }}>
          {(resourcesFeed || []).map((item, idx) => {
            return (
              <React.Fragment key={idx}>
                {'senderId' in item ? (
                  <TalentTeamRecommendation
                    recommendation={item}
                    admins={admins}
                    notifs={resources}
                  />
                ) : (
                  <div />
                )}
              </React.Fragment>
            );
          })}
        </ScrollingContainer>
      ) : (
        <div style={{ display: 'flex', justifyContent: 'center', margin: '100px auto auto auto' }}>
          <Empty description={`No resources have been recommended ${isAdmin ? 'by' : 'for'} you`} />
        </div>
      )}
      {isAdmin && (
        <div
          style={{ display: 'flex', justifyContent: 'flex-end', marginRight: 24, marginTop: 12 }}
        >
          <RecommendationButton
            options={contentfulTitles || []}
            onSave={onSaveRecommendation}
            currentViewedOrgId={Number(organizationId)}
          />
        </div>
      )}
    </>
  );
};

function useResourceRecommendationItem({
  image,
  headline,
  description,
  content,
  footer,
}: {
  image: React.ReactNode;
  headline: React.ReactNode;
  description?: React.ReactNode;
  content: React.ReactNode;
  footer: React.ReactNode;
}) {
  return (
    <>
      <div style={{ margin: '12px 0', display: 'flex', alignItems: 'flex-start', gap: 12 }}>
        <div style={{ display: 'flex' }}>{image}</div>
        <Column style={{ minWidth: 0 }}>
          <Body2Serif style={{ height: 42, display: 'flex', alignItems: 'center' }}>
            {headline}
          </Body2Serif>
          <Column style={{ background: SEQUOIA_WHITE, padding: 12 }}>
            {description && <Body3Serif>{description}</Body3Serif>}
            {content}
          </Column>
          <Body4 style={{ marginTop: 6, opacity: 0.6 }}>{footer}</Body4>
        </Column>
      </div>
      <HorizontalDivider />
    </>
  );
}

const TalentTeamRecommendation: React.FC<{
  recommendation: OrganizationAPI.ContentRecommendation;
  admins: ExternalAPI.SequoiaUserSummary[];
  notifs: ExternalAPI.Notification<ExternalAPI.FeatureNotification>[];
}> = ({ recommendation, admins, notifs }) => {
  const { me, team } = useContext(OrganizationContext);

  const isAdmin = me.organizationId === SEQUOIA_ORG_ID;
  const senderIsYou = me.id === recommendation.senderId;

  const senderName = senderIsYou
    ? 'You'
    : admins.find(a => a.id === recommendation.senderId)?.name || 'Sequoia Talent Team';

  const recipientNameString = isAdmin
    ? recommendation.recipientIds.map(r => team.find(t => t.id === r)?.name).join(', ')
    : 'you';

  const isNotViewed = (rec: OrganizationAPI.ContentRecommendation) => {
    if (isAdmin) {
      return !rec.viewedAt;
    } else {
      return !!notifs.find(n => n.data.id === rec.id);
    }
  };

  const buildViewedAtURL = (contentIdx: number, contentRecId: number) =>
    `${EXTERNAL_HOST}/api/founder-resources/viewed/${contentRecId}/${contentIdx}/${me.id}`;

  const buildSentToString = (rec: OrganizationAPI.ContentRecommendation) => {
    const recipients = team.filter(m => rec.recipientIds.includes(m.id));
    if (recipients.length === 1) {
      return recipients[0].name;
    } else {
      return `${recipients[0]?.name} and ${pluralize(recipients.length - 1, 'other')}`;
    }
  };

  return useResourceRecommendationItem({
    image: (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {isNotViewed(recommendation) ? (
          <NotificationDot style={{ position: 'absolute', marginLeft: -12 }} />
        ) : (
          ''
        )}
        <InitialsView name={senderName} style={{ width: 42, height: 42, fontSize: 16 }} />
      </div>
    ),
    headline: (
      <>
        <span style={{ color: SEQUOIA_DARK_GREEN, fontWeight: 500, paddingRight: 3 }}>
          {senderName}
        </span>
        recommended some resources for {recipientNameString}.
      </>
    ),
    description: recommendation.comment,
    content: (
      <div style={{ marginTop: 6, marginLeft: 8 }}>
        {recommendation.articles.map(({ url, title }, idx) => (
          <ArticleLink
            key={idx}
            url={
              recommendation.viewedAt || isAdmin ? url : buildViewedAtURL(idx, recommendation.id)
            }
            title={title}
          />
        ))}
      </div>
    ),
    footer: !isAdmin
      ? `Sent on ${moment(recommendation.emailSentAt).format('MMM D')}`
      : !recommendation.viewedAt
        ? `Sent to ${buildSentToString(recommendation)} on ${moment(
            recommendation.emailSentAt
          ).format('MMM D')}`
        : `Viewed on ${moment(recommendation.viewedAt).format('MMM D')}`,
  });
};

const ArticleLink: React.FC<{ url: string; title: string }> = ({ url, title }) => {
  return (
    <ClickableItem>
      <li>
        <a style={{ color: SEQUOIA_DARK_GREEN }} href={url}>
          {title}
        </a>
      </li>
    </ClickableItem>
  );
};
