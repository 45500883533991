// This file is also found in shared-backend

const attemptDecode = (str: string) => {
  try {
    return decodeURIComponent(str);
  } catch (e) {
    console.error(`Unable to decode linkedIn identifier ${str}`);
    return str;
  }
};

export const NON_LI_USER_PREFIX = 'NON-LI-USER-';

export function toLinkedinURL(identOrURL?: string) {
  const ident = toLinkedinID(identOrURL);
  if (!ident) return '';
  if (ident.startsWith(NON_LI_USER_PREFIX)) return '';
  return `https://linkedin.com/in/${ident}`;
}

export function toLinkedinID(_identOrURL?: string) {
  if (!_identOrURL || _identOrURL.trim().length === 0) return '';

  let identOrURL = attemptDecode(_identOrURL);

  if (identOrURL.includes('/talent/people/')) {
    identOrURL = identOrURL.split('/talent/people/').pop()!;
  }

  const cmatch =
    identOrURL && /linkedin\.com\/(?:mwlite\/)?in\/([^/?&#]+)\/?(?:[?&#].*)?/i.exec(identOrURL);
  if (cmatch) {
    identOrURL = cmatch[1];
  }

  // /bengotow => bengotow
  if (identOrURL.startsWith('/')) {
    identOrURL = identOrURL.slice(1);
  }
  // bengotow/ => bengotow
  if (identOrURL.endsWith('/')) {
    identOrURL = identOrURL.slice(0, identOrURL.length - 1);
  }
  // bengotow?q=123 => bengotow
  if (identOrURL.includes('?')) {
    identOrURL = identOrURL.split('?')[0];
  }

  identOrURL = identOrURL.trim();

  // If it's still a URL, we failed
  if (
    identOrURL.startsWith('http://') ||
    identOrURL.startsWith('https://') ||
    identOrURL.includes('/')
  ) {
    return '';
  }
  // If it still contains spaces, we failed
  if (identOrURL.includes(' ')) {
    return '';
  }

  return identOrURL;
}
