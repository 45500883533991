import { LaptopOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import { uniq } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import { SeqButton } from '../components/Button';
import { FlexFillingColCSS, LightTooltip } from '../components/Common';
import { PopoverContainer, SeqPopover } from '../components/Popover';
import { SeqCheckbox } from '../components/SeqCheckbox';
import { H4Mono, Label } from '../components/Text';
import { OpenRejectionPopoverEvent } from '../events';
import { ScrollingContainer } from '../shared/ScrollableContainer';
import { Colors, hexWithOpacity } from '../shared/Theme';
import { ReviewFeedback } from './LeadReviewCarousel';
import { CandidateWithDetails } from './PageLeads';

const { SEQUOIA_GREEN, SEQUOIA_LIGHT_GRAY, SEQUOIA_BLACK } = Colors.Static;
const { TextArea } = Input;

export const REJECT_CATEGORIES = ['Years of Experience', 'Skills', 'Companies', 'Other'];
const COMMENT_CAT = 'Leave a comment';

export const RejectionPopover: React.FunctionComponent<{
  candidate: CandidateWithDetails;
  children: React.ReactElement;
  onReject: (feedback?: ReviewFeedback) => void;
  disabled?: boolean;
}> = ({ candidate, onReject, children, disabled }) => {
  const [popoverVisible, setPopoverVisible] = useState<boolean>(false);

  return (
    <SeqPopover
      open={popoverVisible && !disabled}
      onOpenChange={() => setPopoverVisible(v => !v)}
      content={<RejectionForm candidate={candidate} onReject={onReject} visible={true} />}
      contentStyle={{ padding: 0, maxHeight: 'unset' }}
      placement={'topRight'}
    >
      {children}
    </SeqPopover>
  );
};

export const RejectionForm: React.FC<{
  candidate: CandidateWithDetails;
  onReject: (feedback?: ReviewFeedback) => void;
  visible?: boolean;
  setVisible?: (visible: boolean) => void;
}> = ({ candidate, onReject, visible, setVisible }) => {
  const [feedback, setFeedback] = React.useState<ReviewFeedback>({
    categories: candidate.review?.categories || [],
    comment: candidate.review?.comment || '',
  });

  const feedbackIsValid = useMemo(
    () =>
      feedback.categories?.length &&
      (!feedback.categories.includes(COMMENT_CAT) || feedback.comment?.length),
    [feedback]
  );
  const onRejectAndClose = () => {
    onReject(feedbackIsValid ? feedback : undefined);
    setVisible?.(false);
  };

  const onChange = (isVisible: boolean) => {
    if (!isVisible && feedback.categories?.length) {
      onRejectAndClose();
    } else {
      setVisible?.(isVisible);
    }
  };

  useEffect(() => {
    const onOpenPopoverEvent = async (event: OpenRejectionPopoverEvent) => {
      event.candidateId === candidate.profile.id && onChange(!visible);
    };
    window.addEventListener(OpenRejectionPopoverEvent.EventKey, onOpenPopoverEvent as any);
    return () => {
      window.removeEventListener(OpenRejectionPopoverEvent.EventKey, onOpenPopoverEvent as any);
    };
  });

  const onKeyDown = useCallback(
    (e: React.KeyboardEvent<any> | KeyboardEvent) => {
      if (e.target instanceof HTMLElement && e.target.nodeName === 'TEXTAREA') {
        return; //Comment box typing should not close
      }
      if (e.key === 's') {
        if (feedbackIsValid) {
          onReject(feedback);
        }
      }
    },
    [onReject, feedback, feedbackIsValid]
  );

  useEffect(() => {
    document.body.addEventListener('keydown', onKeyDown);
    return () => {
      document.body.removeEventListener('keydown', onKeyDown);
    };
  }, [onKeyDown]);

  const updateCategories = useCallback((inp: string[]) => {
    setFeedback(feed => ({ ...feed, categories: inp }));
  }, []);

  return (
    <RejectionFormContainer onClick={e => e.stopPropagation()}>
      <div
        style={{
          display: 'flex',
          alignItems: 'baseline',
          justifyContent: 'space-between',
          marginRight: 24,
        }}
      >
        <H4Mono>why not?</H4Mono>
        <LightTooltip
          overlay={
            'You may use the arrow keys and space bar to select checkboxes, press s when finished.'
          }
        >
          <LaptopOutlined style={{ color: Colors.Static.SEQUOIA_LIGHT_TEXT }} />
        </LightTooltip>
      </div>
      <ScrollingContainer style={{ background: 'white' }}>
        <RejectReasonsControl values={feedback.categories || []} onChange={updateCategories} />
        <RejectTextArea
          data-ewfs={'reject-text-area'}
          value={feedback.comment}
          tabIndex={REJECT_INFO.map(r => r.reasons).flat().length}
          onChange={e =>
            setFeedback({
              ...feedback,
              comment: e.currentTarget.value,
              categories: uniq([...(feedback.categories || []), COMMENT_CAT]),
            })
          }
          placeholder={'Anything else? Press enter when finished...'}
          autoSize={{ minRows: 2, maxRows: 2 }}
          onPressEnter={e => {
            e.preventDefault(); //No carriage returns in comments
            e.stopPropagation();
            if (feedbackIsValid) {
              onRejectAndClose();
            }
          }}
        />
      </ScrollingContainer>
      <div style={{ display: 'flex', marginRight: 24, justifyContent: 'flex-end' }}>
        <SeqButton intent="inverted" disabled={!feedbackIsValid} onClick={() => onRejectAndClose()}>
          Save (S)
        </SeqButton>
      </div>
    </RejectionFormContainer>
  );
};

const RejectionFormContainer = styled(PopoverContainer)`
  ${FlexFillingColCSS}
  padding-right: 0;
  max-height: unset;
  gap: 6px;
`;

const RejectTextArea = styled(TextArea)`
  width: calc(100% - 32px);
  overflow-y: scroll;
  border: 1px solid ${SEQUOIA_LIGHT_GRAY};
  outline: none;
  -webkit-box-shadow: none;
  resize: none;
  margin: 8px 16px 0 16px;
  padding: 4px;
  caret-color: ${SEQUOIA_GREEN};
  font-size: 12px;
  &:focus {
    -webkit-box-shadow: none;
    border: 1px solid ${hexWithOpacity(SEQUOIA_GREEN, 0.7)};
  }
`;

const REJECT_INFO: { category: string; reasons: string[] }[] = [
  {
    category: 'Experience',
    reasons: ['Too junior', 'Too senior'],
  },
  {
    category: 'Employment History',
    reasons: [
      `Current job title`,
      `Current company`,
      `Missing skills`,
      'Short stints',
      'Wrong company size',
      `Wrong industry`,
    ],
  },
  {
    category: 'Education',
    reasons: [`Inadequate schools`, `Irrelevant degree`],
  },
  {
    category: 'Other',
    reasons: ['Expensive compensation', 'Location', COMMENT_CAT],
  },
];

const RejectContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 4px;
  padding-bottom: 4px;
`;

const CheckboxLabel = styled.div`
  color: ${SEQUOIA_BLACK};
  font-size: 12px;
  line-height: 15px;
  margin-top: 1px;
`;

export const RejectReasonsControl: React.FC<{
  values: string[];
  onChange: (newVals: string[]) => void;
}> = ({ values, onChange }) => {
  const itemRefs = useRef([] as HTMLLabelElement[]);
  const rejectReasonsFlattened = REJECT_INFO.map(r => r.reasons).flat();
  const [currentFocusIndex, setCurrentFocusIndex] = useState(-1);

  const onKeyDown = useCallback(
    (e: React.KeyboardEvent<any> | KeyboardEvent) => {
      const reasons = REJECT_INFO.map(r => r.reasons).flat(); //Do not use outer scoped copy or add it to dependencies: focus problems
      if (e.key === 'ArrowDown') {
        const newFocus = Math.min(reasons.length - 1, currentFocusIndex + 1);
        setCurrentFocusIndex(newFocus);
        itemRefs.current?.[newFocus].focus();
      }
      if (e.key === 'ArrowUp') {
        const newFocus = Math.max(0, currentFocusIndex - 1);
        setCurrentFocusIndex(newFocus);
        itemRefs.current?.[newFocus].focus();
      }
      if (e.key === ' ') {
        const reason = reasons[currentFocusIndex];
        onChange(values.includes(reason) ? values.filter(v => v !== reason) : [...values, reason]);
      }
    },
    [currentFocusIndex, setCurrentFocusIndex, itemRefs, values, onChange]
  );

  useEffect(() => {
    document.body.addEventListener('keydown', onKeyDown);
    return () => {
      document.body.removeEventListener('keydown', onKeyDown);
    };
  }, [onKeyDown]);

  useEffect(() => {
    setTimeout(() => {
      setCurrentFocusIndex(0);
      itemRefs.current?.[0]?.focus();
    }, 200);
  }, []);

  return (
    <RejectContainer>
      {REJECT_INFO.map(({ category, reasons }) => {
        return (
          <div key={category}>
            <Label>{category}</Label>
            {reasons.map(reason => {
              const indexOfReason = rejectReasonsFlattened.indexOf(reason);
              return (
                <SeqCheckbox
                  ref={element => {
                    if (element) {
                      itemRefs.current[indexOfReason] = element;
                    }
                  }}
                  onBlur={() => setCurrentFocusIndex(-1)}
                  onFocus={() => setCurrentFocusIndex(indexOfReason)}
                  tabIndex={indexOfReason}
                  color={Colors.Static.SEQUOIA_LIGHT_TEXT}
                  key={reason}
                  checked={values.includes(reason)}
                  onChange={() => {
                    setCurrentFocusIndex(rejectReasonsFlattened.indexOf(reason));
                    onChange(
                      values.includes(reason)
                        ? values.filter(v => v !== reason)
                        : [...values, reason]
                    );
                  }}
                >
                  <CheckboxLabel>{reason}</CheckboxLabel>
                </SeqCheckbox>
              );
            })}
          </div>
        );
      })}
    </RejectContainer>
  );
};
