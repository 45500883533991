import { ColumnsType } from 'antd/lib/table';
import React, { useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { isInternal } from '../Helpers';
import { ConfirmArchiveModal } from '../components/CampaignActions';
import { LightTooltip } from '../components/Common';
import { NotificationContext } from '../contexts/Notification';
import { OrganizationContext } from '../contexts/Organization';
import { Dot, NotificationDot } from '../shared/Common';
import { ActionButton } from './Button';
import { FastTable } from './FastTable';

export const CampaignsTable: React.FunctionComponent<{
  campaigns: CampaignAPI.CampaignSummary[];
  onCampaignChanged: () => void;
}> = ({ campaigns, onCampaignChanged }) => {
  const history = useHistory();
  const { me, team } = useContext(OrganizationContext);
  const {
    notifications: {
      features: { campaigns: campaignNotifications },
    },
  } = useContext(NotificationContext);

  const [showArchiveModalForCampaign, setShowArchiveModalForCampaign] = React.useState<
    CampaignAPI.Campaign | undefined
  >();

  const columns: (ColumnsType<CampaignAPI.CampaignSummary>[0] & { visible: boolean })[] = [
    {
      title: 'Name',
      visible: true,
      render: (campaign: CampaignAPI.CampaignSummary) => {
        const hasNotification = campaignNotifications.find(n => n.data.id === campaign.id);
        const campaignSender = [me, ...team].find(t => t.id === campaign.senderId);
        return (
          <LightTooltip
            placement="topLeft"
            overlayStyle={{ maxWidth: 500 }}
            overlay={<span>Sends from {`${campaignSender?.name} <${campaignSender?.email}>`}</span>}
          >
            <div style={{ display: 'flex', alignItems: 'center', margin: '6px 0' }}>
              <NotificationDot
                style={{ marginLeft: -8, visibility: hasNotification ? 'visible' : 'hidden' }}
              />
              {campaign.name}
            </div>
          </LightTooltip>
        );
      },
    },
    {
      title: 'Members',
      visible: true,
      render: (campaign: CampaignAPI.CampaignSummary) => {
        return campaign.countCandidates;
      },
    },
    {
      title: '',
      width: 140,
      visible: isInternal(me),
      render: (campaign: CampaignAPI.CampaignSummary) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Link to={`/${campaign.organizationId}/templates?id=${campaign.id}`}>
              <ActionButton>Edit</ActionButton>
            </Link>
            {!campaign.archivedAt && (
              <>
                <Dot />
                <ActionButton
                  onClick={e => {
                    e.stopPropagation();
                    setShowArchiveModalForCampaign(campaign);
                  }}
                >
                  Archive
                </ActionButton>
              </>
            )}
          </div>
        );
      },
    },
  ].filter(c => c.visible);

  return (
    <>
      <ConfirmArchiveModal
        campaign={showArchiveModalForCampaign}
        onClose={(reload?) => {
          setShowArchiveModalForCampaign(undefined);
          !!reload && onCampaignChanged();
        }}
      />
      <FastTable
        rowProps={c => ({
          style: { opacity: c.archivedAt ? 0.5 : 'inherit', cursor: 'pointer' },
          onClick: () => {
            history.push(linkForCampaign(c));
          },
        })}
        rowKey={'id'}
        rowHeight={'measure'}
        dataSource={campaigns}
        columns={columns}
        emptyProps={{
          imgProps: { style: { maxHeight: '25vh' } },
          description: 'No campaigns',
          style: { padding: 0, marginTop: '7vh' },
        }}
      />
    </>
  );
};

function linkForCampaign(campaign: CampaignAPI.Campaign) {
  return `/${campaign.organizationId}/campaigns/sender/${campaign.senderId}/campaigns/${campaign.id}`;
}
