import { CommentOutlined } from '@ant-design/icons';
import Popover from 'antd/lib/popover';

import moment, { Moment } from 'moment';
import { useContext, useEffect, useState } from 'react';
import { NotificationContext, ProfileNotifs } from '../../contexts/Notification';
import { onProfileUpsert } from '../../Helpers';
import { NotificationDot } from '../../shared/Common';
import { getNameParts } from '../../shared/profile-helpers';
import { Colors } from '../../shared/Theme';
import { ActionButton, SeqButton } from '../Button';
import { InputTitle, SeqTextArea } from '../Input';
import { SeqPopover } from '../Popover';
import { DATE_FORMAT, ScheduledDateInput } from '../ScheduledSendButton';
import { TimeIcon } from '../Svgs';

const { SEQUOIA_PAPER } = Colors.Static;

export function useReminderRow({
  profile,
  reminder,
  onProfileSaved,
}: {
  profile: ExternalAPI.Profile;
  reminder: ProfileNotifs['reminder'];
  onProfileSaved: (profile: ExternalAPI.Profile) => void;
}) {
  const {
    actions: { deleteReminder, markSeen },
  } = useContext(NotificationContext);

  useEffect(() => {
    if (reminder?.isActive) {
      markSeen(reminder.notification);
    }
  }, [reminder, markSeen]);

  const props = {
    icon: (
      <>
        {reminder?.isActive && (
          <NotificationDot style={{ position: 'absolute', marginLeft: -12 }} />
        )}
        <TimeIcon />
      </>
    ),
    title: 'Reminder',
    content: reminder && (
      <>
        {moment(reminder.notification.data.remindAt).format(DATE_FORMAT)}
        <span style={{ marginLeft: 8 }}>
          <Popover
            placement="bottom"
            content={
              <div style={{ fontSize: 12, width: 300 }}>{reminder.notification.data.text}</div>
            }
          >
            <CommentOutlined style={{ fontSize: 14 }} />
          </Popover>
        </span>
      </>
    ),
    actions: reminder ? (
      <ActionButton onClick={() => deleteReminder(reminder.notification)}>Cancel</ActionButton>
    ) : (
      <ReminderPopoverButton profile={profile} onProfileSaved={onProfileSaved} />
    ),
  };

  return props;
}

const ReminderPopoverButton: React.FC<{
  profile: ExternalAPI.Profile;
  onProfileSaved: (profile: ExternalAPI.Profile) => void;
}> = ({ profile, onProfileSaved }) => {
  const {
    actions: { createReminder },
  } = useContext(NotificationContext);
  const initReason = `Contact ${getNameParts(profile.name).first}`;
  const [date, setDate] = useState<Moment>();
  const [reason, setReason] = useState<string>(initReason);
  const [popoverVisible, setPopoverVisible] = useState<boolean>(false);

  const disabled = !date || !reason;

  const setUpReminder = async () => {
    if (disabled) return;

    const createProfileReminder = async (p: { id: number; name: string }) =>
      await createReminder({
        remindAt: date?.toDate(),
        text: reason,
        profile: { id: p.id, name: p.name },
      });

    if (profile.id === -1) {
      await onProfileUpsert(profile, profile, async p => {
        await createProfileReminder(p);
        onProfileSaved(p);
      });
    } else {
      await createProfileReminder(profile);
    }

    setPopoverVisible(false);
  };

  return (
    <SeqPopover
      open={popoverVisible}
      onOpenChange={() => setPopoverVisible(v => !v)}
      title="remind me to..."
      content={
        <>
          An email reminder will be sent to you on the date you select below.
          <SeqTextArea
            autoFocus
            title="What"
            size="small"
            value={reason}
            placeholder={initReason}
            onChange={e => setReason(e.currentTarget.value)}
            style={{ background: SEQUOIA_PAPER }}
          />
          <div>
            <InputTitle size="small" title="When" />
            <ScheduledDateInput
              inputProps={{ autoFocus: false }}
              style={{ marginTop: 4 }}
              onDidChange={(setMessage, date) => {
                setDate(date);
                if (date && date.unix() < moment().unix()) {
                  setMessage('Please enter a future date.');
                }
              }}
            />
          </div>
        </>
      }
      footer={
        <SeqButton intent="primary" disabled={disabled} onClick={setUpReminder}>
          Send
        </SeqButton>
      }
    >
      <ActionButton style={{ marginLeft: 16 }}>schedule</ActionButton>
    </SeqPopover>
  );
};
