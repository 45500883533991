import { ColumnsType } from 'antd/lib/table';
import React, { Suspense, useContext, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { ActionButton, IconButton } from '../components/Button';
import { ConfirmArchiveModal } from '../components/CampaignActions';
import { LightTooltip, PageContainer } from '../components/Common';
import { FastTable } from '../components/FastTable';
import { OrganizationContext } from '../contexts/Organization';
import { useVisibleCampaigns } from '../hooks/useVisibleCampaigns';
import { LeadListStatsModal } from '../leads/LeadListStatsModal';
import { CampaignHeaderRow } from '../pages/PageListCampaigns';
import { ClickableItem, Dot, usePageTitle } from '../shared/Common';
import { EXTERNAL_HOST, pushOrOpen, TALENT_WEB_APP } from '../shared/helpers';
import { PieChartIcon } from '../shared/Svgs';
import { sortUtil, sortUtilNumber } from '../shared/table-helpers';
import { Colors } from '../shared/Theme';

const PageCampaignStatisticsDetails = React.lazy(() => import('./PageCampaignStatisticsDetails'));
const { SEQUOIA_GREEN } = Colors.Static;

export const PageCampaignStatistics: React.FunctionComponent<
  RouteComponentProps<{ campaignId: string }>
> = props => {
  const { campaignId } = props.match.params;
  const { all, actions } = React.useContext(OrganizationContext);
  const [showArchive, setShowArchive] = useState<boolean>(false);

  usePageTitle('Campaign Statistics', false);

  const campaigns = useVisibleCampaigns({
    includeArchived: showArchive,
    includeActiveOrganizationOnly: false,
  });
  const [search, setSearch] = useState<string>('');

  const filteredCampaigns = campaigns.filter(
    c => !search || c.name.toLowerCase().includes(search.toLowerCase())
  );

  if (campaignId) {
    const campaign = campaigns.find(c => c.id === Number(campaignId));
    if (campaign)
      return (
        <Suspense fallback={<div>Loading...</div>}>
          <PageCampaignStatisticsDetails
            campaign={campaign}
            orgName={all.find(o => o.id === campaign.organizationId)!.name}
          />
        </Suspense>
      );
  }

  return (
    <PageContainer style={{ paddingRight: 24 }}>
      <CampaignHeaderRow
        archiveConfig={{ showArchive, onClickShowArchive: () => setShowArchive(prev => !prev) }}
        search={{ value: search, onChange: setSearch }}
      />
      <CampaignsTable
        campaigns={filteredCampaigns}
        onCampaignChanged={actions.refresh}
        hasUnshownCampaigns={campaigns.length > filteredCampaigns.length}
      />
      <ClickableItem style={{ paddingTop: 20, float: 'right' }}>
        <a
          style={{ color: SEQUOIA_GREEN, fontWeight: 500 }}
          target="_blank"
          rel="noopener noreferrer"
          href={`${TALENT_WEB_APP}/system-status/email-delivery?service=sourcing`}
        >
          Sourcing Email Delivery Statistics
        </a>
      </ClickableItem>
    </PageContainer>
  );
};

const CampaignsTable: React.FunctionComponent<{
  campaigns: CampaignAPI.CampaignSummary[];
  onCampaignChanged: () => void;
  hasUnshownCampaigns: boolean;
}> = ({ campaigns, onCampaignChanged, hasUnshownCampaigns }) => {
  const { team, all } = useContext(OrganizationContext);
  const history = useHistory();

  const [showArchiveModalForCampaign, setShowArchiveModalForCampaign] = useState<
    CampaignAPI.Campaign | undefined
  >();
  const [statsModalInfo, setStatsModalInfo] = useState<{
    visible: boolean;
    role?: OrganizationAPI.Role;
  }>({
    visible: false,
  });

  const roleForCampaign = (campaign: CampaignAPI.CampaignSummary) => {
    if (!campaign.roleId) {
      return;
    }
    const org = all.find(org => org.id === campaign.organizationId);
    return org?.roles.find(role => role.id === campaign.roleId);
  };

  const columns: ColumnsType<CampaignAPI.CampaignSummary> = [
    {
      title: 'Name',
      dataIndex: 'name',
      render: (_: string, campaign: CampaignAPI.CampaignSummary) => {
        const sender = team.find(t => t.id === campaign.senderId);
        return (
          <LightTooltip
            overlayStyle={{ maxWidth: 500 }}
            placement={'topLeft'}
            overlay={
              <div>
                Sends from {sender?.name} {`<${sender?.email}>`}
              </div>
            }
          >
            <span>{campaign.name}</span>
          </LightTooltip>
        );
      },
      key: 'name',
      sorter: sortUtil(c => c.name),
    },
    {
      title: 'Company',
      key: 'company',
      render: (campaign: CampaignAPI.CampaignSummary) => {
        return all.find(o => o.id === campaign.organizationId)?.name || '--';
      },
      sorter: sortUtil(c => all.find(o => o.id === c.organizationId)?.name || ''),
    },
    {
      title: 'Role',
      key: 'role',
      render: (campaign: CampaignAPI.CampaignSummary) => {
        const role = roleForCampaign(campaign);
        return role ? (
          <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
            <IconButton
              size="small"
              onClick={e => {
                e.stopPropagation();
                setStatsModalInfo({ visible: true, role });
              }}
            >
              <PieChartIcon />
            </IconButton>
            {role.name}
          </div>
        ) : (
          <>--</>
        );
      },
      sorter: sortUtil(c => roleForCampaign(c)?.name || ''),
    },
    {
      title: 'Contacted',
      width: 95,
      dataIndex: 'countCandidates',
      sorter: sortUtilNumber(c => c.countCandidates),
    },
    {
      title: 'Custom Email',
      width: 115,
      key: 'customEmail',
      sorter: sortUtilNumber(c => c.emailsCustomized.raw),
      render: (campaign: CampaignAPI.CampaignSummary) => {
        return campaign.emailsCustomized.raw;
      },
    },
    {
      title: 'Replied',
      width: 85,
      key: 'replied',
      sorter: sortUtilNumber(c => c.candidatesResponding.raw),
      render: (campaign: CampaignAPI.CampaignSummary) => {
        return campaign.candidatesResponding.raw;
      },
    },

    {
      title: 'Positive',
      width: 95,
      key: 'positive',
      sorter: sortUtilNumber(c => c.candidatesRespondingPositively.raw),
      render: (campaign: CampaignAPI.CampaignSummary) => {
        return campaign.candidatesRespondingPositively.raw;
      },
    },
    {
      title: 'Actions',
      width: 250,
      render: (campaign: CampaignAPI.CampaignSummary) => (
        <div style={{ whiteSpace: 'nowrap', display: 'flex', gap: 2, alignItems: 'center' }}>
          <ActionButton onClick={() => history.push(`/campaign-statistics/${campaign.id}`)}>
            Stats
          </ActionButton>
          <Dot />
          <ActionButton>
            <a
              style={{ color: 'unset' }}
              href={`${EXTERNAL_HOST}/api/sourcing/campaign/${campaign.id}/export`}
            >
              Candidates
            </a>
          </ActionButton>
          {!campaign.archivedAt && (
            <>
              <Dot />
              <ActionButton
                onClick={() => {
                  setShowArchiveModalForCampaign(campaign);
                }}
              >
                <div>Archive</div>
              </ActionButton>
            </>
          )}
        </div>
      ),
    },
  ];

  return (
    <>
      <LeadListStatsModal
        onClose={() => setStatsModalInfo({ visible: false })}
        {...statsModalInfo}
      />
      <ConfirmArchiveModal
        campaign={showArchiveModalForCampaign}
        onClose={(reload?) => {
          setShowArchiveModalForCampaign(undefined);
          !!reload && onCampaignChanged();
        }}
      />
      <FastTable<CampaignAPI.CampaignSummary>
        dataSource={campaigns}
        columns={columns}
        rowHeight={40}
        rowKey={c => c.id}
        rowProps={campaign => {
          return {
            style: {
              opacity: campaign.archivedAt ? 0.5 : 'inherit',
              cursor: 'pointer',
            },
            onClick: e => {
              pushOrOpen(
                e,
                `/${campaign.organizationId}/campaigns/sender/${campaign.senderId}/campaigns/${campaign.id}`,
                history
              );
            },
          };
        }}
        emptyProps={{
          description: hasUnshownCampaigns
            ? 'Remove your search filter or choose "Show Archive" to see campaigns.'
            : 'No campaigns',
        }}
      />
    </>
  );
};
