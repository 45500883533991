import { Modal, notification } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { truncate } from 'lodash';
import moment from 'moment';
import React, { useContext, useState } from 'react';
import styled from 'styled-components/macro';
import { ActionButton, SeqButton } from '../components/Button';
import { Dialog } from '../components/Common';
import { FastTable } from '../components/FastTable';
import { SeqInput, SeqTextArea } from '../components/Input';
import { SeqModal } from '../components/Modal';
import { NotificationButton } from '../components/NotificationButton';
import { TeamMemberSelect } from '../components/selects/TeamMemberSelect';
import { Body3, ExplanatoryText } from '../components/Text';
import { OrganizationContext } from '../contexts/Organization';
import { DATE_FORMAT } from '../management/CompanyMembersTable';
import { Dot } from '../shared/Common';
import { makeRequest, useResource } from '../shared/Resource';

type RoleUpdate = Pick<OrganizationAPI.Role, 'reviewerId' | 'name' | 'messageForLinkedInOutreach'>;

export const RolesTab: React.FunctionComponent = () => {
  return (
    <>
      <ExplanatoryText>
        Create a role for each position your company is looking to fill. Your leads, email campaigns
        and pipeline candidates are linked to roles so it's easy to track your progress toward
        hires.
      </ExplanatoryText>
      <RoleSection />
    </>
  );
};

const RoleSection: React.FunctionComponent = () => {
  const {
    id,
    me,
    team,
    roles,
    actions: { refresh },
  } = useContext(OrganizationContext);
  const [roleInput, setRoleInput] = useState<string>('');
  const [models = []] = useResource<SourcingEngineAPI.EngineModel[]>(
    `/api/sourcing/${id}/engine-models`
  );

  const myTeam = [me, ...team];

  const roleCols: ColumnsType<OrganizationAPI.Role> = [
    {
      title: 'Name',
      render: (r: OrganizationAPI.Role) => (r.deletedBy ? `${r.name} (Archived)` : r.name),
    },
    {
      title: 'Created At',
      render: (r: OrganizationAPI.Role) => moment(r.createdAt).format(DATE_FORMAT),
    },
    {
      title: 'Lead List Reviewer',
      render: (r: OrganizationAPI.Role) => (
        <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
          <NotificationButton roleId={r.id} />
          <span>{myTeam.find(m => m.id === r.reviewerId)?.name}</span>
        </div>
      ),
    },
    {
      title: 'LinkedIn Outreach Message',
      render: (r: OrganizationAPI.Role) => (
        <div style={{ fontSize: 12, maxWidth: 300 }}>
          {truncate(r.messageForLinkedInOutreach, { length: 120 }) || '--'}
        </div>
      ),
    },
    {
      title: 'Actions',
      width: 180,
      render: (r: OrganizationAPI.Role) => (
        <RoleActions
          role={r}
          models={models}
          onSave={(change?: RoleUpdate, unarchive?: boolean) => onSaveRole(r, change, unarchive)}
        />
      ),
    },
  ];

  const onSaveRole = async (
    role: OrganizationAPI.Role,
    change?: RoleUpdate,
    unarchive?: boolean
  ) => {
    const roleUpdate = change || { deletedBy: unarchive ? '' : 'me' };
    if (change?.name) change.name = change.name.trim();

    const response = await makeRequest<OrganizationAPI.Role>(
      `/api/${id}/role/${role.id}`,
      'PUT',
      roleUpdate
    );

    if (response.id) {
      await refresh();
      notification.success({
        message: change
          ? `The role '${change?.name || role.name}' has been updated.`
          : `The role '${role.name}' has been ${!!unarchive ? 'un' : ''}archived.`,
        placement: 'bottomRight',
      });
    }
  };

  const onCreateRole = async () => {
    if (roleInput) {
      const response = await makeRequest<OrganizationAPI.Role>(`/api/${id}/role`, 'POST', {
        name: roleInput,
      });
      if (response.id) {
        notification.success({
          message: `The role '${response.name}' has been created.`,
          placement: 'bottomRight',
        });
        await refresh();
        setRoleInput('');
      }
    }
  };

  return (
    <>
      <InputContainer>
        <SeqInput
          autoFocus
          value={roleInput}
          onChange={e => setRoleInput(e.currentTarget.value)}
          placeholder={`Create a role`}
          onKeyDown={e => {
            if (e.key === 'Enter') onCreateRole();
          }}
        />
        <SeqButton disabled={!roleInput} onClick={onCreateRole}>
          Create role
        </SeqButton>
      </InputContainer>
      <FastTable
        rowHeight={60}
        rowKey={r => r.id || r.name}
        columns={roleCols}
        dataSource={roles.sort(
          (a, b) => (a.deletedAt ? 1 : -1) - (b.deletedAt ? 1 : -1) || a.name.localeCompare(b.name)
        )}
      />
    </>
  );
};

const RoleActions: React.FC<{
  role: OrganizationAPI.Role;
  models: SourcingEngineAPI.EngineModel[];
  onSave: (change?: RoleUpdate, unarchive?: boolean) => void;
}> = ({ role, models, onSave }) => {
  const [editRoleOpen, setEditOpen] = useState(false);

  return role.deletedBy ? (
    <ActionButton onClick={() => onSave(undefined, true)}>
      <img src="/icons/undo.svg" alt="undo arrow" style={{ width: 14, marginRight: 4 }} />
      Unarchive
    </ActionButton>
  ) : (
    <div style={{ display: 'flex', gap: 2, alignItems: 'center' }}>
      {editRoleOpen && (
        <EditRoleModal
          role={role}
          onUpdate={c => {
            onSave(c);
            setEditOpen(false);
          }}
          closeModal={() => setEditOpen(false)}
        />
      )}
      <ActionButton onClick={() => setEditOpen(p => !p)}>Edit</ActionButton>
      <Dot />
      <ArchiveRoleModal role={role} models={models} onSave={onSave} />
    </div>
  );
};

const ArchiveRoleModal: React.FunctionComponent<{
  role: OrganizationAPI.Role;
  models: SourcingEngineAPI.EngineModel[];
  onSave: () => void;
}> = ({ role, models, onSave }) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const model = models.find(
    model => model?.target?.id === role.id && model?.target?.type === 'role'
  );
  const modelTarget = model?.target;

  const ArchiveButton: React.FunctionComponent<{ onClick: () => void }> = ({ onClick }) => (
    <ActionButton onClick={onClick}>Archive</ActionButton>
  );

  return (
    <>
      {modelTarget ? (
        <>
          <ArchiveButton onClick={() => setOpen(!open)} />
          <Modal title={'Archive Role'} open={open} onOk={onSave} onCancel={() => setOpen(!open)}>
            Sequoia is currently sending you {modelTarget.candidateCount} Leads{' '}
            {modelTarget.cadence.value === 1
              ? 'daily'
              : `every ${modelTarget.cadence.value} ${modelTarget.cadence.unit}`}{' '}
            to help fill this role. If you archive it, these leads will stop.
          </Modal>
        </>
      ) : (
        <ArchiveButton
          onClick={() => {
            onSave();
          }}
        />
      )}
    </>
  );
};

const EditRoleModal: React.FC<{
  role: OrganizationAPI.Role;
  onUpdate: (change?: RoleUpdate) => void;
  closeModal: () => void;
}> = ({ role, onUpdate, closeModal }) => {
  const [inputValue, _setInputValue] = useState<RoleUpdate>({
    name: role.name,
    reviewerId: role.reviewerId,
    messageForLinkedInOutreach: role.messageForLinkedInOutreach,
  });
  const [dirty, setDirty] = useState(false);

  const setInputValue = (update: Partial<RoleUpdate>) => {
    _setInputValue(prev => {
      const value = { ...prev, ...update };
      const { name, reviewerId, messageForLinkedInOutreach } = value;

      setDirty(() =>
        reviewerId !== role.reviewerId ||
        (name?.trim().length && name?.trim() !== role.name) ||
        messageForLinkedInOutreach !== role.messageForLinkedInOutreach
          ? true
          : false
      );

      return value;
    });
  };

  const content = (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 16, marginTop: 12 }}>
      <SeqInput
        size="small"
        autoFocus
        title="Name"
        placeholder="Role name"
        value={inputValue.name || ''}
        onChange={e => setInputValue({ name: e.currentTarget.value.toString() })}
      />
      <div style={{ display: 'flex', alignItems: 'center', gap: 12 }}>
        <Body3>Lead List Reviewer</Body3>
        <TeamMemberSelect
          value={inputValue.reviewerId}
          onChange={u => setInputValue({ reviewerId: u.id })}
          style={{ flex: 1 }}
        />
      </div>
      <SeqTextArea
        size="small"
        title="LinkedIn Outreach Message"
        placeholder="Write a default LinkedIn campaign message for this role."
        autoSize
        value={inputValue.messageForLinkedInOutreach}
        onChange={e => {
          const messageForLinkedInOutreach = e.target.value;
          setInputValue({ messageForLinkedInOutreach });
        }}
      />
    </div>
  );

  return (
    <SeqModal open={true} onClose={closeModal}>
      <Dialog
        header="Edit Role"
        content={content}
        footer={
          <div style={{ display: 'flex', justifyContent: 'flex-end', gap: 8 }}>
            <SeqButton
              intent="primary"
              disabled={!dirty}
              onClick={() => {
                onUpdate(inputValue);
              }}
            >
              Update Role
            </SeqButton>
          </div>
        }
        style={{ width: 550 }}
      />
    </SeqModal>
  );
};

export const InputContainer = styled.div`
  display: flex;
  margin-top: 24px;
  margin-bottom: 24px;
  gap: 12px;
`;
