import { List } from 'antd';
import styled from 'styled-components/macro';
import { Colors } from './Theme';

export const StyledList = styled(List)`
  height: 477px;
  width: 200px;
  overflow: scroll;
  border-bottom: 0px;
` as typeof List;

export const StyledListItem = styled(List.Item)<{ selected: boolean; disabled?: boolean }>`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  background: ${({ selected }) => (selected ? Colors.Static.SEQUOIA_GREEN + 22 : 'transparent')};
  white-space: pre;
  overflow: hidden;
  max-width: 100%;
  display: block;
  text-overflow: ellipsis;
  user-select: none;
  color: ${({ disabled }) => (disabled ? Colors.Static.SEQUOIA_LIGHT_TEXT : undefined)};
`;

export const StyledListActions = styled.div`
  margin: 0;
  border: 1px solid ${Colors.Static.LIGHT_GRAY2};
`;
