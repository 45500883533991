import { isEqual } from 'lodash';
import React, { CSSProperties } from 'react';
import styled from 'styled-components/macro';
import { LabelCSS } from '../components/Text';
import { Colors } from '../shared/Theme';
import { FlexFillingColCSS } from './Common';
import { Label } from './Text';

const { SEQUOIA_BLACK, BLACK1ALPHA } = Colors.Static;

//AT end: Interfaces & types can be shared w the internal tabs.tsx even w impl look & feel are completely separate,
//avoiding rn bc of merge conflicts
export type TabPaneChild<T> = React.FunctionComponentElement<TabPaneProps<T>>;

export const TabPane = <T,>(props: TabPaneProps<T>) => {
  return <Container style={props.panelStyle}>{props.children}</Container>;
};

type TabPaneProps<T> = {
  label: React.ReactNode;
  labelStyle?: CSSProperties;
  hoverStyle?: string;
  panelStyle?: CSSProperties;
  overrideKey: T;
  children?: TabPaneChild<T> | TabPaneChild<T>[];
};

type TabProps<T> = {
  active: T;
  onChange: (newActive: T) => void;
  labelsContainerStyle?: CSSProperties;
  style?: CSSProperties;
  children?: TabPaneChild<T> | TabPaneChild<T>[];
};

export const Tabs = <T,>({
  active,
  onChange,
  labelsContainerStyle,
  style,
  children,
}: TabProps<T>): JSX.Element => {
  if (!children) return <div />;

  const tabs = Array.isArray(children) ? children : [children];

  return (
    <Container style={style}>
      <LabelsContainer style={labelsContainerStyle}>
        {tabs
          .filter(t => 'label' in t.props)
          .map(t => {
            const isActive = isEqual(t.props.overrideKey || t.props.label, active);

            return (
              <TabLabel
                style={t.props.labelStyle}
                onClick={() => onChange(t.props.overrideKey)}
                active={isActive}
                hoverStyle={t.props.hoverStyle}
                key={`${t.props.overrideKey}`}
              >
                {t.props.label}
              </TabLabel>
            );
          })}
      </LabelsContainer>
      {tabs.filter(c => (c.props.overrideKey || c.props.label) === active)}
    </Container>
  );
};

const Container = styled.div`
  ${FlexFillingColCSS}
`;

const LabelsContainer = styled(Label)`
  display: flex;
  justify-content: flex-start;
  border-bottom: 1px solid ${SEQUOIA_BLACK};
  padding: 0 24px;
  min-width: 0;
`;

const TabLabel = styled.div<{ active: boolean; hoverStyle?: string }>`
  ${LabelCSS};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  user-select: none;
  transition: background 0.3s;
  -webkit-transition: background 0.3s;
  cursor: pointer;

  ${({ active, hoverStyle }) =>
    active
      ? `border-bottom: 4px ${SEQUOIA_BLACK} solid;`
      : `
      font-weight: 500;
      border-bottom: 4px transparent solid;
      flex-shrink: 10;
      min-width: 0;
      &:hover {
        ${hoverStyle || `border-bottom: 4px ${BLACK1ALPHA} solid;`}
      }`}
`;
