import { default as React } from 'react';
import styled, { css } from 'styled-components/macro';
import { Colors, hexWithOpacity } from '../shared/Theme';

const { SEQUOIA_BLACK, SEQUOIA_LIGHT_TEXT, BLACK8ALPHA, BLACK5ALPHA } = Colors.Static;

/* using MD/Tablet sizes from Seq Primitives Figma */
const BaseText = css<{ textType?: 'supporting' | 'normal' | 'subtle' }>`
  color: ${({ textType }) => {
    if (textType === 'subtle') return BLACK5ALPHA;
    return textType === 'supporting' ? BLACK8ALPHA : SEQUOIA_BLACK;
  }};
  margin: unset;
  padding: unset;
  font-weight: 400;
`;

export const BaseUnica = css`
  ${BaseText}
  font-family: Unica77;
`;

export const BasePitchSans = css`
  ${BaseText}
  font-family: Pitch Sans;
  text-transform: uppercase;
  font-weight: 500;
  word-spacing: -0.2em;
`;

export const BaseRosartCSS = css`
  ${BaseText}
  font-family: Rosart;
`;

export const H2 = styled.h2`
  ${BaseUnica}
  font-size: 36px;
  line-height: 44px;
  user-select: none;
`;

export const H3CSS = css`
  ${BaseUnica}
  font-size: 28px;
  line-height: 36px;
  user-select: none;
`;

export const H3 = styled.h3`
  ${H3CSS}
`;

export const H3MonoCSS = css`
  ${H3CSS}
  ${BasePitchSans}
`;

export const H4CSS = css`
  ${BaseUnica}
  font-size: 24px;
  line-height: 32px;
  user-select: none;
`;

export const H4 = styled.h4`
  ${H4CSS}
`;

export const H4MonoCSS = css`
  ${H4CSS}
  ${BasePitchSans}
            line-height: 28px;
`;

export const H5CSS = css`
  ${BaseUnica}
  font-size: 20px;
  line-height: 28px;
  user-select: none;
`;

export const H5 = styled.h5`
  ${H5CSS}
`;

export const H5MonoCSS = css`
  font-size: 20px;
  line-height: 24px;
  ${BasePitchSans}
`;

/** Use with H2 **/
export const Subtitle2 = styled(H4)`
  margin-top: 8px;
  line-height: 30px;
`;

export const LabelCSS = css`
  ${BasePitchSans}
  font-size: 16px;
  line-height: 20px;
`;

export const Label2CSS = css`
  ${BasePitchSans}
  font-size: 14px;
  line-height: 18px;
`;

export const Label3CSS = css`
  ${BasePitchSans}
  font-size: 12px;
  line-height: 16px;
`;

export const Body1CSS = css`
  ${BaseUnica}
  font-size: 16px;
  line-height: 24px;
`;

export const Body2CSS = css`
  ${BaseUnica}
  font-size: 14px;
  line-height: 20px;
`;

export const Body3CSS = css`
  ${BaseUnica}
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.04em;
`;

export const H1 = styled.h1`
  font-size: 48px;
  line-height: 56px;
  user-select: none;
  ${BaseUnica}
`;

/** Use with H1 **/
export const Subheader1 = styled(H3)`
  margin-top: 12px;
`;

/** Use with H3 **/
export const Subtitle3 = styled(Subtitle2)`
  font-size: 20px;
  line-height: 28px;
  font-weight: 400;
`;

export const H2Mono = styled(H2)`
  ${BasePitchSans}
  letter-spacing: -0.06em;
  word-spacing: -0.3em;
  font-size: 48px;
  font-weight: 400;
  line-height: 60px;
`;

export const H3Mono = styled(H3)`
  ${BasePitchSans};
  letter-spacing: -0.015em;
  word-spacing: -0.1em;
`;

export const H4Mono = styled(H4)`
  ${H4MonoCSS}
`;

export const Label = styled.label`
  ${LabelCSS}
`;

export const Label2 = styled.label`
  ${Label2CSS}
`;

export const Label3 = styled.label`
  font-size: 12px;
  line-height: 16px;
  ${BasePitchSans}
`;

/* Use sans for short paragraphs, serif for long paragraphs */
export const Body1 = styled.div`
  ${Body1CSS}
`;

export const Body2 = styled.div`
  ${Body2CSS}
`;

export const Body3 = styled.div`
  ${Body3CSS}
`;

export const Body4 = styled(Body1)`
  font-size: 11px;
  line-height: 15px;
  letter-spacing: 0.015em;
`;

export const H1Serif = styled(H1)`
  ${BaseRosartCSS}
`;

export const Body1Serif = styled.div`
  font-size: 18px;
  line-height: 28px;
  ${BaseRosartCSS}
`;

export const Body2Serif = styled(Body1)`
  ${BaseRosartCSS}
`;

export const Body3Serif = styled(Body2)`
  ${BaseRosartCSS}
`;

export const Callout1 = styled.blockquote`
  font-size: 32px;
  line-height: 40px;
  ${BaseRosartCSS}
`;

export const Callout2 = styled(Callout1)`
  font-size: 28px;
  line-height: 40px;
`;

export const CondensedTextCSS = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
`;

export const ExplanatoryText = styled(Body2Serif)`
  margin-top: 12px;
  line-height: 140%;
  color: ${SEQUOIA_BLACK};
  max-width: 900px;
`;

export const SubText = styled.div`
  font-size: 11px;
  color: ${SEQUOIA_LIGHT_TEXT};
`;

export const SectionHeader = styled.div`
  ${H4MonoCSS}
  padding: 16px;
  text-align: center;
  border-top: 1px solid ${SEQUOIA_BLACK};
  border-bottom: 1px solid ${SEQUOIA_BLACK};
`;

export const EmailSectionHeader = styled(Label)`
  font-size: 14px;
  margin-top: 16px;
  margin-bottom: 8px;
`;

export const QuoteBlock: React.FC<{ quote: string; author?: string }> = ({ quote, author }) => (
  <div
    style={{
      borderLeft: `3px solid ${hexWithOpacity(Colors.Static.SEQUOIA_LIGHT_TEXT, 0.3)}`,
      paddingLeft: 10,
    }}
  >
    <div>{quote}</div>
    {!!author && (
      <div>
        -<span style={{ fontWeight: 500, fontStyle: 'italic' }}>{author}</span>
      </div>
    )}
  </div>
);
