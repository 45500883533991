import { notification } from 'antd';
import { isEqual } from 'lodash';
import { useContext, useState } from 'react';
import { useHistory } from 'react-router';
import { OrganizationContext } from '../../contexts/Organization';
import { onProfileUpsert, shortenedName } from '../../Helpers';
import { isValidEmail } from '../../shared/helpers';
import { NON_LI_USER_PREFIX, toLinkedinID } from '../../shared/li-utils';
import { makeRequest, useResource } from '../../shared/Resource';
import { ScrollingContainer } from '../../shared/ScrollableContainer';
import { Colors } from '../../shared/Theme';
import { SeqButton } from '../Button';
import { HorizontalDivider, LightTooltip } from '../Common';
import { SeqInput } from '../Input';
import { LeadListAndRoleSelect } from '../LeadListAndRoleSelect';
import { SeqModal } from '../Modal';
import { H3Mono } from '../Text';

const { SEQUOIA_BRIGHT_RED, SEQUOIA_BLACK } = Colors.Static;

export const EXTENSION_MODAL_WIDTH = 376;

export type AddCandidateConfig<T> = AddToPipelineConfig | AddToLeadListConfig<T>;

interface UpsertProfileConfig<T> {
  profile: T;
  onUpsert: (profile: T) => void;
  onDelete?: () => void;
}

type AddToPipelineConfig = {
  onAddedToPipeline: () => void;
  initialRoleId?: number;
};

type AddToLeadListConfig<T> = {
  addToLeadList: (list: LeadsAPI.UpdateLeadList) => Promise<void>;
  profile?: T;
};

interface UpsertCandidateProps<T> {
  config: AddCandidateConfig<T> | UpsertProfileConfig<T>;
  visible: boolean;
  setVisible: (v: boolean) => void;
}

type MinimalProfile = Pick<ExternalAPI.Profile, 'name' | 'identifier'> & {
  links?: ExternalAPI.Profile['links'];
  email?: string;
};

export const UpsertCandidateModal = ({
  config,
  visible,
  setVisible,
}: UpsertCandidateProps<ExternalAPI.Profile>) => {
  const { id: orgId, me } = useContext(OrganizationContext);
  const [errorMessage, setErrorMessage] = useState('');
  const history = useHistory();

  const initProfile =
    'profile' in config && config.profile
      ? {
          name: config.profile.name,
          identifier: config.profile.identifier.startsWith(NON_LI_USER_PREFIX)
            ? ''
            : config.profile.identifier,
          links: config.profile.links,
          email: config.profile.email,
        }
      : { name: '', identifier: '' };

  const [inputValue, setInputValue] = useState<MinimalProfile>(initProfile);
  const [leadLists, { refresh: refreshDraftLeadLists }] = useResource<LeadsAPI.LeadList[]>(
    `/api/${orgId}/lead-lists`,
    { draftsOnly: true }
  );
  const [leadListUpdate, setList] = useState<LeadsAPI.UpsertLeadList>();

  const origin: ExternalAPI.CandidateOrigin = { type: 'founder lead', id: me.id };

  const onDelete = 'onDelete' in config && config.onDelete ? config.onDelete : undefined;

  const onClick = async () => {
    if ('onUpsert' in config) {
      await upsertCandidate();
      setVisible(false);
      return;
    }

    if (inputValue.name.split(' ').length < 2) {
      setErrorMessage('Please enter first + last name.');
      return;
    }

    let identifier;
    if (inputValue.identifier) {
      identifier = toLinkedinID(inputValue.identifier);
      if (!identifier.length) {
        setErrorMessage('Please enter a valid LinkedIn url.');
        return;
      }
    }

    if (inputValue.email) {
      const invalidEmail = !isValidEmail(inputValue.email);
      if (invalidEmail) {
        setErrorMessage('Please enter a valid email.');
        return;
      }
    }

    if ('addToLeadList' in config && leadListUpdate) {
      await config.addToLeadList({ ...leadListUpdate, addProfiles: [inputValue] });
      await refreshDraftLeadLists();
    } else if ('onAddedToPipeline' in config) {
      await onProfileUpsert(
        { id: -1, organizationId: orgId },
        { ...inputValue, identifier, status: 'Pool', roleId: config.initialRoleId },
        config.onAddedToPipeline,
        `${inputValue?.name} was added as a candidate in your pipeline.`
      );
    }
    setInputValue(initProfile);
    setVisible(false);
  };

  const upsertCandidate = async () => {
    if (!('onUpsert' in config)) {
      return;
    }

    const { profile, onUpsert } = config;
    const candidateShortenedName = shortenedName(inputValue.name || profile.name);

    const change: Partial<ExternalAPI.Profile> = {};
    if (initProfile.name !== inputValue.name) change.name = inputValue.name;
    if (initProfile.identifier !== inputValue.identifier) change.identifier = inputValue.identifier;
    if (profile.links.github !== inputValue.links?.github)
      change.links = { github: inputValue.links?.github };
    if (profile.links.twitter !== inputValue.links?.twitter)
      change.links = { ...change.links, twitter: inputValue.links?.twitter };
    if (profile.links.designPortfolio !== inputValue.links?.designPortfolio)
      change.links = { ...change.links, designPortfolio: inputValue.links?.designPortfolio };

    const changeStr = [
      ...Object.keys(change).filter(c => c !== 'links'),
      ...(change.links ? Object.keys(change.links) : []),
    ]
      .join(' and ')
      .replace('github', 'Github link')
      .replace('twitter', 'Twitter link')
      .replace('designPortfolio', 'portfolio link');

    const explanation = `updated ${candidateShortenedName}'s ${changeStr}`;
    change.activities = [{ createdAt: new Date(), user: me.name, explanation }];

    const message = `${candidateShortenedName}'s ${changeStr} ${
      changeStr.includes(' and ') ? 'have' : 'has'
    } been updated.`;

    await onProfileUpsert(profile, change, onUpsert, message);
  };

  const deleteCandidate = async () => {
    if (onDelete && 'profile' in config && config.profile) {
      const { profile } = config;
      const message = `${profile.name}'s profile has been deleted.`;
      await makeRequest(`/api/${orgId}/profile/${profile.id}`, 'DELETE');
      onDelete();
      notification.success({ message, placement: 'bottomRight' });
    }
  };

  const disabled =
    !inputValue.name ||
    !Object.keys(inputValue).length ||
    ('onUpsert' in config && isEqual(inputValue, initProfile)) ||
    ('addToLeadList' in config && (!leadListUpdate || !inputValue.identifier));

  return (
    <SeqModal
      styles={{ body: { maxHeight: '75vh' } }}
      width={EXTENSION_MODAL_WIDTH}
      open={visible}
      onClose={() => setVisible(false)}
    >
      <H3Mono style={{ margin: 24 }}>{onDelete ? 'Edit Candidate' : 'Add Candidate'}</H3Mono>
      <ScrollingContainer style={{ marginLeft: 24 }}>
        {'addToLeadList' in config && (
          <>
            <LeadListAndRoleSelect
              leadList={leadListUpdate}
              leadLists={leadLists}
              setList={list => setList({ ...list, addProfiles: [], origin })}
            />
            <HorizontalDivider style={{ margin: '12px 0', backgroundColor: SEQUOIA_BLACK }} />
          </>
        )}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 16,
          }}
        >
          <SeqInput
            size="small"
            title="Name"
            required
            autoFocus
            placeholder="Candidate's first and last name"
            value={inputValue.name || ''}
            onChange={e => {
              setErrorMessage('');
              setInputValue({ ...inputValue, name: e.currentTarget.value.toString() });
            }}
          />
          <SeqInput
            size="small"
            title="LinkedIn Profile"
            required={'addToLeadList' in config}
            placeholder="Candidate's LinkedIn profile"
            value={inputValue.identifier || ''}
            onChange={e => {
              setErrorMessage('');
              setInputValue({ ...inputValue, identifier: e.currentTarget.value.toString() });
            }}
          />
          <SeqInput
            size="small"
            title="Email"
            placeholder="Candidate's email"
            value={inputValue.email || ''}
            onChange={e => {
              setErrorMessage('');
              setInputValue({ ...inputValue, email: e.currentTarget.value.toString() });
            }}
          />
          <SeqInput
            size="small"
            title="Github Profile"
            placeholder="Candidate's Github profile"
            value={inputValue.links?.github || ''}
            onChange={e => {
              setErrorMessage('');
              setInputValue({
                ...inputValue,
                links: { ...inputValue.links, github: e.currentTarget.value.toString() },
              });
            }}
          />
          <SeqInput
            size="small"
            title="Twitter Profile"
            placeholder="Candidate's Twitter profile"
            value={inputValue.links?.twitter || ''}
            onChange={e => {
              setErrorMessage('');
              setInputValue({
                ...inputValue,
                links: { ...inputValue.links, twitter: e.currentTarget.value.toString() },
              });
            }}
          />
          <SeqInput
            size="small"
            title="Design Portfolio"
            placeholder="Candidate's porfolio link"
            value={inputValue.links?.designPortfolio || ''}
            onChange={e => {
              setErrorMessage('');
              setInputValue({
                ...inputValue,
                links: { ...inputValue.links, designPortfolio: e.currentTarget.value.toString() },
              });
            }}
          />
        </div>
      </ScrollingContainer>
      {errorMessage && (
        <div style={{ color: SEQUOIA_BRIGHT_RED, fontSize: 12, padding: '16px 24px 24px 24px' }}>
          {errorMessage}
        </div>
      )}
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          padding: '16px 24px 24px 24px',
          gap: 6,
        }}
      >
        <SeqButton
          onClick={() => {
            history.push(`${'addToLeadList' in config ? 'leads/' : ''}import-candidates`);
          }}
        >
          Import CSV
        </SeqButton>
        {onDelete && (
          <LightTooltip
            placement="topLeft"
            overlayStyle={{ width: 150 }}
            overlay="Delete this candidate from your pipeline."
          >
            <SeqButton danger onClick={deleteCandidate}>
              Delete
            </SeqButton>
          </LightTooltip>
        )}
        <SeqButton intent="primary" onClick={onClick} disabled={disabled}>
          {onDelete ? 'Update' : 'Add'}
        </SeqButton>
      </div>
    </SeqModal>
  );
};
