import { Popover } from 'antd';
import { pick } from 'lodash';
import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { OrganizationContext } from '../../contexts/Organization';
import { isStoppedBounce, isStoppedNoEmail, onProfileUpsert } from '../../Helpers';
import { useIntervalForProfileUpdate } from '../../hooks/useIntervalForProfileUpdate';
import { MessageContainer } from '../../onboarding/PageOnboardingForm';
import { ClickableItem } from '../../shared/Common';
import { EditIconNoTrail, EnvelopeIcon } from '../../shared/Svgs';
import { Colors } from '../../shared/Theme';
import { ActionButton } from '../Button';
import { EditEmailModal } from '../modals/EditEmailModal';
import { PopoverContent } from '../Popover';
import { EmailConfig } from './ProfileSummary';

const { SEQUOIA_PASTEL_YELLOW, BLACK5ALPHA, SEQUOIA_DARK_GREEN } = Colors.Static;

export function useEmailRow({
  profile,
  onProfileSaved,
  emailConfig,
}: {
  profile: ExternalAPI.Profile;
  onProfileSaved: (p: ExternalAPI.Profile) => void;
  emailConfig: EmailConfig;
}) {
  const { features } = useContext(OrganizationContext);
  const { email } = profile;
  const hasCampaignsFeature = features.includes('campaigns');
  const { emailInfo, onClickSendEmail, hasScheduledEmails } = emailConfig;
  const [emailModalOpen, setEmailModalOpen] = useState(false);
  const loadProfileUpdates = useIntervalForProfileUpdate({
    orgId: profile.organizationId,
    profile,
    onProfileSaved,
  });

  const onRefreshFromEmailPicker = async () => {
    await loadProfileUpdates();
  };

  const stuckCampaigns = (emailInfo?.stoppedCampaigns || []).filter(
    c => isStoppedBounce(c) || isStoppedNoEmail(c)
  );

  return {
    icon: <EnvelopeIcon />,
    title: 'Email',
    content: profile.email ? (
      <ProfileEmail email={profile.email} onEdit={() => setEmailModalOpen(true)} />
    ) : (
      <ActionButton
        style={{ textTransform: 'none' }}
        color={BLACK5ALPHA}
        hoverColor={SEQUOIA_DARK_GREEN}
        onClick={async () => {
          if (profile.id === -1) {
            await onProfileUpsert(profile, pick(profile, ['name', 'identifier']), onProfileSaved);
          }
          setEmailModalOpen(true);
        }}
      >
        Add…
      </ActionButton>
    ),
    actions: (
      <>
        <EditEmailModal
          open={emailModalOpen}
          profile={profile}
          onClose={modifiedProfile => {
            if (!!modifiedProfile) {
              onRefreshFromEmailPicker();
            }
            setEmailModalOpen(false);
          }}
        />
        {email === 'not found' || email === 'pending' || hasScheduledEmails ? (
          <Popover
            content={
              <PopoverContent style={{ width: 140 }}>
                {email === 'pending'
                  ? "We're currently searching for this candidate's email."
                  : email === 'not found'
                  ? `Unable to find an email for this candidate. If you can find one, you may enter it manually using the 'Add' button.`
                  : 'This candidate has already been contacted through a campaign.'}
              </PopoverContent>
            }
            placement="bottomRight"
          >
            <ActionButton disabled>{hasCampaignsFeature ? `Send` : 'More Info'}</ActionButton>
          </Popover>
        ) : hasCampaignsFeature ? (
          <ActionButton onClick={onClickSendEmail} style={{ wordSpacing: '-0.5em' }}>
            Send
          </ActionButton>
        ) : (
          <></>
        )}
      </>
    ),
    extra: stuckCampaigns.length > 0 && (
      <MessageContainer style={{ margin: '0 0 10px 0', background: SEQUOIA_PASTEL_YELLOW }}>
        This candidate is scheduled to receive emails in{' '}
        {stuckCampaigns.map(c => (
          <Link to={`/${profile.organizationId}/campaigns/${c.campaignId}`}>{c.name}</Link>
        ))}{' '}
        but their email address needs to be updated above.
      </MessageContainer>
    ),
  };
}
const ProfileEmail = ({ email, onEdit }: { email: string; onEdit: () => void }) => {
  return (
    <>
      {email === 'not found' ? (
        <Popover
          placement="bottomRight"
          content={
            <PopoverContent style={{ width: 120 }}>
              Search completed and no emails were found
            </PopoverContent>
          }
        >
          <span style={{ opacity: 0.5 }}>No address found</span>
        </Popover>
      ) : email === 'pending' ? (
        <span style={{ opacity: 0.5 }}>Searching for email...</span>
      ) : (
        <>{email}</>
      )}
      <ClickableItem onClick={() => onEdit()}>
        <EditIconNoTrail style={{ width: 18, marginLeft: 6 }} />
      </ClickableItem>
    </>
  );
};
