import { Select } from 'antd';
import React from 'react';
import { LI_INDUSTRY_TAGS } from './DSConstants';
import {
  EditableExcludeRulesMap,
  EditableRuleIds,
  Explanatory,
  HeaderRow,
  invalid,
  NumberInputWithLabel,
  onChangeWithValidation,
  Rule,
  RuleEditorProps,
  useDescription,
  valid,
  ValidationInfo,
} from './RuleEditorCommon';

const { Option } = Select;

export const WorkedAtCoWithLITagsEditor: React.FunctionComponent<RuleEditorProps> = ({
  title,
  rule,
  onChange,
  style,
  ruleType,
}) => {
  const description = useDescription(
    { rule, ruleType },
    {
      include: 'Boost people who have worked at a company with any of these industry tags:',
      exclude: `Exclude people who have worked at a company with any of these industry tags:`,
      invert: `Only include people who have worked at a company with any of these industry tags:`,
    }
  );

  return (
    <div style={style}>
      {title && <HeaderRow label={title} onChange={onChange} rule={rule} />}
      <Explanatory>{description}</Explanatory>
      <NumberInputWithLabel
        label={'Months of work history to search'}
        onChange={newVal =>
          onChangeWithValidation(
            { ...rule, parameters: [`${newVal}`, ...rule.parameters.slice(1)] },
            onChange,
            validate
          )
        }
        value={Number(rule.parameters[0])}
      />
      <div style={{ marginTop: 15, marginBottom: 5 }}>Industries:</div>
      <Select
        style={{ width: '100%' }}
        mode={'multiple'}
        placeholder="Pick some tags"
        value={rule.parameters.slice(1)}
        onChange={(tagsSelected: string[]) => {
          onChangeWithValidation(
            { ...rule, parameters: [rule.parameters[0], ...tagsSelected] },
            onChange,
            validate
          );
        }}
      >
        {LI_INDUSTRY_TAGS.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase())).map(
          tag => (
            <Option value={tag} key={tag}>
              {tag}
            </Option>
          )
        )}
      </Select>
    </div>
  );
};

const validate = (rule: Rule): ValidationInfo => {
  const ruleLabel = EditableExcludeRulesMap[rule.id as EditableRuleIds]?.label || '';
  if (!rule.parameters || rule.parameters.filter(Boolean).length < 2) {
    return invalid(
      `${ruleLabel} requires a number of months to historically search, and least 1 tag.`
    );
  }
  if (isNaN(Number(rule.parameters[0] || Number(rule.parameters[0]) < 0))) {
    return invalid(`${ruleLabel} months to search must be a positive or zero number.`);
  }

  return valid();
};
