import { CloseOutlined } from '@ant-design/icons';
import { Button, Select } from 'antd';
import React from 'react';
import { useResource } from '../Resource';
import {
  EditableExcludeRulesMap,
  EditableRuleIds,
  Explanatory,
  HeaderRow,
  invalid,
  onChangeWithValidation,
  Row,
  Rule,
  RuleEditorProps,
  useDescription,
  valid,
  ValidationInfo,
} from '../rule-editors/RuleEditorCommon';
import { Colors } from '../Theme';

export const InCountriesEditor: React.FC<RuleEditorProps> = ({
  rule,
  onChange,
  style,
  title,
  ruleType,
}) => {
  const [countries] = useResource<string[]>(`/talent/api/location/countries`);
  const onNewLocation = (country: string) => {
    if (!country || rule.parameters.includes(country)) {
      return;
    }

    onChangeWithValidation(
      { ...rule, parameters: [...rule.parameters, country] },
      onChange,
      validate
    );
  };

  const description = useDescription(
    { rule, ruleType },
    {
      include: 'People living in the following countries will be given a boost.',
      exclude: 'People living in the following countries will be excluded.',
      invert: 'People must live in the following countries to be included.',
    }
  );

  return (
    <div style={style}>
      {title && <HeaderRow label={title} onChange={onChange} rule={rule} />}
      <Explanatory>{description}</Explanatory>
      <div style={{ marginTop: 10 }}>
        {rule.parameters.map(location => {
          return (
            <Row style={{ marginBottom: 10 }} key={location}>
              <div style={{ minWidth: 250 }}>{location}</div>
              <Button
                style={{ color: Colors.Static.SEQUOIA_BRIGHT_RED }}
                onClick={() => {
                  onChangeWithValidation(
                    {
                      ...rule,
                      parameters: rule.parameters.filter(p => p !== location),
                    },
                    onChange,
                    validate
                  );
                }}
                type={'link'}
              >
                <CloseOutlined style={{ marginLeft: 20 }} />
              </Button>
            </Row>
          );
        })}
      </div>
      <Select
        placeholder={'Search for country'}
        showSearch
        filterOption
        value={[]}
        onSelect={onNewLocation}
        style={{ width: '50%' }}
        options={(countries || [])
          .sort((a, b) => a.localeCompare(b))
          .filter(c => !rule.parameters.includes(c))
          .map(c => ({ name: c, value: c }))}
      />
    </div>
  );
};

const validate = (rule: Rule): ValidationInfo => {
  const ruleLabel = EditableExcludeRulesMap[rule.id as EditableRuleIds]?.label || '';
  if (!rule.parameters.length) {
    return invalid(`${ruleLabel} requires at least 1 country.`);
  }
  return valid();
};
