import { WarningOutlined } from '@ant-design/icons';
import { Dictionary, groupBy } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { CSSProperties } from 'styled-components';
import { SeqButton } from '../components/Button';
import { LightTooltip } from '../components/Common';
import {
  OptionType,
  Select,
  SelectDivider,
  SelectOption,
  SelectProps,
} from '../components/selects/Select';
import { OrganizationContext } from '../contexts/Organization';
import { getNameParts } from '../shared/profile-helpers';
import { Colors } from '../shared/Theme';
import { CampaignSummaryWithSender, useVisibleCampaigns } from './useVisibleCampaigns';

export interface CampaignConfig {
  campaignSelect: ({
    type,
    onSelect,
    onShouldSelect,
    optionsPlacement,
  }: CampaignSelectProps) => JSX.Element;
  campaign?: CampaignSummaryWithSender;
  campaignType?: LeadsAPI.Settings['campaignType'];
  chooseCampaignMessage?: JSX.Element;
}

interface CampaignSelectProps {
  type?: OptionType;
  style?: CSSProperties;
  onSelect?: (id: number, roleId?: number) => void;
  onShouldSelect?: (id: number) => boolean;
  optionsPlacement?: SelectProps['optionsPlacement'];
  optionsContainerStyle?: CSSProperties;
}

const CAMPAIGN_SELECT_CREATE_ID = -2;
export const CAMPAIGN_SELECT_LINKED_IN_OPTION = -3;

type CampaignConfigOptions = {
  feature: 'leads' | 'pipeline';
  organizationId: number;
  roleId?: number | null;
  campaignId?: number | null;
  linkToNewTab?: boolean;
};

export const useCampaignConfig = (opts: CampaignConfigOptions): CampaignConfig => {
  const { feature, organizationId, roleId, campaignId, linkToNewTab } = opts;
  const { me, roles } = useContext(OrganizationContext);
  const history = useHistory();

  const campaigns = useVisibleCampaigns({ includeArchived: false });
  const campaignsForRole = roleId ? campaigns.filter(c => c.roleId === roleId) : [];
  const campaignsByRole = groupBy(
    [...(campaignsForRole.length ? campaignsForRole : campaigns)],
    'roleId'
  );
  const createCampaignLink = `/${organizationId}/templates?from=${feature}${
    roleId ? `&roleId=${roleId}` : ''
  }`;

  const createCampaignOption: SelectOption = {
    id: CAMPAIGN_SELECT_CREATE_ID,
    name: 'Create New Campaign…',
  };

  const linkedInCampaignOption: SelectOption = {
    id: CAMPAIGN_SELECT_LINKED_IN_OPTION,
    name: 'LinkedIn Outreach',
  };

  const campaignOptions: SelectProps['options'] = [
    createCampaignOption,
    SelectDivider,
    ...(feature === 'leads' ? [linkedInCampaignOption] : []),
    ...getCampaignOptions(campaignsByRole, me.id, roles),
  ];
  const [campaign, setCampaign] = useState<CampaignSummaryWithSender>();

  useEffect(() => {
    if (campaignId && campaignId > 0) {
      setCampaign(campaigns.find(c => c.id === campaignId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignId]);

  const campaignSelect = ({
    style = {},
    type = 'primary',
    optionsPlacement,
    onSelect,
    onShouldSelect,
    optionsContainerStyle,
  }: CampaignSelectProps) => (
    <Select
      type={type}
      style={style}
      selected={campaign?.id || campaignId}
      optionsContainerStyle={optionsContainerStyle}
      options={campaignOptions}
      optionsPlacement={optionsPlacement}
      placeholder="Choose Email Campaign…"
      onSelect={id => {
        if (onShouldSelect && id && !onShouldSelect(id)) {
          return;
        }
        if (id === CAMPAIGN_SELECT_CREATE_ID) {
          if (linkToNewTab) {
            window.open(createCampaignLink, 'blank');
          } else {
            history.push(createCampaignLink);
          }
          return;
        }

        setCampaign(campaigns.find(c => c.id === id));
        if (onSelect && id) onSelect(id, campaign?.roleId);
      }}
    />
  );

  const createCampaignButton = (
    <Link
      to={createCampaignLink}
      {...(linkToNewTab ? { target: '_blank', rel: 'noopener noreferrer' } : {})}
    >
      <SeqButton>Create New Campaign</SeqButton>
    </Link>
  );

  const chooseCampaignMessage = !campaigns.length ? (
    <>
      <div>Currently, there are no campaigns. Create a new campaign to continue.</div>
      <div>{createCampaignButton}</div>
    </>
  ) : !campaign && roleId && !campaignsForRole.length ? (
    <>
      <div>
        Currently, there are no campaigns associated with the role{' '}
        <span style={{ fontWeight: 500 }}>
          {roles.find(r => r.id === roleId)?.name || 'Role Not Found'}
        </span>
        . Choose a campaign with a different role from the dropdown or create a new campaign.
      </div>
      <div>{createCampaignButton}</div>
    </>
  ) : undefined;

  return { campaign, campaignSelect, chooseCampaignMessage };
};

const getCampaignOptions = (
  campaignsByRole: Dictionary<CampaignSummaryWithSender[]>,
  myId: number,
  roles: OrganizationAPI.Role[]
) => {
  const options: SelectProps['options'] = Object.keys(campaignsByRole).map(roleId => {
    const role = roles.find(r => r.id === Number(roleId));
    return {
      name: role?.name || 'Role Not Found',
      options: campaignsByRole[roleId].map(c => {
        const { id, name } = c;
        const noPermission = myId !== c.senderId && !c.sender.allowOthersToSend;
        return {
          id,
          name,
          disabled: noPermission,
          extra: (
            <div
              style={{
                fontSize: 12,
                color: Colors.Static.BLACK7ALPHA,
                lineHeight: '20px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {noPermission ? (
                `${getNameParts(c.sender.name).first} must give permission`
              ) : (
                <>
                  {c.settings.sendAs || c.sender.email}
                  {isUneditedDefaultCampaign(c) && (
                    <LightTooltip overlay="This is a default campaign that has not been customized for your organization. Edit this campaign or create a new one to better suit your needs.">
                      <WarningOutlined
                        style={{
                          color: Colors.Static.SEQUOIA_BRIGHT_ORANGE,
                          fontSize: 18,
                          marginLeft: 12,
                        }}
                      />
                    </LightTooltip>
                  )}
                </>
              )}
            </div>
          ),
        };
      }),
    };
  });

  return options;
};

export const isUneditedDefaultCampaign = (campaign?: CampaignSummaryWithSender) =>
  campaign?.settings.isDefault && campaign.createdAt === campaign.updatedAt;
