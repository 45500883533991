import { CloseOutlined } from '@ant-design/icons';
import { Button, Select, Tooltip } from 'antd';
import React from 'react';
import { useDebouncedCall } from '../hooks';
import { makeRequest } from '../Resource';
import {
  EditableExcludeRulesMap,
  EditableRuleIds,
  invalid,
  onChangeWithValidation,
  Row,
  RuleEditorProps,
  useDescription,
  valid,
  ValidationInfo,
} from '../rule-editors/RuleEditorCommon';
import { Colors } from '../Theme';
import { Explanatory, HeaderRow, Rule } from './RuleEditorCommon';

export async function fetchSchools(schoolString: string): Promise<ExternalAPI.Educator[]> {
  const result = await makeRequest<ExternalAPI.Educator[]>(
    `/talent/api/educators?search=${schoolString}`
  );
  return result || [];
}

interface SchoolParameter {
  name: string;
  lirIds: string[];
}

export const WentToCollegeAtEditor: React.FC<RuleEditorProps> = ({
  rule,
  onChange,
  style,
  title,
  ruleType,
}) => {
  const parameters: SchoolParameter[] = rule.parameters.map(p => JSON.parse(p));
  const [schools, setSchools] = React.useState<ExternalAPI.Educator[]>([]);

  const onNewSchool = (schoolName?: string) => {
    if (!schoolName) return;
    const school = schools?.find(s => s.name === schoolName);

    if (
      !school ||
      (school &&
        parameters.some(
          s =>
            school?.name.toLowerCase() === s.name.toLowerCase() ||
            s.lirIds.some(lirId => school.lirID === lirId)
        ))
    ) {
      return;
    }

    const newSchool: SchoolParameter = { name: school.name, lirIds: [school.lirID] };

    onChangeWithValidation(
      { ...rule, parameters: [...rule.parameters, JSON.stringify(newSchool)] },
      onChange,
      validate
    );
  };

  const onSearch = useDebouncedCall<string>(async schoolName => {
    const result = await fetchSchools(schoolName);
    setSchools(result && result.length ? result : []);
  });

  const desc = useDescription(
    { rule, ruleType },
    {
      include:
        'People who attended any of the following schools will recieve a boost in this model:',
      exclude: 'People who attended any of the following schools will be excluded:',
      invert: `Only people who attended any of these universities will be included:`,
    }
  );

  const deleteParam = (school: SchoolParameter) => {
    const newParams = parameters.filter(p => p.name !== school.name);
    onChangeWithValidation(
      {
        ...rule,
        parameters: newParams.map(p => JSON.stringify(p)),
      },
      onChange,
      validate
    );
  };

  const isAlreadySelected = (school: ExternalAPI.Educator) =>
    parameters.some(param => param.name === school.name || param.lirIds.includes(school.lirID));

  return (
    <div style={style}>
      {title && <HeaderRow label={title} onChange={onChange} rule={rule} />}
      <Explanatory>{desc}</Explanatory>
      <div style={{ marginTop: 10 }}>
        {parameters.map((school, idx) => {
          return (
            <Row style={{ marginBottom: 10 }} key={idx}>
              <div style={{ minWidth: 250 }}>{school.name}</div>
              <Button
                style={{ color: Colors.Static.SEQUOIA_BRIGHT_RED }}
                onClick={() => deleteParam(school)}
                type={'link'}
              >
                <CloseOutlined style={{ marginLeft: 20 }} />
              </Button>
            </Row>
          );
        })}
      </div>
      <Select
        placeholder={'Search for a school'}
        showSearch
        filterOption
        value={[]}
        onSearch={onSearch}
        onBlur={() => setSchools([])}
        onSelect={onNewSchool}
        style={{ width: '50%' }}
      >
        {(schools || []).map((school, idx) => (
          <Select.Option
            key={`${school.name}-${idx}`}
            disabled={isAlreadySelected(school)}
            value={school.name}
          >
            {isAlreadySelected(school) ? (
              <Tooltip
                placement="right"
                title="A school with this name or LinkedIn ID is already included in this rule"
              >
                {school.name}
              </Tooltip>
            ) : (
              <>{school.name}</>
            )}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};

const validate = (rule: Rule): ValidationInfo => {
  const ruleLabel = EditableExcludeRulesMap[rule.id as EditableRuleIds]?.label || '';
  const parameters: SchoolParameter[] = rule.parameters.map(p => JSON.parse(p));
  if (
    !rule.parameters ||
    rule.parameters.filter(Boolean).length < 1 ||
    parameters.some(p => !p.name || !p.lirIds)
  ) {
    return invalid(`${ruleLabel} requires at least one school to be selected.`);
  }
  return valid();
};
