import { useEffect, useState } from 'react';
import { SeqModal } from '../Modal';

const HAS_SEEN_SUNSET_MSG = 'hasSeenSunsetMessage';

export const SunsetModal = () => {
  const [showSunsetMsg, setShowSunsetMessage] = useState(
    !window.localStorage.getItem(`${HAS_SEEN_SUNSET_MSG}-${new Date().toDateString()}`)
  );

  return showSunsetMsg ? (
    <SeqModal
      styles={{
        body: {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          maxHeight: '75vh',
          width: 600,
          padding: '36px 36px 24px 36px',
        },
      }}
      open={true}
      onClose={() => {
        setShowSunsetMessage(false);
      }}
    >
      <SunsetMessage />
    </SeqModal>
  ) : (
    <></>
  );
};

const SunsetMessage = () => {
  useEffect(() => {
    window.localStorage.setItem(`${HAS_SEEN_SUNSET_MSG}-${new Date().toDateString()}`, 'true');
  }, []);

  return (
    <>
      <p>
        The Sequoia Hiring Tools will be sunset on April 1st 2025. If you’re using the Hiring Tools
        chrome extension, we encourage you to download the{' '}
        <a
          href="https://chromewebstore.google.com/detail/sequoia-ampersand-intros/njcanigfaicbdchbjeocfkachhkhpcil"
          target="_blank"
          rel="noreferrer"
        >
          Ampersand chrome extension
        </a>{' '}
        which offers profile insights and proprietary signals, warm intros via the Sequoia network,
        and more.
      </p>
      <p>
        Our team is excited to recommend{' '}
        <a href="https://www.ashbyhq.com/pricing" target="_blank" rel="noreferrer">
          Ashby
        </a>{' '}
        and{' '}
        <a href="https://www.gem.com/startups" target="_blank" rel="noreferrer">
          Gem
        </a>{' '}
        for early stage companies looking to manage their candidate pipelines and messaging. Gem
        offers a steep discount for new organizations with less than 15 people.
      </p>
      <p>
        If you’ve used the Pipeline or Campaigns features, you can export your data before April
        1st, 2025 by visiting <b>{'Settings > Export'}</b>. If you have a Gem account, you can
        alternatively add your Gem API key to transfer your candidates, notes, and email
        interactions to Gem.
      </p>
    </>
  );
};
