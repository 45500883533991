import React from 'react';
import styled from 'styled-components/macro';

export const PageLost: React.FunctionComponent<{ alternateTitle?: string }> = ({
  alternateTitle,
}) => {
  return (
    <PageContainer>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '100%' }}>
        <div style={{ flex: 1 }} />
        <div style={{ textAlign: 'center', margin: 'auto' }}>
          <img
            src="/icons/null-state-green.svg"
            alt="plant, paperwork and coffee"
            style={{ width: '75%' }}
          />
          <div style={{ padding: 24 }}>
            {!!alternateTitle
              ? alternateTitle
              : `Oops! It seems you've gotten lost! Please click one of the links in the sidebar to
            return to your tools.`}
          </div>
        </div>
        <div style={{ flex: 1 }} />
      </div>
    </PageContainer>
  );
};

export const PageContainer = styled.div`
  height: calc(100vh - 48px);
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 24px;
  box-sizing: border-box;
`;
