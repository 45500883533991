import { CommentOutlined } from '@ant-design/icons';
import { isEqual } from 'lodash';
import { default as React, useState } from 'react';
import { onProfileUpsert, shortenedName } from '../../Helpers';
import { buildEmptyEndorsement } from '../../referrals/PageReferralsRecommendations';
import { ClickableItem } from '../../shared/Common';
import { Colors } from '../../shared/Theme';
import { ActionButton, SeqButton } from '../Button';
import { InputTitle, SeqTextArea } from '../Input';
import { RowProps } from '../ListRow';
import { SeqPopover } from '../Popover';
import { TagContainer } from '../popovers/AddTagsPopover';
import { SeqTag } from '../SeqTag';
import { StarInput } from '../Star';
import { DiscoveryIcon } from '../Svgs';
import { ReachOutInfo } from './ReachOutInfo';

const { SEQUOIA_GREEN, SEQUOIA_PASTEL_GREEN } = Colors.Static;

interface VouchesRowProps {
  profile: ExternalAPI.Profile;
  myExtProfileId?: number;
  onUpdateProfile: (update: Partial<ExternalAPI.Profile> & { id: number }) => void;
  getRelatedProfileName: (id: number) => string;
}

type ReferredByTagsProps = Omit<VouchesRowProps, 'profile'> & {
  profile: Pick<ExternalAPI.Profile, 'endorsements' | 'id' | 'organizationId' | 'name'>;
  inlineEditing?: boolean;
};

type EditEndorsementPopoverProps = Pick<ReferredByTagsProps, 'profile' | 'onUpdateProfile'> & {
  endorsement: ExternalAPI.Endorsement;
  isNew?: boolean;
  children: React.ReactNode;
};

export const useVouchesRow = ({
  profile,
  myExtProfileId,
  onUpdateProfile,
  getRelatedProfileName,
}: VouchesRowProps): RowProps => {
  const { endorsements } = profile;
  const myEndorsement = profile.endorsements.find(e => e.owner === myExtProfileId);
  const endorsementFromTeamMember =
    endorsements.find(e => e.ownerWillReachOut) || endorsements.find(e => e.useNameToReachOut);

  return {
    icon: <DiscoveryIcon />,
    title: 'Vouches',
    content: endorsements.length ? (
      <div style={{ flex: 1 }}>
        <ReferredByTags
          profile={profile}
          myExtProfileId={myExtProfileId}
          onUpdateProfile={onUpdateProfile}
          getRelatedProfileName={getRelatedProfileName}
        />
        {endorsementFromTeamMember && <ReachOutInfo endorsement={endorsementFromTeamMember} />}
      </div>
    ) : undefined,
    actions: myExtProfileId && profile.connectedTo?.includes(myExtProfileId) && (
      <EditEndorsementPopover
        profile={profile}
        endorsement={myEndorsement || buildEmptyEndorsement(myExtProfileId)}
        onUpdateProfile={onUpdateProfile}
        isNew={!myEndorsement}
      >
        <ActionButton>{myEndorsement ? 'Edit' : 'Add'}</ActionButton>
      </EditEndorsementPopover>
    ),
  };
};

export const ReferredByTags = ({
  profile,
  getRelatedProfileName,
  myExtProfileId,
  onUpdateProfile,
  inlineEditing,
}: ReferredByTagsProps) => {
  return (
    <TagContainer
      style={{ flexDirection: inlineEditing ? 'column' : undefined, alignItems: 'unset' }}
    >
      {profile.endorsements
        .sort(
          (a, b) =>
            (a.score || 'z').localeCompare(b.score || 'z') ||
            Number(b.owner === myExtProfileId) - Number(a.owner === myExtProfileId)
        )
        .map(e => (
          <div style={{ display: 'flex', gap: 4 }} key={e.owner}>
            <SeqTag
              description={e.comment}
              tagColor={e.owner === myExtProfileId ? SEQUOIA_PASTEL_GREEN : undefined}
              tagName={
                <div
                  style={{
                    display: 'flex',
                    gap: 6,
                    alignItems: 'center',
                    fontWeight: e.owner === myExtProfileId ? 500 : 'unset',
                  }}
                >
                  {e.score?.length === 1 && (
                    <div style={{ color: '#F2994A' }}>
                      <span>★</span>
                      {e.score === 'A' && <span>★</span>}
                    </div>
                  )}
                  {getRelatedProfileName(e.owner)}
                  {e.comment && <CommentOutlined style={{ color: SEQUOIA_GREEN }} />}
                </div>
              }
            />
            {inlineEditing && e.owner === myExtProfileId && (
              <EditEndorsementPopover
                profile={profile}
                endorsement={e}
                onUpdateProfile={onUpdateProfile}
              >
                <img
                  src="/icons/edit-pencil.svg"
                  alt="pencil"
                  style={{ width: 11, marginBottom: 4 }}
                />
              </EditEndorsementPopover>
            )}
          </div>
        ))}
    </TagContainer>
  );
};

export const EditEndorsementPopover = ({
  profile,
  endorsement,
  isNew,
  onUpdateProfile,
  children,
}: EditEndorsementPopoverProps) => {
  const [popoverVisible, setPopoverVisible] = useState<boolean>(false);
  const [newEndorsement, setEndorsement] = useState<ExternalAPI.Endorsement>(endorsement);
  const disabled = isEqual(endorsement, newEndorsement);

  const onSave = async () => {
    if (!disabled) {
      await onProfileUpsert(
        profile,
        { endorsements: [newEndorsement] },
        ({ id, endorsements }: ExternalAPI.Profile) => onUpdateProfile({ id, endorsements })
      );
    }
    setPopoverVisible(false);
  };

  return (
    <SeqPopover
      placement="bottomRight"
      arrow={{ pointAtCenter: true }}
      open={popoverVisible}
      onOpenChange={() => setPopoverVisible(v => !v)}
      title={`${isNew ? 'Add' : 'Edit'} My Endorsement for ${shortenedName(profile.name)}.`}
      content={
        <>
          <div style={{ display: 'flex', gap: 12, alignItems: 'center' }}>
            <InputTitle size="small" title="Rating" />
            <StarInput
              value={newEndorsement.score}
              onChange={score => setEndorsement(prev => ({ ...prev, score }))}
            />
          </div>
          <div>
            <SeqTextArea
              size="small"
              title="Comment"
              placeholder="What makes this person great?"
              autoSize
              value={newEndorsement.comment}
              onChange={e => {
                const comment = e.target.value;
                setEndorsement(prevState => ({ ...prevState, comment }));
              }}
            />
          </div>
        </>
      }
      footer={
        <SeqButton size="small" intent="primary" disabled={disabled} onClick={onSave}>
          {isNew ? 'Add' : 'Update'} Endorsement
        </SeqButton>
      }
    >
      <ClickableItem
        style={{ color: SEQUOIA_GREEN, fontSize: 12 }}
        onClick={e => {
          e.stopPropagation();
        }}
      >
        {children}
      </ClickableItem>
    </SeqPopover>
  );
};
