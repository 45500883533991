import { Card, Collapse, Select, Skeleton, Slider } from 'antd';
import { SelectProps } from 'antd/lib/select';
import { cloneDeep, isEqual, range } from 'lodash';
import React, { useContext, useState } from 'react';
import styled from 'styled-components/macro';
import { SeqButton } from '../components/Button';
import { FooterRow, HorizontalDivider } from '../components/Common';
import { STATUS_SORT_ORDERS } from '../components/selects/StatusSelect';
import { ArrowDown, IconContainer } from '../components/Svgs';
import { BaseUnica, Body2, H4MonoCSS } from '../components/Text';
import { OrganizationContext } from '../contexts/Organization';
import { CharInCircle, FadeInContainer } from '../shared/Common';
import { FriendlyErrorBoundary } from '../shared/FriendlyErrorBoundary';
import { EPD_FUNCTIONS } from '../shared/profile-helpers';
import { useResource } from '../shared/Resource';
import {
  DATASCIENCE_CATEGORY_LABELS,
  DATASCIENCE_ROUND_LABELS,
  DATASCIENCE_SORTED_ROUNDS,
} from '../shared/rule-editors/DSConstants';
import { ArrowLeft } from '../shared/Svgs';
import { Colors } from '../shared/Theme';
import { SearchTermLabels } from './SearchTitle';

const { Panel } = Collapse;
const { Option } = Select;
const XP_RANGE: [number, number] = [0, 20];

const { SEQUOIA_GREEN, SEQUOIA_LIGHT_GRAY, SEQUOIA_PAPER, BLACK1ALPHA, SEQUOIA_BLACK } =
  Colors.Static;

export const INSIGHT_FILTERS: NetworkAPI.InsightFilter[] = [
  {
    type: 'profile',
    id: 'aircraft-carrier',
    title: 'Big Tech Experience',
    description: 'Work experience at distinguished large tech companies',
  },
  {
    type: 'profile',
    id: 'exploring-opps',
    title: 'Exploring Opportunities',
    description: 'May be looking for their next opportunity',
  },
  {
    type: 'profile',
    id: 'starting-something-new',
    title: 'Starting Something New',
    description: 'On our radar and likely starting a new company',
  },
  {
    type: 'profile',
    id: 'upcoming-anniversary',
    title: 'Upcoming Anniversary',
    description: 'Approaching a year mark at their current company',
  },
  {
    type: 'profile',
    id: 'recently-promoted',
    title: 'Recently Promoted',
    description: 'Changed titles in the last three months',
  },
  {
    type: 'profile',
    id: 'no-current-job',
    title: 'No Current Job',
    description: 'Has no current, non-advisory role on LinkedIn',
  },
  {
    type: 'profile',
    id: 'vouched',
    title: 'Vouched',
    description: `Highly regarded in Sequoia's network`,
  },
  {
    type: 'profile',
    id: 'early-stage',
    title: 'Early Stage',
    description: 'Work experience at a company while they were raising early funding rounds.',
  },
  {
    type: 'company',
    id: 'startup-recent-raise',
    title: 'Recent Fundraise',
    description: 'Company completed a raise in the last 6mo',
  },
  {
    type: 'company',
    id: 'layoffs',
    title: 'Recent Layoffs',
    description: 'Recent reduction in force per Layoffs.fyi',
  },
];

export const NetworkFilterDrawer: React.FC<{
  searchTermLabels: SearchTermLabels;
  initSearch: NetworkAPI.SearchHistoryData;
  onSearch: (s: NetworkAPI.SearchHistoryData, isNew: boolean) => void;
  closeDrawer: () => void;
}> = ({ initSearch, closeDrawer, searchTermLabels, onSearch }) => {
  const { id } = useContext(OrganizationContext);
  const [search, setSearch] = useState<NetworkAPI.SearchHistoryData>(
    Object.keys(initSearch).length ? initSearch : { person: { functions: EPD_FUNCTIONS } }
  );
  const [searchOptions] = useResource<NetworkAPI.SearchOptions>(
    `/api/${id}/network/search-options?maxXp=${XP_RANGE[1]}`
  );
  const [activeKey, setActiveKey] = useState<string[]>([
    'person',
    'company',
    'pipeline',
    'insights',
  ]);

  const { roles, owners, insights = [] } = searchTermLabels;
  const { filterCounts, isEmpty } = React.useMemo(() => getFilterCounts(search), [search]);

  const _onSave = () => {
    isEmpty ? closeDrawer() : onSearch(search, !isEqual(search, initSearch));
  };

  // Save on close!
  const onSaveRef = React.useRef(_onSave);
  onSaveRef.current = _onSave;
  React.useEffect(() => {
    return () => onSaveRef.current();
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        background: SEQUOIA_PAPER,
      }}
    >
      <div style={{ flex: 1, overflowY: 'auto' }}>
        <FriendlyErrorBoundary description="network filter drawer">
          <Collapse
            ghost
            expandIcon={({ isActive }) => (
              <IconContainer style={{ width: 18 }}>
                {isActive ? <ArrowDown /> : <ArrowLeft style={{ transform: 'rotate(180deg)' }} />}
              </IconContainer>
            )}
            activeKey={activeKey}
            onChange={key => setActiveKey(key as string[])}
          >
            <SeqPanel
              key="person"
              active={activeKey.includes('person')}
              header={<PanelHeader title="Person" count={filterCounts.person} />}
            >
              <PanelContainer loading={!searchOptions}>
                <PersonFilters
                  search={search?.person}
                  searchOptions={searchOptions}
                  onChange={update =>
                    setSearch(prev => ({ ...prev, person: { ...(prev.person || {}), ...update } }))
                  }
                />
              </PanelContainer>
            </SeqPanel>
            <SeqPanel
              key="company"
              active={activeKey.includes('company')}
              header={<PanelHeader title="Company" count={filterCounts.company} />}
            >
              <PanelContainer loading={!searchOptions}>
                <CompanyFilters
                  search={search?.company}
                  searchOptions={searchOptions}
                  onChange={update =>
                    setSearch(prev => ({
                      ...prev,
                      company: { ...(prev.company || {}), ...update },
                    }))
                  }
                />
              </PanelContainer>
            </SeqPanel>
            <SeqPanel
              key="pipeline"
              active={activeKey.includes('pipeline')}
              header={<PanelHeader title="Pipeline" count={filterCounts.pipeline} />}
            >
              <PanelContainer loading={!searchOptions}>
                <PipelineFilters
                  search={search?.pipeline}
                  onChange={update =>
                    setSearch(prev => ({
                      ...prev,
                      pipeline: { ...(prev.pipeline || {}), ...update },
                    }))
                  }
                  roles={roles}
                  owners={owners}
                />
              </PanelContainer>
            </SeqPanel>
            <SeqPanel
              key="insights"
              active={activeKey.includes('insights')}
              header={<PanelHeader title="Insights" count={filterCounts.insights} />}
            >
              <PanelContainer loading={!searchOptions}>
                <CardContainer>
                  {insights.map(f => (
                    <FilterCard filter={f} search={search} setSearch={setSearch} key={f.id} />
                  ))}
                </CardContainer>
              </PanelContainer>
            </SeqPanel>
          </Collapse>
        </FriendlyErrorBoundary>
      </div>
      <HorizontalDivider />
      <FooterRow style={{ marginTop: 'unset', padding: '16px 24px', justifyContent: 'flex-end' }}>
        <SeqButton intent="primary" disabled={isEmpty} onClick={closeDrawer}>
          search
        </SeqButton>
      </FooterRow>
    </div>
  );
};

const PersonFilters: React.FC<{
  search?: NetworkAPI.SearchHistoryData['person'];
  searchOptions?: NetworkAPI.SearchOptions;
  onChange: (update: Partial<NetworkAPI.SearchHistoryData['person']>) => void;
}> = ({ search, searchOptions, onChange }) => (
  <>
    <FilterInputContainer>
      <FilterInputTitle>Title:</FilterInputTitle>
      <MultiSelect
        mode="tags"
        placeholder="Filter by keywords in person's title"
        defaultValue={search?.title}
        onChange={title => onChange({ title })}
      />
    </FilterInputContainer>
    <FilterInputContainer>
      <FilterInputTitle>Tags:</FilterInputTitle>
      <MultiSelect
        placeholder="Filter by person's functions"
        defaultValue={search?.functions}
        onChange={functions => onChange({ functions })}
        options={searchOptions?.person.functions}
      />
    </FilterInputContainer>
    <FilterInputContainer>
      <FilterInputTitle>Location:</FilterInputTitle>
      <MultiSelect
        placeholder="Filter by location"
        defaultValue={search?.locations}
        onChange={locations => onChange({ locations })}
        options={searchOptions?.person.locations}
      />
    </FilterInputContainer>
    <FilterInputContainer>
      <FilterInputTitle>Experience:</FilterInputTitle>
      <ExperienceSlider
        defaultValue={search?.experience}
        options={searchOptions?.person?.experience}
        onChange={experience => {
          const xpIsDefaultRange = isEqual(XP_RANGE, experience);
          if (xpIsDefaultRange && !search?.experience) return;
          onChange({
            experience: xpIsDefaultRange && search?.experience ? undefined : experience,
          });
        }}
      />
    </FilterInputContainer>
  </>
);

const CompanyFilters: React.FC<{
  search?: NetworkAPI.SearchHistoryData['company'];
  searchOptions?: NetworkAPI.SearchOptions;
  onChange: (update: Partial<NetworkAPI.SearchHistoryData['company']>) => void;
}> = ({ search, searchOptions, onChange }) => (
  <>
    <FilterInputContainer>
      <FilterInputTitle>Name:</FilterInputTitle>
      <MultiSelect
        placeholder="Filter by company"
        defaultValue={search?.name}
        onChange={name => onChange({ name })}
        options={searchOptions?.company.name}
      />
    </FilterInputContainer>
    <FilterInputContainer>
      <FilterInputTitle>Series:</FilterInputTitle>
      <MultiSelect
        placeholder="Filter by company's last funding round"
        defaultValue={search?.lastRound}
        onChange={lastRound => onChange({ lastRound })}
        labels={DATASCIENCE_ROUND_LABELS}
        options={DATASCIENCE_SORTED_ROUNDS}
      />
    </FilterInputContainer>
    <FilterInputContainer>
      <FilterInputTitle>Industry:</FilterInputTitle>
      <MultiSelect
        placeholder="Filter by industry"
        defaultValue={search?.categories}
        options={searchOptions?.company.categories}
        labels={DATASCIENCE_CATEGORY_LABELS}
        onChange={categories => onChange({ categories: categories.map(c => c.toLowerCase()) })}
      />
    </FilterInputContainer>
  </>
);

const PipelineFilters: React.FC<{
  search?: NetworkAPI.SearchHistoryData['pipeline'];
  roles: OrganizationAPI.Role[];
  owners: ExternalAPI.UserWithCampaigns[];
  onChange: (update: Partial<NetworkAPI.SearchHistoryData['pipeline']>) => void;
}> = ({ search, onChange, owners, roles }) => (
  <>
    <FilterInputContainer>
      <FilterInputTitle>Status:</FilterInputTitle>
      <MultiSelect
        placeholder="Filter by status"
        defaultValue={search?.status}
        options={Object.keys(STATUS_SORT_ORDERS)}
        onChange={statuses => onChange({ status: statuses as PipelineAPI.ProfileStatus[] })}
      />
    </FilterInputContainer>
    <FilterInputContainer>
      <FilterInputTitle>Role:</FilterInputTitle>
      <MultiSelect
        placeholder="Filter by role"
        defaultValue={search?.role?.map(r => `${r}`)}
        options={roles.map(r => `${r.id}`)}
        labels={roles.reduce(
          (acc, r) => {
            acc[r.id] = r.name;
            return acc;
          },
          {} as { [key: string]: string }
        )}
        onChange={roles => onChange({ role: roles.map(r => Number(r)) })}
      />
    </FilterInputContainer>
    <FilterInputContainer>
      <FilterInputTitle>Owner:</FilterInputTitle>
      <MultiSelect
        placeholder="Filter by owner"
        defaultValue={search?.owner?.map(o => `${o}`)}
        options={owners.map(o => `${o.id}`)}
        labels={owners.reduce(
          (acc, o) => {
            acc[o.id] = o.name;
            return acc;
          },
          {} as { [key: string]: string }
        )}
        onChange={owners => onChange({ owner: owners.map(o => Number(o)) })}
      />
    </FilterInputContainer>
  </>
);

const PanelContainer: React.FC<{ loading: boolean }> = ({ loading, children }) => (
  <div style={{ display: 'flex', flexDirection: 'column', gap: 12, background: SEQUOIA_PAPER }}>
    {loading ? <Skeleton active /> : children}
  </div>
);

const ExperienceSlider: React.FC<{
  defaultValue?: [number, number];
  options?: { [years: number]: number };
  onChange: (options: [number, number]) => void;
}> = ({ defaultValue, options, onChange }) => {
  const maxCount = options ? Math.max(...Object.values(options)) : 0;
  const bars = range(XP_RANGE[0], XP_RANGE[1] + 1);
  defaultValue = defaultValue || XP_RANGE;

  return (
    <div style={{ flex: 1 }}>
      {bars && options && (
        <FadeInContainer style={{ display: 'flex', alignItems: 'flex-end', height: 30 }}>
          {bars.map(idx => {
            const style =
              !!options[idx] && defaultValue
                ? {
                    height: `${Math.round((options[idx] / maxCount) * 100)}%`,
                    background:
                      idx >= defaultValue[0] && idx <= defaultValue[1]
                        ? SEQUOIA_GREEN
                        : SEQUOIA_LIGHT_GRAY,
                  }
                : { height: '0%' };

            return <div key={idx} style={{ flex: 1, ...style }} />;
          })}
        </FadeInContainer>
      )}
      <Slider
        range
        defaultValue={defaultValue}
        min={XP_RANGE[0]}
        max={XP_RANGE[1]}
        tooltip={{
          formatter: (value?: number) => `${value}${value === XP_RANGE[1] ? '+' : ''} yr`,
        }}
        onChange={value => options && onChange(value as [number, number])}
      />
    </div>
  );
};

const getFilterCounts = (search: NetworkAPI.SearchHistoryData) => {
  const result: Record<string, number> = {};
  if (search.insights?.length) {
    result['insights'] = search.insights.length;
  }
  if (search.company) {
    result['company'] = Object.values(search.company).flat().length;
  }
  if (search.person) {
    result['person'] = Object.values(search.person).flat().length;
  }
  if (search.pipeline) {
    result['pipeline'] = Object.values(search.pipeline).flat().length;
  }
  return { filterCounts: result, isEmpty: !Object.values(result).reduce((acc, c) => acc + c, 0) };
};

const MultiSelect: React.FC<
  Omit<SelectProps<string[]>, 'options'> & {
    options?: string[];
    labels?: { [key: string]: string };
  }
> = ({ options = [], labels = {}, ...selectProps }) => {
  return (
    <Select
      allowClear
      mode="multiple"
      style={{ width: '100%', borderRadius: 10, fontSize: 12 }}
      notFoundContent="Type any keyword"
      {...selectProps}
    >
      {options.map(o => (
        <StyledOption key={o} value={o}>
          {labels[o] || o}
        </StyledOption>
      ))}
    </Select>
  );
};

const StyledOption = styled(Option)`
  ${BaseUnica}
`;

const FilterCard: React.FC<{
  filter: NetworkAPI.InsightFilter;
  search: NetworkAPI.SearchHistoryData;
  setSearch: (d: NetworkAPI.SearchHistoryData) => void;
}> = ({ filter, search, setSearch }) => {
  const { id, title, description } = filter;
  const selected = search.insights?.includes(id);

  return (
    <Card
      hoverable={true}
      size="small"
      bodyStyle={{ fontSize: 12, padding: 0 }}
      onClick={() => {
        const updatedSearch = cloneDeep(search);
        const insights = search.insights || [];
        updatedSearch.insights = insights.includes(id)
          ? insights.filter(fId => id !== fId)
          : insights.concat(id);

        setSearch(updatedSearch);
      }}
      style={{
        flex: 1,
        maxWidth: '48%',
        minWidth: '48%',
        borderRadius: 10,
        outline: selected ? `2px solid ${SEQUOIA_GREEN}` : undefined,
        userSelect: 'none',
      }}
    >
      <div style={{ fontWeight: 500, padding: `8px 12px` }}>{title}</div>
      {description && (
        <div
          style={{
            borderTop: `1px solid ${BLACK1ALPHA}`,
            padding: `8px 12px`,
          }}
        >
          {description}
        </div>
      )}
    </Card>
  );
};

const PanelHeader: React.FC<{ title: string; count?: number }> = ({ title, count }) => (
  <Header>
    {title}
    {!!count && (
      <CharInCircle
        char={count}
        style={{ width: 24, height: 24, fontSize: 16, background: SEQUOIA_PAPER }}
      />
    )}
  </Header>
);

const SeqPanel = styled(Panel)<{ active?: boolean }>`
  background: ${SEQUOIA_PAPER};
  padding: 0;
  margin: 0;
  & .ant-collapse-header {
    background: ${BLACK1ALPHA};
    border-top: 1px solid ${SEQUOIA_BLACK};
    ${({ active }) => active && `border-bottom: 1px solid ${SEQUOIA_BLACK};`}
  }
`;

const Header = styled.div`
  ${H4MonoCSS}
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const CardContainer = styled.div`
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
`;

const FilterInputContainer = styled.div`
  display: flex;
  align-items: center;
`;

const FilterInputTitle = styled(Body2)`
  width: 90px;
  flex-shrink: 0;
  white-space: nowrap;
`;
