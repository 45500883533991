import { notification } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { default as React, useContext } from 'react';
import { ActionButton } from '../components/Button';
import { PageContainer } from '../components/Common';
import { InviteTeamInput } from '../components/InviteTeamInput';
import { SeqCheckbox } from '../components/SeqCheckbox';
import { SeqPopConfirm } from '../components/SeqPopConfirm';
import { SeqTable } from '../components/SeqTable';
import { Body1Serif, H2Mono, H3Mono } from '../components/Text';
import { OrganizationContext } from '../contexts/Organization';
import { DATE_FORMAT } from '../management/CompanyMembersTable';
import { makeRequest } from '../shared/Resource';
import { ScrollingContainer } from '../shared/ScrollableContainer';

export const PageTeam: React.FC = () => {
  const organization = useContext(OrganizationContext);
  const { me, team, settings: orgSettings, actions } = organization;
  const pendingInvites = team.filter(tm => !tm.lastActivity);
  const activeUsers = team.filter(tm => tm.lastActivity);

  const archiveUser = async (user: ExternalAPI.User) => {
    const response = await makeRequest<ExternalAPI.User>(`/api/user/${user.id}/archive`, 'PUT', {
      stopFollowups: true,
    } satisfies ExternalAPI.UserArchiveRequest);
    if (response.id) {
      notification.open({
        message: 'Archive Complete',
        description: `${user.name || user.email} has been archived`,
        duration: 2,
      });
      actions.refresh();
    }
  };

  const activeUserColumns: ColumnsType<ExternalAPI.UserWithLastActivity> = [
    { title: 'Name', dataIndex: 'name' },
    { title: 'Email', dataIndex: 'email' },
    {
      title: 'Last Seen',
      render: (user: ExternalAPI.UserWithLastActivity) => {
        return <div>{moment(user.lastActivity?.createdAt).format(DATE_FORMAT)}</div>;
      },
    },
    {
      title: 'Actions',
      render: (user: ExternalAPI.User) => {
        return (
          <SeqPopConfirm
            width={225}
            okText={'Archive'}
            title={
              <div>
                Archiving <strong>{user.name}</strong> will pause any active campaigns running
                through their email account.
              </div>
            }
            onConfirm={async () => {
              await archiveUser(user);
            }}
          >
            <div style={{ display: 'flex' }}>
              <ActionButton>Archive</ActionButton>
            </div>
          </SeqPopConfirm>
        );
      },
    },
  ];

  const pendingInviteColumns: ColumnsType<ExternalAPI.User> = [
    {
      title: 'Email',
      render: (user: ExternalAPI.User) => {
        return <div>{user.email}</div>;
      },
    },
    {
      title: 'Sent On',
      render: (user: ExternalAPI.User) => {
        return <div>{moment(user.updatedAt).format(DATE_FORMAT)}</div>;
      },
    },
    {
      title: 'Valid Until',
      render: (user: ExternalAPI.User) => {
        return <div>{moment(user.updatedAt).add(60, 'days').format(DATE_FORMAT)}</div>;
      },
    },
    {
      title: 'Action',
      render: (user: ExternalAPI.User) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
            <ActionButton
              onClick={async () => {
                const userUpdate: ExternalAPI.UpsertUserRequest = {
                  ...user,
                  sendInvite: true,
                };
                const response = await makeRequest<ExternalAPI.User>(
                  '/api/user',
                  'PUT',
                  userUpdate
                );
                if (response.id) {
                  notification.success({ message: 'Invite sent' });
                  actions.refresh();
                }
              }}
            >
              Resend Invite
            </ActionButton>
            <ActionButton
              onClick={async () => {
                await archiveUser(user);
              }}
            >
              Archive
            </ActionButton>
          </div>
        );
      },
    },
  ];
  return (
    <PageContainer>
      <H2Mono>Team</H2Mono>
      <div style={{ maxWidth: '800px' }}>
        <Body1Serif>
          Invite your team to give them access to the founder tools. Users you invite can help
          collect leads, review candidates and update your pipeline. You can also create email
          campaigns that send as any user in your organization.
        </Body1Serif>
        <InviteTeamInput team={team} organization={organization} refresh={actions.refresh} />
      </div>
      <ScrollingContainer style={{ flex: 1 }}>
        <H3Mono style={{ marginTop: 40, marginBottom: 18 }}>Pending Invitations</H3Mono>
        <SeqTable
          rowKey={'email'}
          pagination={false}
          dataSource={pendingInvites}
          columns={pendingInviteColumns}
        />
        {!!activeUsers.length && (
          <>
            <H3Mono style={{ marginTop: 40, marginBottom: 18 }}>Team Members</H3Mono>
            <SeqTable
              rowKey={'email'}
              pagination={false}
              dataSource={activeUsers}
              columns={activeUserColumns}
            />
          </>
        )}
        <H3Mono style={{ marginTop: 40, marginBottom: 18 }}>Permissions</H3Mono>
        <SeqCheckbox
          onChange={async event => {
            orgSettings.shareWithSequoia = event.currentTarget.checked;
            await makeRequest<OrganizationAPI.Organization>(
              `/api/organization/${me.organizationId}`,
              'PUT',
              { settings: orgSettings } satisfies OrganizationAPI.UpdateOrganizationRequest
            );
            await actions.refresh();
          }}
          checked={orgSettings.shareWithSequoia}
        >
          Share connections & references with Sequoia
        </SeqCheckbox>
      </ScrollingContainer>
    </PageContainer>
  );
};
