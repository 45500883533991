import { CommentOutlined } from '@ant-design/icons';
import { Popover } from 'antd';
import React, { ReactElement, useState } from 'react';
import { RowContainer } from '../leads/LeadListsSection';
import { CharInCircle, ClickableItem } from '../shared/Common';
import { isValidEmail } from '../shared/helpers';
import { bestRoleTitleAndEmployer, MinimalExtProfile } from '../shared/profile-helpers';
import { InitialsView } from '../shared/ProfileImage';
import { Colors } from '../shared/Theme';
import { ActionButton, SeqButton } from './Button';
import { DisplayOnHover, HorizontalDivider, HoverableDiv, LightTooltip } from './Common';
import { PlainTextArea } from './Input';
const { BLACK5ALPHA, BLACK1ALPHA, SEQUOIA_GREEN, SEQUOIA_BRIGHT_RED } = Colors.Static;
const CANDIDATE_ALREADY_CONTACTED_MSG = 'This candidate has already been contacted.';

interface RemovableProfileRowProps<T> {
  profile: T;
  alreadyContacted: boolean;
  added: boolean;
  onSelect?: () => void;
  onAddOrRemove?: () => void;
  selected?: boolean;
  leadsConfig?: {
    onAddEmail: (profile: T) => void;
    onUpsertComment: (comment: string) => void;
    comment?: string;
  };
}

export const RemovableProfileRow = <T extends MinimalExtProfile & { email: string }>({
  profile,
  alreadyContacted,
  added,
  selected = false,
  onSelect,
  onAddOrRemove,
  leadsConfig,
}: RemovableProfileRowProps<T>): ReactElement => {
  const hasValidEmail = isValidEmail(profile.email);

  const row = (
    <ClickableItem
      disabled={!added}
      style={{
        flex: 1,
        alignItems: 'center',
        cursor: leadsConfig ? 'pointer' : undefined,
      }}
    >
      <InitialsView name={profile.name} style={{ width: 36, height: 36, fontSize: 14 }} />
      <div
        style={{
          padding: '0 12px',
          flex: 1,
          justifyContent: 'center',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div style={{ fontWeight: 500 }}>
          {profile.name}
          {leadsConfig?.comment && (
            <span style={{ marginLeft: 12 }}>
              <Popover
                placement="bottomLeft"
                content={<div style={{ fontSize: 12, width: 300 }}>{leadsConfig.comment}</div>}
              >
                <CommentOutlined style={{ fontSize: 14, color: SEQUOIA_GREEN }} />
              </Popover>
            </span>
          )}
        </div>
        {(hasValidEmail || profile.email === 'pending') && (
          <div>{profile.email === 'pending' ? 'Pending email' : profile.email}</div>
        )}
        <div style={{ color: BLACK5ALPHA }}>{bestRoleTitleAndEmployer(profile)}</div>
      </div>
      {leadsConfig && alreadyContacted && (
        <div
          style={{
            width: 145,
            float: 'right',
            textAlign: 'right',
            fontStyle: 'italic',
            marginRight: 34,
          }}
        >
          {CANDIDATE_ALREADY_CONTACTED_MSG}
        </div>
      )}
    </ClickableItem>
  );

  return leadsConfig || hasValidEmail ? (
    <HoverableDiv>
      <RowContainer style={{ background: selected ? BLACK1ALPHA : undefined }}>
        <div
          onClick={added ? onSelect : undefined}
          style={{ display: 'flex', flex: 1, alignItems: 'center' }}
        >
          {leadsConfig && added ? (
            <>
              {row}
              {!alreadyContacted && (
                <div
                  style={{
                    gap: 6,
                    marginRight: 12,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                  }}
                >
                  {!hasValidEmail && (
                    <ActionButton onClick={() => leadsConfig.onAddEmail(profile)}>
                      Add Email
                    </ActionButton>
                  )}
                  <UpsertCommentPopover
                    comment={leadsConfig.comment}
                    onUpsert={leadsConfig.onUpsertComment}
                  />
                </div>
              )}
            </>
          ) : (
            row
          )}
        </div>
        {leadsConfig && !alreadyContacted && (
          <DisplayOnHover style={{ visibility: added ? undefined : 'visible' }}>
            <ClickableItem onClick={onAddOrRemove}>
              <CharInCircle
                char={added ? '–' : '+'}
                style={{
                  fontSize: 22,
                  color: added ? SEQUOIA_BRIGHT_RED : SEQUOIA_GREEN,
                  paddingBottom: 4,
                }}
              />
            </ClickableItem>
          </DisplayOnHover>
        )}
      </RowContainer>
      <HorizontalDivider />
    </HoverableDiv>
  ) : (
    <LightTooltip
      key={profile.id}
      overlayStyle={{ width: 200 }}
      overlay={
        alreadyContacted
          ? CANDIDATE_ALREADY_CONTACTED_MSG
          : 'This candidate is missing a valid email address.'
      }
      placement="right"
    >
      <div>
        <RowContainer>{row}</RowContainer>
        <HorizontalDivider />
      </div>
    </LightTooltip>
  );
};

const UpsertCommentPopover: React.FC<{ onUpsert: (comment: string) => void; comment?: string }> = ({
  onUpsert,
  comment,
}) => {
  const [popoverVisible, setPopoverVisible] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState(comment || '');

  const actionText = `${comment ? 'Edit' : 'Add'} Comment`;

  const PopoverContent = (
    <div style={{ width: 300 }}>
      <PlainTextArea
        autoFocus
        autoSize
        style={{ fontSize: 12, padding: '6px 0 0 12px', minHeight: 50 }}
        placeholder="Why was this candidate chosen?"
        value={inputValue}
        onChange={e => setInputValue(e.currentTarget.value)}
      />
      <HorizontalDivider />
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <SeqButton
          style={{ margin: '6px 12px' }}
          disabled={!inputValue.trim().length || inputValue.trim() === comment}
          onClick={() => {
            onUpsert(inputValue);
            setPopoverVisible(false);
          }}
        >
          {actionText}
        </SeqButton>
      </div>
    </div>
  );

  return (
    <Popover
      overlayClassName={'seq-no-padding'}
      trigger={'click'}
      content={PopoverContent}
      placement={'bottomRight'}
      open={popoverVisible}
      onOpenChange={() => setPopoverVisible(v => !v)}
    >
      <ActionButton>{actionText}</ActionButton>
    </Popover>
  );
};
