import { EyeInvisibleOutlined } from '@ant-design/icons';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, Document, INLINES } from '@contentful/rich-text-types';
import { Tooltip } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { HorizontalDivider } from '../components/Common';
import { TabPane, Tabs } from '../components/Tabs';
import { OrganizationContext } from '../contexts/Organization';
import { EXTERNAL_WEB_APP } from '../shared/helpers';
import { useStateWithStorage } from '../shared/hooks';
import { ScrollingContainer } from '../shared/ScrollableContainer';
import { Colors } from '../shared/Theme';
import { getLoomIdFromUrl, getNotionIdFromUrl } from './PageResources';

interface ContentfulBlock {
  title: string;
  tab: string;
  foundersOnly: boolean;
  column: {
    nodeType: BLOCKS.DOCUMENT;
    data: {};
    content: {
      data: any;
      marks: any[];
      value: string;
      nodeType: BLOCKS;
    }[];
  };
}

const TAB_KEY = 'resourcesTab_v1';

const contentfulOptions = {
  renderNode: {
    [INLINES.ENTRY_HYPERLINK]: (node: any) => {
      const { content, data } = node;
      return (
        // We want Ampersand to know it was us.
        // eslint-disable-next-line react/jsx-no-target-blank
        <a
          href={transformAmpersandIdToURL(data.target.sys.id)}
          target="_blank"
          style={{ display: 'block', color: Colors.Static.SEQUOIA_GREEN }}
        >
          {content[0].value}
        </a>
      );
    },
    [INLINES.HYPERLINK]: (node: any) => {
      const { content, data } = node;
      const history = useHistory();
      const onClick = () => {
        if (data.uri.includes('https://www.loom.com/share/')) {
          history.push(`resources/v/${getLoomIdFromUrl(data.uri)}`);
        } else if (
          data.uri.includes('https://www.notion.so/') ||
          data.uri.includes('notion.site/')
        ) {
          history.push(
            `resources/n/${getNotionIdFromUrl(data.uri, data.uri.includes('notion.site/'))}`
          );
        } else {
          window.open(data.uri);
        }
      };

      return (
        <a href="#/" style={{ color: Colors.Static.SEQUOIA_GREEN }} onClick={onClick}>
          {content[0].value}
        </a>
      );
    },
  },
};

export const ResourceTableView: React.FC<{
  tabNames: string[];
  contentfulTitles: ConfigJSON.ContentfulTitleData[];
  contentful: any[];
}> = ({ tabNames, contentfulTitles, contentful }) => {
  const [activeTab, setActiveTab] = useStateWithStorage<string>(TAB_KEY, tabNames[0]);
  const loomVideos =
    contentfulTitles.filter(
      title =>
        title.category.toLocaleLowerCase() === 'loom videos' &&
        title.url.includes('https://www.loom.com/share/')
    ) || [];

  const resourcesByTab: Array<any> = contentful.filter(content => content.tab === activeTab);

  return (
    <>
      <Tabs active={activeTab || tabNames[0]} onChange={setActiveTab}>
        {tabNames.map(t => (
          <TabPane
            key={t}
            label={t.toLowerCase() === 'sequoia talent articles' ? 'Sequoia Talent Resources' : t}
            overrideKey={t}
          >
            <ScrollingContainer
              style={{ marginLeft: 24, paddingTop: 24, display: 'flex', justifyContent: 'center' }}
            >
              {t.toLowerCase() === 'loom videos' ? (
                <LoomSection loomVideos={loomVideos} />
              ) : (
                <ContentfulSection items={resourcesByTab} />
              )}
            </ScrollingContainer>
          </TabPane>
        ))}
      </Tabs>
    </>
  );
};

const ContentfulSection: React.FC<{
  items: ContentfulBlock[];
}> = ({ items }) => {
  return (
    <div>
      <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', columnGap: 12 }}>
        {items
          .filter(section => section.title.toLowerCase() !== 'recommended recruiting products')
          .map((section, idx) => {
            return (
              <div key={idx} style={{ marginBottom: 40 }}>
                <div
                  style={{ fontSize: 16, marginBottom: 16, display: 'flex', alignItems: 'center' }}
                >
                  {section.title}
                  {section.foundersOnly && (
                    <Tooltip
                      title={
                        'This section contains content that is only visible to founders and admins'
                      }
                    >
                      <EyeInvisibleOutlined
                        style={{
                          color: Colors.Static.SEQUOIA_LIGHT_TEXT,
                          fontSize: 14,
                          marginLeft: 6,
                        }}
                      />
                    </Tooltip>
                  )}
                </div>
                <div>
                  {documentToReactComponents(
                    section.column as unknown as Document,
                    contentfulOptions
                  )}
                </div>
              </div>
            );
          })}
      </div>
      {items
        .filter(section => section.title.toLowerCase() === 'recommended recruiting products')
        .map((section, idx) => (
          <React.Fragment key={`r-${idx}`}>
            <HorizontalDivider style={{ marginBottom: 24 }} />
            <div style={{ paddingBottom: 40 }}>
              <div style={{ fontSize: 16, marginBottom: 16 }}>{section.title}</div>
              <div>
                {documentToReactComponents(
                  section.column as unknown as Document,
                  contentfulOptions
                )}
              </div>
            </div>
          </React.Fragment>
        ))}
      <React.Fragment>
        <HorizontalDivider style={{ marginBottom: 24 }} />
        <div style={{ paddingBottom: 40 }}>
          <div style={{ fontSize: 16, marginBottom: 16, fontWeight: 400 }}>
            Sequoia Builders | Get Advice from an expert
          </div>
          <div>
            Sequoia Builders are outlier Engineering, GTM and Product leaders in our network who
            have generously offered to share their expertise with Sequoia founders. If you need
            company building advice from a GTM, Product or Engineering expert, schedule a 1:1 with a
            Sequoia Builder{' '}
            <a
              target="_blank"
              style={{ color: Colors.Static.SEQUOIA_GREEN }}
              href={`https://ampersand.sequoiacap.com/login?redirect=%2Fbuilders%2Fconnect`}
              rel="noreferrer"
            >
              here
            </a>
            .
          </div>
        </div>
      </React.Fragment>
    </div>
  );
};

const LoomSection: React.FC<{ loomVideos: ConfigJSON.ContentfulTitleData[] }> = ({
  loomVideos,
}) => {
  const { me } = React.useContext(OrganizationContext);
  return (
    <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 20 }}>
      {loomVideos.map((video, idx) => {
        const loomId = getLoomIdFromUrl(video.url);
        return (
          <div key={idx} style={{ marginBottom: 20 }}>
            <div style={{ fontSize: 16, marginBottom: 16 }}>{video.title}</div>
            <a key={idx} href={`${EXTERNAL_WEB_APP}/${me.organizationId}/resources/v/${loomId}`}>
              <img
                style={{ width: '80%', maxWidth: '500px' }}
                alt={video.url}
                src={`https://cdn.loom.com/sessions/thumbnails/${loomId}-00001.gif`}
              />
            </a>
          </div>
        );
      })}
    </div>
  );
};

const transformAmpersandIdToURL = (ampersandId: string) =>
  `https://ampersand.sequoiacap.com/library?story=${ampersandId}`;
