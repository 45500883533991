import Axis from 'bizcharts/lib/components/Axis';
import Chart from 'bizcharts/lib/components/Chart';
import Legend from 'bizcharts/lib/components/Legend';
import Tooltip from 'bizcharts/lib/components/Tooltip';
import Interval from 'bizcharts/lib/geometry/Interval';
import moment from 'moment';
import React, { ReactNode } from 'react';
import {
  ChartRowContainer,
  DEFAULT_CHART_HEIGHT,
  filterFromLabelClick,
  MAX_CHARTS_SLIDER,
} from '../ChartHelpers';
import { SelectCohortEvent } from '../events';
import { extractAxisClick, extractClickData, StackedBarChartData } from '../shared/ChartHelpers';
import { Colors, hexWithOpacity } from '../shared/Theme';
import { ChartLegend } from './ChartLegend';
import { cutCandidatesByDayCreated } from './selects/CohortSelector';

const { SEQUOIA_GREEN, SEQUOIA_BLACK } = Colors.Static;

interface PageInfo {
  maxIndex: number;
  currentIndex: number;
}

export const BarChartSlider: React.FunctionComponent<{
  candidates: CampaignAPI.CampaignMember[];
  height?: number;
  extra?: ReactNode;
  onSelectFilter: (filter?: string) => void;
}> = ({ candidates, height = DEFAULT_CHART_HEIGHT, extra, onSelectFilter }) => {
  const candidatesByDay = React.useMemo(() => cutCandidatesByDayCreated(candidates), [candidates]);
  const [pageInfo, setPageInfo] = React.useState<PageInfo>({ currentIndex: 0, maxIndex: 0 });

  React.useEffect(() => {
    const maxIndex = Math.floor(Object.keys(candidatesByDay).length / MAX_CHARTS_SLIDER);
    setPageInfo({
      maxIndex,
      currentIndex: maxIndex,
    });
  }, [candidatesByDay]);

  const modular = Object.keys(candidatesByDay).length % MAX_CHARTS_SLIDER;
  const chartData: StackedBarChartData[] = Object.keys(candidatesByDay)
    .map(day => Number(day))
    .sort((r1, r2) => r2 - r1)
    .reverse()
    .slice(
      Math.max(0, (pageInfo.currentIndex - 1) * MAX_CHARTS_SLIDER + modular),
      pageInfo.currentIndex * MAX_CHARTS_SLIDER + modular
    )
    .map(unixTime => {
      const candidates = candidatesByDay[unixTime];
      const groupName = moment.unix(unixTime / 1000).format('MMMM DD');
      return [
        {
          groupName,
          dataSet: 'Contacted',
          value: candidates.length,
        },
        {
          groupName,
          dataSet: 'Replied',
          value: candidates.filter(r => r.candidateRespondedAt).length,
        },
        {
          groupName,
          dataSet: 'Replied Positively',
          value: candidates.filter(
            r => r.candidateRespondedAt && r.responseSentiment === 'Positive'
          ).length,
        },
      ].filter(d => d.value > 0);
    })
    .flat();

  return (
    <div>
      <ChartLegend
        labelsAndColors={labelsAndColors}
        onSelectFilter={onSelectFilter}
        onLeftClick={
          pageInfo.currentIndex > 0
            ? () =>
                setPageInfo({ ...pageInfo, currentIndex: Math.max(pageInfo.currentIndex - 1, 0) })
            : undefined
        }
        onRightClick={
          pageInfo.currentIndex < pageInfo.maxIndex
            ? () => {
                setPageInfo({
                  ...pageInfo,
                  currentIndex: Math.min(pageInfo.currentIndex + 1, pageInfo.maxIndex),
                });
              }
            : undefined
        }
      />
      <ChartRowContainer height={height}>
        <Chart
          defaultInteractions={['tooltip']}
          onPlotClick={(e: any) => {
            const clicked = extractClickData<StackedBarChartData>(e);
            if (!clicked) {
              return;
            }
            onSelectFilter(filterFromLabelClick(clicked.dataSet)?.label);
          }}
          interactions={[]}
          height={height - 50}
          data={chartData}
          autoFit
          onAxisLabelClick={(e: any) => {
            const clickedLabel = extractAxisClick(e);
            for (const unixTimesOfCohorts of Object.keys(candidatesByDay)) {
              const groupName = moment.unix(Number(unixTimesOfCohorts) / 1000).format('MMMM DD');
              groupName === clickedLabel &&
                SelectCohortEvent.dispatchFor(Number(unixTimesOfCohorts));
            }
          }}
        >
          <Axis
            label={{ style: { fill: SEQUOIA_BLACK } }}
            name="value"
            grid={false}
            line={false}
            tickLine={null}
          />
          <Axis
            label={{ offset: 20, style: { fill: SEQUOIA_BLACK, marginRight: 40 } }}
            name="groupName"
            grid={false}
            line={false}
            tickLine={false}
          />
          <Tooltip showTitle={false} />
          <Interval
            label="wee"
            adjust={[
              {
                type: 'dodge',
                marginRatio: 0,
              },
            ]}
            color={['dataSet', labelsAndColors.map(l => l.color)]}
            position="groupName*value"
          />
          <Legend visible={false} />
        </Chart>
        {extra}
      </ChartRowContainer>
    </div>
  );
};

const labelsAndColors: { label: string; color: string }[] = [
  { label: 'Contacted', color: hexWithOpacity(SEQUOIA_GREEN, 0.15) },
  { label: 'Replied', color: hexWithOpacity(SEQUOIA_GREEN, 0.6) },
  { label: 'Replied Positively', color: SEQUOIA_GREEN },
];
