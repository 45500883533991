import { CopyOutlined, DeleteOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, notification, Popconfirm, Tooltip } from 'antd';
import React, { useContext } from 'react';
import styled from 'styled-components/macro';
import { OrganizationContext } from '../contexts/Organization';
import { useVisibleCampaigns } from '../hooks/useVisibleCampaigns';
import { EXTERNAL_RECIPIENT_VARIABLES } from '../shared/helpers';
import { makeRequest } from '../shared/Resource';
import { Colors } from '../shared/Theme';
import { ActionButton, SeqButton } from './Button';
import { DisplayOnHover, HoverableDiv } from './Common';
import { FastTable } from './FastTable';
import { PlainInput, PlainTextArea } from './Input';
const NEW_TEMPLATE_VARIABLE_KEY = 'new_variable';
const NEW_TEMPLATE_VARIABLE_VALUE = 'Enter value';

type EditInfo = {
  originalKey: string;
  originalValue: string;
  newKey: string;
  newValue: string;
};
const EMPTY_EDIT_INFO = { originalKey: '', newKey: '', originalValue: '', newValue: '' };

export const EditVariablesTable: React.FunctionComponent = () => {
  const {
    me,
    actions: { refresh },
  } = useContext(OrganizationContext);

  const campaigns = useVisibleCampaigns({ includeArchived: false });

  const [editInfo, setEditInfo] = React.useState<EditInfo>(EMPTY_EDIT_INFO);

  const edit = (record: string[]) => {
    setEditInfo({
      originalKey: record[0],
      originalValue: record[1],
      newKey: record[0],
      newValue: record[1],
    });
  };

  const cancel = () => {
    setEditInfo(EMPTY_EDIT_INFO);
  };

  const allUsesOfVar: { [varKey: string]: string[] } = {};
  for (const campaign of campaigns) {
    if (campaign.senderId !== me.id) {
      continue;
    }
    for (let ii = 0; ii < campaign.templates.length; ii++) {
      const t = campaign.templates[ii];
      for (let tv of t.requiredVariables) {
        allUsesOfVar[tv] = allUsesOfVar[tv] || [];
        allUsesOfVar[tv].push(`${campaign.name} - Email ${ii + 1}`);
      }
    }
  }

  const saveVariable = async () => {
    if (!editInfo.newKey) {
      return;
    }
    const body = {
      replaceKey: editInfo.originalKey,
      key: editInfo.newKey,
      value: editInfo.newValue,
    };
    await makeRequest<{}>(`/api/user/${me.id}/variable`, 'PUT', body);
    notification.success({
      message: `${editInfo.newKey} ${
        editInfo.originalKey === 'new_variable' ? 'added' : 'updated'
      } `,
    });
    setEditInfo(EMPTY_EDIT_INFO);
    refresh();
  };

  const deleteVariable = async (varKey: string) => {
    const body = {
      replaceKey: varKey,
      key: varKey,
      value: '',
    };
    await makeRequest<{}>(`/api/user/${me.id}/variable`, 'PUT', body);
    notification.success({ message: `${varKey} deleted` });
    setEditInfo(EMPTY_EDIT_INFO);
    refresh();
  };

  const isEditing = (key: string) => {
    return key === editInfo.originalKey;
  };

  const isUsedInTemplates = (key: string) => {
    return key in allUsesOfVar;
  };

  const isRecipientVariable = (name: string) => {
    const recipKeys = Object.keys(EXTERNAL_RECIPIENT_VARIABLES);
    return !recipKeys.includes(name);
  };

  const columns = [
    {
      title: 'Name',
      render: (variable: string[]) => {
        return (
          <HoverableDiv style={{ display: 'flex', alignItems: 'center' }}>
            <EditableCell
              singleLine={true}
              value={isEditing(variable[0]) ? editInfo.newKey : variable[0]}
              editing={isEditing(variable[0]) && !isUsedInTemplates(variable[0])}
              onChange={newKey => {
                setEditInfo({ ...editInfo, newKey: newKey });
              }}
            />
            <DisplayOnHover>
              <Button
                type={'link'}
                onClick={() => {
                  notification.success({ message: `${variable[0]} copied to clipboard` });
                  navigator.clipboard.writeText(`{{${variable[0]}}}`);
                }}
              >
                <CopyOutlined style={{ color: Colors.Static.SEQUOIA_GREEN }} />
              </Button>
            </DisplayOnHover>
          </HoverableDiv>
        );
      },
    },
    {
      title: 'Value',
      render: (variable: string[]) => (
        <EditableCell
          value={isEditing(variable[0]) ? editInfo.newValue : variable[1]}
          editing={isEditing(variable[0])}
          onChange={newValue => {
            setEditInfo({ ...editInfo, newValue: newValue });
          }}
        />
      ),
    },
    {
      title: 'Used In',
      render: (variable: string[]) => (
        <>
          {allUsesOfVar[variable[0]] && (
            <Tooltip title={(allUsesOfVar[variable[0]] || []).join(', ')}>
              <Button
                style={{
                  maxWidth: 130,
                  marginTop: 10,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >{`${(allUsesOfVar[variable[0]] || []).length} templates`}</Button>
            </Tooltip>
          )}
        </>
      ),
    },
    {
      title: 'Actions',
      width: 200,
      render: (variable: string[]) => {
        if (isRecipientVariable(variable[0])) {
          if (isEditing(variable[0])) {
            return (
              <ActionsContainer>
                <SeqButton onClick={() => cancel()} size="small">
                  Cancel
                </SeqButton>
                <SeqButton intent="primary" size="small" onClick={() => saveVariable()}>
                  <SaveOutlined /> Save
                </SeqButton>
              </ActionsContainer>
            );
          } else {
            return (
              <ActionsContainer>
                <ActionButton disabled={editInfo.originalKey !== ''} onClick={() => edit(variable)}>
                  EDIT
                </ActionButton>
                <Popconfirm
                  icon={<DeleteOutlined style={{ color: Colors.Static.DESTRUCTIVE_TINT }} />}
                  placement={'topLeft'}
                  title={'Are you sure you want to delete?'}
                  disabled={editInfo.originalKey !== '' || isUsedInTemplates(variable[0])}
                  onConfirm={() => deleteVariable(variable[0])}
                >
                  <ActionButton
                    disabled={editInfo.originalKey !== '' || isUsedInTemplates(variable[0])}
                  >
                    DELETE
                  </ActionButton>
                </Popconfirm>
              </ActionsContainer>
            );
          }
        } else {
          return <em>Based on candidate</em>;
        }
      },
    },
  ];

  const variables: { [key: string]: string } = Object.assign(
    {},
    EXTERNAL_RECIPIENT_VARIABLES,
    me.templateVariables || {}
  );

  if (editInfo.originalKey === NEW_TEMPLATE_VARIABLE_KEY) {
    variables[editInfo.originalKey] = editInfo.originalValue;
  }

  return (
    <div style={{ marginBottom: 20 }}>
      <FastTable
        dataSource={Object.entries(variables).sort((v1, v2) => {
          if (v1[0].toLowerCase() === NEW_TEMPLATE_VARIABLE_KEY) {
            return 1;
          }
          return v1[0].toLowerCase().localeCompare(v2[0].toLowerCase());
        })}
        rowHeight={60}
        rowKey={item => item[0]}
        columns={columns}
      />
      <SeqButton
        disabled={editInfo.originalKey !== ''}
        style={{ marginTop: 12 }}
        onClick={() => {
          setEditInfo({
            originalKey: NEW_TEMPLATE_VARIABLE_KEY,
            originalValue: NEW_TEMPLATE_VARIABLE_VALUE,
            newKey: NEW_TEMPLATE_VARIABLE_KEY,
            newValue: NEW_TEMPLATE_VARIABLE_VALUE,
          });
        }}
      >
        <span style={{ fontSize: 24, fontWeight: 400 }}>+</span>
        Add Variable
      </SeqButton>
    </div>
  );
};

const EditableCell: React.FunctionComponent<{
  value: string;
  editing: boolean;
  onChange: (newVal: string) => void;
  singleLine?: boolean;
}> = ({ value, editing, onChange, singleLine }) => {
  const [errorString, setErrorString] = React.useState<string>('');

  return editing ? (
    <div>
      {singleLine ? (
        <PlainInput
          value={value}
          style={{ flex: 1 }}
          onFocus={e => {
            if (e.currentTarget.value === NEW_TEMPLATE_VARIABLE_KEY) {
              e.currentTarget.setSelectionRange(0, e.currentTarget.value.length);
            }
          }}
          onChange={e => {
            onChange(e.currentTarget.value);
            if (!e.currentTarget.value) {
              setErrorString('Variable must have a key!');
            } else {
              setErrorString('');
            }
          }}
        />
      ) : (
        <PlainTextArea
          autoSize
          value={value}
          rows={1}
          style={{ flex: 1, marginLeft: -12, marginTop: -1 }}
          onFocus={e => {
            if (e.currentTarget.value === NEW_TEMPLATE_VARIABLE_VALUE) {
              e.currentTarget.setSelectionRange(0, e.currentTarget.value.length);
            }
          }}
          onChange={e => {
            onChange(e.currentTarget.value);
            if (!e.currentTarget.value) {
              setErrorString('Variable must have value!');
            } else {
              setErrorString('');
            }
          }}
        />
      )}
      <ErrorLabel>{errorString}</ErrorLabel>
    </div>
  ) : (
    <div style={{ overflowWrap: 'break-word', whiteSpace: 'pre-wrap' }}>{value}</div>
  );
};

const ErrorLabel = styled.div`
  color: ${Colors.Static.RED4};
  font-size: 12px;
`;

const ActionsContainer = styled.div`
  display: flex;
  gap: 16px;
`;
