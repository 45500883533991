import React, { useContext } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import {
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  useHistory,
  useRouteMatch,
} from 'react-router';
import { BaseContainer } from './components/Common';
import { FeatureSidebar, SequoiaBrand } from './components/navigation/FeatureSidebar';
import { PageLost } from './components/navigation/PageLost';
import { NetworkProvider } from './contexts/Network';
import { NotificationsProvider } from './contexts/Notification';
import { OrganizationContext, OrganizationProvider } from './contexts/Organization';
import * as DataDogMonitoring from './datadog';
import { ExtensionApp } from './extension/ExtensionApp';
import { isInternal } from './Helpers';
import { PageLeads } from './leads/PageLeads';
import { PageCampaignStatistics } from './management/PageCampaignStatistics';
import { PageCompaniesHome } from './management/PageCompaniesHome';
import { PageNetwork } from './network/PageNetwork';
import { PageExtensionDownload } from './onboarding/PageExtensionDownload';
import { PageExtensionGuide } from './onboarding/PageExtensionGuide';
import { PageOnboardingForm } from './onboarding/PageOnboardingForm';
import { PageOnboardingSetup } from './onboarding/PageOnboardingSetup';
import { PageCampaignDetails } from './pages/PageCampaignDetails';
import { PageCandidateUpload } from './pages/PageCandidateUpload';
import { PageListCampaigns } from './pages/PageListCampaigns';
import { PageTeam } from './pages/PageTeam';
import { PageCandidateImport } from './pipeline/PageCandidateImport';
import { PagePipeline } from './pipeline/PagePipeline';
import { PageReferralsConnections } from './referrals/PageReferralsConnections';
import { PageReferralsEmail } from './referrals/PageReferralsEmail';
import { PageReferralsRecommendations } from './referrals/PageReferralsRecommendations';
import { PageLoomResource } from './resources/PageLoomResource';
import { PageNotionResource } from './resources/PageNotionResource';
import { PageResources } from './resources/PageResources';
import { PageRoleAudit } from './role-audit/PageRoleAudit';
import { PageRoleAuditConfirmResend } from './role-audit/PageRoleAuditConfirmResend';
import { PageSettings } from './settings/PageSettings';
import { FriendlyErrorBoundary } from './shared/FriendlyErrorBoundary';
import { AppRootContainer } from './shared/ThemeManagement';
import { PageManageCampaigns } from './templates/PageManageCampaigns';

export const SEQUOIA_ORG_ID = 1;
export const MISSING_PERMISSIONS = 'missing-permissions';
export const AMPERSAND_USER_NAME = 'Ampersand User';

export const App: React.FunctionComponent<{}> = () => {
  const history = useHistory();

  React.useEffect(() => {
    if (window.location.href.includes('sequoiacap.com')) {
      const version = document.getElementById('root')?.dataset.datadogRelease;
      if (version) {
        DataDogMonitoring.initializeDataDog(version);
      }
    }
  }, [history]);

  return (
    <Switch>
      <Route path="/sourcing/candidates/upload/:hash" component={PageCandidateUpload} />
      <Route path="/extension" component={ExtensionApp} />
      <Route path="/role-audit-resend/:hash/:secret?" component={PageRoleAuditConfirmResend} />
      <Route path="/role-audit/:hash" component={PageRoleAudit} />
      <Route path="/:organizationId/singleuse">
        <BaseContainer>
          <SequoiaBrand style={{ position: 'absolute', top: 24, left: 24 }} />
          <Route
            path={`/:organizationId/singleuse/sheet/:sheetHash/review/:sheetName?`}
            component={PageReferralsRecommendations}
          />
        </BaseContainer>
      </Route>

      <AppRootContainer disableDarkMode={true}>
        <OrganizationProvider>
          <NotificationsProvider>
            <NetworkProvider>
              <DndProvider backend={HTML5Backend}>
                <AuthenticatedApp />
              </DndProvider>
            </NetworkProvider>
          </NotificationsProvider>
        </OrganizationProvider>
      </AppRootContainer>
    </Switch>
  );
};

export const AuthenticatedApp: React.FunctionComponent<{}> = () => {
  const { me, features } = useContext(OrganizationContext);

  return (
    <Switch>
      <Route path="/onboarding" component={Onboarding} />
      <FeatureSidebar>
        <FriendlyErrorBoundary description="Sequoia Talent Suite">
          <Switch>
            {isInternal(me) && (
              <Route path="/companies/:organizationId?" component={PageCompaniesHome} />
            )}
            {isInternal(me) && (
              <Route path="/campaign-statistics/:campaignId?" component={PageCampaignStatistics} />
            )}
            <Route path="/:organizationId/templates" component={PageManageCampaigns} />
            <Route path="/:organizationId/campaigns" component={Sourcing} />
            {features.includes('referrals') && (
              <Route path="/:organizationId/referrals" component={Referrals} />
            )}
            <Route path="/:organizationId/network" component={PageNetwork} />
            <Route path="/:organizationId/pipeline" component={Pipeline} />
            <Route
              path={`/:organizationId/leads/import-candidates`}
              component={PageCandidateImport}
            />
            <Route path="/:organizationId/leads" component={PageLeads} />
            <Route path="/:organizationId/team" component={PageTeam} />
            <Route path="/:organizationId/resources" component={Resources} />
            <Route path="/:organizationId/settings" component={PageSettings} />
            <Route path="/:organizationId/home" exact component={RedirectToOrgHome} />

            <Redirect
              path="/:organizationId/talent-leads/import-candidates"
              to="/:organizationId/leads/import-candidates"
            />
            <Redirect path="/:organizationId/talent-leads" to="/:organizationId/leads" />

            <Route path="/sourcing" exact component={RedirectToOrgHome} />
            <Route path="/home" exact component={RedirectToOrgHome} />
            <Route path="/" exact component={RedirectToOrgHome} />
            <Route component={PageLost} />
          </Switch>
        </FriendlyErrorBoundary>
      </FeatureSidebar>
    </Switch>
  );
};

const RedirectToOrgHome: React.FunctionComponent<RouteComponentProps> = props => {
  const { id: orgId, me, features } = useContext(OrganizationContext);
  const qs = new URLSearchParams(props.location.search);
  const missingPermissions = qs.get(MISSING_PERMISSIONS);
  if (missingPermissions) window.localStorage.setItem(MISSING_PERMISSIONS, missingPermissions);

  return !me.identifier || !me.name ? (
    <Redirect to="/onboarding" />
  ) : me.internalTalentId && orgId === SEQUOIA_ORG_ID ? (
    <Redirect to="/companies" />
  ) : features.includes('leads') ? (
    <Redirect to={`/${orgId}/leads`} />
  ) : (
    <Redirect to={`/${orgId}/campaigns`} />
  );
};

function Onboarding() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}`} exact component={PageOnboardingForm} />
      <Route path={`${path}/setup`} exact component={PageOnboardingSetup} />
      <Route path={`${path}/extension`} component={PageExtensionDownload} />
      <Route path={`${path}/extension-guide`} component={PageExtensionGuide} />
    </Switch>
  );
}

function Pipeline() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/import-candidates`} component={PageCandidateImport} />
      <Route path={`${path}/search`} exact component={PagePipeline} />
      <Redirect key={'searchRedirect'} path={path} to={`${path}/search`} />
      <Redirect to={path} />
    </Switch>
  );
}

//This is what used to be the network-miner
function Referrals() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/sheet/:sheetHash/email/:sheetName`} component={PageReferralsEmail} />
      <Route
        path={`${path}/sheet/:sheetHash/review/:sheetName`}
        component={PageReferralsRecommendations}
      />
      <Route path={`${path}/sheet/:sheetHash/:sheetName`} component={PageReferralsConnections} />
      <Redirect to={path} />
    </Switch>
  );
}

function Sourcing() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route strict exact path={`${path}/sender/:senderId`} component={PageListCampaigns} />

      <Route
        path={`${path}/sender/:senderId/campaigns/:campaignId`}
        component={PageCampaignDetails}
      />
      <Route path={path} exact component={PageListCampaigns} />
      <Redirect to={path} />
    </Switch>
  );
}

function Resources() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route strict path={`${path}/v/:loomId`} component={PageLoomResource} />
      <Route strict path={`${path}/n/:pageId`} component={PageNotionResource} />
      <Route path={path} exact component={PageResources} />
      <Redirect to={path} />
    </Switch>
  );
}
