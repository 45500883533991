import React, { useEffect } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { logPageView } from '../Helpers';
import { SeqButton } from '../components/Button';
import { CampaignInfoCharts } from '../components/CampaignInfoCharts';
import { CampaignsTable } from '../components/CampaignsTable';
import { HeaderRow, PageContainer } from '../components/Common';
import { DebouncedSearchInput } from '../components/Input';
import { OrganizationContext } from '../contexts/Organization';
import { useVisibleCampaigns } from '../hooks/useVisibleCampaigns';
import { usePageTitle } from '../shared/Common';
import { useQueryPersistedState } from '../shared/useQueryPersistedState';

export const PageListCampaigns = (props: RouteComponentProps<{ senderId?: string }>) => {
  const { id: organizationId, me, actions, all, id } = React.useContext(OrganizationContext);
  const senderId = Number(props.match.params.senderId || 0); // home case for external founders
  usePageTitle('Sourcing', false, { all, id });
  const [showArchive, setShowArchive] = React.useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useQueryPersistedState<string>({
    queryKey: 'q',
    defaults: { q: '' },
  });
  const campaigns = useVisibleCampaigns({
    includeArchived: showArchive,
    includeActiveOrganizationOnly: true,
  });
  const filteredCampaigns = campaigns
    .filter(c => !searchTerm || c.name.toLowerCase().includes(searchTerm.toLowerCase()))
    .sort((a, b) => a.name.localeCompare(b.name));
  useEffect(() => {
    logPageView(me, 'campaigns');
  }, [me]);
  return (
    <PageContainer key={`list-campaigns-${senderId}`}>
      <CampaignHeaderRow
        archiveConfig={{ showArchive, onClickShowArchive: () => setShowArchive(prev => !prev) }}
        manageConfig={{ organizationId }}
        search={{ value: searchTerm, onChange: setSearchTerm }}
      />
      <CampaignInfoCharts campaigns={filteredCampaigns} />
      <CampaignsTable campaigns={filteredCampaigns} onCampaignChanged={actions.refresh} />
    </PageContainer>
  );
};

export const CampaignHeaderRow: React.FunctionComponent<{
  manageConfig?: {
    organizationId: number;
    templateId?: number;
  };
  archiveConfig?: {
    showArchive: boolean;
    onClickShowArchive: () => void;
  };
  search: {
    value: string;
    onChange: (val: string) => void;
    placeholder?: string;
  };
  extra?: React.ReactNode;
}> = ({ manageConfig, archiveConfig, search, extra }) => {
  const history = useHistory();
  return (
    <HeaderRow>
      <DebouncedSearchInput placeholder="Search campaigns" {...search} />
      {manageConfig && (
        <SeqButton
          onClick={() => {
            history.push(
              `/${manageConfig.organizationId}/templates${
                manageConfig.templateId ? `/?id=${manageConfig.templateId}` : ''
              }`
            );
          }}
        >
          Manage Campaigns
        </SeqButton>
      )}
      {archiveConfig && (
        <SeqButton
          intent={archiveConfig.showArchive ? 'inverted' : 'secondary'}
          onClick={() => {
            archiveConfig.onClickShowArchive();
          }}
        >
          {archiveConfig.showArchive ? 'Hide' : 'Show'} Archive
        </SeqButton>
      )}
      {extra}
    </HeaderRow>
  );
};
