import { CSSProperties, Fragment, useContext } from 'react';
import { Link } from 'react-router-dom';
import { IconButton } from '../components/Button';
import { CountWithIcon, ListSubheader, Row } from '../components/Common';
import { SeqModal } from '../components/Modal';
import { PopoverDeleteButton } from '../components/Popover';
import { Profile } from '../components/Profile';
import { MinerSheetStatus, ViewMinerSheetButton } from '../components/profile-drawer/useNetworkRow';
import { Body2Serif, H3Mono } from '../components/Text';
import { OrganizationContext } from '../contexts/Organization';
import { Dot } from '../shared/Common';
import { makeRequest, useResource } from '../shared/Resource';
import { ScrollingContainer } from '../shared/ScrollableContainer';
import { TrashIcon } from '../shared/Svgs';
import { Colors } from '../shared/Theme';

interface ManageNetworksModalProps {
  networkOwnersInfo: NetworkAPI.NetworkRes['owners'];
  visible: boolean;
  onClose: () => void;
  refreshNetwork: () => void;
}

const { BLACK5ALPHA, BLACK7ALPHA } = Colors.Static;

export const ManageNetworksModal = ({
  networkOwnersInfo,
  visible,
  onClose,
  refreshNetwork,
}: ManageNetworksModalProps) => {
  const { id } = useContext(OrganizationContext);
  const [orgSheets = {}] = useResource<NetworkAPI.NetworkOrgSheetRes>(
    `/api/${id}/network/org-sheets`
  );
  const { firstDegreeIds, secondDegreeIds, profiles } = networkOwnersInfo;

  const deleteNetwork = async (hash: string) => {
    const res = await makeRequest<{ success: boolean }>(
      `/api/${id}/miner-sheets/${hash}`,
      'DELETE'
    );
    if (res.success) refreshNetwork();
  };

  const buildOwnerSheetRow = (ownerId: number, canDelete: boolean, style?: CSSProperties) => {
    const minerSheet = orgSheets[ownerId];
    const ownerProfile = profiles[ownerId];

    return (
      <Row
        style={{
          alignItems: 'center',
          gap: 12,
          padding: '6px 0',
          borderBottom: `1px solid ${BLACK5ALPHA}`,
          ...style,
        }}
      >
        <Profile profile={ownerProfile} size="small" />
        <div
          style={{
            display: 'flex',
            flex: 1,
            alignItems: 'center',
            justifyContent: 'flex-end',
            gap: 2,
          }}
        >
          {minerSheet && (
            <>
              <div style={{ display: 'flex', justifyContent: 'flex-end', minWidth: 120 }}>
                <MinerSheetStatus status={minerSheet.status} />
              </div>
              <Dot color={BLACK7ALPHA} />
              <CountWithIcon
                count={minerSheet.connectionCount}
                icon={{ src: '/icons/person-filled.svg', alt: 'person' }}
                style={{ opacity: 0.8 }}
              />
              <Dot color={BLACK7ALPHA} />
              <ViewMinerSheetButton orgId={id} hash={minerSheet.hash} />
            </>
          )}
        </div>
        <div style={{ width: 26 }}>
          {canDelete && (
            <PopoverDeleteButton itemName="network" onDelete={() => deleteNetwork(minerSheet.hash)}>
              <IconButton size="small">
                <TrashIcon />
              </IconButton>
            </PopoverDeleteButton>
          )}
        </div>
      </Row>
    );
  };

  return (
    <SeqModal
      width={800}
      height="90vh"
      open={visible}
      onClose={onClose}
      styles={{ body: { padding: '24px 0 24px 24px', gap: 12 } }}
    >
      <H3Mono>Manage Networks</H3Mono>
      <Body2Serif style={{ marginRight: 24 }}>
        View or delete networks in your organization. In order to add new networks, you can invite
        your team <Link to={`/${id}/team`}>Team</Link>. You can also search for any of your
        first-degree LinkedIn connections in the Founder tools or in the extension and click{' '}
        <strong>Add Network</strong>.
      </Body2Serif>
      <ScrollingContainer style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        <ListSubheader>First-Degree Networks</ListSubheader>
        {firstDegreeIds.map((ownerId, idx) => (
          <Fragment key={`first-${idx}`}>
            {buildOwnerSheetRow(
              ownerId,
              false,
              idx === firstDegreeIds.length - 1 ? { borderBottom: 'unset' } : undefined
            )}
          </Fragment>
        ))}
        <ListSubheader>Second-Degree Networks</ListSubheader>
        {secondDegreeIds.map((ownerId, idx) => (
          <Fragment key={`second-${idx}`}>{buildOwnerSheetRow(ownerId, true)}</Fragment>
        ))}
      </ScrollingContainer>
    </SeqModal>
  );
};
