import { notification } from 'antd';
import { omit } from 'lodash';
import moment from 'moment';
import { match } from 'react-router-dom';
import { SelectOption, SelectProps } from './components/selects/Select';
import { createEmailPreview, isValidEmail } from './shared/helpers';
import { getNameParts } from './shared/profile-helpers';
import { makeRequest } from './shared/Resource';

export const organizationIdFromRoute = (props: { match: match<{ organizationId: string }> }) => {
  return Number(props.match.params.organizationId);
};

export const isInternal = (me: ExternalAPI.User) => {
  return !!me.internalTalentId;
};

export const isStoppedBounce = (c: { stoppedReason?: string }) => {
  return c.stoppedReason && c.stoppedReason.toLowerCase().includes('bounced');
};

export const isStoppedNoEmail = (c: { stoppedReason?: string }) => {
  return c.stoppedReason && c.stoppedReason.toLowerCase().includes('no email address');
};

export const canManageCampaign = (me: ExternalAPI.User, campaign: { sender: ExternalAPI.User }) => {
  return (
    !!me.internalTalentId ||
    me.id === campaign.sender.id ||
    (me.organizationId === campaign.sender.organizationId && campaign.sender.allowOthersToSend)
  );
};

export const canReviewCandidates = (
  me: ExternalAPI.Me,
  team: ExternalAPI.User[],
  campaign: { senderId: number }
) => {
  return (
    me.id === campaign.senderId ||
    team.some(tm => tm.allowOthersToSend && tm.id === campaign.senderId)
  );
};

export const extractTwitterHandle = (url?: string | null) => {
  if (!url) {
    return;
  }
  const match = url.match(/(?:https?:\/\/)?(?:www\.)?twitter\.com\/(?:#!\/)?@?([^/?\s]*)/);
  return match && match[1];
};

export const extractGithubUsername = (url?: string | null) => {
  if (!url) {
    return;
  }
  const match = url.match(/(?:https?:\/\/)?(?:www\.)?github\.com\/(?:#!\/)?@?([^/?\s]*)/);
  return match && match[1];
};

export const onlyLettersNumbersAndPunctuation = (inp: string) => {
  return inp.replace(/[^\p{L}\p{N}\p{P}\p{Z}]/gu, '');
};

export const shortenedName = (name?: string) => {
  if (!name) return '';
  const { first, rest } = getNameParts(name);
  return onlyLettersNumbersAndPunctuation(first && rest ? `${first} ${rest[0]}` : name);
};

export const getRoleOptions = (roles: OrganizationAPI.Role[]): SelectProps['options'] => {
  const activeOptions = roles.reduce(
    (acc, { id, name, deletedAt }) => (!deletedAt ? acc.concat({ id, name }) : acc),
    [] as SelectOption[]
  );
  return activeOptions.length === roles.length
    ? activeOptions
    : [
        { name: 'Active', options: activeOptions },
        {
          name: 'Archive',
          options: roles.reduce(
            (acc, { id, name, deletedAt }) => (deletedAt ? acc.concat({ id, name }) : acc),
            [] as SelectOption[]
          ),
        },
      ];
};

export const errorForCampaign = (
  req: CampaignAPI.Campaign,
  variables: { [key: string]: string }
): string | undefined => {
  if (!req.senderId || req.senderId <= 0) {
    return 'Choose a from address before saving.';
  }

  if (!req.roleId) {
    return 'Choose a role for candidates in this campaign.';
  }

  if (!req.name) {
    return 'Campaign must have a name.';
  }

  if (!req.settings.subject) {
    return 'Campaign must have a subject line.';
  }

  if (req.settings.cc.some(email => !isValidEmail(email))) {
    return `One or more CC'd email address(es) is invalid.`;
  }

  if (req.settings.bcc.some(email => !isValidEmail(email))) {
    return `One or more BCC'd email address(es) is invalid.`;
  }

  if (req.templates.length < 1 || req.templates.length > 10) {
    return 'Campaign must have between 1 and 3 templates.';
  }

  if (req.templates[0].delay.value !== 0) {
    return 'First template cannot have a delay.';
  }

  if (req.templates.some((t, idx) => idx > 0 && t.delay.value === 0)) {
    return 'All templates besides the first must have a delay.';
  }

  for (let ii = 0; ii < req.templates.length; ii++) {
    const template = req.templates[ii];
    if (!template.body || template.body.replace(/<div>|<\/div>|<br>/g, '').length === 0) {
      return `Email ${ii + 1} has no content in the email body.`;
    }

    const bodyPreview = createEmailPreview(template.body, req.settings.subject, variables);
    if (bodyPreview.body.match(/\{\{(.*?)\}\}/)) {
      return `There are undefined variable names used in email ${ii + 1}.`;
    }
    if (bodyPreview.subject.match(/\{\{(.*?)\}\}/)) {
      return `There are undefined variable names used in the campaign subject.`;
    }
  }

  return;
};

export async function onProfileUpsert<T extends ExternalAPI.Profile>(
  profile: { id: number; organizationId: number },
  change: Partial<T>,
  onSave?: (profile: T) => void,
  message?: string
) {
  const res = await makeRequest<{ profile?: T; message?: string }>(
    `/api/${profile.organizationId}/profile`,
    profile.id === -1 ? 'POST' : 'PUT',
    { ...(profile.id === -1 ? profile : { id: profile.id }), ...change }
  );
  if (res.profile) {
    if (message) {
      notification.success({ message, placement: 'bottomRight' });
    }

    if (res.message) {
      notification.info({ message: res.message, placement: 'bottomRight' });
    }

    onSave?.(res.profile);
  }
}

interface BulkUpdateProfilesProps {
  orgId: number;
  ids: number[];
  update: ExternalAPI.MultiProfileUpdateReq['update'];
  onSaved: (profiles: NetworkAPI.NetworkProfile[]) => void;
  silenceNotif?: boolean;
}

export async function onBulkUpdateProfiles({
  orgId,
  ids,
  update,
  onSaved,
  silenceNotif = false,
}: BulkUpdateProfilesProps) {
  const res = await makeRequest<{ profiles: NetworkAPI.NetworkProfile[]; message: string }>(
    `/api/${orgId}/profiles`,
    'PUT',
    { ids, update } satisfies ExternalAPI.MultiProfileUpdateReq
  );

  if (res.profiles) {
    if (!silenceNotif) notification.success({ message: res.message, placement: 'bottomRight' });
    onSaved(res.profiles);
  }
}

export const onEmailUpdate = async (
  updatedBy: string,
  profile: ExternalAPI.Profile,
  newEmail: string,
  onSaved: (profile: ExternalAPI.Profile) => void
) => {
  const candidateName = shortenedName(profile.name);
  if (isValidEmail(newEmail)) {
    await onProfileUpsert(
      profile,
      {
        email: newEmail.toLowerCase(),
        activities: [
          {
            user: updatedBy,
            explanation: `updated ${candidateName}'s email to ${newEmail}`,
            createdAt: new Date(),
          },
        ],
      },
      onSaved
    );
  }
};

export const onStatusUpdate = async (
  profile: ExternalAPI.Profile,
  status: PipelineAPI.ProfileStatus,
  currUser: { id: number; name: string },
  opts: { onSaved?: (profile: ExternalAPI.Profile) => void; silent?: boolean }
) => {
  const candidateShortenedName = shortenedName(profile.name);
  const change: Pick<ExternalAPI.Profile, 'status' | 'activities' | 'ownerId'> = {
    status,
  };
  const activity = {
    user: currUser.name,
    createdAt: new Date(),
    explanation: `updated ${candidateShortenedName}'s status to ${status}`,
  };
  if (change.status === 'Hired' && (!profile.ownerId || profile.ownerId !== currUser.id)) {
    change.ownerId = currUser.id;
    activity.explanation = `${activity.explanation} and owner to ${shortenedName(currUser.name)}`;
  }
  change.activities = [activity];

  await onProfileUpsert(
    profile,
    change,
    opts.onSaved,
    opts.silent
      ? undefined
      : `${candidateShortenedName}'s status ${
          change.ownerId ? 'and owner have' : 'has'
        } been updated.`
  );
};

export const getProfileName = (profiles: { id: number; name: string }[], profileId: number) =>
  profiles?.find(({ id }) => profileId === id)?.name || '';

export const monitoredStatuses: PipelineAPI.ProfileStatus[] = ['Interviewing', 'Offer'];

export const lastStaleUpdateFor = (p: Pick<ExternalAPI.Profile, 'activities' | 'status'>) => {
  const lastStatusUpdate: PipelineAPI.Activity | undefined =
    p.activities &&
    p.activities
      .slice()
      .reverse()
      .find(a => a.explanation.includes(' status to '));

  return lastStatusUpdate &&
    monitoredStatuses.includes(p.status!) &&
    moment().diff(lastStatusUpdate.createdAt, 'days', false) > 6
    ? lastStatusUpdate
    : false;
};

export const getHireLock = (myId: number, ownerId?: number, status?: PipelineAPI.ProfileStatus) =>
  status === 'Hired' && ownerId !== myId;

//Profiles cannot be modified in miner-sheet views, but are large, remove before PUT
export const cleanMinerSheetForUpload = (
  sheetIn: NetworkMinerAPI.MinerSheetWithProfiles
): NetworkMinerAPI.MinerSheet => {
  return omit(sheetIn, ['profiles', 'senderCompany']);
};

export const logActivity = async (
  me: ExternalAPI.UserWithCampaigns,
  info: ExternalAPI.ActivityType
) => {
  if (me.internalTalentId) return;
  await makeRequest(`/api/analytics`, 'POST', { info });
};

export const logPageView = async (
  me: ExternalAPI.UserWithCampaigns,
  pageName: ExternalAPI.PageView['pageName']
) => {
  await logActivity(me, { type: 'page-view', pageName });
};
