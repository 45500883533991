import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Button, Tag } from 'antd';
import React from 'react';
import { HorizontalDivider, PageContainerWithBackNav } from '../components/Common';
import { H2Mono } from '../components/Text';
import { EditOrgModal } from '../components/modals/EditOrgModal';
import { EditTeamModal } from '../components/modals/EditTeamModal';
import { usePageTitle } from '../shared/Common';
import { Colors } from '../shared/Theme';
import { CompanyMembersTable } from './CompanyMembersTable';
import { getSequoiaUserTitle } from './PageCompaniesHome';

const { SEQUOIA_BLACK, SEQUOIA_LIGHT_TEXT, SEQUOIA_GREEN, SEQUOIA_BRIGHT_RED } = Colors.Static;

export const PageCompanyDetails: React.FC<{
  organization: OrganizationAPI.OrganizationWithUsers;
  sequoiaPOC: ExternalAPI.User | undefined;
  refresh: () => void;
}> = ({ organization, sequoiaPOC, refresh }) => {
  usePageTitle(`${organization.name}`, false);

  return (
    <PageContainerWithBackNav backTo={{ pageName: 'Companies Home', link: '/companies' }}>
      <H2Mono style={{ marginTop: 0, display: 'flex', alignItems: 'center' }}>
        <div style={{ display: 'flex', flex: 1, alignItems: 'center' }}>
          {organization.name}
          <EditOrgModal
            organization={organization}
            onSaved={() => refresh()}
            button={
              <Button type={'link'} style={{ marginLeft: 8, padding: 0 }}>
                <img src="/icons/edit-pencil.svg" alt="pencil" style={{ width: 20 }} />
              </Button>
            }
          />
        </div>
      </H2Mono>
      <HorizontalDivider />
      <div style={{ display: 'flex', marginTop: 14, marginBottom: 14 }}>
        <div style={{ flexGrow: 1 }}>
          <div style={{ fontSize: 14, color: SEQUOIA_LIGHT_TEXT, alignSelf: 'flex-end' }}>
            <div>
              Sequoia POC:
              <span style={{ fontWeight: 500, color: SEQUOIA_BLACK, marginLeft: 8 }}>
                {getSequoiaUserTitle(sequoiaPOC) || '--'}
              </span>
            </div>
            <div style={{ marginTop: 12, display: 'flex', alignItems: 'center' }}>
              Features:
              <div style={{ marginLeft: 8 }}>
                {organization.features.length
                  ? organization.features.map((f, i) => (
                      <Tag key={i} color={SEQUOIA_GREEN}>
                        {f}
                      </Tag>
                    ))
                  : '--'}
              </div>
            </div>
            <div style={{ marginTop: 12, display: 'flex', alignItems: 'center' }}>
              {organization.settings.canLeaveFeedback ? (
                <CheckCircleOutlined style={{ color: SEQUOIA_GREEN, marginRight: 8 }} />
              ) : (
                <CloseCircleOutlined style={{ color: SEQUOIA_BRIGHT_RED, marginRight: 8 }} />
              )}
              Can Leave Feedback
            </div>
          </div>
        </div>
        <div style={{ alignSelf: 'flex-end' }}>
          <EditTeamModal organization={organization} refresh={refresh} />
        </div>
      </div>
      <CompanyMembersTable organization={organization} refresh={refresh} />
    </PageContainerWithBackNav>
  );
};
