import { LoadingOutlined } from '@ant-design/icons';
import { notification, Switch } from 'antd';
import { pick } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components/macro';
import { OrganizationContext } from '../../contexts/Organization';
import { logPageView } from '../../Helpers';
import { toLinkedinID, toLinkedinURL } from '../../shared/li-utils';
import { InitialsOrProfileImage } from '../../shared/ProfileImage';
import { makeRequest } from '../../shared/Resource';
import { ScrollingContainer } from '../../shared/ScrollableContainer';
import { DefaultButtonCSS, SecondaryButtonCSS, SeqButton } from '../Button';
import { LightTooltip } from '../Common';
import { SeqInput } from '../Input';
import { SeqModal } from '../Modal';
import { H2Mono, H3Mono } from '../Text';

const EVENT_TYPE = 'show-account-settings';

const State: { openForUserId: number | null } = { openForUserId: null };

export function showAccountSettingsModal(userId: number) {
  State.openForUserId = userId;
  document.dispatchEvent(new CustomEvent(EVENT_TYPE));
}

function closeAccountSettingsModal() {
  State.openForUserId = null;
  document.dispatchEvent(new CustomEvent(EVENT_TYPE));
}

export const AccountSettingsModal: React.FunctionComponent = () => {
  const session = useContext(OrganizationContext);
  const [userId, setUserId] = useState(State.openForUserId);

  useEffect(() => {
    const handler = () => setUserId(State.openForUserId);
    document.addEventListener(EVENT_TYPE, handler);
    return () => {
      document.removeEventListener(EVENT_TYPE, handler);
    };
  });

  const onClose = () => {
    closeAccountSettingsModal();
    logPageView(session.me, 'settings');
    session.actions.refresh();
  };

  const onKeyDown = (e: React.KeyboardEvent<any> | KeyboardEvent) => {
    if (e.key === 'Escape') onClose();
  };

  useEffect(() => {
    document.body.addEventListener('keydown', onKeyDown);
    return () => {
      document.body.removeEventListener('keydown', onKeyDown);
    };
  });

  const user = [session.me, ...session.team].find(u => u.id === userId);

  return (
    <SeqModal onClose={onClose} width={800} open={!!user}>
      {user ? (
        <MyAccountTab onClose={onClose} user={user} onRefresh={session.actions.refresh} />
      ) : (
        <div />
      )}
    </SeqModal>
  );
};

const MyAccountTab: React.FunctionComponent<{
  user: ExternalAPI.UserWithCampaigns;
  onClose: () => void;
  onRefresh: () => Promise<any>;
}> = ({ user, onClose, onRefresh }) => {
  const history = useHistory();

  const { features } = useContext(OrganizationContext);

  const [accountState, setAccountState] = useState<{
    name: string;
    identifier: string;
    allowOthersToSend: boolean;
    settings: ExternalAPI.UserSettings;
  }>({
    ...pick(user, ['name', 'email', 'allowOthersToSend', 'settings']),
    identifier: toLinkedinURL(user.identifier),
  });
  const [fileInputKey, setFileInputKey] = useState<number>(0);
  const [photoUploading, setPhotoUploading] = useState(false);
  const [updating, setUpdating] = useState(false);

  const onClickUpdate = async () => {
    if (!accountState.name || accountState.name.split(' ').length < 2) {
      notification.warning({ message: 'You must provide a first name and last name' });
      return;
    }

    if (accountState.identifier && !toLinkedinID(accountState.identifier)) {
      notification.warning({ message: 'Invalid LinkedIn URL, please try again.' });
      return;
    }
    setUpdating(true);
    const userUpdate: ExternalAPI.UpsertUserRequest = {
      id: user.id,
      email: user.email,
      sendInvite: false,
      name: accountState.name.trim(),
      identifier: toLinkedinID(accountState.identifier),
      allowOthersToSend: accountState.allowOthersToSend,
      settings: accountState.settings,
    };
    const response = await makeRequest<ExternalAPI.User | { error: string }>(
      '/api/user',
      'PUT',
      userUpdate
    );
    setUpdating(false);

    if ('id' in response) {
      notification.success({ message: 'Profile updated' });
      onClose();
    } else {
      notification.error({
        message: response.error || 'Could not update profile, please try again.',
      });
    }
  };

  const onDeleteAccount = async () => {
    if (
      !window.confirm(
        `Are you sure you want to delete ${user.name}'s account? Your email data and account will be deleted and you will be signed out.`
      )
    ) {
      return;
    }
    setUpdating(true);
    const resp = await makeRequest<{ success?: boolean }>(`/api/user/${user.id}`, 'DELETE');
    if (resp?.success) {
      window.location.reload();
    } else {
      notification.error({
        message: 'Account could not be deleted. Please contact an administrator.',
      });
    }
  };

  const onClickUploadPhoto = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.length) {
      const raw = e.target.files[0];
      if (raw.size > 500000) {
        notification.error({ message: 'Please choose an image file less than 500Kb in size.' });
        setFileInputKey(k => k + 1);
        return;
      }
      const formData = new FormData();
      formData.append(`profilePic`, raw);

      setPhotoUploading(true);
      const response = await fetch(`/api/user/${user.id}/profile-picture`, {
        method: 'PUT',
        credentials: 'include',
        body: formData,
      });
      if (response.status === 200) {
        await onRefresh();
      } else {
        notification.error({ message: 'Image upload failed, please try again.' });
        setFileInputKey(k => k + 1);
      }
      setPhotoUploading(false);
    }
  };

  return (
    <div style={{ padding: 24 }}>
      <ScrollingContainer>
        <H2Mono>My Account</H2Mono>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 24, padding: '16px 0' }}>
          <div style={{ display: 'flex', gap: 48, alignItems: 'center' }}>
            <div style={{ flex: 1, display: 'flex', flexDirection: 'column', gap: 16 }}>
              <SeqInput
                title="Name"
                value={accountState.name}
                onChange={e => {
                  setAccountState({ ...accountState, name: e.currentTarget.value });
                }}
                placeholder={'Enter your name'}
              />
              <SeqInput
                title="LinkedIn Profile"
                value={accountState.identifier}
                onChange={e => {
                  setAccountState({ ...accountState, identifier: e.currentTarget.value });
                }}
                placeholder={'Url for your personal LinkedIn page'}
              />
              <LightTooltip
                placement={'topLeft'}
                overlay={
                  'Email address is used to provide your authorization to use this platform and cannot be modified.'
                }
              >
                <div>
                  <SeqInput
                    title="Email"
                    disabled={true}
                    value={user.email}
                    placeholder={'Enter your email address'}
                  />
                </div>
              </LightTooltip>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyItems: 'center',
                gap: 12,
              }}
            >
              {photoUploading ? (
                <LoadingOutlined style={{ marginTop: 20, marginLeft: 10, fontSize: 40 }} spin />
              ) : (
                <InitialsOrProfileImage widthHeight={80} user={user} />
              )}
              <div>
                <label htmlFor={'special'}>
                  <LabelAsButton>Upload Photo</LabelAsButton>
                </label>
                <input
                  key={`profile-pic-input-${fileInputKey}`}
                  onChange={onClickUploadPhoto}
                  accept=".jpg,.jpeg,.png"
                  id={'special'}
                  style={{ display: 'none' }}
                  type={'file'}
                />
              </div>
            </div>
          </div>
        </div>
        {features.includes('campaigns') && (
          <div style={{ display: 'flex', alignItems: 'center', paddingBottom: 12, marginRight: 6 }}>
            <div style={{ flex: 1 }}>
              <H3Mono>Permissions</H3Mono>
              <SwitchContainer>
                <Switch
                  size="small"
                  checked={accountState.allowOthersToSend}
                  onChange={e => setAccountState(a => ({ ...a, allowOthersToSend: e }))}
                />
                <div>Allow teammates to send emails on your behalf</div>
              </SwitchContainer>
            </div>
          </div>
        )}
        <H3Mono>Advanced</H3Mono>
        <div style={{ display: 'flex', gap: 4 }}>
          <SeqButton
            size="small"
            onClick={() => history.push('/onboarding/extension')}
            style={{ marginTop: 0 }}
          >
            Download Extension
          </SeqButton>
          {!user.internalTalentId && (
            <SeqButton size="small" onClick={onDeleteAccount} style={{ marginTop: 0 }}>
              Delete my Account
            </SeqButton>
          )}
        </div>
      </ScrollingContainer>
      <div style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: 16 }}>
        <SeqButton onClick={onClose} style={{ marginRight: 12 }}>
          Cancel
        </SeqButton>
        <SeqButton loading={updating} onClick={onClickUpdate} intent="primary">
          Update
        </SeqButton>
      </div>
    </div>
  );
};

export const SwitchContainer = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;

const LabelAsButton = styled.div`
  ${DefaultButtonCSS}
  ${SecondaryButtonCSS}
`;
