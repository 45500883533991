import { notification, Switch } from 'antd';
import { capitalize, isEqual } from 'lodash';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { HorizontalDivider } from '../../components/Common';
import { FEATURE_OPTIONS } from '../../management/FeatureDropdown';
import { makeRequest } from '../../shared/Resource';
import { Colors } from '../../shared/Theme';
import { SeqButton } from '../Button';
import { PlainInput } from '../Input';
import { DIALOG_MASK_STYLE, SeqModal } from '../Modal';
import { SourcingPOCSelect } from '../selects/TeamMemberSelect';
import { SeqCheckbox } from '../SeqCheckbox';
import { H3Mono } from '../Text';

const { SEQUOIA_LIGHT_GRAY, SEQUOIA_LIGHT_TEXT } = Colors.Static;

export const EditOrgModal: React.FC<{
  organization: OrganizationAPI.OrganizationWithUsers | OrganizationAPI.CreateOrganizationRequest;
  onSaved: (id: number) => void;
  button: React.ReactElement;
}> = ({ organization, onSaved, button }) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<OrganizationAPI.CreateOrganizationRequest>({
    name: organization.name,
    managerTalentId: organization.managerTalentId,
    settings: organization.settings,
    features: organization.features,
  });

  const unchangedName = !inputValue.name.length || inputValue.name.trim() === organization.name;
  const unchangedSourcer = inputValue.managerTalentId === organization.managerTalentId;
  const unchangedSettings = isEqual(inputValue.settings, organization.settings);
  const unchangedFeatures =
    organization.features.every(feature => inputValue.features.includes(feature)) &&
    organization.features.length === inputValue.features.length;

  useEffect(() => {
    setInputValue({
      name: organization.name,
      managerTalentId: organization.managerTalentId,
      settings: organization?.settings,
      features: organization.features,
    });
  }, [setInputValue, organization]);

  const onUpdate = async () => {
    let response: OrganizationAPI.Organization | { error: string };
    if ('id' in organization) {
      const update: OrganizationAPI.UpdateOrganizationRequest = {};
      if (!unchangedName) update.name = inputValue.name.trim();
      if (!unchangedSourcer) update.managerTalentId = inputValue.managerTalentId;
      if (!unchangedFeatures) update.features = inputValue.features;
      if (!unchangedSettings) update.settings = inputValue.settings;
      response = await makeRequest<OrganizationAPI.Organization>(
        `/api/organization/${organization.id}`,
        'PUT',
        update
      );
    } else {
      if (!inputValue.name) {
        notification.warning({ message: 'A company must have a name.' });
        return;
      }
      if (!inputValue.managerTalentId) {
        notification.warning({ message: 'A company must have a Sequoia POC.' });
        return;
      }
      response = await makeRequest<OrganizationAPI.Organization | { error: string }>(
        `/api/organization`,
        'POST',
        {
          ...inputValue,
        }
      );
    }
    if ('id' in response) {
      onSaved(response.id);
      setVisible(false);
      notification.success({
        message: 'id' in organization ? 'Company updated.' : 'Company added.',
      });
    } else if ('error' in response) {
      notification.warning({ message: response.error });
    }
  };

  return (
    <>
      {React.cloneElement(button, { onClick: () => setVisible(!visible) })}
      <SeqModal
        open={visible}
        style={{ top: '7.5vh', bottom: '7.5vh' }}
        styles={{ mask: DIALOG_MASK_STYLE }}
        width={900}
        footer={null}
        closable={false}
        title={null}
        onClose={() => setVisible(!visible)}
      >
        <div
          style={{ position: 'relative', padding: 24 }}
          onKeyDown={e => {
            if (e.key === 'Escape') setVisible(!visible);
          }}
        >
          <H3Mono>{'id' in organization ? 'Edit' : 'Add'} Company</H3Mono>
          <HorizontalDivider />
          <FieldContainer>
            <InputLabel>Name</InputLabel>
            <PlainInput
              autoFocus
              placeholder="Company name"
              value={inputValue.name || ''}
              onChange={e => {
                setInputValue({ ...inputValue, name: e.currentTarget.value.toString() });
              }}
            />
          </FieldContainer>
          <FieldContainer>
            <InputLabel>Sequoia POC</InputLabel>
            <SourcingPOCSelect
              value={inputValue.managerTalentId}
              style={{
                marginLeft: 0,
                marginTop: '15px',
                border: `1px solid ${SEQUOIA_LIGHT_GRAY}`,
                width: '100%',
              }}
              onChange={({ internalTalentId }) =>
                setInputValue({ ...inputValue, managerTalentId: internalTalentId! })
              }
            />
          </FieldContainer>
          <FieldContainer>
            <InputLabel>Feature Access</InputLabel>
            <div style={{ fontSize: 14, display: 'flex', flexWrap: 'wrap' }}>
              {FEATURE_OPTIONS.map(({ feature }) => (
                <div key={feature} style={{ display: 'flex', width: '40%', marginTop: 5 }}>
                  <SeqCheckbox
                    checked={inputValue.features.includes(feature)}
                    onChange={e => {
                      const checked = e.target.checked;
                      const newFeaturesArr = checked
                        ? [...inputValue.features, feature]
                        : inputValue.features.filter(f => f !== feature);
                      setInputValue({ ...inputValue, features: [...newFeaturesArr] });
                    }}
                    color={SEQUOIA_LIGHT_TEXT}
                  >
                    <div style={{ userSelect: 'none' }}>{capitalize(feature)}</div>
                  </SeqCheckbox>
                </div>
              ))}
            </div>
          </FieldContainer>
          <div
            style={{
              flexDirection: 'column',
              display: 'flex',

              paddingBottom: 12,
              marginRight: 6,
            }}
          >
            <div style={{ flex: 1 }}>
              <InputLabel>Can Leave Feedback</InputLabel>
              <Switch
                style={{ marginTop: '5px', marginBottom: '5px' }}
                onChange={newVal =>
                  setInputValue({
                    ...inputValue,
                    settings: { ...inputValue.settings, canLeaveFeedback: !!newVal },
                  })
                }
                checked={inputValue.settings.canLeaveFeedback}
              />
            </div>
          </div>
          <HorizontalDivider />
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 15 }}>
            <SeqButton onClick={() => setVisible(!visible)} style={{ marginRight: 12 }}>
              Cancel
            </SeqButton>
            <SeqButton
              intent="primary"
              onClick={onUpdate}
              disabled={unchangedName && unchangedSourcer && unchangedSettings && unchangedFeatures}
            >
              Update
            </SeqButton>
          </div>
        </div>
      </SeqModal>
    </>
  );
};

const FieldContainer = styled.div`
  margin-bottom: 20px;
`;

const InputLabel = styled.div`
  margin-bottom: 8px;
  margin-top: 16px;
`;
