import { datadogRum } from '@datadog/browser-rum';
import React from 'react';
import { Colors } from './Theme';

// BG Note: This doesn't seem to play super nicely with react-hot-reloader
// in dev mode, which seems to force-re renders and then displays it's own
// error UI. Maybe we just use this in prod if it's a pain.

export class FriendlyErrorBoundary extends React.Component<
  { description: string },
  { error: Error | null }
> {
  state = { error: null } as { error: Error | null };

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { error };
  }

  componentDidCatch(error: Error, errorInfo: any) {
    // You can also log the error to an error reporting service
    // Note: Sentry may be blocked by an ad-blocker, can't assume it's available
    if (process.env.NODE_ENV === 'production') {
      datadogRum.addError(error, { boundary: this.props.description });
    }
  }

  render() {
    const { error } = this.state;

    if (error) {
      return (
        <div
          style={{
            width: '100%',
            height: '100%',
            flex: 1,
            border: `1px solid ${Colors.Static.SEQUOIA_GREEN}`,
            background: 'white',
            borderRadius: 3,
            color: '#222',
            padding: 15,
          }}
        >
          <h3>Sorry, {this.props.description} can't be displayed.</h3>
          <p style={{ color: Colors.Static.GRAY4 }}>
            Details about this error have been sent to the dev team, refresh the page to try again.
          </p>
          {process.env.NODE_ENV === 'development' && (
            <code>
              <pre>{`${error}`}</pre>
            </code>
          )}
        </div>
      );
    }

    return this.props.children;
  }
}
