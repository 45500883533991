import { CloseOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import {
  EditableExcludeRulesMap,
  EditableRuleIds,
  EditorTooltip,
  invalid,
  onChangeWithValidation,
  RuleEditorProps,
  useDescription,
  valid,
  ValidationInfo,
} from '../rule-editors/RuleEditorCommon';
import { Colors } from '../Theme';
import { EditorInput, Explanatory, HeaderRow, REGEX_EXPLANATION, Rule } from './RuleEditorCommon';

export const HasDegreeInEditor: React.FC<RuleEditorProps> = ({
  rule,
  onChange,
  style,
  title,
  ruleType,
}) => {
  const replaceParam = (value: string | null, index: number) => {
    if (value === null) {
      onChangeWithValidation(
        { ...rule, parameters: rule.parameters.filter((_, idx) => idx !== index) },
        onChange,
        validate
      );
    } else {
      const replaced = [...rule.parameters];
      replaced[index] = value;
      onChangeWithValidation({ ...rule, parameters: replaced }, onChange, validate);
    }
  };

  const keywords = [...rule.parameters.slice(1)];
  if (keywords.every(word => !!word)) {
    keywords.push('');
  }

  const desc = useDescription(
    { rule, ruleType },
    {
      include: 'List college degrees which should boost a person:',
      exclude: 'List college degrees which should exclude a person:',
      invert: 'All people will be excluded unless they have a degree in any of:',
    }
  );

  return (
    <div style={style}>
      {title && <HeaderRow label={title} onChange={onChange} rule={rule} />}
      <Explanatory>{desc}</Explanatory>
      <div style={{ marginLeft: 15, marginTop: 20 }}>
        {keywords.map((keyword, index) => (
          <div key={`keyword-${index}`} style={{ display: 'flex', alignItems: 'center' }}>
            <EditorInput
              placeholder={'Enter a keyword'}
              style={{ width: 380, marginBottom: 10 }}
              value={keyword}
              onChange={e => replaceParam(e.currentTarget.value || null, index + 1)}
            />
            {!!keyword && (
              <Button
                tabIndex={-1}
                style={{ color: Colors.Static.SEQUOIA_BRIGHT_RED }}
                onClick={() => replaceParam(null, index + 1)}
                type={'link'}
              >
                <CloseOutlined style={{ marginLeft: 20 }} />
              </Button>
            )}
            {index === 0 && <EditorTooltip text={REGEX_EXPLANATION} />}
          </div>
        ))}
      </div>
    </div>
  );
};

const validate = (rule: Rule): ValidationInfo => {
  const ruleLabel = EditableExcludeRulesMap[rule.id as EditableRuleIds]?.label || '';
  if (!rule.parameters?.length || rule.parameters?.every(p => !p)) {
    return invalid(`'${ruleLabel}' requires at least one job title to be entered.`);
  }
  console.log('Parameters is ' + JSON.stringify(rule.parameters));
  return valid();
};
