import { uniqBy } from 'lodash';
import moment from 'moment';
import { nameTitleOrCompanyMatches } from './profile-helpers';
import { getUndeletedTags } from './tags-helpers';

/**
 * Code here is concerned with function and value, not appearance. Do not put react nodes or
 * rendering functions here, only manipulate, aggregate and transform.
 */
export interface OptionWithChildren {
  id: number;
  name: string;
  matches?: number;
  children?: OptionWithChildren[];
}

export interface ReferralsFilters {
  locations: string[];
  roles: string[];
  rolesInverted?: string[];
  nameOrTitle: string;
  exec?: string;
  workEduOverlap?: SchoolWorkInfo[];
  workEduOverlapNamesInverted: string[];
}

export type FilterOn = [keyof ReferralsFilters, ReferralsFilters];

type GroupedMinerSheet = NetworkMinerAPI.MinerSheet | StatsAPI.MinerSheet;

export function filterFunctionFromReferralFilters(filter: ReferralsFilters) {
  return (profile: NetworkMinerAPI.MinerProfileSummary | CoreAPI.ProfileWithXylem) => {
    const wantedOverlap = (filter.workEduOverlap || []).filter(
      w => !filter.workEduOverlapNamesInverted?.includes(w.name)
    );
    const unwantedOverlap = (filter.workEduOverlap || []).filter(w =>
      filter.workEduOverlapNamesInverted?.includes(w.name)
    );

    if (wantedOverlap.length && !wantedOverlap.some(o => o.ids.includes(profile.id))) {
      return false;
    }
    if (unwantedOverlap.length && unwantedOverlap.some(o => o.ids.includes(profile.id))) {
      return false;
    }

    if (!nameTitleOrCompanyMatches(profile, filter.nameOrTitle)) {
      return false;
    }

    const tags = ('functions' in profile ? profile.functions : getUndeletedTags(profile.tags)).map(
      t => t.toLowerCase()
    );
    if (
      (filter.exec === 'Exec' && !tags.includes('executive')) ||
      (filter.exec === 'Non-exec' && tags.includes('executive'))
    ) {
      return false;
    }

    const matchesAnyLocation =
      !filter.locations.length ||
      filter.locations.some(l => {
        if ('locationObj' in profile && profile.locationObj) {
          const { continent, country, state } = profile.locationObj;
          return l === continent || l === country || l === state;
        }
        return false;
      });

    if (!matchesAnyLocation) {
      return false;
    }

    const wantedRoles = filter.roles.filter(f => !filter.rolesInverted?.includes(f));
    const unwantedRoles = filter.roles.filter(f => filter.rolesInverted?.includes(f));

    if (unwantedRoles.length && unwantedRoles.some(r => tags.includes(r.toLowerCase()))) {
      return false;
    }
    if (wantedRoles.length && !wantedRoles.some(r => tags.includes(r.toLowerCase()))) {
      return false;
    }

    return true;
  };
}

export function countNumOfMatches(
  profiles: NetworkMinerAPI.MinerProfileSummary[],
  filter: FilterOn,
  optionName: string
): number {
  return profiles.filter(
    filterFunctionFromReferralFilters({
      ...filter[1],
      [filter[0]]: filter[0] === 'exec' ? optionName : [optionName],
    })
  ).length;
}

export function optionsFromTags(
  profiles: NetworkMinerAPI.MinerProfileSummary[],
  filter: FilterOn,
  tagsIn: ConfigJSON.PeopleTagSet
): OptionWithChildren[] {
  const selectOptions = Object.keys(tagsIn).map((k, idx) => {
    const mSubTags = tagsIn[k].subtags;
    const countMatches: number = countNumOfMatches(profiles, filter, k);
    return {
      id: idx,
      name: k,
      matches: countMatches,
      children: mSubTags ? optionsFromTags(profiles, filter, mSubTags) : undefined,
    } satisfies OptionWithChildren;
  });
  return filterOutOptionsWithNoMatch(selectOptions);
}

const filterOutOptionsWithNoMatch = (options?: OptionWithChildren[]): OptionWithChildren[] => {
  if (!options) {
    return [];
  }
  let optionsNew = options.filter(o => !!o.matches);
  optionsNew = optionsNew.map(o => ({ ...o, children: filterOutOptionsWithNoMatch(o.children) }));
  return optionsNew;
};

export type SchoolWorkInfo = {
  name: string;
  type: 'school' | 'work';
  ids: number[];
  dates: string;
};

// Note: This function returns one entry for each named place. If a person worked at
// "Cornell University" and studied at "Cornell University", it's one item in the results
// with type=work and the ids + dates combined. This is because the downstream filtering
// logic only looks at the name.
export function sliceProfilesByEduWork(
  target: { details: CoreAPI.ProfileDetails } | undefined,
  profiles: { id: number; details: CoreAPI.ProfileDetails }[]
): SchoolWorkInfo[] {
  if (!target || !target.details) {
    return [];
  }
  const { education = [], work = [] } = target.details;
  const uqSchools: { name: string; lirID?: string }[] = uniqBy(
    education.map(s => s.school).filter(school => !!school.name),
    s => s.lirID || s.name
  );
  const uqCompanies: { name: string; lirID?: string }[] = uniqBy(
    work.map(w => w.company).filter(company => !!company.name),
    s => s.lirID || s.name
  );

  const results: SchoolWorkInfo[] = [];
  const now = Date.now();
  const append = (name: string, dates: string, ids: number[], type: 'school' | 'work') => {
    const item = results.find(n => n.name === name);
    if (item) {
      item.dates = `${item.dates}, ${dates}`;
      item.ids.push(...ids);
    } else {
      results.push({ name, dates, ids, type });
    }
  };

  uqCompanies.forEach(co => {
    const entries = work.filter(w => workEduEqual(w.company, co));
    const start = Math.min(...entries.map(e => e.startTimestamp));
    const end = Math.max(...entries.map(e => e.endTimestamp || now));
    const dates = `${moment(start).format('YYYY')} - ${
      end === now ? 'Present' : moment(end).format('YYYY')
    }`;
    const ids = profiles
      .filter(p => p.details.work?.some(w => workEduEqual(w.company, co)))
      .map(p => p.id);

    append(co.name, dates, ids, 'work');
  });

  uqSchools.forEach(school => {
    const entries = education.filter(edu => workEduEqual(edu.school, school));
    const ids = profiles
      .filter(p => p.details.education?.some(edu => workEduEqual(edu.school, school)))
      .map(p => p.id);

    append(school.name, entries[0]?.years || '', ids, 'school');
  });

  return results.sort((a, b) => b.ids.length - a.ids.length);
}

function workEduEqual(e1: { lirID?: string; name: string }, e2: { lirID?: string; name: string }) {
  return (!!e1.lirID && e1.lirID === e2.lirID) || e1.name?.toLowerCase() === e2.name?.toLowerCase();
}

export function groupSheetsByStep(sheets: GroupedMinerSheet[]) {
  const inProgress = sheets
    .filter(({ status }) => status.percent < 100 || status.error)
    .sort(
      ({ status: aStatus }, { status: bStatus }) =>
        Number(!!aStatus.error) - Number(!!bStatus.error) || bStatus.percent - aStatus.percent
    );
  const toFilterAndSend = sheets.filter(s => !s.sentAt && !inProgress.includes(s));
  const waitingOn = sheets.filter(s => s.sentAt && !s.targetCompletedAt);
  const completed = sheets.filter(s => s.sentAt && s.targetCompletedAt);

  return {
    inProgress,
    toFilterAndSend,
    waitingOn,
    completed,
  };
}
