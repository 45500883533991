import { ColumnsType } from 'antd/lib/table';
import { IconLinkButton } from '../components/Button';
import { customSortableColumn } from '../components/SeqTable';
import { compactLocationText } from '../shared/helpers';
import { bestRole, sanitizeProfileName } from '../shared/profile-helpers';

const isMobile = window.innerWidth < 500;

export const MinerProfileNameColumn: ColumnsType<NetworkMinerAPI.MinerProfileSummary>[0] = {
  title: customSortableColumn<NetworkMinerAPI.MinerProfileSummary>('person'),
  key: 'person',
  width: isMobile ? undefined : 240,
  render: (_, profile) => {
    return (
      <div
        style={{
          gap: 6,
          display: 'flex',
          minHeight: 42,
          alignItems: 'center',
          whiteSpace: 'nowrap',
        }}
      >
        <IconLinkButton type="linkedIn" link={profile.identifier} size="small" />
        <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {sanitizeProfileName(profile.name)}
        </span>
      </div>
    );
  },
  defaultSortOrder: 'ascend',
  sorter: (a: NetworkMinerAPI.MinerProfileSummary, b: NetworkMinerAPI.MinerProfileSummary) =>
    sanitizeProfileName(a.name.toLowerCase()).localeCompare(
      sanitizeProfileName(b.name.toLowerCase())
    ),
};

export const MinerProfileTitleColumn: ColumnsType<NetworkMinerAPI.MinerProfileSummary>[0] = {
  title: customSortableColumn<ExternalAPI.Profile>('title'),
  key: 'title',
  render: (_, profile) => {
    const role = bestRole(profile);
    return <div>{role ? role.title : profile.title}</div>;
  },
  sorter: (a: NetworkMinerAPI.MinerProfileSummary, b: NetworkMinerAPI.MinerProfileSummary) => {
    const aRole = bestRole(a);
    const bRole = bestRole(b);
    return (aRole ? aRole.title : a.title)
      .toLowerCase()
      .localeCompare((bRole ? bRole.title : b.title).toLowerCase());
  },
};

export const MinerProfileCompanyColumn: ColumnsType<NetworkMinerAPI.MinerProfileSummary>[0] = {
  key: 'company',
  title: customSortableColumn<ExternalAPI.Profile>('company'),
  width: 220,
  render: (_, profile) => {
    const role = bestRole(profile);
    return <div>{role ? role.company.name : '—'}</div>;
  },
  sorter: (a: NetworkMinerAPI.MinerProfileSummary, b: NetworkMinerAPI.MinerProfileSummary) => {
    const aRole = bestRole(a);
    const bRole = bestRole(b);
    if (!aRole) {
      return -1;
    } else if (!bRole) {
      return 1;
    }
    const aCompany = aRole.company.name.toLowerCase();
    const bCompany = bRole.company.name.toLowerCase();
    return aCompany.localeCompare(bCompany);
  },
};

export const MinerProfileLocationColumn: ColumnsType<NetworkMinerAPI.MinerProfileSummary>[0] = {
  title: customSortableColumn<NetworkMinerAPI.MinerProfileSummary>('location'),
  key: 'location',
  width: 220,
  sorter: (a: NetworkMinerAPI.MinerProfileSummary, b: NetworkMinerAPI.MinerProfileSummary) => {
    return a.location.toLowerCase().localeCompare(b.location.toLowerCase());
  },
  render: (_, a) => compactLocationText(a.location || ''),
};
