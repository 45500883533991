import { Checkbox } from 'antd';
import React from 'react';
import { makeRequest } from '../shared/Resource';
import { SeqButton } from './Button';
import { Dialog } from './Common';
import { SeqModal } from './Modal';

export const ConfirmArchiveModal: React.FunctionComponent<{
  campaign?: CampaignAPI.Campaign;
  onClose: (reloadCampaign?: boolean) => void;
}> = ({ campaign, onClose }) => {
  const visible = !!campaign;
  const [archiving, setArchiving] = React.useState<boolean>(false);
  const [stopFollowups, setStopFollowups] = React.useState<boolean>(false);

  if (!campaign) {
    return <></>;
  }

  const onArchive = async () => {
    setArchiving(true);

    campaign.archivedAt = new Date();
    if (stopFollowups) {
      campaign.stopped = true;
    }

    const campaignResponse = await makeRequest<CampaignAPI.Campaign>(
      `/api/sourcing/campaign/${campaign.id}`,
      'PUT',
      campaign
    );
    setArchiving(false);
    if (campaignResponse.id) {
      onClose(true);
    }
  };

  const footer = (
    <div style={{ display: 'flex', justifyContent: 'flex-end', gap: 8 }}>
      <SeqButton onClick={() => onClose(false)}>Cancel</SeqButton>
      <SeqButton intent="inverted" loading={archiving} onClick={onArchive}>
        {archiving ? 'Archiving ' : 'Archive'}
      </SeqButton>
    </div>
  );

  const content = (
    <div style={{ marginTop: 16 }}>
      <div>
        Are you sure you want to archive <span style={{ fontWeight: 500 }}>{campaign.name}</span>?
      </div>
      <Checkbox
        checked={stopFollowups}
        onChange={e => {
          setStopFollowups(e.target.checked);
        }}
        style={{ marginTop: 15 }}
      >
        Stop all follow-ups in campaign
      </Checkbox>
    </div>
  );

  return (
    <SeqModal open={visible} onClose={() => onClose(false)}>
      <Dialog header={`Archive ${campaign.name}`} content={content} footer={footer} />
    </SeqModal>
  );
};
