import { Menu } from 'antd';
import { pick } from 'lodash';
import { default as React } from 'react';
import { SeqButton, SeqDropdownButton } from '../components/Button';
import { ScheduledSendButton } from '../components/ScheduledSendButton';
import { H4Mono, Label } from '../components/Text';
import { useCampaignConfig } from '../hooks/useCampaignConfig';
import { FadeInContainer } from '../shared/Common';
import { pluralize } from '../shared/helpers';
import { CandidateWithDetails, LeadListWithReviewStatus } from './PageLeads';

export const LeadListFooter: React.FC<{
  leadList: LeadListWithReviewStatus;
  profiles: CandidateWithDetails[];
  onUpdate: (update: Omit<LeadsAPI.UpdateLeadList | LeadsAPI.BulkReview, 'id'>) => Promise<void>;
  onStartCarousel: () => void;
}> = ({ leadList, profiles, onUpdate, onStartCarousel }) => {
  const unreviewedCount = profiles.filter(p => !p.review).length;
  const { organizationId, roleId, campaignId } = leadList;
  const { campaign, campaignSelect } = useCampaignConfig({
    feature: 'leads',
    organizationId,
    roleId,
    campaignId,
  });

  const acceptAll = async (deliverAt: Date) => {
    if (campaign) {
      await onUpdate({
        campaignId: campaign.id,
        roleId: campaign.roleId,
        reviewedProfiles: profiles.map(p => ({
          ...pick(p, ['internalId', 'externalId']),
          email: p.profile.email,
          review: p.profile.email ? { status: 'approved' } : { status: 'rejected' },
          overrides: { deliverAt },
        })),
      });
    }
  };

  const menu = (
    <Menu style={{ width: 350, display: 'flex', flexDirection: 'column', gap: 16, padding: 16 }}>
      <H4Mono>Accept All</H4Mono>
      <div style={{ display: 'flex', alignItems: 'center', gap: 12 }}>
        <Label>Campaign:</Label>
        {campaignSelect({ type: 'secondary', optionsPlacement: 'topRight' })}
      </div>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
        <ScheduledSendButton
          campaign={campaign}
          onSend={acceptAll}
          disabled={!campaign}
          defaultToLastUsed
        />
      </div>
    </Menu>
  );

  const buttonContent = (
    <FadeInContainer>
      {unreviewedCount ? '' : 'Re-'}Review{' '}
      {pluralize(unreviewedCount || profiles.length, 'profile')}
    </FadeInContainer>
  );

  return (
    <div style={{ display: 'flex', marginTop: 24, justifyContent: 'flex-end' }}>
      {unreviewedCount ? (
        <SeqDropdownButton
          type="primary"
          overlay={menu}
          onClick={e => {
            e.currentTarget.blur();
            onStartCarousel();
          }}
        >
          {buttonContent}
        </SeqDropdownButton>
      ) : (
        <SeqButton intent="primary" onClick={onStartCarousel}>
          {buttonContent}
        </SeqButton>
      )}
    </div>
  );
};
