import { notification, Switch } from 'antd';
import { default as React, useContext, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components/macro';
import { SeqButton } from '../components/Button';
import { Dialog } from '../components/Common';
import { SeqModal } from '../components/Modal';
import { SwitchContainer } from '../components/modals/AccountSettingsModal';
import { Select, SelectOption, SelectProps } from '../components/selects/Select';
import { Body2Serif, Body3, CondensedTextCSS } from '../components/Text';
import { EMPTY_NETWORK_OWNERS, NetworkContext } from '../contexts/Network';
import { OrganizationContext } from '../contexts/Organization';
import { shortenedName } from '../Helpers';
import { makeRequest } from '../shared/Resource';
import { Colors } from '../shared/Theme';
import { ManageNetworksModal } from './ManageNetworksModal';
import {
  NETWORK_OWNER_ADD_TEAM_MEMBER_ID,
  NETWORK_OWNER_MANAGE_NETWORKS_ID,
  NETWORK_OWNER_SELECT_ALL_ID,
  NETWORK_OWNER_SELECT_FIRST_DEGREE_ID,
  NETWORK_OWNER_SELECT_MANAGE_COHORT,
  NETWORK_OWNER_SELECT_SECOND_DEGREE_ID,
  NETWORK_OWNER_SELECT_SHARED_ID,
} from './NetworkFiltering';

export const NetworkOwnerSelect: React.FC<{
  value?: number;
  onChange: (ownerId: number) => void;
}> = ({ value, onChange }) => {
  const { me } = useContext(OrganizationContext);
  const { network, networkOps } = useContext(NetworkContext);

  const history = useHistory();
  const cohortKey = network?.owners.cohortKey;
  const [showManageCohortModal, setShowManageCohortModal] = useState(
    !!me.isFounder && !!cohortKey && !('networkSharedWithCohort' in me.settings)
  );
  const [showManageNetworksModal, setShowManageNetworksModal] = useState(false);

  const onDeselect = React.useCallback(
    (ownerId: number) => {
      if (ownerId === value) {
        onChange(NETWORK_OWNER_SELECT_ALL_ID);
      }
    },
    [onChange, value]
  );

  const networkOwners = useMemo(() => network?.owners || EMPTY_NETWORK_OWNERS, [network]);
  const networkOwnerOptions = useMemo(
    () => buildNetworkOwnerOptions({ owners: networkOwners, onDeselect }),
    [networkOwners, onDeselect]
  );

  return (
    <>
      <ManageNetworksModal
        networkOwnersInfo={networkOwners}
        visible={showManageNetworksModal}
        onClose={() => setShowManageNetworksModal(false)}
        refreshNetwork={networkOps.refresh}
      />
      <ManageCohortModal
        visible={showManageCohortModal}
        onClose={() => setShowManageCohortModal(false)}
        cohortKey={cohortKey}
      />
      <Select
        style={{ background: Colors.Static.SEQUOIA_PAPER }}
        selected={value || NETWORK_OWNER_SELECT_ALL_ID}
        type="secondary"
        options={networkOwnerOptions}
        onSelect={id => {
          if (id) {
            if (id === NETWORK_OWNER_SELECT_MANAGE_COHORT) {
              setShowManageCohortModal(true);
            } else if (id === NETWORK_OWNER_MANAGE_NETWORKS_ID) {
              setShowManageNetworksModal(true);
            } else if (id === NETWORK_OWNER_ADD_TEAM_MEMBER_ID) {
              history.push('team');
            } else {
              onChange(id);
            }
          }
        }}
      />
    </>
  );
};

const ManageCohortModal: React.FC<{
  cohortKey?: string;
  visible: boolean;
  onClose: () => void;
}> = ({ cohortKey, visible, onClose }) => {
  const { me } = useContext(OrganizationContext);
  const [saving, setSaving] = useState(false);
  const [sharing, setSharing] = useState(me.settings.networkSharedWithCohort || false);

  const onSave = async () => {
    if (!cohortKey) {
      return;
    }
    setSaving(true);
    const res = await makeRequest<{ message: string } | { error: string }>(
      `/api/user/${me.id}/network-sharing`,
      'PUT',
      { sharing, userId: me.id } satisfies ExternalAPI.CohortUserUpdate
    );
    setSaving(false);

    if ('error' in res) {
      notification.error({ message: res.error });
      return;
    }
    notification.success({
      message: 'Settings updated. It may take a few minutes for company networks to appear.',
    });
    onClose();
  };

  return (
    <SeqModal width={700} open={visible} onClose={onClose}>
      <Dialog
        header="Expand your Network"
        content={
          <div>
            <img
              src={require('../images/arrow-multidirectional.png').default}
              style={{ width: 200, float: 'right', paddingLeft: 24 }}
              alt="arrows"
            />
            <div style={{ display: 'flex', flexDirection: 'column', marginTop: 24, gap: 12 }}>
              <Body2Serif>
                The network tab makes it easy to see your team's LinkedIn connections to find
                potential candidates and sales leads.
              </Body2Serif>
              <Body2Serif>
                Share your personal network with your <strong>{cohortKey}</strong> cohort to
                supercharge search and see how other founders could and see opportunities for warm
                intros from other founders.
              </Body2Serif>
              <Body2Serif>
                <em>You can change this setting later from the Networks dropdown.</em>
              </Body2Serif>
              <SwitchContainer style={{ paddingTop: 6 }}>
                <Switch disabled={saving} checked={sharing} onChange={setSharing} />
                <strong>Share your personal network with your cohort</strong>
              </SwitchContainer>
            </div>
          </div>
        }
        footer={
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <SeqButton intent="primary" onClick={onSave}>
              Continue
            </SeqButton>
          </div>
        }
      />
    </SeqModal>
  );
};

const buildNetworkOwnerOptions = ({
  owners,
  onDeselect,
}: {
  owners: NetworkAPI.NetworkRes['owners'];
  onDeselect: (ownerId: number) => void;
}) => {
  const networkOwnerOptionAcc = (acc: SelectOption[], ownerId: number) => {
    const owner = owners.profiles[ownerId];
    acc.push({
      id: ownerId,
      name: shortenedName(owner?.name),
      customRender: () => (
        <div
          onClick={() => onDeselect(owner.id)}
          style={{
            display: 'flex',
            width: '100%',
            fontWeight: 400,
            alignItems: 'baseline',
            gap: 4,
          }}
        >
          <div
            style={{
              flex: 1,
              display: 'flex',
              flexBasis: 'auto',
              whiteSpace: 'nowrap',
              minWidth: 0,
              gap: 4,
            }}
          >
            {shortenedName(owner?.name)}
            {owner?.founderOrgName && (
              <>
                <div style={{ fontSize: 16 }}>|</div>
                <OrgName>{owner?.founderOrgName}</OrgName>
              </>
            )}
          </div>
        </div>
      ),
    });
    return acc;
  };

  const first = owners.firstDegreeIds.reduce(networkOwnerOptionAcc, [] satisfies SelectOption[]);
  const second = owners.secondDegreeIds.reduce(networkOwnerOptionAcc, [] satisfies SelectOption[]);
  const shared = owners.sharedIds.reduce(networkOwnerOptionAcc, [] satisfies SelectOption[]);

  first.push({
    id: NETWORK_OWNER_ADD_TEAM_MEMBER_ID,
    name: 'Add Team Member',
    customRender: () => <MiscSelectOptionButton>Add Team Member…</MiscSelectOptionButton>,
  });

  second.push({
    id: NETWORK_OWNER_MANAGE_NETWORKS_ID,
    name: 'Manage Networks',
    customRender: () => <MiscSelectOptionButton>Manage Networks…</MiscSelectOptionButton>,
  });

  let options: SelectProps['options'] = [
    { id: NETWORK_OWNER_SELECT_ALL_ID, name: `${second.length ? 'All' : 'Team'} Networks` },
  ];

  const nestedOptions = [
    {
      name: 'First-Degree Network',
      options: [{ id: NETWORK_OWNER_SELECT_FIRST_DEGREE_ID, name: 'All First-Degree' }, ...first],
    },
    {
      name: 'Second-Degree Network',
      options: [
        { id: NETWORK_OWNER_SELECT_SECOND_DEGREE_ID, name: 'All Second-Degree' },
        ...second,
      ],
    },
  ];

  if (shared.length) {
    nestedOptions.push({
      name: 'Shared Network',
      options: [{ id: NETWORK_OWNER_SELECT_SHARED_ID, name: 'All Shared' }, ...shared],
    });
  }

  options = options.concat(nestedOptions);

  if (owners.cohortKey) {
    options = options.concat([
      {
        id: NETWORK_OWNER_SELECT_MANAGE_COHORT,
        name: 'Manage Cohort Settings',
        customRender: () => (
          <MiscSelectOptionButton>Manage Cohort Settings…</MiscSelectOptionButton>
        ),
      },
    ]);
  }

  return options;
};

const OrgName = styled(Body3)`
  color: ${Colors.Static.BLACK5ALPHA};
  word-spacing: initial;
  text-transform: initial;
  letter-spacing: 0.02em;
  ${CondensedTextCSS}
`;

const MiscSelectOptionButton = styled(Body3)`
  color: ${Colors.Static.SEQUOIA_DARK_GREEN};
  font-weight: 500;
  word-spacing: initial;
  text-transform: initial;
  letter-spacing: 0.02em;
  line-height: 18px;
`;
