import { default as React, useContext, useMemo } from 'react';
import { PageContainer } from '../components/Common';
import { TabPane, Tabs } from '../components/Tabs';
import { OrganizationContext } from '../contexts/Organization';
import { useResource } from '../shared/Resource';
import { ScrollingContainer } from '../shared/ScrollableContainer';
import { useQueryPersistedState } from '../shared/useQueryPersistedState';
import { ExportTab } from './ExportTab';
import { RolesTab } from './RolesTab';
import { SETab } from './SETab';
import { StatusesTab } from './StatusesTab';
import { TagsTab } from './TagsTab';
import { VariablesTab } from './VariablesTab';

type SettingsTabs = 'Roles' | 'Tags' | 'Variables' | 'Export' | 'Candidate Filters' | 'Stages';

export const PageSettings: React.FC = () => {
  const { features } = useContext(OrganizationContext);
  const [tab, setTab] = useQueryPersistedState<SettingsTabs>({
    queryKey: 'tab',
    defaults: { tab: 'Roles' },
  });

  const canViewCandidateFilters = useCandidateFiltersPermission();

  return (
    <PageContainer>
      <Tabs active={tab} onChange={setTab}>
        <TabPane label={'Roles'} overrideKey="Roles">
          <RolesTab />
        </TabPane>
        <TabPane label={'Tags'} overrideKey={'Tags'}>
          <ScrollingContainer>
            <TagsTab />
          </ScrollingContainer>
        </TabPane>
        <TabPane label={'Stages'} overrideKey="Stages">
          <StatusesTab />
        </TabPane>
        <TabPane
          labelStyle={{ display: features.includes('campaigns') ? undefined : 'none' }}
          label={'Variables'}
          overrideKey="Variables"
        >
          <VariablesTab />
        </TabPane>
        <TabPane label={'Export'} overrideKey="Export">
          <ExportTab />
        </TabPane>
        {canViewCandidateFilters ? (
          <TabPane label={'Candidate Filters'} overrideKey="Candidate Filters">
            <ScrollingContainer minHeight={'calc(100vh - 400px)'}>
              <SETab />
            </ScrollingContainer>
          </TabPane>
        ) : (
          <></>
        )}
      </Tabs>
    </PageContainer>
  );
};

export const useCandidateFiltersPermission = (forRole?: number) => {
  const { id: orgId, features } = useContext(OrganizationContext);
  const [models = []] = useResource<SourcingEngineAPI.EngineModel[]>(
    `/api/sourcing/${orgId}/engine-models`
  );
  return useMemo(() => {
    const canViewCandidateFilters =
      features.includes('leads') &&
      !!models.length &&
      (!forRole || models.some(m => m.target?.id === forRole && m.target?.type === 'role'));
    return canViewCandidateFilters;
  }, [features, models, forRole]);
};
