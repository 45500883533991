export class OpenRejectionPopoverEvent extends CustomEvent<undefined> {
  static EventKey = 'OpenRejectionPopover';
  candidateId: number;
  constructor(candidateId: number) {
    super(OpenRejectionPopoverEvent.EventKey);
    this.candidateId = candidateId;
  }

  static dispatch(candidateId: number) {
    window.dispatchEvent(new OpenRejectionPopoverEvent(candidateId));
  }
}

export class ClickAcceptOnCandidateEvent extends CustomEvent<undefined> {
  static EventKey = 'ClickAcceptOnCandidateEvent';
  constructor() {
    super(ClickAcceptOnCandidateEvent.EventKey);
  }
  static dispatch() {
    window.dispatchEvent(new ClickAcceptOnCandidateEvent());
  }
}

export class SelectCohortEvent extends CustomEvent<undefined> {
  static EventKey = 'SelectCohort';

  static dispatchFor(cohortUnix: number) {
    window.dispatchEvent(new SelectCohortEvent(cohortUnix));
  }

  cohortUnix: number;

  constructor(cohortUnix: number) {
    super(SelectCohortEvent.EventKey);
    this.cohortUnix = cohortUnix;
  }
}

export class OnEditNoteEvent extends CustomEvent<undefined> {
  static EventKey = 'OnEditNotePopover';
  profileId: number;
  constructor(profileId: number) {
    super(OnEditNoteEvent.EventKey);
    this.profileId = profileId;
  }

  static dispatch(profileId: number) {
    window.dispatchEvent(new OnEditNoteEvent(profileId));
  }
}
