import moment from 'moment';
import React from 'react';
import { useRouteMatch } from 'react-router';
import { isInternal } from '../Helpers';
import { makeRequest } from '../shared/Resource';
import { EXTERNAL_HOST } from '../shared/helpers';

export type OrganizationContextData = OrganizationAPI.MyOrganization & {
  actions: {
    refresh: () => Promise<void>;
  };
};

export const OrganizationContext = React.createContext<OrganizationContextData>(
  new Error(
    'No organization context data available, outside of scope?'
  ) as any as OrganizationContextData
);

const cache = window.caches?.open('FounderTools');
const cacheKey = `${EXTERNAL_HOST}/api/organization/mine?v=2023-05-02`;

export const OrganizationProvider: React.FunctionComponent = ({ children }) => {
  const [org, setOrg] = React.useState<OrganizationAPI.MyOrganization | Error | null>(null);
  const route = useRouteMatch<{ organizationId: string }>('/:organizationId');

  const refresh = React.useCallback(async () => {
    const resp = await (await cache)?.match(cacheKey);
    if (resp) {
      try {
        setOrg(JSON.parse(await resp.text()));
      } catch (err) {
        console.warn('Could not parse cached session');
      }
    }

    try {
      const newOrg = await makeRequest<OrganizationAPI.MyOrganization>('/api/organization/mine');
      if (!('id' in newOrg)) {
        throw new Error(`Unable to fetch org info.`);
      } else {
        newOrg.roles = newOrg.roles.sort(
          (a, b) =>
            (a.deletedBy || '').localeCompare(b.deletedBy || '') ||
            moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf()
        );
      }
      try {
        await (await cache)?.put(cacheKey, new Response(JSON.stringify(newOrg)));
      } catch (err) {
        console.warn('Unable to persist the session to window.caches', err);
      }
      setOrg(newOrg);
    } catch (err) {
      setOrg(err);
    }
  }, [setOrg]);

  React.useEffect(() => {
    refresh();
  }, [refresh]);

  const currentOrgId = Number(route?.params.organizationId);

  const value = React.useMemo(() => {
    if (!org || org instanceof Error) {
      return undefined;
    }

    let value: OrganizationContextData = { ...org, actions: { refresh } };

    // If you're an internal Sequoia user, you can view all orgs - if an org ID is present in the
    // URL, we return that organization as the current top level org, change `me.features` to reflect
    // the features available to that team, and change `team` to be the set of people on that team.
    //
    if (org && isInternal(org.me) && currentOrgId !== org.me.organizationId) {
      const current = org.all.find(o => o.id === currentOrgId);
      const currentTeam = org.team.filter(
        u =>
          u.organizationId === currentOrgId ||
          u.campaigns.some(c => c.organizationId === currentOrgId)
      );

      if (current) {
        value = {
          ...org,
          ...current,
          team: currentTeam,
          me: {
            ...org.me,
          },
          actions: { refresh },
        };
      }
    }

    return value;
  }, [org, currentOrgId, refresh]);

  if (!value) {
    return <div />;
  }

  if (org instanceof Error) {
    return (
      <div style={{ padding: 20, textAlign: 'center' }}>
        {org.toString()} Please try again in a few minutes.
      </div>
    );
  }

  return <OrganizationContext.Provider value={value}>{children}</OrganizationContext.Provider>;
};
