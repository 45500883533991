import { Popover } from 'antd';
import React, { useContext, useState } from 'react';
import styled from 'styled-components/macro';
import { HorizontalDivider } from '../../components/Common';
import { OrganizationContext } from '../../contexts/Organization';
import { makeRequest } from '../../shared/Resource';
import { SeqButton } from '../Button';
import { PlainTextArea } from '../Input';
import { PopoverDeleteButton } from '../Popover';
import { SeqTag } from '../SeqTag';
import { TagContainer, TAG_COLORS } from './AddTagsPopover';
export const EditTagPopover: React.FunctionComponent<{
  currTag: string;
  onRemoveProfileTag?: () => void;
  editDescription?: boolean;
  children: React.ReactElement;
}> = ({ currTag, onRemoveProfileTag, editDescription, children }) => {
  const { id, tags, actions } = useContext(OrganizationContext);
  const currTagColor = tags[currTag].color;
  const [popoverVisible, setPopoverVisible] = useState<boolean>(false);
  const [tagColor, setColor] = useState<string>(currTagColor);
  const [description, setDescription] = useState<string>(tags[currTag].description);

  const unusedTagColors = TAG_COLORS.filter(c => c !== tagColor).map(c => (
    <TagColorSelection key={c} color={c} onClick={() => setColor(c)} />
  ));

  const isDirty = tagColor !== currTagColor || description !== tags[currTag].description;

  const onEditOrgTag = async () => {
    const newTag = { [currTag]: { color: tagColor, description: description || '' } };
    const response = await makeRequest<OrganizationAPI.Organization>(
      `/api/organization/${id}`,
      'PUT',
      { tag: newTag } satisfies OrganizationAPI.UpdateOrganizationRequest
    );
    if (response.id) await actions.refresh();
  };

  const onDeleteTag = async () => {
    const response = await makeRequest<{ message: string }>(
      `/api/organization/${id}/tag`,
      'DELETE',
      { tag: currTag }
    );
    if (response.message) actions.refresh();
  };

  const PopoverContent = (
    <div style={{ maxWidth: 192 }}>
      <TagContainer>
        <SeqTag
          style={{ margin: 6, textOverflow: 'ellipsis', overflowX: 'hidden', maxWidth: 188 }}
          tagColor={tagColor}
          tagName={currTag}
        />
      </TagContainer>
      {editDescription && (
        <div>
          <HorizontalDivider />
          <PlainTextArea
            autoSize
            rows={1}
            placeholder={'Add a description (optional).'}
            style={{
              paddingLeft: 10,
              fontSize: 12,
              marginTop: 10,
              marginBottom: 10,
              borderBottom: 'none',
            }}
            value={description}
            onChange={e => setDescription(e.currentTarget.value.toString())}
          />
        </div>
      )}
      <HorizontalDivider />
      <TagColorContainer>{unusedTagColors}</TagColorContainer>
      <HorizontalDivider />
      <div style={{ display: 'flex', padding: '6px 10px', justifyContent: 'flex-end', gap: 6 }}>
        {onRemoveProfileTag ? (
          <SeqButton size="small" danger onClick={onRemoveProfileTag}>
            Remove
          </SeqButton>
        ) : (
          <PopoverDeleteButton
            onDelete={onDeleteTag}
            itemName="tag"
            extraMsg="and remove it from all candidates"
          />
        )}
        <SeqButton size="small" disabled={!isDirty} onClick={onEditOrgTag}>
          Update
        </SeqButton>
      </div>
    </div>
  );

  return (
    <Popover
      overlayClassName={'seq-no-padding'}
      trigger={'click'}
      content={PopoverContent}
      placement={'bottomLeft'}
      open={popoverVisible}
      onOpenChange={() => setPopoverVisible(v => !v)}
    >
      {children}
    </Popover>
  );
};

const TagColorContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 6px;
  max-height: 100px;
  align-items: center;
`;

const TagColorSelection = styled.div<{ color: string }>`
  background: ${({ color }) => color};
  margin: 3px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
`;
