import { WarningOutlined } from '@ant-design/icons';
import { omit } from 'lodash';
import { CSSProperties } from 'react';
import styled from 'styled-components';
import { FlexFillingRowCSS, LightTooltip, Tag } from '../components/Common';
import { TabPane, Tabs } from '../components/Tabs';
import { Label2 } from '../components/Text';
import { FilterTagsContainer } from '../referrals/components/FilterTags';
import { ClickableItem, FadeInContainer } from '../shared/Common';
import { Colors } from '../shared/Theme';
import { EMPTY_SEARCH } from './NetworkFiltering';
import { compileSearchTerms, SearchTermLabels, SearchTitle } from './SearchTitle';

const { SEQUOIA_PAPER, WARNING_TINT, BLACK1ALPHA, BLACK3ALPHA, BLACK5ALPHA, SEQUOIA_DARK_GREEN } =
  Colors.Static;
export const TAB_LIMIT = 5;

interface NetworkTabProps {
  myTabs: NetworkAPI.CreateSearchHistory[];
  search: NetworkAPI.CreateSearchHistory;
  currFilterTerms: string[];
  searchTermLabels: SearchTermLabels;
  onChange: (h: NetworkAPI.CreateSearchHistory) => void;
  onDeleteTab: (searchId: number) => Promise<void>;
}

export const NetworkTabs = ({
  myTabs,
  search,
  currFilterTerms,
  onChange,
  searchTermLabels,
  onDeleteTab,
}: NetworkTabProps) => {
  const renderedTabs = [...myTabs, ...(!myTabs.find(t => t.id === search?.id) ? [search] : [])];
  const newTabDisabled = myTabs.length === TAB_LIMIT;

  return (
    <TabsContainer>
      <Tabs
        active={search}
        onChange={onChange}
        style={{ minWidth: 0 }}
        labelsContainerStyle={{ height: 44, background: 'unset', padding: 0, gap: 'unset' }}
      >
        {renderedTabs.map((h, idx, tabs) => {
          const active = search?.id === h.id;
          const hasRightDivider = !active && tabs?.[idx + 1]?.id !== search?.id;

          return (
            <TabPane
              key={idx}
              overrideKey={h}
              labelStyle={tabLabelStyle(active, hasRightDivider)}
              hoverStyle="border-bottom: 3px solid transparent;"
              label={
                <Label2
                  style={{ minWidth: 0 }}
                  as="div"
                  title={compileSearchTerms({ search: h.data, labels: searchTermLabels }).join(
                    ', '
                  )}
                >
                  {active ? (
                    <div style={{ display: 'flex', gap: 8, marginRight: 2, marginTop: 2 }}>
                      {currFilterTerms.length ? (
                        <>
                          {!h.isTab && newTabDisabled && (
                            <LightTooltip
                              overlay={`This tab will not be saved. Delete an existing tab in order to create a new tab.`}
                            >
                              <WarningOutlined
                                style={{ color: WARNING_TINT, fontSize: 14, marginTop: 20 }}
                              />
                            </LightTooltip>
                          )}
                          <FilterTagsContainer style={{ marginRight: 0, flex: 1 }}>
                            {currFilterTerms.map((t, idx) => (
                              <Tag
                                key={idx}
                                style={{
                                  background: SEQUOIA_PAPER,
                                  border: `1px solid ${BLACK3ALPHA}`,
                                  fontWeight: 700,
                                }}
                              >
                                {t}
                              </Tag>
                            ))}
                          </FilterTagsContainer>
                        </>
                      ) : (
                        <div style={{ fontStyle: 'italic' }}>New Tab</div>
                      )}
                      {h.id && h.isTab && (
                        <ClickableItem
                          onClick={() => {
                            h.id && onDeleteTab(h.id);
                          }}
                        >
                          <img src="/icons/trash.svg" alt="trash" style={{ width: 18 }} />
                        </ClickableItem>
                      )}
                    </div>
                  ) : (
                    <ClickableItem style={{ flexShrink: 0 }}>
                      <SearchTitle search={h.data} labels={searchTermLabels} />
                    </ClickableItem>
                  )}
                </Label2>
              }
            />
          );
        })}
      </Tabs>
      <LightTooltip
        overlay={
          myTabs.length === TAB_LIMIT
            ? 'Delete an existing tab before creating a new search or edit an existing tab'
            : ''
        }
      >
        <ClickableItem
          style={{
            fontSize: 18,
            padding: '8px 0',
            display: 'flex',
            gap: 2,
            alignItems: 'baseline',
            color: SEQUOIA_DARK_GREEN,
          }}
          onClick={() => {
            onChange(EMPTY_SEARCH);
          }}
        >
          +<span style={{ fontSize: 14, whiteSpace: 'nowrap' }}>New Search</span>
        </ClickableItem>
      </LightTooltip>
    </TabsContainer>
  );
};

export const NetworkTabsLoadingPlaceholder = () => (
  <FadeInContainer
    style={{
      height: 100,
      background: SEQUOIA_PAPER,
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
    }}
  />
);

const TabsContainer = styled.div`
  ${FlexFillingRowCSS}
  align-items: center;
  gap: 12px;
`;

const tabLabelStyle = (active: boolean, hasRightDivider: boolean): CSSProperties => {
  let styles: CSSProperties = {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    padding: '0 12px',
    borderBottom: '3px solid transparent',
  };

  if (active) {
    styles = { ...styles, background: BLACK1ALPHA, cursor: 'default' };
  }

  if (hasRightDivider) {
    styles = {
      ...omit(styles, 'border', 'borderTopRightRadius'),
      borderRight: `1px solid ${BLACK5ALPHA}`,
      margin: 'auto 0',
    };
  }

  return styles;
};
