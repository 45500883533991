import React, { useEffect } from 'react';
import { Prompt, useLocation } from 'react-router';

export const PromptOnDirtyForm: React.FC<{
  dirty: boolean;
  children: React.ReactNode;
  allowQuerystringChanges?: boolean;
}> = ({ dirty, children, allowQuerystringChanges }) => {
  //Page closing event listener must access Ref, not state or prop
  const dirtyRef = React.useRef(dirty);
  const loc = useLocation();

  useEffect(() => {
    dirtyRef.current = dirty;
  }, [dirty]);

  useEffect(() => {
    window.addEventListener('beforeunload', alertUser);
    return () => {
      window.removeEventListener('beforeunload', alertUser);
    };
  }, []);

  const alertUser = (e: any) => {
    if (!!dirtyRef.current) {
      e.preventDefault();
      e.returnValue = '';
    }
  };

  return (
    <div>
      <Prompt
        when={dirty}
        message={nextLoc => {
          if (allowQuerystringChanges && nextLoc.pathname === loc.pathname) {
            return true;
          }
          return 'You have unsaved changes, are you sure you want to leave this page?';
        }}
      />
      {children}
    </div>
  );
};
