import { useContext, useEffect, useState } from 'react';
import { FloatingButton } from '../components/Button';
import { SeqDrawer } from '../components/Common';
import { ProfileDrawer } from '../components/profile-drawer/ProfileDrawer';
import { OrganizationContext } from '../contexts/Organization';
import { makeRequest } from '../shared/Resource';
import { ListItemPos, useListInteractions } from './useListInteractions';

interface CandidateDrawerProps<T> {
  profiles: T[];
  refreshProfiles: () => void;
  getRelatedProfileName: (id: number) => string;
  applyLocalUpdates: (profiles: T[]) => void;
  onClose?: () => void;
}

export function useCandidateDrawer({
  profiles,
  refreshProfiles,
  getRelatedProfileName,
  applyLocalUpdates,
  onClose,
}: CandidateDrawerProps<ExternalAPI.Profile | NetworkAPI.NetworkProfile>) {
  const { id } = useContext(OrganizationContext);
  const {
    focusedId: drawerCandidateId,
    setFocusedId: setDrawerCandidateId,
    shiftFocusedId,
    onKeyDown,
    itemPos,
  } = useListInteractions();

  const onCloseDrawer = () => {
    setDrawerCandidateId(0);
    setProfile(undefined);
    onClose?.();
  };

  const initProfile = !!drawerCandidateId && profiles.find(({ id }) => drawerCandidateId === id);
  const [profile, setProfile] = useState<ExternalAPI.Profile | undefined>(
    initProfile && 'details' in initProfile ? initProfile : undefined
  );

  useEffect(() => {
    if (!!drawerCandidateId && (!profile || profile?.id !== drawerCandidateId)) {
      const getProfile = async () => {
        const orgProfile = await makeRequest<ExternalAPI.Profile>(
          `/api/${id}/profile/byId/${drawerCandidateId}`
        );
        if (orgProfile.id) {
          setProfile(orgProfile);
        }
      };

      getProfile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerCandidateId]);

  const onProfileSaved = (profile?: ExternalAPI.Profile) => {
    if (profile) {
      const updatedProfiles = [...profiles];
      const idx = updatedProfiles.findIndex(p => p.id === profile.id);
      updatedProfiles[idx] = { ...updatedProfiles[idx], ...profile };
      applyLocalUpdates(updatedProfiles);
      setProfile(profile);
    }
  };

  const candidateDrawer = (
    <SeqDrawer width={550} open={!!profile} onClose={onCloseDrawer} destroyOnClose>
      <PrevNextArrows itemPos={itemPos} onClick={shiftFocusedId} />
      <div
        style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}
        onKeyDown={onKeyDown}
      >
        {!!profile && (
          <ProfileDrawer
            key={drawerCandidateId}
            profile={profile}
            getRelatedProfileName={getRelatedProfileName}
            onProfileSaved={onProfileSaved}
            onDelete={
              profile.id > 0
                ? () => {
                    onCloseDrawer();
                    refreshProfiles();
                  }
                : undefined
            }
          />
        )}
      </div>
    </SeqDrawer>
  );

  return { candidateDrawer, drawerCandidateId, setDrawerCandidateId, onProfileSaved };
}

export const PrevNextArrows: React.FunctionComponent<{
  itemPos: ListItemPos | null;
  onClick: (direction: string) => void;
}> = ({ itemPos, onClick }) => {
  if (!itemPos) return <div />;

  return (
    <div
      style={{ position: 'absolute', left: -60, top: 6, display: 'flex', flexDirection: 'column' }}
    >
      <FloatingButton disabled={itemPos === 'first'} onClick={() => onClick('ArrowUp')}>
        <img style={{ transform: 'rotate(90deg)' }} src="/icons/arrow-left.svg" alt="Right Arrow" />
      </FloatingButton>
      <FloatingButton
        disabled={itemPos === 'last'}
        style={{ marginTop: 0 }}
        onClick={() => onClick('ArrowDown')}
      >
        <img
          style={{ transform: 'rotate(90deg)' }}
          src="/icons/arrow-right.svg"
          alt="Right Arrow"
        />
      </FloatingButton>
    </div>
  );
};
