import { LoadingOutlined } from '@ant-design/icons';
import { capitalize } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { Dialog } from '../../components/Common';
import { OrganizationContext } from '../../contexts/Organization';
import { onEmailUpdate } from '../../Helpers';
import { Dot } from '../../shared/Common';
import { isValidEmail } from '../../shared/helpers';
import { getNameParts } from '../../shared/profile-helpers';
import { makeRequest } from '../../shared/Resource';
import { Colors } from '../../shared/Theme';
import { ActionButton, SeqButton } from '../Button';
import { MessageRowContainer } from '../Common';
import { SeqInput } from '../Input';
import { SeqModal } from '../Modal';
import { Select, SelectOption } from '../selects/Select';
import { H4Mono } from '../Text';
import { EXTENSION_MODAL_WIDTH } from './UpsertCandidateModal';

const { SEQUOIA_PASTEL_ORANGE, SEQUOIA_BRIGHT_RED, SEQUOIA_DARK_RED, SEQUOIA_BLACK } =
  Colors.Static;

export const EditEmailModalButton: React.FunctionComponent<{
  profile: ExternalAPI.Profile;
  onClose: (modifiedProfile?: ExternalAPI.Profile) => void;
  bouncingAddress?: string;
}> = props => {
  const [open, setOpen] = React.useState<boolean>(false);

  return (
    <>
      {open && (
        <EditEmailModal
          bouncingAddress={props.bouncingAddress}
          profile={props.profile}
          onClose={newProfile => {
            setOpen(false);
            props.onClose(newProfile);
          }}
          open={open}
        />
      )}
      <ActionButton
        onClick={() => setOpen(true)}
        style={{ fontSize: 12 }}
        color={SEQUOIA_BRIGHT_RED}
        hoverColor={SEQUOIA_DARK_RED}
      >
        {props.bouncingAddress ? 'Bounced' : 'Email Not Found'}
      </ActionButton>
    </>
  );
};

export const EditEmailModal: React.FunctionComponent<{
  open: boolean;
  onClose: (modifiedProfile?: ExternalAPI.Profile) => void;
  profile: ExternalAPI.Profile;
  bouncingAddress?: string;
}> = ({ open, onClose, profile, bouncingAddress }) => {
  const { me } = useContext(OrganizationContext);
  const [newEmail, setNewEmail] = useState<string>('');

  const onSave = async () => {
    await onEmailUpdate(me.name, profile, newEmail, onClose);
  };

  const firstName = getNameParts(profile.name).first;
  const hasPickedNewEmail =
    (!bouncingAddress || bouncingAddress.toLowerCase() !== newEmail.toLowerCase()) &&
    isValidEmail(newEmail);

  return (
    <SeqModal width={EXTENSION_MODAL_WIDTH} open={open} onClose={() => onClose()}>
      <Dialog
        header={`Update email`}
        content={
          <div style={{ marginTop: 12, display: 'flex', flexDirection: 'column', gap: 12 }}>
            {!!bouncingAddress && (
              <MessageRowContainer size="small" color={SEQUOIA_PASTEL_ORANGE}>
                An email to {firstName} at the following email bounced:
                <span style={{ paddingLeft: 6, fontWeight: 500 }}>{bouncingAddress}</span>.
              </MessageRowContainer>
            )}
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 16,
              }}
            >
              <div style={{ flex: 1, minWidth: 300 }}>
                <SeqInput
                  style={{ marginTop: 12 }}
                  autoFocus
                  placeholder={'Enter new email address'}
                  value={
                    newEmail ||
                    (bouncingAddress ? '' : isValidEmail(profile.email) ? profile.email : '')
                  }
                  onChange={e => {
                    setNewEmail(e.currentTarget.value);
                  }}
                  onKeyDown={e => {
                    if ((e.key === 'Enter' || e.key === 'Return') && isValidEmail(newEmail)) {
                      onSave();
                    }
                  }}
                />
              </div>
              <H4Mono
                style={{
                  alignSelf: 'center',
                  padding: 'unset',
                  display: 'flex',
                  alignItems: 'center',
                  justifySelf: 'center',
                  opacity: 0.4,
                }}
              >
                <Dot color={SEQUOIA_BLACK} />
                OR
                <Dot color={SEQUOIA_BLACK} />
              </H4Mono>
              <FindEmailsButton
                bouncingAddress={bouncingAddress}
                profile={profile}
                onEmailSelected={setNewEmail}
                newEmail={newEmail}
              />
            </div>
          </div>
        }
        footer={
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 30 }}>
            <SeqButton
              disabled={newEmail ? !isValidEmail(newEmail) : false}
              onClick={() => {
                hasPickedNewEmail ? onSave() : onClose();
              }}
            >
              Done
            </SeqButton>
          </div>
        }
      />
    </SeqModal>
  );
};

const nonBouncingResult = (
  result: ExternalAPI.DiscoverEmailResult,
  bouncingAddress?: string
): ExternalAPI.DiscoverEmailResult => {
  const bounced = bouncingAddress?.toLowerCase() || '';
  if (!result || 'error' in result || 'status' in result) {
    return result;
  }
  const resultWithoutBouncingAddr: PeopleAPI.Emails = {
    lastDiscovery: result.lastDiscovery,
    discoveryBy: result.discoveryBy,
    work: result.work?.address.toLowerCase() === bounced ? undefined : result.work,
    personal: result.personal?.address.toLowerCase() === bounced ? undefined : result.personal,
    alternates: result.alternates.filter(alt => alt.address.toLowerCase() !== bounced),
  };
  return resultWithoutBouncingAddr;
};

const updateCountdownForResult = (
  result: ExternalAPI.DiscoverEmailResult,
  countdown: number | null
) => {
  if ('status' in result && result.status === 'Pending') {
    // If countdown <5 and we still don't have an answer, reset it to 9.
    // Note we choose a number 1 less than % 5 so it doesn't trigger a fetch for ~4s
    return !countdown ? 34 : countdown <= 5 ? 9 : countdown;
  }
  return null;
};

//New component: soft-check to see if emails have been discovered recently, show a loading icon until that is done
//If yes, a select appears showing those new email address options
//If not, show button which would run a hard-check for discovery:
//While running, show a loading message
const FindEmailsButton: React.FC<{
  profile: ExternalAPI.Profile;
  newEmail: string;
  onEmailSelected: (newEmail: string) => void;
  bouncingAddress?: string;
}> = ({ profile, onEmailSelected, newEmail, bouncingAddress }) => {
  const [countdown, setCountdown] = useState<number | null>(null);
  const [lastResult, setLastResult] = useState<ExternalAPI.DiscoverEmailResult | undefined>();
  const [loading, setLoading] = useState(false);

  const requestDiscoverEmailAddresses = React.useCallback(
    async (options?: { softCheck: boolean }) => {
      if (profile.id === -1) {
        return;
      }
      setLoading(true);
      const result = await makeRequest<ExternalAPI.DiscoverEmailResult>(
        `/api/${profile.organizationId}/profile/${profile.id}/seekEmail`,
        'POST',
        options
      );
      setLastResult(nonBouncingResult(result, bouncingAddress));
      setCountdown(countdown => updateCountdownForResult(result, countdown));
      setLoading(false);
    },
    [profile.id, profile.organizationId, bouncingAddress]
  );

  // Kick off an initial soft check on mount
  const [initial, setInitial] = useState(false);
  useEffect(() => {
    if (!initial) {
      requestDiscoverEmailAddresses({ softCheck: true });
      setInitial(true);
    }
  }, [initial, requestDiscoverEmailAddresses]);

  // Decrement the countdown, repeat soft check every 5s while in pending state
  useEffect(() => {
    if (!countdown) {
      return;
    }
    if (countdown % 5 === 0) {
      requestDiscoverEmailAddresses({ softCheck: true });
    }
    const to = setTimeout(() => {
      setCountdown(prev => (prev && prev > 0 ? prev - 1 : null));
    }, 1000);
    return () => {
      clearTimeout(to);
    };
  }, [countdown, setCountdown, requestDiscoverEmailAddresses]);

  return lastResult === undefined ? (
    <LoadingMessage message="Scanning for other email addresses in network." />
  ) : 'lastDiscovery' in lastResult ? (
    <EmailPicker options={lastResult} onChange={onEmailSelected} value={newEmail} />
  ) : 'status' in lastResult &&
    (lastResult.status === 'Never-Executed' || lastResult.status === 'Pending') ? (
    <>
      <SeqButton
        intent="primary"
        loading={loading}
        style={{ margin: '6px 0px', flex: 1, justifyContent: 'center' }}
        onClick={() => requestDiscoverEmailAddresses()}
      >
        {lastResult.status === 'Pending' ? `Discovering...` : `Discover Email Addresses`}
      </SeqButton>
      {lastResult.status === 'Pending' && (
        <LoadingMessage
          message={`Feel free to start drafting an email - if we find an address, it will be saved when discovery completes in ${
            !!countdown && countdown > 10 ? `${countdown}` : 'a few'
          } seconds.`}
        />
      )}
    </>
  ) : (
    <>Error</>
  );
};

const LoadingMessage: React.FC<{ message: string }> = ({ message }) => (
  <div style={{ display: 'flex', gap: 10 }}>
    <span>
      <LoadingOutlined spin={true} />
    </span>
    <span style={{ flex: 1, color: Colors.Static.SEQUOIA_LIGHT_TEXT }}>{message}</span>
  </div>
);

const EmailPicker: React.FC<{
  value?: string;
  onChange: (newVal: string) => void;
  options: PeopleAPI.Emails;
}> = ({ value, onChange, options }) => {
  const selOpts: SelectOption[] = [];
  options.personal &&
    selOpts.push({ name: options.personal.address, id: selOpts.length, extra: 'Personal' });
  options.work && selOpts.push({ name: options.work.address, id: selOpts.length, extra: 'Work' });

  selOpts.push(
    ...options.alternates.map((email, idx) => ({
      name: email.address,
      id: selOpts.length + idx,
      extra: capitalize(email.type),
    }))
  );

  const valueId = selOpts.find(selOpt => selOpt.name.toLowerCase() === value)?.id;

  return (
    <Select
      style={{ fontSize: 14 }}
      disabled={!selOpts.length}
      placeholder={
        selOpts.length ? `${selOpts.length} new emails found` : 'No additional email addresses'
      }
      selected={valueId}
      onSelect={optionIdx => {
        optionIdx !== null &&
          optionIdx !== undefined &&
          onChange(selOpts.find(o => o.id === optionIdx)?.name || '');
      }}
      options={selOpts}
    />
  );
};
