import { Select } from 'antd';
import { INSIGHT_FILTERS } from '../network/NetworkFilterDrawer';
import { CandidateWithDetails } from './PageLeads';

export const InsightFiltersDropdown: React.FunctionComponent<{
  candidates: CandidateWithDetails[];
  candidateInsightFilters: NetworkAPI.InsightFilter[];
  setCandidateInsightFilters: React.Dispatch<React.SetStateAction<NetworkAPI.InsightFilter[]>>;
}> = ({ candidates, setCandidateInsightFilters, candidateInsightFilters }) => {
  const insightFiltersMatchingAtLeast1Candidate = INSIGHT_FILTERS.filter(insightFilter => {
    return candidates.some(c =>
      Object.keys(c.profile.insights?.filters || {}).some(filterId => filterId === insightFilter.id)
    );
  });

  if (!insightFiltersMatchingAtLeast1Candidate.length) {
    return <></>;
  }

  return (
    <Select
      mode="multiple"
      placeholder={'Choose a filter'}
      style={{ width: 300, fontSize: 12, margin: '8px 0' }}
      allowClear
      value={candidateInsightFilters.map(f => f.id)}
      onChange={(values: string[]) =>
        setCandidateInsightFilters(INSIGHT_FILTERS.filter(f => values.includes(f.id)))
      }
      maxTagCount={1}
      options={insightFiltersMatchingAtLeast1Candidate.map(insightFilter => ({
        label: `${insightFilter.title} (${
          candidates.filter(c =>
            Object.keys(c.profile.insights?.filters || {}).some(
              filterId => filterId === insightFilter.id
            )
          ).length
        })`,
        value: insightFilter.id,
      }))}
    />
  );
};
