import { notification } from 'antd';
import React, { useContext, useState } from 'react';
import { Props } from 'react-rnd';
import { SeqButton } from '../components/Button';
import { SeqInput } from '../components/Input';
import { TAG_COLORS } from '../components/popovers/AddTagsPopover';
import { EditTagPopover } from '../components/popovers/EditTagPopover';
import { SeqTag } from '../components/SeqTag';
import { IconContainer, SearchIcon } from '../components/Svgs';
import { ExplanatoryText } from '../components/Text';
import { OrganizationContext } from '../contexts/Organization';
import { makeRequest } from '../shared/Resource';
import { InputContainer } from './RolesTab';

export const TagsTab: React.FunctionComponent = () => {
  const {
    id,
    tags,
    actions: { refresh },
  } = useContext(OrganizationContext);

  return (
    <>
      <ExplanatoryText>
        Custom tags can be created and used by your entire team. These tags can be added to
        candidate profiles in the Pipeline tab. Click on a tag in the list below to edit or delete
        it.
      </ExplanatoryText>
      <TagSection org={{ id, tags, refresh }} />
    </>
  );
};

const TagSection: React.FunctionComponent<Props> = props => {
  const { id, tags, refresh } = props.org;
  const [tagInput, setTagInput] = useState('');

  const filteredTags = Object.keys(tags)
    .filter(t => (tagInput.length ? t.toLowerCase().includes(tagInput.toLowerCase()) : true))
    .map(t => t);

  const onCreateTag = async () => {
    if (filteredTags.length) return;
    else if (!filteredTags.length && tagInput) {
      const newTag = {
        [tagInput]: { color: TAG_COLORS[Math.floor(Math.random() * TAG_COLORS.length)] },
      };

      const response = await makeRequest<OrganizationAPI.Organization>(
        `/api/organization/${id}`,
        'PUT',
        // SATISFIES ERROR
        { tag: newTag } as OrganizationAPI.UpdateOrganizationRequest
      );
      if (response.id) {
        notification.success({
          message: `The tag '${tagInput}' has been created.`,
          placement: 'bottomRight',
        });
        await refresh();
        setTagInput('');
      }
    }
  };

  return (
    <>
      <InputContainer>
        <SeqInput
          value={tagInput}
          autoFocus
          onChange={e => setTagInput(e.currentTarget.value)}
          placeholder={`Search or create a tag`}
          onKeyDown={e => {
            if (e.key === 'Enter') onCreateTag();
          }}
          prefix={
            <IconContainer>
              <SearchIcon />
            </IconContainer>
          }
        />
        <SeqButton disabled={!!filteredTags.length} onClick={onCreateTag}>
          Create tag
        </SeqButton>
      </InputContainer>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {filteredTags.sort().map(t => (
          <EditTagPopover editDescription={true} key={t} currTag={t}>
            <SeqTag
              style={{ margin: 4, fontSize: 14 }}
              clickable
              tagColor={tags[t].color}
              tagName={t}
            />
          </EditTagPopover>
        ))}
      </div>
    </>
  );
};
