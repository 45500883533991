import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components/macro';
import { OrganizationContext } from '../../contexts/Organization';
import { isInternal } from '../../Helpers';
import { useVisibleCampaigns } from '../../hooks/useVisibleCampaigns';
import { Colors } from '../../shared/Theme';

const { SEQUOIA_DARK_GRAY, SEQUOIA_GREEN } = Colors.Static;

export const CampaignsSubNavigation: React.FunctionComponent<{}> = () => {
  const { me } = useContext(OrganizationContext);
  const campaigns = useVisibleCampaigns({ includeArchived: false });

  if (campaigns.length === 0) {
    return <span />;
  }
  return (
    <div style={{ paddingLeft: 12 }}>
      <SidebarCampaignsListFounder key={me.id} campaigns={campaigns} />
    </div>
  );
};

const SidebarCampaignsListFounder: React.FunctionComponent<{
  campaigns: CampaignAPI.CampaignSummary[];
}> = ({ campaigns }) => {
  const { me, team, id: organizationId } = useContext(OrganizationContext);

  const campaignsToShowWithNoHeader = campaigns.filter(c => c.senderId === me.id);

  const otherTeamMembersCampagins = team
    .filter(t => !me.internalTalentId || organizationId === t.organizationId)
    .reduce((prev, { id, name }) => {
      if (!name) {
        //Has not signed in yet
        return prev;
      }
      prev[name] = campaigns.filter(c => c.senderId === id && organizationId === c.organizationId);
      return prev;
      // SATISFIES ERROR
    }, {} as { [personName: string]: CampaignAPI.Campaign[] });

  return (
    <div style={{ position: 'relative' }}>
      {campaignsToShowWithNoHeader
        .filter(c => !c.archivedAt)
        .map(campaign => (
          <SidebarLink
            exact
            key={`${campaign.name}`}
            to={`/${organizationId}/campaigns/sender/${campaign.senderId}/campaigns/${campaign.id}`}
          >
            <ReturnArrowIcon />
            {campaign.name}
          </SidebarLink>
        ))}
      {otherTeamMembersCampagins && !!Object.keys(otherTeamMembersCampagins).length && (
        <div>
          {Object.entries(otherTeamMembersCampagins).map(([name, campaigns], idx) =>
            !!campaigns.length ? (
              <div key={`other-team-member-${idx}`}>
                {isInternal(me) ? (
                  <SidebarLink
                    exact
                    className={'header'}
                    key={`sender-${name}`}
                    to={`/${organizationId}/campaigns/sender/${
                      team.find(t => t.name === name && t.organizationId === organizationId)?.id
                    }`}
                    title={name}
                  >
                    {name}
                  </SidebarLink>
                ) : (
                  <UnclickableSidebarLink
                    onClick={e => {
                      e.preventDefault();
                    }}
                    exact
                    className={'header'}
                    title={name}
                    to={``}
                  >
                    {name}
                  </UnclickableSidebarLink>
                )}
                {campaigns.map((campaign, idx) => (
                  <SidebarLink
                    exact
                    key={`${campaign.name}-${idx}`}
                    to={`/${organizationId}/campaigns/sender/${campaign.senderId}/campaigns/${campaign.id}`}
                    title={campaign.name}
                  >
                    <ReturnArrowIcon />
                    {campaign.name}
                  </SidebarLink>
                ))}
              </div>
            ) : (
              <div key={`other-team-member-${idx}`} />
            )
          )}
        </div>
      )}
    </div>
  );
};

const ReturnArrowIcon: React.FunctionComponent<{}> = () => (
  <img style={{ marginRight: 8 }} src={'/icons/return-arrow.svg'} alt="Return Arrow" />
);

const SidebarLink = styled(NavLink)`
  background: transparent;
  line-height: 16px;
  height: 24px;
  padding-right: 5px;
  font-weight: 400;
  display: block;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 4px;
  font-size: 12px;
  color: ${SEQUOIA_DARK_GRAY};
  &.header {
    margin-top: 8px;
    color: ${SEQUOIA_GREEN};
  }
  &:hover {
    font-weight: 500;
  }
  &:hover :not(.header) {
    color: ${SEQUOIA_DARK_GRAY};
  }
  &.active :not(.inactive) {
    font-weight: 500;
  }
`;

const UnclickableSidebarLink = styled(SidebarLink)`
  cursor: default;
  &:hover {
    font-weight: 400;
  }
`;
