import { notification } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { OrganizationContext } from '../../contexts/Organization';
import { ClickableItem } from '../../shared/Common';
import { makeRequest, useResource } from '../../shared/Resource';
import { ScrollingContainer } from '../../shared/ScrollableContainer';
import { Colors } from '../../shared/Theme';
import { SeqButton } from '../Button';
import { FlexFillingColCSS } from '../Common';
import { SeqInput, SeqTextArea } from '../Input';
import { SeqPopover } from '../Popover';
import { SecondarySidebarItemContainer } from './FeatureSidebar';

const { SEQUOIA_PAPER } = Colors.Static;
const DEFAULT_FEEDBACK_DETAILS = { title: '', description: '' };

interface AsanaTicketDetails {
  title: string;
  description: string;
}

export const FeedbackButton: React.FunctionComponent<{}> = () => {
  const {
    me,
    name: orgName,
    managerTalentId,
    settings: orgSettings,
  } = useContext(OrganizationContext);
  const [seqUsers = []] = useResource<ExternalAPI.SequoiaUserSummary[]>(`/api/users/seq-team`);
  const manager = seqUsers.find(u => u.internalTalentId === managerTalentId);
  const [popoverVisible, setPopoverVisible] = useState<boolean>(false);
  const [asanaTicketDetails, setAsanaTicketDetails] = useState<AsanaTicketDetails>({
    title: '',
    description: '',
  });

  const onOpenFromEvent = (event: OpenFeedbackEvent) => {
    if (popoverVisible) {
      return;
    }
    setPopoverVisible(true);
    setAsanaTicketDetails({ title: event.title, description: event.description });
  };

  useEffect(() => {
    window.addEventListener(OpenFeedbackEvent.EventKey, onOpenFromEvent as any);
    return () => {
      window.removeEventListener(OpenFeedbackEvent.EventKey, onOpenFromEvent as any);
    };
  });

  const createTask = async () => {
    const response = await makeRequest('/api/user/asana-ticket', 'POST', {
      title: asanaTicketDetails.title,
      description: asanaTicketDetails.description,
      html_notes: `<body><ul><li>Organization: ${orgName}</li><li>Name: ${
        me.name
      }</li><li>URL path: ${window.location.href}</li><li>Seq POC: ${
        manager?.name || manager?.email
      }</li><li>Description: ${asanaTicketDetails.description}</li></ul></body>`,
    });
    if (response) {
      notification.success({ message: 'Your feedback has been submitted.' });
      setAsanaTicketDetails(DEFAULT_FEEDBACK_DETAILS);
    } else {
      notification.error({
        message: 'We had an issue submitting your feedback. Please try again later.',
      });
    }
  };

  const content = (
    <ContentContainer style={{ gap: 16 }}>
      <SeqInput
        size="small"
        title="Title"
        autoFocus
        required
        placeholder="Bug or Feature request"
        value={asanaTicketDetails.title}
        onChange={e => {
          const title = e.target.value;
          setAsanaTicketDetails(prevState => ({
            ...prevState,
            title,
          }));
        }}
      />
      <SeqTextArea
        size="small"
        title="Description"
        required
        placeholder="Please provide us with a detailed description of your feedback."
        style={{ minHeight: 90, background: SEQUOIA_PAPER }}
        autoSize
        value={asanaTicketDetails.description}
        onChange={e => {
          const description = e.target.value;
          setAsanaTicketDetails(prevState => ({
            ...prevState,
            description,
          }));
        }}
      />
    </ContentContainer>
  );

  const footer = (
    <SeqButton
      size="small"
      intent="primary"
      onClick={() => {
        setPopoverVisible(false);
        createTask();
      }}
      disabled={!asanaTicketDetails.title || !asanaTicketDetails.description}
    >
      Report Issue / Feedback
    </SeqButton>
  );

  return (
    <SeqPopover
      title="Leave feedback"
      open={popoverVisible}
      onOpenChange={() => setPopoverVisible(v => !v)}
      content={content}
      footer={footer}
      contentStyle={{ paddingRight: 0 }}
      footerStyle={{ marginRight: 24 }}
    >
      <ClickableItem style={{ display: orgSettings.canLeaveFeedback ? undefined : 'none' }}>
        <SecondarySidebarItemContainer>
          <img style={{ width: 22 }} src={'/icons/feedback.svg'} alt={'feedback'} />
          <div>Report Issue / Feedback</div>
        </SecondarySidebarItemContainer>
      </ClickableItem>
    </SeqPopover>
  );
};

const ContentContainer = styled(ScrollingContainer)`
  ${FlexFillingColCSS}
  background: white;
`;

export class OpenFeedbackEvent extends CustomEvent<undefined> {
  static EventKey = 'OpenFeedback';

  static dispatchFor(inp: AsanaTicketDetails) {
    window.dispatchEvent(new OpenFeedbackEvent(inp.title || '', inp.description || ''));
  }

  title: string;
  description: string;

  constructor(title: string, description: string) {
    super(OpenFeedbackEvent.EventKey);
    this.title = title;
    this.description = description;
  }
}
