import { notification } from 'antd';
import { isEqual } from 'lodash';
import { default as React, useContext } from 'react';
import styled from 'styled-components/macro';
import { Column, FlexFillingColCSS } from '../../components/Common';
import { OrganizationContext } from '../../contexts/Organization';
import { useCampaignConfig } from '../../hooks/useCampaignConfig';
import { EXTERNAL_RECIPIENT_VARIABLES, isValidEmail } from '../../shared/helpers';
import { makeRequest } from '../../shared/Resource';
import { ScrollingContainer } from '../../shared/ScrollableContainer';
import { Colors } from '../../shared/Theme';
import { usePersistedForm } from '../../shared/usePersistedForm';
import { CCBCCRow } from '../CCBCCRow';
import { BOX_SHADOW } from '../Common';
import { ExternalTemplateEditor } from '../ExternalTemplateEditor';
import { buildVariablesMap, TemplateContext } from '../ExternalTemplateSupport';
import { SeqInput } from '../Input';
import { ListRow, StyledRow } from '../ListRow';
import { ScheduledSendButton } from '../ScheduledSendButton';
import { Label2, SectionHeader } from '../Text';

type EmailViewOverrides = Pick<
  CampaignAPI.CandidateOverride,
  'introBody' | 'subject' | 'bcc' | 'cc'
>;

const EMPTY_OVERRIDES: EmailViewOverrides = {
  introBody: '',
  subject: '',
  bcc: [],
  cc: [],
};

const EMPTY_SAVED_STATE = { overrides: { ...EMPTY_OVERRIDES }, campaignId: undefined };

export const EmailView: React.FunctionComponent<{
  candidate: ExternalAPI.Profile;
  onClose: (didAddToCampaign?: CampaignAPI.Campaign, ownerId?: number) => void;
  isExtensionView?: boolean;
}> = ({ candidate, onClose, isExtensionView }) => {
  const { id: orgId, me } = useContext(OrganizationContext);

  const [{ values: saved }, { update: setSaved, empty: clearSaved }] = usePersistedForm<{
    overrides: EmailViewOverrides;
    campaignId?: number;
  }>({
    localStorageKey: 'founder-email',
    localStorageIdentifier: `${candidate.id}`,
    empty: EMPTY_SAVED_STATE,
    initial: null,
    shouldUseSaved: value => value && 'overrides' in value,
  });

  const { overrides } = saved;
  const { campaign, campaignSelect, chooseCampaignMessage } = useCampaignConfig({
    feature: 'pipeline',
    organizationId: orgId,
    roleId: candidate.roleId,
    campaignId: saved.campaignId,
    linkToNewTab: isExtensionView,
  });

  const sender = campaign?.sender;
  const canSend = sender && (sender.id === me.id || sender.allowOthersToSend);

  const variables = sender
    ? buildVariablesMap(sender, EXTERNAL_RECIPIENT_VARIABLES, candidate, sender.templateVariables)
    : {};

  const onSend = async (deliverAt: Date) => {
    if (!campaign) {
      return;
    }

    if (overrides.cc?.some(e => !isValidEmail(e))) {
      notification.warning({ message: 'Invalid email address in the cc field.' });
      return;
    }

    if (overrides.bcc?.some(e => !isValidEmail(e))) {
      notification.warning({ message: 'Invalid email address in the bcc field.' });
      return;
    }

    const resp = await makeRequest<CampaignAPI.CampaignMember | { error: string }>(
      `/api/${orgId}/campaign/${campaign.id}/profile/${candidate.id}`,
      'PUT',
      {
        //Convert empty strings to undefined
        overrides: {
          introBody: overrides.introBody ? overrides.introBody : undefined,
          subject: overrides.subject ? overrides.subject.trim() : undefined,
          deliverAt,
          cc: overrides.cc?.length ? overrides.cc : undefined,
          bcc: overrides.bcc?.length ? overrides.bcc : undefined,
        },
        candidate: candidate.id === -1 ? candidate : undefined,
      } satisfies CampaignAPI.AddCandidateToCampaignReq
    );

    if ('error' in resp) {
      alert(resp.error);
      return;
    }

    clearSaved();
    onClose(campaign, me.id);
  };

  return (
    <TemplateContext.Provider value={{ variables, recipient: candidate }}>
      <Column style={{ margin: '8px 0 16px 24px', minWidth: 0 }}>
        <ListRow
          hasDivider={false}
          title="Campaign"
          content={campaignSelect({
            optionsContainerStyle: { maxWidth: 380 },
            type: 'secondary',
            onShouldSelect: () =>
              isEqual(saved.overrides, EMPTY_OVERRIDES)
                ? true
                : window.confirm(
                    "Are you sure you want to switch campaigns and clear the changes you've made?"
                  ),
            onSelect: campaignId => {
              setSaved({ campaignId, overrides: EMPTY_OVERRIDES });
            },
          })}
          contentStyle={{ marginRight: 24 }}
        />
        {campaign && (
          <>
            <ContentContainer>
              <ListRow
                hasDivider={false}
                title="From"
                content={`${campaign.sender.name} <${
                  campaign.settings.sendAs || campaign.sender.email
                }>`}
              />
              <ListRow
                hasDivider={false}
                title="To"
                content={
                  candidate.email ? (
                    `${candidate.name} <${candidate.email}>`
                  ) : (
                    <span>
                      {`${candidate.name} - `}
                      <span style={{ opacity: 0.5 }}>Email discovered automatically</span>
                    </span>
                  )
                }
              />
              <CCBCCRow
                headers={{ cc: <Label2>CC</Label2>, bcc: <Label2>BCC</Label2> }}
                value={{
                  cc: overrides.cc?.length ? overrides.cc : campaign.settings.cc,
                  bcc: overrides.bcc?.length ? overrides.bcc : campaign.settings.bcc,
                }}
                onChange={({ cc, bcc }) => {
                  setSaved({
                    overrides: {
                      ...overrides,
                      cc: isEqual(cc, campaign.settings.cc) ? undefined : cc,
                      bcc: isEqual(bcc, campaign.settings.bcc) ? undefined : bcc,
                    },
                  });
                }}
              />
              <Label2 style={{ marginTop: 16, marginBottom: 8 }}>Subject</Label2>
              <SeqInput
                size="small"
                outline="rect"
                value={overrides.subject || campaign.settings.subject}
                onChange={e =>
                  setSaved({
                    overrides: { ...overrides, subject: e.currentTarget.value.toString() },
                  })
                }
                style={{ marginBottom: 12, flexGrow: 0 }}
              />
              {campaign.templates.map((template, idx) => (
                <div style={{ marginLeft: 0 }} key={`template-${idx}`}>
                  {idx > 0 ? (
                    <SectionHeader style={{ marginBottom: 16 }}>
                      {`Followup - ${template.delay.value} ${template.delay.unit} later`}
                    </SectionHeader>
                  ) : undefined}
                  <ExternalTemplateEditor
                    value={{
                      text: idx === 0 ? overrides.introBody || template.body : template.body,
                    }}
                    setValue={val => {
                      idx === 0 && setSaved({ overrides: { ...overrides, introBody: val.text } });
                    }}
                    sender={
                      template.fromUserWithEmail
                        ? { name: '', email: template.fromUserWithEmail }
                        : campaign.sender!
                    }
                    config={{
                      titleStyle: { fontSize: 14 },
                      subject: campaign.settings.subject,
                      previewOnly: !!idx,
                    }}
                  />
                </div>
              ))}
            </ContentContainer>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginRight: 24,
                marginTop: 16,
              }}
            >
              <ScheduledSendButton campaign={campaign} onSend={onSend} disabled={!canSend} />
            </div>
          </>
        )}
        {chooseCampaignMessage && (
          <ChooseCampaignMsgContainer>{chooseCampaignMessage}</ChooseCampaignMsgContainer>
        )}
      </Column>
    </TemplateContext.Provider>
  );
};

const ContentContainer = styled(ScrollingContainer)`
  ${FlexFillingColCSS};

  ${StyledRow} {
    padding: 0;
    padding-bottom: 8px;
  }
`;

export const ChooseCampaignMsgContainer = styled.div`
  box-shadow: ${BOX_SHADOW};
  background: ${Colors.Static.BLACK1ALPHA};
  padding: 24px;
  margin: 48px auto;
  width: 70%;
  line-height: 1.5;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
`;
