import { uniq } from 'lodash';
import React from 'react';

class LRULocalStorage<T> {
  localStorageKey = '';
  localValue: { [identifier: string]: T } = {};

  constructor(localStorageKey: string) {
    this.localStorageKey = localStorageKey;
  }

  getRecentKeys() {
    const val = localStorage.getItem(this.localStorageKey);
    if (!val) return [];
    return JSON.parse(val) as string[];
  }

  markRecentKey(key: string) {
    let keys = this.getRecentKeys();
    keys = uniq([key, ...keys]);
    while (keys.length > 10) {
      const unusedKey = keys.pop()!;
      localStorage.removeItem(unusedKey);
    }
    try {
      localStorage.setItem(this.localStorageKey, JSON.stringify(keys));
    } catch (err) {
      // QuotaExceededError may occur if we have maxed out our storage (5MB)
      this.clearAll();
    }
  }

  clear(identifier: string) {
    delete this.localValue[identifier];
    if (localStorage) {
      const key = `${this.localStorageKey}:${identifier}`;
      localStorage.removeItem(key);
    }
  }

  clearAll() {
    Object.keys(localStorage).forEach(key => {
      if (key.startsWith(this.localStorageKey)) {
        localStorage.removeItem(key);
      }
    });
  }

  set(identifier: string, item: T) {
    this.localValue[identifier] = item;

    if (localStorage) {
      const key = `${this.localStorageKey}:${identifier}`;
      try {
        localStorage.setItem(key, JSON.stringify(item));
      } catch (err) {
        // QuotaExceededError may occur if we have maxed out our storage (5MB)
        this.clearAll();
      }
      this.markRecentKey(key);
    }
  }

  get(identifier: string): T | undefined {
    if (localStorage) {
      const val = localStorage.getItem(`${this.localStorageKey}:${identifier}`);
      if (!val) return this.localValue[identifier];
      try {
        return JSON.parse(val) as T;
      } catch (err) {
        return undefined;
      }
    } else {
      return this.localValue[identifier];
    }
  }
}

export function useLRULocalStorage<T>(storageKey: string) {
  const ref = React.useRef(new LRULocalStorage<T>(storageKey));
  return ref.current;
}
