import { ClockCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Button, notification } from 'antd';
import React, { useContext, useState } from 'react';
import styled from 'styled-components/macro';
import { SeqButton } from '../components/Button';
import { Dialog, LightTooltip } from '../components/Common';
import { SeqModal } from '../components/Modal';
import { OpenFeedbackEvent } from '../components/navigation/FeedbackButton';
import { RoleSelect } from '../components/selects/RoleSelect';
import { ExplanatoryText } from '../components/Text';
import { OrganizationContext } from '../contexts/Organization';
import { useDebouncedCall } from '../shared/hooks';
import { useResource } from '../shared/Resource';
import {
  EditableExcludeRulesMap,
  EditableRuleIds,
  Rule,
  RuleEditorContext,
  ValidationInfo,
} from '../shared/rule-editors/RuleEditorCommon';
import { Colors, hexWithOpacity } from '../shared/Theme';
import { useQueryPersistedState } from '../shared/useQueryPersistedState';
import { PromptOnDirtyForm } from '../templates/PromptOnDirtyForm';
const { SEQUOIA_LIGHT_GRAY } = Colors.Static;

export const SETab: React.FC<{}> = () => {
  const { id: orgId } = useContext(OrganizationContext);

  const [dirty, setDirty] = useState(false);
  const [leadLists = [], { refresh: refreshLeads }] = useResource<LeadsAPI.LeadList[]>(
    `/api/${orgId}/lead-lists`
  );

  const [models = [], { applyLocalUpdates, refresh, putItem }] = useResource<
    SourcingEngineAPI.EngineModel[],
    SourcingEngineAPI.EngineModel
  >(`/api/sourcing/${orgId}/engine-models`, undefined, { silent: true });
  const [selectedRoleId, setSelectedRoleId] = useQueryPersistedState<number | undefined>({
    encode: roleId => ({ roleId }),
    decode: qs => (qs.roleId ? Number(qs.roleId) : undefined),
  });
  const editModel = models.find(m => m.target?.id === selectedRoleId && m.target?.type === 'role');

  const onSave = useDebouncedCall<void>(async () => {
    if (!editModel) {
      return;
    }

    const resp = (await putItem(editModel)) as SourcingEngineAPI.EngineModel;
    if (resp.id) {
      setDirty(false);
      notification.success({
        message:
          'Preferences updated, your changes will be reflected in the next batch of candidates.',
      });
      await refresh();
      await refreshLeads();
    }
  });

  const [validationErrors, setValidationErrors] = useState<{ [ruleKey: string]: string }>({});
  const invalidReasons = Object.values(validationErrors);

  const countUnreviewedCandidatesFromModel = leadLists
    .filter(ll => ll.generatedBySEModelId === editModel?.id)
    .map(ll => ll.profiles)
    .flat()
    .filter(p => !p.review?.status).length;

  const [confirmingSave, setConfirmingSave] = useState<boolean>(false);

  return (
    <PromptOnDirtyForm dirty={dirty} allowQuerystringChanges>
      {editModel?.settings.pausedUntilModified && (
        <WarningText>
          Too many candidates were excluded by the filters below, you will not receive additional
          candidates for this role until you modify the configuration below.
        </WarningText>
      )}
      <ExplanatoryText style={{ marginBottom: 24 }}>
        If necessary, Sequoia administrators may create filters to help you find the best candidates
        for each role that you need to hire. You may view & edit those filters here.
      </ExplanatoryText>
      <div style={{ display: 'flex', alignItems: 'center', gap: 10, overflow: 'visible' }}>
        <span>Select a role:</span>
        <RoleSelect
          opts={{
            blockSelectNone: true,
            blockCreateNew: true,
            filterRoleOptions: (role: OrganizationAPI.Role) =>
              models.some(m => m.target?.id === role.id && m.target?.type === 'role'),
            defaultSelectOnSingleOption: true,
          }}
          role={selectedRoleId}
          setRole={setSelectedRoleId}
        />
        <div style={{ flex: 1 }} />
        <SeqButton
          disabled={!dirty}
          onClick={() => {
            setDirty(false);
            refresh();
          }}
        >
          Clear Changes
        </SeqButton>
        <LightTooltip
          overlay={<div>{invalidReasons.join('\n')}</div>}
          overlayStyle={{ display: invalidReasons.length ? undefined : 'none' }}
        >
          <SeqButton
            disabled={!!invalidReasons.length}
            intent={dirty ? 'primary' : 'secondary'}
            onClick={() => {
              countUnreviewedCandidatesFromModel ? setConfirmingSave(true) : onSave();
            }}
          >
            Save
          </SeqButton>
          <ConfirmationModal
            visible={confirmingSave}
            onClose={() => setConfirmingSave(false)}
            onConfirm={() => {
              setConfirmingSave(false);
              onSave();
            }}
            data={{ countCandidatesUnreviewed: countUnreviewedCandidatesFromModel }}
          />
        </LightTooltip>
      </div>
      {editModel && (
        <RulesEditor
          model={editModel}
          onChange={(newModel, [ruleId, validation]) => {
            setDirty(true);
            if (validation.isValid) {
              const copy = { ...validationErrors };
              delete copy[ruleId];
              setValidationErrors(copy);
            } else {
              setValidationErrors({ ...validationErrors, [ruleId]: validation.reason! });
            }
            applyLocalUpdates(models.map(m => (m.id === editModel.id ? newModel : m)));
          }}
        />
      )}
    </PromptOnDirtyForm>
  );
};

const ConfirmationModal: React.FunctionComponent<{
  visible: boolean;
  onClose: () => void;
  onConfirm: () => void;
  data: { countCandidatesUnreviewed: number };
}> = ({ visible, onClose, onConfirm, data }) => {
  return (
    <SeqModal width={550} open={visible} onClose={onClose}>
      <Dialog
        header={'Save new filters?'}
        footer={
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <SeqButton style={{ marginRight: 12 }} onClick={() => onClose()}>
              Continue editing
            </SeqButton>
            <SeqButton onClick={onConfirm} intent="primary">
              Save
            </SeqButton>
          </div>
        }
      >
        <div style={{ paddingTop: 10 }}>
          You have {data.countCandidatesUnreviewed} unreviewed candidates that were generated by
          these filters. Clicking save will remove these unreviewed candidates, and find you new
          candidates using your new filters. This process can take a few minutes, so it is
          recommended to try to make all of your changes at once.
        </div>
      </Dialog>
    </SeqModal>
  );
};

const RulesEditor: React.FC<{
  model: SourcingEngineAPI.EngineModel;
  onChange: (newModel: SourcingEngineAPI.EngineModel, validation: [string, ValidationInfo]) => void;
}> = ({ model, onChange }) => {
  const organization = useContext(OrganizationContext);
  const [selectedRule, setSelectedRule] = useQueryPersistedState<EditableRuleIds | undefined>({
    encode: selectedRule => ({ selectedRule }),
    decode: qs => (qs.selectedRule ? qs.selectedRule : 'Location'),
  });

  const replaceExcludeRule = (
    newRule: Rule,
    opts: { remove?: boolean; validation: ValidationInfo }
  ) => {
    const { remove, validation } = opts;
    if (remove) {
      const newM = {
        ...model,
        excludeRules: model.excludeRules.filter(r => r.uniqueId !== newRule.uniqueId),
      };
      onChange(newM, [newRule.id, validation]);
    } else if (!model.excludeRules.some(e => e.uniqueId === newRule.uniqueId)) {
      onChange(
        {
          ...model,
          excludeRules: [...model.excludeRules, newRule],
        },
        [newRule.id, validation]
      );
    } else {
      onChange(
        {
          ...model,
          excludeRules: model.excludeRules.map(e =>
            e.uniqueId === newRule.uniqueId ? newRule : e
          ),
        },
        [newRule.id, validation]
      );
    }
  };

  return (
    <div style={{ marginTop: 20 }}>
      <div style={{ display: 'flex' }}>
        <ListContainer>
          <ListRow
            style={{
              borderBottom: `1px solid ${SEQUOIA_LIGHT_GRAY}`,
              cursor: 'unset',
              color: Colors.Static.SEQUOIA_LIGHT_TEXT,
            }}
          >
            SELECT A FILTER TO EDIT
          </ListRow>
          {Object.entries(EditableExcludeRulesMap)
            .filter(entryArr => !!entryArr[1].founderEditorUniqueId)
            .map(([key, { label, founderEditorUniqueId }]) => (
              <RuleListRow
                selected={selectedRule === key}
                ruleInfo={{ label, id: key, uniqueId: founderEditorUniqueId! }}
                onSetSelected={setSelectedRule}
                model={model}
                key={founderEditorUniqueId}
              >
                {label}
              </RuleListRow>
            ))}
          <div style={{ flex: 1 }} />
          <Button
            onClick={() => {
              OpenFeedbackEvent.dispatchFor({
                title: `New candidate filter idea!`,
                description: '',
              });
            }}
            type="link"
            style={{
              textAlign: 'left',
              height: 40,
              display: organization.settings.canLeaveFeedback ? undefined : 'none',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                color: Colors.Static.SEQUOIA_LIGHT_TEXT,
              }}
            >
              <div>
                <QuestionCircleOutlined style={{ fontSize: 18, marginRight: 10 }} />
              </div>
              <div style={{ paddingBottom: 4 }}>Suggest a Filter</div>
            </div>
          </Button>
        </ListContainer>
        <RuleEditorContext.Provider
          value={{
            locationInputBasePath: '/api',
            workplacesAPIPath: '/api/workplaces',
            dsCategoriesPath: '/api/config/company-categories',
            tagsPath: '',
          }}
        >
          <EditorContainer>
            {!!selectedRule &&
              EditableExcludeRulesMap[selectedRule]?.editor &&
              React.createElement(EditableExcludeRulesMap[selectedRule].editor, {
                ruleType: 'exclude',
                onChange: replaceExcludeRule,
                rule:
                  (model.excludeRules.find(e => e.id === selectedRule) as Rule) ||
                  buildDefaultRule(selectedRule),
                title: EditableExcludeRulesMap[selectedRule].title(
                  'exclude',
                  founderModelInverts(selectedRule)
                ),
              })}
          </EditorContainer>
        </RuleEditorContext.Provider>
      </div>
    </div>
  );
};

const RuleListRow: React.FC<{
  ruleInfo: {
    id: string;
    uniqueId: string;
    label: string;
  };
  selected: boolean;
  model: SourcingEngineAPI.EngineModel;
  onSetSelected: (selKey: EditableRuleIds) => void;
}> = ({ ruleInfo, selected, onSetSelected, model }) => {
  const ruleStats = model.stats?.scoreCountsByRule?.[ruleInfo.uniqueId];
  const invert = !!model.excludeRules.find(er => er.uniqueId === ruleInfo.uniqueId)?.invert;
  const numerator = (invert ? ruleStats?.[0] : ruleStats?.[1]) || 0;
  const percentEliminated = ruleStats
    ? Math.round((numerator / (ruleStats[0] + ruleStats[1])) * 100)
    : 0;
  const showWarning = percentEliminated > 85;

  return (
    <LightTooltip
      overlayStyle={{ maxWidth: 350 }}
      overlay={
        showWarning
          ? 'Too many candidates are being eliminated by this filter, try to widen your parameters or remove this filter.'
          : null
      }
    >
      <ListRow
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          fontWeight: selected ? 500 : undefined,
          background: showWarning
            ? hexWithOpacity(Colors.Static.DESTRUCTIVE_TINT, 0.35)
            : selected
              ? hexWithOpacity(SEQUOIA_LIGHT_GRAY, 0.6)
              : undefined,
          paddingRight: 10,
        }}
        onClick={() => onSetSelected(ruleInfo.id as EditableRuleIds)}
      >
        <div>{ruleInfo.label}</div>
        {!!model.excludeRules.some(er => er.uniqueId === ruleInfo.uniqueId) ? (
          <div>
            <LightTooltip
              overlayStyle={{ maxWidth: 500 }}
              overlay={
                ruleStats
                  ? 'Percentage of people in the candidate pool who were eliminated by this rule. A large percentage may indicate too specfic of a search, while a small percentage may indicate that more filters are necessary.'
                  : 'Statistics about the latest configuration will appear a few minutes after you make changes.'
              }
            >
              {ruleStats ? (
                <span>{Math.round((numerator / (ruleStats[0] + ruleStats[1])) * 100)}%</span>
              ) : (
                <ClockCircleOutlined />
              )}
            </LightTooltip>
          </div>
        ) : (
          <div>OFF</div>
        )}
      </ListRow>
    </LightTooltip>
  );
};

const founderModelInverts = (ruleId: EditableRuleIds): boolean => {
  switch (ruleId) {
    case 'current-co-ds-categories':
    case 'current-co-headcount-between':
    case 'summary-or-work-desc-includes':
    case 'years-of-experience-between':
    case 'near-any-of':
      return true; //A match means 'keep candidate'
    case 'held-title-past-months':
    case 'works-at':
      return false; //A match means 'remove candidate'
    default:
      return false;
  }
};

//Default configuration for each editor when founder clicks add
const buildDefaultRule = (ruleId: EditableRuleIds): SourcingEngineAPI.ExcludeRule => {
  const base = {
    id: ruleId,
    parameters: [] as string[],
    uniqueId: EditableExcludeRulesMap[ruleId].founderEditorUniqueId!,
    parameterHash: '',
    invert: founderModelInverts(ruleId),
  };

  switch (ruleId) {
    case 'current-co-ds-categories':
    case 'summary-or-work-desc-includes':
      break;
    case 'current-co-headcount-between':
      base.parameters = ['0', '3000000'];
      break;
    case 'works-at':
    case 'held-title-past-months':
      base.parameters = ['0'];
      break;
    case 'near-any-of':
      base.parameters = ['40'];
      break;
    case 'years-of-experience-between':
      base.parameters = ['2', '6', ''];
  }
  return base;
};

const ListContainer = styled.div`
  width: 300px;
  border: 1px solid ${SEQUOIA_LIGHT_GRAY};
  height: calc(100vh - 320px);
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

const ListRow = styled.div`
  height: 40px;
  min-height: 40px;
  padding: 10px 0 0 10px;
  cursor: pointer;
`;

const EditorContainer = styled.div`
  margin: 0 0 0 30px;
  padding: 20px;
  border: 1px solid ${SEQUOIA_LIGHT_GRAY};
  flex: 1;
`;

const WarningText = styled.div`
  padding: 10px;
  background: ${Colors.Static.SEQUOIA_PASTEL_RED};
`;
