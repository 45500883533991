import { Drawer } from 'antd';
import React, { CSSProperties } from 'react';
import styled from 'styled-components/macro';
import { Colors, hexWithOpacity } from './Theme';

const { SEQUOIA_DARK_GREEN, BLACK1ALPHA, SEQUOIA_BRIGHT_RED } = Colors.Static;

export const ClickableItem = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? undefined : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? 0.45 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  user-select: none;
  &:hover {
    opacity: 0.5;
  }
`;

export type PageTitleOptions = {
  all: OrganizationAPI.Organization[];
  id: number;
};

export const usePageTitle = (
  title: string | undefined | null | false,
  isInternal: boolean,
  opts?: PageTitleOptions
) => {
  React.useEffect(() => {
    const fallback = isInternal ? 'Sequoia Talent' : 'Sequoia Talent Suite';

    if (opts?.all.length && opts.id) {
      const orgName = opts.all.filter(org => org.id === opts.id).map(org => org.name)[0];
      if (orgName) {
        document.title = `${orgName} - ${title}`;
      }
    } else if (title) {
      document.title = `${title}${isInternal ? '' : ' - Sequoia'}`;
    } else {
      document.title = fallback;
    }
    return () => {
      document.title = fallback;
    };
  }, [title, opts, isInternal]);
};

export const Dot = styled.div<{ color?: string; size?: number }>`
  background: ${({ color }) => color || SEQUOIA_DARK_GREEN};
  border-radius: 50%;
  width: ${({ size }) => `${size || 4}px`};
  height: ${({ size }) => `${size || 4}px`};
  margin: 4px;
  min-width: ${({ size }) => `${size || 4}px`};
`;

export const NotificationDot = styled(Dot)`
  background: ${SEQUOIA_BRIGHT_RED};
  margin: 8px;
  box-shadow: 0px 0px 4px ${hexWithOpacity(SEQUOIA_BRIGHT_RED, 0.6)};
`;

export const CharInCircleEl = styled.span<{ size?: number }>`
  display: flex;
  font-size: 12px;
  font-weight: 500;
  background: ${BLACK1ALPHA};
  ${({ size=22 }) => (`width: ${size}px; height: ${size}px;`)}
  border-radius: 50%;
  align-items: center;
  justify-content: center;
`;

export const CharInCircle = ({
  char,
  size,
  style,
}: { char: string | number; size?: number; style?: CSSProperties }) => {
  return <CharInCircleEl size={size} style={style}>{char}</CharInCircleEl>;
};

export const StyledDrawer = styled(Drawer)`
  &.ant-drawer-open .ant-drawer-content {
    overflow: visible;
  }
` as typeof Drawer;

export const FadeInContainer = styled.div<{ fadeInSeconds?: number }>`
  animation: fadeIn ease ${({ fadeInSeconds = 0.5 }) => fadeInSeconds}s;
  -webkit-animation: fadeIn ease ${({ fadeInSeconds = 0.5 }) => fadeInSeconds}s;
  -moz-animation: fadeIn ease ${({ fadeInSeconds = 0.5 }) => fadeInSeconds}s;
`;
