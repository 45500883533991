import { InfoCircleOutlined, WarningOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { OrganizationContext } from '../../contexts/Organization';
import { MinerButton } from '../../extension/MinerButton';
import { useMinerSheetForProfile } from '../../extension/useMinerSheetForProfile';
import { Dot } from '../../shared/Common';
import { Colors } from '../../shared/Theme';
import { ActionButton } from '../Button';
import { LightTooltip } from '../Common';
import { RowProps } from '../ListRow';
import { ScanIcon } from '../Svgs';
import { ExtensionConfig } from './ProfileDrawer';

const { SEQUOIA_BRIGHT_RED } = Colors.Static;

const SCRAPE_ERROR_MSG =
  'An error occurred when attempting to gather these connections. Please try again or cancel this scan.';

export interface NetworkRowProps {
  profile: { name: string; identifier: string };
  miner?: ExtensionConfig['miner'];
}

export function useNetworkRow(props: NetworkRowProps) {
  const { me, id } = useContext(OrganizationContext);

  const [minerSheet, minerSheetOps] = useMinerSheetForProfile(props.profile);

  const row: RowProps = { icon: <ScanIcon />, title: 'Network' };

  if (minerSheet) {
    row.content = <MinerSheetStatus status={minerSheet.status} />;

    const actions: React.ReactNode[] = [];
    if (!!minerSheet.status.error || minerSheet.status?.percent === 0) {
      actions.push(
        <ActionButton
          key="cancel"
          onClick={() => minerSheetOps.cancel(minerSheet.hash)}
          style={{ marginLeft: 16 }}
        >
          Cancel
        </ActionButton>
      );
    }
    if (!!minerSheet.status.error) {
      actions.push(
        <ActionButton key="retry" onClick={() => minerSheetOps.retry(minerSheet.hash)}>
          Retry
        </ActionButton>
      );
    }

    if (
      minerSheet.senderId === me.externalProfileId &&
      !minerSheet.status.error &&
      (minerSheet.status.percent >= 95 ||
        moment(minerSheet.createdAt) < moment().subtract(1, 'day'))
    ) {
      actions.push(
        <ActionButton
          key="rescan"
          style={{ marginLeft: 10 }}
          onClick={() => minerSheetOps.create({ recreateIfExists: true })}
        >
          Rescan
        </ActionButton>
      );
    }

    if (minerSheet.status?.percent >= 95) {
      actions.push(
        <ViewMinerSheetButton
          key="view-sheet"
          orgId={id}
          hash={minerSheet.hash}
          linkToNewTab={!!props.miner}
        />
      );
      actions.push(
        <ActionButton key="view-filtered">
          <Link
            style={{ color: 'unset' }}
            to={`/${id}/network`}
            {...(props.miner ? { target: '_blank', rel: 'noopener noreferrer' } : {})}
          >
            Filter
          </Link>
        </ActionButton>
      );
    }

    row.actions = (
      <>{actions.flatMap((a, idx) => (idx < actions.length - 1 ? [a, <Dot key={idx} />] : [a]))}</>
    );
  } else {
    row.actions = <MinerButton {...props} onClick={minerSheetOps.create} />;
  }

  return row;
}

export const MinerSheetStatus = ({ status }: { status: NetworkMinerAPI.V2ScrapeStatus }) => (
  <>
    {status.error ? (
      <LightTooltip overlay={SCRAPE_ERROR_MSG} placement="topLeft">
        <WarningOutlined style={{ color: SEQUOIA_BRIGHT_RED, fontSize: 14, marginRight: 6 }} />
        Error Occurred
      </LightTooltip>
    ) : status?.percent > 0 ? (
      `${status.percent}% complete`
    ) : (
      <div>
        Starting...
        <LightTooltip
          overlay={`We'll email you when their connections have been added to your Network tab.`}
          placement="topLeft"
        >
          <InfoCircleOutlined style={{ fontSize: 14, marginLeft: 6 }} />
        </LightTooltip>
      </div>
    )}
  </>
);

export const ViewMinerSheetButton = ({
  orgId,
  hash,
  linkToNewTab = false,
}: {
  orgId: number;
  hash: string;
  linkToNewTab?: boolean;
}) => (
  <ActionButton style={{ minWidth: 86 }}>
    <Link
      style={{ color: 'unset' }}
      to={`/${orgId}/referrals/sheet/${hash}/z`}
      {...(linkToNewTab ? { target: '_blank', rel: 'noopener noreferrer' } : {})}
    >
      View List
    </Link>
  </ActionButton>
);
