import { MailOutlined, ReloadOutlined, WarningOutlined } from '@ant-design/icons';
import { notification, Popconfirm, Radio } from 'antd';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components/macro';
import { isStoppedBounce } from '../Helpers';
import { getNameParts } from '../shared/profile-helpers';
import { makeRequest } from '../shared/Resource';
import { ScrollingContainer } from '../shared/ScrollableContainer';
import { Colors, hexWithOpacity } from '../shared/Theme';
import { IconButton } from './Button';
import { SentimentIcon } from './CandidatesTable';
import { Column, Empty, FOOTER_HEIGHT } from './Common';
import { EditEmailModal } from './modals/EditEmailModal';
import { H4Mono } from './Text';

const { SEQUOIA_GREEN, BLACK1ALPHA } = Colors.Static;
const TIME_FORMAT = `MMMM D, YYYY [at] h:mma`;

export const ConversationDrawerContent: React.FunctionComponent<{
  canManage: boolean;
  candidate: CampaignAPI.CampaignMember;
  onReload: (closeDrawer?: boolean) => void;
}> = props => {
  const [candidate, setCandidate] = React.useState<
    CampaignAPI.CampaignMember & { emails?: CampaignAPI.CampaignEmail[] }
  >(props.candidate);
  const [spinRefresh, setSpinRefresh] = React.useState<boolean>(false);
  const [downloadingEmails, setDownloadingEmails] = React.useState(false);

  React.useEffect(() => {
    const loadEmailsAsync = async () => {
      const emails = await makeRequest<CampaignAPI.CampaignEmail[]>(
        `/api/sourcing/campaign-member/${props.candidate.id}/emails`
      );
      setCandidate({ ...candidate, emails: emails });
      setDownloadingEmails(false);
    };

    setCandidate(props.candidate);
    if (candidate.emails === undefined) {
      setDownloadingEmails(true);
      loadEmailsAsync();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.candidate.id]);

  const refreshConversation = async (closeDrawerOnComplete: boolean) => {
    setSpinRefresh(true);
    const emails = await makeRequest<CampaignAPI.CampaignEmail[]>(
      `/api/sourcing/campaign-member/${candidate.id}/refresh`
    );
    const refreshedCandidate = { ...candidate, emails };
    if (refreshedCandidate) {
      setCandidate(refreshedCandidate);
      props.onReload(closeDrawerOnComplete);
    }
    if (!closeDrawerOnComplete) {
      setTimeout(() => {
        notification.success({ message: 'Conversation Refreshed' });
        setSpinRefresh(false);
      }, 1500);
    } else {
      setSpinRefresh(false);
    }
  };

  const onSentimentChosen = async (sentiment: 'Positive' | 'Negative') => {
    const candidateResponse = await makeRequest<CampaignAPI.CampaignMember>(
      `/api/sourcing/campaign-member/${props.candidate.id}/sentiment`,
      'PUT',
      {
        sentiment,
      }
    );
    if (candidateResponse.id) {
      setCandidate({ ...candidate, responseSentiment: sentiment });
      props.onReload();
    }
  };

  return (
    <Column style={{ padding: '24px 0 24px 24px', gap: 16, height: '100vh' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginRight: 24,
        }}
      >
        <H4Mono title={`${candidate.id}`}>Conversation with {candidate.profile.name}</H4Mono>
        <IconButton
          title={`Refresh conversation from email provider.`}
          onClick={() => {
            refreshConversation(false);
          }}
        >
          <ReloadOutlined spin={spinRefresh} />
        </IconButton>
      </div>
      {candidate.candidateRespondedAt && (
        <FeedbackContainer>
          <div style={{ fontSize: 14 }}>
            Is {getNameParts(candidate.profile.name).first} interested in the position?
          </div>
          <Radio.Group
            buttonStyle={'solid'}
            value={candidate.responseSentiment}
            onChange={e => {
              onSentimentChosen(e.target.value);
            }}
          >
            <StyledRadioButton accentColor={SEQUOIA_GREEN} value="Positive">
              <SentimentIcon sentiment={'Positive'} />
              Yes
            </StyledRadioButton>
            <StyledRadioButton accentColor={'#E43D30'} value="Negative">
              <SentimentIcon sentiment={'Negative'} />
              No
            </StyledRadioButton>
          </Radio.Group>
        </FeedbackContainer>
      )}
      {candidate.emails?.length ? (
        <Conversation
          canManage={props.canManage}
          candidate={candidate}
          onRequestRefreshConversation={() => {
            refreshConversation(true);
          }}
        />
      ) : (
        <div
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          {!downloadingEmails && <Empty description={'No emails'} />}
        </div>
      )}
    </Column>
  );
};

const Conversation: React.FunctionComponent<{
  canManage: boolean;
  candidate: CampaignAPI.CampaignMember & { emails?: CampaignAPI.CampaignEmail[] };
  onRequestRefreshConversation: () => void;
}> = ({ canManage, candidate, onRequestRefreshConversation }) => {
  if (!candidate.emails) {
    return <></>;
  }
  return (
    <ScrollingContainer>
      {candidate.emails
        .filter(e => e.sentAt)
        .sort((e1, e2) => moment(e2.sentAt!).diff(moment(e1.sentAt!), 'seconds'))
        .map(e => (
          <CampaignEmailEntry
            key={e.id}
            email={e}
            candidate={candidate}
            onRequestRefreshConversation={onRequestRefreshConversation}
            canManage={canManage}
          />
        ))}
      {candidate.stoppedReason && <CandidateErrorEntry stoppedReason={candidate.stoppedReason} />}
    </ScrollingContainer>
  );
};

const FeedbackContainer = styled.div`
  border-top: 1px solid ${BLACK1ALPHA};
  margin-right: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: ${FOOTER_HEIGHT}px;
`;

const CampaignEmailEntry: React.FunctionComponent<{
  canManage: boolean;
  candidate: CampaignAPI.CampaignMember;
  email: CampaignAPI.CampaignEmail;
  onRequestRefreshConversation: () => void;
}> = ({ canManage, email, candidate, onRequestRefreshConversation }) => {
  const [showRestartModal, setShowRestartModal] = React.useState<boolean>(false);

  return (
    <ConversationEmailContainer>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <div>From: {email.from}</div>
          <div>To: {email.to}</div>
        </div>
        <div>
          {email.direction === 'inbound' && canManage && (
            <div style={{ textAlign: 'right' }}>
              <ManualBouncePopover
                candidate={candidate}
                onRequestRefreshConversation={onRequestRefreshConversation}
                onRequestRestart={() => {
                  setShowRestartModal(true);
                }}
              />
              {showRestartModal && (
                <EditEmailModal
                  open={true}
                  onClose={modifiedProfile => {
                    if (modifiedProfile) {
                      onRequestRefreshConversation();
                    }
                    setShowRestartModal(false);
                  }}
                  profile={candidate.profile}
                  bouncingAddress={candidate.email}
                />
              )}
            </div>
          )}
          <div>{moment(email.sentAt!).format(TIME_FORMAT)}</div>
        </div>
      </div>
      {email.html ? (
        <div style={{ marginTop: 10 }} dangerouslySetInnerHTML={{ __html: email.html }} />
      ) : (
        <div style={{ marginTop: 10, whiteSpace: 'pre-wrap' }}>{email.plainText}</div>
      )}
    </ConversationEmailContainer>
  );
};

const ManualBouncePopover: React.FunctionComponent<{
  candidate: CampaignAPI.CampaignMember;
  onRequestRestart: () => void;
  onRequestRefreshConversation: () => void;
}> = ({ candidate, onRequestRestart, onRequestRefreshConversation }) => {
  const [visible, setVisible] = React.useState<boolean>(false);

  const reportManualBounce = async () => {
    const candidateResp = await makeRequest<CampaignAPI.CampaignMember>(
      `/api/sourcing/campaign-member/${candidate.id}/bounced`,
      'POST',
      {}
    );
    if (candidateResp.id) {
      notification.success({ message: 'Marked email as bounced' });
    }
    onRequestRefreshConversation();
  };

  return (
    <Popconfirm
      overlayStyle={{ maxWidth: 250 }}
      placement={'left'}
      open={visible}
      title={`Discover or input a new email address for ${candidate.profile.name}?`}
      onOpenChange={setVisible}
      trigger="click"
      okText="Yes"
      okButtonProps={{ type: 'link' }}
      cancelButtonProps={{ type: 'link' }}
      cancelText="No"
      onConfirm={onRequestRestart}
      onCancel={async () => {
        await reportManualBounce();
      }}
      icon={<MailOutlined />}
    >
      <div
        style={{
          cursor: 'pointer',
          display: 'inline-block',
          color: Colors.Static.DESTRUCTIVE_TINT,
        }}
        onClick={() => setVisible(!visible)}
      >
        {isStoppedBounce(candidate) ? 'Reported As Bounce' : 'Report Bounce'}
      </div>
    </Popconfirm>
  );
};

const CandidateErrorEntry: React.FunctionComponent<{ stoppedReason: string }> = ({
  stoppedReason,
}) => {
  return (
    <ConversationEmailContainer isError={true}>
      <WarningOutlined style={{ marginRight: 10, fontSize: 16 }} />
      {stoppedReason}
    </ConversationEmailContainer>
  );
};

const ConversationEmailContainer = styled.div<{ isError?: boolean }>`
  padding: 20px;
  margin-bottom: 20px;
  background: ${({ isError }) => (isError ? hexWithOpacity('#E43D30', 0.1) : 'none')};
  border: ${({ isError }) => (isError ? 'none' : `1px solid ${BLACK1ALPHA}`)};
  animation: fadeIn ease 0.5s;
  -webkit-animation: fadeIn ease 0.5s;
  -moz-animation: fadeIn ease 0.5s;
`;

const StyledRadioButton = styled(Radio.Button)<{ accentColor: string }>`
  &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    z-index: 1;
    color: ${({ accentColor }) => accentColor};
    background: #fff;
    border-color: ${({ accentColor }) => accentColor};
  }

  &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: ${({ accentColor }) => accentColor};
  }
`;
