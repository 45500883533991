import { pick } from 'lodash';
import React from 'react';
import styled from 'styled-components/macro';
import { ActionButton } from '../components/Button';
import { CampaignSenderInput } from '../components/CampaignSenderInput';
import { CCBCCRow } from '../components/CCBCCRow';
import { HorizontalDivider } from '../components/Common';
import { RequiredText, SeqInput } from '../components/Input';
import { RoleSelect } from '../components/selects/RoleSelect';
import { SeqCheckbox } from '../components/SeqCheckbox';
import { Label } from '../components/Text';
import { SubheaderSection } from '../leads/LeadListHeader';

export const ACTION_ICON_SIZE = 18;

export const EditCampaignForm: React.FunctionComponent<{
  campaign: CampaignAPI.Campaign;
  onChange: (info: CampaignAPI.Campaign) => void;
}> = ({ campaign, onChange }) => {
  const settings = campaign.settings;
  const onChangeSettings = (changes: Partial<CampaignAPI.Campaign['settings']>) =>
    onChange({ ...campaign, settings: { ...settings, ...changes } });

  const [showCc, setShowCc] = React.useState(settings.cc?.length > 0 || settings.bcc?.length > 0);
  React.useEffect(() => {
    if (settings.cc?.length > 0 || settings.bcc?.length > 0) {
      setShowCc(true);
    }
  }, [settings, setShowCc]);

  return (
    <div
      style={{
        display: 'flex',
        gap: 24,
        justifyContent: 'flex-end',
        flexWrap: 'wrap',
        width: 'auto',
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', gap: 16, flex: 1 }}>
        <RowContainer>
          <div style={{ width: 200, flexShrink: 0 }}>
            <Label>Campaign Name</Label>
            <RequiredText />:
          </div>
          <SeqInput
            autoFocus
            outline="rect"
            size="small"
            value={campaign.name}
            onChange={e => onChange({ ...campaign, name: e.currentTarget.value })}
            style={{ marginLeft: 12 }}
          />
        </RowContainer>
        <RowContainer>
          <div style={{ width: 200, flexShrink: 0 }}>
            <Label>Subject</Label>
            <RequiredText />:
          </div>
          <SeqInput
            style={{ marginLeft: 12 }}
            size="small"
            outline="rect"
            value={settings.subject}
            onChange={e =>
              onChange({
                ...campaign,
                settings: { ...settings, subject: e.currentTarget.value },
              })
            }
          />
        </RowContainer>
        <RowContainer>
          <div style={{ width: 200, flexShrink: 0 }}>
            <Label>Sender</Label>
            <RequiredText />:
          </div>
          <CampaignSenderInput
            disabled={campaign.id !== -1}
            campaign={campaign}
            onChange={onChange}
          />
        </RowContainer>
        {showCc ? (
          <CCBCCRow
            style={{ flexDirection: 'row', gap: 30 }}
            value={pick(settings, ['cc', 'bcc'])}
            onChange={onChangeSettings}
          />
        ) : (
          <div style={{ marginLeft: 212 }}>
            <ActionButton onClick={() => setShowCc(true)}>Add CC/BCC Recipients</ActionButton>
          </div>
        )}
      </div>
      <SubheaderSection style={{ gap: 12, padding: 16, margin: 'unset' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Label>Role</Label>
          <RequiredText />:
          <div>
            <RoleSelect
              role={campaign.roleId}
              setRole={roleId => roleId && onChange({ ...campaign, roleId })}
              optionsPlacement="bottomRight"
              style={{ background: 'white', marginLeft: 12, height: 34 }}
            />
          </div>
        </div>
        <HorizontalDivider />
        <div style={{ display: 'flex', userSelect: 'none' }}>
          <SeqCheckbox
            style={{ display: 'inline' }}
            id="weekdaysOnly"
            checked={settings.weekdaysOnly}
            onChange={checked => {
              onChangeSettings({ weekdaysOnly: !!checked.currentTarget.checked });
            }}
          />
          <Label htmlFor="weekdaysOnly">Send on Weekdays only</Label>
        </div>
      </SubheaderSection>
    </div>
  );
};

const RowContainer = styled.div`
  display: flex;
  align-items: baseline;
`;
