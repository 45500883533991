import { WarningOutlined } from '@ant-design/icons';
import { notification } from 'antd';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { SeqButton } from '../components/Button';
import { CCBCCRow } from '../components/CCBCCRow';
import { Column, FlexFillingColCSS, FlexFillingRowCSS } from '../components/Common';
import { CursorBodyState, ExternalTemplateEditor } from '../components/ExternalTemplateEditor';
import { buildVariablesMap, TemplateContext } from '../components/ExternalTemplateSupport';
import { SeqInput } from '../components/Input';
import { ScheduledSendButton } from '../components/ScheduledSendButton';
import { H3Mono, Label } from '../components/Text';
import { CampaignSummaryWithSender } from '../hooks/useVisibleCampaigns';
import { createEmailPreview, EXTERNAL_RECIPIENT_VARIABLES, isValidEmail } from '../shared/helpers';
import { ScrollingContainer } from '../shared/ScrollableContainer';
import { Colors } from '../shared/Theme';

const { DESTRUCTIVE_TINT, SEQUOIA_BLACK } = Colors.Static;

export const CustomizeEmail: React.FunctionComponent<{
  campaign: CampaignSummaryWithSender;
  candidate: ExternalAPI.Profile;
  footer: {
    disabled?: boolean | string;
    onCancel: () => void;
    onSubmit: (overrides?: CampaignAPI.CandidateOverride) => void;
  };
}> = ({ candidate, campaign, footer }) => {
  const { settings, sender } = campaign;
  const template = campaign.templates[0];
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [saving, setSaving] = useState<boolean>(false);
  const [cursorBodyState, setCursorBodyState] = useState<CursorBodyState>({
    text: template.body,
  });
  const [overrides, setOverrides] = useState<
    Required<Pick<CampaignAPI.CandidateOverride, 'to' | 'bcc' | 'cc' | 'subject'>>
  >({ to: '', subject: settings.subject, cc: settings.cc, bcc: settings.bcc });

  useEffect(() => {
    const previewText = createEmailPreview(cursorBodyState.text, '', {
      ...EXTERNAL_RECIPIENT_VARIABLES,
      ...sender.templateVariables,
    });
    if (previewText.body.match(/\{\{(.*?)\}\}/)) {
      setErrorMessage(`Invalid template variable.`);
    } else {
      setErrorMessage('');
    }
  }, [cursorBodyState.text, campaign, sender.templateVariables]);

  const onAcceptAndSend = (deliverAt: Date) => {
    if (overrides.to && !isValidEmail(overrides.to)) {
      notification.warning({ message: 'Invalid recipient email address.' });
      return;
    }
    if (!overrides.subject) {
      notification.warning({ message: 'Email must have a subject.' });
      return;
    }
    if (overrides.bcc.some(email => !isValidEmail(email))) {
      notification.warning({ message: `One of the BCC'd email addresses is invalid.` });
      return;
    }
    if (overrides.cc.some(email => !isValidEmail(email))) {
      notification.warning({ message: `One of the CC'd email addresses is invalid.` });
      return;
    }
    if (!footer || saving) {
      return;
    }
    setSaving(true);

    footer.onSubmit({ ...overrides, introBody: cursorBodyState.text, deliverAt });
    setSaving(false);
  };

  const variables = buildVariablesMap(
    { ...sender },
    EXTERNAL_RECIPIENT_VARIABLES,
    candidate,
    sender.templateVariables
  );

  const missingEmail = !isValidEmail(candidate.email);

  return (
    <Container style={{ borderLeft: `1px solid ${SEQUOIA_BLACK}` }}>
      <ContentContainer>
        <H3Mono style={{ marginRight: 24 }}>Customize Email</H3Mono>
        <ScrollingContainer style={{ margin: '16px 0', flex: 1 }}>
          <Column style={{ gap: 16 }}>
            <div>
              <Label style={{ marginTop: 0, marginRight: 12 }}>To</Label>
              <span>
                {candidate.name} {!missingEmail && `<${candidate.email}>`}
              </span>
              {missingEmail && (
                <SeqInput
                  size="small"
                  outline="rect"
                  value={overrides.to}
                  onChange={e => setOverrides({ ...overrides, to: e.target.value })}
                  helperText={
                    !isValidEmail(overrides.to || '') ? (
                      <div style={{ color: Colors.Static.SEQUOIA_BRIGHT_RED }}>
                        <WarningOutlined style={{ fontSize: 16, marginRight: 6 }} />
                        {overrides.to.length && !isValidEmail(overrides.to)
                          ? 'Invalid email'
                          : 'We were unable to find an email for this candidate. Please enter one manually.'}
                      </div>
                    ) : undefined
                  }
                />
              )}
            </div>
            <CCBCCRow
              value={{ cc: overrides.cc, bcc: overrides.bcc }}
              onChange={({ cc, bcc }) => {
                setOverrides({ ...overrides, cc, bcc });
              }}
            />
            <div style={{ display: 'flex', flexDirection: 'column', gap: 6 }}>
              <Label>Subject</Label>
              <SeqInput
                size="small"
                outline="rect"
                value={overrides.subject}
                onChange={e =>
                  setOverrides({ ...overrides, subject: e.currentTarget.value.toString() })
                }
              />
            </div>
            <div>
              <TemplateContext.Provider value={{ variables, recipient: candidate }}>
                <ExternalTemplateEditor
                  value={cursorBodyState}
                  setValue={setCursorBodyState}
                  sender={sender}
                  config={{ subject: overrides.subject || campaign.settings.subject }}
                />
              </TemplateContext.Provider>
              <div
                style={{
                  display: 'flex',
                  marginTop: 10,
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                }}
              >
                <div style={{ color: DESTRUCTIVE_TINT }}> {errorMessage}</div>
              </div>
            </div>
          </Column>
        </ScrollingContainer>
        {footer && (
          <div
            style={{
              display: 'flex',
              marginRight: 24,
              gap: 10,
              justifyContent: 'flex-end',
            }}
          >
            <SeqButton onClick={footer.onCancel}>Cancel</SeqButton>
            <ScheduledSendButton
              campaign={campaign}
              disabled={footer.disabled}
              defaultToLastUsed
              onSend={onAcceptAndSend}
            />
          </div>
        )}
      </ContentContainer>
    </Container>
  );
};

const Container = styled.div`
  ${FlexFillingRowCSS}
  min-height: 0;
`;

const ContentContainer = styled.div`
  margin: 20px 0 24px 24px;
  ${FlexFillingColCSS}
`;
