import { useResource } from '../shared/Resource';

export const MAX_NETWORK_PROFILE_COUNT = 500;

export const useNetworkProfiles = (orgId: number, search: { id?: number; data: string }) => {
  const [profiles, profileOps] = useResource<NetworkAPI.NetworkProfile[]>(
    `/api/${orgId}/network-profiles/search`,
    { take: MAX_NETWORK_PROFILE_COUNT, ...search }
  );

  return {
    profileOps,
    profiles: profiles || [],
    errored: false,
    loadingProfiles: !profiles,
  };
};
