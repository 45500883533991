export const reqExtStartMinerSheet = (target: {
  identifier: string;
  name: string | null;
  hash: string;
}) => {
  // Older founder tools extension users
  (window.parent || window).postMessage({
    type: 'seqfounder:send-to-background',
    payload: { type: 'request-scrapes' },
  });

  // Newer founder tools extension users
  (window.parent || window).postMessage(
    { type: 'seqfounder:send-to-background', payload: { type: 'miner-start', target } },
    '*'
  );
};
