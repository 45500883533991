import React from 'react';
import { Colors } from '../Theme';
import { Explanatory, HeaderRow, RuleEditorProps } from './RuleEditorCommon';

export const SingleParamEditor: React.FC<RuleEditorProps> = props => {
  const { rule, title, style, onChange } = props;

  return (
    <div style={style}>
      {title && <HeaderRow label={title} onChange={onChange} rule={rule} />}
      <Explanatory>
        <span style={{ color: Colors.Static.SEQUOIA_LIGHT_TEXT }}>
          This rule requires no additional parameters.
        </span>
      </Explanatory>
    </div>
  );
};
