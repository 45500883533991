import React, { useContext, useEffect, useState } from 'react';
import { reqExtStartMinerSheet } from '../chrome/extension-coms';
import { OrganizationContext } from '../contexts/Organization';
import { useInterval } from '../hooks/useInterval';
import { makeRequest } from '../shared/Resource';

export function useMinerSheetForProfile({
  identifier,
  name,
}: {
  identifier: string;
  name: string;
}) {
  const [minerSheet, setMinerSheet] = useState<NetworkMinerAPI.MinerSheet | undefined>();
  const { me, id: orgId } = useContext(OrganizationContext);

  const refresh = React.useCallback(async () => {
    if (!identifier) {
      setMinerSheet(undefined);
      return;
    }
    const sheets = await makeRequest<NetworkMinerAPI.MinerSheet[]>(
      `/api/${orgId}/miner-sheets?targetIdentifier=${identifier}`
    );
    setMinerSheet(sheets?.[0]);
  }, [orgId, identifier]);

  const create = async (opts?: { recreateIfExists?: boolean; minerSheetsShared?: boolean }) => {
    if (!identifier) {
      return;
    }
    const sheet = await makeRequest<NetworkMinerAPI.MinerSheet>(
      `/api/${orgId}/user/${me.id}/miner-sheets`,
      'POST',
      {
        target: { name, identifier },
        forceCreate: opts?.recreateIfExists || undefined,
        minerSheetsShared: me.identifier === identifier,
      }
    );
    await refresh();

    reqExtStartMinerSheet({ name, identifier, hash: sheet.hash });
  };

  const cancel = async (sheetHash: string) => {
    await makeRequest(`/api/${orgId}/miner-sheets/${sheetHash}`, 'DELETE');
    await refresh();
  };

  const retry = async (sheetHash: string) => {
    const sheet = await makeRequest<NetworkMinerAPI.MinerSheet>(
      `/api/${orgId}/miner-sheets/${sheetHash}/retry`,
      'PUT',
      {}
    );
    await refresh();
    reqExtStartMinerSheet({
      hash: sheet.hash,
      name: sheet.target.name,
      identifier: sheet.target.identifier,
    });
  };

  useEffect(() => {
    if (identifier) {
      refresh();
    }
  }, [refresh, identifier]);

  useInterval(
    () => identifier && refresh(),
    minerSheet && minerSheet.status.percent < 100 ? 5 * 1000 : null
  );

  return [minerSheet, { refresh, cancel, retry, create }] as const;
}

export type MinerSheetOps = ReturnType<typeof useMinerSheetForProfile>[1];
