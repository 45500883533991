import { Select } from 'antd';
import React from 'react';
import { LI_INDUSTRY_TAGS } from './DSConstants';
import {
  EditableExcludeRulesMap,
  EditableRuleIds,
  Explanatory,
  HeaderRow,
  invalid,
  onChangeWithValidation,
  Rule,
  RuleEditorProps,
  useDescription,
  valid,
  ValidationInfo,
} from './RuleEditorCommon';
const { Option } = Select;

export const LIIndustryTagEditor: React.FunctionComponent<RuleEditorProps> = ({
  title,
  rule,
  onChange,
  style,
  ruleType,
}) => {
  const description = useDescription(
    { rule, ruleType },
    {
      include: 'Boost people who currently work at a company tagged with any of:',
      exclude: `Exclude people who currently work at a company tagged with any of:`,
      invert: `Only include people who currently work at a company tagged with:`,
    }
  );

  return (
    <div style={style}>
      {title && <HeaderRow label={title} onChange={onChange} rule={rule} />}
      <Explanatory>{description}</Explanatory>
      <Select
        value={rule.parameters}
        style={{ width: '50%' }}
        mode={'multiple'}
        placeholder="Pick some tags"
        onChange={(tagsSelected: string[]) => {
          onChangeWithValidation({ ...rule, parameters: tagsSelected }, onChange, validate);
        }}
      >
        {LI_INDUSTRY_TAGS.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase())).map(
          tag => (
            <Option value={tag} key={tag}>
              {tag}
            </Option>
          )
        )}
      </Select>
    </div>
  );
};

const validate = (rule: Rule): ValidationInfo => {
  const ruleLabel = EditableExcludeRulesMap[rule.id as EditableRuleIds]?.label || '';
  if (!rule.parameters || rule.parameters.filter(Boolean).length < 1) {
    return invalid(`${ruleLabel} requires at least 1 tag.`);
  }
  return valid();
};
