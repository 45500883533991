import { Cascader } from 'antd';
import { DefaultOptionType } from 'antd/lib/cascader';
import { uniq } from 'lodash';
import React, { useContext } from 'react';
import { OrganizationContext } from '../contexts/Organization';
import { useVisibleCampaigns } from '../hooks/useVisibleCampaigns';
import { ClickableItem } from '../shared/Common';
import { CopyIcon, IconContainer } from './Svgs';

export const TemplateSelect: React.FunctionComponent<{
  onTemplateSelected: (template: CampaignAPI.TemplateInfo) => void;
}> = ({ onTemplateSelected }) => {
  const campaigns = useVisibleCampaigns({ includeArchived: false });
  const orgIds = uniq(campaigns.map(c => c.organizationId));
  const myOrg = useContext(OrganizationContext);

  let treeData: DefaultOptionType[] = orgIds.map(orgId => {
    const org = myOrg.id === orgId ? myOrg : myOrg.all.find(o => o.id === orgId)!;
    const orgCampaigns = campaigns.filter(c => c.organizationId === orgId);

    return {
      label: <span style={{ paddingTop: 10, paddingBottom: 10, marginRight: 15 }}>{org.name}</span>,
      value: org.id,
      selectable: false,
      children: orgCampaigns.map(campaign => ({
        selectable: false,
        label: campaign.name,
        value: campaign.name,
        children: campaign.templates.map((t, idx) => ({
          selectable: false,
          label: templateTitle(idx || 0),
          value: `${t.id}`,
        })),
      })),
    };
  });

  //For non talent users, only one org will be visible so eliminate the first cascade
  if (treeData.length === 1) {
    treeData = treeData[0]?.children || [];
  }

  if (treeData.length === 0) {
    return <></>;
  }

  return (
    <Cascader
      expandTrigger={'click'}
      style={{ width: 40, height: 30, background: 'transparent' }}
      suffixIcon={
        <IconContainer>
          <ClickableItem>
            <CopyIcon />
          </ClickableItem>
        </IconContainer>
      }
      bordered={false}
      options={treeData}
      value={[]}
      onChange={selectedVals => {
        const template = campaigns
          .flatMap(c => c.templates)
          .find(t => `${t.id}` === selectedVals[selectedVals.length - 1]);
        if (!template) {
          return;
        }
        onTemplateSelected(
          Object.assign({}, { ...template, sender: undefined, campaignInfo: undefined })
        );
      }}
    />
  );
};

export const templateTitle = (index: number) => {
  switch (index) {
    case 0:
      return 'Introductory Email';
    case 1:
      return 'First Followup';
    case 2:
      return 'Second Followup';
    case 3:
      return 'Third Followup';
    case 4:
      return 'Fourth Followup';
    case 5:
      return 'Fifth Followup';
    case 6:
      return 'Sixth Followup';
    case 7:
      return 'Seventh Followup';
    case 8:
      return 'Eighth Followup';
    case 9:
      return 'Ninth Followup';
    default:
      return 'Followup';
  }
};
