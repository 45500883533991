import React from 'react';
import { Star } from '../hooks/useProfileTableConfig';
import { escapeStringForUseInRegex } from '../shared/helpers';
import { hexWithOpacity, SecondaryColors } from '../shared/Theme';
import { LightTooltip, Tag, TagsContainer } from './Common';
import { SeqTag } from './SeqTag';

export const Badges: React.FC<{ annotation?: LeadsAPI.Annotation }> = ({ annotation }) =>
  annotation ? (
    <TagsContainer style={{ fontSize: 13 }}>
      {annotation.badges.map((b, idx) => {
        return b.type === 'openToOpps' ? (
          <Tag
            key={idx}
            style={{
              backgroundColor: hexWithOpacity(SecondaryColors.SKY_BLUE, 0.3),
              fontSize: 13,
              padding: '4px 8px 4px 6px',
            }}
          >
            <img src="/icons/open-door.svg" alt="open door" style={{ width: 14, marginLeft: 2 }} />
            <div style={{ marginTop: 3 }}>Open to Opportunities</div>
          </Tag>
        ) : (
          <Tag
            key={idx}
            style={{
              backgroundColor: hexWithOpacity(SecondaryColors.YELLOW, 0.3),
              padding: '4px 8px 4px 6px',
            }}
          >
            <Star style={{ marginTop: 2, fontSize: 17 }} />
            <div style={{ marginTop: 3 }}>Vouched by Sequoia Network</div>
          </Tag>
        );
      })}
    </TagsContainer>
  ) : (
    <></>
  );

export const CondensedBadges: React.FC<{ annotation?: LeadsAPI.Annotation }> = ({ annotation }) =>
  annotation ? (
    <TagsContainer>
      {annotation.badges.map((b, idx) => {
        return b.type === 'openToOpps' ? (
          <SeqTag
            key={idx}
            style={{ marginLeft: idx ? -16 : undefined }}
            tagColor="transparent"
            description="Open to Opportunities"
            tagName={
              <img src="/icons/open-door.svg" alt="open door" style={{ width: 13, marginTop: 1 }} />
            }
          />
        ) : (
          <SeqTag
            key={idx}
            style={{ marginLeft: idx ? -16 : undefined }}
            tagColor="transparent"
            description="Vouched by Sequoia network"
            tagName={<Star style={{ fontSize: 15 }} />}
          />
        );
      })}
    </TagsContainer>
  ) : (
    <></>
  );

export const HighlightedEl: React.FC<{
  str: string;
  highlights: LeadsAPI.Annotation['highlights'];
}> = ({ str, highlights }): JSX.Element => {
  if (!highlights.length) return <>{str}</>;

  const strWithHighlights: (string | JSX.Element)[] = [];
  const highlightRegex = new RegExp(
    highlights.map(h => escapeStringForUseInRegex(h.text)).join('|'),
    'i'
  );

  function buildHighlightedEl(str: string) {
    if (str.length) {
      const match = highlightRegex.exec(str);
      if (!match) {
        strWithHighlights.push(str);
      } else {
        if (match.index) {
          strWithHighlights.push(str.slice(0, match.index));
        }
        strWithHighlights.push(<HighlightTooltip highlights={highlights} match={match[0]} />);
        buildHighlightedEl(str.slice(match.index + match[0].length));
      }
    }
  }

  buildHighlightedEl(str);

  return <>{strWithHighlights.map(s => s)}</>;
};

const HighlightTooltip: React.FC<{
  highlights: LeadsAPI.Annotation['highlights'];
  match: string;
}> = ({ highlights, match }) => {
  const description = highlights.find(
    h => h.text.toLowerCase() === match.toLowerCase()
  )?.description;

  return description ? (
    <LightTooltip placement="topLeft" overlay={description}>
      <span
        style={{
          fontWeight: 500,
          backgroundColor: hexWithOpacity(SecondaryColors.LILAC, 0.3),
          padding: '4px 0',
        }}
      >
        {match}
      </span>
    </LightTooltip>
  ) : (
    <>{match}</>
  );
};
