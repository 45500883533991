import {
  EditableExcludeRulesMap,
  EditableRuleIds,
  invalid,
  onChangeWithValidation,
  RuleEditorProps,
  useDescription,
  valid,
  ValidationInfo,
} from '../rule-editors/RuleEditorCommon';
import { EditorInput, HeaderRow, Rule } from './RuleEditorCommon';

export const AttendedBootcampEditor: React.FC<RuleEditorProps> = ({
  rule,
  onChange,
  style,
  title,
  ruleType,
}) => {
  const replaceParam = (value: string | null, index: number) => {
    if (value === null) {
      onChangeWithValidation(
        { ...rule, parameters: rule.parameters.filter((_, idx) => idx !== index) },
        onChange,
        validate
      );
    } else {
      const replaced = [...rule.parameters];
      replaced[index] = value;
      onChangeWithValidation({ ...rule, parameters: replaced }, onChange, validate);
    }
  };

  const jobTitles = [...rule.parameters.slice(2)];
  if (jobTitles.every(j => !!j)) {
    jobTitles.push('');
  }

  const description = useDescription(
    { rule, ruleType },
    {
      include: 'Boost people who attended a coding bootcamp recently.',
      exclude: 'Remove people who attended a coding bootcamp recently.',
      invert: 'Keep only people who attended coding bootcamp recently.',
    }
  );

  return (
    <div style={style}>
      {title && <HeaderRow label={title} onChange={onChange} rule={rule} />}
      <div
        style={{
          display: 'flex',
          marginTop: 25,
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div>{description}</div>
        <EditorInput
          placeholder={'years'}
          style={{ width: 100 }}
          value={rule.parameters[0]}
          onChange={e => replaceParam(e.currentTarget.value, 0)}
        />
      </div>
    </div>
  );
};

const validate = (rule: Rule): ValidationInfo => {
  if (
    !rule.parameters ||
    rule.parameters.length !== 1 ||
    isNaN(Number(rule.parameters[0])) ||
    Number(rule.parameters[0]) <= 0
  ) {
    const ruleLabel = EditableExcludeRulesMap[rule.id as EditableRuleIds]?.label || '';
    return invalid(
      `${ruleLabel} requires a positive number in the parameters to evaluate people who attended coding bootcamps.`
    );
  }
  return valid();
};
