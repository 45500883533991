import React, { useState } from 'react';
import { Redirect, RouteComponentProps } from 'react-router';
import styled from 'styled-components/macro';
import { SeqButton } from '../components/Button';
import { SequoiaBrand } from '../components/navigation/FeatureSidebar';
import { Container } from '../onboarding/PageOnboardingForm';
import { useResource } from '../shared/Resource';
import { RemotePolicyEditor } from './RemotePolicyEditor';
import { RoleForm } from './RoleForm';

type Info = InternalActionQueueAPI.RoleAudit;
type RoleAuditResponse = InternalActionQueueAPI.RoleAuditResponse;

const EMPTY_RESPONSE = (p: PeopleAPI.AuditEmailPosition) =>
  // SATISFIES ERROR
  ({ id: p.id, response: 'open', remotePolicy: p.remotePolicy } as RoleAuditResponse);

const EMPTY_REMOTE_POLICY: CompanyAPI.RemotePolicy = {
  asOf: 0,
  type: 'In person',
};

export const PageRoleAudit = (props: RouteComponentProps<{ hash: string }>) => {
  const [info, infoActions] = useResource<Info | { error: string }>(
    `/api/role-audit/${props.match.params.hash}`
  );

  const [state, setState] = useState<null | 'submitting' | 'submitted'>(null);
  const [responses, setResponses] = useState<RoleAuditResponse[]>([]);
  const [companyRemotePolicy, setCompanyRemotePolicy] =
    useState<CompanyAPI.RemotePolicy>(EMPTY_REMOTE_POLICY);

  React.useEffect(() => {
    if (!info) return;
    setResponses(('responses' in info && info.responses) || []);
    setCompanyRemotePolicy('company' in info ? info.company.remotePolicy : EMPTY_REMOTE_POLICY);
  }, [info]);

  if (!info) {
    return (
      <Container
        style={{ alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}
      >
        <SequoiaBrand />
        <StyledFormContainer />
      </Container>
    );
  }
  if ('error' in info) {
    return <Redirect to={`/role-audit-resend/${props.match.params.hash}`} />;
  }

  const recentlyReviewed = info.positions.filter(p => p.recentlyReviewed);
  const company = { ...info.company, remotePolicy: companyRemotePolicy };

  const onSubmit = () => {
    setState('submitting');
    infoActions.put({ ...info, company, responses });
    setState('submitted');
  };

  return (
    <Container
      style={{
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        height: 'initial',
        minHeight: '100vh',
        paddingTop: 50,
        paddingBottom: 50,
      }}
    >
      <SequoiaBrand />
      <StyledFormContainer>
        {info.positions.length === 0 ? (
          <Header style={{ lineHeight: 1.35, textAlign: 'center' }}>
            There are no open roles for you to review at this time.
          </Header>
        ) : state === 'submitted' ? (
          <Header style={{ lineHeight: 1.35, textAlign: 'center' }}>
            Thanks for reviewing your open roles and helping us direct our efforts.
          </Header>
        ) : (
          <>
            <Header style={{ paddingBottom: 24, lineHeight: 1.35 }}>
              Please verify your company's remote policy.
            </Header>
            <RemotePolicyEditor
              company={info.company}
              remotePolicy={companyRemotePolicy}
              onChange={setCompanyRemotePolicy}
            />
            <Header style={{ paddingTop: 48, paddingBottom: 24, lineHeight: 1.35 }}>
              Please let us know if the following roles are still open at your company.
            </Header>

            {info.positions
              .filter(p => !p.recentlyReviewed)
              .map(p => (
                <RoleForm
                  key={p.id}
                  position={p}
                  company={company}
                  auditResponse={responses.find(r => r.id === p.id) || EMPTY_RESPONSE(p)}
                  onChange={r => setResponses([...responses.filter(r => r.id !== p.id), r])}
                />
              ))}

            {recentlyReviewed.length > 0 && (
              <Header style={{ marginTop: 48, marginBottom: 24, fontSize: 18 }}>
                Other roles we've talked about recently
              </Header>
            )}

            {recentlyReviewed.map(p => (
              <RoleForm
                key={p.id}
                position={p}
                company={company}
                auditResponse={responses.find(r => r.id === p.id) || EMPTY_RESPONSE(p)}
                onChange={r => setResponses([...responses.filter(r => r.id !== p.id), r])}
              />
            ))}

            <div style={{ display: 'flex', justifyContent: 'center', marginTop: 24 }}>
              <SeqButton loading={state === 'submitting'} onClick={onSubmit} intent="primary">
                {state === 'submitting' ? 'Submitting' : 'Submit'}
              </SeqButton>
            </div>
          </>
        )}
      </StyledFormContainer>
    </Container>
  );
};

const Header = styled.div`
  font-size: 24px;
  text-align: left;

  @media (max-width: 800px) {
    font-size: 20px;
  }
`;

export const FormContainer = styled.div`
  background: white;
  padding: 48px;
  width: 458px;
`;

const StyledFormContainer = styled(FormContainer)`
  width: 650px;
  @media (max-width: 800px) {
    padding: 18px;
    width: 100%;
  }
`;
