import React from 'react';
import styled from 'styled-components/macro';
import { Colors } from '../shared/Theme';

const { SEQUOIA_BLACK } = Colors.Static;

export const TextButton: React.FunctionComponent<
  React.ButtonHTMLAttributes<HTMLButtonElement> & {
    link?: boolean;
  }
> = props => {
  return !!props.link ? (
    <LinkButton {...props}>{props.children}</LinkButton>
  ) : (
    <StyledButton {...props}>{props.children}</StyledButton>
  );
};

const StyledButton = styled.button<{ disabled?: boolean }>`
  background: white;
  border-radius: 0;
  border: none;
  cursor: ${({ disabled }) => (disabled ? 'inherit' : 'pointer')};
  font-size: 16px;
  line-height: 19px;
  color: ${SEQUOIA_BLACK};
  padding: 12px;
  outline: none;
  & :hover {
    background: ${({ disabled }) => (disabled ? 'inherit' : '#f1f1f1')};
    color: ${({ disabled }) => (disabled ? 'inherit' : '#737373')};
    -webkit-transition: background 0.3s;
    transition: background 0.3s;
  }
`;

const LinkButton = styled(StyledButton)`
  padding: 0px 12px 0px 12px;
  font-size: 14px;
  & :hover {
    background: unset;
    color: ${({ disabled }) => (disabled ? 'inherit' : '#737373')};
  }
`;
