import React from 'react';
import styled from 'styled-components/macro';
import { SeqButton } from '../../components/Button';
import { SeqModal } from '../../components/Modal';
import { StarExplanatory, UnicornExplanatory } from '../../components/Star';
import { H3Mono } from '../../components/Text';
import { getNameParts } from '../../shared/profile-helpers';
import { ScrollingContainer } from '../../shared/ScrollableContainer';
import { Colors } from '../../shared/Theme';

const LOCAL_STORAGE_KEY = 'HasSeenSequoiaRecHelpDialog';
const { BLACK1ALPHA } = Colors.Static;

export const RecommendationsHelpDialog: React.FunctionComponent<{
  name: string;
  isSequoiaSheet: boolean;
  isAmpersandSheet?: boolean;
}> = ({ name, isSequoiaSheet, isAmpersandSheet = false }) => {
  const [visible, setVisible] = React.useState<boolean>(
    !window.localStorage.getItem(LOCAL_STORAGE_KEY)
  );
  const [, setRefreshCounter] = React.useState<number>(0);

  const firstName = getNameParts(name).first;

  const onCancel = () => {
    window.localStorage.setItem(LOCAL_STORAGE_KEY, 'true');
    setVisible(false);
    setRefreshCounter(c => c + 1);
  };

  return (
    <SeqModal
      bodyStyle={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        maxHeight: '75vh',
      }}
      maskClosable={false}
      width={450}
      open={visible}
    >
      <H3Mono style={{ margin: 24 }}>Welcome, {firstName}!</H3Mono>
      <ScrollingContainer
        style={{ marginLeft: 24, gap: 12, display: 'flex', flexDirection: 'column' }}
      >
        Thanks for taking the time and offering to dig into your network! This sheet has been
        created with a list of some of your connections, and it would be greatly appreciated if you
        could let us know about the best people.
        <GrayContainer>
          <StarExplanatory
            rating={1}
            header={'Star up to 10 people'}
            extraStyles={{ header: { fontWeight: 600 } }}
            explanation=" Star up to 10 top people you would enthusiastically work with again, based on their skill and impact. Use the optional comment box to share the superpower of the individuals you select."
          />
          <StarExplanatory
            rating={2}
            header={'Give a second star (or two)'}
            extraStyles={{ header: { fontWeight: 600 } }}
            explanation="From that shortlist, add a second star to the two people you would most confidently endorse."
          />
        </GrayContainer>
        {(isSequoiaSheet || isAmpersandSheet) && (
          <GrayContainer>
            <UnicornExplanatory
              explanation={
                isAmpersandSheet
                  ? 'Use the Sequoia icon to select anyone who you believe is likely to start a special company that you would eagerly invest in.'
                  : undefined
              }
            />
          </GrayContainer>
        )}
      </ScrollingContainer>
      <SeqButton style={{ margin: '24px 0' }} intent="primary" onClick={onCancel}>
        Get Started
      </SeqButton>
    </SeqModal>
  );
};

const GrayContainer = styled.div`
  background: ${BLACK1ALPHA};
  padding: 16px 12px;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
`;
