import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components/macro';
import { PageContainerWithBackNav } from '../components/Common';
import { Body1Serif, H2Mono } from '../components/Text';
import { Colors } from '../shared/Theme';
import { CampaignEditor } from './CampaignEditor';

const EDITOR_HEIGHT = 'calc(100vh - 165px)';

export const PageManageCampaigns: React.FC<
  RouteComponentProps<{ organizationId: string }>
> = props => {
  const qs = new URLSearchParams(props.location.search);
  const from = qs.get('from');

  return (
    <PageContainerWithBackNav
      backTo={{
        pageName: from || 'Campaigns',
        link: from === 'pipeline' ? 'pipeline' : undefined,
      }}
      style={{ padding: 0 }}
    >
      <Header>
        <H2Mono>Manage Campaigns</H2Mono>
        <Body1Serif>
          Create and edit email campaigns and messaging to fit your needs. Email campaigns can be
          seen by others on your team.
        </Body1Serif>
      </Header>
      <CampaignEditor height={EDITOR_HEIGHT} isNew={!!from} roleId={qs.get('roleId')} />
    </PageContainerWithBackNav>
  );
};

const Header = styled.div`
  padding-bottom: 12px;
  border-bottom: 1px solid ${Colors.Static.SEQUOIA_BLACK};
`;
