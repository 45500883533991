import { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { OrganizationContext } from '../../contexts/Organization';
import { ClickableItem } from '../../shared/Common';
import { Colors } from '../../shared/Theme';
import { IconContainer, SettingsIcon } from '../Svgs';

const { SEQUOIA_BLACK } = Colors.Static;

export const SettingsButton: React.FC<{}> = () => {
  const { id: orgId } = useContext(OrganizationContext);

  return (
    <ClickableItem style={{ display: 'flex', alignItems: 'center' }}>
      <NavLink
        to={`/${orgId}/settings`}
        style={{ display: 'flex', alignItems: 'center', width: '100%', height: 22, gap: 12 }}
      >
        <IconContainer>
          <SettingsIcon />
        </IconContainer>
        <div style={{ color: SEQUOIA_BLACK, fontSize: 12 }}>Settings</div>
      </NavLink>
    </ClickableItem>
  );
};
