import { default as React, useContext } from 'react';
import styled from 'styled-components/macro';
import { NetworkContext } from '../../contexts/Network';
import { shortenedName } from '../../Helpers';
import { Colors, hexWithOpacity } from '../../shared/Theme';
import { SeqTag } from '../SeqTag';
import { Body4, CondensedTextCSS } from '../Text';
import { CondensingTagsContainer } from './useTagsRow';

const { SEQUOIA_GREEN } = Colors.Static;

export const ConnectedToTags: React.FC<{
  profile: Pick<ExternalAPI.Profile, 'connectedTo'>;
  onSearch?: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    callback: (search: NetworkAPI.SearchHistoryData) => NetworkAPI.SearchHistoryData
  ) => void;
  myExternalId?: number;
}> = ({ profile, onSearch, myExternalId }) => {
  const { network } = useContext(NetworkContext);
  const ownerProfilesMap = network?.owners.profiles;
  const { connectedTo } = profile;
  const sortedConnectedTo = (connectedTo || []).sort(
    (a, b) =>
      Number(b === myExternalId) - Number(a === myExternalId) ||
      Number(network?.owners.firstDegreeIds.includes(b)) -
        Number(network?.owners.firstDegreeIds.includes(a)) ||
      Number(network?.owners.sharedIds.includes(b)) - Number(network?.owners.sharedIds.includes(a))
  );

  return (
    <CondensingTagsContainer
      condensed
      tags={sortedConnectedTo.map(c => {
        const owner = ownerProfilesMap?.[c];
        const memberName = shortenedName(owner?.name);

        return (
          <SeqTag
            key={c}
            style={{ maxWidth: 150 }}
            tagName={
              <div style={{ display: 'flex', gap: 4, alignItems: 'center' }}>
                {memberName}
                {owner?.founderOrgName && (
                  <>
                    <span style={{ fontSize: 14 }}>|</span>
                    <OrgName>{owner?.founderOrgName}</OrgName>
                  </>
                )}
              </div>
            }
            tagColor={
              network?.owners.firstDegreeIds.includes(c)
                ? hexWithOpacity(SEQUOIA_GREEN, 0.12)
                : undefined
            }
            onClick={e =>
              onSearch?.(e, (s: NetworkAPI.SearchHistoryData) =>
                Object.assign(s, { networkOwner: c })
              )
            }
          />
        );
      })}
    />
  );
};

export const OrgName = styled(Body4)`
  line-height: 16px;
  ${CondensedTextCSS}
`;
