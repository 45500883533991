import moment from 'moment';
import { useCallback, useContext, useEffect, useState } from 'react';
import { OrganizationContext } from '../contexts/Organization';
import { logActivity } from '../Helpers';
import { MAX_IDLE_TIME } from '../leads/LeadReviewCarousel';
import { closeCarouselNotifs } from '../leads/ReviewFooter';
import { useTimeout } from './useTimeout';

export function useCarouselNavigation({
  startIdx,
  listInfo,
  onClose,
}: {
  startIdx: number;
  listInfo: { id: number; length: number };
  onClose: () => void;
}) {
  const { me } = useContext(OrganizationContext);
  const [carouselPosition, _setCarouselPosition] = useState<number>(startIdx);

  const [sessionStart, setStart] = useState<Date | undefined>(new Date());
  const [delay, setDelay] = useState(MAX_IDLE_TIME);

  const logSession = useCallback(() => {
    if (listInfo.length && sessionStart) {
      const stop = moment()
        .subtract(delay ? 0 : 10, 'minute')
        .toDate();

      logActivity(me, { type: 'lead-list-review', listId: listInfo.id, start: sessionStart, stop });
      setStart(undefined);
    }
  }, [listInfo.length, sessionStart, delay, me, listInfo.id]);

  useTimeout(logSession, delay);

  const resetDelay = useCallback(() => {
    if (!sessionStart) setStart(new Date());
    setDelay(MAX_IDLE_TIME);
  }, [sessionStart, setStart, setDelay]);

  const setCarouselPosition = useCallback(
    (position: number) => {
      _setCarouselPosition(position);
      resetDelay();
    },
    [_setCarouselPosition, resetDelay]
  );

  const advance = useCallback(() => {
    closeCarouselNotifs();
    if (listInfo.length === 1) {
      onClose();
      return;
    }

    if (carouselPosition >= listInfo.length - 1) {
      return;
    }

    setCarouselPosition((carouselPosition + 1) % listInfo.length);
  }, [setCarouselPosition, carouselPosition, listInfo.length, onClose]);

  const reverse = () => {
    closeCarouselNotifs();
    if (carouselPosition === 0) {
      return;
    }
    setCarouselPosition(Math.abs((carouselPosition - 1) % listInfo.length));
  };

  useEffect(() => {
    window.addEventListener('beforeunload', logSession);
    document.body.addEventListener('keydown', resetDelay);
    return () => {
      document.body.removeEventListener('keydown', resetDelay);
      window.removeEventListener('beforeunload', logSession);
    };
  }, [resetDelay, logSession]);

  return {
    position: carouselPosition,
    advance,
    reverse,
  };
}
