import { LoadingOutlined } from '@ant-design/icons';
import React, { useContext, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { DownloadButton, SeqButton } from '../components/Button';
import { Column, MessageRowContainer } from '../components/Common';
import { Body1Serif, H2Mono, Label } from '../components/Text';
import { OrganizationContext } from '../contexts/Organization';
import { useMinerSheetForProfile } from '../extension/useMinerSheetForProfile';
import { useInterval } from '../hooks/useInterval';
import { CharInCircle } from '../shared/Common';
import { ScrollingContainer } from '../shared/ScrollableContainer';
import { Colors, hexWithOpacity } from '../shared/Theme';
import { useQueryPersistedState } from '../shared/useQueryPersistedState';
import { LogoWithExtension } from './PageExtensionDownload';
import { DIRECTIONS, ExtensionDirections } from './PageExtensionGuide';
import { SimpleSidebar } from './SimpleSidebar';

const { SEQUOIA_BLACK, SEQUOIA_DARK_GREEN, BLACK1ALPHA, BLACK5ALPHA, SEQUOIA_LIGHT_GRAY } =
  Colors.Static;

type StepId = 1 | 2 | 3;

export const PageOnboardingSetup: React.FC<RouteComponentProps> = props => {
  const [stepId, _setStepId] = useQueryPersistedState<StepId>({
    decode: qs => Number(qs.s || 1) as StepId,
    encode: s => ({ s }),
  });

  const onNextStep = () => {
    _setStepId(stepId === 2 ? 3 : stepId === 1 ? 2 : stepId);
  };

  const steps = [
    {
      id: 1,
      name: 'Download extension',
      content: <Step1 onNextStep={onNextStep} />,
    },
    {
      id: 2,
      name: 'Set up extension',
      content: <Step2 onNextStep={onNextStep} />,
    },
    {
      id: 3,
      name: 'Add your network',
      content: <Step3 />,
    },
  ];

  return (
    <SimpleSidebar style={{ gap: 90 }}>
      <Column style={{ alignSelf: 'center', flexGrow: 3 }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 36,
            border: `1px solid ${SEQUOIA_BLACK}`,
            borderRadius: 36,
            padding: 24,
            height: 'fit-content',
            alignSelf: 'flex-end',
            width: 200,
          }}
        >
          {steps.map(s => (
            <>
              <div style={{ display: 'flex', alignItems: 'center', gap: 12 }}>
                <CharInCircle
                  char={stepId > s.id ? '✓' : s.id}
                  style={{
                    fontFamily: 'Pitch Sans',
                    fontSize: 20,
                    width: 40,
                    minWidth: 40,
                    height: 40,
                    ...(stepId === s.id
                      ? { background: SEQUOIA_DARK_GREEN, color: 'white' }
                      : { background: BLACK1ALPHA }),
                  }}
                />
                <Label style={{ textAlign: 'left' }}>{s.name}</Label>
              </div>
            </>
          ))}
        </div>
      </Column>
      <Column style={{ flex: 7 }}>
        <Column
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            color: BLACK5ALPHA,
            gap: 24,
          }}
        >
          {steps.find(s => s.id === stepId)?.content}
        </Column>
      </Column>
    </SimpleSidebar>
  );
};

const Step1: React.FC<{ onNextStep: () => void }> = ({ onNextStep }) => {
  const [downloading, setDownloading] = useState(false);

  return (
    <>
      <LogoWithExtension />
      <H2Mono>set up your workspace</H2Mono>
      <Body1Serif>
        The Sequoia Chrome extension overlays company funding data on LinkedIn profiles, allows you
        to discover email addresses, makes it easy to add candidates to your pipeline, and more.
      </Body1Serif>
      <DownloadButton
        downloading={downloading}
        file={'extension.zip'}
        onDownload={() => {
          setDownloading(true);
          setTimeout(() => {
            onNextStep();
            setDownloading(false);
          }, 2000);
        }}
      />
    </>
  );
};
const Step2: React.FC<{ onNextStep: () => void }> = ({ onNextStep }) => {
  const { me, actions } = useContext(OrganizationContext);

  useInterval(() => {
    // following ifs are intentionally in separate steps rather than an if/else
    if (!me.settings.lastExtensionUse) {
      actions.refresh();
    }
  }, 2000);

  return (
    <>
      <H2Mono>Install the Chrome Extension</H2Mono>
      <ScrollingContainer $alwaysShow={true}>
        <ExtensionDirections directions={DIRECTIONS(me).slice(0, 7)} />
      </ScrollingContainer>
      <SeqButton intent="primary" disabled={!me.settings.lastExtensionUse} onClick={onNextStep}>
        Continue
      </SeqButton>
    </>
  );
};

const Step3: React.FC = () => {
  const { me, id: organizationId } = useContext(OrganizationContext);
  const history = useHistory();

  const [minerSheet, minerSheetOps] = useMinerSheetForProfile({
    name: me.name,
    identifier: me.identifier || '',
  });

  const onSkip = () => history.push(`/${organizationId}/network`);

  // When you click "Start" we first reload the page and then kick off the
  // network miner. Reloading the page allows us to reach the Chrome Extension
  // even if the user installed it /while viewing this page/.

  const onAddNetwork = () => {
    window.location.hash = minerSheet ? `#retry-${minerSheet.hash}` : '#start';
    window.location.reload();
  };

  React.useEffect(() => {
    if (window.location.hash === '#start') {
      setClickedStart(Date.now());
      minerSheetOps.create({ minerSheetsShared: true });
      window.location.hash = '';
    }
    if (window.location.hash.startsWith('#retry-')) {
      setClickedStart(Date.now());
      minerSheetOps.retry(window.location.hash.replace('#retry-', ''));
      window.location.hash = '';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [clickedStart, setClickedStart] = useState<false | number>(false);
  const percent = minerSheet?.status.percent || 0;

  if (!clickedStart) {
    return (
      <>
        <H2Mono>Add your LinkedIn network</H2Mono>
        <Body1Serif>
          Help your team reach interesting candidates, spot potential hires, and stay on top of
          career changes by contributing your LinkedIn connections to your company's collective
          network.
        </Body1Serif>
        <MessageRowContainer>
          <Body1Serif style={{ fontStyle: 'italic' }}>
            Privacy Notice: This step will scan and upload your LinkedIn Connections and share them
            with your team.
          </Body1Serif>
        </MessageRowContainer>
        <div style={{ display: 'flex', gap: 12 }}>
          <SeqButton intent="primary" onClick={onAddNetwork}>
            Add Network
          </SeqButton>
          {percent > 95 && <SeqButton onClick={onSkip}>Continue</SeqButton>}
        </div>
      </>
    );
  }
  if (minerSheet?.status.error) {
    return (
      <>
        <div style={{ fontSize: 28, color: SEQUOIA_BLACK, marginBottom: 12 }}>
          Unable to Add your Network
        </div>

        <NoticeBox>
          An error occurred - please try again. If you're still not able to import your network, let
          the Sequoia team know.{' '}
          <a href="#/" onClick={() => window.alert(minerSheet.status.error)}>
            View error details.
          </a>
        </NoticeBox>
        <SeqButton intent="primary" onClick={onAddNetwork}>
          Try Again
        </SeqButton>
      </>
    );
  }

  if (percent === 0 && Date.now() - clickedStart > 15 * 1000) {
    return (
      <>
        <div style={{ fontSize: 28, color: SEQUOIA_BLACK, marginBottom: 12 }}>
          Unable to add your network
        </div>

        <NoticeBox>
          Review the <Link to="/onboarding/setup?s=2">extension setup guide</Link> and make sure
          you've followed the last few steps or click Skip if you don't plan to use the extension.
        </NoticeBox>
        <div style={{ display: 'flex', gap: 12 }}>
          <SeqButton intent="primary" onClick={onAddNetwork}>
            Try Again
          </SeqButton>
          <SeqButton onClick={onSkip}>Skip</SeqButton>
        </div>
      </>
    );
  }

  return (
    <>
      <div style={{ fontSize: 28, color: SEQUOIA_BLACK, marginBottom: 12 }}>
        Adding your network…
      </div>

      <div style={{ textAlign: 'center', fontSize: 20 }}>
        {percent < 100 && <LoadingOutlined style={{ fontSize: 16, marginRight: 14 }} spin />}
        {percent}% Complete
      </div>

      <NoticeBox>
        {percent < 5 ? (
          <>
            Please wait a few moments while we connect to LinkedIn and start
            importing&nbsp;your&nbsp;connections.
          </>
        ) : percent < 100 ? (
          <>
            Feel free to continue and explore the Sequoia Hiring Tools! Leave your browser open for
            a few minutes to finish importing&nbsp;your&nbsp;connections.
          </>
        ) : (
          <>Click Continue to finish onboarding and explore the Sequoia&nbsp;Hiring&nbsp;Tools.</>
        )}
      </NoticeBox>

      {percent < 5 ? (
        <SeqButton intent="primary" disabled>
          Starting...
        </SeqButton>
      ) : (
        <SeqButton intent="primary" onClick={onSkip}>
          Continue
        </SeqButton>
      )}
    </>
  );
};

const NoticeBox = styled.div`
  font-size: 14px;
  line-height: 20px;
  width: 600px;
  background: ${hexWithOpacity(SEQUOIA_LIGHT_GRAY, 0.25)};
  color: ${SEQUOIA_BLACK};
  padding: 16px;
`;
