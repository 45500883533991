import { CSSProperties, useContext } from 'react';
import styled from 'styled-components/macro';
import { BaseContainer, Column } from '../components/Common';
import { OrganizationContext } from '../contexts/Organization';
import { InitialsOrProfileImage } from '../shared/ProfileImage';
import { Colors } from '../shared/Theme';

export const SimpleSidebar = ({
  children,
  style,
}: {
  children: React.ReactNode;
  style?: CSSProperties;
}) => {
  const { me } = useContext(OrganizationContext);

  return (
    <BaseContainer>
      <SidebarContainer>
        <img style={{ width: 32 }} src={'/icons/logo.svg'} alt="Logo" />
        <InitialsOrProfileImage widthHeight={40} user={me} />
      </SidebarContainer>
      <ContentContainer style={style}>{children}</ContentContainer>
    </BaseContainer>
  );
};

const SidebarContainer = styled(Column)`
  padding: 24px;
  justify-content: space-between;
  flex: unset;
`;

const ContentContainer = styled(Column)`
  padding: 48px 72px;
  flex-direction: row;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  background-color: ${Colors.Static.SEQUOIA_PAPER};
  box-shadow: 0px 16px 32px ${Colors.Static.BLACK1ALPHA};
`;
