import { Moment } from 'moment';
import styled, { CSSProperties } from 'styled-components/macro';

export interface StackedBarChartData {
  groupName: string;
  dataSet: string;
  value: number;
}

export interface PieChartData {
  label: string;
  count: number;
  percent: number;
  color: string;
}

//These events are only documented LOOSELY in an old version here:
//https://bizcharts.net/product/bizcharts/category/7/page/66
export function extractClickData<T>(e: any): T | undefined {
  if (!e || !e.data || !e.data.data) {
    return;
  }
  const clickedOn: T = e.data.data;
  return clickedOn;
}

export const extractAxisClick = (e: any) => {
  if (!e || !e.gEvent || !e.gEvent.target || !e.gEvent.target.cfg) {
    return;
  }
  const axisId = e.gEvent.target.cfg.id.trim();
  const match = axisId.match(/-axis-label-([^]*)/);
  if (match && match[1]) {
    return match[1];
  }
};

export const extractLegendClick = (e: any) => {
  if (!e || !e.item || !e.item.name) {
    return;
  }
  return e.item.name;
};

export const buildPastNumOfDaysArray = (startOfDay: Moment, numOfDays: number) =>
  [...new Array(numOfDays)].map((i, idx) => startOfDay.clone().subtract(idx, 'days')).reverse();

export const CenteredChartHeader: React.FC<{ style?: CSSProperties }> = ({ children, style }) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', ...style }}>
      <ChartHeader>{children}</ChartHeader>
    </div>
  );
};

export const ChartHeader = styled.div`
  font-weight: 500;
  margin-bottom: 10px;
  text-align: center;
`;
