import React from 'react';
import { ClickableItem } from './Common';

export const CompanyLILink = ({
  lirCompanyId,
  size = 16,
  icon,
  children,
}: {
  lirCompanyId?: string;
  size?: number;
  icon?: React.ReactNode;
  children?: React.ReactNode;
}) => (
  <span>
    <div style={{ display: 'flex', position: 'relative', alignItems: 'center' }}>
      <div
        style={{ flexShrink: 2, maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }}
      >
        {children}
      </div>
      {lirCompanyId && (
        <ClickableItem>
          <a
            href={`https://www.linkedin.com/company/${lirCompanyId}`}
            style={{ verticalAlign: 'middle', flexShrink: 0, lineHeight: `${size}px` }}
            title="View on LinkedIn"
            rel="noopener noreferrer"
            target="_blank"
          >
            {icon || (
              <img
                alt=""
                style={{
                  width: size,
                  height: size,
                  display: 'inline',
                  marginLeft: 5,
                  marginTop: 2,
                }}
                src={require('../images/linkedin.svg').default}
              />
            )}
          </a>
        </ClickableItem>
      )}
    </div>
  </span>
);
