import { default as React, useEffect, useState } from 'react';
import { SeqButton } from '../components/Button';
import { FooterRow } from '../components/Common';
import { EditEmailModal } from '../components/modals/EditEmailModal';
import { SeqPopover } from '../components/Popover';
import { RemovableProfileRow } from '../components/RemovableProfileRow';
import { isValidEmail, pluralize } from '../shared/helpers';
import { ScrollingContainer } from '../shared/ScrollableContainer';
import { CandidateWithDetails } from './PageLeads';

export const DraftList: React.FC<{
  candidates: CandidateWithDetails[];
  onUpdate: {
    leadList: (update: Omit<LeadsAPI.UpdateLeadList, 'id'>) => Promise<void>;
    candidate: (profile: ExternalAPI.Profile) => void;
  };
}> = ({ candidates, onUpdate }) => {
  const [profileIdsToRemove, setIdsToRemove] = useState<number[]>([]);
  const [editEmailProfile, setEditEmailProfile] = useState<ExternalAPI.Profile>();
  const [missingEmailPopoverVisible, setVisible] = useState(false);
  const numOfMissingEmails = candidates.filter(
    c => !isValidEmail(c.profile.email) && !profileIdsToRemove.includes(c.profile.id)
  ).length;
  const disabled =
    !candidates.length || candidates.length === profileIdsToRemove.length || !!numOfMissingEmails;

  useEffect(() => {
    setIdsToRemove(
      candidates.reduce(
        (acc, c) => (contactedCandidate(c.profile.status) ? acc.concat(c.profile.id) : acc),
        [] as number[]
      )
    );
  }, [candidates]);

  const leadsToSend = candidates.length - profileIdsToRemove.length;
  const SendButton = (
    <SeqButton
      intent="primary"
      onClick={() =>
        !disabled &&
        onUpdate.leadList({ removeProfileIds: profileIdsToRemove, readyForReviewAt: new Date() })
      }
      disabled={!leadsToSend}
    >
      {leadsToSend ? `send ${pluralize(leadsToSend, 'lead')} for review` : 'no leads to send'}
    </SeqButton>
  );

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%', minHeight: 0 }}>
      <ScrollingContainer style={{ flex: 1 }}>
        {candidates.map(c => (
          <RemovableProfileRow
            key={c.profile.identifier}
            profile={c.profile}
            alreadyContacted={contactedCandidate(c.profile.status)}
            added={!profileIdsToRemove.includes(c.profile.id)}
            onAddOrRemove={() =>
              setIdsToRemove(prev =>
                prev.includes(c.profile.id)
                  ? prev.filter(id => id !== c.profile.id)
                  : prev.concat(c.profile.id)
              )
            }
            leadsConfig={{
              comment: c.comment,
              onAddEmail: setEditEmailProfile,
              onUpsertComment: comment =>
                onUpdate.leadList({ addSourcerComment: { id: c.profile.id, comment } }),
            }}
          />
        ))}
      </ScrollingContainer>
      <FooterRow style={{ marginRight: 24, justifyContent: 'flex-end' }}>
        <SeqButton
          onClick={() => onUpdate.leadList({ removeProfileIds: profileIdsToRemove })}
          disabled={!profileIdsToRemove.length}
        >
          remove {pluralize(profileIdsToRemove.length, 'lead')}
        </SeqButton>
        {numOfMissingEmails ? (
          <SeqPopover
            open={missingEmailPopoverVisible}
            onOpenChange={setVisible}
            title={`missing ${pluralize(numOfMissingEmails, 'email')}`}
            content={
              <>
                <div>Would you like to submit the list anyways?</div>
                <div>
                  You also have the option of adding any missing email addresses or removing the
                  candidates with invalid emails from your list prior to submitting.
                </div>
              </>
            }
            footer={
              <>
                <SeqButton onClick={() => setVisible(false)}>Cancel</SeqButton>
                <SeqButton
                  intent="primary"
                  onClick={() =>
                    onUpdate.leadList({
                      removeProfileIds: profileIdsToRemove,
                      readyForReviewAt: new Date(),
                    })
                  }
                >
                  Submit
                </SeqButton>
              </>
            }
            placement="topRight"
          >
            {SendButton}
          </SeqPopover>
        ) : (
          SendButton
        )}
      </FooterRow>
      {editEmailProfile && (
        <EditEmailModal
          open={!!editEmailProfile}
          profile={editEmailProfile}
          onClose={modifiedProfile => {
            modifiedProfile && onUpdate.candidate(modifiedProfile);
            setEditEmailProfile(undefined);
          }}
        />
      )}
    </div>
  );
};

const contactedCandidate = (s?: PipelineAPI.ProfileStatus) => {
  const contactedStatuses: PipelineAPI.ProfileStatus[] = [
    'Contacted',
    'Interviewing',
    'Hired',
    'Unresponsive',
  ];
  return s ? contactedStatuses.includes(s) : false;
};
