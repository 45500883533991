import { DeleteOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Input, Tooltip } from 'antd';
import { FunctionComponent, createContext, useMemo } from 'react';
import styled, { CSSProperties } from 'styled-components/macro';
import { Colors, hexWithOpacity } from '../Theme';
import { AttendedBootcampEditor } from './AtttendedBootcampEditor';
import { AverageEmploymentLongerThanEditor } from './AverageEmploymentLongerThanEditor';
import { CurrentEmployerDSCategoriesEditor } from './CurrentEmployerDSCategoriesEditor';
import { HasBachelorsDegreeBetweenEditor } from './HasBachelorsDegreeBetweenEditor';
import { HasBeenVouchedEditor } from './HasBeenVouchedEditor';
import { HasDegreeInEditor } from './HasDegreeInEditor';
import { HasTagEditor } from './HasTagEditor';
import { HasWorkedWithinXMilesOfY } from './HasWorkedWithinXMilesOfY';
import { HeadCountEditor } from './HeadcountEditor';
import { HeldCurrentTitleForXYearsEditor } from './HeldCurrentTitleForXYearsEditor';
import { HeldTitleEditor } from './HeldTitleEditor';
import { InCountriesEditor } from './InCountriesEditor';
import { LIIndustryTagEditor } from './LIIndustryTagEditor';
import { LocationEditor } from './LocationEditor';
import { MovedJobTitlesEditor } from './MovedJobTitlesEditor';
import { NoJobInPastMonthsEditor } from './NoJobInPastMonthsEditor';
import { SingleParamEditor } from './SingleParamEditor';
import { SummaryOrWorkDescriptionEditor } from './SummaryOrWorkDescriptionEditor';
import { SummaryOrWorkMatchesKeywordEditor } from './SummaryOrWorkMatchesKeywordEditor';
import { WentToCollegeAtEditor } from './WentToCollegeAtEditor';
import { WorkedAtCoWithLITagsEditor } from './WorkedAtCoWithLITagsEditor';
import { WorkedAtCompanyHeadcount } from './WorkedAtCompanyHeadcount';
import { WorksAtEditor } from './WorksAtEditor';
import { YearsOfExperienceEditor } from './YearsOfExperienceEditor';

export type Rule = SourcingEngineAPI.EngineModel['excludeRules'][0] & { parameters: string[] };
export type RuleEditorCategory =
  | 'Education'
  | 'Profile'
  | 'Location'
  | 'Work History'
  | 'Current Work';

export interface RuleEditorProps {
  title?: string;
  rule: Rule;
  onChange: (newRule: Rule, opts: { remove?: boolean; validation: ValidationInfo }) => void;
  style?: CSSProperties;
  ruleType: 'include' | 'exclude';
}

const { SEQUOIA_BLACK, SEQUOIA_GREEN, SEQUOIA_DARK_GRAY, SEQUOIA_LIGHT_GRAY } = Colors.Static;

export type EditableRuleIds =
  | 'near-any-of'
  | 'years-of-experience-between'
  | 'summary-or-work-desc-includes'
  | 'works-at'
  | 'current-co-ds-categories'
  | 'held-title-past-months'
  | 'current-co-headcount-between'
  | 'open-to-opportunities'
  | 'has-phd'
  | 'intern-to-fulltime'
  | 'worked-at-portfolio-co'
  | 'worked-at-aircraft-carrier'
  | 'is-a-student'
  | 'at-portfolio-co'
  | 'not-open-keywords'
  | 'big-co-executive'
  | 'full-stack-engineering-keywords'
  | 'in-current-job-less-than-x-years'
  | 'attended-bootcamp'
  | 'in-countries'
  | 'worked-at-company-headcount-over-x-for-y'
  | 'average-employment-longer-than'
  | 'has-degree-in'
  | 'hasBeenVouched'
  | 'went-to-college-at-one-of'
  | 'moved-jobs-titles'
  | 'summary-or-work-matches-more-than-x-keywords'
  | 'was-a-founder'
  | 'has-github-link'
  | 'has-stem-degree'
  | 'has-mba'
  | 'bachelors-degree-between'
  | 'no-job-in-past-x-months'
  | 'has-worked-in-location'
  | 'has-tag'
  | 'currently-at-co-with-tags'
  | 'worked-at-co-with-tags-more-than-x-months'
  | 'is-software-engineer';

export const EditableExcludeRulesMap: Record<
  EditableRuleIds,
  {
    label: string;
    editor: FunctionComponent<RuleEditorProps>;
    founderEditorUniqueId?: string;
    title: (ruleType: 'include' | 'exclude', invert?: boolean) => string; //Ex-editor so it can render on modal on internal
    category: RuleEditorCategory;
  }
> = {
  'near-any-of': {
    label: 'Location',
    editor: LocationEditor,
    founderEditorUniqueId: 'founder-sub-model-loc-rule',
    title: (ruleType, invert) =>
      ruleType === 'exclude'
        ? invert
          ? 'Where does your person need to live?'
          : 'Exclude people who live near:'
        : 'Prefer people who live near:',

    category: 'Location',
  },
  'years-of-experience-between': {
    label: 'Years of Experience',
    editor: YearsOfExperienceEditor,
    founderEditorUniqueId: 'company-years-exp',
    title: (ruleType, invert) =>
      ruleType === 'exclude'
        ? invert
          ? 'How much experience does your ideal person have?'
          : 'Exclude with specific work experience:'
        : 'Prefer people with work experience:',

    category: 'Work History',
  },
  'summary-or-work-desc-includes': {
    label: 'Work Description Keywords',
    editor: SummaryOrWorkDescriptionEditor,
    founderEditorUniqueId: 'founder-sub-model-keywords',
    title: (ruleType, invert) =>
      ruleType === 'exclude'
        ? invert
          ? 'What does your person mention on their LinkedIn page?'
          : 'Exclude people who specifically mention:'
        : 'Prefer people who mention:',

    category: 'Profile',
  },
  'works-at': {
    label: 'Does Not Work At',
    editor: WorksAtEditor,
    founderEditorUniqueId: 'founder-sub-model-works-at-company',
    title: (ruleType, invert) =>
      ruleType === 'exclude'
        ? invert
          ? 'All people must work at:'
          : 'Exclude people that work at specific companies'
        : 'Prefer people who currently work at:',

    category: 'Current Work',
  },
  'current-co-ds-categories': {
    label: 'Current Industry',
    editor: CurrentEmployerDSCategoriesEditor,
    founderEditorUniqueId: 'founder-sub-model-company-ds-categories',
    title: (ruleType, invert) =>
      ruleType === 'exclude'
        ? invert
          ? 'What industries does your person currently work in?'
          : 'Exclude people working in specific industries (not recommended)'
        : 'Prefer people working in these industries:',

    category: 'Current Work',
  },
  'held-title-past-months': {
    label: 'Current Job Title Excludes',
    editor: HeldTitleEditor,
    founderEditorUniqueId: 'founder-sub-model-title-includes',
    title: (ruleType, invert) =>
      ruleType === 'exclude'
        ? invert
          ? 'What current title must your person have?'
          : 'Exclude people with title'
        : 'Prefer people with title',

    category: 'Current Work',
  },
  'current-co-headcount-between': {
    label: 'Current Employer Size',
    editor: HeadCountEditor,
    founderEditorUniqueId: 'founder-sub-model-headcount',
    title: (ruleType, invert) =>
      ruleType === 'exclude'
        ? invert
          ? 'What sized company does your ideal person work at right now?'
          : 'Exclude people working at companies with a headcount between'
        : 'Prefer people working at co with headcount:',

    category: 'Current Work',
  },
  'open-to-opportunities': {
    label: 'Open to Opportunities',
    editor: SingleParamEditor,

    title: (ruleType, invert) =>
      ruleType === 'exclude'
        ? invert
          ? 'Person must be open to opporunities'
          : 'Exclude people that are open to opportunities'
        : 'Boost people who are open to opportunities',

    category: 'Profile',
  },
  'has-phd': {
    label: 'Has a PhD',
    editor: SingleParamEditor,

    title: (ruleType, invert) =>
      ruleType === 'exclude'
        ? invert
          ? 'Person must have have a PhD'
          : 'Exclude people with a PhD'
        : 'Boost people with a PhD',

    category: 'Education',
  },
  'intern-to-fulltime': {
    label: '',
    editor: SingleParamEditor,

    title: (ruleType, invert) =>
      ruleType === 'exclude'
        ? invert
          ? 'Person must have gone from intern to fulltime at a company'
          : 'Exclude people that went from intern to fulltime at a company'
        : 'Boost people that went from intern to fulltime at a company',

    category: 'Work History',
  },
  'worked-at-portfolio-co': {
    label: '',
    editor: SingleParamEditor,

    title: (ruleType, invert) =>
      ruleType === 'exclude'
        ? invert
          ? 'Person must have worked at a portco'
          : 'Exclude people who worked at a portco'
        : 'Boost people who worked at a portco',

    category: 'Work History',
  },
  'worked-at-aircraft-carrier': {
    label: 'Worked at Aircraft Carrier',
    editor: SingleParamEditor,

    title: (ruleType, invert) =>
      ruleType === 'exclude'
        ? invert
          ? 'Person must have worked at an Aircraft Carrier'
          : 'Exclude people that worked at an Aircraft Carrier'
        : 'Boost people that worked at an Aircraft Carrier',

    category: 'Work History',
  },
  'is-a-student': {
    label: 'Is a Student',
    editor: SingleParamEditor,

    title: (ruleType, invert) =>
      ruleType === 'exclude'
        ? invert
          ? 'Person must be a student'
          : 'Exclude people that are students'
        : 'Boost people that are currently a student',

    category: 'Education',
  },
  'at-portfolio-co': {
    label: 'At a Portco',
    editor: SingleParamEditor,

    title: (ruleType, invert) =>
      ruleType === 'exclude'
        ? invert
          ? 'Person must work at a portco'
          : 'Exclude people that currently work at a portco'
        : 'Boost people that currently work at a portco',

    category: 'Current Work',
  },
  'not-open-keywords': {
    label: 'Not OTO Keywords',
    editor: SingleParamEditor,

    title: (ruleType, invert) =>
      ruleType === 'exclude'
        ? invert
          ? `Person's summary must include not OTO keywords`
          : 'Exclude people with summaries including not OTO keywords'
        : 'Boost people with summaries including not OTO keywords',

    category: 'Profile',
  },
  'big-co-executive': {
    label: 'Held an Exec Title at a Med/Lg Co',
    editor: SingleParamEditor,

    title: (ruleType, invert) =>
      ruleType === 'exclude'
        ? invert
          ? `Person must have held a exec title at co with more than 50 employees`
          : 'Exclude people that have held an exec title at co with more than 50 employees'
        : 'Boost people that have held a exec title at co with more than 50 employees',
    category: 'Work History',
  },
  'full-stack-engineering-keywords': {
    label: 'Full-Stack Engineering Keywords',
    editor: SingleParamEditor,
    title: (ruleType, invert) =>
      ruleType === 'exclude'
        ? invert
          ? `Person's summary must include full-stack engineering keywords`
          : 'Exclude people with summaries including full-stack engineering keywords'
        : 'Boost people with summaries including full-stack engineering keywords',

    category: 'Profile',
  },
  'in-current-job-less-than-x-years': {
    label: 'Held Current Job Title for < X Years',
    editor: HeldCurrentTitleForXYearsEditor,

    title: (ruleType, invert) =>
      ruleType === 'exclude'
        ? invert
          ? 'People must have held current job title for less than X years'
          : 'Exclude people who have held current job title for less than X years'
        : 'Boost people who have held current job title for less than X years',

    category: 'Current Work',
  },
  'attended-bootcamp': {
    label: 'Attended a Bootcamp',
    editor: AttendedBootcampEditor,

    title: (ruleType, invert) =>
      ruleType === 'exclude'
        ? invert
          ? `Person must have attended a bootcamp in the past X years`
          : 'Exclude people who attended a bootcamp in the past X years'
        : 'Boost people who attended a bootcamp in the past X years',

    category: 'Education',
  },
  'in-countries': {
    label: 'Not Located In',
    editor: InCountriesEditor,

    title: (ruleType, invert) =>
      ruleType === 'exclude'
        ? invert
          ? `People must be located in the following countries`
          : 'Exclude people located in the following countries'
        : 'Boost people located in the following countries',

    category: 'Location',
  },
  'worked-at-company-headcount-over-x-for-y': {
    label: 'Works at Company of w/ Headcount X for Y months',
    editor: WorkedAtCompanyHeadcount,

    title: (ruleType, invert) =>
      ruleType === 'exclude'
        ? invert
          ? `Person must work at company with X headcount for Y months`
          : 'Exclude people who work at company with X headcount for Y months'
        : 'Boost people who have been at company with X headcount for Y months',

    category: 'Work History',
  },
  'average-employment-longer-than': {
    label: 'Average employment length',
    editor: AverageEmploymentLongerThanEditor,

    title: (ruleType, invert) =>
      ruleType === 'exclude'
        ? invert
          ? `Person must stay at company for longer than X years on average`
          : 'Exclude people who stay at company for longer than X years on average'
        : 'Boost people who stay at company for longer than X years on average',

    category: 'Work History',
  },
  'has-degree-in': {
    label: 'Has a Degree In',
    editor: HasDegreeInEditor,

    title: (ruleType, invert) =>
      ruleType === 'exclude'
        ? invert
          ? `Person must have a degree in specified fields`
          : 'Exclude people with degrees in specified fields'
        : 'Boost people people with degrees in specified fields',

    category: 'Education',
  },
  hasBeenVouched: {
    label: 'Has Been Vouched',
    editor: HasBeenVouchedEditor,

    title: (ruleType, invert) =>
      ruleType === 'exclude'
        ? invert
          ? `Require person to have been vouched`
          : 'Exclude people who have been vouched'
        : 'Boost people who have been vouched',

    category: 'Profile',
  },
  'went-to-college-at-one-of': {
    label: 'Went to College At',
    editor: WentToCollegeAtEditor,

    title: (ruleType, invert) =>
      ruleType === 'exclude'
        ? invert
          ? `Require that people attend specific universities`
          : 'Exclude people who attended specific universities'
        : 'Boost people attending specific universities',

    category: 'Education',
  },
  'moved-jobs-titles': {
    label: 'Changed Job Titles',
    editor: MovedJobTitlesEditor,

    title: (ruleType, invert) =>
      ruleType === 'exclude'
        ? invert
          ? `Person must have changed job titles`
          : 'Exclude people who have changed job titles'
        : 'Boost people who have changed job titles',

    category: 'Work History',
  },
  'summary-or-work-matches-more-than-x-keywords': {
    label: 'Summary or Work Description Keyword Matches',
    editor: SummaryOrWorkMatchesKeywordEditor,

    title: (ruleType, invert) =>
      ruleType === 'exclude'
        ? invert
          ? `Require keyword match in past X years work history`
          : 'Exclude based on keyword match in past X years work history'
        : 'Boost people based on keyword match in past X years work history',
    category: 'Profile',
  },
  'was-a-founder': {
    editor: SingleParamEditor,
    label: 'Was a founder',
    title: (ruleType, invert) =>
      ruleType === 'exclude'
        ? invert
          ? 'Person must have been a founder to be included'
          : 'Exclude people who were previously founders'
        : 'Boost people who were previously founders',

    category: 'Work History',
  },
  'has-github-link': {
    label: 'Has GitHub Link Attached to Profile',
    editor: SingleParamEditor,

    title: (ruleType, invert) =>
      ruleType === 'exclude'
        ? invert
          ? 'Person must have GitHub link attached to profile'
          : 'Exclude people who have GitHub link attached to profile'
        : 'Boost people who have GitHub link attached to profile',

    category: 'Profile',
  },
  'has-stem-degree': {
    label: 'Has STEM Degree',
    editor: SingleParamEditor,

    title: (ruleType, invert) =>
      ruleType === 'exclude'
        ? invert
          ? 'Person must have a STEM degree'
          : 'Exclude people who have a STEM degree'
        : 'Boost people with a STEM degree',

    category: 'Education',
  },
  'has-mba': {
    label: 'Has an MBA',
    editor: SingleParamEditor,

    title: (ruleType, invert) =>
      ruleType === 'exclude'
        ? invert
          ? 'Person must have an MBA'
          : 'Exclude people who have an MBA'
        : 'Boost people with an MBA',

    category: 'Education',
  },
  'bachelors-degree-between': {
    label: 'Has bachelors degree within range',
    editor: HasBachelorsDegreeBetweenEditor,

    title: (ruleType, invert) =>
      ruleType === 'exclude'
        ? invert
          ? `People must have had a bachelors degree between X and Y years ago`
          : 'Exclude people who have a bachelors degree between X and Y years ago'
        : 'Boost people who have a bachelors degree between X and Y years ago',

    category: 'Education',
  },
  'no-job-in-past-x-months': {
    label: 'Person has not had job in past months',
    editor: NoJobInPastMonthsEditor,

    title: (ruleType, invert) =>
      ruleType === 'exclude'
        ? invert
          ? `Include only people with 0 jobs in past X months`
          : 'Exclude person must not have had a job in the past X months'
        : 'Boost people who have not had a job in the past X months',

    category: 'Work History',
  },
  'has-worked-in-location': {
    label: 'Has Worked Within Distance Of',
    editor: HasWorkedWithinXMilesOfY,

    title: (ruleType, invert) =>
      ruleType === 'exclude'
        ? invert
          ? 'Person must have worked near one of the specified locations'
          : 'Exclude people who have worked near one of the specified locations'
        : 'Boost people who have worked near at least one of specified locations',

    category: 'Location',
  },
  'has-tag': {
    label: 'Profile Tagged As',
    editor: HasTagEditor,

    title: (ruleType, invert) =>
      ruleType === 'exclude'
        ? invert
          ? 'Require tag'
          : 'Exclude people with tag(s)'
        : 'Boost people with tag(s)',

    category: 'Profile',
  },
  'currently-at-co-with-tags': {
    label: 'Current Company Industry',
    editor: LIIndustryTagEditor,

    title: (ruleType, invert) =>
      ruleType === 'exclude'
        ? invert
          ? 'Only include people working at company with industry tag'
          : `Exclude people based on current company's industry tag`
        : `Boost people based on current company's industry tag`,

    category: 'Work History',
  },
  'worked-at-co-with-tags-more-than-x-months': {
    label: 'Worked in industries',
    editor: WorkedAtCoWithLITagsEditor,

    title: (ruleType, invert) =>
      ruleType === 'exclude'
        ? invert
          ? 'Only include people who worked at co with tags in past X months'
          : `Exclude people who have worked at a co with tag(s)`
        : `Boost people based industry tags in their work history`,

    category: 'Work History',
  },
  'is-software-engineer': {
    label: 'Is software engineer',
    editor: SingleParamEditor,
    title: (ruleType, invert) =>
      ruleType === 'exclude'
        ? invert
          ? 'Only include people who currently have an swe related title.'
          : `Exclude people with an swe title.`
        : 'Boost people with an swe title.',

    category: 'Work History',
  },
};

export const HeaderRow: React.FC<{
  label: string;
  rule?: Rule;
  extra?: React.ReactElement;
  onChange: (newRule: Rule, opts: { remove?: boolean; validation: ValidationInfo }) => void;
}> = ({ label, rule, onChange, extra }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', marginBottom: 16 }}>
      <EditorHeader style={{ marginBottom: 0 }}>{label}</EditorHeader>
      <div style={{ flex: 1 }} />
      {extra}
      {rule ? (
        <EditorButton
          ghost={true}
          icon={<DeleteOutlined />}
          onClick={() => {
            onChange(rule, { remove: true, validation: valid() });
          }}
        >
          Delete
        </EditorButton>
      ) : (
        <></>
      )}
    </div>
  );
};

const EditorHeader = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: ${SEQUOIA_BLACK};
  margin-bottom: 16px;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
`;

export const EditorInput = styled.input`
  font-size: 14px;
  height: 39px;
  border: none;
  border-bottom: 1px solid ${hexWithOpacity(SEQUOIA_GREEN, 0.15)};
  line-height: 19px;
  width: 100%;
  padding-left: 0px;
  color: ${SEQUOIA_DARK_GRAY};
  background: none;
  &:focus {
    outline: none;
  }
  &:active {
    border: none;
    border-bottom: 1px solid ${SEQUOIA_LIGHT_GRAY};
    outline: none;
  }
  &::placeholder {
    color: #c8c8c8;
  }
  &:disabled {
    color: #909090;
    background: none;
  }
`;

export const Explanatory = styled.div`
  color: ${SEQUOIA_BLACK};
  font-size: 14px;
  margin-bottom: 15px;
  line-height: 25px;
`;

export const REGEX_EXPLANATION = `Upper or lowercase letters are considered to be equal. Each of these fields may also be a javascript regular expression, if you are familiar with that syntax.`;

export const EditorTooltip: React.FC<{ text: string; style?: CSSProperties }> = ({
  text,
  style,
}) => {
  return (
    <Tooltip overlayStyle={{ maxWidth: 400 }} title={text}>
      <QuestionCircleOutlined style={{ color: Colors.Static.SEQUOIA_LIGHT_TEXT, ...style }} />
    </Tooltip>
  );
};

export type Validator = (rule: Rule) => ValidationInfo;

export interface ValidationInfo {
  isValid: boolean;
  reason?: string;
}

export const invalid = (reason: string): ValidationInfo => ({
  isValid: false,
  reason,
});

export const valid = (): ValidationInfo => ({
  isValid: true,
});

export const onChangeWithValidation = (
  rule: Rule,
  onChange: RuleEditorProps['onChange'],
  validator: Validator
) => {
  onChange(rule, { validation: validator(rule) });
};

export const useDescription = (
  ruleInfo: {
    ruleType: 'include' | 'exclude';
    rule: Rule;
  },
  descs: { include: string; exclude: string; invert: string }
) => {
  return useMemo(() => {
    const { ruleType, rule } = ruleInfo;
    if (ruleType === 'include') {
      return descs.include;
    } else if ('invert' in rule && !!rule.invert) {
      return descs.invert;
    }
    return descs.exclude;
  }, [ruleInfo, descs]);
};

//Will add edu API path here as well
interface RuleEditorContextType {
  workplacesAPIPath: string;
  dsCategoriesPath: string;
  locationInputBasePath: string;
  tagsPath: string;
}

export const RuleEditorContext = createContext<RuleEditorContextType>({
  workplacesAPIPath: '',
  dsCategoriesPath: '',
  locationInputBasePath: '',
  tagsPath: '',
});

export const NumberInputWithLabel: React.FunctionComponent<{
  label: string;
  onChange: (newVal?: number) => void;
  value: number | string;
  tooltipText?: string;
}> = ({ label, value, onChange, tooltipText }) => {
  return (
    <div
      style={{
        display: 'flex',
        marginBottom: 10,
        alignItems: 'center',
      }}
    >
      <div>{label}</div>
      {tooltipText && <EditorTooltip style={{ marginLeft: 5 }} text={tooltipText} />}
      <div style={{ flex: 1 }} />
      <InputNumberNoArrows
        style={{ width: 75 }}
        value={isNaN(Number(value)) ? undefined : Number(value)}
        placeholder={'months'}
        onChange={e => {
          const numberVal = Number(e.target.value);
          onChange(isNaN(numberVal) || numberVal < 0 ? 0 : numberVal);
        }}
      />
    </div>
  );
};

export const InputNumberNoArrows = styled(Input)`
  border: none;
  border-bottom: 1px solid ${hexWithOpacity(SEQUOIA_GREEN, 0.15)};
  line-height: 19px;
  .ant-input {
    -webkit-appearance: none;
    margin: 0;
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    ::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
  background: none;
  &:focus {
    outline: none;
  }
  &:active {
    border: none;
    border-bottom: 1px solid ${SEQUOIA_LIGHT_GRAY};
    outline: none;
  }
  &::placeholder {
    color: #c8c8c8;
  }
  &:disabled {
    color: #909090;
    background: none;
  }
`;

export const EditorButton = styled(Button)`
  background: ${Colors.Static.SEQUOIA_LIGHT_GRAY};
  color: ${SEQUOIA_DARK_GRAY};
  border: 1px solid #ededed;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
`;
