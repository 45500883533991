import { useEffect, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router';
import styled from 'styled-components/macro';
import { SeqButton } from '../components/Button';
import { SequoiaBrand } from '../components/navigation/FeatureSidebar';
import { Container, MessageContainer } from '../onboarding/PageOnboardingForm';
import { makeRequest } from '../shared/Resource';
import { FormContainer } from './PageRoleAudit';

const NEED_TO_RESEND =
  'For security purposes, we need to send an updated link to this page to your email. The link will expire in ten minutes.';

export const PageRoleAuditConfirmResend = (
  props: RouteComponentProps<{ hash: string; secret?: string }>
) => {
  const { hash, secret } = props.match.params;
  const history = useHistory();
  const [message, setMessage] = useState('Confirming email...');

  useEffect(() => {
    const confirmEmail = async () => {
      const res = await makeRequest<{ message: string; hash?: string }>(
        `/api/role-audit/${hash}/resend-confirmed/${secret}`,
        'POST'
      );

      if (res.message === 'expired')
        setMessage(
          'Unable to confirm email. The link has expired. Please click the button below to be sent another confirmation email.'
        );
      if (res.message === 'success') {
        setMessage(
          'Email successfully confirmed! Redirecting you to review open roles in your company...'
        );
        setTimeout(() => history.push(`/role-audit/${hash}`), 2000);
      }
    };

    if (secret) {
      confirmEmail();
    } else setMessage(NEED_TO_RESEND);
  }, [secret, hash, history]);

  const sendConfirmation = async () => {
    setMessage('Sending email...');
    const res = await makeRequest<{ message: string }>(`/api/role-audit/${hash}/resend`, 'POST');
    if (res) {
      setMessage('A confirmation email has been sent!');
    }
  };

  return (
    <Container style={{ alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
      <SequoiaBrand />
      <FormContainer style={{ width: 528 }}>
        <Header style={{ lineHeight: 1.25 }}>Email Confirmation</Header>
        <MessageContainer>{message}</MessageContainer>
        {message === NEED_TO_RESEND && (
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: 24 }}>
            <SeqButton onClick={sendConfirmation} intent="primary">
              Resend Email
            </SeqButton>
          </div>
        )}
      </FormContainer>
    </Container>
  );
};

const Header = styled.div`
  font-size: 24px;
  text-align: center;
`;
