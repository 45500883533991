import { Spin } from 'antd';
import { NotionRenderer } from 'react-notion';
import 'react-notion/src/styles.css';
import { RouteComponentProps } from 'react-router';
import { PageContainerWithBackNav } from '../components/Common';
import { PageLost } from '../components/navigation/PageLost';
import { useResource } from '../shared/Resource';
import { ScrollableContainer } from '../shared/ScrollableContainer';

const LOST_RESOURCE_MESSAGE = `Oops! Looks like this resource isn't currently available. Try coming back later!`;

export const PageNotionResource = (
  props: RouteComponentProps<{
    organizationId: string;
    pageId: string;
  }>
) => {
  const { pageId } = props.match.params;
  const qs = new URLSearchParams(props.location.search);
  const from = qs.get('from');

  //Not specifying type here - <NotionRenderer />
  //handles all data conversion directly from the backend
  const [blockMap] = useResource<any>(`/api/founder-resources/notion-docs/${pageId}`);

  return (
    <PageContainerWithBackNav
      style={{ padding: '24px 0 24px 0' }}
      backTo={{
        pageName: from || 'Resources',
      }}
    >
      {blockMap && 'error' in blockMap ? (
        <PageLost alternateTitle={LOST_RESOURCE_MESSAGE} />
      ) : (
        <ScrollableContainer style={{ marginTop: -24 }} height={'calc(100vh - 120px)'}>
          {!!blockMap ? (
            <NotionRenderer fullPage={true} hideHeader blockMap={blockMap} />
          ) : (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                height: '100%',
                justifyContent: 'center',
              }}
            >
              <Spin size="large" />
            </div>
          )}
          <div style={{ marginBottom: 80 }} />
        </ScrollableContainer>
      )}
    </PageContainerWithBackNav>
  );
};
