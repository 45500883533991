import {
  EditableExcludeRulesMap,
  EditableRuleIds,
  invalid,
  onChangeWithValidation,
  RuleEditorProps,
  useDescription,
  valid,
  ValidationInfo,
} from '../rule-editors/RuleEditorCommon';
import { EditorInput, Explanatory, HeaderRow, Rule } from './RuleEditorCommon';

export const WorkedAtCompanyHeadcount: React.FC<RuleEditorProps> = ({
  rule,
  onChange,
  style,
  title,
  ruleType,
}) => {
  const replaceParam = (value: string | null, index: number) => {
    if (value === null) {
      onChangeWithValidation(
        { ...rule, parameters: rule.parameters.filter((_, idx) => idx !== index) },
        onChange,
        validate
      );
    } else {
      const replaced = [...rule.parameters];
      replaced[index] = value;
      onChangeWithValidation({ ...rule, parameters: replaced }, onChange, validate);
    }
  };

  const description = useDescription(
    { rule, ruleType },
    {
      include: 'Boost people who have been at a specific size of employer for a period of time.',
      exclude: 'Remove people who have been at a specific size of employer for a period of time.',
      invert: 'Keep only people who have been at a specific size of employer for a period of time.',
    }
  );

  return (
    <div style={style}>
      {title && <HeaderRow label={title} onChange={onChange} rule={rule} />}
      <Explanatory>{description}</Explanatory>
      <div style={{ display: 'flex', marginTop: 25 }}>
        <div>
          <div>Headcount</div>
          <EditorInput
            placeholder={'employees'}
            value={rule.parameters[0]}
            onChange={e => replaceParam(e.currentTarget.value, 0)}
          />
        </div>
        <div style={{ width: 40 }} />
        <div>
          <div>Years at Company</div>
          <EditorInput
            placeholder={'years'}
            value={rule.parameters[1]}
            onChange={e => replaceParam(e.currentTarget.value, 1)}
          />
        </div>
      </div>
    </div>
  );
};

const validate = (rule: Rule): ValidationInfo => {
  if (
    !rule.parameters ||
    rule.parameters.filter(Boolean).length !== 2 ||
    isNaN(Number(rule.parameters[0])) ||
    isNaN(Number(rule.parameters[1]))
  ) {
    const ruleLabel = EditableExcludeRulesMap[rule.id as EditableRuleIds]?.label || '';
    return invalid(`${ruleLabel} requires a headcount and a minimum number of years at a company.`);
  }
  return valid();
};
