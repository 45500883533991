import { useContext, useState } from 'react';
import { OrganizationContext } from '../../contexts/Organization';
import { onStatusUpdate } from '../../Helpers';
import { ARCHIVED_STATUS_COLUMNS } from '../../settings/StatusesTab';
import { Select } from './Select';

export const STATUS_SORT_ORDERS: { [key: string]: number } = {
  'Not a match': 1,
  'Not interested': 2,
  Unresponsive: 3,
  Pool: 4,
  Contacted: 5,
  Interviewing: 6,
  Offer: 7,
  Hired: 8,
};

interface StatusSelectProps<T> {
  profile: T;
  onProfileSaved: (profile: T) => void;
  placeholder?: string;
}

export const StatusSelect = ({
  profile,
  onProfileSaved,
  placeholder,
}: StatusSelectProps<ExternalAPI.Profile>) => {
  const { me, statuses } = useContext(OrganizationContext);
  const [currStatus, setStatus] = useState(profile.status);
  const options = [
    {
      name: 'Active',
      options: statuses
        .filter(s => !ARCHIVED_STATUS_COLUMNS.includes(s))
        .map(s => ({ name: s, id: s })),
    },
    {
      name: 'Archive',
      options: statuses
        .filter(s => ARCHIVED_STATUS_COLUMNS.includes(s))
        .map(s => ({ name: s, id: s })),
    },
  ];

  const onSelectStatus = async (status?: string) => {
    if (!!status) {
      await onStatusUpdate(
        profile,
        status,
        { id: me.id, name: me.name },
        { onSaved: onProfileSaved }
      );
      setStatus(status);
    }
  };

  return (
    <Select
      placeholder={placeholder || '--'}
      selected={currStatus}
      options={options}
      type="secondary"
      onSelect={onSelectStatus}
    />
  );
};
