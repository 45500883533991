import React from 'react';
import { Colors } from './Theme';

export const SequoiaLogo: React.FunctionComponent<{ style?: React.CSSProperties }> = ({
  style,
}) => {
  return (
    <span title="Works at a Portfolio Company">
      <svg
        width="79px"
        height="79px"
        viewBox="0 0 79 79"
        version="1.1"
        style={{ ...{ height: 12, width: 12 }, ...style }}
      >
        <g fill="none" fillRule="evenodd">
          <g fill={style?.color || Colors.Static.SEQUOIA_GREEN} fillRule="nonzero">
            <polygon points="58.9 0.7 78.3 0.7 78.3 20.1 58.9 20.1"></polygon>
            <polyline points="10.4 61.7 10.4 0.7 0.7 0.7 0.7 71.4 10.4 61.7"></polyline>
            <polyline
              id="Path"
              points="17.2 68.6 7.5 78.3 78.2 78.3 78.2 68.6 17.2 68.6"
            ></polyline>
            <polyline points="29.8 42.3 29.8 0.7 20.1 0.7 20.1 52 29.8 42.3"></polyline>
            <polyline
              id="Path"
              points="36.6 49.2 26.9 58.9 78.2 58.9 78.2 49.2 36.6 49.2"
            ></polyline>
            <polyline points="49.2 22.9 49.2 0.7 39.5 0.7 39.5 32.6 49.2 22.9"></polyline>
            <polyline points="56 29.8 46.3 39.5 78.2 39.5 78.2 29.8 56 29.8"></polyline>
          </g>
        </g>
      </svg>
    </span>
  );
};
