import React, { useContext } from 'react';
import { CountWithIcon } from '../components/Common';
import { Select, SelectOption } from '../components/selects/Select';
import { OrganizationContext } from '../contexts/Organization';

const OWNER_SELECT_ALL_ID = 0;
export const OWNER_SELECT_NONE_ID = -1;

export const PipelineOwnerFilter: React.FC<{
  value: number | undefined;
  onChange: (value: number | undefined) => void;
  profiles: Pick<ExternalAPI.Profile, 'ownerId'>[];
}> = ({ value, onChange, profiles }) => {
  const { id, team, me } = useContext(OrganizationContext);

  const ownerOptions = React.useMemo(
    () =>
      [me, ...team]
        .reduce(
          (acc, m) => {
            if (m.organizationId === id) {
              acc.push({ id: m.id, name: m.name || m.email });
            }
            return acc;
          },
          [
            { id: OWNER_SELECT_ALL_ID, name: 'All Owners' },
            { id: OWNER_SELECT_NONE_ID, name: 'No Assigned Owner' },
          ] as SelectOption[]
        )
        .map(o => ({
          ...o,
          extra: (
            <CountWithIcon
              icon={{ src: '/icons/person-filled.svg', alt: 'person' }}
              count={getOwnerMatchCount(profiles, o.id)}
            />
          ),
        })),
    [me, team, profiles, id]
  );

  return (
    <Select
      placeholder={'OWNER'}
      selected={value || OWNER_SELECT_ALL_ID}
      onSelect={onChange}
      options={ownerOptions}
      optionsPlacement="bottomRight"
    />
  );
};

const getOwnerMatchCount = (profiles: Pick<ExternalAPI.Profile, 'ownerId'>[], ownerId: number) => {
  switch (ownerId) {
    case OWNER_SELECT_ALL_ID:
      return profiles.length;
    case OWNER_SELECT_NONE_ID:
      return profiles.filter(p => !p.ownerId).length;
    default:
      return profiles.filter(p => p.ownerId === ownerId).length;
  }
};
