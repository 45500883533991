import { useEffect, useState } from 'react';
import { getProfileName } from '../Helpers';
import { makeRequest } from '../shared/Resource';

export function useRelatedProfiles<
  T extends Pick<ExternalAPI.Profile, 'endorsements' | 'organizationId'>
>(profiles: T[]) {
  const [relatedProfiles, setRelatedProfiles] = useState<ExternalAPI.Profile[]>([]);

  useEffect(() => {
    if (profiles.length > 0) {
      loadRelatedProfiles(profiles[0].organizationId, profiles, setRelatedProfiles);
    }
  }, [profiles]);

  return {
    relatedProfiles,
    getRelatedProfileName: (id: number) => getProfileName(relatedProfiles, id) || '',
  };
}

export const loadRelatedProfiles = async (
  orgId: number,
  profiles: Pick<ExternalAPI.Profile, 'endorsements'>[],
  onLoad: (p: ExternalAPI.Profile[]) => void
) => {
  const ids = profiles.reduce((acc, p) => {
    p.endorsements.forEach(e => acc.add(e.owner));

    return acc;
  }, new Set() as Set<number>);

  if (ids.size === 0) {
    onLoad([]);
    return;
  }

  const result = await makeRequest<ExternalAPI.Profile[]>(`/api/${orgId}/profiles/search`, 'POST', {
    ids: Array.from(ids),
  });
  onLoad(result);
};
