import { default as React, useContext } from 'react';
import { CSSProperties } from 'styled-components';
import { OrganizationContext } from '../../contexts/Organization';
import { onProfileUpsert } from '../../Helpers';
import { AndMoreSuffix } from '../../network/AndMoreSuffix';
import { makeRequest } from '../../shared/Resource';
import { ActionButton } from '../Button';
import { AddTagsPopover, TagContainer } from '../popovers/AddTagsPopover';
import { EditTagPopover } from '../popovers/EditTagPopover';
import { SeqTag, SeqTagProps } from '../SeqTag';
import { TagIcon } from '../Svgs';

export function useTagsRow({
  profile,
  onProfileSaved,
}: {
  profile: ExternalAPI.Profile;
  onProfileSaved: (profile?: ExternalAPI.Profile) => void;
}) {
  const { tags } = useContext(OrganizationContext);
  const { hiddenFunctionTags = [] } = profile.settings || {};

  const onRemoveProfileTag = async (tagName: string) => {
    const updatedTags = profile.tags.filter(t => t !== tagName);
    await onProfileUpsert(profile, { tags: updatedTags }, onProfileSaved);
  };

  const onRemoveFunctionTag = async (tagName: string) => {
    const hiddenFunctionTags = (profile.settings?.hiddenFunctionTags || []).concat(tagName);
    const updatedProfile = await makeRequest<ExternalAPI.Profile>(
      `/api/${profile.organizationId}/profile-settings/${profile.id}`,
      'PUT',
      { hiddenFunctionTags }
    );
    onProfileSaved?.(updatedProfile);
  };

  const seqTags = profile.functions
    .filter(f => !hiddenFunctionTags.includes(f))
    .map(t => {
      const tagProps: SeqTagProps = {
        tagName: t,
        clickable: false,
        onRemove: () => onRemoveFunctionTag(t),
      };

      return <SeqTag key={`seq-${t}`} {...tagProps} />;
    });

  const profileTags =
    'tags' in profile
      ? profile.tags
          .filter(t => !!tags[t])
          .sort()
          .map(t => {
            const tagProps: SeqTagProps = {
              tagName: t,
              tagColor: tags[t].color,
              description: tags[t].description,
              clickable: false,
              onRemove: () => onRemoveProfileTag(t),
              editEl: editButton => (
                <EditTagPopover currTag={t} onRemoveProfileTag={() => onRemoveProfileTag(t)}>
                  {editButton}
                </EditTagPopover>
              ),
            };

            return <SeqTag key={t} {...tagProps} />;
          })
      : [];

  const allTags = [...seqTags, ...profileTags];

  return {
    icon: <TagIcon />,
    title: 'tags',
    content: allTags.length ? (
      <CondensingTagsContainer condensed={false} tags={allTags} />
    ) : undefined,
    actions: (
      <AddTagsPopover profile={profile} onProfileSaved={onProfileSaved}>
        <ActionButton style={{ marginLeft: 16 }}>Add</ActionButton>
      </AddTagsPopover>
    ),
  };
}

export const CondensingTagsContainer: React.FC<{
  tags: React.ReactNode[];
  condensed?: boolean;
  style?: CSSProperties;
}> = ({ tags, condensed, style }) => {
  const hiddenTags = condensed ? tags.slice(4) : [];

  return (
    <TagContainer style={style}>
      {condensed ? (
        <>
          {tags.slice(0, 4)}
          {!!hiddenTags.length && (
            <SeqTag
              tagName={<AndMoreSuffix amt={hiddenTags.length} />}
              tagColor="transparent"
              description={<TagContainer>{hiddenTags}</TagContainer>}
              style={{ paddingLeft: 2 }}
            />
          )}
        </>
      ) : (
        tags
      )}
    </TagContainer>
  );
};
