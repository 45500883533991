import React, { CSSProperties } from 'react';
import styled from 'styled-components/macro';
import { Body1CSS, Body2CSS, Body3CSS, H4CSS } from '../components/Text';
import { AvatarWithStar } from '../pipeline/AvatarWithStar';
import { bestRoleTitleAndEmployer, MinimalExtProfile } from '../shared/profile-helpers';
import { Colors } from '../shared/Theme';
import { FlexFillingColCSS, FlexFillingRowCSS, VerticalDivider } from './Common';
import { SocialLinks } from './profile-drawer/ProfileDrawer';
import { Body1, Body3, CondensedTextCSS } from './Text';

const { BLACK8ALPHA } = Colors.Static;

type ProfileSize = 'small' | 'medium' | 'large';

interface ProfileProps<T> {
  profile: T;
  size?: ProfileSize;
  extra?: { header?: React.ReactNode; footer?: React.ReactNode };
  style?: CSSProperties;
  hideAvatar?: true;
}

export const Profile = <T extends MinimalExtProfile>({
  profile,
  size = 'medium',
  extra,
  style,
  hideAvatar,
}: ProfileProps<T>) => {
  const { endorsements = [], name, location, title, links, identifier } = profile;

  return (
    <Container size={size} style={style}>
      {!hideAvatar && (
        <div style={{ margin: 'auto' }}>
          <AvatarWithStar size={size} endorsements={endorsements} name={name} />
        </div>
      )}
      <ProfileInfo size={size}>
        <div style={{ display: 'flex' }}>
          <ProfileName
            size={size}
            style={{
              userSelect: 'text',
              width: endorsements.length && size !== 'small' ? '90%' : '95%',
              flex: 1,
            }}
          >
            {name}
          </ProfileName>
          {(size === 'large' || extra?.header) && (
            <div style={{ display: 'flex', gap: 4, alignItems: 'center' }}>
              {size === 'large' && <SocialLinks identifier={identifier} links={links} />}
              <VerticalDivider />
              {extra?.header}
            </div>
          )}
        </div>
        <ProfileSubtext size={size}>
          {('bestRole' in profile
            ? profile.bestRole?.title
            : 'details' in profile
              ? bestRoleTitleAndEmployer(profile)
              : undefined) || title}
        </ProfileSubtext>
        {size === 'large' ? (
          <Body1 textType="subtle">{location}</Body1>
        ) : size === 'medium' ? (
          <Body3
            textType="subtle"
            style={{ whiteSpace: 'nowrap', overflow: 'hidden', minWidth: 0 }}
          >
            {location}
          </Body3>
        ) : undefined}
        {extra?.footer}
      </ProfileInfo>
    </Container>
  );
};

const Container = styled.div<{ size: ProfileSize }>`
  ${FlexFillingRowCSS};
  flex: unset;
  align-items: center;
  justify-content: center;
  ${({ size }) => (size === 'large' ? `gap: 16px;` : `gap: 8px;`)}
`;

const ProfileInfo = styled.div<{ size: ProfileSize }>`
  ${FlexFillingColCSS}
  min-width: 0;
  justify-content: center;
  ${({ size }) => size === 'medium' && `height: 56px;`}
`;

const ProfileName = styled.div<{ size: ProfileSize }>`
  ${({ size }) => (size === 'large' ? H4CSS : Body2CSS)}
`;

export const ProfileSubtext = styled(Body3)<{ size: ProfileSize }>`
  color: ${BLACK8ALPHA};
  ${CondensedTextCSS}
  ${({ size }) => (size === 'large' ? Body1CSS : Body3CSS)}
  ${({ size }) => size === 'small' && 'font-weight: 300;'}
`;
