import React, { useContext } from 'react';
import { OrganizationContext } from '../contexts/Organization';
import { onBulkUpdateProfiles } from '../Helpers';
import { SeqButton } from './Button';
import {
  MultiProfileUpdateButton,
  MultiProfileUpdateButtonProps,
} from './MultiProfileUpdateButton';
import { BulkAddTagsPopover } from './popovers/AddTagsPopover';

export const MultiProfileUpdateButtonGroup: React.FC<{
  checkedIds: number[];
  onSaved: (profiles: NetworkAPI.NetworkProfile[]) => void;
}> = ({ checkedIds, onSaved }) => {
  const { id, statuses } = useContext(OrganizationContext);

  const disabled = !checkedIds.length;

  const onUpdate = (update: ExternalAPI.MultiProfileUpdateReq['update']) => {
    onBulkUpdateProfiles({ orgId: id, ids: checkedIds, update, onSaved });
  };

  return (
    <>
      <BulkAddTagsPopover disabled={disabled} ids={checkedIds} onUpdate={onUpdate}>
        <SeqButton disabled={disabled}>+ tags</SeqButton>
      </BulkAddTagsPopover>
      <BulkUpdateRoleButton ids={checkedIds} onUpdate={onUpdate} disabled={disabled} />
      <MultiProfileUpdateButton
        options={statuses.map((name, id) => ({ id, name }))}
        updateType="status"
        ids={checkedIds}
        onUpdate={onUpdate}
        disabled={disabled}
      >
        + statuses
      </MultiProfileUpdateButton>
    </>
  );
};

const BulkUpdateRoleButton: React.FC<
  Pick<MultiProfileUpdateButtonProps, 'ids' | 'onUpdate' | 'disabled'>
> = props => {
  const { roles } = useContext(OrganizationContext);
  const openRoles = roles.filter(r => !r.deletedAt);
  return openRoles ? (
    <MultiProfileUpdateButton options={openRoles} updateType="role" {...props}>
      + ROLES
    </MultiProfileUpdateButton>
  ) : (
    <div />
  );
};
