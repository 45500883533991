import { Dropdown, Menu } from 'antd';
import { default as React } from 'react';
import { HorizontalDivider } from '../components/Common';
import { ClickableItem } from '../shared/Common';
import { SearchTermLabels, SearchTitle } from './SearchTitle';

export const SearchHistoryDropdown: React.FC<{
  history: NetworkAPI.MinimalSearchHistory[];
  searchTermLabels: SearchTermLabels;
  onItemClicked: (item: NetworkAPI.MinimalSearchHistory) => void;
}> = ({ history, searchTermLabels, onItemClicked }) => {
  const SearchHistoryMenu: React.ReactElement = (
    <Menu style={{ maxWidth: 500, maxHeight: 'calc(100vh - 180px)', overflowY: 'auto' }}>
      <div style={{ fontWeight: 500, padding: '8px 16px' }}>Recent Searches</div>
      <HorizontalDivider />
      {history.map((h, idx) => (
        <Menu.Item
          style={{ padding: '8px 16px', fontSize: 12, width: '100%', whiteSpace: 'normal' }}
          key={`search-history-${idx}`}
          onClick={() => onItemClicked(h)}
        >
          <SearchTitle search={h.data} labels={searchTermLabels} />
        </Menu.Item>
      ))}
    </Menu>
  );
  return (
    <Dropdown overlay={SearchHistoryMenu} placement="bottomRight">
      <ClickableItem>
        <img alt="history" style={{ height: 18 }} src="/icons/history.svg" />
      </ClickableItem>
    </Dropdown>
  );
};
