import { Select } from 'antd';
import {
  EditableExcludeRulesMap,
  EditableRuleIds,
  Explanatory,
  HeaderRow,
  invalid,
  onChangeWithValidation,
  Rule,
  RuleEditorProps,
  useDescription,
  valid,
  ValidationInfo,
} from '../rule-editors/RuleEditorCommon';

const VOUCH_OPTIONS = ['A', 'B', 'AorB'];

export const HasBeenVouchedEditor: React.FC<RuleEditorProps> = ({
  rule,
  onChange,
  style,
  title,
  ruleType,
}) => {
  const description = useDescription(
    { ruleType, rule },
    {
      include: 'Choose vouch types which should receive a boost:',
      exclude: 'Exclude people receiving a vouch of:',
      invert: 'Only include people receiving a vouch of type:',
    }
  );

  const updateParams = (selected: string) => {
    onChangeWithValidation({ ...rule, parameters: [selected] }, onChange, validate);
  };

  return (
    <div style={style}>
      {title && <HeaderRow label={title} onChange={onChange} rule={rule} />}
      <Explanatory>{description}</Explanatory>
      <Select
        value={rule.parameters[0]}
        style={{ width: 100 }}
        onChange={e => updateParams(e.toString())}
        options={VOUCH_OPTIONS.map(o => ({ value: o, name: o }))}
      />
    </div>
  );
};

const validate = (rule: Rule): ValidationInfo => {
  const ruleLabel = EditableExcludeRulesMap[rule.id as EditableRuleIds]?.label || '';
  if (!rule.parameters || rule.parameters.filter(Boolean).length !== 1) {
    return invalid(`${ruleLabel} requires at least one vouch status to be selected.`);
  }
  return valid();
};
