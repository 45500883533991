import moment from 'moment';
import React, { CSSProperties, useContext, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { FlexFillingColCSS, HorizontalDivider } from '../components/Common';
import { H4 } from '../components/Text';
import { OrganizationContext } from '../contexts/Organization';
import { isInternal } from '../Helpers';
import { ReviewFeedback } from '../leads/LeadReviewCarousel';
import { InsightTags } from '../network/InsightTags';
import { Dot, FadeInContainer } from '../shared/Common';
import { CompanyLILink } from '../shared/CompanyLILink';
import { TALENT_WEB_APP } from '../shared/helpers';
import { activeRole, getNameParts, sortEducation, sortWorks } from '../shared/profile-helpers';
import { makeRequest, useResource } from '../shared/Resource';
import { ScrollingContainer } from '../shared/ScrollableContainer';
import { SequoiaLogo } from '../shared/SequoiaLogo';
import { BriefcaseIcon } from '../shared/Svgs';
import { Colors } from '../shared/Theme';
import { HighlightedEl } from './CandidateAnnotations';
import { LightTooltip } from './Common';
import { ListRow } from './ListRow';
import { Profile } from './Profile';
import { AddCompanyToSEFilterButton, AddTitleToSEFilterButton } from './SEFilterButtons';
import { SourcerComment, SourcerCommentProps } from './SourcerComment';
import { IconContainer, LinkedInIcon, SchoolIcon } from './Svgs';
import { Body1, Body2, Body2Serif } from './Text';

const { BLACK7ALPHA, SEQUOIA_BLACK, BLACK5ALPHA, SEQUOIA_DARK_GREEN } = Colors.Static;

export const CandidateProfile: React.FunctionComponent<{
  profile: ExternalAPI.Profile;
  sourcerComment?: SourcerCommentProps;
  annotation?: LeadsAPI.Annotation;
  generatedBySEModelId: number | null;
  hasCampaign: boolean;
  onReject: (feedback?: ReviewFeedback) => void;
}> = ({ profile, sourcerComment, annotation, generatedBySEModelId, onReject, hasCampaign }) => {
  const { me } = useContext(OrganizationContext);
  const { details, insights } = profile;

  return (
    <>
      <Profile
        style={{ padding: 16, borderBottom: `1px solid ${SEQUOIA_BLACK}` }}
        profile={profile}
        size="large"
        extra={{
          header: (
            <>
              {isInternal(me) && !!profile.identifier && (
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`${TALENT_WEB_APP}/people/${profile.identifier}`}
                >
                  <SequoiaLogo style={{ width: 20, height: 20 }} />
                </a>
              )}
              <LikelyToRespond profile={profile} />
            </>
          ),
          footer: <InsightTags insights={insights} style={{ marginTop: 6 }} />,
        }}
      />
      <Container>
        {sourcerComment && <SourcerComment {...sourcerComment} />}
        <WorkAndEducation
          onReject={onReject}
          details={details}
          annotation={annotation}
          generatedBySEModelId={generatedBySEModelId}
          hasCampaign={hasCampaign}
          style={{ marginLeft: 24 }}
        />
      </Container>
    </>
  );
};

const Container = styled(ScrollingContainer)`
  ${FlexFillingColCSS}
`;

export const WorkAndEducation: React.FC<{
  details: CoreAPI.ProfileDetails;
  onReject?: (feedback?: ReviewFeedback) => void;
  generatedBySEModelId?: number | null;
  compactMode?: boolean;
  annotation?: LeadsAPI.Annotation;
  hasCampaign?: boolean;
  style?: CSSProperties;
}> = ({ details, compactMode, annotation, generatedBySEModelId, onReject, hasCampaign, style }) => {
  return (
    <div style={style}>
      {!compactMode && details.summary && (
        <>
          <ProfileSummary>
            <HighlightedEl
              highlights={annotation?.highlights.filter(h => h.location === 'summary') || []}
              str={details.summary}
            />
          </ProfileSummary>
        </>
      )}
      {!!details.work?.length && (
        <>
          <H4 style={{ paddingTop: 16 }}>Work history</H4>
          <HorizontalDivider />
          <WorkList
            work={details.work}
            highlights={annotation?.highlights.filter(h => h.location === 'work') || []}
            generatedBySEModelId={generatedBySEModelId}
            onReject={onReject}
            hasCampaign={hasCampaign}
          />
        </>
      )}
      {!!details.education?.length && (
        <>
          <H4 style={{ paddingTop: 16 }}>Education</H4>
          <HorizontalDivider />
          {sortEducation(details.education).map((e: CoreAPI.SchoolRole, idx: React.Key) => (
            <EducationEntry
              key={idx}
              education={e}
              highlights={annotation?.highlights.filter(h => h.location === 'education') || []}
              hasDivider={details.education!.length - 1 !== idx}
            />
          ))}
        </>
      )}
    </div>
  );
};

const WorkList: React.FC<{
  work: CoreAPI.WorkRole[];
  highlights: LeadsAPI.Annotation['highlights'];
  generatedBySEModelId?: number | null;
  onReject?: (feedback?: ReviewFeedback) => void;
  hasCampaign?: boolean;
}> = ({ work, highlights, generatedBySEModelId, onReject, hasCampaign }) => {
  const [fundingAnnotations, setAnnotations] = useState<CompanyFundingAPI.WorkEntryAnnotations[]>(
    []
  );

  const { id: orgId } = useContext(OrganizationContext);
  const [models = []] = useResource<SourcingEngineAPI.EngineModel[]>(
    `/api/sourcing/${orgId}/engine-models`,
    { showArchived: true }
  );
  const modelToUpdate = models.find(m => !m.archivedAt && m.id === generatedBySEModelId);

  useEffect(() => {
    let active = true;

    const data = work.reduce(
      (prev, w) =>
        w.company.lirID
          ? [...prev, { lirId: w.company.lirID, start: w.startTimestamp, end: w.endTimestamp }]
          : prev,
      [] as CompanyFundingAPI.WorkEntryInfo[]
    );

    const getFundingAnnotations = async () => {
      const res = await makeRequest<CompanyFundingAPI.WorkEntryAnnotations[]>(
        `/api/company-funding/`,
        'POST',
        { data }
      );
      if (res.length && active) setAnnotations(res);
    };

    getFundingAnnotations();

    return () => {
      active = false;
    };
  }, [work]);

  return (
    <>
      {sortWorks(work).map((w, idx) => (
        <WorkEntry
          key={idx}
          onReject={onReject}
          model={modelToUpdate}
          workRole={w}
          hasCampaign={hasCampaign}
          hasDivider={work.length - 1 !== idx}
          annotations={{
            funding: fundingAnnotations?.find(a => a.lirId === w.company.lirID),
            highlights,
          }}
        />
      ))}
    </>
  );
};

const WorkEntry: React.FunctionComponent<{
  workRole: CoreAPI.WorkRole;
  model: SourcingEngineAPI.EngineModel | undefined;
  onReject?: (feedback?: ReviewFeedback) => void;
  annotations: {
    highlights: LeadsAPI.Annotation['highlights'];
    funding?: CompanyFundingAPI.WorkEntryAnnotations;
  };
  hasCampaign?: boolean;
  hasDivider?: boolean;
}> = ({ workRole, model, annotations, onReject, hasCampaign, hasDivider }) => {
  const { highlights, funding } = annotations;
  const DATE_FORMAT = 'MMM YYYY';
  const { title, company, startTimestamp, endTimestamp, description } = workRole;

  const showSEFilterButtons = model && hasCampaign && !endTimestamp;

  return (
    <Entry
      hasDivider={hasDivider}
      icon={<BriefcaseIcon />}
      description={description}
      highlights={highlights}
      title={title}
      titleSEFilterButton={
        showSEFilterButtons && (
          <div style={{ flexShrink: 2, padding: 0, margin: 0, marginLeft: 5, marginTop: -3 }}>
            <AddTitleToSEFilterButton title={title} model={model} onReject={onReject} />
          </div>
        )
      }
      subtitle={
        <div style={{ display: 'flex', alignItems: 'flex-start' }}>
          <div style={{ display: 'flex', alignItems: 'center', minWidth: 0, gap: 4 }}>
            {showSEFilterButtons && (
              <AddCompanyToSEFilterButton company={company} model={model} onReject={onReject} />
            )}
            <CompanyLILink
              lirCompanyId={company.lirID}
              icon={
                <div style={{ display: 'flex', marginLeft: 2, gap: 2 }}>
                  <Dot style={{ marginTop: 7 }} size={3} color={BLACK5ALPHA} />
                  <IconContainer style={{ width: 16 }}>
                    <LinkedInIcon color={BLACK5ALPHA} />
                  </IconContainer>
                </div>
              }
            >
              <DescriptionEntry>
                <Body2>
                  <HighlightedEl str={company.name} highlights={highlights} />
                </Body2>
              </DescriptionEntry>
            </CompanyLILink>
          </div>
          {funding && (
            <div
              style={{
                lineHeight: 1.4,
                display: 'flex',
                flex: 1,
                fontSize: 12,
                alignItems: 'center',
              }}
            >
              {funding.companyAnnotation && (
                <FadeInContainer style={{ display: 'flex' }}>
                  <Dot style={{ marginTop: 7 }} size={3} color={BLACK5ALPHA} />
                  <Body2 style={{ color: SEQUOIA_DARK_GREEN }}>{funding.companyAnnotation}</Body2>
                </FadeInContainer>
              )}
              {funding.dateAnnotation && (
                <FadeInContainer
                  style={{
                    flex: 1,
                    textAlign: 'right',
                    paddingLeft: 12,
                    minWidth: 80,
                  }}
                >
                  <Body2 style={{ color: SEQUOIA_DARK_GREEN }}>{funding.dateAnnotation}</Body2>
                </FadeInContainer>
              )}
            </div>
          )}
        </div>
      }
      dateRange={
        startTimestamp ? (
          <DateEntry>{`${moment(startTimestamp).format(DATE_FORMAT)} - ${
            endTimestamp ? moment(endTimestamp).format(DATE_FORMAT) : 'Present'
          }`}</DateEntry>
        ) : (
          <DateEntry>Present</DateEntry>
        )
      }
    />
  );
};

const EducationEntry: React.FunctionComponent<{
  education: CoreAPI.SchoolRole;
  highlights: LeadsAPI.Annotation['highlights'];
  hasDivider?: boolean;
}> = ({ education, highlights, hasDivider }) => {
  return (
    <Entry
      hasDivider={hasDivider}
      icon={<SchoolIcon />}
      title={education.school.name}
      subtitle={
        education.degree ? (
          <DescriptionEntry>
            <HighlightedEl str={education.degree} highlights={highlights} />
          </DescriptionEntry>
        ) : (
          ''
        )
      }
      dateRange={<DateEntry style={{ flexShrink: 10 }}>{education.years || ''}</DateEntry>}
      highlights={highlights}
    />
  );
};

const Entry: React.FunctionComponent<{
  icon: React.ReactNode;
  title: string;
  subtitle: React.ReactNode;
  dateRange: React.ReactNode;
  highlights: LeadsAPI.Annotation['highlights'];
  description?: string;
  hasDivider?: boolean;
  titleSEFilterButton?: React.ReactNode;
}> = ({
  title,
  description,
  subtitle,
  dateRange,
  icon,
  highlights,
  hasDivider,
  titleSEFilterButton,
}) => (
  <ListRow
    hasDivider={hasDivider}
    icon={icon}
    contentStyle={{ flexDirection: 'column' }}
    content={
      <>
        <div style={{ display: 'flex', alignItems: 'flex-start' }}>
          <DescriptionEntry style={{ fontWeight: 500, flexShrink: 2 }}>
            <HighlightedEl str={title} highlights={highlights} />
          </DescriptionEntry>
          {titleSEFilterButton}
          <div style={{ flex: 1 }} />
          {dateRange}
        </div>
        {subtitle}
        {description && (
          <Body2 style={{ color: BLACK7ALPHA, whiteSpace: 'pre-wrap', paddingTop: 5 }}>
            <HighlightedEl str={description} highlights={highlights} />
          </Body2>
        )}
      </>
    }
  />
);

const LikelyToRespond: React.FC<{ profile: ExternalAPI.Profile }> = ({ profile }) => {
  const active = activeRole(profile);
  if (!active) {
    return <></>;
  }
  const monthsInJob = moment().diff(moment.unix(active?.startTimestamp / 1000), 'months', true);
  if (monthsInJob >= 22 && monthsInJob < 36) {
    return (
      <LightTooltip
        placement="topLeft"
        overlay={`${getNameParts(profile.name).first} is likely to be open to exploring new roles.`}
      >
        <GlowingBulb
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.186 19.172c.789.51 1.701.855 2.814.828c1.111.027 2.025-.318 2.814-.828L12.797 17H7.203l-.017 2.172zM12.697 16c0-4.357 4.63-5.848 4.283-10.188c-.218-2.738-2.073-5.81-6.98-5.81S3.238 3.074 3.019 5.813C2.672 10.152 7.303 11.643 7.303 16h5.394zM5 6c.207-2.598 2.113-4 5-4c2.886 0 4.654 1.371 4.861 3.969c.113 1.424-.705 2.373-1.809 3.926C12.238 11.041 11.449 12.238 11 14H9c-.449-1.762-1.238-2.959-2.053-4.106C5.844 8.342 4.886 7.424 5 6z"
            fill="#8c8c8c"
          />
        </GlowingBulb>
      </LightTooltip>
    );
  }
  return <></>;
};

const GlowingBulb = styled.svg`
  font-size: 18px;
  margin-right: 13px;
  -webkit-transition-property: -webkit-transform;
  -webkit-transition-duration: 0.5s;
  -moz-transition-property: -moz-transform;
  -moz-transition-duration: 0.5s;
  -webkit-animation-name: grow;
  -webkit-animation-duration: 0.75s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -webkit-animation-direction: alternate;

  -moz-animation-name: grow;
  -moz-animation-duration: 0.75s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -moz-animation-direction: alternate;

  transition-property: -moz-transform;
  transition-duration: 0.5s;
  animation-name: grow;
  animation-duration: 0.75s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-direction: alternate;
`;

const ProfileSummary = styled(Body2Serif)`
  padding: 16px 0;
  white-space: pre-line;
`;

const DescriptionEntry = styled(Body1)`
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
  white-space: nowrap;
`;

const DateEntry = styled(DescriptionEntry)`
  white-space: nowrap;
  color: ${SEQUOIA_BLACK};
  flex-shrink: 0;
`;
