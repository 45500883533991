import Axis from 'bizcharts/lib/components/Axis';
import Chart from 'bizcharts/lib/components/Chart';
import Legend from 'bizcharts/lib/components/Legend';
import ChartToolTip from 'bizcharts/lib/components/Tooltip';
import Interval from 'bizcharts/lib/geometry/Interval';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { DEFAULT_CHART_HEIGHT, MAX_CHARTS_SLIDER } from '../ChartHelpers';
import { extractAxisClick, extractClickData, StackedBarChartData } from '../shared/ChartHelpers';
import { Colors, hexWithOpacity } from '../shared/Theme';
import { ChartLegend } from './ChartLegend';

const { SEQUOIA_GREEN, SEQUOIA_BLACK } = Colors.Static;

export type CampaignInfoChartType = 'pie' | 'bar';

const sliceBarGraphDataByCampaignName = (
  campaigns: CampaignAPI.CampaignSummary[]
): (StackedBarChartData & {
  externalOnly?: boolean;
  color: string;
})[][] => {
  return campaigns.map(c => {
    const countSafe = c.countCandidates || 1;
    let chartData = [
      {
        dataSet: 'Contacted',
        groupName: c.name,
        value: c.countCandidates,
        percent: 0 / countSafe,
        color: hexWithOpacity(SEQUOIA_GREEN, 0.15),
      },
      {
        dataSet: 'Replied',
        groupName: c.name,
        value: c.candidatesResponding.raw,
        percent: c.candidatesResponding.raw / countSafe,
        color: hexWithOpacity(SEQUOIA_GREEN, 0.6),
      },
      {
        dataSet: 'Replied Positively',
        groupName: c.name,
        value: c.candidatesRespondingPositively.raw,
        percent: c.candidatesRespondingPositively.raw / (c.candidatesResponding.raw || 1),
        color: SEQUOIA_GREEN,
      },
    ];
    return chartData;
  });
};

export const CampaignInfoCharts: React.FunctionComponent<{
  campaigns: CampaignAPI.CampaignSummary[];
}> = ({ campaigns }) => {
  const history = useHistory();

  const [arrowIndex, setArrowIndex] = React.useState(0);
  const maxIndex = Math.floor(campaigns.length / MAX_CHARTS_SLIDER);
  const leftArrowClick = arrowIndex > 0 ? () => setArrowIndex(c => Math.max(c - 1, 0)) : undefined;
  const rightArrowClick =
    arrowIndex < maxIndex ? () => setArrowIndex(c => Math.min(c + 1, maxIndex)) : undefined;

  const navigateToCampaign = (name: string) => {
    const c = campaigns.find(c => c.name === name);
    c && history.push(`/${c.organizationId}/campaigns/sender/${c.senderId}/campaigns/${c.id}`);
  };

  const chartFormattedData = sliceBarGraphDataByCampaignName(campaigns);

  if (!chartFormattedData.length) {
    return <></>;
  }

  return (
    <div>
      <ChartLegend
        labelsAndColors={chartFormattedData[0].map(c => ({ label: c.dataSet, color: c.color }))}
        onLeftClick={leftArrowClick}
        onRightClick={rightArrowClick}
      />
      <Chart
        height={DEFAULT_CHART_HEIGHT}
        padding={[50, 50, 50, 50]}
        defaultInteractions={['tooltip']}
        data={chartFormattedData
          .filter(
            (_, idx) =>
              idx >= arrowIndex * MAX_CHARTS_SLIDER && idx < MAX_CHARTS_SLIDER * (arrowIndex + 1)
          )
          .flat()}
        autoFit
        onAxisLabelClick={(e: any) => {
          const clickedLabel = extractAxisClick(e);
          if (clickedLabel) {
            navigateToCampaign(clickedLabel);
          }
        }}
        onPlotClick={(e: any) => {
          const clicked = extractClickData<StackedBarChartData>(e);
          if (!clicked) {
            return;
          }
          navigateToCampaign(clicked.groupName);
        }}
      >
        <Axis name={'groupName'} label={{ style: { color: SEQUOIA_BLACK } }} />
        <Legend visible={false} />
        <ChartToolTip showTitle={false} />
        <Interval
          adjust={[{ type: 'dodge', marginRatio: 0 }]}
          color={['dataSet', chartFormattedData[0].map(c => c.color)]}
          position="groupName*value"
        />
      </Chart>
    </div>
  );
};
