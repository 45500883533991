import { useEffect, useState } from 'react';
import { useKeyDownListeners } from '../shared/hooks';

export function useListInteractions() {
  const [focusedId, _setFocusedId] = useState<number>(0);
  const [itemIds, setItemIds] = useState<number[]>([]);

  const itemPos =
    !focusedId || itemIds.length < 2
      ? null
      : getItemPos(itemIds.findIndex(itemId => itemId === focusedId) || 0, itemIds.length);

  function setFocusedId(id: number, ids?: number[]) {
    ids && setItemIds(ids);
    _setFocusedId(id);
  }

  function shiftFocusedId(direction: string) {
    const curIdx = itemIds.findIndex(itemId => itemId === focusedId) || 0;

    if (direction === 'ArrowDown' && itemPos !== 'last') {
      _setFocusedId(itemIds[curIdx + 1]);
    }
    if (direction === 'ArrowUp' && itemPos !== 'first') {
      _setFocusedId(itemIds[curIdx - 1]);
    }
    if (direction === 'ArrowRight' || direction === 'Escape') {
      _setFocusedId(0);
    }
  }

  useEffect(() => {
    if (focusedId && !itemIds.find(itemId => itemId === focusedId)) {
      _setFocusedId(0);
    }
  }, [focusedId, itemIds]);

  const onKeyDown = useKeyDownListeners(shiftFocusedId);

  return {
    focusedId,
    setFocusedId,
    shiftFocusedId,
    onKeyDown,
    setItemIds,
    itemPos,
  };
}

export type ListItemPos = 'first' | 'last' | 'middle';

export const getItemPos = (curIdx: number, itemsLength: number): ListItemPos => {
  switch (curIdx) {
    case 0:
      return 'first';
    case itemsLength - 1:
      return 'last';
    default:
      return 'middle';
  }
};
