import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';
import 'react-quill/dist/quill.snow.css';
import { Route, Switch } from 'react-router-dom';
import { App } from './App';
import { BrowserRouterWithReloader } from './shared/BrowserRouterWithReloader';
import './shared/index.css';
import { Colors, GlobalStyle } from './shared/Theme';
import './styles/overrides.css';

ReactDOM.render(
  <BrowserRouterWithReloader
    bannerStyle={{ backgroundColor: Colors.Static.SEQUOIA_GREEN }}
    productName="Sequoia Talent Suite"
  >
    <GlobalStyle />
    <Switch>
      <Route path="/*" component={App} />
    </Switch>
  </BrowserRouterWithReloader>,
  document.getElementById('root')
);
