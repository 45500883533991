import { notification } from 'antd';
import { pick } from 'lodash';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components/macro';
import { SeqModal } from '../components/Modal';
import { OrganizationContext } from '../contexts/Organization';
import { OpenRejectionPopoverEvent } from '../events';
import { CAMPAIGN_SELECT_LINKED_IN_OPTION, useCampaignConfig } from '../hooks/useCampaignConfig';
import { useCarouselNavigation } from '../hooks/useCarouselNavigation';
import { keyEventInInput } from '../shared/helpers';
import { makeRequest } from '../shared/Resource';
import { CarouselItem } from './CarouselItem';
import { CandidateWithDetails, LeadListWithReviewStatus } from './PageLeads';

export const MAX_IDLE_TIME = 10 * 60 * 1000;

export type ReviewFeedback = Pick<LeadsAPI.CandidateReview, 'categories' | 'comment'>;

export const LeadReviewCarousel: React.FunctionComponent<{
  leadList: LeadListWithReviewStatus;
  candidates: CandidateWithDetails[];
  actions: {
    onUpdate: (update: Omit<LeadsAPI.UpdateLeadList, 'id'>) => Promise<void>;
    onClose: () => void;
  };
  startFrom?: string;
}> = ({ leadList, candidates, actions, startFrom }) => {
  const {
    roleId,
    organizationId,
    campaignId,
    settings: { campaignType, requireRejectionFeedback },
  } = leadList;

  const { onUpdate, onClose } = actions;

  const startIdx = Math.max(
    0,
    candidates.findIndex(c => (startFrom ? c.profile.identifier === startFrom : !c.review))
  );

  const { advance, reverse, position } = useCarouselNavigation({
    startIdx,
    listInfo: { length: candidates.length, id: leadList.id },
    onClose,
  });

  const { me } = useContext(OrganizationContext);

  const [customizing, setCustomizing] = useState(false);

  const candidate = candidates[position];

  const numOfRemainingCandidates = candidates.filter(c => !c.review).length;
  const isReviewable = useAsyncReviewable(candidate);

  //We only use the 'You're done' screen if out of candidates & user reviewed 1+ while
  //drawer has been open, otherwise they're 'browsing' reviewed candidates.
  const [useCompletedScreenAtEnd, setUseCompletedScreenAtEnd] = useState(false);
  const showingFinishedScreen = numOfRemainingCandidates === 0 && useCompletedScreenAtEnd;

  const campaignConfig = useCampaignConfig({
    feature: 'leads',
    organizationId,
    roleId,
    campaignId: campaignType === 'LinkedIn' ? CAMPAIGN_SELECT_LINKED_IN_OPTION : campaignId,
  });
  const { campaign } = campaignConfig;

  const disableAllFooterActionsReason: string | undefined = useMemo(() => {
    if (!campaign?.id && campaignType !== 'LinkedIn') {
      return `In order to contact or provide feedback on candidates, you must select a campaign from the dropdown at the top of this dialog.`;
    }
    if (campaignType !== 'LinkedIn') {
      if (campaign?.sender?.warning) {
        return campaign.sender.warning;
      }
      if (me.id !== campaign?.senderId && !campaign?.sender.allowOthersToSend) {
        return 'The owner of this campaign has not given permission for others to send emails on their behalf.';
      }
    }
    return;
  }, [
    campaignType,
    campaign?.id,
    campaign?.sender?.warning,
    campaign?.sender.allowOthersToSend,
    campaign?.senderId,
    me.id,
  ]);

  const onKeyDown = (e: React.KeyboardEvent<any> | KeyboardEvent) => {
    if (e.key === 'Escape') {
      onClose();
    }

    if (keyEventInInput(e) || customizing) {
      return;
    }

    if (e.key === 'ArrowLeft') {
      reverse();
    } else if (e.key === 'ArrowRight') {
      advance();
    }

    if (!disableAllFooterActionsReason) {
      if (e.key === 'l') {
        onLater();
      } else if (e.key === 'e' && isReviewable && campaignType !== 'LinkedIn') {
        setCustomizing(true);
      } else if (e.key === 'n' && isReviewable) {
        if (requireRejectionFeedback) {
          OpenRejectionPopoverEvent.dispatch(candidates[position].profile.id);
        } else {
          onApprovalChoice({ status: 'rejected' });
          advance();
        }
      }
    }
  };

  useEffect(() => {
    document.body.addEventListener('keydown', onKeyDown);
    return () => {
      document.body.removeEventListener('keydown', onKeyDown);
    };
  });

  const onApprovalChoice = useCallback(
    async (
      review: LeadsAPI.CandidateReview,
      overrides?: CampaignAPI.CandidateOverride,
      blockCarouselAdvance?: boolean
    ) => {
      setUseCompletedScreenAtEnd(true);

      if (campaign || campaignType === 'LinkedIn') {
        await onUpdate({
          reviewedProfile: {
            ...pick(candidate, ['internalId', 'externalId']),
            review,
            overrides,
            email: candidate.profile.email,
          },
        });

        if (!!customizing) setCustomizing(false);
        if (!blockCarouselAdvance) {
          advance();
        }
      }
    },
    [advance, campaignType, candidate, customizing, onUpdate, campaign]
  );

  const onLater = useCallback(
    async (blockCarouselAdvance?: boolean) => {
      await onApprovalChoice({ status: 'later' }, undefined, blockCarouselAdvance);
      setUseCompletedScreenAtEnd(true);
    },
    [setUseCompletedScreenAtEnd, onApprovalChoice]
  );

  return (
    <SeqModal
      open
      height="90vh"
      onClose={onClose}
      leftConfig={{ onClick: reverse, disabled: position === 0, visible: candidates.length > 1 }}
      rightConfig={{
        onClick: advance,
        disabled: position >= candidates.length - 1,
        visible: candidates.length > 1,
      }}
    >
      <CarouselItem
        key={candidate.profile.id}
        leadList={leadList}
        customizing={customizing}
        setCustomizing={setCustomizing}
        numOfRemainingCandidates={numOfRemainingCandidates}
        campaignConfig={campaignConfig}
        candidate={candidate}
        isReviewable={isReviewable}
        showingFinishedScreen={showingFinishedScreen}
        actions={{
          disabledReason: disableAllFooterActionsReason,
          onLater,
          onReject: (feedback?: ReviewFeedback) =>
            onApprovalChoice({ status: 'rejected', ...feedback }),
          onAccept: (overrides?: CampaignAPI.CandidateOverride) =>
            onApprovalChoice({ status: 'approved' }, overrides),
          onCampaignUpdate: onUpdate,
          onRequestIntro: (kind: 'talent' | 'network', id?: number) => {
            onApprovalChoice({ status: 'requested-intro', introId: id });
            if (kind === 'talent') {
              notification.success({
                message: 'Introduction requested through your recruiter.',
              });
            }
          },
        }}
      />
    </SeqModal>
  );
};

const useAsyncReviewable = (candidate: CandidateWithDetails) => {
  const [isReviewable, setIsReviewable] = useState<boolean>(
    !candidate.review || candidate.review?.status !== 'approved'
  );
  useEffect(() => {
    setIsReviewable(!candidate.review || candidate.review?.status !== 'approved');
    const checkIfReviewable = async () => {
      const { myEmails, teamEmails } = await makeRequest<PipelineAPI.EmailInteraction>(
        `/api/${candidate.profile.organizationId}/profile/${candidate.profile.id}/emails`
      );
      //This would also catch emails to same candidate from other campaigns/pipeline but that seems like a very rare case
      setIsReviewable(!myEmails.length && !teamEmails.length);
    };
    if (candidate.review?.status === 'approved') {
      checkIfReviewable();
    }
  }, [candidate]);

  return isReviewable;
};

export const ShadowedContainer = styled.div`
  border-color: transparent;
  -webkit-box-shadow:
    0 1px 2px -2px rgba(0, 0, 0, 0.16),
    0 3px 6px 0 rgba(0, 0, 0, 0.12),
    0 5px 12px 4px rgba(0, 0, 0, 0.09);
  box-shadow:
    0 1px 2px -2px rgba(0, 0, 0, 0.16),
    0 3px 6px 0 rgba(0, 0, 0, 0.12),
    0 5px 12px 4px rgba(0, 0, 0, 0.09);
`;
