import Modal, { ModalProps } from 'antd/lib/modal';
import React, { CSSProperties } from 'react';
import styled from 'styled-components/macro';
import { Colors } from '../shared/Theme';
import { FloatingButton } from './Button';
import { FlexFillingColCSS } from './Common';

export const DIALOG_MASK_STYLE: CSSProperties = {
  backdropFilter: 'blur(4px)',
  background: 'rgba(255, 255, 255, 0.8)',
};

export const CloseButton: React.FunctionComponent<{
  onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  style?: CSSProperties;
}> = ({ onClick, style }) => {
  return (
    <FloatingButton
      onClick={onClick}
      style={{ position: 'absolute', margin: 0, right: -60, ...style }}
    >
      <img src="/icons/close-x.svg" alt={'A close icon'} />
    </FloatingButton>
  );
};

export const ModalHeader = styled.div`
  font-size: 16px;
  padding: 20px 24px;
`;

interface SeqModalProps extends ModalProps {
  onClose?: () => void;
  leftConfig?: { onClick: () => void; disabled?: boolean; visible?: boolean };
  rightConfig?: { onClick: () => void; disabled?: boolean; visible?: boolean };
  height?: CSSProperties['height'];
}

export const SeqModal: React.FC<SeqModalProps> = props => {
  const { leftConfig, rightConfig, onClose, height, style = {}, ...rest } = props;

  if (typeof height === 'string' && height.includes('vh')) {
    style.top = `${(100 - Number(height.split('vh')[0])) / 2}vh`;
  }

  return (
    <Modal
      style={{ ...style, padding: undefined }}
      styles={{ mask: DIALOG_MASK_STYLE }}
      width={'fit-content'}
      footer={null}
      bodyStyle={{ padding: 0 }}
      closable={false}
      onOk={undefined}
      {...rest}
    >
      {onClose && (
        <CloseButton
          onClick={() => {
            onClose();
          }}
        />
      )}
      {leftConfig && (
        <FloatingButton
          style={{
            position: 'absolute',
            margin: 0,
            top: 'calc(50vh - 60px)',
            left: -60,
            visibility: leftConfig.visible ? 'visible' : 'hidden',
          }}
          disabled={leftConfig.disabled}
          onClick={leftConfig.onClick}
        >
          <img src="/icons/arrow-left.svg" alt="Left Arrow" />
        </FloatingButton>
      )}
      {rightConfig && (
        <FloatingButton
          style={{
            visibility: rightConfig.visible ? 'visible' : 'hidden',
            position: 'absolute',
            margin: 0,
            top: 'calc(50vh - 60px)',
            right: -60,
          }}
          disabled={rightConfig.disabled}
          onClick={rightConfig.onClick}
        >
          <img src="/icons/arrow-right.svg" alt="Right Arrow" />
        </FloatingButton>
      )}
      <ContentContainer style={{ height, ...props.bodyStyle }}>{props.children}</ContentContainer>
    </Modal>
  );
};

const ContentContainer = styled.div`
  ${FlexFillingColCSS}
  background: ${Colors.Static.SEQUOIA_PAPER};
`;
