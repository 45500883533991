import { Location } from 'history';
import React from 'react';
import extensionCurrentManifest from '../extension-current-manifest.json';

const DefaultTabState: Extension.TabState = {
  manifestVersion: extensionCurrentManifest.version,
  profile: { name: null, email: null, identifier: null },
  miner: { available: false, connectionsPrivate: false },
  site: null,
};

function tabStateFromURL(search: URLSearchParams, hash: string): Extension.TabState {
  let stateStr = search.get('state') || hash.slice(1);
  let state = {};

  if (stateStr) {
    try {
      stateStr = decodeURIComponent(stateStr);
    } catch (err) {
      console.log(`Could not decode state: ${err}`);
    }
    try {
      state = JSON.parse(stateStr);
    } catch (err) {
      console.log(`Could not parse state: ${err}`);
    }
  }
  return Object.assign({}, DefaultTabState, state);
}

export function useExtensionTabState(location: Location<unknown>) {
  return React.useMemo(
    () => tabStateFromURL(new URLSearchParams(location.search), location.hash),
    [location]
  );
}
