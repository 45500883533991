import { LoadingOutlined } from '@ant-design/icons';
import { default as React, useContext, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { SeqButton } from '../components/Button';
import {
  FlexFillingColCSS,
  FooterRow,
  LoaderLine,
  PageContainer,
  SeqDrawer,
} from '../components/Common';
import { DebouncedSearchInput } from '../components/Input';
import { MultiProfileUpdateButtonGroup } from '../components/MultiProfileUpdateButtonGroup';
import { FilterIcon } from '../components/Svgs';
import { NetworkContext } from '../contexts/Network';
import { OrganizationContext } from '../contexts/Organization';
import { logPageView } from '../Helpers';
import { useBulkEmailModal } from '../hooks/useBulkEmailModal';
import { useCandidateDrawer } from '../hooks/useProfileDrawer';
import { useProfileTableConfig } from '../hooks/useProfileTableConfig';
import { useRelatedProfiles } from '../hooks/useRelatedProfiles';
import { NETWORK_SEARCH_PLACEHOLDER } from '../pipeline/PagePipeline';
import { usePageTitle } from '../shared/Common';
import { pluralize } from '../shared/helpers';
import { makeRequest } from '../shared/Resource';
import { Colors } from '../shared/Theme';
import { useQueryPersistedState } from '../shared/useQueryPersistedState';
import { AddYourNetworkOverlay } from './AddYourNetworkOverlay';
import { INSIGHT_FILTERS, NetworkFilterDrawer } from './NetworkFilterDrawer';
import { EMPTY_SEARCH, isEmptySearch } from './NetworkFiltering';
import { NetworkOwnerSelect } from './NetworkOwnerSelect';
import { NetworkTable } from './NetworkTable';
import { NetworkTabs, NetworkTabsLoadingPlaceholder, TAB_LIMIT } from './NetworkTabs';
import { SearchHistoryDropdown } from './SearchHistoryDropdown';
import { compileSearchTerms, SearchTermLabels } from './SearchTitle';
import { ShareYourNetworkOverlay } from './ShareYourNetworkOverlay';
import { useNetworkFiltersState } from './useNetworkFiltersState';
import { MAX_NETWORK_PROFILE_COUNT, useNetworkProfiles } from './useNetworkProfiles';

const { SEQUOIA_WHITE, SEQUOIA_BLACK, SEQUOIA_PAPER, BLACK5ALPHA } = Colors.Static;

export const PageNetwork: React.FC = () => {
  const { me, all, id, team, roles } = useContext(OrganizationContext);
  const { network, networkOps, loadingMsg } = useContext(NetworkContext);
  const [profileId, setProfileId] = useQueryPersistedState<number | undefined>({
    encode: profileId => ({ profileId }),
    decode: qs => qs.profileId || undefined,
  });
  const [search, setSearch] = useNetworkFiltersState();

  const { profiles, profileOps, errored, loadingProfiles } = useNetworkProfiles(id, {
    id: search.id,
    data: JSON.stringify(search.data),
  });
  const { getRelatedProfileName } = useRelatedProfiles(profiles);

  const [filterDrawerOpen, setDrawerOpen] = useState(false);

  const isTeamUser = me.organizationId === id;
  const [showAddYourNetwork, setShowAddYourNetwork] = useState(false);
  const [showShareYourNetwork, setShowShareYourNetwork] = useState(
    isTeamUser && !me.settings.minerSheetsShared
  );

  useEffect(() => {
    if (isTeamUser && network && !network.owners.ids.length && !me.settings.minerSheetsShared) {
      setShowAddYourNetwork(true);
    }
    const myTabs = network?.myTabs || [];
    if (myTabs?.length && isEmptySearch(search)) {
      setSearch(myTabs[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTeamUser, network, me]);

  usePageTitle('Network', false, { all, id });

  useEffect(() => {
    logPageView(me, 'network');
  }, [me]);

  const { candidateDrawer, setDrawerCandidateId } = useCandidateDrawer({
    profiles,
    refreshProfiles: profileOps.refresh,
    getRelatedProfileName,
    applyLocalUpdates: profiles =>
      profileOps.applyLocalUpdates(profiles as NetworkAPI.NetworkProfile[]),
    onClose: () => setProfileId(undefined),
  });

  const checkAndDownloadConfig = useProfileTableConfig(profiles);
  const { checkedIds, checkCol, actions } = checkAndDownloadConfig;
  const bulkEmail = useBulkEmailModal(profiles, profileOps.refresh, checkedIds);

  const onSaveSearch = async (data: NetworkAPI.CreateSearchHistory['data'], isNew: boolean) => {
    if (network) {
      setSearch({ ...search, data });
      const hasMaxTabs = network.myTabs?.length === TAB_LIMIT;

      if (isNew) {
        const createSearch = { ...search, data };
        if (!search.isTab) createSearch.isTab = !hasMaxTabs;

        const { newSearch, myTabs }: NetworkAPI.PostNetworkSearchRes = await makeRequest(
          `/api/${id}/network-search`,
          'POST',
          createSearch
        );

        if (newSearch) {
          const update = { ...network };
          update.searchHistory.unshift(newSearch);
          if (myTabs) update.myTabs = myTabs;
          networkOps.applyLocalUpdates(update);
          setSearch(newSearch);
        }
      } else if (!hasMaxTabs && search.id) {
        await onUpdateTab({ isTab: true }, search.id);
      }
    }
  };

  useEffect(() => {
    if (profileId) setDrawerCandidateId(Number(profileId));
  }, [profileId, setDrawerCandidateId]);

  const onUpdateTab = async (update: NetworkAPI.UpdateSearchReq, searchId?: number) => {
    if (network) {
      if (!searchId) {
        return setSearch({ ...search, data: { ...search.data, ...update } });
      }

      const myTabs: NetworkAPI.NetworkRes['myTabs'] = await makeRequest(
        `/api/${id}/network-search/${searchId}`,
        'PUT',
        update
      );

      if (myTabs) {
        const updatedNetwork = { ...network };
        const searchHistoryItem = network.searchHistory.find(h => h.id === searchId);
        if ('isTab' in update) {
          if (searchHistoryItem) searchHistoryItem.isTab = false;
          updatedNetwork.myTabs = myTabs;
          networkOps.applyLocalUpdates(updatedNetwork);
          setSearch(myTabs[0]);
        } else {
          if (searchHistoryItem) Object.assign(searchHistoryItem.data, update);
          const updatedTab = network.myTabs.find(t => t.id === searchId);
          if (updatedTab) {
            Object.assign(updatedTab.data, update);
            setSearch(updatedTab);
            networkOps.applyLocalUpdates(updatedNetwork);
          }
        }
      }
    }
  };

  const onUpdateProfile = (update: Partial<NetworkAPI.NetworkProfile> & { id: number }) => {
    if (profiles && network) {
      const profileIdx = profiles.findIndex(p => p.id === update.id);
      if (profileIdx) {
        const updatedProfiles = [...profiles];
        Object.assign(updatedProfiles[profileIdx], update);
        profileOps.applyLocalUpdates(updatedProfiles);
      }
    }
  };

  const onMultiProfileUpdate = (updated: NetworkAPI.NetworkProfile[]) => {
    if (profiles && network) {
      const updatedProfiles = [...profiles];
      updated.forEach(p => {
        const profileIdx = profiles.findIndex(np => np.id === p.id);
        Object.assign(updatedProfiles[profileIdx], p);
      });
      profileOps.applyLocalUpdates(updatedProfiles);
    }
  };

  const searchTermLabels: SearchTermLabels = {
    insights: INSIGHT_FILTERS,
    owners: [me, ...team].filter(o => o.name && o.identifier),
    roles: roles.filter(r => !r.deletedAt),
  };

  const currFilterTerms = compileSearchTerms({ search: search.data, labels: searchTermLabels });

  return (
    <>
      {bulkEmail.modal}
      {showAddYourNetwork && (
        <AddYourNetworkOverlay onDismiss={() => setShowAddYourNetwork(false)} />
      )}
      {showShareYourNetwork && (
        <ShareYourNetworkOverlay onDismiss={() => setShowShareYourNetwork(false)} />
      )}
      {candidateDrawer}
      <PageContainer style={{ display: 'flex', flexDirection: 'column' }}>
        {network ? (
          <>
            <div style={{ display: 'flex', justifyContent: 'space-between', gap: 12 }}>
              <NetworkTabs
                myTabs={network.myTabs || []}
                search={search}
                currFilterTerms={currFilterTerms}
                searchTermLabels={searchTermLabels}
                onChange={setSearch}
                onDeleteTab={id => onUpdateTab({ isTab: false }, id)}
              />
              {!!network?.searchHistory?.length && (
                <SearchHistoryDropdown
                  searchTermLabels={searchTermLabels}
                  history={network.searchHistory || []}
                  onItemClicked={h => {
                    setSearch(h);
                    setDrawerOpen(true);
                  }}
                />
              )}
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                padding: 12,
                marginTop: -1,
                background: SEQUOIA_WHITE,
                borderTop: `1px solid ${SEQUOIA_BLACK}`,
                gap: 12,
              }}
            >
              <NetworkOwnerSelect
                value={search.data?.networkOwner}
                onChange={id => onUpdateTab({ networkOwner: id }, search.id)}
              />

              {isTeamUser &&
                me.externalProfileId &&
                network &&
                !network.owners.ids.includes(me.externalProfileId) && (
                  <SeqButton
                    intent="inverted"
                    size="small"
                    onClick={() => setShowAddYourNetwork(true)}
                  >
                    Add your Connections
                  </SeqButton>
                )}

              <SeqButton size="small" icon={<FilterIcon />} onClick={() => setDrawerOpen(true)}>
                {currFilterTerms.length > 0
                  ? `Edit Filters (${currFilterTerms.length})`
                  : `Filter by…`}
              </SeqButton>

              <DebouncedSearchInput
                style={{ background: SEQUOIA_PAPER }}
                size="small"
                outline="rounded"
                value={search.data?.searchTerm || ''}
                onChange={searchTerm => onUpdateTab({ searchTerm }, search.id)}
                placeholder={NETWORK_SEARCH_PLACEHOLDER}
              />

              <div
                style={{
                  fontWeight: 500,
                  color: BLACK5ALPHA,
                  whiteSpace: 'nowrap',
                  padding: '0 12px',
                }}
              >
                {profiles.length >= MAX_NETWORK_PROFILE_COUNT
                  ? `${MAX_NETWORK_PROFILE_COUNT}+ people`
                  : pluralize(profiles.length, 'person', 'people')}
              </div>
            </div>
          </>
        ) : (
          <NetworkTabsLoadingPlaceholder />
        )}
        {!!loadingProfiles && <LoaderLine style={{ bottom: -1 }} />}
        <TableContainer>
          <NetworkTable
            profiles={profiles}
            checkCol={checkCol}
            onUpdateProfile={onUpdateProfile}
            initSearch={search.data}
            onSaveSearch={onSaveSearch}
            onSearchWithoutFilters={() =>
              setSearch({ ...EMPTY_SEARCH, data: { searchTerm: search.data.searchTerm } })
            }
            setDrawerCandidateId={setDrawerCandidateId}
            getRelatedProfileName={getRelatedProfileName}
            applyLocalUpdates={(change: Partial<NetworkAPI.NetworkRes>) =>
              networkOps.applyLocalUpdates({ ...network, ...change } as NetworkAPI.NetworkRes)
            }
          />

          {errored ? (
            <NetworkTableOverlay>
              <h2>Uh-oh, something happened.</h2>
              <p>
                We weren't able to retrieve your team's 1st degree networks at this time. Please try
                reloading the page.
              </p>
            </NetworkTableOverlay>
          ) : loadingMsg ? (
            <NetworkTableOverlay>
              {loadingMsg}
              <LoadingOutlined spin={true} style={{ fontSize: 48 }} />
            </NetworkTableOverlay>
          ) : (
            !showShareYourNetwork &&
            !showAddYourNetwork &&
            network?.owners.ids.length === 0 && (
              <NetworkTableOverlay>
                {me.settings.minerSheetsShared ? (
                  <SeqButton intent="inverted" onClick={() => setShowAddYourNetwork(true)}>
                    Add your Network
                  </SeqButton>
                ) : (
                  <SeqButton intent="inverted" onClick={() => setShowShareYourNetwork(true)}>
                    Grant Access
                  </SeqButton>
                )}
              </NetworkTableOverlay>
            )
          )}
        </TableContainer>

        <SeqDrawer width={400} open={filterDrawerOpen} onClose={() => setDrawerOpen(false)}>
          {filterDrawerOpen && (
            <NetworkFilterDrawer
              initSearch={search.data || {}}
              searchTermLabels={searchTermLabels}
              onSearch={onSaveSearch}
              closeDrawer={() => setDrawerOpen(false)}
            />
          )}
        </SeqDrawer>

        <FooterRow>
          {bulkEmail.button}
          <MultiProfileUpdateButtonGroup checkedIds={checkedIds} onSaved={onMultiProfileUpdate} />
          <div style={{ flex: 1 }} />
          <SeqButton
            disabled={!checkedIds.length}
            onClick={() => actions.onDownload(`/api/${id}/profiles/csv?`, false)}
          >
            Export CSV ({checkedIds?.length || 0})
          </SeqButton>
        </FooterRow>
      </PageContainer>
    </>
  );
};

const NetworkTableOverlay = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: absolute;
  inset: 0;
  transition: filter 300ms linear;
  backdrop-filter: blur(4px);
  z-index: 2;
`;

const TableContainer = styled.div`
  ${FlexFillingColCSS}
  position: relative;
  width: 100%;
`;
