import { Link, useHistory } from 'react-router-dom';
import { SeqButton } from '../components/Button';
import { Dialog, SeqFullPageOverlay } from '../components/Common';
import { ExplanatoryText } from '../components/Text';
import { ClickableItem } from '../shared/Common';
import { Colors, hexWithOpacity } from '../shared/Theme';

const { SEQUOIA_DARK_GRAY } = Colors.Static;

export const AddYourNetworkOverlay: React.FC<{ onDismiss: () => void }> = ({ onDismiss }) => {
  const history = useHistory();

  return (
    <SeqFullPageOverlay width={510}>
      <Dialog
        header="Add your connections"
        content={
          <>
            <ExplanatoryText>
              To see your connections in the Network tab, download the{' '}
              <Link to="/onboarding/extension">Sequoia Chrome Extension</Link>, visit your own
              LinkedIn profile, activate the extension and choose{' '}
              <strong>"Create a Referral Sheet"</strong>.
            </ExplanatoryText>
            <ExplanatoryText style={{ marginBottom: 12 }}>
              The Chrome extension will import your LinkedIn connections. We'll automatically keep
              their data up-to-date and notify you of important events in your network.
            </ExplanatoryText>
          </>
        }
        footer={
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <ClickableItem
              onClick={onDismiss}
              style={{
                fontSize: 12,
                fontWeight: 500,
                color: hexWithOpacity(SEQUOIA_DARK_GRAY, 0.5),
              }}
            >
              Not now
            </ClickableItem>
            <SeqButton
              intent="primary"
              size="small"
              onClick={() => history.push('/onboarding/extension')}
              style={{ margin: 0 }}
            >
              Download Chrome Extension
            </SeqButton>
          </div>
        }
      />
    </SeqFullPageOverlay>
  );
};
