import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components/macro';
import { Tag, TagsContainer } from '../../components/Common';
import { CharInCircle } from '../../shared/Common';
import { ReferralsFilters, SchoolWorkInfo } from '../../shared/network-miner-filter-helpers';
import { trimCompanyName } from '../../shared/profile-helpers';

export const FilterTag: React.FC<{
  tagInfo: SchoolWorkInfo;
  workEduOverlap: SchoolWorkInfo[];
  setFilterValues: Dispatch<SetStateAction<ReferralsFilters>>;
}> = ({ tagInfo, workEduOverlap, setFilterValues }) => {
  const tagType = tagInfo.type;
  const tagSelected = workEduOverlap!.findIndex(
    o => tagInfo.type === o.type && tagInfo.name === o.name
  );
  const onClick = () => {
    const newWorkEduOverlap = [...workEduOverlap!];
    tagSelected !== -1 ? newWorkEduOverlap.splice(tagSelected, 1) : newWorkEduOverlap.push(tagInfo);
    setFilterValues(prev => ({ ...prev, workEduOverlap: newWorkEduOverlap }));
  };
  return (
    <Tag onClick={onClick} selected={tagSelected !== -1}>
      <img
        style={{ height: 14, opacity: 0.7 }}
        src={`/icons/${tagType === 'work' ? 'briefcase' : 'education'}.svg`}
        alt={`${tagType} icon`}
      />
      {trimCompanyName(tagInfo.name)}
      <CharInCircle char={tagInfo.ids.length} />
    </Tag>
  );
};

export const FilterTagsContainer = styled(TagsContainer)`
  align-items: center;
  margin: 10px 0;
  overflow: scroll;
  flex-shrink: 0;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;
