import { Table } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { SortOrder } from 'antd/lib/table/interface';
import { ReactNode } from 'react';
import styled from 'styled-components/macro';
import { Colors } from '../shared/Theme';
import { DisplayOnHover } from './Common';
import { SortIndicator } from './FastTable';

const { SEQUOIA_BLACK, SEQUOIA_LIGHT_GRAY, SEQUOIA_DARK_GRAY, SEQUOIA_PAPER } = Colors.Static;

export function customSortableColumn<T>(name: string, icon?: ReactNode) {
  return (inp?: {
    sortColumns?: {
      column: ColumnType<T>;
      order: SortOrder;
    }[];
  }) => {
    return (
      <div style={{ display: 'flex', userSelect: 'none', alignItems: 'center' }}>
        {icon || name}
        <SortIndicator
          hasIcon={!!icon}
          sortOrder={
            inp?.sortColumns?.find(
              s => s.column.key?.toString().toLowerCase() === name.toLowerCase()
            )?.order
          }
        />
      </div>
    );
  };
}

export const SeqTable = styled(Table)`
  & .ant-table-tbody td {
    vertical-align: center;
  }
  & .ant-table-tbody > tr.ant-table-row-level-0:hover > td {
    &:hover ${DisplayOnHover} {
      display: initial;
    }
  }
  & .ant-table {
    background: transparent;
  }
  & .ant-table-tbody > tr.ant-table-row-level-0:hover:not(.hoverable-rows) > td {
    background: transparent;
  }
  .ant-table-thead tr th.ant-table-column-has-sorters .ant-table-filter-column {
    margin-left: 0;
    margin-right: 0;
  }
  .ant-table-thead > tr > th {
    background: ${SEQUOIA_PAPER};
    border-top: 1px solid ${SEQUOIA_LIGHT_GRAY};
    height: 56px;
    & .ant-table-column-sorter {
      display: none;
    }
    & .ant-table-column-sorters > span {
    }
    font-size: 12px;
    text-transform: uppercase;
    color: ${SEQUOIA_DARK_GRAY};
  }
  .ant-table-filter-column-title {
    padding: 16px 0px 16px 0px;
  }
  & td.ant-table-column-sort {
    background: unset;
  }
  th.ant-table-column-sort {
    background: ${SEQUOIA_LIGHT_GRAY};
  }
  .top-align-rows {
    vertical-align: top;
  }
  font-family: Gotham;
  font-size: 14px;
  color: ${SEQUOIA_BLACK};
` as typeof Table;
