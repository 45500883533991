import { message } from 'antd';
import qs from 'query-string';
import React from 'react';
import { delay } from './helpers';

// Wrapper around window.fetch for making API requests
//
export async function makeRequest<T>(
  path: string,
  method = 'GET',
  itemPayload: any = null
): Promise<T> {
  const options: any = {
    credentials: 'include',
    method,
  };

  if (itemPayload) {
    options.body = JSON.stringify(itemPayload);
    options.headers = {
      'content-type': 'application/json',
    };
  }

  let response: Response | null = null;
  try {
    response = await fetch(path, options);
  } catch (err) {
    return { error: `${err}` } as any; // Usually a CORS issue which causes immediate failure
  }

  // Temporary: Retry 502 responses just once -- they are spurious errors at the
  // load balancer layer and we haven't been able to fix them yet.
  if (response.status === 502) {
    await delay(1000);
    try {
      response = await fetch(path, options);
    } catch (err) {
      return { error: `${err}` } as any; // Usually a CORS issue which causes immediate failure
    }
  }

  const text = await response.text();
  let json = { error: `${response.status} ${response.statusText}` };

  try {
    json = JSON.parse(text);
  } catch (err) {
    console.error(`${method} ${path} returned invalid JSON: ${text}`);
  }

  if (response.status === 401) {
    window.location.href =
      (process.env.NODE_ENV === 'production'
        ? process.env.REACT_APP_UNAUTHORIZED_REDIRECT_PROD
        : process.env.REACT_APP_UNAUTHORIZED_REDIRECT_DEV) ||
      `/talent/access-denied?next=${encodeURIComponent(window.location.href)}&message=${
        json.error
      }`;
    throw new Error('Redirecting to login');
  }

  if (response.status === 403 && process.env.REACT_APP_FORBIDDEN_REDIRECT) {
    window.location.href = process.env.REACT_APP_FORBIDDEN_REDIRECT;
    throw new Error('Hit unauthroized endpoint, redirecting to lost');
  }

  return json as any;
}

interface ResourceConfig {
  silent?: boolean;
  GETRequestMethod?: 'GET' | 'POST';
  queryStringifyOptions?: qs.StringifyOptions;
}

export interface ResourceOps<T, U = Partial<T>> {
  post: (v: U, opts?: { silent?: boolean }) => Promise<unknown>;
  put: (v: U, opts?: { silent?: boolean }) => Promise<unknown>;
  putItem: (item: { id: string | number } & U) => Promise<unknown>;
  delete: () => Promise<void>;
  deleteItem: (item: string | number | { id: string | number }) => Promise<void>;
  applyLocalUpdates: (v: T) => void;
  refresh: () => Promise<T>;
  refreshItem: (item: { id: string | number } & U) => Promise<unknown>;
}

export function useResource<T, U = Partial<T>>(
  path: string,
  query?: { [key: string]: any },
  config?: ResourceConfig
) {
  const { silent = false, GETRequestMethod = 'GET', queryStringifyOptions } = config || {};
  const [state, setState] = React.useState<{ value: T; url: string } | undefined>(undefined);

  const queryStr = JSON.stringify(query);
  const url = `${path}${
    query
      ? path.includes('?')
        ? `&${qs.stringify(query)}`
        : `?${qs.stringify(query, queryStringifyOptions || {})}`
      : ''
  }`;
  const urlRef = React.useRef(url);
  urlRef.current = url;

  const getValue = React.useCallback(async () => {
    return await makeRequest<T>(
      GETRequestMethod === 'POST' ? path : url,
      GETRequestMethod,
      GETRequestMethod === 'POST' ? JSON.parse(queryStr) : undefined
    );
  }, [url, path, queryStr, GETRequestMethod]);

  React.useEffect(() => {
    const fetch = async () => {
      const value = await getValue();
      // The URL passed to the useResource may have changed while we were fetching the
      // data, and we don't want to apply data for an OLD url!
      if (urlRef.current === url) {
        setState({ url, value });
      }
    };
    setState(undefined);
    if (path) void fetch();
  }, [path, url, getValue]);

  const ops = React.useMemo(() => {
    const setNextValue = ({
      lastState,
      newItem,
    }: {
      lastState?: { value: T; url: string };
      newItem: any;
    }) => {
      if (lastState && lastState.value instanceof Array) {
        const nextValue: any = [];
        for (const lastStateItem of lastState.value) {
          nextValue.push(lastStateItem.id === newItem.id ? newItem : lastStateItem);
        }
        return { ...lastState, value: nextValue };
      }
      console.warn('useResource: PUT update skipped, response does not look like array item');
      return state;
    };

    const result: ResourceOps<T, U> = {
      post: async (v: U) => {
        try {
          const resp = await makeRequest<U>(path, 'POST', v);

          setState(lastState => {
            if (
              resp &&
              typeof resp === 'object' &&
              'id' in resp &&
              lastState &&
              lastState.value instanceof Array
            ) {
              return { ...lastState, value: [...lastState.value, resp] as any };
            }
            console.warn('useResource: POST update skipped, no id or state.value is not an array');
            return lastState;
          });

          if (
            resp &&
            typeof resp === 'object' &&
            'error' in resp &&
            typeof (resp as U & { error: string }).error === 'string'
          ) {
            throw Error((resp as U & { error: string }).error);
          } else {
            !silent && void message.success('Item created');
          }
          return resp;
        } catch (err) {
          !silent &&
            void message.error('message' in err ? err.message : 'Failed to save, try again.');
          throw err;
        }
      },
      put: async (v: U, opts?: { silent?: boolean }) => {
        try {
          const resp = await makeRequest<T>(path, 'PUT', v);
          setState(lastState => {
            if (lastState) {
              if (lastState.value instanceof Array) {
                if (resp instanceof Array) {
                  return { ...lastState, value: resp };
                }
              } else if (resp && typeof resp === 'object' && 'id' in resp) {
                return { ...lastState, value: Object.assign({}, lastState.value, resp) };
              }

              console.warn('useResource: PUT update skipped, response not a model or array');
              return lastState;
            }
          });
          if (
            resp &&
            typeof resp === 'object' &&
            'error' in resp &&
            typeof (resp as T & { error: string }).error === 'string'
          ) {
            throw Error((resp as T & { error: string }).error);
          } else {
            !silent && !opts?.silent && void message.success('Changes saved');
          }
          return resp;
        } catch (err) {
          !silent &&
            void message.error('message' in err ? err.message : 'Failed to save, try again.');
          throw err;
        }
      },
      putItem: async (item: { id: string | number } & U) => {
        setState(lastState => setNextValue({ lastState, newItem: item }));

        const resp = await makeRequest<any>(`${path}/${item.id}`, 'PUT', item);

        if (resp && typeof resp === 'object' && 'id' in resp) {
          setState(lastState => setNextValue({ lastState, newItem: { ...item, ...resp } }));
        }
        if ('error' in resp && typeof (resp as T & { error: string }).error === 'string') {
          !silent && void message.error((resp as T & { error: string }).error);
        } else {
          !silent && void message.success('Updated successfully');
        }
        return resp;
      },
      delete: async () => {
        await makeRequest<T>(path, 'DELETE');
        !silent && void message.success('Deleted successfully');
      },
      deleteItem: async (item: string | number | { id: string | number }) => {
        const itemId = typeof item === 'object' && 'id' in item ? item.id : item;
        await makeRequest<T>(`${path}/${itemId}`, 'DELETE');
        !silent && void message.success('Deleted successfully');

        setState(lastState => {
          if (lastState && lastState.value instanceof Array) {
            return { ...lastState, value: lastState.value.filter(i => i.id !== itemId) as any };
          }
          console.warn('useResource: DELETE update skipped, local state is not an array of items');
          return lastState;
        });
      },
      applyLocalUpdates: (v: T) => {
        setState({ url, value: v });
      },
      refresh: async () => {
        const value = await getValue();
        setState({ url, value });
        return value;
      },
      refreshItem: async item => {
        const resp = await makeRequest<any>(`${path}/${item.id}`);
        if (resp && typeof resp === 'object' && 'id' in resp) {
          setState(lastState => setNextValue({ lastState, newItem: { ...item, ...resp } }));
        }
      },
    };
    return result;
    // eslint-disable-next-line
  }, [setState, silent, getValue, path, url]);

  // Explicitly tell TS this is a tuple of two distinct types, not an array of (T | typeof Ops)
  if (state?.url === url) {
    return [state.value, ops] as [T | undefined, ResourceOps<T, U>];
  } else {
    return [undefined, ops] as [T | undefined, ResourceOps<T, U>];
  }
}
