import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Popconfirm } from 'antd';
import React from 'react';
import { ClickableItem } from '../shared/Common';
import { EXTERNAL_RECIPIENT_VARIABLES, RECIPIENT_PLACEHOLDER } from '../shared/helpers';
import { TrashIcon } from '../shared/Svgs';
import { Colors } from '../shared/Theme';
import { ActionButton, SeqButton } from './Button';
import { ListSubheader } from './Common';
import { ExternalTemplateDelayConfiguration } from './ExternalTemplateDelayConfiguration';
import { ExternalTemplateEditor } from './ExternalTemplateEditor';
import { CursorBodyState, TemplateContext } from './ExternalTemplateSupport';
import { IconContainer } from './Svgs';
import { TemplateOverrideFromModal } from './TemplateOverrideFromModal';
import { TemplateSelect, templateTitle } from './TemplateSelect';
import { Body3 } from './Text';

const { BLACK, BLACK5ALPHA, DESTRUCTIVE_TINT } = Colors.Static;

const DEFAULT_DELAY: CampaignAPI.TimeDelay = {
  value: 10,
  unit: 'days',
};

export const MIN_HEIGHT_ADD_FOLLOWUP = 100;

export interface CampaignMetaInfo {
  name: string;
  settings: CampaignAPI.CampaignSettings;
}

export const TemplateArea: React.FunctionComponent<{
  campaign: CampaignAPI.CampaignSummary | CampaignAPI.Campaign;
  sender: ExternalAPI.User;
  onChange: (updates: Partial<CampaignAPI.Campaign>) => void;
}> = ({ campaign, sender, onChange }) => {
  const templates = campaign.templates;
  const isStarted = 'startDate' in campaign ? !!campaign.startDate : false;
  const [changingFrom, setChangingFrom] = React.useState<number | undefined>();
  const fromDefault = campaign.settings.sendAs || campaign.sender?.email || '';

  const onChangeTemplate = (idx: number, change: Partial<CampaignAPI.TemplateInfo>) => {
    const templatesDeep = [...templates];
    templatesDeep.splice(idx, 1, { ...templates[idx], ...change });
    onChange({ templates: templatesDeep });
  };

  return (
    <TemplateContext.Provider
      value={{
        quillScrollingContainer: '.scrollable-content',
        variables: { ...EXTERNAL_RECIPIENT_VARIABLES, ...(sender.templateVariables || {}) },
        recipient: RECIPIENT_PLACEHOLDER,
      }}
    >
      <TemplateOverrideFromModal
        visible={!!changingFrom}
        fromDefault={fromDefault}
        fromUserWithEmail={changingFrom ? templates[changingFrom]?.fromUserWithEmail : undefined}
        onChange={fromUserWithEmail => onChangeTemplate(changingFrom!, { fromUserWithEmail })}
        onReset={() => {
          onChangeTemplate(changingFrom!, { fromUserWithEmail: undefined });
          setChangingFrom(undefined);
        }}
        onClose={() => {
          if (changingFrom) {
            const fromEmail = templates[changingFrom]?.fromUserWithEmail;
            const cc = campaign.settings.cc || [];
            if (fromEmail && fromEmail !== fromDefault && !cc.includes(fromEmail)) {
              onChange({ settings: { ...campaign.settings, cc: [...cc, fromEmail] } });
            }
            setChangingFrom(undefined);
          }
        }}
      />

      <div style={{ display: 'flex', flexDirection: 'column', marginTop: 20 }}>
        {templates.map((template, idx) => (
          <React.Fragment key={idx}>
            {idx > 0 && (
              <ExternalTemplateDelayConfiguration
                disabled={!template}
                delay={template?.delay || DEFAULT_DELAY}
                onSetDelay={(delay: CampaignAPI.TimeDelay) => {
                  if (template) {
                    onChangeTemplate(idx, { delay });
                  }
                }}
              />
            )}
            <TemplatePanel
              key={`${campaign.id}-template-${idx}`}
              title={templateTitle(idx)}
              sender={sender}
              campaignInfo={campaign}
              template={template}
              onChange={updated => onChangeTemplate(idx, updated)}
              onDelete={
                idx === 0 || isStarted
                  ? undefined
                  : () => {
                      onChange({ templates: templates.filter(t => t !== template) });
                    }
              }
            />
            {idx > 0 && (
              <div>
                <Body3
                  style={
                    template.fromUserWithEmail
                      ? { color: BLACK, fontWeight: 700 }
                      : { color: BLACK5ALPHA }
                  }
                >
                  Sent from: {template.fromUserWithEmail || fromDefault}
                  <ActionButton
                    onClick={() => setChangingFrom(idx)}
                    style={{ wordSpacing: '-0.5em' }}
                  >
                    Change
                  </ActionButton>
                </Body3>
              </div>
            )}
          </React.Fragment>
        ))}
        <EmptyTemplatePanel
          disabledMsg={
            isStarted
              ? 'This campaign has already started. Duplicate it to add emails to the flow.'
              : templates.length === 10
                ? 'You can only have 10 emails in a campaign.'
                : ''
          }
          count={templates.length}
          onAddTemplateClick={() => {
            onChange({ templates: [...templates, { ...EMPTY_TEMPLATE, delay: DEFAULT_DELAY }] });
          }}
        />
      </div>
    </TemplateContext.Provider>
  );
};

const EmptyTemplatePanel: React.FunctionComponent<{
  disabledMsg: string;
  count: number;
  onAddTemplateClick: () => void;
}> = ({ disabledMsg, count, onAddTemplateClick }) => {
  const next = ['', 'A', 'Second', 'Third', 'Fourth', 'Fifth', 'Sixth'][count] || 'Another';
  return (
    <div
      style={{
        minHeight: MIN_HEIGHT_ADD_FOLLOWUP,
        marginTop: 30,
        marginBottom: 30,
        display: 'flex',
        justifyContent: 'center',
        width: 'auto',
      }}
    >
      <div>
        <SeqButton
          style={{ margin: '0 auto', display: 'block' }}
          disabled={!!disabledMsg}
          onClick={onAddTemplateClick}
        >
          <PlusOutlined style={{ marginRight: 4 }} /> Add {next} Followup
        </SeqButton>
        {!!disabledMsg && <div style={{ marginTop: 10 }}>{disabledMsg}</div>}
      </div>
    </div>
  );
};

const TemplatePanel: React.FunctionComponent<{
  title: string;
  template: CampaignAPI.TemplateInfo;
  campaignInfo: CampaignMetaInfo;
  sender: ExternalAPI.User;
  onChange: (template: CampaignAPI.TemplateInfo) => void;
  onDelete?: () => void;
}> = ({ title, sender, campaignInfo, template, onChange, onDelete }) => {
  const [cursorBodyState, setCursorBodyState] = React.useState<CursorBodyState>({
    text: template.body,
  });

  React.useEffect(() => {
    onChange({ ...template, body: cursorBodyState.text });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cursorBodyState.text]);

  const titleBlock = (
    <ListSubheader style={{ alignItems: 'center', width: 'auto' }}>
      {title}
      <div style={{ flex: 1 }} />
      <TemplateSelect
        onTemplateSelected={(selectedTemplate: CampaignAPI.TemplateInfo) => {
          setCursorBodyState({ ...cursorBodyState, text: selectedTemplate.body });
          onChange({ ...selectedTemplate, id: '' });
        }}
      />
      {onDelete && (
        <Popconfirm
          icon={<DeleteOutlined style={{ color: DESTRUCTIVE_TINT }} />}
          placement={'topRight'}
          okText={'Remove'}
          okButtonProps={{
            style: { background: DESTRUCTIVE_TINT, borderColor: DESTRUCTIVE_TINT },
          }}
          overlayStyle={{ maxWidth: 300 }}
          title={'Are you sure you want to remove this template from the campaign?'}
          onConfirm={() => {
            onDelete();
          }}
        >
          <ClickableItem>
            <IconContainer style={{ width: 19 }}>
              <TrashIcon />
            </IconContainer>
          </ClickableItem>
        </Popconfirm>
      )}
    </ListSubheader>
  );

  return (
    <div style={{ display: 'relative' }}>
      {titleBlock}
      <div style={{ marginTop: 12 }}>
        <ExternalTemplateEditor
          value={cursorBodyState}
          setValue={setCursorBodyState}
          sender={sender}
          config={{ subject: campaignInfo.settings.subject }}
        />
      </div>
    </div>
  );
};

const EMPTY_TEMPLATE: CampaignAPI.TemplateInfo = {
  id: '',
  body: '',
  requiredVariables: [],
  createdAt: new Date(),
  updatedAt: new Date(),
  delay: { value: 0, unit: 'days' },
};
