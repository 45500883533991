import { LoadingOutlined } from '@ant-design/icons';
import { notification } from 'antd';
import { groupBy, omit } from 'lodash';
import { default as React, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { logPageView } from '../Helpers';
import { IconButton, PlusButton } from '../components/Button';
import { Column, Empty, LightTooltip, ListSubheader, PageContainer } from '../components/Common';
import { Body1Serif, H2Mono } from '../components/Text';
import { OrganizationContext } from '../contexts/Organization';
import { AddCandidatesPopover } from '../pipeline/AddCandidatesPopover';
import { usePageTitle } from '../shared/Common';
import { FriendlyErrorBoundary } from '../shared/FriendlyErrorBoundary';
import { makeRequest, useResource } from '../shared/Resource';
import { ScrollingContainer } from '../shared/ScrollableContainer';
import { PieChartIcon } from '../shared/Svgs';
import { Colors } from '../shared/Theme';
import { useQueryPersistedState } from '../shared/useQueryPersistedState';
import { LeadListCandidatesSection } from './LeadListCandidatesSection';
import { LeadListHeader } from './LeadListHeader';
import { LeadListStatsModal } from './LeadListStatsModal';
import { LeadListTitle, LeadListsSection } from './LeadListsSection';

export type CandidateWithDetails = LeadsAPI.Candidate & {
  profile: ExternalAPI.Profile;
};

export type LeadListWithReviewStatus = LeadsAPI.LeadList & {
  status: 'drafts' | 'reviewed' | 'reviewing';
};

type LeadListUpdate =
  | (Omit<LeadsAPI.UpdateLeadList | LeadsAPI.ReviewUpdate | LeadsAPI.BulkReview, 'id'> & {
      id?: number;
    })
  | LeadsAPI.CampaignUpdate;

export const LEAD_LIST_NAME_DATE_FORMAT = 'MMM D, YYYY';

function reviewStatusForLeadList(l: LeadsAPI.LeadList) {
  if (l.completedAt) return 'reviewed';
  if (l.readyForReviewAt) return 'reviewing';
  return 'drafts';
}

export const PageLeads: React.FunctionComponent = () => {
  const { me, all, id, roles } = useContext(OrganizationContext);
  const [leadLists, { applyLocalUpdates, refresh }] = useResource<LeadsAPI.LeadList[]>(
    `/api/${id}/lead-lists`
  );
  const leadListsGrouped = groupBy(leadLists || [], reviewStatusForLeadList);
  const leadListsByReviewStatus = {
    drafts: leadListsGrouped['drafts'] || [],
    reviewing: leadListsGrouped['reviewing'] || [],
    reviewed: leadListsGrouped['reviewed'] || [],
  };

  const [statsModalInfo, setStatsModalInfo] = useState<{
    visible: boolean;
    leadList?: LeadsAPI.LeadList;
  }>({
    visible: false,
  });
  const [currLeadList, setList] = useState<LeadListWithReviewStatus>();
  const [selectedLeadList, setSelectedLeadList] = useQueryPersistedState<number | undefined>({
    encode: listId => ({ listId }),
    decode: qs => (qs.listId ? Number(qs.listId) : undefined),
  });

  usePageTitle('Leads', false, { all, id });

  useEffect(() => {
    logPageView(me, 'leads');
  }, [me]);

  useEffect(() => {
    if (leadLists && leadLists.length && !currLeadList) {
      if (selectedLeadList && leadLists.some(l => l.id === selectedLeadList)) {
        const newLeadList = leadLists.find(l => l.id === selectedLeadList)!;
        setList({ ...newLeadList, status: reviewStatusForLeadList(newLeadList) });
      } else {
        setList({ ...leadLists[0], status: reviewStatusForLeadList(leadLists[0]) });
      }
    }
  }, [leadLists, currLeadList, selectedLeadList]);

  useEffect(() => {
    if (currLeadList && (!selectedLeadList || selectedLeadList !== currLeadList.id)) {
      setSelectedLeadList(currLeadList?.id);
    }
  }, [currLeadList, selectedLeadList, setSelectedLeadList]);

  const updateList = async (update: LeadListUpdate) => {
    if (!leadLists) return;

    const leadList = await updateLeadList(id, update, currLeadList);

    if (!leadList) return;

    if ('reviewedProfile' in update && leadList.status === 409) {
      notification.error({ message: 'Candidate was already contacted. Cannot reject.' });
    } else if (leadList) {
      if ('readyForReviewAt' in leadList && !!leadList.readyForReviewAt) {
        refresh();
        setList({ ...leadList, status: 'reviewing' });
      } else if ('origin' in update) {
        refresh();
        setList({ ...leadList, status: 'drafts' });
      } else {
        applyLocalUpdates(leadLists.map(l => (l.id === leadList.id ? leadList : l)));
        setList(prev =>
          'id' in update
            ? { ...leadList, status: 'drafts' }
            : { ...prev!, ...omit(leadList, ['status']) }
        );
      }
    }
  };

  const deleteList = async () => {
    if (!!leadLists?.length && currLeadList && !currLeadList.profiles.some(p => !!p.review)) {
      const res = await makeRequest<{ message: string }>(
        `/api/${id}/lead-list/${currLeadList.id}`,
        'DELETE'
      );

      if (res.message) {
        applyLocalUpdates(leadLists.filter(l => l.id !== currLeadList.id));
        setList(undefined);
      }
    }
  };

  return (
    <PageContainer style={{ flexDirection: 'row', paddingRight: 0 }}>
      <Column>
        <Header>
          <HeaderRow>
            <H2Mono>Lead Lists</H2Mono>
            <div style={{ display: 'flex', gap: 4 }}>
              <LightTooltip overlay="Stats" placement="bottom">
                <IconButton onClick={() => setStatsModalInfo({ visible: true })}>
                  <PieChartIcon />
                </IconButton>
              </LightTooltip>
              <AddCandidatesPopover config={{ addToLeadList: updateList }}>
                <PlusButton />
              </AddCandidatesPopover>
            </div>
          </HeaderRow>
          <Body1Serif>Create or review lists of potential candidates.</Body1Serif>
        </Header>
        <ScrollingContainer>
          <InProgressNotice onRequestListRefresh={refresh} />
          <FriendlyErrorBoundary description="lead lists">
            <LeadListsSection
              leadListsByReviewStatus={leadListsByReviewStatus}
              currLeadList={currLeadList}
              setList={setList}
              roles={roles}
            />
          </FriendlyErrorBoundary>
        </ScrollingContainer>
      </Column>
      <div
        style={{
          width: 1,
          borderRight: `1px solid ${Colors.Static.SEQUOIA_BLACK}`,
          margin: '12px 24px 12px 0',
        }}
      />
      <Column style={{ flex: 2 }}>
        {currLeadList ? (
          <FriendlyErrorBoundary description="leads">
            <LeadListHeader
              leadList={currLeadList}
              actions={{
                updateList,
                deleteList: currLeadList.profiles.some(p => p.review) ? undefined : deleteList,
                onClickStats: () => {
                  setStatsModalInfo({ visible: true, leadList: currLeadList });
                },
              }}
            />
            <LeadListCandidatesSection leadList={currLeadList} onUpdate={updateList} />
          </FriendlyErrorBoundary>
        ) : (
          <div style={{ maxWidth: 600, margin: 'auto' }}>
            {leadLists && leadLists.length === 0 ? (
              <Empty description="Collect candidates for yourself or another person on the team to review. To get started, open the Founder Chrome extension while on a LinkedIn profile." />
            ) : (
              <LoadingOutlined style={{ marginTop: 20, marginLeft: 10, fontSize: 40 }} spin />
            )}
          </div>
        )}
      </Column>
      <LeadListStatsModal
        onClose={() => setStatsModalInfo({ visible: false })}
        {...statsModalInfo}
      />
    </PageContainer>
  );
};

export async function updateLeadList<T extends LeadsAPI.LeadList>(
  orgId: number,
  update: LeadListUpdate,
  currLeadList?: T
) {
  if (currLeadList || 'origin' in update || 'id' in update) {
    let leadList: LeadsAPI.LeadList & { status: Number };

    if ('campaignType' in update && currLeadList) {
      leadList = await makeRequest(
        `/api/${orgId}/lead-list/${currLeadList.id}/campaign-update`,
        'PUT',
        update
      );
    } else {
      leadList = await makeRequest(
        `/api/${orgId}/lead-lists`,
        'POST',
        'origin' in update
          ? (update as LeadsAPI.UpsertLeadList)
          : ({ ...update, id: 'id' in update ? update.id : currLeadList?.id } as
              | LeadsAPI.UpdateLeadList
              | LeadsAPI.ReviewUpdate
              | LeadsAPI.BulkReview)
      );
    }

    return leadList;
  }
}

const Header = styled.div`
  margin-right: 24px;
  margin-bottom: 12px;
`;

const HeaderRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const InProgressNotice: React.FunctionComponent<{ onRequestListRefresh: () => void }> = ({
  onRequestListRefresh,
}) => {
  const { id: orgId, roles } = useContext(OrganizationContext);
  const [models = [], { refresh }] = useResource<
    SourcingEngineAPI.EngineModel[],
    SourcingEngineAPI.EngineModel
  >(`/api/sourcing/${orgId}/engine-models`, undefined, { silent: true });

  const rolesWithModelStillLoading = models
    .filter(m => m.requestedReEvalAt)
    .map(m => m.target)
    .map(t => t?.id || 0)
    .filter(Boolean);

  useEffect(() => {
    onRequestListRefresh();
  }, [rolesWithModelStillLoading.length, onRequestListRefresh]);

  useEffect(() => {
    const intervalCall = setInterval(() => {
      refresh();
    }, 10 * 1000);
    return () => {
      // clean up
      clearTimeout(intervalCall);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const rolesCalculating = roles.filter(r => rolesWithModelStillLoading.includes(r.id));
  if (!rolesCalculating.length) {
    return <></>;
  }

  return (
    <div>
      <ListSubheader>Pending</ListSubheader>
      {rolesCalculating.map((r, idx) => {
        return (
          <div
            key={`${idx}-pending-subhead`}
            style={{ display: 'flex', alignItems: 'center', padding: 10 }}
          >
            <div>
              <img style={{ width: 42 }} src="/icons/auto-sourcing.svg" alt="wand" />
            </div>
            <div>
              <LeadListTitle style={{ paddingLeft: 12, color: Colors.Static.BLACK5ALPHA }}>
                Building a new candidate list for{' '}
                <Link
                  style={{ color: 'unset' }}
                  to={`/${r.organizationId}/settings?tab=Candidate%20Filters&roleId=${r.id}`}
                >
                  <b style={{ marginLeft: 4 }}>{r.name}</b>
                </Link>
              </LeadListTitle>
              <div style={{ paddingLeft: 12, color: Colors.Static.BLACK5ALPHA, fontSize: 12 }}>
                Available within 5 minutes
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
