import { Menu, notification } from 'antd';
import * as chronos from 'chrono-node';
import { capitalize } from 'lodash';
import moment, { Moment } from 'moment';
import React, { useCallback, useState } from 'react';
import { ClickAcceptOnCandidateEvent } from '../events';
import { isUneditedDefaultCampaign } from '../hooks/useCampaignConfig';
import { CampaignSummaryWithSender } from '../hooks/useVisibleCampaigns';
import { ClickableItem } from '../shared/Common';
import { MAX_HOUR_FOLLOWUP, MIN_HOUR_FOLLOWUP } from '../shared/helpers';
import { Colors } from '../shared/Theme';
import { SeqDropdownButton } from './Button';
import { DisplayOnHover, HorizontalDivider, HoverableDiv, LightTooltip } from './Common';
import { InputProps, SeqInput } from './Input';
import { ScheduledSendIcon } from './Svgs';
import { H4Mono } from './Text';

const { BLACK3ALPHA, BLACK5ALPHA, SEQUOIA_BRIGHT_RED } = Colors.Static;
export const DATE_FORMAT = 'ddd, MMM D [at] LT';
const USER_TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;
const LAST_SCHEDULED = 'lastScheduled';

export interface ScheduledDate {
  name: string;
  date?: moment.Moment;
}

export const ScheduledSendButton: React.FC<{
  onSend: (deliverAt: Date) => void;
  extraButtonText?: string;
  campaign?: CampaignSummaryWithSender;
  disabled?: boolean | string;
  style?: React.CSSProperties;
  defaultToLastUsed?: true;
}> = ({ campaign, onSend, extraButtonText, disabled = false, defaultToLastUsed }) => {
  const [dropdownVisible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const menuItems: { name: string; date: moment.Moment; clearStorageIfSelected?: boolean }[] = [];

  const now = moment();
  const currDay = now.day();

  const lastScheduledRawString = window.localStorage.getItem(LAST_SCHEDULED);
  const lastScheduledMoment: Moment | undefined =
    lastScheduledRawString && moment(lastScheduledRawString).unix() > now.unix()
      ? moment(lastScheduledRawString)
      : undefined;

  const weekdaysOnly = campaign?.settings.weekdaysOnly || false;
  const { deliverAt, message, rawTimeDesc } = getPreferredSendingTime(weekdaysOnly);
  const defaultDeliverAt = defaultToLastUsed
    ? lastScheduledMoment?.toDate() || deliverAt
    : deliverAt;

  lastScheduledMoment &&
    menuItems.push(
      defaultToLastUsed
        ? { name: capitalize(rawTimeDesc), date: moment(deliverAt), clearStorageIfSelected: true }
        : {
            name: 'Last Scheduled',
            date: lastScheduledMoment,
          }
    );

  isSendableDay(currDay + 1, weekdaysOnly) &&
    menuItems.push({
      name: 'Tomorrow Morning',
      date: moment().startOf('day').add(8, 'hour').add(1, 'day'),
    });

  currDay > 1 &&
    menuItems.push({
      name: 'Monday Morning',
      date: now.day('Monday').startOf('day').add(8, 'hours').add(7, 'day'),
    });

  const sendAndClose = useCallback(
    (date: Date) => {
      setLoading(true);
      if (isUneditedDefaultCampaign(campaign)) {
        notification.warning({
          message:
            'You are sending a default campaign that has not been customized for your organization.',
        });
      }
      onSend(date);
      setVisible(false);
      setLoading(false);
    },
    [campaign, onSend]
  );

  React.useEffect(() => {
    const onAcceptEvent = async () => {
      defaultDeliverAt && sendAndClose(defaultDeliverAt);
    };
    window.addEventListener(ClickAcceptOnCandidateEvent.EventKey, onAcceptEvent as any);
    return () => {
      window.removeEventListener(ClickAcceptOnCandidateEvent.EventKey, onAcceptEvent as any);
    };
  }, [onSend, defaultDeliverAt, sendAndClose]);

  const menu = (
    <Menu style={{ width: 350 }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <H4Mono style={{ flex: 1, margin: '16px 24px' }}>Send Later</H4Mono>
        <div style={{ paddingRight: 38, fontSize: 12, color: BLACK5ALPHA }}>{USER_TIMEZONE}</div>
      </div>
      <HorizontalDivider />
      {menuItems.map((m, idx) => (
        <Menu.Item
          style={{
            borderBottom: `1px solid ${BLACK3ALPHA}`,
            padding: '12px 12px 12px 24px',
            fontSize: 12,
            display: 'flex',
            cursor: 'default',
          }}
          key={idx}
        >
          <HoverableDiv style={{ display: 'flex', alignItems: 'center', flex: 1 }}>
            <div style={{ fontWeight: 500, flex: 1 }}>{m.name}</div>
            {m.date.format(DATE_FORMAT)}
            <DisplayOnHover>
              <ClickableItem
                onClick={() => {
                  if (m.clearStorageIfSelected) {
                    window.localStorage.removeItem(LAST_SCHEDULED);
                  } else {
                    window.localStorage.setItem(LAST_SCHEDULED, m.date.toISOString());
                  }
                  sendAndClose(m.date.toDate());
                }}
                style={{ marginBottom: 4 }}
              >
                <ScheduledSendIcon style={{ marginLeft: 12, height: 12 }} />
              </ClickableItem>
            </DisplayOnHover>
          </HoverableDiv>
        </Menu.Item>
      ))}
      <ScheduledDateInput
        onSend={date => {
          sendAndClose(date.toDate());
          window.localStorage.setItem(LAST_SCHEDULED, date.toISOString());
        }}
        onDidChange={(setMessage, date) => {
          if (date) {
            if (!isSendableDay(date.day(), weekdaysOnly)) {
              setMessage('This campaign only sends on weekdays. Please enter a weekday.');
            } else if (!isSendableTime(date)) {
              setMessage('Please enter a future time between 8am and 7pm.');
            }
          }
        }}
        style={{ padding: 12 }}
      />
    </Menu>
  );

  return (
    <SeqDropdownButton
      overlay={menu}
      disabled={!!disabled || loading}
      onClick={() => sendAndClose(defaultDeliverAt)}
      open={dropdownVisible}
      onOpenChange={() => setVisible(v => !v)}
      buttonsRender={([left, right]: any[]) => [
        defaultToLastUsed && !!lastScheduledMoment ? (
          left
        ) : (
          <LightTooltip overlay={message} key="leftButton" placement="topLeft">
            {left}
          </LightTooltip>
        ),
        React.cloneElement(right),
      ]}
    >
      {typeof disabled === 'string' ? disabled : sendButtonText(defaultDeliverAt, extraButtonText)}
    </SeqDropdownButton>
  );
};

const sendButtonText = (dateToSend: Date, extra?: string) => {
  let text = 'Send';

  if (moment(dateToSend).diff(moment(), 'hours', true) > 1)
    text = `${text} ${moment(dateToSend).format('MMM D [at] h:mma')}`;
  if (extra) text = `${text} ${extra}`;

  return text;
};

const getPreferredSendingTime = (weekdaysOnly: boolean) => {
  const ideal = moment().add(30, 'minute');
  let deliverAt: Date | undefined = undefined;
  let strTime: string = '';

  if (isSendableDay(ideal.day(), weekdaysOnly) && isSendableTime(ideal)) {
    strTime = 'in 30 minutes';
    deliverAt = ideal.toDate();
  } else if (isSendableDay(ideal.day(), weekdaysOnly) && ideal.hours() <= MIN_HOUR_FOLLOWUP) {
    strTime = 'today at 8am';
  } else if (isSendableDay(ideal.add(1, 'day').day(), weekdaysOnly)) {
    strTime = 'tomorrow at 8am';
  } else {
    strTime = 'Monday at 8am';
  }

  return {
    deliverAt: deliverAt || chronos.parseDate(strTime),
    message: `Your email will send ${strTime}.`,
    rawTimeDesc: strTime,
  };
};

export const ScheduledDateInput: React.FC<{
  onDidChange: (setMessage: (message: string) => void, deliverAt?: Moment) => void;
  onSend?: (deliverAt: Moment) => void;
  inputProps?: InputProps;
  style?: React.CSSProperties;
}> = ({ onSend, onDidChange, inputProps, style }) => {
  const [message, setMessage] = useState('');
  const [inputValue, _setInputValue] = useState<ScheduledDate>({
    name: '',
  });

  const setInputValue = (value: ScheduledDate) => {
    onDidChange(setMessage, value.date);
    _setInputValue(value);
  };

  return (
    <div style={style}>
      <div style={{ display: 'flex', alignItems: 'center', gap: 12 }}>
        <SeqInput
          autoFocus
          size="small"
          outline="rect"
          placeholder="Enter a date"
          value={inputValue.name}
          onChange={e => {
            setMessage('');
            const { value } = e.currentTarget;
            let date;
            if (value.length > 5) {
              date = moment(chronos.parseDate(value));
            }
            setInputValue({
              name: e.currentTarget.value,
              date: date?.isValid() ? date : undefined,
            });
          }}
          {...inputProps}
        />
        {inputValue.date && (
          <>
            <div>{inputValue.date.format(DATE_FORMAT)}</div>
            {onSend && (
              <ClickableItem
                disabled={!!message}
                onClick={() => {
                  if (!message && inputValue.date) {
                    onSend(inputValue.date);
                  }
                }}
              >
                <ScheduledSendIcon style={{ height: 12 }} />
              </ClickableItem>
            )}
          </>
        )}
      </div>
      <div
        style={{
          padding: '6px 0 0 6px',
          color: message ? SEQUOIA_BRIGHT_RED : BLACK5ALPHA,
          fontStyle: 'italic',
          display: 'flex',
          alignItems: 'center',
          fontSize: 12,
        }}
      >
        {message || 'e.g. Tomorrow afternoon, Mon at 6, etc.'}
      </div>
    </div>
  );
};

const isSendableDay = (day: number, weekdaysOnly: boolean) => !weekdaysOnly || (!!day && day < 6);
const isSendableTime = (date: moment.Moment) => {
  const startOfDay = moment(date).startOf('day').add(MIN_HOUR_FOLLOWUP, 'hour').unix();
  const endOfDay = moment(date).startOf('day').add(MAX_HOUR_FOLLOWUP, 'hour').unix();
  const unixDate = date.unix();

  return unixDate >= startOfDay && unixDate <= endOfDay && unixDate >= moment().unix();
};
