import { ReactElement, useCallback, useEffect, useState } from 'react';

interface ScreenWidthControlProps {
  widthBarrier: number;
  narrowRender: ReactElement;
}

export const WIDTH_LARGEST_IPHONE_PX = 926;

export const ScreenWidthControl: React.FunctionComponent<ScreenWidthControlProps> = ({
  widthBarrier,
  narrowRender,
  children,
}) => {
  const size = useWindowSize();
  return <div>{size.width <= widthBarrier ? narrowRender : children}</div>;
};

export const useWindowSize = () => {
  const [size, setSize] = useState({ width: window.screen.width, height: window.screen.height });
  const onWindowResize = useCallback(() => {
    setSize({ width: window.screen.width, height: window.screen.height });
  }, []);

  useEffect(() => {
    window.addEventListener('resize', onWindowResize);
    return () => {
      window.removeEventListener('resize', onWindowResize);
    };
  });

  return size;
};

export const useIsMobileSize = () => {
  const size = useWindowSize();
  const mobileSize = WIDTH_LARGEST_IPHONE_PX + 50 > Math.max(size.width, size.height);
  return mobileSize;
};
