import crypto from 'crypto';
import { compact, omit } from 'lodash';
import { createGlobalStyle } from 'styled-components';

const BlackHue = '16';
const BlackSat = '8%';

export const hexWithOpacity = (hex: string, opacity: number) => {
  const tempHex = hex.replace('#', '');
  const r = parseInt(tempHex.substring(0, 2), 16);
  const g = parseInt(tempHex.substring(2, 4), 16);
  const b = parseInt(tempHex.substring(4, 6), 16);
  return `rgba(${r},${g},${b},${opacity})`;
};

// Color sets declared in Figma

export const SequoiaPrimary = {
  SEQUOIA_GREEN: '#00A071',
  SEQUOIA_GREEN_TRANSPARENT: '#00A071AA',
  SEQUOIA_BRIGHT_BLUE: '#1F8AC4',
  SEQUOIA_BRIGHT_PINK: '#E8599E',
  SEQUOIA_BRIGHT_RED: '#EB2926',
  SEQUOIA_BRIGHT_ORANGE: '#F5571C',
  SEQUOIA_BRIGHT_YELLOW: '#FAB23A',
  SEQUOIA_LIGHT_GRAY: '#A8A39E',
  SEQUOIA_PAPER: '#FFFCF6',
};

export const SequoiaDark = {
  SEQUOIA_DARK_GREEN: '#007354',
  SEQUOIA_DARK_BLUE: '#006D96',
  SEQUOIA_DARK_PURPLE: '#8B4C9E',
  SEQUOIA_DARK_RED: '#BA080D',
  SEQUOIA_DARK_ORANGE: '#C74200',
  SEQUOIA_DARK_YELLOW: '#B06300',
  SEQUOIA_DARK_GRAY: '#928C86 ',
  SEQUOIA_BLACK: '#1B1917',
};

export const SequoiaPastel = {
  SEQUOIA_PASTEL_GREEN: '#E3EDDD',
  SEQUOIA_PASTEL_BLUE: '#E0EBF1',
  SEQUOIA_PASTEL_PURPLE: '#FBE4E9',
  SEQUOIA_PASTEL_RED: '#FBDCD7',
  SEQUOIA_PASTEL_ORANGE: '#FDE3D5',
  SEQUOIA_PASTEL_YELLOW: '#FDF1DA',
  SEQUOIA_PASTEL_GRAY: '#F5F3F2',
  SEQUOIA_WHITE: '#F3F1EA', // Changed from Seq spec
};

const Deprecated = {
  BLACK: '#000000',
  BLUE1: '#0E5A8A',
  BLUE2: '#106BA3',
  BLUE3: '#137CBD',
  BLUE4: '#2B95D6',
  BLUE5: '#48AFF0',
  GREEN1: '#0A6640',
  GREEN2: '#0D8050',
  GREEN3: '#0F9960',
  GREEN4: '#15B371',
  GREEN5: '#3DCC91',
  ORANGE1: '#A66321',
  ORANGE2: '#BF7326',
  ORANGE3: '#D9822B',
  ORANGE4: '#F29D49',
  ORANGE5: '#FFB366',
  RED1: '#A82A2A',
  RED2: '#C23030',
  RED3: '#DB3737',
  RED4: '#F55656',
  RED5: '#FF7373',
  VIOLET1: '#5C255C',
  VIOLET2: '#752F75',
  VIOLET3: '#8F398F',
  VIOLET4: '#A854A8',
  VIOLET5: '#C274C2',
  WHITE: '#FFFFFF',
};
// Cohesive set of colors that works as a background for black text

export const SecondaryColors = {
  GREEN_YELLOW: '#f8f4a4',
  YELLOW: '#fccc52',
  ORANGE: '#ffa400',
  SALMON: '#ff8370',
  PINK: '#e6a5ab',
  LIGHT_PINK: '#f7dee4',
  LAVENDER: '#cdc7e5',
  LILAC: '#bf93f0',
  CITRINE: '#d8ca31',
  AQUAMARINE: '#bfede6',
  BLUE_GREEN: '#82ede5',
  SKY_BLUE: '#5adbff',
  LIGHT_BLUE: '#c7ffda',
};

// Light + Dark mode grays

export const LightColors = {
  BLACK: SequoiaDark.SEQUOIA_BLACK,
  BLACK8ALPHA: hexWithOpacity(SequoiaDark.SEQUOIA_BLACK, 0.8),
  BLACK7ALPHA: hexWithOpacity(SequoiaDark.SEQUOIA_BLACK, 0.7),
  BLACK5ALPHA: hexWithOpacity(SequoiaDark.SEQUOIA_BLACK, 0.5),
  BLACK3ALPHA: hexWithOpacity(SequoiaDark.SEQUOIA_BLACK, 0.3),
  BLACK2ALPHA: hexWithOpacity(SequoiaDark.SEQUOIA_BLACK, 0.2),
  BLACK1ALPHA: hexWithOpacity(SequoiaDark.SEQUOIA_BLACK, 0.1),
  BLACK8SOLID: '#494745',
  BLACK7SOLID: '#5F5E5D',
  BLACK5SOLID: '#8D8C8B',
  BLACK3SOLID: '#BBBAB9',
  BLACK2SOLID: '#D1D1D1',
  BLACK1SOLID: '#E8E8E8',

  WHITE8ALPHA: hexWithOpacity(SequoiaPastel.SEQUOIA_PASTEL_GRAY, 0.8),
  WHITE7ALPHA: hexWithOpacity(SequoiaPastel.SEQUOIA_PASTEL_GRAY, 0.7),
  WHITE5ALPHA: hexWithOpacity(SequoiaPastel.SEQUOIA_PASTEL_GRAY, 0.5),
  WHITE3ALPHA: hexWithOpacity(SequoiaPastel.SEQUOIA_PASTEL_GRAY, 0.3),
  WHITE1ALPHA: hexWithOpacity(SequoiaPastel.SEQUOIA_PASTEL_GRAY, 0.1),
  WHITE8SOLID: '#F7F5F5',
  WHITE7SOLID: '#FBF7F6',
  WHITE5SOLID: '#FAF9F8',
  WHITE3SOLID: '#FDFDFD',
  WHITE1SOLID: '#FEFEFE',

  PAPER5ALPHA: hexWithOpacity(SequoiaPrimary.SEQUOIA_PAPER, 0.5),
  WHITE: '#FFFFFF',
  BORDER_XLIGHT: '#E6E5E5',
  CONFIDENTIAL_TINT: '#C274C2',

  DARK_GRAY1: `hsl(${BlackHue}, ${BlackSat}, 8%)`,
  DARK_GRAY2: `hsl(${BlackHue}, ${BlackSat}, 14%)`,
  DARK_GRAY3: `hsl(${BlackHue}, ${BlackSat}, 20%)`,
  DARK_GRAY4: `hsl(${BlackHue}, ${BlackSat}, 26%)`,
  DARK_GRAY5: `hsl(${BlackHue}, ${BlackSat}, 32%)`,

  GRAY1: `hsl(${BlackHue}, ${BlackSat}, 38%)`,
  GRAY2: `hsl(${BlackHue}, ${BlackSat}, 44%)`,
  GRAY3: `hsl(${BlackHue}, ${BlackSat}, 50%)`,
  GRAY4: `hsl(${BlackHue}, ${BlackSat}, 56%)`,
  GRAY5: `hsl(${BlackHue}, ${BlackSat}, 62%)`,

  LIGHT_GRAY1: `hsl(${BlackHue}, ${BlackSat}, 70%)`,
  LIGHT_GRAY2: `hsl(${BlackHue}, ${BlackSat}, 76%)`,
  LIGHT_GRAY3: `hsl(${BlackHue}, ${BlackSat}, 82%)`,
  LIGHT_GRAY4: `hsl(${BlackHue}, ${BlackSat}, 88%)`,
  LIGHT_GRAY5: `hsl(${BlackHue}, ${BlackSat}, 95%)`,

  RED2: '#C23030',
  ORANGE2: '#BF7326',
  ORANGE3: '#D9822B',
  ORANGE4: '#F29D49',
  ORANGE5: '#FFB366',
  VIOLET5: '#C274C2',

  ...SequoiaPastel,
  ...omit(SequoiaDark, 'SEQUOIA_BLACK'),
};

export const DarkColors: typeof LightColors = {
  BLACK: SequoiaPrimary.SEQUOIA_PAPER,
  BLACK8ALPHA: LightColors.WHITE8ALPHA,
  BLACK7ALPHA: LightColors.WHITE7ALPHA,
  BLACK5ALPHA: LightColors.WHITE5ALPHA,
  BLACK3ALPHA: LightColors.WHITE3ALPHA,
  BLACK2ALPHA: hexWithOpacity(SequoiaPastel.SEQUOIA_PASTEL_GRAY, 0.2),
  BLACK1ALPHA: LightColors.WHITE1ALPHA,
  BLACK8SOLID: LightColors.WHITE1SOLID,
  BLACK7SOLID: LightColors.WHITE3SOLID,
  BLACK5SOLID: LightColors.WHITE5SOLID,
  BLACK3SOLID: LightColors.WHITE7SOLID,
  BLACK2SOLID: LightColors.WHITE8SOLID,
  BLACK1SOLID: LightColors.WHITE8SOLID,

  WHITE8ALPHA: LightColors.BLACK8ALPHA,
  WHITE7ALPHA: LightColors.BLACK7ALPHA,
  WHITE5ALPHA: LightColors.BLACK5ALPHA,
  WHITE3ALPHA: LightColors.BLACK3ALPHA,
  WHITE1ALPHA: LightColors.BLACK1ALPHA,
  WHITE8SOLID: LightColors.BLACK2SOLID,
  WHITE7SOLID: LightColors.BLACK3SOLID,
  WHITE5SOLID: LightColors.BLACK5SOLID,
  WHITE3SOLID: LightColors.BLACK7SOLID,
  WHITE1SOLID: LightColors.BLACK8SOLID,

  PAPER5ALPHA: hexWithOpacity(SequoiaDark.SEQUOIA_BLACK, 0.5),
  WHITE: `#000000`,
  BORDER_XLIGHT: LightColors.WHITE3ALPHA,
  CONFIDENTIAL_TINT: '#C274C2',

  LIGHT_GRAY5: `hsl(${BlackHue}, ${BlackSat}, 8%)`,
  LIGHT_GRAY4: `hsl(${BlackHue}, ${BlackSat}, 14%)`,
  LIGHT_GRAY3: `hsl(${BlackHue}, ${BlackSat}, 20%)`,
  LIGHT_GRAY2: `hsl(${BlackHue}, ${BlackSat}, 26%)`,
  LIGHT_GRAY1: `hsl(${BlackHue}, ${BlackSat}, 32%)`,

  GRAY5: `hsl(${BlackHue}, ${BlackSat}, 38%)`,
  GRAY4: `hsl(${BlackHue}, ${BlackSat}, 44%)`,
  GRAY3: `hsl(${BlackHue}, ${BlackSat}, 50%)`,
  GRAY2: `hsl(${BlackHue}, ${BlackSat}, 56%)`,
  GRAY1: `hsl(${BlackHue}, ${BlackSat}, 62%)`,

  DARK_GRAY1: `hsl(${BlackHue}, ${BlackSat}, 70%)`,
  DARK_GRAY2: `hsl(${BlackHue}, ${BlackSat}, 76%)`,
  DARK_GRAY3: `hsl(${BlackHue}, ${BlackSat}, 82%)`,
  DARK_GRAY4: `hsl(${BlackHue}, ${BlackSat}, 88%)`,
  DARK_GRAY5: `hsl(${BlackHue}, ${BlackSat}, 95%)`,

  RED2: '#C23030',
  ORANGE2: '#BF7326',
  ORANGE3: '#D9822B',
  ORANGE4: '#F29D49',
  ORANGE5: '#FFB366',
  VIOLET5: '#C274C2',

  ...(Object.fromEntries(
    Object.entries(SequoiaPastel).map(([key, value]) => [
      key,
      key === 'SEQUOIA_PASTEL_GRAY' || key === 'SEQUOIA_WHITE'
        ? SequoiaDark.SEQUOIA_BLACK
        : SequoiaDark[`SEQUOIA_DARK_${key.split('_')[2]}` as keyof typeof SequoiaDark],
    ])
  ) as typeof SequoiaPastel),
  ...(Object.fromEntries(
    Object.entries(omit(SequoiaDark, 'SEQUOIA_BLACK')).map(([key, value]) => [
      key,
      SequoiaPastel[`SEQUOIA_PASTEL_${key.split('_')[2]}` as keyof typeof SequoiaPastel],
    ])
  ) as Exclude<typeof SequoiaDark, 'SEQUOIA_BLACK'>),
};

export const ColorPairings = {
  gray: {
    light: SequoiaPastel.SEQUOIA_PASTEL_GRAY,
    dark: LightColors.BLACK8ALPHA,
  },
  green: {
    light: SequoiaPastel.SEQUOIA_PASTEL_GREEN,
    dark: SequoiaDark.SEQUOIA_DARK_GREEN,
  },
  blue: {
    light: SequoiaPastel.SEQUOIA_PASTEL_BLUE,
    dark: SequoiaDark.SEQUOIA_DARK_BLUE,
  },
  purple: {
    light: SequoiaPastel.SEQUOIA_PASTEL_PURPLE,
    dark: SequoiaDark.SEQUOIA_DARK_PURPLE,
  },
  red: {
    light: SequoiaPastel.SEQUOIA_PASTEL_RED,
    dark: SequoiaDark.SEQUOIA_DARK_RED,
  },
  orange: {
    light: SequoiaPastel.SEQUOIA_PASTEL_ORANGE,
    dark: SequoiaDark.SEQUOIA_DARK_ORANGE,
  },
  yellow: {
    light: SequoiaPastel.SEQUOIA_PASTEL_YELLOW,
    dark: SequoiaDark.SEQUOIA_DARK_YELLOW,
  },
};

export const Colors = {
  Static: {
    ...LightColors,
    ...SequoiaPrimary,
    ...SequoiaDark,
    ...SequoiaPastel,
    ...Deprecated,

    // Named constants
    DESTRUCTIVE_TINT: SequoiaPrimary.SEQUOIA_BRIGHT_RED, // Deprecated, use semantic key instead
    WARNING_TINT: SequoiaDark.SEQUOIA_DARK_YELLOW, // Deprecated, use semantic key instead
    SEQUOIA_LIGHT_TEXT: '#8C8C8C', // Only used in external
  },
};

export const generateColorIndexForName = (name: string, max?: number) => {
  const hash = crypto.createHash('md5').update(name).digest('hex');

  const nums = compact(hash.split('').map(char => !!parseInt(char) && parseInt(char))).slice(0, 3);

  const index = Math.abs(nums.reduce((prev, cur) => prev + cur) - 15);

  return max && index > max ? max : index;
};

export const ColorFromInitials = (name: string) => {
  if (name.split(' ').length < 2) {
    return SequoiaDark.SEQUOIA_DARK_YELLOW;
  }
  const excluded = [
    SequoiaPrimary.SEQUOIA_PAPER,
    SequoiaPrimary.SEQUOIA_LIGHT_GRAY,
    SequoiaPrimary.SEQUOIA_GREEN_TRANSPARENT,
    SequoiaDark.SEQUOIA_DARK_GRAY,
    SequoiaDark.SEQUOIA_BLACK,
  ];

  const colors = [...Object.values(SequoiaPrimary), ...Object.values(SequoiaDark)].filter(
    c => !excluded.includes(c)
  );

  const hue = colors[generateColorIndexForName(name)];

  return hue;
};

export const GlobalStyle = createGlobalStyle`
  .ant-alert,
  .ant-card-body,
  .ant-card-head-title,
  .ant-checkbox-wrapper,
  .ant-collapse,
  .ant-divider,
  .ant-dropdown,
  .ant-form-item,
  .ant-input,
  .ant-list,
  .ant-mentions > textarea,
  .ant-mentions-dropdown,
  .ant-message,
  .ant-modal,
  .ant-notification-notice-message,
  .ant-popover,
  .ant-popover-inner-content,
  .ant-radio-group,
  .ant-radio-wrapper,
  .ant-select,
  .ant-select-tree,
  .ant-select-dropdown,
  .ant-select-item-option-content,
  .ant-statistic,
  .ant-statistic-title,
  .ant-table,
  .ant-tag,
  .ant-timeline,
  .ant-tooltip-content,
  .ant-menu-item,
  .ant-menu,
  body {
    font-variant: initial;
    font-variant-numeric: initial;
    font-feature-settings: initial !important;
    font-family: Unica77;
  }
  .ant-scrolling-effect {
    overflow: visible !important;
  }
  .ant-scrolling-effect::-webkit-scrollbar {
    display: none;
  }
  .ant-timeline .ant-timeline-item-head.ant-timeline-item-head-custom {
    background: transparent;
  }
  .ant-card.ant-card-bordered { 
    border: 1px solid #d9d9d9;
  }
  .ant-checkbox-wrapper {
    margin-left: 0 !important;
  }
  .ant-checkbox {
    top: 0.3em;
  }
  .ant-checkbox-inner {
    border-radius: 4px;
    width: 20px;
    height: 20px;
  }
  .ant-checkbox-checked .ant-checkbox-inner::after {
    top: 8px;
    left: 4.5px;
  }
`;
