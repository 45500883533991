import { CloseCircleOutlined } from '@ant-design/icons';
import { Button, notification, Popconfirm, Tooltip } from 'antd';
import React, { useContext } from 'react';
import { OrganizationContext } from '../contexts/Organization';
import { ReviewFeedback } from '../leads/LeadReviewCarousel';
import { makeRequest } from '../shared/Resource';
import { EditableExcludeRulesMap } from '../shared/rule-editors/RuleEditorCommon';
import { Colors } from '../shared/Theme';

const { SEQUOIA_BRIGHT_RED, SEQUOIA_LIGHT_TEXT } = Colors.Static;

export const AddCompanyToSEFilterButton: React.FunctionComponent<{
  company: {
    name: string;
    lirID?: string | undefined;
  };
  model: SourcingEngineAPI.EngineModel;
  onReject?: (feedback?: ReviewFeedback) => void;
}> = ({ company, model, onReject }) => {
  const { id: orgId } = useContext(OrganizationContext);
  const [companyAlreadyIncluded, setCompanyAlreadyIncluded] = React.useState(false);

  const detemineIfCompanyRuleExists = (model: SourcingEngineAPI.EngineModel) =>
    model.excludeRules.some(
      rule =>
        rule.uniqueId === EditableExcludeRulesMap['works-at'].founderEditorUniqueId &&
        rule.parameters &&
        rule.parameters.slice(1).some(p => {
          const param: { name: string; lirIds: string[] } = JSON.parse(p);
          return param.name.toLowerCase() === company.name.toLowerCase();
        })
    );

  const updateSEModel = async () => {
    const worksAtRuleIdx = model.excludeRules.findIndex(
      rule => rule.uniqueId === EditableExcludeRulesMap['works-at'].founderEditorUniqueId
    );
    if (worksAtRuleIdx === -1) {
      model.excludeRules.push({
        id: 'works-at',
        uniqueId: EditableExcludeRulesMap['works-at'].founderEditorUniqueId!,
        parameters: ['0', JSON.stringify({ name: company.name, lirIds: [company.lirID] })],
        parameterHash: '',
      });
    } else {
      model.excludeRules[worksAtRuleIdx].parameters?.push(
        JSON.stringify({
          name: company.name,
          lirIds: [company.lirID],
        })
      );
    }
    if (onReject) {
      onReject({ categories: ['Current Company'], comment: '' });
    }
    const res: SourcingEngineAPI.EngineModel = await makeRequest(
      `/api/sourcing/${orgId}/engine-models/${model.id}?preserveLists=true`,
      'PUT',
      model
    );
    notification.success({
      message: `Candidates from ${company.name} will be excluded from your future lead lists.`,
    });
    if (res.id) {
      setCompanyAlreadyIncluded(detemineIfCompanyRuleExists(res));
    }
  };

  React.useEffect(() => {
    setCompanyAlreadyIncluded(detemineIfCompanyRuleExists(model));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model]);

  return (
    <SEFilterIconButton
      popconfirmTitle={
        <span>
          Would you like to exclude other candidates that currently work at <b>{company.name}</b>{' '}
          from future lead lists?
        </span>
      }
      tootltipTitle={`Exclude candidates who work at ${company.name} from future lead lists.`}
      alreadyIncluded={companyAlreadyIncluded}
      updateSEModel={updateSEModel}
    />
  );
};

export const AddTitleToSEFilterButton: React.FunctionComponent<{
  title: string;
  model: SourcingEngineAPI.EngineModel;
  onReject?: (feedback?: ReviewFeedback) => void;
}> = ({ title, model, onReject }) => {
  const { id: orgId } = useContext(OrganizationContext);
  const [titleAlreadyIncluded, setTitleAlreadyIncluded] = React.useState(false);
  const detemineIfTitleRuleExists = (model: SourcingEngineAPI.EngineModel) =>
    model.excludeRules.some(
      rule =>
        rule.uniqueId === EditableExcludeRulesMap['held-title-past-months'].founderEditorUniqueId &&
        rule.parameters &&
        rule.parameters.slice(1).some(p => p.toLowerCase() === title.toLowerCase())
    );

  const updateSEModel = async () => {
    const holdsTitleRuleIdx = model.excludeRules.findIndex(
      rule =>
        rule.uniqueId === EditableExcludeRulesMap['held-title-past-months'].founderEditorUniqueId
    );
    if (holdsTitleRuleIdx === -1) {
      model.excludeRules.push({
        id: 'held-title-past-months',
        uniqueId: EditableExcludeRulesMap['held-title-past-months'].founderEditorUniqueId!,
        parameters: ['0', title],
        parameterHash: '',
      });
    } else {
      model.excludeRules[holdsTitleRuleIdx].parameters?.push(title);
    }
    if (onReject) {
      onReject({ categories: ['Current job title'], comment: '' });
    }
    const res: SourcingEngineAPI.EngineModel = await makeRequest(
      `/api/sourcing/${orgId}/engine-models/${model.id}?preserveLists=true`,
      'PUT',
      model
    );
    notification.success({
      message: `Candidates with the title of ${title} will be excluded from your future lead lists.`,
    });
    if (res.id) {
      setTitleAlreadyIncluded(detemineIfTitleRuleExists(res));
    }
  };

  React.useEffect(() => {
    setTitleAlreadyIncluded(detemineIfTitleRuleExists(model));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model]);

  return (
    <SEFilterIconButton
      popconfirmTitle={
        <span>
          Would you like to exclude other candidates with a current title of <b>{title}</b> from
          future lead lists?
        </span>
      }
      tootltipTitle={`Exclude candidates with a current title of ${title} from future lead lists.`}
      alreadyIncluded={titleAlreadyIncluded}
      updateSEModel={updateSEModel}
    />
  );
};

const SEFilterIconButton: React.FunctionComponent<{
  popconfirmTitle: React.ReactNode;
  tootltipTitle?: string;
  alreadyIncluded: boolean;
  updateSEModel: () => Promise<void>;
}> = ({ popconfirmTitle, alreadyIncluded, tootltipTitle, updateSEModel }) => {
  return (
    <Popconfirm
      overlayStyle={{ maxWidth: 400 }}
      placement="right"
      icon={null}
      title={popconfirmTitle}
      okText={'Yes'}
      cancelText={'No'}
      disabled={false}
      onConfirm={async () => await updateSEModel()}
    >
      <Button
        block
        type="link"
        disabled={alreadyIncluded}
        style={{
          border: 'none',
          margin: 0,
          padding: 0,
          height: 'fit-content',
        }}
        icon={
          <Tooltip title={tootltipTitle} placement="right">
            <CloseCircleOutlined
              style={{ color: alreadyIncluded ? SEQUOIA_LIGHT_TEXT : SEQUOIA_BRIGHT_RED }}
            />
          </Tooltip>
        }
      />
    </Popconfirm>
  );
};
