import { notification } from 'antd';
import type { Identifier, XYCoord } from 'dnd-core';
import { isEqual } from 'lodash';
import React, { useContext, useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { IconButton, SeqButton } from '../components/Button';
import { Column } from '../components/Common';
import { SeqInput } from '../components/Input';
import { Body1, ExplanatoryText } from '../components/Text';
import { OrganizationContext } from '../contexts/Organization';
import { FadeInContainer } from '../shared/Common';
import { makeRequest } from '../shared/Resource';
import { ScrollingContainer } from '../shared/ScrollableContainer';
import { TrashIcon } from '../shared/Svgs';

interface DragItem {
  idx: number;
  key: string;
  type: string;
}

const STATUS_ITEM_TYPE = 'status';

const REQUIRED_STATUS_COLUMNS: PipelineAPI.ProfileStatus[] = ['Pool', 'Contacted', 'Unresponsive'];
export const ARCHIVED_STATUS_COLUMNS: PipelineAPI.ProfileStatus[] = [
  'Unresponsive',
  'Not interested',
  'Not a match',
];

export const StatusesTab: React.FC = () => {
  const {
    id,
    statuses,
    actions: { refresh },
  } = useContext(OrganizationContext);
  const [updatedStatuses, _setStatuses] = useState<PipelineAPI.ProfileStatus[]>([...statuses, '']);

  const setStatuses = (statuses: PipelineAPI.ProfileStatus[]) => {
    if (!updatedStatuses.includes('')) statuses.push('');
    _setStatuses(statuses);
  };

  const onSave = async () => {
    const newStatuses = updatedStatuses.filter(s => !!s);
    const res = await makeRequest<{ message?: string }>(`/api/organization/${id}/statuses`, 'PUT', {
      statuses: newStatuses,
    });

    if (res.message) {
      refresh();
    }
    notification.success({
      message: res.message || 'No updates were made to your pipeline statuses.',
    });
  };

  const disabled = isEqual(
    statuses.map(s => s.toLowerCase()),
    updatedStatuses.reduce((acc, s) => (s ? [...acc, s.toLowerCase()] : acc), [] as string[])
  );

  return (
    <Column style={{ gap: 16 }}>
      <ExplanatoryText>
        Customize the pipeline stages used in your organization. Drag and drop to reorder. Some of
        the stages have built-in automations and cannot be deleted or renamed. If a stage is
        deleted, all the candidates at that stage will be moved to{' '}
        <span style={{ fontWeight: 700 }}>Later</span>.
      </ExplanatoryText>
      <ScrollingContainer style={{ flex: 1, width: 'fit-content' }}>
        {updatedStatuses.map((s, idx) => (
          <StatusRow
            idx={idx}
            status={s}
            statuses={updatedStatuses}
            onChange={s => setStatuses(s)}
          />
        ))}
      </ScrollingContainer>
      <div style={{ display: 'flex', gap: 12, marginLeft: 36 }}>
        <SeqButton
          intent="secondary"
          disabled={disabled}
          onClick={() => setStatuses([...statuses, ''])}
        >
          reset
        </SeqButton>
        <SeqButton intent="primary" disabled={disabled} onClick={() => onSave()}>
          save
        </SeqButton>
      </div>
    </Column>
  );
};

// Drag and drop logic: https://codesandbox.io/s/long-frost-jonwuv?file=/src/Card.tsx:2702-2729

const StatusRow: React.FC<{
  statuses: string[];
  idx: number;
  status: string;
  onChange: (statuses: string[]) => void;
}> = ({ statuses, idx, status, onChange }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [{ isDragging }, drag] = useDrag({
    type: STATUS_ITEM_TYPE,
    item: { key: status, idx },
    collect: monitor => ({ isDragging: monitor.isDragging() }),
  });

  const moveRow = (dragIndex: number, hoverIndex: number) => {
    const update = [...statuses];
    update.splice(dragIndex, 1);
    update.splice(hoverIndex, 0, statuses[dragIndex]);
    onChange(update);
  };

  const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: STATUS_ITEM_TYPE,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.idx;
      const hoverIndex = idx;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveRow(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.idx = hoverIndex;
    },
  });

  drag(drop(ref));

  const isNotEditable = REQUIRED_STATUS_COLUMNS.includes(status);

  return (
    <FadeInContainer>
      <div
        data-handler-id={handlerId}
        ref={status === 'Pool' ? null : ref}
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: 8,
          padding: 6,
          opacity: isDragging ? 0.5 : 1,
          width: 'fit-content',
        }}
      >
        <FadeInContainer>
          <img
            src="/icons/drag-handle.svg"
            alt="2 columns of ellipses"
            style={{
              cursor: 'grab',
              visibility: !status || status === 'Pool' ? 'hidden' : 'visible',
            }}
          />
        </FadeInContainer>
        {isNotEditable ? (
          <Body1 style={{ fontWeight: 500, lineHeight: '42px' }}>{status}</Body1>
        ) : (
          <>
            <SeqInput
              placeholder="Name of stage"
              value={status}
              outline="rect"
              onChange={e => {
                const update = [...statuses];
                update[idx] = e.target.value;
                onChange(update);
              }}
            />
            {status && (
              <FadeInContainer>
                <IconButton
                  size="small"
                  onClick={() => onChange([...statuses.slice(0, idx), ...statuses.slice(idx + 1)])}
                >
                  <TrashIcon />
                </IconButton>
              </FadeInContainer>
            )}
          </>
        )}
      </div>
    </FadeInContainer>
  );
};
