import { CheckCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { Empty, notification } from 'antd';
import moment from 'moment';
import csv, { Row } from 'neat-csv';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { SourcingTable } from '../components/Common';
import { PageContainer } from '../components/navigation/PageLost';
import { H4 } from '../components/Text';
import { toLinkedinID, toLinkedinURL } from '../shared/li-utils';
import { makeRequest, useResource } from '../shared/Resource';
import { sortUtilNumber } from '../shared/table-helpers';
import { Colors } from '../shared/Theme';

const VALID_HEADERS = [
  'linkedin',
  'linkedin url',
  'identifier',
  'linked in',
  'linkedin page',
  'linkedin profile',
  'linkedin identifier',
  'public url',
  'url',
];
const VALID_COMMENT_HEADERS = ['note', 'notes', 'comment', 'comments'];

export const PageCandidateUpload: React.FunctionComponent<
  RouteComponentProps<{
    hash: string;
  }>
> = props => {
  const { hash } = props.match.params;
  const [pageSummary, { refresh }] = useResource<
    CampaignAPI.ExportedProjectInfo | { error: string }
  >(`/api/sourcing/project-candididates/${hash}/summary`);

  const [uploadStatus, setUploadStatus] = React.useState<'none' | 'uploading' | 'uploaded'>('none');

  if (pageSummary && 'error' in pageSummary) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          height: '100vh',
          paddingBottom: 150,
        }}
      >
        <h1 style={{ display: 'block', textAlign: 'center' }}>Project Not Found</h1>
        <Empty
          description={
            'This link is no longer accessible. If you believe this has been an error, please contact the owner of your project.'
          }
        />
      </div>
    );
  }

  const onReceiveFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!pageSummary) {
      return;
    }

    setUploadStatus('none');
    const file = e.target.files && e.target.files[0];
    if (!file) return;
    if (!file.name.endsWith('.csv')) {
      notification.error({ message: 'The file you chose is not a CSV. Please try again.' });
      return;
    }
    const reader = new FileReader();
    reader.onload = async function (event: any) {
      const inputEl = event.target;
      if (!inputEl) return;

      let rows: Row[] = await csv(inputEl.result, { escape: '""', newline: '\r' });
      if (rows.length === 0) {
        rows = await csv(inputEl.result, { escape: '""', newline: '\n' });
      }
      const headers = rows.length ? Object.keys(rows[0]) : [];
      const liColumn = headers.find(h => VALID_HEADERS.includes(h.toLowerCase()));
      if (!liColumn) {
        notification.error({
          message:
            "Sorry, we're unable to find the column with the LinkedIn identifier in this CSV. " +
            'Please ensure that there is a "linkedin" column and re-upload.',
        });
        inputEl.value = '';
        inputEl.files = null;
        return;
      }

      const commentColumn = headers.find(h => VALID_COMMENT_HEADERS.includes(h.toLowerCase()));

      const profiles: { identifier: string; contractorComment: string }[] = [];
      for (const row of rows) {
        const t = row[liColumn];
        const liId = toLinkedinID(t?.trim());
        // const contractorComment: string =
        // Object.keys(row).find(k => k.toLowerCase() === COMMENT_COLUMN) || '';
        const contractorComment: string = (commentColumn && row[commentColumn]) || '';
        !!liId && profiles.push({ identifier: liId, contractorComment });
      }
      if (!liColumn) {
        notification.error({
          message:
            "Sorry, this CSV doesn't contain any rows with a valid LinkedIn URL or identifier.",
        });
        inputEl.value = '';
        inputEl.files = null;
        return;
      }
      setUploadStatus('uploading');

      const result = await makeRequest<{ success: boolean }>(
        '/api/sourcing/project-candidates',
        'PUT',
        {
          projectId: pageSummary.project.id,
          sourcer: pageSummary.contractorName,
          profiles,
          // SATISFIES ERROR
        } as CampaignAPI.ProjectCandidateUpload
      );

      if (result.success) {
        setUploadStatus('uploaded');
        notification.success({ message: 'Candidates uploaded successfully.' });
        refresh();
      } else {
        setUploadStatus('none');
      }
      inputEl.value = '';
      inputEl.files = null;
    };
    reader.readAsText(file);
  };

  return (
    <PageContainer>
      {pageSummary ? (
        <div>
          <H4>
            Submit Candidates For{' '}
            <span style={{ fontWeight: 500, color: Colors.Static.SEQUOIA_GREEN }}>
              {pageSummary.project.name}
            </span>
          </H4>
          <p style={{ marginBottom: 20, maxWidth: 700 }}>
            Choose a CSV file of candidates to add to the project. Your CSV must have a column
            called "<strong>linkedin</strong>" with each candidate's LinkedIn username or URL (
            https://www.linkedin.com/in/test-profile-b1b7075b/ OR test-profile-b1b7075b).
          </p>
          <p style={{ marginBottom: 20, maxWidth: 700 }}>
            Our team is automatically notified when you submit candidates, there's nothing more you
            need to do!
          </p>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <input type="file" onChange={onReceiveFile} style={{ marginRight: 10 }} />
            {uploadStatus === 'uploading' && (
              <div>
                <LoadingOutlined spin style={{ marginRight: 10 }} />
                Uploading...
              </div>
            )}
            {uploadStatus === 'uploaded' && (
              <div>
                <CheckCircleOutlined style={{ marginRight: 10 }} />
                Uploaded - Thanks! 🎉
              </div>
            )}
          </div>
          <h3 style={{ marginTop: 50 }}>Recent Sent</h3>
          <p style={{ maxWidth: 700 }}>
            After you upload candidates, our team will do a final once-over to check the candidates
            profiles and we'll update the table below if we have any feedback.
          </p>
          <SourcingTable<CampaignAPI.ExportedProjectInfo['recentResults'][0]>
            dataSource={pageSummary.recentResults}
            pagination={{ defaultPageSize: 20 }}
            size={'small'}
            columns={[
              {
                title: 'identifier',
                render: (_, recentUpload) => {
                  return (
                    <a href={toLinkedinURL(recentUpload.identifier)}>{recentUpload.identifier}</a>
                  );
                },
              },
              {
                title: 'Outcome',
                render: (_, recentUpload) => {
                  return recentUpload.approved === null
                    ? 'Awaiting Review'
                    : !!recentUpload.approved
                      ? 'Accepted'
                      : 'Rejected';
                },
              },
              {
                title: 'Reviewed At',
                render: (_, recentUpload) =>
                  recentUpload.reviewedAt ? moment(recentUpload.reviewedAt).format(`M/D/YYYY`) : '',
                sorter: sortUtilNumber(u => moment(u?.reviewedAt).unix()),
              },
              {
                title: 'Comment',
                render: (_, recentUpload) => {
                  return recentUpload.commentInfo ? (
                    <span>
                      <strong>{recentUpload.commentInfo.authorName}</strong>:{' '}
                      {recentUpload.commentInfo.comment}
                    </span>
                  ) : (
                    <div />
                  );
                },
              },
              {
                title: `Contractor Comment`,
                dataIndex: 'contractorComment',
              },
            ]}
          />
        </div>
      ) : (
        <div></div>
      )}
    </PageContainer>
  );
};
