import moment from 'moment';
import React, { useState } from 'react';
import { SeqButton } from '../components/Button';
import { HorizontalDivider, LoaderLine } from '../components/Common';
import { Flag } from '../components/Svgs';
import { Body3 } from '../components/Text';
import { lastStaleUpdateFor, monitoredStatuses, onBulkUpdateProfiles } from '../Helpers';
import { Colors } from '../shared/Theme';

const { SEQUOIA_BRIGHT_BLUE, SEQUOIA_PASTEL_BLUE } = Colors.Static;

export const StaleBatchMessage: React.FunctionComponent<{
  status: PipelineAPI.ProfileStatus;
  profiles: NetworkAPI.NetworkProfile[];
  onCheckSome: (ids: number[], uncheck?: boolean) => void;
  onSaved: (profiles: NetworkAPI.NetworkProfile[]) => void;
}> = ({ status, profiles, onCheckSome, onSaved }) => {
  const [saving, setSaving] = useState<boolean>(false);

  if (!monitoredStatuses.includes(status)) return <></>;

  const staleProfiles = profiles.filter(p => {
    const lastUpdate = lastStaleUpdateFor(p);
    if (!lastUpdate) return false;

    const numOfDaysAgo = moment().diff(lastUpdate.createdAt, 'days', false);
    return numOfDaysAgo >= 30;
  });

  if (staleProfiles.length < 2) return <></>;

  const onClearFlags = () => {
    const profileIds = staleProfiles.map(p => p.id);
    setSaving(true);
    onBulkUpdateProfiles({
      orgId: profiles[0].organizationId,
      ids: profileIds,
      update: { type: 'status', status, name: status },
      onSaved,
      silenceNotif: true,
    });
    setSaving(false);
  };

  const onMoveToPool = () => {
    const profileIds = staleProfiles.map(p => p.id);
    setSaving(true);
    onCheckSome(profileIds);
    onBulkUpdateProfiles({
      orgId: profiles[0].organizationId,
      ids: profileIds,
      update: { type: 'status', status: 'Pool', name: 'Pool' },
      onSaved,
    });
    onCheckSome(profileIds, true);
    setSaving(false);
  };

  return (
    <>
      {saving && <LoaderLine />}
      <Body3 style={{ padding: 12, background: SEQUOIA_PASTEL_BLUE, fontSize: 12 }}>
        <div style={{ display: 'flex', gap: 8 }}>
          <div>
            {staleProfiles.length} profiles in <span style={{ fontWeight: 700 }}>{status}</span>{' '}
            haven't been updated in the past month.
          </div>
          <div>
            <Flag color={SEQUOIA_BRIGHT_BLUE} style={{ width: 14 }} />
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 8, gap: 8 }}>
          <SeqButton size="small" onClick={onClearFlags} disabled={saving}>
            Clear flags
          </SeqButton>
          <SeqButton intent="inverted" size="small" onClick={onMoveToPool} disabled={saving}>
            Move to Pool
          </SeqButton>
        </div>
      </Body3>
      <HorizontalDivider />
    </>
  );
};
