import { CloseOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { AutoComplete, Button, Tooltip } from 'antd';
import { uniqBy } from 'lodash';
import React, { useContext } from 'react';
import { makeRequest } from '../Resource';
import { Colors } from '../Theme';
import {
  EditableExcludeRulesMap,
  EditableRuleIds,
  EditorInput,
  HeaderRow,
  invalid,
  NumberInputWithLabel,
  onChangeWithValidation,
  REGEX_EXPLANATION,
  Rule,
  RuleEditorContext,
  RuleEditorProps,
  valid,
  ValidationInfo,
} from './RuleEditorCommon';

export const WorksAtEditor: React.FC<RuleEditorProps> = ({ rule, onChange, style, title }) => {
  const [options, setOptions] = React.useState<ExternalAPI.Workplace[]>([]);
  const [searchString, setSearchString] = React.useState('');
  const currentParams: { lirIds: string[]; name: string }[] = rule.parameters
    .slice(1)
    .map(p => JSON.parse(p));

  const { workplacesAPIPath } = useContext(RuleEditorContext);
  const searchForCompany = async (name: string) => {
    setSearchString(name);
    setOptions(await makeRequest<ExternalAPI.Workplace[]>(`${workplacesAPIPath}?search=${name}`));
  };

  const updateParams = (selected: string, isDeleting?: boolean) => {
    setSearchString('');
    if (isDeleting) {
      const updatedParams = currentParams
        .filter(param => param.name.toLowerCase() !== selected.toLowerCase())
        .map(p => JSON.stringify(p));
      onChangeWithValidation(
        {
          ...rule,
          parameters: ['0', ...updatedParams],
        },
        onChange,
        validate
      );
    } else {
      const companiesToAdd = options.filter(
        opt => opt.companyName.toLowerCase() === selected.toLowerCase()
      );
      const replaced = [
        ...rule.parameters,
        JSON.stringify({
          name: companiesToAdd[0]?.companyName,
          lirIds: [...companiesToAdd.filter(co => !!co.lirID).map(co => co.lirID)],
        }),
      ];
      onChangeWithValidation({ ...rule, parameters: replaced }, onChange, validate);
    }
  };

  return (
    <div style={style}>
      {title && <HeaderRow label={title} onChange={onChange} rule={rule} />}
      <NumberInputWithLabel
        label={'Months (historically) to search'}
        tooltipText={
          'A zero will only match if person currently works for one of these employers. 18 will match if they worked there in the past 18 months.'
        }
        onChange={newVal => {
          onChangeWithValidation(
            { ...rule, parameters: [`${newVal}`, ...rule.parameters.slice(1)] },
            onChange,
            validate
          );
        }}
        value={rule.parameters[0]}
      />
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
        Search for a company in the field below{' '}
        <Tooltip overlayStyle={{ minWidth: 500 }} overlay={REGEX_EXPLANATION}>
          <QuestionCircleOutlined
            style={{ color: Colors.Static.SEQUOIA_LIGHT_TEXT, marginLeft: 5 }}
          />
        </Tooltip>
        <div style={{ width: 50 }} />
      </div>
      <div style={{ marginLeft: 15, marginTop: 20 }}>
        <div style={{ display: 'flex' }}>
          <AutoComplete
            backfill={true}
            defaultActiveFirstOption={true}
            options={uniqBy(
              options.map(opt => ({
                value: opt.companyName,
                disabled: currentParams.some(
                  param => param.name.toLowerCase() === opt.companyName.toLowerCase()
                ),
              })),
              'value'
            )}
            value={searchString}
            onChange={search => searchForCompany(search)}
            onSelect={value => updateParams(value)}
            children={
              <EditorInput placeholder={'Ex: Facebook'} style={{ width: 380, marginBottom: 10 }} />
            }
          />
        </div>
        {currentParams.map((company, index) => (
          <div key={`company-${company.name}-${index}`} style={{ display: 'flex' }}>
            <EditorInput
              style={{ width: 380, marginBottom: 10 }}
              defaultValue={company.name}
              readOnly={true}
            />
            {company.name && (
              <Button
                tabIndex={-1}
                style={{ color: Colors.Static.SEQUOIA_BRIGHT_RED }}
                onClick={() => updateParams(company.name, true)}
                type={'link'}
              >
                <CloseOutlined style={{ marginLeft: 20 }} />
              </Button>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

const validate = (rule: Rule): ValidationInfo => {
  const ruleLabel = EditableExcludeRulesMap[rule.id as EditableRuleIds]?.label || '';
  if (
    !rule.parameters ||
    isNaN(Number(rule.parameters[0])) ||
    rule.parameters.filter(Boolean).length < 2 //First parameter is the zero for time in job
  ) {
    return invalid(`'${ruleLabel}' requires at least one company name.`);
  }
  return valid();
};
