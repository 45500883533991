import { History } from 'history';
import { cloneDeep } from 'lodash';
import { Moment } from 'moment';
import React from 'react';
import { NON_LI_USER_PREFIX } from './li-utils';
import { Colors } from './Theme';

export const OFFICE_TIMEZONE = 'America/Los_Angeles';
// these followups intentionally don't match backend versions, bc these are timezone independent.
export const MIN_HOUR_FOLLOWUP = 8;
export const MAX_HOUR_FOLLOWUP = 19;

export const TALENT_HOST =
  process.env.NODE_ENV === 'production'
    ? document.location.host === 'app-dev.sequoiacap.com'
      ? 'https://app-dev.sequoiacap.com/talent'
      : 'https://app.sequoiacap.com/talent'
    : 'http://localhost:5000/talent';

export const TALENT_WEB_APP =
  process.env.NODE_ENV === 'production'
    ? document.location.host === 'app-dev.sequoiacap.com'
      ? 'https://app-dev.sequoiacap.com/talent'
      : 'https://app.sequoiacap.com/talent'
    : 'http://localhost:3000/talent';

export const EXTERNAL_HOST =
  process.env.NODE_ENV === 'production'
    ? 'https://network.sequoiacap.com'
    : 'https://localhost:5001';

export const EXTERNAL_WEB_APP =
  process.env.NODE_ENV === 'production'
    ? 'https://network.sequoiacap.com'
    : 'http://localhost:3001';

export const EmailRegexp = () =>
  // eslint-disable-next-line no-control-regex
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/gi;

export const isValidEmail = (email: string): boolean => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return !!email && re.test(String(email).toLowerCase());
};

export function isValidPhoneNumber(phoneNumber: string): boolean {
  const re = /^(\(?\+?[0-9]*\)?)?[0-9\-+ ()]{10,} *(\(.*\))?$/;
  return re.test(phoneNumber);
}

export const withHTTPScheme = (url: string) => {
  if (!url) {
    return url;
  }
  if (!url.startsWith('http')) {
    return `http://${url}`;
  }
  return url;
};

export function delay(milliseconds: number) {
  return new Promise(resolve => setTimeout(resolve, milliseconds));
}

export const lowerCaseEmails = (email: PeopleAPI.Emails | undefined) => {
  if (!email) {
    return;
  }
  const emailOut = cloneDeep(email);

  emailOut.personal = emailOut.personal
    ? {
        ...emailOut.personal,
        address: emailOut.personal.address.toLowerCase(),
      }
    : undefined;
  emailOut.work = emailOut.work
    ? { ...emailOut.work, address: emailOut.work.address.toLowerCase() }
    : undefined;
  emailOut.alternates = emailOut.alternates.map(a => ({ ...a, address: a.address.toLowerCase() }));
  return emailOut;
};

export const isLIUser = (profile: { identifier: string }): boolean => {
  return !profile.identifier.includes(NON_LI_USER_PREFIX);
};

export function compactLocationText(text: string) {
  return text
    .replace(', United States', '')
    .replace(', United Kingdom', '')
    .replace(', USA', '')
    .replace(', US', '')
    .replace(', California', '')
    .replace(/, Ca$/i, '')
    .replace(' Metropolitan Area', '');
}

export function compactLocation(location: LocationAPI.Location) {
  if (!location || !location.title) {
    return '';
  }
  const address = /, (.*), (.*) [0-9]{5}, [A-Z]{2,3}$/.exec(location.title);
  const suffix = location.isHQ ? ' (HQ)' : '';

  if (address) {
    // If the location ends with a 5-digit zipcode + country, it's a geocoded street address.
    // Chop it down to just city, state.
    return `${address[1]}, ${address[2]}${suffix}`;
  }
  return `${compactLocationText(location.title)}${suffix}`;
}

export function isNever(val: never) {
  return true;
}

export const EXTERNAL_RECIPIENT_VARIABLES: { [key: string]: string } = {
  first_name: 'John',
  last_name: 'Doe',
  company: 'Hooli',
};

export const RECIPIENT_VARIABLES: { [key: string]: string } = {
  ...EXTERNAL_RECIPIENT_VARIABLES,
  company_poc: 'Gavin Belson',
};

export const RECIPIENT_PLACEHOLDER = {
  name: `John Doe`,
  email: 'john.doe@example.com',
};

export const createEmailPreview = (
  bodyText: string,
  subjectText: string,
  variables: { [key: string]: string }
) => {
  let bodyPreview = bodyText;
  let subjectPreview = subjectText;
  for (const [varName, varValue] of Object.entries(variables)) {
    bodyPreview = bodyPreview.replace(
      new RegExp(`{{{${varName}}}}`, 'g'),
      `<span style="color:${Colors.Static.SEQUOIA_GREEN};">${varValue}</span>`
    );
    bodyPreview = bodyPreview.replace(
      new RegExp(`{{${varName}}}`, 'g'),
      `<span style="color:${Colors.Static.SEQUOIA_GREEN};">${varValue}</span>`
    );
    subjectPreview = subjectPreview.replace(new RegExp(`{{{${varName}}}}`, 'g'), varValue);
    subjectPreview = subjectPreview.replace(new RegExp(`{{${varName}}}`, 'g'), varValue);
  }
  return {
    body: bodyPreview,
    subject: subjectPreview,
  };
};

export const fillInVariables = (text: string, variables: { [key: string]: string }) => {
  let result = text;

  for (const [varName, varValue] of Object.entries(variables)) {
    result = result.replace(new RegExp(`{{{${varName}}}}`, 'g'), varValue);
    result = result.replace(new RegExp(`{{${varName}}}`, 'g'), varValue);
  }

  return result;
};

export function getNonDefault<T extends object>(values: T, defaults: T): Partial<T> {
  const nonDefaultVals: { [key: string]: any } = {};
  for (const [key, value] of Object.entries(values)) {
    const defaultValue = defaults[key as keyof T];
    if (JSON.stringify(defaultValue) !== JSON.stringify(value)) {
      nonDefaultVals[key] = value;
    }
  }
  return nonDefaultVals as Partial<T>;
}

export function keyEventInInput(e: KeyboardEvent | React.KeyboardEvent<any>) {
  // if (e.defaultPrevented) {
  //   return true;
  // }
  if (
    e.target instanceof HTMLElement &&
    (['INPUT', 'TEXTAREA'].includes(e.target.nodeName) || e.target.contentEditable === 'true') &&
    e.target.getAttribute('type') !== 'checkbox'
  ) {
    return true;
  }
  if (e.target instanceof HTMLElement && e.target.closest('[data-lexical-editor]')) {
    return true;
  }
  return false;
}

//Opens in a new tab if cmd + click
export function pushOrOpen(
  e: React.MouseEvent<HTMLElement, MouseEvent>,
  path: string,
  history: History<unknown>
) {
  e.metaKey ? window.open(`/talent${path}`, '_blank') : history.push(path);
}

export const findClosestWorkday = (date: Moment) =>
  (date.weekday() === 0
    ? date.add(1, 'day')
    : date.weekday() === 6
      ? date.subtract(1, 'day')
      : date
  ).toDate();

let hiddenDiv: HTMLDivElement;

export function htmlToPlain(html: string) {
  // if html does not contain any html, just return it
  if (!/<[^<]+>/.test(html)) {
    return html;
  }

  // Create a temporary DOM node and use it to convert from HTML to plaintext.
  // Note: must be attached before calling innerText or whitespace is not preserved.
  if (!hiddenDiv) {
    hiddenDiv = document.createElement('div');
    hiddenDiv.style.height = '0px';
    hiddenDiv.style.width = '0px';
    hiddenDiv.style.overflow = 'hidden';
    hiddenDiv.style.pointerEvents = 'none';
    hiddenDiv.style.opacity = '0';
    document.body.append(hiddenDiv);
  }
  hiddenDiv.innerHTML = html;
  const plain = hiddenDiv.innerText.trim().replace(/\n\n/g, '\n');
  return plain;
}

export const prefixMatch = (matchingStr: string, input: string) =>
  !!matchingStr &&
  (matchingStr.toLowerCase().startsWith(input.toLowerCase()) ||
    matchingStr.split(' ').some(n => n.startsWith(input.toLowerCase())));

export const pluralize = (
  count: number,
  item: string,
  plural = item + 's',
  verb?: {
    beforeVerb?: string;
    singular: string;
    plural: string;
  }
) => {
  const isSingular = count === 1;
  let str = `${count.toLocaleString()} ${isSingular ? item : plural}`;
  if (verb) {
    const { beforeVerb, singular } = verb;
    if (beforeVerb) str += ` ${beforeVerb}`;
    str += ` ${isSingular ? singular : verb.plural}`;
  }
  return str;
};

export const escapeStringForUseInRegex = (input: string) =>
  input.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string

export function addToDict<T>(item: T, prop: string | number, dict: { [key: string]: T[] }) {
  return prop in dict ? dict[prop].push(item) : (dict[prop] = [item]);
}

export const addToDictCount = (prop: string, dict: { [key: string]: number }) =>
  prop in dict ? dict[prop]++ : (dict[prop] = 1);

export const removeFromUrl = (params: string[]) => {
  const urlParams = new URLSearchParams(window.location.search);
  params.forEach(p => urlParams.delete(p));
  const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
  window.history.replaceState({}, '', newUrl);
};
