import { notification, Switch } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { pick } from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import { ActionButton, SeqButton } from '../components/Button';
import { FastTable } from '../components/FastTable';
import { showAccountSettingsModal } from '../components/modals/AccountSettingsModal';
import { SeqPopover } from '../components/Popover';
import { customSortableColumn } from '../components/SeqTable';
import { Label3 } from '../components/Text';
import { TextButton } from '../components/TextButton';
import { Dot } from '../shared/Common';
import { makeRequest } from '../shared/Resource';
import { Colors } from '../shared/Theme';

export const DATE_FORMAT = 'MMM D, YYYY';
const { SEQUOIA_LIGHT_TEXT, SEQUOIA_GREEN } = Colors.Static;

export const CompanyMembersTable: React.FC<{
  organization: OrganizationAPI.OrganizationWithUsers;
  refresh: () => void;
}> = ({ organization, refresh }) => {
  const onReInvite = async (user: ExternalAPI.User) => {
    const payload = pick(user, ['id', 'name', 'email']);
    const userResp = await makeRequest<ExternalAPI.User | { error: string }>('/api/user', 'PUT', {
      ...payload,
      sendInvite: true,
    } satisfies ExternalAPI.UpsertUserRequest);
    if ('id' in userResp && userResp.id) {
      notification.success({ message: `Invitation sent to ${user.email}` });
    } else {
      const errorMessage =
        'error' in userResp
          ? userResp.error
          : 'Unable to invite user, please contact an administrator';
      notification.error({ message: errorMessage });
    }
  };

  const onReactivate = async (user: ExternalAPI.User) => {
    const userResp = await makeRequest<ExternalAPI.User>(
      `/api/user/${user.id}/activate`,
      'PUT',
      {}
    );
    notification.success({
      message: `${userResp.name} was reactivated. They will be able to log into their account again. Any old campaigns will remain archived.`,
    });
    refresh();
  };

  const onToggleFounder = async (user: ExternalAPI.User, isFounder: boolean) => {
    await makeRequest(`/api/user`, 'PUT', { id: user.id, isFounder });
    notification.success({ message: `${user.name}'s founder status was updated.` });
    refresh();
  };

  const columns: ColumnsType<ExternalAPI.UserWithLastActivity> = [
    {
      key: 'Name',
      width: 180,
      title: customSortableColumn<ExternalAPI.Profile>('Name'),
      render: (_, user) => user.name,
      sorter: (a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
    },
    {
      title: 'Email',
      render: (_, user) => (
        <>
          <div>{user.email}</div>
          {!user.lastActivity && !user.archivedAt && (
            <TextButton
              onClick={() => onReInvite(user)}
              style={{ color: SEQUOIA_GREEN, fontSize: 12, paddingLeft: 0 }}
              link
            >
              Resend Invitation
            </TextButton>
          )}
        </>
      ),
    },
    {
      title: 'Access Dates',
      width: 160,
      render: (_, user) => (
        <div style={{ color: SEQUOIA_LIGHT_TEXT }}>
          {moment(user.createdAt).format(DATE_FORMAT)} -{' '}
          {user.archivedAt ? <div>{moment(user.archivedAt).format(DATE_FORMAT)}</div> : 'Present'}
        </div>
      ),
    },
  ];

  !organization.inactive &&
    columns.push(
      ...([
        {
          title: 'Last Activity',
          render: (_, user) => (
            <div style={{ color: SEQUOIA_LIGHT_TEXT }}>
              {user.lastActivity ? moment(user.lastActivity.createdAt).format(DATE_FORMAT) : '--'}
            </div>
          ),
        },
        {
          title: 'Is Founder',
          width: 100,
          render: (_, user) => {
            return (
              <Switch
                checked={user.isFounder}
                checkedChildren={<Label3 style={{ color: 'white' }}>Yes</Label3>}
                unCheckedChildren={<Label3>No</Label3>}
                onChange={e => onToggleFounder(user, e)}
              />
            );
          },
        },
      ] satisfies ColumnsType<ExternalAPI.UserWithLastActivity>)
    );

  columns.push({
    title: 'Actions',
    width: 190,
    render: (_, user) => (
      <div style={{ display: 'flex', gap: 2, alignItems: 'center', justifyContent: 'flex-end' }}>
        {!user.archivedAt ? (
          <>
            <ActionButton onClick={() => showAccountSettingsModal(user.id)}>SETTINGS</ActionButton>
            <Dot />
            <DeactivateUserPopover user={user} refresh={refresh} />
          </>
        ) : (
          <ActionButton onClick={() => onReactivate(user)}>REACTIVATE</ActionButton>
        )}
      </div>
    ),
  });

  return (
    <FastTable
      rowKey={u => u.id}
      rowHeight="measure"
      columns={columns}
      dataSource={organization.users}
    />
  );
};

const DeactivateUserPopover: React.FC<{ user: ExternalAPI.User; refresh: () => void }> = ({
  user,
  refresh,
}) => {
  const [archiving, setArchiving] = React.useState<boolean>(false);
  const [popoverVisible, setPopoverVisible] = useState<boolean>(false);

  const onArchiveUser = async () => {
    setArchiving(true);
    const senderResponse = await makeRequest<ExternalAPI.User>(
      `/api/user/${user.id}/archive`,
      'PUT',
      { stopFollowups: true }
    );

    setArchiving(false);
    if (senderResponse.id) {
      notification.success({ message: `${user.name || user.email} has been archived.` });
      refresh();
    }
    setPopoverVisible(false);
  };

  const popoverContent = (
    <>
      <div>
        Are you sure that you'd like to deactivate{' '}
        <span style={{ fontWeight: 500 }}>{user.name || user.email}</span>?
      </div>
      <div>
        This action archives all of this user's campaigns & stops any remaining followup emails.
      </div>
    </>
  );

  return (
    <SeqPopover
      content={popoverContent}
      footer={
        <>
          <SeqButton size="small" onClick={() => setPopoverVisible(false)}>
            Cancel
          </SeqButton>
          <SeqButton size="small" danger onClick={onArchiveUser}>
            Archive User
          </SeqButton>
        </>
      }
      placement={'bottomLeft'}
      open={popoverVisible}
      onOpenChange={() => setPopoverVisible(v => !v)}
    >
      <ActionButton disabled={archiving}>DEACTIVATE</ActionButton>
    </SeqPopover>
  );
};
