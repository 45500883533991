import { Slider } from 'antd';
import { findKey } from 'lodash';
import React from 'react';
import {
  EditableExcludeRulesMap,
  EditableRuleIds,
  invalid,
  onChangeWithValidation,
  RuleEditorProps,
  useDescription,
  valid,
  ValidationInfo,
} from '../rule-editors/RuleEditorCommon';
import { Explanatory, HeaderRow, Rule } from './RuleEditorCommon';

export const HeadCountEditor: React.FC<RuleEditorProps> = ({
  rule,
  onChange,
  style,
  title,
  ruleType,
}) => {
  const onChangeParams = (values: number[]) => {
    const [min, max] = values;
    if (marks[min] === marks[max]) {
      return;
    } //Do nothing, single val is not a range
    onChangeWithValidation(
      { ...rule, parameters: [marks[min].value, marks[max].value] },
      onChange,
      validate
    );
  };

  const marks: { [idx: number]: { value: string; label: string } } = {
    0: { label: '1', value: '1' },
    1: { label: '500', value: '500' },
    2: { label: '1,000', value: '1000' },
    3: { label: '5,000', value: '5000' },
    4: { label: '10,000', value: '10000' },
    5: { label: '1M+', value: '3000000' }, //Walmart is largest at ~2.5 mill
  };

  const sliderValues = (
    rule.parameters.slice(0, 2).length === 2
      ? rule.parameters.map(paramIn =>
          paramIn === '30000000' ? 5 : Number(findKey(marks, i => i.value === paramIn))
        )
      : undefined
  ) as [number, number] | undefined;

  const desc = useDescription(
    { rule, ruleType },
    {
      include: 'What size of company would you prefer that people work for?',
      exclude:
        'If a person works at an employer with a headcount between these two numbers, they will be removed from the model.',
      invert: `Would you prefer people currently working at an established enterprise employer or
  people from a smaller startup environment?`,
    }
  );

  return (
    <div style={style}>
      {title && <HeaderRow label={title} onChange={onChange} rule={rule} />}
      <Explanatory>{desc}</Explanatory>
      <div style={{ paddingLeft: 15, paddingRight: 15, marginTop: 20 }}>
        <Slider
          min={0}
          max={5}
          value={sliderValues}
          tooltip={{ open: false }}
          marks={marks}
          range
          defaultValue={[0, 5]}
          onChange={onChangeParams}
        />
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div>Employees</div>
        </div>
      </div>
    </div>
  );
};

const validate = (rule: Rule): ValidationInfo => {
  const ruleLabel = EditableExcludeRulesMap[rule.id as EditableRuleIds]?.label || '';
  if (
    !rule.parameters ||
    rule.parameters.filter(Boolean).length < 2 ||
    isNaN(Number(rule.parameters[0])) ||
    isNaN(Number(rule.parameters[1]))
  ) {
    return invalid(`${ruleLabel} requires 2 number parameters.`);
  }
  return valid();
};
