import { groupBy } from 'lodash';
import moment from 'moment';
import * as QueryString from 'query-string';
import { default as React, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { LEAD_LIST_NAME_DATE_FORMAT } from '../leads/PageLeads';
import { CharInCircle } from '../shared/Common';
import { InputTitle, SeqTextArea } from './Input';
import { RoleSelect } from './selects/RoleSelect';
import { Select } from './selects/Select';
import { Label2 } from './Text';

const CREATE_LEAD_LIST_OPTION_ID = -1;

export const LeadListAndRoleSelect: React.FC<{
  leadLists?: LeadsAPI.LeadList[];
  leadList?: LeadsAPI.UpsertLeadList;
  setList: (l?: CoreAPI.MakeOptional<LeadsAPI.UpsertLeadList, 'addProfiles' | 'origin'>) => void;
}> = ({ leadList, leadLists, setList }) => {
  const location = useLocation();

  const leadListsByRole = React.useMemo(() => groupBy(leadLists, 'roleId'), [leadLists]);
  const listIdFromURL: number | undefined = React.useMemo(() => {
    const query = QueryString.parse(location.search) as { listId?: number };
    return query.listId ? Number(query.listId) : undefined;
  }, [location.search]);

  useEffect(() => {
    if (Object.keys(leadListsByRole).length) {
      let list = Object.values(leadListsByRole)
        .flat()
        .find(ll => ll.id === listIdFromURL);

      if (!list) {
        const roleId = Object.keys(leadListsByRole)[0];
        list = leadListsByRole[roleId][0];
      }

      setList(convertToUpsertList(list));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leadListsByRole, listIdFromURL]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
      <div style={{ display: 'flex', alignItems: 'center', gap: 12 }}>
        <InputTitle title="Role" size="small" required />
        <RoleSelect
          role={leadList?.roleId}
          opts={{ blockSelectNone: true }}
          setRole={r => {
            if (r) {
              setList(
                r in leadListsByRole
                  ? convertToUpsertList(leadListsByRole[r][0])
                  : { roleId: r, id: CREATE_LEAD_LIST_OPTION_ID }
              );
            }
          }}
        />
      </div>
      {leadList?.roleId && (
        <>
          <div style={{ display: 'flex', alignItems: 'center', gap: 12 }}>
            <InputTitle title="List" size="small" required />
            <LeadListSelect
              selected={leadList?.id}
              roleId={leadList.roleId}
              leadLists={leadListsByRole[leadList.roleId]}
              setList={setList}
            />
          </div>
          <SeqTextArea
            title="Description"
            size="small"
            autoSize
            placeholder="Describe this lead list"
            value={leadList?.description || ''}
            onChange={e => {
              const description = e.currentTarget.value;
              setList({ ...leadList, description });
            }}
            disabled={leadList.id !== CREATE_LEAD_LIST_OPTION_ID && !!leadList.description}
          />
          <SeqTextArea
            title="Comment"
            size="small"
            autoSize
            placeholder="Why was this candidate chosen?"
            value={leadList.addProfiles?.[0]?.comment}
            onChange={e => {
              const comment = e.currentTarget.value;
              const update = { ...leadList };
              update.addProfiles = [{ ...update.addProfiles?.[0], comment }];
              setList(update);
            }}
          />
        </>
      )}
    </div>
  );
};

const LeadListSelect: React.FC<{
  selected?: number;
  roleId: number;
  leadLists: LeadsAPI.LeadList[];
  setList: (l?: CoreAPI.MakeOptional<LeadsAPI.UpsertLeadList, 'addProfiles' | 'origin'>) => void;
}> = ({ selected, roleId, leadLists, setList }) => {
  return leadLists?.length && selected ? (
    <Select
      type="secondary"
      selected={selected}
      onSelect={id =>
        setList(
          id === CREATE_LEAD_LIST_OPTION_ID
            ? { id: CREATE_LEAD_LIST_OPTION_ID, roleId }
            : convertToUpsertList(leadLists.find(b => b.id === id))
        )
      }
      options={[
        ...leadLists.map(({ id, createdAt, profiles }) => {
          const leadListName = moment(createdAt).format(LEAD_LIST_NAME_DATE_FORMAT);
          return {
            id,
            name: leadListName,
            customRender: () => (
              <div style={{ display: 'flex', alignItems: 'center', gap: 6, fontWeight: 400 }}>
                {leadListName}
                <CharInCircle char={profiles.length} />
              </div>
            ),
          };
        }),
        {
          id: CREATE_LEAD_LIST_OPTION_ID,
          name: 'New Lead List',
          customRender: () => <div style={{ fontWeight: 500 }}>Create New List</div>,
        },
      ]}
    />
  ) : (
    <Label2>New Lead List</Label2>
  );
};

const convertToUpsertList = (list?: LeadsAPI.LeadList) =>
  list
    ? ({
        id: list.id,
        roleId: list.roleId,
        description: list.description || '',
      } satisfies CoreAPI.MakeOptional<LeadsAPI.UpsertLeadList, 'addProfiles' | 'origin'>)
    : undefined;
