import { Button, Checkbox, Popconfirm, Select } from 'antd';
import { uniq } from 'lodash';
import csv from 'neat-csv';
import React from 'react';
import { Prompt } from 'react-router-dom';
import { isValidEmail } from './helpers';
import { toLinkedinID } from './li-utils';
import { Colors } from './Theme';

type CSVItem = { [key: string]: string };

type HeaderMap = { [colIdx: string]: KnownColumns };

export type KnownColumns =
  | 'name'
  | 'firstname'
  | 'lastname'
  | 'linkedin'
  | 'title'
  | 'location'
  | 'email'
  | 'workEmail'
  | 'personalEmail'
  | 'phone'
  | 'otoStart'
  | 'comment'
  | 'group1'
  | 'group2'
  | 'note'
  | 'none'
  | 'github'
  | 'twitter'
  | 'designportfolio'
  | 'role'
  | 'tag'
  | 'assignment'
  | 'attachment'
  | 'status';

export const KnownColumnLabels = {
  none: 'None',
  name: 'Name',
  firstname: 'First Name',
  lastname: 'Last Name',
  linkedin: 'LinkedIn',
  email: 'Email (auto)',
  workEmail: 'Work Email',
  personalEmail: 'Personal Email',
  title: 'Title',
  location: 'Location',
  phone: 'Phone',
  comment: 'Comment',
  group1: 'Group #1',
  group2: 'Group #2',
  status: 'Project Status',
  assignment: 'Project Owner',
  otoStart: 'Open to Opps Start',
  note: 'New Profile Note',
  twitter: 'Link To Twitter',
  github: 'Link To Github',
  designportfolio: 'Link to portfolio',
  role: 'Role',
  tag: 'Tags',
  attachment: 'Resume URL',
};

export const KnownHeaders: { [str: string]: KnownColumns } = {
  name: 'name',
  'person - name': 'name',
  first: 'firstname',
  'first name': 'firstname',
  'last name': 'lastname',
  last: 'lastname',
  link: 'linkedin',
  'linkedin url': 'linkedin',
  'linkedin profile': 'linkedin',
  'person - linkedin url': 'linkedin',
  person_linkedin: 'linkedin',
  linkedin: 'linkedin',
  'linkedin identifier': 'linkedin',
  'public url': 'linkedin',
  identifier: 'linkedin',
  title: 'title',
  'current company - title': 'title',
  occupation: 'title',
  bio: 'title',
  location: 'location',
  email: 'email',
  'work email': 'workEmail',
  'personal email': 'personalEmail',
  'person - email': 'email',
  emails: 'email',
  'email address': 'email',
  'email addresses': 'email',
  phone: 'phone',
  phones: 'phone',
  'phone number': 'phone',
  'phone numbers': 'phone',
  comment: 'comment',
  note: 'note',
  notes: 'note',
  'profile note': 'note',
  github: 'github',
  'team to track': 'group1',
  'investor status': 'group1',
  group: 'group1',
  grouping: 'group1',
  group1: 'group1',
  'timeline to found': 'group2',
  group2: 'group2',
  'github URL': 'github',
  'github link': 'github',
  'github profile': 'github',
  twitter: 'twitter',
  'twitter URL': 'twitter',
  'twitter link': 'twitter',
  'person - twitter url': 'twitter',
  designs: 'designportfolio',
  'design portfolio': 'designportfolio',
  portfolio: 'designportfolio',
  role: 'role',
  tags: 'tag',
  tag: 'tag',
  status: 'status',
  owner: 'assignment',
  assignment: 'assignment',
  assigned: 'assignment',
  attachment: 'attachment',
  attachments: 'attachment',
  resume: 'attachment',
  resumes: 'attachment',
};

const { WARNING_TINT } = Colors.Static;
const PROFILE_NOTE_EXPLANATION = `A column in this file has been mapped to "New Profile Note". This will create a new profile note on the profile of each person imported. If you want to add a comment to the profile on the project, change that column to "Comment".`;

export const CSVImporter: React.FunctionComponent<{
  allowedFields: KnownColumns[];
  onFilePicked?: (fileName: string) => void;
  onSubmit?: (chosenColumns: KnownColumns[]) => Promise<boolean>; //return false to stop, true to continue
  onAddItem: (item: ProjectAPI.UpsertMemberRequest) => Promise<boolean>;
  onFinish: () => void;
  onError?: () => void;
  refresh?: () => void;
  customValidator?: (
    item: Partial<ProjectAPI.UpsertMemberRequest>,
    idx: number
  ) => string | undefined;
}> = ({
  allowedFields: defaultFields,
  onAddItem,
  onFinish,
  onError,
  refresh,
  customValidator,
  onFilePicked,
  onSubmit,
}) => {
  const [hasChosenFile, setHasChosenFile] = React.useState(false);
  const [csvData, setCSVData] = React.useState<CSVItem[]>([]);
  const [headerMapping, setHeaderMapping] = React.useState<HeaderMap>({});
  const [importProgress, setImportProgress] = React.useState<number | null>(null);
  const [headerRow, setHeaderRow] = React.useState<number | 'none' | 'auto'>('auto');

  const csvRow1 = headerRow === 'auto' || headerRow === 'none' ? 0 : headerRow + 1;
  const csvHeaders = csvData.length ? Object.keys(csvData[0]) : [];
  const csvErrors: string[] = [];

  const csvDataValidated = csvData
    .slice(csvRow1)
    .map((row, idx) => {
      const result: Partial<ProjectAPI.UpsertMemberRequest> = {};
      let nameparts: string[] = [];

      for (const colKey of Object.keys(row)) {
        const mapping = headerMapping[colKey];
        const value = row[colKey]?.trim();
        if (!value || !mapping || mapping === 'none') {
          continue;
        }

        switch (mapping) {
          case 'linkedin': {
            const identifier = toLinkedinID(value);
            if (identifier.startsWith('AC') && identifier.length === 39) {
              csvErrors.push(
                `Row ${idx + csvRow1}: Invalid LinkedIn identifier "${value}" was ignored.`
              );
            } else {
              result.identifier = identifier;
            }
            break;
          }
          case 'name':
            nameparts = [value];
            break;
          case 'title':
            result.title = value;
            break;
          case 'location':
            result.location = value;
            break;
          case 'firstname':
            nameparts.unshift(value);
            break;
          case 'lastname':
            nameparts.push(value);
            break;
          case 'phone':
            result.phoneNumbers = value.split(/,/g).map(e => e.trim());
            break;
          case 'comment':
            result.comment = value;
            break;
          case 'group1':
            result.group1 = value;
            break;
          case 'group2':
            result.group2 = value;
            break;
          case 'assignment':
            result.assignment = value.trim();
            break;
          case 'status':
            result.status = value.trim();
            break;
          case 'email':
            result.emails = value
              .split(/,/g)
              .map(e => e.toLowerCase().trim())
              .filter(e => {
                const valid = isValidEmail(e);
                if (!valid) {
                  csvErrors.push(`Row ${idx + csvRow1}: Invalid email "${e}" was ignored.`);
                }
                return valid;
              });
            break;
          case 'workEmail':
            result.workEmails = value
              .split(/,/g)
              .map(e => e.toLowerCase().trim())
              .filter(e => {
                const valid = isValidEmail(e);
                if (!valid) {
                  csvErrors.push(`Row ${idx + csvRow1}: Invalid email "${e}" was ignored.`);
                }
                return valid;
              });
            break;
          case 'personalEmail':
            result.personalEmails = value
              .split(/,/g)
              .map(e => e.toLowerCase().trim())
              .filter(e => {
                const valid = isValidEmail(e);
                if (!valid) {
                  csvErrors.push(`Row ${idx + csvRow1}: Invalid email "${e}" was ignored.`);
                }
                return valid;
              });
            break;
          case 'otoStart': {
            const date = new Date(value);
            if (isNaN(date.getTime())) {
              csvErrors.push(`Row ${idx + csvRow1}: Invalid date "${value}" was ignored.`);
            } else {
              result.openToOpportunitiesStart = date;
            }
            break;
          }
          case 'twitter': {
            const formattedResult = isTwitterURL(value) ? value : `https://twitter.com/${value}`;
            result.links = result.links
              ? { ...result.links, twitter: formattedResult }
              : { twitter: formattedResult };
            break;
          }
          case 'github': {
            const formattedResult = isGithubURL(value) ? value : `https://github.com/${value}`;
            result.links = result.links
              ? { ...result.links, github: formattedResult }
              : { github: formattedResult };
            break;
          }
          case 'designportfolio': {
            result.links = result.links
              ? { ...result.links, designPortfolio: value }
              : { designPortfolio: value };
            break;
          }
          case 'role': {
            result.role = value;
            break;
          }
          case 'tag': {
            result.tags = value.split(/,/g).map(t => t.trim());
            break;
          }
          case 'attachment': {
            result.attachment = value;
            break;
          }
        }
      }
      if (nameparts.length) {
        // Sometimes there's a "First Name" and "Last Name" field, but SOME of the last names
        // appear in the first name column. As a final step, remove duplicate name components
        // so "Benjamin Usmani Usmani" becomes just "Benjamin Usmani"
        result.name = uniq(nameparts.join(' ').trim().split(' ')).join(' ');
      }
      return result;
    })
    .filter((r, idx) => {
      if (!r.identifier && !r.name) {
        csvErrors.push(
          `Row ${idx + csvRow1}: Missing linkedin identifier & name. This person will be skipped.`
        );
        return false;
      } else if (customValidator) {
        const errorMessage = customValidator(r, idx + csvRow1);
        if (errorMessage) {
          errorMessage && csvErrors.push(errorMessage);
          return false;
        }
      }
      return true;
    }) as ProjectAPI.UpsertMemberRequest[];

  const reprocessHeaderMapping = (rows: CSVItem[]) => {
    if (!rows.length) {
      setHeaderMapping({});
      setHeaderRow('none');
      return;
    }
    const rowIndexes = [0, 1, 2, 3, 4, 5, 6, 7, 8];

    const rowIdxPossibleHeaders = rowIndexes.map(rowIdx => {
      const headers = Object.entries(rows[rowIdx] || {});
      const mappings: HeaderMap = {};

      let found = 0;
      for (const [colIdx, value] of headers) {
        let mapping = KnownHeaders[value.trim().toLowerCase()] || 'none';
        if (defaultFields && !defaultFields.includes(mapping)) mapping = 'none';
        mappings[colIdx] = mapping;
        if (mapping !== 'none') {
          found += 1;
        }
      }
      return { rowIdx, found, mappings };
    });

    const bestCount = Math.max(...rowIdxPossibleHeaders.map(r => r.found));
    const bestRow = rowIdxPossibleHeaders.find(row => row.found === bestCount);
    if (bestCount === 0 || !bestRow) {
      setHeaderMapping({});
      setHeaderRow('none');
      return;
    }

    setHeaderRow(bestRow.rowIdx);
    setHeaderMapping(bestRow.mappings);
  };

  const onProcessFile = async (file: File | null) => {
    if (!file) return;

    const reader = new FileReader();
    reader.onload = async function (event: any) {
      if (!event.target) return;

      const separator =
        (event.target.result.match(/;/g) || []).length >
        (event.target.result.match(/,/g) || []).length
          ? ';'
          : ',';

      const options = {
        escape: '""',
        newline: '\r',
        headers: false,
      };

      let rows = await csv(event.target.result, { ...options, separator });
      if (rows.length === 1) {
        rows = await csv(event.target.result, { ...options, newline: '\n', separator });
      }

      rows = rows.map((row: { [key: string]: string }) => {
        const newRow: { [key: string]: string } = {};
        Object.keys(row).forEach(key => {
          newRow[key.trim()] =
            row[key].trim().startsWith(`"`) && row[key].trim().endsWith(`"`)
              ? row[key].trim().replace(/"/g, '')
              : row[key];
        });
        return newRow;
      });

      setCSVData(rows);
      reprocessHeaderMapping(rows);
    };
    reader.readAsText(file);
  };

  const onReceiveFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (!file) {
      setHasChosenFile(false);
      return;
    }
    setHasChosenFile(true);
    if (file.name.endsWith('.xlsx') || file.name.endsWith('.xls')) {
      window.alert(
        `${file.name} looks like an Excel document. Open it in Excel ` +
          'and save it as a CSV to upload it.'
      );
      return;
    }
    if (!file.name.endsWith('.csv')) {
      window.alert(
        `${file.name} doesn't look like a CSV file. We'll attempt to parse ` +
          `it but you may need to convert it to a CSV and try again.`
      );
    }

    setHeaderRow('auto');
    onFilePicked?.(file.name);
    await onProcessFile(file);
  };

  const onImport = async () => {
    if (onSubmit) {
      const shouldContinue = await onSubmit(Object.values(headerMapping));
      if (!shouldContinue) {
        return false;
      }
    }

    const erroredCSVData: CSVItem[] = [];
    let ii = 0;

    const importOne = async () => {
      const idx = ii++;
      const item = csvDataValidated[idx];
      if (!item) return false;

      setImportProgress(ii);
      try {
        const success = await onAddItem(item);
        if (!success) {
          throw new Error('Failed');
        }
      } catch (err) {
        erroredCSVData.push(csvData[idx]);
      }
      return true;
    };

    const importLoop = async () => {
      while (await importOne()) {
        //
      }
    };

    // Send three people at a time
    await Promise.all([importLoop(), importLoop(), importLoop()]);

    if (erroredCSVData.length > 0) {
      onError && onError();
      console.log('Errored data' + JSON.stringify(erroredCSVData));
      alert(
        `${erroredCSVData.length} rows could not be imported even though the data is acceptable. Click Start Import to try ` +
          `these rows again or review the data for issues.`
      );
      setImportProgress(null);
      setCSVData(erroredCSVData);
      return;
    }
    onFinish();
  };

  const warnOnLeave =
    csvDataValidated &&
    csvHeaders.length > 0 &&
    importProgress !== null &&
    importProgress < csvDataValidated.length;

  React.useEffect(() => {
    const onBeforeUnload = (e: BeforeUnloadEvent) => {
      refresh && refresh();
      if (warnOnLeave) {
        e.returnValue = 'o/';
      }
    };
    window.addEventListener('beforeunload', onBeforeUnload);
    return () => window.removeEventListener('beforeunload', onBeforeUnload);
  });

  const previewData = csvData.slice(csvRow1, csvRow1 + 10);
  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <input type="file" onChange={onReceiveFile} />
      </div>
      {previewData.length > 0 && (
        <>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <h3>{`Preview (${previewData.length} of ${csvData.length.toLocaleString()} rows)`}</h3>
            {previewData.length > 0 && (
              <Checkbox
                checked={headerRow !== 'none'}
                onChange={async change => {
                  const checked = change.target.checked;
                  if (checked) {
                    reprocessHeaderMapping(csvData);
                  } else {
                    setHeaderRow('none');
                    setHeaderMapping({});
                  }
                }}
              >
                Row {headerRow === 'auto' ? '?' : headerRow === 'none' ? 1 : headerRow + 1} is the
                header
              </Checkbox>
            )}
          </div>
          <div style={{ overflow: 'auto', flex: 1, maxHeight: 'calc(100vh - 375px)' }}>
            <div className="ant-table ant-table-small ant-table-bordered">
              <table className="ant-table-container">
                <thead className="ant-table-thead">
                  <tr>
                    {csvHeaders.map(h => (
                      <th className="ant-table-cell" key={h}>
                        <Select<KnownColumns>
                          style={{ width: 200 }}
                          onChange={value => setHeaderMapping({ ...headerMapping, [h]: value })}
                          value={headerMapping[h]}
                        >
                          {Object.entries(KnownColumnLabels)
                            .filter(([key]) => {
                              return !defaultFields || defaultFields.includes(key as KnownColumns);
                            })
                            .map(([key, label]) => (
                              <Select.Option value={key} key={key}>
                                {label}
                              </Select.Option>
                            ))}
                        </Select>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="ant-table-tbody">
                  {previewData.map((row, idx) => (
                    <tr key={idx} className="ant-table-row">
                      {csvHeaders.map(h => (
                        <td key={h}>{row[h]}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'start',
              marginTop: 10,
            }}
          >
            {csvErrors.length > 0 && (
              <div style={{ overflow: 'auto', flex: 1, color: WARNING_TINT, width: '100%' }}>
                <div style={{ marginBottom: 16, paddingTop: 8 }}>
                  {csvErrors.length} errors were encountered that may cause certain data to be
                  skipped. Please review these to confirm that omitting these cells is acceptable.
                </div>
                {csvErrors.map((error, idx) => (
                  <div key={idx}>{error}</div>
                ))}
              </div>
            )}
            <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
              <div style={{ color: '#999999', fontSize: 12, marginRight: 10 }}>
                {csvDataValidated.length} valid people will be added.
              </div>
              <div style={{ flex: 1 }} />
              {importProgress !== null && (
                <div
                  style={{ paddingRight: 10 }}
                >{`${importProgress} / ${csvDataValidated.length}`}</div>
              )}
              {csvHeaders.some(h => headerMapping[h] === 'note') ? (
                <Popconfirm
                  trigger={['click']}
                  title={<div style={{ width: 350 }}>{PROFILE_NOTE_EXPLANATION}</div>}
                  onConfirm={onImport}
                  placement="left"
                >
                  <Button type="primary" disabled={importProgress !== null}>
                    {importProgress ? `Importing...` : `Start Import`}
                  </Button>
                </Popconfirm>
              ) : (
                <Button type="primary" disabled={importProgress !== null} onClick={onImport}>
                  {importProgress ? `Importing...` : `Start Import`}
                </Button>
              )}
            </div>
          </div>
        </>
      )}
      {!!hasChosenFile && !csvData.length && (
        <ErrorBox
          error="No readable rows were found inside of this sheet. Please double check that it is a valid CSV
          with a single row of headers, and at least 1 row of data."
        />
      )}
      {csvHeaders.length === 0 && csvData.length > 0 && (
        <ErrorBox
          error="No headers were found in this sheet. Try deleting empty rows that appear at the top of
            the CSV and re-selecting the file above"
        />
      )}

      <Prompt
        when={warnOnLeave}
        message={`Are you sure you want to leave this page? People are still being imported.`}
      />
    </>
  );
};

//Note: These match domain-helper in backend's internal/src exactly
export function isGithubURL(inp?: string): boolean {
  if (!inp) {
    return false;
  }
  return /(^|https?:\/\/(www\.)?)github\.com\/[A-z0-9]+\/?$/i.test(inp);
}

export function isTwitterURL(inp?: string): boolean {
  if (!inp) {
    return false;
  }
  return /(^|https?:\/\/(www\.)?)twitter\.com\/[A-z0-9]+\/?$/i.test(inp);
}

const ErrorBox: React.FC<{ error: string }> = ({ error }) => {
  return (
    <div>
      <h3>Preview</h3>
      <div style={{ maxWidth: 700, color: WARNING_TINT }}>{error}</div>
    </div>
  );
};
