import { Modal } from 'antd';
import React, { useState } from 'react';
import { Colors } from '../../shared/Theme';
import { SeqButton } from '../Button';
import { InviteTeamInput } from '../InviteTeamInput';
import { CloseButton, DIALOG_MASK_STYLE } from '../Modal';
import { ExplanatoryText, H2Mono } from '../Text';

export const EditTeamModal: React.FC<{
  organization: OrganizationAPI.OrganizationWithUsers;
  refresh: () => void;
}> = ({ organization, refresh }) => {
  const [visible, setVisible] = useState<boolean>(false);

  return (
    <>
      <SeqButton onClick={() => setVisible(!visible)} intent="primary">
        Invite Team Members
      </SeqButton>
      <Modal
        open={visible}
        style={{ top: '7.5vh', bottom: '7.5vh' }}
        styles={{ mask: DIALOG_MASK_STYLE, body: { padding: 0 } }}
        width={900}
        footer={null}
        closable={false}
        title={null}
        onCancel={() => setVisible(!visible)}
      >
        <div
          style={{ position: 'relative' }}
          onKeyDown={e => {
            if (e.key === 'Escape') setVisible(!visible);
          }}
        >
          <CloseButton onClick={() => setVisible(!visible)} />
          <div style={{ padding: '45px' }}>
            <H2Mono style={{ marginTop: 0 }}>Invite Team Members</H2Mono>
            <ExplanatoryText style={{ color: Colors.Static.SEQUOIA_LIGHT_TEXT, marginBottom: 24 }}>
              Type an email address below to invite a new user to join {organization.name}.
              Invitations are sent via email, and members must join using the same email address the
              invitation is sent to.
            </ExplanatoryText>
            <InviteTeamInput
              organization={organization}
              team={organization.users}
              refresh={refresh}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};
