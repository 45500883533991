import { Popover, Switch } from 'antd';
import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { SeqButton } from '../components/Button';
import { Colors } from '../shared/Theme';

const { SEQUOIA_DARK_GRAY } = Colors.Static;

export interface MemberInviteInfo {
  sendInvite: boolean;
  isFounder: boolean;
}

export const InviteMemberAdminPopover: React.FC<{
  disabled: boolean;
  onCreateOrg: () => Promise<void>;
  inviteInfo: MemberInviteInfo;
  onChange: (newVal: MemberInviteInfo) => void;
}> = ({ disabled, onCreateOrg, inviteInfo, onChange }) => {
  const [popoverVisible, setPopoverVisible] = useState<boolean>(false);
  const { sendInvite, isFounder } = inviteInfo;

  const popoverContent = (
    <div
      style={{
        width: 180,
        color: SEQUOIA_DARK_GRAY,
        fontSize: 12,
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <PopoverRowTitle>Send Email Invite:</PopoverRowTitle>
        <Switch
          size="small"
          checked={sendInvite}
          onChange={e => onChange({ ...inviteInfo, sendInvite: e })}
        />
      </div>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <PopoverRowTitle> Is Founder:</PopoverRowTitle>
        <Switch
          size="small"
          checked={isFounder}
          onChange={e => onChange({ ...inviteInfo, isFounder: e })}
        />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          borderTop: `1px solid ${Colors.Static.LIGHT_GRAY5}`,
          paddingTop: 8,
        }}
      >
        <SeqButton style={{ marginLeft: 100 }} intent="primary" onClick={onCreateOrg}>
          Send
        </SeqButton>
      </div>
    </div>
  );

  const inviteButton = (
    <SeqButton style={{ marginLeft: 24 }} disabled={disabled} intent="primary">
      Invite
    </SeqButton>
  );

  return disabled ? (
    inviteButton
  ) : (
    <Popover
      trigger="click"
      content={popoverContent}
      placement="left"
      open={popoverVisible}
      onOpenChange={() => setPopoverVisible(v => !v)}
    >
      {inviteButton}
    </Popover>
  );
};

const PopoverRowTitle = styled.div`
  padding-right: 12px;
  white-space: nowrap;
`;
