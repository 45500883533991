import { CloseOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import React from 'react';
import { Colors } from '../Theme';
import {
  EditableExcludeRulesMap,
  EditableRuleIds,
  RuleEditorProps,
  ValidationInfo,
  invalid,
  onChangeWithValidation,
  useDescription,
  valid,
} from '../rule-editors/RuleEditorCommon';
import { EditorInput, Explanatory, HeaderRow, REGEX_EXPLANATION, Rule } from './RuleEditorCommon';

export const SummaryOrWorkMatchesKeywordEditor: React.FC<RuleEditorProps> = ({
  rule,
  onChange,
  style,
  title,
  ruleType,
}) => {
  const keywords = [...rule.parameters.slice(2)];
  if (keywords.every(word => !!word)) {
    keywords.push('');
  }
  const replaceParam = (value: string | null, index: number) => {
    if (value === null) {
      onChangeWithValidation(
        { ...rule, parameters: rule.parameters.filter((_, idx) => idx !== index) },
        onChange,
        validate
      );
    } else if (index < 2) {
      const replaced = [...rule.parameters];
      replaced[index] = value;
      onChangeWithValidation({ ...rule, parameters: replaced }, onChange, validate);
    } else {
      const replaced = [...rule.parameters];
      replaced[index] = value;
      onChangeWithValidation({ ...rule, parameters: replaced }, onChange, validate);
    }
  };

  const desc = useDescription(
    { rule, ruleType },
    {
      include:
        'Boost people with at least count X of the following keywords in their summary or work description in the past Y years:',
      exclude:
        'Exclude people with at least count X  of the following keywords in their summary or work description in the past Y years:',
      invert:
        'Person must have at least count X of the following keywords in their summary or work description in the past Y years to be included:',
    }
  );

  return (
    <div style={style}>
      {title && <HeaderRow label={title} onChange={onChange} rule={rule} />}
      <Explanatory>{desc}</Explanatory>
      <div style={{ marginLeft: 15, marginTop: 20 }}>
        <div
          style={{
            display: 'flex',
            marginTop: 25,
            marginBottom: 25,
            justifyContent: 'space-between',
          }}
        >
          <div>
            <div># of Keywords that Must Match (X)</div>
            <EditorInput
              value={rule.parameters[0]}
              onChange={e => replaceParam(e.currentTarget.value, 0)}
            />
          </div>
          <div style={{ width: 40 }} />
          <div>
            <div>Years (Y)</div>
            <EditorInput
              value={rule.parameters[1]}
              onChange={e => replaceParam(e.currentTarget.value, 1)}
            />
          </div>
        </div>
        {keywords.map((keyword, index) => (
          <div key={`keyword-${index}`} style={{ display: 'flex', alignItems: 'center' }}>
            <EditorInput
              placeholder={'Enter a keyword'}
              style={{ width: 380, marginBottom: 10 }}
              value={keyword}
              onChange={e => replaceParam(e.currentTarget.value || null, index + 2)}
            />
            {!!keyword && (
              <Button
                tabIndex={-1}
                style={{ color: Colors.Static.SEQUOIA_BRIGHT_RED }}
                onClick={() => replaceParam(null, index + 2)}
                type={'link'}
              >
                <CloseOutlined style={{ marginLeft: 20 }} />
              </Button>
            )}
            {index === 0 && (
              <Tooltip overlayStyle={{ minWidth: 500 }} overlay={REGEX_EXPLANATION}>
                <QuestionCircleOutlined
                  style={{ color: Colors.Static.SEQUOIA_LIGHT_TEXT, marginLeft: 5 }}
                />
              </Tooltip>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

const validate = (rule: Rule): ValidationInfo => {
  const ruleLabel = EditableExcludeRulesMap[rule.id as EditableRuleIds]?.label || '';
  if (
    !rule.parameters ||
    rule.parameters.length < 3 ||
    isNaN(Number(rule.parameters[0])) ||
    isNaN(Number(rule.parameters[1]))
  ) {
    return invalid(`${ruleLabel} requires at least a minimum number of keywords and years.`);
  }
  return valid();
};
