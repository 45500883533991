import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { CountWithIcon } from '../components/Common';
import { Select, SelectDivider, SelectProps } from '../components/selects/Select';
import { OrganizationContext } from '../contexts/Organization';
import { getRoleOptions } from '../Helpers';

const ROLE_SELECT_MANAGE_ID = -3;
export const ROLE_SELECT_ALL_ID = -2;
export const ROLE_SELECT_NONE_ID = -1;

export const PipelineRoleFilter: React.FC<{
  value: number | undefined;
  onChange: (value: number | undefined) => void;
  profiles: Pick<ExternalAPI.Profile, 'roleId'>[];
}> = ({ value, onChange, profiles }) => {
  const { id, roles } = useContext(OrganizationContext);
  const history = useHistory();

  const roleOptions: SelectProps['options'] = React.useMemo(
    () =>
      [
        { id: ROLE_SELECT_ALL_ID, name: 'All Roles' },
        { id: ROLE_SELECT_NONE_ID, name: 'No Assigned Role' },
        ...getRoleOptions(roles),
        SelectDivider,
        {
          id: ROLE_SELECT_MANAGE_ID,
          name: 'Manage Roles',
          customRender: () => (
            <div onClick={() => history.push(`/${id}/settings?tab=Roles`)}>Manage Roles…</div>
          ),
        },
      ].map(r => {
        if ('id' in r && r.id === ROLE_SELECT_MANAGE_ID) return r;
        else if ('options' in r) {
          return {
            ...r,
            options: r.options.map(o => ({
              ...o,
              extra: (
                <CountWithIcon
                  icon={{ src: '/icons/person-filled.svg', alt: 'person' }}
                  count={getRoleMatchCount(profiles, o.id)}
                  style={{ alignSelf: 'flex-end' }}
                />
              ),
            })),
          };
        } else
          return {
            ...r,
            extra: (
              <CountWithIcon
                icon={{ src: '/icons/person-filled.svg', alt: 'person' }}
                count={getRoleMatchCount(profiles, r.id)}
              />
            ),
          };
      }),
    [roles, profiles, id, history]
  );

  return (
    <Select
      selected={value || ROLE_SELECT_ALL_ID}
      onSelect={id => {
        if (id !== ROLE_SELECT_MANAGE_ID) {
          onChange(id);
        }
      }}
      options={roleOptions}
      optionsPlacement="bottomRight"
    />
  );
};

const getRoleMatchCount = (profiles: Pick<ExternalAPI.Profile, 'roleId'>[], roleId: number) => {
  switch (roleId) {
    case ROLE_SELECT_ALL_ID:
      return profiles.length;
    case ROLE_SELECT_NONE_ID:
      return profiles.filter(p => !p.roleId).length;
    default:
      return profiles.filter(p => p.roleId === roleId).length;
  }
};
