import { Tooltip } from 'antd';
import React, { CSSProperties, useState } from 'react';
import styled from 'styled-components/macro';
import { ColorFromInitials, Colors } from './Theme';

interface ProfileImageProps {
  src: string | null | undefined;
  width: number;
  height: number;
  rounded?: boolean;
  style?: CSSProperties;
}

export const ProfileImage: React.FunctionComponent<ProfileImageProps> = ({
  src,
  width,
  height,
  rounded,
  style,
}) => {
  const [reRender, setReRender] = useState(0);
  React.useEffect(() => {
    //CSS for size & scale breaks when background image is changed unless you force a re-render
    setReRender(r => r + 1);
  }, [setReRender, src]);

  return (
    <div
      key={`profile-image-render-${reRender}`}
      style={{
        width: width,
        height: height,
        background: `url(${
          src || 'https://network.sequoiacap.com/images/candidate-pic-empty.png'
        }) #ddd`,
        backgroundSize: 'cover',
        position: 'absolute',
        borderRadius: rounded ? width / 2 : 0,
        ...style,
      }}
    />
  );
};

export const InitialsView: React.FunctionComponent<{
  name: string;
  tooltipFullname?: boolean;
  style?: CSSProperties;
}> = ({ name, tooltipFullname, style }) => {
  const initialsReg = (name || '').match(/\b\w/g) || [];
  const initials = ((initialsReg.shift() || '') + (initialsReg.pop() || '')).toUpperCase();

  return (
    <Tooltip title={tooltipFullname ? name : undefined}>
      <InitialsDiv style={{ background: ColorFromInitials(name), ...style }}>
        <strong style={{ margin: 'auto' }}>{initials}</strong>
      </InitialsDiv>
    </Tooltip>
  );
};

export const InitialsOrProfileImage: React.FunctionComponent<{
  user: { name: string; profilePictureURL?: string | null };
  widthHeight: number;
  style?: CSSProperties;
}> = ({ user, widthHeight, style }) => {
  return user.profilePictureURL ? (
    <div style={{ width: widthHeight }}>
      <ProfileImage
        src={user.profilePictureURL}
        width={widthHeight}
        height={widthHeight}
        rounded={true}
        style={{ position: 'static', ...style }}
      />
    </div>
  ) : (
    <InitialsView
      name={user.name}
      style={{
        width: widthHeight,
        height: widthHeight,
        minWidth: widthHeight,
        fontSize: 12,
        fontFamily: 'Unica77',
        ...style,
      }}
    />
  );
};

const InitialsDiv = styled.div`
  height: 30px;
  width: 30px;
  border-radius: 50%;
  justify-content: center;
  align-content: center;
  color: ${Colors.Static.WHITE};
  text-align: center;
  user-select: none;
  display: flex;
`;
