import { ConfigProvider, notification } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import * as QueryString from 'query-string';
import React, { ReactNode, useContext, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { RouteComponentProps, useHistory, useLocation } from 'react-router';
import styled from 'styled-components';
import { AMPERSAND_USER_NAME, SEQUOIA_ORG_ID } from '../App';
import { SeqButton } from '../components/Button';
import { FooterRow, HeaderRow, LightTooltip, PageContainer } from '../components/Common';
import { FastTable } from '../components/FastTable';
import { DebouncedSearchInput } from '../components/Input';
import { Select } from '../components/selects/Select';
import { customSortableColumn } from '../components/SeqTable';
import { StarInput } from '../components/Star';
import { Body1Serif, H2Mono } from '../components/Text';
import { OrganizationContext } from '../contexts/Organization';
import { organizationIdFromRoute } from '../Helpers';
import { usePageTitle } from '../shared/Common';
import { pluralize } from '../shared/helpers';
import { getNameParts, nameTitleOrCompanyMatches } from '../shared/profile-helpers';
import { makeRequest, ResourceOps, useResource } from '../shared/Resource';
import { useIsMobileSize } from '../shared/ScreenWidthControl';
import { Colors, hexWithOpacity } from '../shared/Theme';
import { AddPersonDialog } from './components/AddPersonDialog';
import { RecommendationsHelpDialog } from './components/RecommendationsHelpDialog';
import {
  MinerProfileCompanyColumn,
  MinerProfileLocationColumn,
  MinerProfileNameColumn,
  MinerProfileTitleColumn,
} from './ReferralTableColumns';

const { BLACK5ALPHA, SEQUOIA_BLACK, SEQUOIA_GREEN, ORANGE5 } = Colors.Static;

const ExecOptions = [
  { id: 1, name: 'All Levels' },
  { id: 2, name: 'Exec' },
  { id: 3, name: 'Non-exec' },
];

interface FilterValueProps {
  searchText: string;
  exec: number;
}

export const SheetViewTracker: React.FunctionComponent<{
  sheet: NetworkMinerAPI.MinerSheetWithProfiles;
  put: (payload: NetworkMinerAPI.UpdateSheetRequest) => Promise<unknown>;
  applyLocalUpdates: (sheet: NetworkMinerAPI.MinerSheetWithProfiles) => void;
}> = ({ sheet, put, applyLocalUpdates }) => {
  const { me }: { me: ExternalAPI.UserWithCampaigns | undefined } = useContext(OrganizationContext); //ME IS OPTIONAL HERE, SingleUse page = no "me"
  const [reportedView, setReportedView] = useState(false);
  usePageTitle('Review Your Connections', false);
  useEffect(() => {
    if (me) {
      return; // user is logged in, not recipient
    }
    const markViewed = async (sheet: NetworkMinerAPI.MinerSheetWithProfiles) => {
      setReportedView(true);
      await put({ targetLastViewedAt: new Date() });
      applyLocalUpdates({ ...sheet, targetLastViewedAt: new Date() });
    };
    if (!reportedView && !!sheet) {
      markViewed(sheet);
    }
  }, [sheet, put, reportedView, applyLocalUpdates, me]);

  return <></>;
};

export const SendRefreshedMinerSheetSection: React.FunctionComponent<{
  sheetHash: string;
  organizationId: number;
}> = ({ sheetHash, organizationId }) => {
  const history = useHistory();
  const [sending, setSending] = React.useState<boolean>(false);

  const onClickSend = async () => {
    setSending(true);
    const sendResp = await makeRequest<{ success?: boolean; error?: string }>(
      `/api/${organizationId}/miner-sheets/${sheetHash}/resend`
    );
    setSending(false);
    if (sendResp.success) {
      history.push(`/${organizationId}/referrals/home`);
    } else if (sendResp.error) {
      notification.error({ message: sendResp.error });
    } else {
      notification.error({ message: 'Email failed to send, please try again.' });
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'white',
        padding: 24,
        gap: 16,
        margin: 'auto',
        width: 450,
        boxShadow: '0px 16px 32px rgba(140, 140, 140, .3)',
        textAlign: 'center',
      }}
    >
      <img alt="flash" src="/images/flash-02.svg" style={{ width: '60%' }} />
      <H2Mono>Expired</H2Mono>
      <Body1Serif>
        This link has expired, click below to receive a new link in your email.
      </Body1Serif>
      <SeqButton onClick={onClickSend}>{sending ? 'Sending' : 'Resend Link'}</SeqButton>
    </div>
  );
};

export const PageReferralsRecommendations = (
  props: RouteComponentProps<{
    sheetHash: string;
    sheetName?: string;
    organizationId: string;
  }>
) => {
  const location = useLocation();
  const organizationId = organizationIdFromRoute(props);
  usePageTitle('Review Your Connections', false);

  const { sheetHash } = props.match.params;

  const { preview, refresh } = QueryString.parse(location.search) as {
    preview?: string;
    refresh?: string;
  };

  const [sheet, sheetOps] = useResource<
    NetworkMinerAPI.MinerSheetWithProfiles,
    NetworkMinerAPI.UpdateSheetRequest
  >(
    `/api/${organizationId}/miner-sheets/${sheetHash || 'missing'}`,
    { includedOnly: true, refresh },
    { silent: true }
  );

  if (!sheet) {
    return <div />;
  }

  if ('error' in sheet && sheet.error === 'expired') {
    return <SendRefreshedMinerSheetSection sheetHash={sheetHash} organizationId={organizationId} />;
  }

  const showViewTracker = !preview && !('error' in sheet);

  return (
    <>
      {showViewTracker && (
        <SheetViewTracker
          sheet={sheet}
          applyLocalUpdates={sheetOps.applyLocalUpdates}
          put={sheetOps.put}
        />
      )}
      <PageReferralsRecommendationsWithData
        sheet={sheet}
        sheetOps={sheetOps}
        organizationId={organizationId}
        previewOnly={!!preview}
      />
    </>
  );
};

const PageReferralsRecommendationsWithData: React.FC<{
  sheet: NetworkMinerAPI.MinerSheetWithProfiles;
  sheetOps: ResourceOps<NetworkMinerAPI.MinerSheetWithProfiles, NetworkMinerAPI.UpdateSheetRequest>;
  organizationId: number;
  previewOnly: boolean;
}> = ({ sheet, sheetOps, organizationId, previewOnly }) => {
  const history = useHistory();

  const [userRequestedGoBack, setUserRequestedGoBack] = useState<boolean>(false);
  const [filterValues, setFilterValues] = useState<FilterValueProps>({ searchText: '', exec: 1 });
  const [addPersonDialogName, setAddPersonDialogName] = useState<string | null>(null);

  const isMobile = useIsMobileSize();
  const isSequoiaSheet = sheet.sender.organizationId === SEQUOIA_ORG_ID;
  const isAmpersandSheet = sheet.sender.name === AMPERSAND_USER_NAME;
  const isTotallyEmpty =
    sheet.profiles.every(p => p.endorsements.length === 0) && sheet.daringFounders.length === 0;

  const onCompleteSheet = async () => {
    sheet.targetCompletedAt = new Date();
    sheet.targetLastInteractedAt = new Date();
    sheetOps.applyLocalUpdates({ ...sheet });
    setUserRequestedGoBack(false);
    void sheetOps.put(sheet);
  };

  const onFinish = async () => {
    if (previewOnly) {
      if (isSequoiaSheet) {
        window.close(); //Linked here from a 'new tab' out of internal dashboard
        return;
      }
      if (sheet.sentAt) {
        history.push(`/${organizationId}/referrals`);
      } else {
        history.push(`/${organizationId}/referrals/sheet/${sheet.hash}/email/${sheet.target.name}`);
      }
      return;
    }

    await onCompleteSheet();
  };

  const renderOnEmpty = (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 16 }}>
      Sorry, there aren't any matches.
      <SeqButton
        disabled={previewOnly}
        onClick={() => setAddPersonDialogName(filterValues.searchText)}
        intent="primary"
      >
        Add A Person
      </SeqButton>
    </div>
  );

  const filteredProfiles = React.useMemo(() => {
    const includedExternalIds = sheet.includedProfiles.map(p => p.externalId);
    const searchLower = filterValues.searchText.toLowerCase();

    return sheet.profiles.filter((profile: NetworkMinerAPI.MinerProfileSummary) => {
      // Only show endorsed folks on completed sheets
      if (
        sheet.targetCompletedAt &&
        !userRequestedGoBack &&
        !profile.endorsements.some(e => (e.comment || e.score) && e.owner === sheet.targetId)
      ) {
        return false;
      }

      // Only show folks selected during filtering
      if (!includedExternalIds.includes(profile.id)) {
        return false;
      }

      // Only show folks matching the exec selector
      const isExec = profile.functions.includes('Executive');
      if (filterValues.exec === 2 && !isExec) {
        return false;
      }
      if (filterValues.exec === 3 && isExec) {
        return false;
      }

      // Only show folks matching the name/title/company search

      return !searchLower || nameTitleOrCompanyMatches(profile, searchLower);
    });
  }, [sheet, filterValues, userRequestedGoBack]);

  const onUpdateEndorsement = async (
    profileId: number,
    changes: Partial<ExternalAPI.Endorsement>
  ) => {
    const profile = sheet.profiles.find(p => p.id === profileId)!;
    const endorsement = profile.endorsements[0] || buildEmptyEndorsement(sheet.targetId);
    const endorsementUpdated = { ...endorsement, ...changes };
    const profileUpdated = { ...profile, endorsements: [endorsementUpdated] };

    // Apply the changes locally so the user sees instant confirmation
    // and also so we can immediately handle another change to the same row.
    // On the backend, PUT + reload can take ~600ms+
    sheetOps.applyLocalUpdates({
      ...sheet,
      profiles: sheet.profiles.map(p => (p === profile ? profileUpdated : p)),
    });

    // Send the updated data - this endpoint will merge this endorsement with others from
    // other senders, and sending empty (no comment, no score) will remove the endorsement
    await makeRequest(`/api/${organizationId}/miner-sheet/${sheet.hash}/profile`, 'PUT', {
      profile: { ...profile, endorsements: [endorsementUpdated] },
    });
  };

  const endorsementCount = sheet.profiles.filter(p =>
    p.endorsements.some(endorsement => !!endorsement.score || !!endorsement.comment)
  ).length;

  return (
    <PageContainer
      style={{ paddingRight: 24, width: '100%', display: 'flex', flexDirection: 'column' }}
    >
      <AddPersonDialog
        sheet={sheet}
        onClose={async added => {
          added && (await sheetOps.refresh());
          setAddPersonDialogName(null);
        }}
        profileSeed={addPersonDialogName !== null ? { name: addPersonDialogName } : undefined}
      />
      {!previewOnly &&
        (isMobile ? (
          <div style={{ height: 32 }} />
        ) : (
          <SheetTitle>
            Recommendations for{' '}
            {organizationId === SEQUOIA_ORG_ID
              ? 'Sequoia Capital'
              : getNameParts(sheet.sender.name).first}
          </SheetTitle>
        ))}
      <RecommendationsHelpDialog
        isAmpersandSheet={isAmpersandSheet}
        isSequoiaSheet={isSequoiaSheet}
        name={getNameParts(sheet.target.name).first}
      />
      {(sheet.targetCompletedAt || sheet.locked) && !userRequestedGoBack && !previewOnly ? (
        <ThanksSection onGoBack={sheet.locked ? undefined : () => setUserRequestedGoBack(true)} />
      ) : (
        <>
          {previewOnly && !!sheet.sentAt && !sheet.targetCompletedAt && (
            <MessageContainer
              style={{
                background: hexWithOpacity(SEQUOIA_GREEN, 0.4),
                marginBottom: 8,
                marginTop: 30,
              }}
            >
              {sheet.target.name} was sent an email with a link to this sheet, but has not yet
              marked it complete.
            </MessageContainer>
          )}
          {!previewOnly && (
            <>
              {!isMobile && (
                <TipsAndTricks
                  isSequoiaSheet={isSequoiaSheet}
                  isAmpersandSheet={isAmpersandSheet}
                />
              )}
              <HeaderRow style={{ width: '100%' }}>
                <DebouncedSearchInput
                  placeholder={'Search for a person or keywords'}
                  value={filterValues.searchText}
                  onChange={e => setFilterValues({ ...filterValues, searchText: e })}
                />
                {!isMobile && (
                  <Select
                    optionsPlacement="bottomRight"
                    placeholder={'ALL LEVELS'}
                    selected={filterValues.exec}
                    options={ExecOptions}
                    onSelect={exec => exec && setFilterValues({ ...filterValues, exec })}
                  />
                )}
              </HeaderRow>
            </>
          )}
          <RecommendationsTable
            sheet={sheet}
            isSequoiaSheet={isSequoiaSheet}
            blockEditing={previewOnly}
            onUpdateEndorsement={onUpdateEndorsement}
            profiles={filteredProfiles}
            renderOnEmpty={renderOnEmpty}
            sheetOps={sheetOps}
          />
          {(!sheet.targetCompletedAt || userRequestedGoBack) && (
            <FooterRow>
              <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                {!isMobile && (
                  <>
                    <span style={{ fontWeight: 500 }}>
                      Currently: {pluralize(endorsementCount, 'recommendation')}
                    </span>
                    <div style={{ color: BLACK5ALPHA, marginTop: 5 }}>
                      We suggest recommending about 5-10 of the top people that come to mind.
                    </div>
                  </>
                )}
              </div>
              <SeqButton disabled={previewOnly} onClick={() => setAddPersonDialogName('')}>
                Anyone missing?
              </SeqButton>
              <SeqButton disabled={isTotallyEmpty} onClick={onFinish} intent="primary">
                {previewOnly ? 'Done Previewing' : 'Finish'}
              </SeqButton>
            </FooterRow>
          )}
        </>
      )}
    </PageContainer>
  );
};

const MessageContainer = styled.div`
  color: ${SEQUOIA_BLACK};
  display: flex;
  padding: 12px 24px;
  width: 100%;
  user-select: none;
`;

const StyledTips = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 2px;
`;

const StyledInstruction = styled.div`
  margin: 12px;
  margin-top: -3px;
  max-width: 580px;
`;

export const buildEmptyEndorsement = (ownerId: number): ExternalAPI.Endorsement => {
  return {
    comment: '',
    score: '',
    createdAt: new Date(),
    owner: ownerId,
    reviewed: false,
    synced: false,
  };
};

const RecommendationsTable: React.FunctionComponent<{
  sheet: NetworkMinerAPI.MinerSheetWithProfiles;
  blockEditing?: boolean;
  isSequoiaSheet: boolean;
  profiles: NetworkMinerAPI.MinerProfileSummary[];
  onUpdateEndorsement: (
    profileId: number,
    endorsement: Partial<ExternalAPI.Endorsement>
  ) => Promise<void>;
  sheetOps: ResourceOps<NetworkMinerAPI.MinerSheetWithProfiles, NetworkMinerAPI.UpdateSheetRequest>;
  renderOnEmpty: ReactNode;
}> = ({
  sheet,
  sheetOps,
  isSequoiaSheet,
  blockEditing,
  profiles,
  onUpdateEndorsement,
  renderOnEmpty,
}) => {
  const isMobile = useIsMobileSize();
  const [editingProfileSuperpower, setEditingProfileSuperpower] = useState<number>();

  const onPossibleFounderUpdate = async (profileId: number) => {
    const rmProfile = sheet.daringFounders.find(f => f.externalId === profileId);
    const daringFounders: NetworkMinerAPI.ProfileIds[] = rmProfile
      ? sheet.daringFounders.filter(p => p.externalId !== profileId)
      : [...sheet.daringFounders, { externalId: profileId }];

    sheetOps.applyLocalUpdates({ ...sheet, daringFounders });
    if (!isMobile && !rmProfile) {
      setEditingProfileSuperpower(profileId);
    }
    (await sheetOps.put({ daringFounders })) as NetworkMinerAPI.MinerSheet;
  };

  let columns: ColumnsType<NetworkMinerAPI.MinerProfileSummary>;
  const StarColumnContent = (
    <div
      style={{
        display: 'inline-flex',
        alignItems: 'end',
        fontSize: isMobile ? 24 : 20,
        marginRight: 2,
      }}
    >
      ★
      <LightTooltip
        placement="topLeft"
        overlay={
          <>
            <div>★★: Top 1%</div>
            <div>★: Exceptional in the right situation</div>
          </>
        }
      >
        <img alt="info" src="/icons/info.svg" style={{ opacity: 0.7, marginLeft: 5 }} />
      </LightTooltip>
    </div>
  );

  columns = [
    {
      key: 'star',
      width: 64,
      title: isMobile
        ? StarColumnContent
        : customSortableColumn<ExternalAPI.Profile>('star', StarColumnContent),
      defaultSortOrder: !!sheet.targetCompletedAt ? 'ascend' : undefined,
      sorter: (
        p1: NetworkMinerAPI.MinerProfileSummary,
        p2: NetworkMinerAPI.MinerProfileSummary
      ) => {
        const endorsementDiff = p2.endorsements.length - p1.endorsements.length;
        if (endorsementDiff || (p2.endorsements.length === 0 && p1.endorsements.length === 0)) {
          return endorsementDiff;
        }
        if (!p1.endorsements[0].score) {
          return 1;
        }
        if (!p2.endorsements[0].score) {
          return -1;
        }

        return p1.endorsements[0].score.localeCompare(p2.endorsements[0].score);
      },
      render: (_, profile) => {
        return (
          <StarInput
            disabled={blockEditing}
            value={profile.endorsements[0]?.score || ''}
            onChange={score => onUpdateEndorsement(profile.id, { score })}
            style={{ fontSize: isMobile ? 24 : 20 }}
          />
        );
      },
    },
  ];

  if (isSequoiaSheet) {
    columns.unshift({
      title: (
        <>
          <img
            alt="unicorn"
            src="/icons/unicorn.svg"
            style={{ width: 18, filter: 'saturate(0) brightness(0.7)' }}
          />
          <DaringFounderTooltip />
        </>
      ),
      width: 52,
      render: (profile: NetworkMinerAPI.MinerProfileSummary) => {
        const selected = sheet.daringFounders.some(p => p.externalId === profile.id);
        return (
          <PotentialFounderInput
            disabled={blockEditing}
            value={selected}
            onChange={() => onPossibleFounderUpdate(profile.id)}
          />
        );
      },
    });
  }

  if (!isMobile) {
    columns = [
      ...columns,
      {
        title: (
          <div style={{ display: 'flex', alignItems: 'flex-start' }}>
            SUPERPOWER
            <LightTooltip overlay="What makes this person exceptional at what they do?">
              <img alt="info" src="/icons/info.svg" style={{ opacity: 0.7, marginLeft: 6 }} />
            </LightTooltip>
          </div>
        ),
        key: 'superpower',
        width: 300,
        render: (_, profile) => {
          const firstName = getNameParts(profile.name).first;
          return (
            <EditableInputCell
              disabled={blockEditing}
              value={profile.endorsements[0]?.comment || ''}
              onComplete={comment => onUpdateEndorsement(profile.id, { comment })}
              firstName={firstName}
              editing={editingProfileSuperpower === profile.id}
              setEditing={(editing: boolean) => {
                setEditingProfileSuperpower(editing ? profile.id : undefined);
              }}
            />
          );
        },
      },
      MinerProfileNameColumn,
      MinerProfileTitleColumn,
      MinerProfileCompanyColumn,
      MinerProfileLocationColumn,
    ];
  } else {
    columns = [...columns, MinerProfileNameColumn, MinerProfileTitleColumn];
  }

  return (
    <ConfigProvider renderEmpty={() => renderOnEmpty}>
      <FastTable
        rowKey={r => `${r.id}:${r.endorsements[0]?.comment}`}
        rowHeight="measure"
        dataSource={profiles}
        columns={columns}
        emptyProps={{
          description: renderOnEmpty,
          imgProps: { style: { maxHeight: '30vh' } },
          style: { padding: 36 },
        }}
      />
    </ConfigProvider>
  );
};

export const ThanksSection: React.FC<{
  onGoBack?: () => void;
}> = ({ onGoBack }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'white',
        padding: 24,
        gap: 16,
        margin: 'auto',
        width: 450,
        boxShadow: '0px 16px 32px rgba(140, 140, 140, .3)',
        textAlign: 'center',
      }}
    >
      <img alt="flash" src="/images/flash-02.svg" style={{ width: 241, height: 244 }} />
      <H2Mono>Thank you!</H2Mono>
      <Body1Serif>
        We really appreciate you taking the time to recommend folks in your network.
      </Body1Serif>
      <SeqButton onClick={onGoBack}>Wait, I forgot someone!</SeqButton>
    </div>
  );
};

const EditableInputCell: React.FunctionComponent<{
  firstName: string;
  value?: string;
  onComplete: (newVal: string) => void;
  disabled?: boolean;
  setEditing: (editing: boolean) => void;
  editing: boolean;
}> = ({ value, firstName, onComplete, disabled, editing, setEditing }) => {
  const [editableText, setEditableText] = useState<string>(value || '');
  const inputRef = useRef(undefined);

  const requestEdit = (e: React.MouseEvent<HTMLDivElement>) => {
    setEditing(true);
  };

  useEffect(() => {
    if (editing === true) {
      const el = inputRef.current
        ? (ReactDOM.findDOMNode(inputRef.current) as HTMLTextAreaElement)
        : undefined;
      if (!el) return;
      el.focus();
      el.selectionStart = el.value.length;
    }
  }, [editing]);

  return (
    <div style={{ position: 'relative' }}>
      <EditableInputValueDiv isPlaceholder={!value} onClick={requestEdit}>
        {value || 'Click to type...'}
      </EditableInputValueDiv>
      {editing && !disabled && (
        <RecommendationCommentInput
          ref={inputRef as any}
          value={editableText}
          placeholder={`What is ${firstName} great at?`}
          onKeyDown={e => {
            if (e.key === 'Enter' || e.key === 'Return' || e.key === 'Escape') {
              e.currentTarget.blur();
              e.preventDefault();
            }
          }}
          onChange={e => {
            setEditableText(e.currentTarget.value);
          }}
          onBlur={() => {
            setEditing(false);
            onComplete(editableText);
          }}
        />
      )}
    </div>
  );
};

export const DaringFounderTooltip: React.FC = () => (
  <LightTooltip
    placement="topLeft"
    overlay="Daring founder who will (or has) started a special company. We're always looking to meet potential founders early & would love your recommendations."
  >
    <img alt="info" src="/icons/info.svg" style={{ opacity: 0.7, marginLeft: 5 }} />
  </LightTooltip>
);

const RecommendationCommentInput = styled.textarea`
  color: ${SEQUOIA_BLACK};
  line-height: 22px;
  resize: none;
  padding-left: 4px;
  margin-left: -4px;
  position: absolute;
  display: inline-block;
  border: none;
  width: 100%;
  bottom: 2px;
  overflow: hidden;
  top: 0;
  word-break: break-word;
  &:focus {
    outline: 1px solid #dddddd;
    box-shadow: none;
  }
  &:hover {
    outline: 1px solid #dddddd;
  }
`;

const EditableInputValueDiv = styled.div<{ isPlaceholder: boolean }>`
  color: ${({ isPlaceholder }) => (isPlaceholder ? '#c8c8c8' : SEQUOIA_BLACK)};
  line-height: 22px;
  padding: 2px 5px 2px 0;
  word-break: break-word;
`;

const SheetTitle = styled.div`
  font-size: 24px;
  margin: 0 0 24px 184px;
  color: ${SEQUOIA_BLACK};
  user-select: none;
`;

const TipsAndTricks: React.FC<{
  isSequoiaSheet: boolean;
  isAmpersandSheet?: boolean;
}> = ({ isSequoiaSheet, isAmpersandSheet }) => (
  <MessageContainer style={{ marginBottom: 24, background: hexWithOpacity(ORANGE5, 0.2) }}>
    <div style={{ margin: '16px 0', width: 250 }}>
      Here are a couple of tips on how to best use this tool:
    </div>
    <div style={{ display: 'flex', margin: '16px 0 0 40px' }}>
      <StyledTips>
        <img alt="Keyboard" src="/icons/keyboard.svg" />
        <StyledInstruction>
          For the star rating, one star represents exceptional people in the right situation and two
          stars represent people in your top 1%.
        </StyledInstruction>
      </StyledTips>
      {(isSequoiaSheet || isAmpersandSheet) && (
        <StyledTips>
          <img
            alt="unicorn"
            src="/icons/unicorn.svg"
            style={{ width: 20, filter: 'saturate(0) brightness(0.7)' }}
          />
          <StyledInstruction>
            Daring founder who has or will start a special company? Check the Sequoia icon. We're
            always looking to meet potential founders early.
          </StyledInstruction>
        </StyledTips>
      )}
      <StyledTips>
        <img alt="Text icon" src="/icons/text-icon.svg" />
        <StyledInstruction>The more info and context you write, the better.</StyledInstruction>
      </StyledTips>
    </div>
  </MessageContainer>
);

const PotentialFounderInput: React.FC<{
  value: boolean;
  onChange: () => void;
  disabled?: boolean;
}> = ({ disabled, value, onChange }) => {
  return (
    <PotentialFounderInputContainer disabled={disabled} onClick={onChange}>
      <img
        alt="unicorn"
        src={value ? '/icons/unicorn.svg' : '/icons/gray-unicorn.svg'}
        style={{ width: 18 }}
      />
    </PotentialFounderInputContainer>
  );
};

const PotentialFounderInputContainer = styled.button`
  border: none;
  padding: 8px;
  margin: -8px;
  margin-top: -5px;
  background: transparent;
  line-height: 0;

  ${p =>
    !p.disabled &&
    `
  cursor: pointer;
  &:hover {
    filter: brightness(80%);
  }`}
`;
