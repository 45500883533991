import { CommentOutlined, LoadingOutlined } from '@ant-design/icons';
import { Popover, Spin } from 'antd';
import { intersection, uniqBy } from 'lodash';
import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router';
import { SeqButton } from '../components/Button';
import { CountWithIcon, FooterRow, HeaderRow, PageContainer } from '../components/Common';
import { DebouncedSearchInput } from '../components/Input';
import { MultiProfileUpdateButtonGroup } from '../components/MultiProfileUpdateButtonGroup';
import { MultiSelect } from '../components/selects/MultiSelect';
import { IconContainer, SettingsIcon } from '../components/Svgs';
import { OrganizationContext } from '../contexts/Organization';
import { logPageView } from '../Helpers';
import { useBulkEmailModal } from '../hooks/useBulkEmailModal';
import { useCandidateDrawer } from '../hooks/useProfileDrawer';
import { useProfileTableConfig } from '../hooks/useProfileTableConfig';
import { useRelatedProfiles } from '../hooks/useRelatedProfiles';
import { ClickableItem, usePageTitle } from '../shared/Common';
import { addToDictCount, getNonDefault, prefixMatch } from '../shared/helpers';
import { MinimalExtProfile, nameTitleOrCompanyMatches } from '../shared/profile-helpers';
import { useResource } from '../shared/Resource';
import { Colors } from '../shared/Theme';
import { useQueryPersistedState } from '../shared/useQueryPersistedState';
import { AddCandidatesPopover } from './AddCandidatesPopover';
import { PipelineCRMView } from './PipelineCRMView';
import { OWNER_SELECT_NONE_ID, PipelineOwnerFilter } from './PipelineOwnerFilter';
import { PipelineRoleFilter, ROLE_SELECT_ALL_ID, ROLE_SELECT_NONE_ID } from './PipelineRoleFilter';

const { SEQUOIA_GREEN } = Colors.Static;

interface CandidatesFilters {
  roleId?: number;
  status?: string;
  ownerId?: number;
  tags?: string[];
  searchTerm: string;
}

const EMPTY_CANDIDATE_FILTER: CandidatesFilters = {
  roleId: undefined,
  status: undefined,
  ownerId: undefined,
  searchTerm: '',
};

export const NETWORK_SEARCH_PLACEHOLDER = 'Search by name, location, tags or keywords';

// Important: Using a /specific/ empty array as the empty state allows us to use `profiles`
// as a React.useEffect dependency. Without this, we're actually defaulting to a different
// empty array on each render, and the arrays are never "==="
const EMPTY: NetworkAPI.NetworkProfile[] = [];

export const PagePipeline = () => {
  const { me, id, all } = useContext(OrganizationContext);
  const history = useHistory();

  usePageTitle('Pipeline', false, { all, id });

  const [profiles = EMPTY, { refresh, applyLocalUpdates }] = useResource<
    NetworkAPI.NetworkProfile[]
  >(`/api/${id}/profiles`);
  const [filterValues, setFilterValues] = useQueryPersistedState<CandidatesFilters>({
    encode: search => encodePipelineFilters(search),
    decode: qs => ({ ...EMPTY_CANDIDATE_FILTER, ...(qs.q ? JSON.parse(qs.q) : {}) }),
  });
  const { getRelatedProfileName } = useRelatedProfiles(profiles);

  useEffect(() => {
    logPageView(me, 'pipeline');
  }, [me]);

  const { candidateDrawer, setDrawerCandidateId, onProfileSaved } = useCandidateDrawer({
    profiles,
    refreshProfiles: refresh,
    getRelatedProfileName,
    applyLocalUpdates: profiles => applyLocalUpdates(profiles as NetworkAPI.NetworkProfile[]),
  });

  const tagOptions = React.useMemo(() => {
    const tagCountDict = profiles.reduce((acc, p) => {
      [...p.functions, ...p.tags].forEach(t => addToDictCount(t, acc));
      return acc;
    }, {} as { string: number });

    return Object.entries(tagCountDict)
      .sort((a, b) => a[0].toLowerCase().localeCompare(b[0].toLowerCase()))
      .map(([tag, count]) => ({
        id: tag,
        name: tag,
        extra: (
          <CountWithIcon icon={{ src: '/icons/person-filled.svg', alt: 'person' }} count={count} />
        ),
      }));
  }, [profiles]);

  const profilesFiltered =
    filterValues.searchTerm.length ||
    filterValues.tags?.length ||
    filterValues.status ||
    filterValues.roleId ||
    filterValues.ownerId
      ? profiles.filter(p => {
          let result = true;
          const { roleId, ownerId, status, tags, searchTerm } = filterValues;

          if (roleId) {
            if (roleId === ROLE_SELECT_ALL_ID) {
              result = true;
            } else if (roleId === ROLE_SELECT_NONE_ID) {
              result = p.roleId === null;
            } else {
              result = p.roleId ? roleId === p.roleId : false;
            }
          }

          if (result && status) {
            result = status === p.status;
          }

          if (result && ownerId) {
            result = ownerId === OWNER_SELECT_NONE_ID ? !p.ownerId : p.ownerId === ownerId;
          }

          if (result && tags?.length) {
            const profileTags = [...p.functions, ...('tags' in p ? p.tags : [])];
            result = !!intersection(profileTags, tags).length;
          }

          if (result && searchTerm.length) {
            result =
              matchProfile(p, searchTerm) ||
              p.endorsements.some(e => prefixMatch(getRelatedProfileName(e.owner), searchTerm));
          }

          return result;
        })
      : profiles;

  const { checkedIds, actions } = useProfileTableConfig(profilesFiltered, () =>
    setDrawerCandidateId(0)
  );
  const bulkEmail = useBulkEmailModal(profilesFiltered, refresh, checkedIds);

  const onMultiProfileUpdate = (updatedProfiles: NetworkAPI.NetworkProfile[]) => {
    applyLocalUpdates(uniqBy([...updatedProfiles, ...profiles], 'id'));
  };

  return (
    <PageContainer>
      {bulkEmail.modal}
      <HeaderRow>
        <DebouncedSearchInput
          placeholder={NETWORK_SEARCH_PLACEHOLDER}
          value={filterValues.searchTerm}
          onChange={searchTerm => setFilterValues({ ...filterValues, searchTerm })}
        />
        <MultiSelect
          selected={filterValues.tags || []}
          options={tagOptions}
          onSelect={tag => {
            if (tag)
              setFilterValues({
                ...filterValues,
                tags: filterValues.tags?.includes(tag.name)
                  ? filterValues.tags.filter(r => r !== tag.name)
                  : [...(filterValues.tags || []), tag.name],
              });
          }}
          searchable
          placeholder="ALL TAGS"
        />
        <PipelineOwnerFilter
          profiles={profiles}
          value={filterValues.ownerId}
          onChange={ownerId => setFilterValues({ ...filterValues, ownerId })}
        />
        <PipelineRoleFilter
          profiles={profiles}
          value={filterValues.roleId}
          onChange={roleId => setFilterValues({ ...filterValues, roleId })}
        />
        <ClickableItem onClick={() => history.push(`/${id}/settings?tab=Stages`)}>
          <IconContainer>
            <SettingsIcon />
          </IconContainer>
        </ClickableItem>
      </HeaderRow>

      <PipelineCRMView
        isFiltering={!!filterValues.searchTerm.length || !!filterValues.roleId}
        setDrawerCandidateId={setDrawerCandidateId}
        profilesFiltered={profilesFiltered}
        checkedIds={checkedIds}
        actions={{
          onCheckOne: actions.onCheckOne,
          onCheckSome: actions.onCheckSome,
          onProfileSaved,
          onMultiProfileUpdate,
        }}
      />

      {candidateDrawer}

      <FooterRow style={{ marginTop: 'unset' }}>
        <AddCandidatesPopover
          config={{
            onAddedToPipeline: refresh,
            initialRoleId:
              filterValues?.roleId && filterValues.roleId > 0 ? filterValues.roleId : undefined,
          }}
        >
          <SeqButton>ADD CANDIDATES</SeqButton>
        </AddCandidatesPopover>
        {bulkEmail.button}
        <MultiProfileUpdateButtonGroup checkedIds={checkedIds} onSaved={onMultiProfileUpdate} />
        <div style={{ flex: 1 }} />
        <SeqButton
          onClick={() =>
            actions.onDownload(`/api/${id}/profiles/csv?`, checkedIds?.length === profiles.length)
          }
        >
          Export CSV ({checkedIds?.length || profiles.length})
        </SeqButton>
      </FooterRow>
    </PageContainer>
  );
};

const matchProfile = (
  profile: MinimalExtProfile & Pick<ExternalAPI.Profile, 'functions' | 'tags'>,
  searchTerm: string
) =>
  nameTitleOrCompanyMatches(profile, searchTerm) ||
  prefixMatch(profile.location, searchTerm) ||
  matchProfileTags(profile, searchTerm);

const matchProfileTags = (
  profile: Pick<ExternalAPI.Profile, 'functions' | 'tags'>,
  searchTerm: string
) => {
  const tags = [...profile.functions, ...('tags' in profile ? profile.tags : [])];
  return tags.some(t => prefixMatch(t, searchTerm));
};

export const Endorsement: React.FC<{
  endorsement: ExternalAPI.Endorsement;
  getProfileName: (profileId: number) => string;
}> = ({ endorsement, getProfileName }) => {
  const { owner, comment } = endorsement;
  return (
    <div style={{ paddingBottom: 4, whiteSpace: 'nowrap' }}>
      {getProfileName(owner) || (
        <Spin indicator={<LoadingOutlined style={{ fontSize: 16 }} spin />} />
      )}
      {comment && (
        <Popover placement="topLeft" content={<div style={{ fontSize: 12 }}>{comment}</div>}>
          <CommentOutlined style={{ color: SEQUOIA_GREEN, marginLeft: 6 }} />
        </Popover>
      )}
    </div>
  );
};

export function encodePipelineFilters(next: CandidatesFilters) {
  return { q: JSON.stringify(getNonDefault(next, EMPTY_CANDIDATE_FILTER)) };
}
