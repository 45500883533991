import { CommentOutlined } from '@ant-design/icons';
import React from 'react';
import styled from 'styled-components/macro';
import { LabelCSS } from '../components/Text';
import { getNameParts } from '../shared/profile-helpers';
import { SequoiaLogo } from '../shared/SequoiaLogo';
import { Colors } from '../shared/Theme';
import { Body1Serif } from './Text';

const { BLACK1ALPHA, BLACK7ALPHA, SEQUOIA_BLACK, SEQUOIA_GREEN } = Colors.Static;

export interface SourcerCommentProps {
  comment: string;
  author: ExternalAPI.User | null;
}

export const SourcerComment: React.FC<SourcerCommentProps> = ({ comment, author }) => {
  return (
    <Container>
      {!author?.internalTalentId ? (
        <CommentOutlined style={{ fontSize: 16, color: SEQUOIA_GREEN }} />
      ) : (
        <SequoiaLogo />
      )}
      <div style={{ whiteSpace: 'pre-wrap' }}>{comment}</div>
      {author && <CommentAuthor>- {getNameParts(author.name).first}</CommentAuthor>}
    </Container>
  );
};

const Container = styled(Body1Serif)`
  color: ${SEQUOIA_BLACK};
  background: ${BLACK1ALPHA};
  margin: 16px 0 16px 24px;
  padding: 12px;
  display: flex;
  align-items: baseline;
  gap: 12px;
`;

const CommentAuthor = styled.div`
  ${LabelCSS};
  color: ${BLACK7ALPHA};
  text-align: right;
  padding-right: 24px;
  white-space: nowrap;
  align-self: flex-end;
  line-height: 28px;
`;
