import { Popover, Radio, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { pluralize } from '../shared/helpers';
import { SeqButton } from './Button';
import { FOOTER_ROW_POPOVER_WIDTH, PopoverDescription } from './Popover';
import { H4Mono } from './Text';

interface Option {
  id: number;
  name: string;
}

export interface MultiProfileUpdateButtonProps {
  ids: number[];
  options: { id: number; name: string }[];
  updateType: 'status' | 'role';
  onUpdate: (update: ExternalAPI.MultiProfileUpdateReq['update']) => void;
  disabled: boolean;
}

export const MultiProfileUpdateButton: React.FC<MultiProfileUpdateButtonProps> = ({
  ids,
  options,
  updateType,
  onUpdate,
  disabled,
  children,
}) => {
  const [popoverVisible, setPopoverVisible] = useState<boolean>(false);
  const [selection, setSelection] = useState<Option | null>(null);

  useEffect(() => {
    if (!popoverVisible) {
      setSelection(null);
    }
  }, [popoverVisible, setSelection]);

  const onUpdateProfiles = () => {
    if (!selection) {
      return;
    }

    onUpdate(
      updateType === 'role'
        ? { type: 'role', roleId: selection.id, name: selection.name }
        : {
            type: 'status',
            status: selection.name as PipelineAPI.ProfileStatus,
            name: selection.name,
          }
    );
  };

  const PopoverContent = (
    <div style={{ width: FOOTER_ROW_POPOVER_WIDTH, padding: 16 }}>
      <H4Mono>Bulk Update Profile {updateType}</H4Mono>
      <div style={{ padding: '16px 0' }}>
        <PopoverDescription style={{ paddingBottom: 12 }}>
          Update to one of the following {updateType === 'role' ? 'roles' : 'statuses'} for{' '}
          {pluralize(ids.length, 'profile')}:
        </PopoverDescription>
        <Radio.Group
          value={options.find(o => selection && o.id === selection.id && o.name === selection.name)}
          onChange={e => {
            setSelection(e.target.value);
          }}
        >
          <Space direction="vertical">
            {options.map(r => (
              <Radio key={r.name} value={r}>
                {r.name}
              </Radio>
            ))}
          </Space>
        </Radio.Group>
      </div>
      <div style={{ display: 'flex' }}>
        <div style={{ flex: 1 }} />
        <SeqButton
          size="small"
          intent="primary"
          disabled={!selection}
          onClick={async () => {
            onUpdateProfiles();
            setPopoverVisible(false);
          }}
        >
          {`Update ${pluralize(ids.length, 'profile')}`}
        </SeqButton>
      </div>
    </div>
  );

  return (
    <Popover
      overlayClassName={'seq-no-padding'}
      trigger={'click'}
      content={PopoverContent}
      placement={'topLeft'}
      open={popoverVisible}
      onOpenChange={() => !disabled && setPopoverVisible(v => !v)}
    >
      <SeqButton disabled={disabled}>{children}</SeqButton>
    </Popover>
  );
};
