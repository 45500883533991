import { default as React } from 'react';
import { EditVariablesTable } from '../components/EditVariablesTable';
import { ExplanatoryText } from '../components/Text';
import { OrganizationContext } from '../contexts/Organization';

export const VariablesTab: React.FunctionComponent = () => {
  const { me } = React.useContext(OrganizationContext);
  return (
    <>
      <ExplanatoryText style={{ marginBottom: 24, maxWidth: 900 }}>
        Variables allow you to easily personalize email messaging. They can be used in email
        campaigns and are accessible via a dropdown when you are customizing an email. Some
        variables, like{' '}
        <code>
          <strong>company</strong>
        </code>
        , allow you to include details about the candidate.
        <br />
        <br />
        Showing variables for <strong>{me.email}</strong> - changes you make here will only impact
        email campaigns sent from your email account.
      </ExplanatoryText>
      <EditVariablesTable />
    </>
  );
};
