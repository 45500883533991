import { useCallback, useEffect, useRef } from 'react';
import { makeRequest } from '../shared/Resource';

interface IntervalProfileUpdate<T> {
  orgId: number;
  profile: T;
  onProfileSaved: (profile: T) => void;
}

export function useIntervalForProfileUpdate<T extends ExternalAPI.Profile>({
  orgId,
  profile,
  onProfileSaved,
}: IntervalProfileUpdate<T>) {
  const { email, details, identifier } = profile;

  const onProfileSavedRef = useRef(onProfileSaved);
  onProfileSavedRef.current = onProfileSaved;

  const loadProfileUpdates = useCallback(async () => {
    const newProfile = await makeRequest<T>(`/api/${orgId}/profile/${identifier}`);
    const hasUpdate =
      email?.toLowerCase() !== newProfile.email?.toLowerCase() ||
      (hasPendingProfileDetails(details) && !hasPendingProfileDetails(newProfile.details));

    if (hasUpdate) {
      onProfileSavedRef.current(newProfile);
    }
  }, [email, details, identifier, onProfileSavedRef, orgId]);

  useEffect(() => {
    if (
      profile.id > 0 &&
      (email === 'pending' || hasPendingProfileDetails(details) || !details?.lirProfileUnavailable)
    ) {
      const interval = setInterval(loadProfileUpdates, 10000);
      return () => clearInterval(interval);
    }
  }, [email, details, loadProfileUpdates, profile.id]);

  return loadProfileUpdates;
}

const hasPendingProfileDetails = (details?: CoreAPI.ProfileDetails) =>
  details?.lirProfileUnavailable || (!details?.work && !details?.education);
