export const SOFTWARE_DATASCIENCE_CATEGORIES: string[] = [
  'software',
  'software engineering',
  'mobile software and services',
  'internet',
  'information technology',
  'enterprise saas',
  'apps',
];

export const COMMON_DATASCIENCE_CATEGORIES: string[] = [
  'healthcare',
  'hardware',
  'data and analytics',
  'science and engineering',
  'fintech',
  'health & wellness',
  'mobile',
  'ai/ml',
  'apps',
  'payments',
  'security',
  'privacy & security',
  'digital health',
  'consumer products and services',
  'crypto',
  'cybersecurity',
  'big data',
  'robotics',
  'adtech',
  'developer tools',
  'ar/vr',
  'network security',
  'mobile payments',
  'digital lending',
  'healthcare software',
  'data visualization',
];

export const DATASCIENCE_CATEGORY_LABELS: { [key: string]: string } = {
  software: 'Software',
  'software engineering': 'Software Engineering',
  'mobile software and services': 'Mobile Services',
  internet: 'Internet',
  'information technology': 'IT',
  'enterprise saas': 'Enterprise SaaS',
  apps: 'Apps',
  healthcare: 'Healthcare',
  hardware: 'Hardware',
  'data and analytics': 'Data & Analytics',
  'science and engineering': 'Science & Engineering',
  fintech: 'Fintech',
  'health & wellness': 'Health & Wellness',
  mobile: 'Mobile',
  'ai/ml': 'AI/ML',
  payments: 'Payments',
  security: 'Security',
  'privacy & security': 'Privacy',
  'digital health': 'Digital Health',
  'consumer products and services': 'Consumer',
  crypto: 'Crypto',
  cybersecurity: 'Cybersecurity',
  'big data': 'Big Data',
  robotics: 'Robotics',
  adtech: 'Adtech',
  'developer tools': 'Developer Tools',
  'ar/vr': 'AR/VR',
  'network security': 'Network Security',
  'mobile payments': 'Mobile Payments',
  'digital lending': 'Digital Lending',
  'healthcare software': 'Healthcare Software',
  'data visualization': 'Data Viz',
};

export const DATASCIENCE_SORTED_ROUNDS = [
  'incubator',
  'angel',
  'seed',
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
];

export const DATASCIENCE_ROUND_LABELS: { [key: string]: string } = {
  incubator: 'Incubator',
  angel: 'Angel',
  seed: 'Seed',
  A: 'A',
  B: 'B',
  C: 'C',
  D: 'D',
  E: 'E',
  F: 'F',
  G: 'G',
  H: 'H',
};

//Most commonly used tags in LI
export const LI_INDUSTRY_TAGS = [
  'Accounting',
  'Administración gubernamental',
  'Airlines/Aviation',
  'Alimentación y bebidas',
  'Almacenamiento',
  'Alternative Dispute Resolution',
  'Alternative Medicine',
  'Animation',
  'Apparel & Fashion',
  'Architecture & Planning',
  'Artes escénicas',
  'Artículos de consumo',
  'Artículos de lujo y joyas',
  'Artículos deportivos',
  'Arts & Crafts',
  'Atención a la salud mental',
  'Atención sanitaria y hospitalaria',
  'Automotive',
  'Aviation & Aerospace',
  'Banca',
  'Banking',
  'Bienes inmobiliarios',
  'Biotechnology',
  'Biotecnología',
  'Broadcast Media',
  'Building Materials',
  'Business Supplies & Equipment',
  'Capital Markets',
  'Capital de riesgo y capital privado',
  'Ceramics & Concrete',
  'Chemicals',
  'Civic & Social Organization',
  'Civil Engineering',
  'Commercial Real Estate',
  'Computer & Network Security',
  'Computer Games',
  'Computer Hardware',
  'Computer Networking',
  'Computer Software',
  'Construcción',
  'Construction',
  'Consultoría de estrategia y operaciones',
  'Consumer Electronics',
  'Consumer Goods',
  'Consumer Services',
  'Contabilidad',
  'Cosmética',
  'Cosmetics',
  'Dairy',
  'Defense & Space',
  'Deportes',
  'Derecho',
  'Design',
  'Diseño',
  'Diseño gráfico',
  'Dotación y selección de personal',
  'E-learning',
  'Education Management',
  'Electrical & Electronic Manufacturing',
  'Energía renovable y medio ambiente',
  'Enseñanza superior',
  'Entertainment',
  'Entretenimiento',
  'Environmental Services',
  'Escritura y edición',
  'Events Services',
  'Executive Office',
  'Facilities Services',
  'Farming',
  'Financial Services',
  'Fine Art',
  'Fishery',
  'Food & Beverages',
  'Food Production',
  'Fundraising',
  'Furniture',
  'Gambling & Casinos',
  'Gestión de inversiones',
  'Gestión de organizaciones sin ánimo de lucro',
  'Gestión educativa',
  'Glass',
  'Government Administration',
  'Government Relations',
  'Graphic Design',
  'Health',
  'Higher Education',
  'Hospital & Health Care',
  'Hospitality',
  'Hostelería',
  'Human Resources',
  'Import & Export',
  'Importación y exportación',
  'Individual & Family Services',
  'Industria farmacéutica',
  'Industria textil y moda',
  'Industrial Automation',
  'Information Services',
  'Information Technology & Services',
  'Ingeniería industrial o mecánica',
  'Insurance',
  'Interconexión en red',
  'International Affairs',
  'International Trade & Development',
  'Internet',
  'Investigación',
  'Investment Banking',
  'Investment Management',
  'Judiciary',
  'Law Enforcement',
  'Law Practice',
  'Legal Services',
  'Legislative Office',
  'Leisure',
  'Libraries',
  'Logística y cadena de suministro',
  'Logistics & Supply Chain',
  'Luxury Goods & Jewelry',
  'Machinery',
  'Management Consulting',
  'Manufactura eléctrica/electrónica',
  'Maritime',
  'Market Research',
  'Marketing & Advertising',
  'Marketing y publicidad',
  'Materiales de construcción',
  'Mechanical Or Industrial Engineering',
  'Media Production',
  'Medical Device',
  'Medical Practice',
  'Medios de comunicación en línea',
  'Mental Health Care',
  'Military',
  'Minería y metalurgia',
  'Mining & Metals',
  'Mobile Games',
  'Motion Pictures & Film',
  'Museums & Institutions',
  'Music',
  'Música',
  'Nanotechnology',
  'Newspapers',
  'Non-profit Organization Management',
  'Ocio',
  'Oil & Energy',
  'Online Media',
  'Organización cívica y social',
  'Organización política',
  'Outsourcing/Offshoring',
  'Package/Freight Delivery',
  'Packaging & Containers',
  'Paper & Forest Products',
  'Performing Arts',
  'Petróleo y energía',
  'Pharmaceuticals',
  'Philanthropy',
  'Photography',
  'Plásticos',
  'Plastics',
  'Political Organization',
  'Primary/Secondary Education',
  'Printing',
  'Productos químicos',
  'Profesiones médicas',
  'Professional Training & Coaching',
  'Program Development',
  'Public Policy',
  'Public Relations & Communications',
  'Public Safety',
  'Publicaciones',
  'Publishing',
  'Railroad Manufacture',
  'Ranching',
  'Real Estate',
  'Recreational Facilities & Services',
  'Recursos humanos',
  'Relaciones gubernamentales',
  'Religious Institutions',
  'Renewables & Environment',
  'Research',
  'Restaurantes',
  'Restaurants',
  'Retail',
  'Sanidad',
  'Sector automovilístico',
  'Sector textil',
  'Security & Investigations',
  'Seguros',
  'Semiconductores',
  'Semiconductors',
  'Servicio al consumidor',
  'Servicio de información',
  'Servicios de eventos',
  'Servicios financieros',
  'Servicios médicos',
  'Servicios medioambientales',
  'Servicios y tecnologías de la información',
  'Shipbuilding',
  'Software',
  'Sporting Goods',
  'Sports',
  'Staffing & Recruiting',
  'Supermarkets',
  'Telecommunications',
  'Telecomunicaciones',
  'Textiles',
  'Think Tanks',
  'Tobacco',
  'Translation & Localization',
  'Transportation/Trucking/Railroad',
  'Transporte por carretera o ferrocarril',
  'Travel & Tourism',
  'Utilities',
  'Venture Capital & Private Equity',
  'Veterinary',
  'Videojuegos',
  'Vinos y licores',
  'Warehousing',
  'Wellness & Fitness',
  'Wholesale',
  'Wine & Spirits',
  'Wireless',
  'Writing & Editing',
  'bienestar y ejercicio',
  'viajes y turismo',
];
