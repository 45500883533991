import { Progress } from 'antd';
import { merge, uniqBy } from 'lodash';
import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { useHistory } from 'react-router-dom';
import { SeqButton } from '../components/Button';
import {
  CountWithIcon,
  Dialog,
  FooterRow,
  HeaderRow,
  LightTooltip,
  MessageRowContainer,
  PageContainer,
  SeqFullPageOverlay,
} from '../components/Common';
import { FastTable } from '../components/FastTable';
import { DebouncedSearchInput } from '../components/Input';
import { MultiSelect } from '../components/selects/MultiSelect';
import { Select, SelectOption } from '../components/selects/Select';
import { SeqCheckbox } from '../components/SeqCheckbox';
import { NotificationContext } from '../contexts/Notification';
import { OrganizationContext } from '../contexts/Organization';
import { organizationIdFromRoute } from '../Helpers';
import { renderToClipboard } from '../shared/ClipboardSupport';
import { usePageTitle } from '../shared/Common';
import { EXTERNAL_HOST, EXTERNAL_WEB_APP } from '../shared/helpers';
import {
  countNumOfMatches,
  filterFunctionFromReferralFilters,
  optionsFromTags,
  OptionWithChildren,
  ReferralsFilters,
  sliceProfilesByEduWork,
} from '../shared/network-miner-filter-helpers';
import { getNameParts } from '../shared/profile-helpers';
import { makeRequest, useResource } from '../shared/Resource';
import { Colors } from '../shared/Theme';
import { FilterTag, FilterTagsContainer } from './components/FilterTags';
import {
  MinerProfileCompanyColumn,
  MinerProfileLocationColumn,
  MinerProfileNameColumn,
  MinerProfileTitleColumn,
} from './ReferralTableColumns';

const { SEQUOIA_GREEN, BLACK3ALPHA } = Colors.Static;

const IRRELEVANT_ROLES = [
  'Founder',
  'VC',
  'Science',
  'CEO',
  'Legal',
  'Bus Ops',
  'Bus Dev',
  'Accounting',
  'Consultant',
  'Cybersecurity',
  'Prof Services',
  'Administrative',
  'QA',
  'Brand',
  'Growth',
  'Communications',
  'Product Marketing',
  'Fraud/Risk',
];

const EMPTY_REFERRAL_FILTER: ReferralsFilters = {
  locations: [],
  roles: [],
  rolesInverted: [],
  exec: undefined,
  nameOrTitle: '',
  workEduOverlap: [],
  workEduOverlapNamesInverted: [],
};

const PROFILES_READY_THRESHOLD = 0.95;

const PERSON_FILLED_ICON = { src: '/icons/person-filled.svg', alt: 'person' };

export const PageReferralsConnections = (
  props: RouteComponentProps<{ sheetHash: string; organizationId: string }>
) => {
  const history = useHistory();
  const organizationId = organizationIdFromRoute(props);
  const { me } = useContext(OrganizationContext);
  const { sheetHash } = props.match.params;
  const {
    notifications: {
      features: { referrals: referralsNotifications },
    },
    actions: { markSeen },
  } = useContext(NotificationContext);
  const [saving, setSaving] = useState(false);
  const [filterValues, setfilterValues] = useState<ReferralsFilters>(EMPTY_REFERRAL_FILTER);
  usePageTitle('Review Your Connections', false);

  const [peopleTags] = useResource<ConfigJSON.PeopleTags>(`/api/config/external-people-tags`);
  const [sheet, { refresh, applyLocalUpdates }] =
    useResource<NetworkMinerAPI.MinerSheetWithProfiles>(
      `/api/${organizationId}/miner-sheets/${sheetHash || 'missing'}`,
      undefined,
      { silent: true }
    );

  const { profiles } = sheet || { profiles: [] as NetworkMinerAPI.MinerProfileSummary[] };
  const profilesDetailsProgress =
    profiles.filter(p => p.details.education || p.details.work).length / profiles.length || 0;
  const profilesDetailsUnready =
    profiles.length > 0 && profilesDetailsProgress < PROFILES_READY_THRESHOLD;

  const [progressModalDismissed, setProgressModalDismissed] = useState(false);
  const showProgressModal = profilesDetailsUnready && !progressModalDismissed;

  const notification = referralsNotifications.find(n => n.data.id === sheet?.id);

  useEffect(() => {
    if (notification && progressModalDismissed) {
      markSeen(notification);
    }
  }, [notification, progressModalDismissed, markSeen]);

  // Update profiles every 15s if they're still loading
  useEffect(() => {
    const interval = setInterval(() => {
      if (profilesDetailsProgress < 0.99) {
        refresh();
      }
    }, 15 * 1000);
    return () => clearInterval(interval);
  }, [refresh, profilesDetailsProgress]);

  //Build 1x, re-use, recalc is expensive with large list
  const workEduSlices = React.useMemo(
    () => sliceProfilesByEduWork(sheet?.target, profiles),
    [sheet?.target, profiles]
  );
  const locationOptions = React.useMemo(() => buildLocationOptions(profiles), [profiles]);

  const profilesFiltered = React.useMemo(
    () => profiles.filter(filterFunctionFromReferralFilters(filterValues)),
    [profiles, filterValues]
  );

  const onSaveIncluded = React.useCallback(
    async (includedProfiles: NetworkMinerAPI.ProfileIds[]) => {
      if (!sheet) {
        return;
      }
      applyLocalUpdates({ ...sheet, includedProfiles });

      const body: NetworkMinerAPI.UpdateSheetRequest = { includedProfiles };
      await makeRequest<NetworkMinerAPI.MinerSheet>(
        `/api/${organizationId}/miner-sheets/${sheetHash}`,
        'PUT',
        body
      );
    },
    [sheet, sheetHash, applyLocalUpdates, organizationId]
  );

  const onClickPreview = async () => {
    if (!sheet) {
      return;
    }
    setSaving(true);
    onSaveIncluded(sheet.includedProfiles);
    setSaving(false);

    history.push(`/${organizationId}/referrals/sheet/${sheet.hash}/email/${sheet.target.name}`);
  };

  const onProfileClicked = React.useCallback(
    async (profile: NetworkMinerAPI.MinerProfileSummary) => {
      if (!sheet) {
        return;
      }
      onSaveIncluded(
        sheet.includedProfiles.some(ids => ids.externalId === profile.id)
          ? sheet.includedProfiles.filter(ids => ids.externalId !== profile.id)
          : [...sheet.includedProfiles, { externalId: profile.id }]
      );
    },
    [onSaveIncluded, sheet]
  );

  const onAllClicked = async (nextCheckedState: boolean) => {
    if (!sheet) {
      return;
    }

    // Toggling the "all" checkbox should add or remove everyone in the displayed set from
    // the total included profiles WITHOUT touching other non-visible selected folks.
    const next = nextCheckedState
      ? [...sheet.includedProfiles, ...profilesFiltered.map(({ id }) => ({ externalId: id }))]
      : sheet.includedProfiles.filter(i => !profilesFiltered.some(p => p.id === i.externalId));

    onSaveIncluded(uniqBy(next, p => p.externalId));
  };

  const onDownload = async (checkedOnly?: boolean) => {
    if (!sheet) {
      return;
    }
    let url = `/api/${organizationId}/miner-sheets/${sheetHash}/csv?collected=true`;
    if (checkedOnly) {
      url += `&ids=${encodeURIComponent(
        JSON.stringify(sheet.includedProfiles.map(p => p.externalId))
      )}`;
    }
    window.open(`${EXTERNAL_HOST}${url}`, '_blank');
  };

  const { roleOptions, execOptions } = React.useMemo(
    () =>
      peopleTags
        ? buildDropdownOptions(profiles, peopleTags, filterValues)
        : { roleOptions: [], execOptions: [] },
    [profiles, peopleTags, filterValues]
  );

  if (!sheet || profiles.length === 0 || !peopleTags) {
    return <span />;
  }

  const targetFirstName = getNameParts(sheet.target.name).first;

  const progress = (
    <Progress
      percent={Math.round(profilesDetailsProgress * 100)}
      strokeColor={SEQUOIA_GREEN}
      trailColor={BLACK3ALPHA}
      size="small"
    />
  );

  return (
    <>
      {showProgressModal && (
        <ProgressModal
          targetFirstName={targetFirstName}
          progress={progress}
          sheet={sheet}
          onCancel={() => history.push(`/${organizationId}/referrals/`)}
          onClose={() => setProgressModalDismissed(true)}
        />
      )}
      <PageContainer style={{ display: 'flex', flexDirection: 'column' }}>
        <HeaderRow style={{ display: 'flex', marginBottom: 'unset' }}>
          <DebouncedSearchInput
            placeholder={'Search by name or job title'}
            value={filterValues.nameOrTitle}
            onChange={nameOrTitle => setfilterValues({ ...filterValues, nameOrTitle })}
          />

          <MultiSelect
            searchable={true}
            placeholder={'ALL ROLES'}
            selected={filterValues.roles}
            options={roleOptions.sort((o1, o2) => {
              return (o2.matches || 0) - (o1.matches || 0);
            })}
            onSelect={selected => {
              setfilterValues(
                filterValues.roles.includes(selected.name)
                  ? { ...filterValues, roles: filterValues.roles.filter(r => r !== selected.name) }
                  : { ...filterValues, roles: [...filterValues.roles, selected.name] }
              );
            }}
          />

          <MultiSelect
            placeholder={'ALL LOCATIONS'}
            selected={filterValues.locations}
            options={locationOptions}
            onSelect={selected => {
              const locations = filterValues.locations.includes(selected.name)
                ? filterValues.locations.filter(r => r !== selected.name)
                : [...filterValues.locations, selected.name];

              setfilterValues({ ...filterValues, locations });
            }}
          />

          <Select
            placeholder={'ALL LEVELS'}
            selected={execOptions.find(e => e.name === filterValues.exec)?.id}
            options={execOptions}
            onSelect={execId => {
              setfilterValues({
                ...filterValues,
                exec: execOptions.find(e => e.id === execId)?.name,
              });
            }}
            optionsContainerStyle={{ width: 250, left: 'unset' }}
            selectionTransformer={t => t.name.toUpperCase()}
          />
        </HeaderRow>
        <FilterTagsContainer>
          {workEduSlices
            .filter(s => s.ids.length)
            .map(s => (
              <FilterTag
                key={s.name}
                tagInfo={s}
                workEduOverlap={filterValues.workEduOverlap!}
                setFilterValues={setfilterValues}
              />
            ))}
          {profilesDetailsUnready && (
            <>
              <div style={{ flex: 1 }} />
              <div style={{ width: 200 }}>
                <LightTooltip
                  overlay={`We're still collecting details for some of these profiles, so the work and education overlap filters may not be complete. We'll email you when that information is ready.`}
                  placement="bottomRight"
                >
                  {progress}
                </LightTooltip>
              </div>
            </>
          )}
        </FilterTagsContainer>
        {sheet.transferredFromSenderId &&
          sheet.targetId === me.externalProfileId &&
          sheet.collectedProfiles.length > 950 && (
            <MessageRowContainer
              style={{
                border: `1px solid ${Colors.Static.WARNING_TINT}`,
                color: Colors.Static.WARNING_TINT,
                marginRight: 24,
                padding: '12px 18px',
                height: 'initial',
                fontSize: 14,
                marginBottom: 10,
                marginTop: 15,
              }}
            >
              <span>
                This list was generated by Sequoia and may be missing some of your LinkedIn
                connections. Using the Hiring Tools chrome extension to{' '}
                <a
                  style={{
                    color: Colors.Static.WARNING_TINT,
                    fontWeight: 400,
                    textDecoration: 'underline',
                  }}
                  target="blank"
                  rel="noreferrer"
                  href={`https://www.linkedin.com/in/${me.identifier}`}
                >
                  create a new one
                </a>{' '}
                may yield additional candidates.
              </span>
            </MessageRowContainer>
          )}
        <MessageRowContainer
          style={{
            background: SEQUOIA_GREEN,
            color: 'white',
            padding: '12px 18px',
            height: 'initial',
            fontSize: 14,
          }}
        >
          Filter {targetFirstName}'s network to ~200 connections for them to review. Use the filters
          above to focus on friends and former co-workers in {targetFirstName}'s areas of expertise.
          <div style={{ fontWeight: 500, textAlign: 'right', marginLeft: 12 }}>
            {sheet.includedProfiles.length} of {profiles.length} selected
          </div>
        </MessageRowContainer>
        <div style={{ flex: 1, minHeight: 0, display: 'flex', flexDirection: 'column' }}>
          <ConnectionsTable
            profilesFiltered={profilesFiltered}
            includedProfiles={sheet.includedProfiles}
            onProfileClicked={onProfileClicked}
            onAllClicked={onAllClicked}
            peopleTags={peopleTags}
          />
          <FooterRow>
            <SeqButton
              onClick={() => onDownload(true)}
              disabled={sheet.includedProfiles.length === 0}
            >
              DOWNLOAD CHECKED{' '}
              {sheet.includedProfiles.length > 0 && `(${sheet.includedProfiles.length})`}
            </SeqButton>
            <SeqButton
              onClick={() => onDownload()}
            >{`DOWNLOAD ALL (${profiles.length})`}</SeqButton>
            <div style={{ flex: 1 }} />
            {sheet.includedProfiles.length === 0 && (
              <span style={{ maxWidth: 400, textAlign: 'right', paddingRight: 8 }}>
                Use the checkboxes above to choose people that {sheet.target.name} should review.
              </span>
            )}
            <SeqButton
              onClick={() => {
                renderToClipboard(
                  <>{`${EXTERNAL_WEB_APP}/${organizationId}/singleuse/sheet/${sheet.hash}/review/z`}</>,
                  'Copied review link to clipboard'
                );
              }}
            >
              COPY REVIEW LINK
            </SeqButton>
            <SeqButton
              loading={saving}
              disabled={!sheet.includedProfiles.length}
              onClick={onClickPreview}
              intent="primary"
            >
              COMPOSE EMAIL
            </SeqButton>
          </FooterRow>
        </div>
      </PageContainer>
    </>
  );
};

const ConnectionsTable: React.FunctionComponent<{
  profilesFiltered: NetworkMinerAPI.MinerProfileSummary[];
  includedProfiles: NetworkMinerAPI.ProfileIds[];
  onProfileClicked: (profile: NetworkMinerAPI.MinerProfileSummary) => void;
  onAllClicked: (checkAll: boolean) => void;
  peopleTags: ConfigJSON.PeopleTags;
}> = ({ profilesFiltered, includedProfiles, onProfileClicked, onAllClicked, peopleTags }) => {
  const allChecked = profilesFiltered.every(p => includedProfiles.some(i => i.externalId === p.id));

  const columns = [
    {
      width: 50,
      key: 'checkbox',
      title: <SeqCheckbox checked={allChecked} onChange={() => onAllClicked(!allChecked)} />,
      render: (profile: NetworkMinerAPI.MinerProfileSummary) => (
        <SeqCheckbox
          checked={includedProfiles.some(c => c.externalId === profile.id)}
          onChange={() => onProfileClicked(profile)}
        />
      ),
    },
    MinerProfileNameColumn,
    MinerProfileTitleColumn,
    MinerProfileCompanyColumn,
    MinerProfileLocationColumn,
    {
      title: 'Roles',
      key: 'roles',
      render: (profile: NetworkMinerAPI.MinerProfileSummary) => {
        return profile.functions.join(', ');
      },
    },
  ];

  const rowProps = React.useCallback(
    profile => ({
      onClick: () => {
        onProfileClicked(profile);
      },
    }),
    [onProfileClicked]
  );

  return (
    <FastTable<NetworkMinerAPI.MinerProfileSummary>
      dataSource={profilesFiltered}
      columns={columns}
      rowKey={'id'}
      rowHeight={'measure'}
      rowProps={rowProps}
    />
  );
};

const ProgressModal: React.FC<{
  progress: ReactNode;
  targetFirstName: string;
  sheet: NetworkMinerAPI.MinerSheetWithProfiles;
  onCancel: () => void;
  onClose: () => void;
}> = ({ progress, sheet, onCancel, onClose, targetFirstName }) => {
  return (
    <SeqFullPageOverlay width={500}>
      <Dialog
        header="Collecting Work & Education History"
        content={
          <div style={{ margin: '12px 0' }}>
            <p>
              We're still collecting details for {targetFirstName}'s {sheet.profiles.length}{' '}
              connections. You can start reviewing now, but the work and education filter options
              may be incomplete.
            </p>
            <p>
              We'll email you at <span style={{ fontWeight: 500 }}>{sheet.sender.email}</span> when
              the details have been fully collected!
            </p>
            {progress}
          </div>
        }
        footer={
          <div style={{ display: 'flex', gap: 12 }}>
            <div style={{ flex: 1 }} />
            <SeqButton onClick={onCancel}>Review Later</SeqButton>
            <SeqButton intent="primary" onClick={onClose}>
              Review Now
            </SeqButton>
          </div>
        }
      />
    </SeqFullPageOverlay>
  );
};

const buildLocationOptions = (profiles: NetworkMinerAPI.MinerProfileSummary[]) => {
  const locationDict: Partial<{ [c in LocationAPI.Continent]: { [s: string]: string[] } }> = {};
  const locationOptions: SelectOption[] = [];

  profiles
    .filter(p => p.locationObj)
    .forEach(p => {
      if (p.locationObj?.continent && p.locationObj?.country) {
        const { continent, country, state } = p.locationObj;

        if (continent in locationDict) {
          if (locationDict[continent]![country])
            locationDict[continent]![country].push(state || '');
          else locationDict[continent]![country] = [state || ''];
        } else locationDict[continent] = { [country]: [state || ''] };
      }
    });

  let currIdx = 1;

  const buildLocationOption = (name: string, count: number) => {
    const option = {
      id: currIdx,
      name,
      extra: <CountWithIcon icon={PERSON_FILLED_ICON} count={count} />,
    };
    currIdx++;
    return option;
  };

  Object.keys(locationDict).forEach(l => {
    const continent = l as LocationAPI.Continent;
    let continentTotal = 0;
    Object.values(locationDict[continent] || []).forEach(c => (continentTotal += c.length));
    if (continentTotal < 50) {
      locationOptions.push(buildLocationOption(continent, continentTotal));
    } else {
      Object.keys(locationDict[continent] || []).forEach(c => {
        const state = locationDict[continent]![c];
        const baseOption: SelectOption = buildLocationOption(c, state.length);
        if (state.length > 49) {
          const stateDict: { [s: string]: number } = {};
          state.forEach(s => (stateDict[s] ? stateDict[s]++ : (stateDict[s] = 1)));
          const sortedStateDict = Object.keys(stateDict)
            .filter(s => s.length)
            .sort((a, b) => stateDict[b] - stateDict[a]);

          const children = sortedStateDict
            .slice(0, 5)
            .map(s => buildLocationOption(s, stateDict[s]));

          if (sortedStateDict.length > 5) {
            children.push(
              buildLocationOption(
                'Other',
                sortedStateDict.slice(5).reduce((acc, s) => acc + stateDict[s], 0)
              )
            );
          }

          baseOption.children = children;
        }

        locationOptions.push(baseOption);
      });
    }
  });

  return locationOptions;
};

function buildDropdownOptions(
  profiles: NetworkMinerAPI.MinerProfileSummary[],
  peopleTags: ConfigJSON.PeopleTags,
  filterValues: ReferralsFilters
) {
  const optionWithChildrenToSelectOption = (
    optionWithChildren: OptionWithChildren
  ): SelectOption & Pick<OptionWithChildren, 'matches'> => {
    return {
      ...optionWithChildren,
      extra: optionWithChildren.matches ? (
        <CountWithIcon icon={PERSON_FILLED_ICON} count={optionWithChildren.matches} />
      ) : undefined,
      children: optionWithChildren.children?.map(c => optionWithChildrenToSelectOption(c)),
    };
  };

  const roleOptions = (
    optionsFromTags(
      profiles,
      ['roles', filterValues],
      merge({}, peopleTags.industries, peopleTags.roles)
    )
      .filter(r => r.matches && !IRRELEVANT_ROLES.includes(r.name))
      .map(r => {
        if (r.children) r.children = r.children?.filter(c => !IRRELEVANT_ROLES.includes(c.name));
        return r;
      }) || []
  ).map(optionWithChildrenToSelectOption);

  const execOptions: OptionWithChildren[] = [
    { id: 0, name: 'All Levels' },
    { id: 1, name: 'Exec' },
    { id: 2, name: 'Non-exec' },
  ].map(o => {
    const count = countNumOfMatches(profiles, ['exec', filterValues], o.name);
    return {
      id: o.id,
      name: o.name,
      extra: <CountWithIcon icon={PERSON_FILLED_ICON} count={count} />,
    } satisfies SelectOption;
  });

  return { execOptions, roleOptions };
}
