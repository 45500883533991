import { datadogRum } from '@datadog/browser-rum';

export const initializeDataDog = (version: string) => {
  datadogRum.init({
    applicationId: '435948d8-0953-49c0-87c0-d6dc29f24d88',
    clientToken: 'pub17dd69924087abb75040f4cb06fab909',
    site: 'datadoghq.com',
    service: 'founder_tools',
    env: 'prod',
    version: version,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: false,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  });

  datadogRum.startSessionReplayRecording();
};
