import { pick } from 'lodash';
import qs from 'query-string';
import React, { useContext, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { DownloadButton } from '../components/Button';
import { HorizontalDivider, PageContainer } from '../components/Common';
import { LeadListAndRoleSelect } from '../components/LeadListAndRoleSelect';
import { RoleSelect } from '../components/selects/RoleSelect';
import { Body1Serif, H3Mono } from '../components/Text';
import { OrganizationContext } from '../contexts/Organization';
import { usePageTitle } from '../shared/Common';
import { CSVImporter, KnownColumns } from '../shared/CSVImporter';
import { makeRequest, useResource } from '../shared/Resource';
import { Colors } from '../shared/Theme';
import { encodePipelineFilters } from './PagePipeline';

const { BLACK3ALPHA } = Colors.Static;

export const PageCandidateImport = () => {
  const history = useHistory();
  const location = useLocation();

  const forPipeline = location.pathname.includes('pipeline');
  const { id: orgId, roles, tags, me } = useContext(OrganizationContext);
  const [, { refresh }] = useResource<ExternalAPI.Profile[]>(`/api/${orgId}/profiles`);
  const [downloading, setDownload] = useState(false);
  const [roleId, setRoleId] = useState<number | undefined>();
  usePageTitle('Import Candidates', false);

  const [leadLists] = useResource<LeadsAPI.LeadList[]>(`/api/${orgId}/lead-lists`, {
    draftsOnly: true,
  });

  const [leadList, setList] = useState<LeadsAPI.UpsertLeadList>();
  const [addProfiles, setProfiles] = useState<LeadsAPI.CandidateUpsert[]>([]);
  // refs keep onFinish current with the latest leadList and addProfiles
  const leadListRef = React.useRef(leadList);
  leadListRef.current = leadList;
  const addProfilesRef = React.useRef(addProfiles);
  addProfilesRef.current = addProfiles;

  const onFinish = async () => {
    if (forPipeline) {
      const query = qs.stringify(encodePipelineFilters({ searchTerm: '', roleId }));
      history.push(`/${orgId}/pipeline/search?${query}`);
    } else if (leadListRef.current && addProfilesRef.current) {
      upsertLeadList(true);
    }
  };

  const upsertLeadList = async (goBackToList: boolean) => {
    if (leadListRef.current && addProfilesRef.current) {
      try {
        const payload = {
          ...pick(leadListRef.current, ['id', 'roleId', 'origin', 'description']),
          addProfiles: addProfilesRef.current,
          // SATISFIES ERROR
        } satisfies LeadsAPI.UpsertLeadList;

        const list = await makeRequest<LeadsAPI.LeadList>(
          `/api/${orgId}/lead-lists`,
          'POST',
          payload
        );

        goBackToList && history.push(`/${list.organizationId}/leads?listId=${list.id}`);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const addToLeads = async (item: ProjectAPI.UpsertMemberRequest) => {
    if (!(item.name || item.identifier) || !leadList) {
      return false;
    }

    const profileCleaned: LeadsAPI.CandidateUpsert = {
      name: item.name || '',
      identifier: item.identifier,
      email: item.emails?.length ? item.emails[0] : undefined,
      links: item.links,
      comment: item.comment,
    };

    setProfiles(prev => [...prev, profileCleaned]);

    return true;
  };

  const addToPipeline = async (item: ProjectAPI.UpsertMemberRequest) => {
    if (!item.name) {
      //Custom validator handles this, but just in case
      return false;
    }

    const profileCleaned: ExternalAPI.UpsertProfileRequest = {
      name: item.name || '',
      identifier: item.identifier || undefined,
      email: item.emails?.length ? item.emails[0] : undefined,
      links: item.links,
      roleId: roleId || roles.find(r => r.name.toLowerCase() === item.role?.toLowerCase())?.id,
      tags: item.tags
        ? Object.keys(tags).filter(ot =>
            item.tags?.find(nt => nt.toLowerCase().includes(ot.toLowerCase()))
          )
        : [],
    };

    const { profile } = await makeRequest<{ profile: ExternalAPI.Profile }>(
      `/api/${orgId}/profile`,
      'POST',
      { ...profileCleaned, status: 'Pool' }
    );

    return !!profile;
  };

  return (
    <PageContainer style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ flexShrink: 0 }}>
        <div style={{ display: 'flex', gap: 24 }}>
          <div style={{ flex: 3, borderRight: `1px solid ${BLACK3ALPHA}` }}>
            <H3Mono>Add candidates to {forPipeline ? 'your pipeline' : 'a leads list'}</H3Mono>
            <Body1Serif>
              Choose a CSV file to import. Note that you must have the name and/or LinkedIn of each
              candidate. All&nbsp;other&nbsp;fields&nbsp;are&nbsp;optional.
            </Body1Serif>
          </div>
          {forPipeline ? (
            <div style={{ marginLeft: 24, display: 'flex', gap: 16, flexDirection: 'column' }}>
              <div style={{ display: 'flex', alignItems: 'center', gap: 12 }}>
                <div style={{ fontWeight: 500 }}>Candidate CSV Template:</div>
                <DownloadButton
                  downloading={downloading}
                  file={'candidate-importer-template.csv'}
                  onDownload={() => {
                    setDownload(true);
                    setTimeout(() => setDownload(false), 500);
                  }}
                  size="small"
                />
              </div>
              <div style={{ display: 'flex', alignItems: 'center', gap: 12 }}>
                <div style={{ fontWeight: 500 }}>Role:</div>
                <RoleSelect role={roleId} setRole={setRoleId} />
              </div>
            </div>
          ) : (
            <div style={{ display: 'flex', flexDirection: 'column', width: '30%' }}>
              <LeadListAndRoleSelect
                leadLists={leadLists}
                leadList={leadList}
                setList={list => {
                  setList({ ...list, origin: { id: me.id, type: 'founder lead' }, addProfiles });
                }}
              />
            </div>
          )}
        </div>
        <HorizontalDivider style={{ margin: '24px 0' }} />
      </div>

      {forPipeline || leadList?.roleId ? (
        <CSVImporter
          onAddItem={forPipeline ? addToPipeline : addToLeads}
          onError={forPipeline ? undefined : () => upsertLeadList(false)}
          onFinish={onFinish}
          allowedFields={[
            'none',
            'name',
            'firstname',
            'lastname',
            'linkedin',
            'email',
            'github',
            'twitter',
            'designportfolio',
            ...((forPipeline ? ['role', 'tag'] : ['comment']) as KnownColumns[]),
          ]}
          refresh={refresh}
        />
      ) : (
        <div style={{ marginTop: 20 }}>
          Please select a role from the role dropdown above to upload a CSV file.
        </div>
      )}
    </PageContainer>
  );
};
