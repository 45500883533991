import React, { useContext } from 'react';
import { RouteComponentProps, useHistory } from 'react-router';
import styled from 'styled-components/macro';
import { SeqButton } from '../components/Button';
import { Body1Serif, H5MonoCSS, Label } from '../components/Text';
import { OrganizationContext } from '../contexts/Organization';
import { ScrollingContainer } from '../shared/ScrollableContainer';
import { Colors } from '../shared/Theme';
import { SimpleSidebar } from './SimpleSidebar';

interface InstallStep {
  direction: React.ReactNode;
  img?: React.ReactNode;
}

const { SEQUOIA_DARK_GREEN, BLACK7ALPHA } = Colors.Static;

export const DIRECTIONS = (me: ExternalAPI.UserWithCampaigns): InstallStep[] => [
  {
    direction: (
      <>
        Unzip the <span style={{ fontWeight: 600 }}>extension.zip</span> file by double-clicking it.
        (Most likely located in your <span style={{ fontWeight: 600 }}>Downloads</span> folder.) We
        recommend moving the unzipped file to a permanent location on your computer.
      </>
    ),
  },
  {
    direction: (
      <>
        From the menu in Chrome, choose{' '}
        <span style={{ fontWeight: 600 }}>Window &gt; Extensions</span> to show your installed
        extensions or navigate to <span style={{ fontWeight: 600 }}>chrome://extensions</span>
        <p style={{ marginTop: 12, fontStyle: 'italic' }}>
          Note: The Sequoia Chrome extension is not compatible with the Brave browser.
        </p>
      </>
    ),
    img: (
      <div style={{ margin: '-36px 0 -6px 30px' }}>
        <img src="/images/chrome-extensions.svg" alt="navigation to installed chrome extensions" />
        <img
          style={{ position: 'relative', top: -4, right: 546 }}
          src="/images/drawn-line1.svg"
          alt="squiggly line"
        />
      </div>
    ),
  },
  {
    direction: (
      <>
        Enable <span style={{ fontWeight: 600 }}>&ldquo;Developer Mode&rdquo;</span> in the top
        right corner of the extensions panel.
      </>
    ),
    img: (
      <div style={{ margin: '-24px 0 -6px 30px' }}>
        <img src="/images/dev-mode.svg" alt="enabling developer mode and clicking load unpacked" />
        <img
          style={{ position: 'relative', bottom: 40, right: 625 }}
          src="/images/drawn-arrow1.svg"
          alt="arrow"
        />
        <img
          style={{
            position: 'relative',
            bottom: 51,
            right: 235,
            transform: 'rotate(10deg)',
            width: 160,
          }}
          src="/images/drawn-ellipse.svg"
          alt="ellipse"
        />
      </div>
    ),
  },
  {
    direction: (
      <>
        <div style={{ marginBottom: 12 }}>
          Click <span style={{ fontWeight: 600 }}>&ldquo;Load Unpacked&rdquo;</span> and select the
          <span style={{ fontWeight: 600 }}> extension</span> directory that you downloaded and
          unzipped to add it to Chrome.
        </div>
        <div>
          Keep in mind that if you've installed the extension before, the new version may have a
          suffix (eg: "extension (1)") in your Downloads folder.
        </div>
      </>
    ),
  },
  {
    direction: (
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'baseline',
          position: 'relative',
          marginTop: -7,
        }}
      >
        Pin the extension to your Chrome windows by clicking the
        <img style={{ margin: 8 }} src="/icons/puzzle.svg" alt="puzzle piece" /> and then the
        <img style={{ margin: 4 }} src="/icons/pin.svg" alt="pin" /> next to Sequoia Hiring Tools.
      </div>
    ),
    img: (
      <div style={{ margin: '-24px 0 -6px 30px' }}>
        <img src="/images/pin-extension.svg" alt="pin extension to bar" />
        <img
          style={{ position: 'relative', bottom: 328, right: 95, transform: 'rotate(15deg)' }}
          src="/images/drawn-arrow2.svg"
          alt="arrow"
        />
        <img
          style={{ position: 'relative', bottom: 74, right: 419 }}
          src="/images/drawn-line2.svg"
          alt="squiggly line"
        />
        <img
          style={{ position: 'relative', bottom: 77, right: 298 }}
          src="/images/drawn-circle.svg"
          alt="circle"
        />
      </div>
    ),
  },
  {
    direction: (
      <>
        <a
          style={{ fontWeight: 700 }}
          target="_blank"
          rel="noreferrer"
          href={`https://www.linkedin.com/${me.identifier ? `in/${me.identifier}` : ''}`}
        >
          Visit LinkedIn
        </a>
        , and make sure you're signed into your LinkedIn account in order to use the extension. The
        extension icon in your browser bar will turn green when you are looking at a LinkedIn
        profile.
      </>
    ),
  },
  { direction: <>You're all set!</> },
];

export const PageExtensionGuide: React.FC<RouteComponentProps> = props => {
  const history = useHistory();
  const { me, actions } = useContext(OrganizationContext);
  const qs = new URLSearchParams(props.location.search);

  return (
    <SimpleSidebar>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: 'calc(100vh - 140px)',
          marginTop: 48,
        }}
      >
        <div style={{ fontSize: 24, marginBottom: 24 }}>
          Sequoia Chrome Extension: How to Install
        </div>
        <ScrollingContainer $alwaysShow={true}>
          <ExtensionDirections directions={DIRECTIONS(me)} />
        </ScrollingContainer>
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 16 }}>
          <SeqButton
            intent="primary"
            onClick={() => {
              history.push(qs.get('next') || '/home');
              actions.refresh(); // in order to get latest user settings if they completed download + visited LI
            }}
          >
            Continue
          </SeqButton>
        </div>
      </div>
    </SimpleSidebar>
  );
};

export const ExtensionDirections: React.FC<{ directions: InstallStep[] }> = ({ directions }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: 36,
        textAlign: 'left',
        fontSize: 16,
        lineHeight: 1.4,
        color: BLACK7ALPHA,
        minWidth: 1000,
      }}
    >
      {directions.map((d, idx) => {
        return (
          <>
            <div style={{ display: 'flex' }}>
              <ListNumber>{idx + 1}.</ListNumber>
              <Body1Serif>{d.direction}</Body1Serif>
            </div>
            {d.img}
          </>
        );
      })}
    </div>
  );
};

const ListNumber = styled(Label)`
  ${H5MonoCSS}
  width: 12px;
  color: ${SEQUOIA_DARK_GREEN};
  margin-right: 18px;
`;
