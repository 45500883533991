import React, { CSSProperties } from 'react';
import styled from 'styled-components/macro';
import { FlexFillingRowCSS, HorizontalDivider } from './Common';
import { IconContainer } from './Svgs';
import { Body2, Label2 } from './Text';

export const ROW_ITEM_HEIGHT = 28;

const ROW_PADDING = 12;

export interface RowProps {
  icon?: React.ReactNode;
  title?: string;
  content?: React.ReactNode;
  actions?: React.ReactNode;
  extra?: React.ReactNode;
  contentStyle?: CSSProperties;
  hasDivider?: boolean;
  style?: CSSProperties;
}

export const ListRow: React.FC<RowProps> = ({
  icon,
  title,
  content,
  actions,
  extra,
  contentStyle,
  hasDivider = true,
}) => {
  return (
    <>
      <StyledRow>
        <RowContent>
          {icon && <IconContainer style={{ height: ROW_ITEM_HEIGHT }}>{icon}</IconContainer>}
          {title && <RowTitle>{title}:</RowTitle>}
          <div style={{ display: 'flex', flex: 1, margin: 'auto 0', minWidth: 0, ...contentStyle }}>
            {content || `–`}
          </div>
        </RowContent>
        {actions && <ActionsContainer>{actions}</ActionsContainer>}
      </StyledRow>
      {extra}
      {hasDivider && <HorizontalDivider />}
    </>
  );
};

export const StyledRow = styled.div`
  display: flex;
  align-items: flex-start;
  padding: ${ROW_PADDING}px 0;
  flex-shrink: 1;
`;

const RowContent = styled(Body2)`
  ${FlexFillingRowCSS}
  line-height: ${ROW_ITEM_HEIGHT - 2}px;
  align-items: flex-start;
  gap: 12px;
`;

export const RowTitle = styled(Label2)`
  line-height: ${ROW_ITEM_HEIGHT}px;
`;

const ActionsContainer = styled.div`
  display: flex;
  height: ${ROW_ITEM_HEIGHT}px;
  align-items: center;
  gap: 4px;
`;
