import { ColumnsType } from 'antd/lib/table';
import { groupBy, indexOf } from 'lodash';
import { default as React, useContext, useMemo, useState } from 'react';
import { Column, Tag } from '../components/Common';
import { FastTable } from '../components/FastTable';
import { SocialLinks } from '../components/profile-drawer/ProfileDrawer';
import { customSortableColumn } from '../components/SeqTable';
import { QuoteBlock } from '../components/Text';
import { OrganizationContext } from '../contexts/Organization';
import { InsightTags } from '../network/InsightTags';
import { FilterTagsContainer } from '../referrals/components/FilterTags';
import { CharInCircle } from '../shared/Common';
import { bestRole } from '../shared/profile-helpers';
import { useResource } from '../shared/Resource';
import { Colors } from '../shared/Theme';
import { DraftList } from './DraftList';
import { LeadListFooter } from './LeadListFooter';
import { LeadReviewCarousel } from './LeadReviewCarousel';
import { InsightFiltersDropdown } from './LeadsCandidateFilter';
import { CandidateWithDetails, LeadListWithReviewStatus } from './PageLeads';
import { closeCarouselNotifs } from './ReviewFooter';

type ProfileWithReviewInfo = {
  profile: ExternalAPI.Profile;
  externalId: number;
  internalId?: number | undefined;
  review?: LeadsAPI.CandidateReview | undefined;
  comment?: string | undefined;
  annotation?: LeadsAPI.Annotation;
};

const feedbackArr = (review: LeadsAPI.Candidate['review']) =>
  [
    (review?.categories || []).filter(c => c !== 'Leave a comment').join(', '),
    review?.comment || '',
  ] satisfies [categories: string, comment: string];

const columns: ColumnsType<ProfileWithReviewInfo> = [
  {
    key: 'Status',
    width: 100,
    title: customSortableColumn<ProfileWithReviewInfo>('Status'),
    render: (p: ProfileWithReviewInfo) => {
      switch (p.review?.status) {
        case 'approved':
          return <img src="/icons/thumb-up.svg" alt="thumbs up" style={{ marginTop: 6 }} />;
        case 'rejected':
          return <img src="/icons/thumb-down.svg" alt="thumbs down" style={{ marginTop: 6 }} />;
        case 'later':
          return <img src="/icons/later.svg" alt="clock" style={{ marginTop: 6 }} />;
        default:
          return '--';
      }
    },
    sorter: (p: ProfileWithReviewInfo, p2: ProfileWithReviewInfo) =>
      (p.review?.status || '-').localeCompare(p2.review?.status || '-'),
  },
  {
    key: 'Name',
    title: customSortableColumn<ProfileWithReviewInfo>('Name'),
    render: (p: ProfileWithReviewInfo) =>
      p.profile && <ProfileWithDetailsCol profile={p.profile} />,
    sorter: (p: ProfileWithReviewInfo, p2: ProfileWithReviewInfo) =>
      (p.profile?.name || '-').localeCompare(p2.profile?.name || '-'),
  },
  {
    key: 'Insights',
    width: 205,
    title: 'Insights',
    render: (p: ProfileWithReviewInfo) => <InsightTags insights={p.profile.insights} />,
  },
  {
    key: 'Feedback',
    title: customSortableColumn<ProfileWithReviewInfo>('Feedback'),
    render: (p: ProfileWithReviewInfo) => {
      const { comment: sourcerComment } = p;
      const [categories, comment] = feedbackArr(p.review);
      return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: 6 }}>
          {!!categories && <div>{categories}</div>}
          {!!comment && <QuoteBlock quote={comment} author="Reviewer" />}
          {!!sourcerComment && <QuoteBlock quote={sourcerComment} author="Sourcer" />}
        </div>
      );
    },
    sorter: (p: ProfileWithReviewInfo, p2: ProfileWithReviewInfo) =>
      feedbackArr(p.review).join('').localeCompare(feedbackArr(p2.review).join('')),
  },
];

//Carousel starts at first unreviewed profile. So user starts "past" any previously reviewed
const reviewedThenUnReviewed = (
  a: { review?: LeadsAPI.CandidateReview },
  b: { review?: LeadsAPI.CandidateReview }
) => (b.review ? 1 : -1) - (a.review ? 1 : -1);

export const LeadListCandidatesSection: React.FC<{
  onUpdate: (update: Omit<LeadsAPI.UpdateLeadList, 'id'>) => Promise<void>;
  leadList: LeadListWithReviewStatus;
}> = ({ leadList, onUpdate }) => {
  const { me } = useContext(OrganizationContext);
  const [startCarouselFrom, setStartCarouselFrom] = useState<string | undefined>();
  const [reviewing, _setReviewing] = useState(false);
  const [statusFilter, setFilter] = useState<
    LeadsAPI.CandidateReview['status'] | 'undefined' | undefined
  >();
  const [candidateInsightFilters, setCandidateInsightFilters] = React.useState<
    NetworkAPI.InsightFilter[]
  >([]);

  const setReviewing = (reviewing: boolean) => {
    if (reviewing && !me.internalTalentId) {
      onUpdate({ lastReviewedAt: new Date() });
    }
    _setReviewing(reviewing);
  };

  const idsSorted = useMemo(() => {
    return leadList.profiles.sort(reviewedThenUnReviewed).map(p => p.externalId);
    // We intentionally let idsSorted get stale so order doesn't change!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(leadList.profiles.map(p => p.externalId).sort())]);

  const [candidates = [], { applyLocalUpdates }] = useResource<ExternalAPI.Profile[]>(
    `/api/${leadList.organizationId}/profiles/search`,
    { ids: idsSorted },
    { GETRequestMethod: 'POST' }
  );

  const leadListReviewsWithProfile = useMemo(() => {
    const sortByOriginalOrder = (a: { externalId: number }, b: { externalId: number }) => {
      const idxA = indexOf(idsSorted, a.externalId);
      const idxB = indexOf(idsSorted, b.externalId);
      return idxA - idxB;
    };

    return candidates.length
      ? leadList.profiles
          .reduce((acc, p) => {
            const profile = candidates.find(c => c.id === p.externalId);
            if (profile) {
              acc.push({ ...p, profile });
            }
            return acc;
          }, [] as CandidateWithDetails[])
          .sort(sortByOriginalOrder)
      : [];
  }, [leadList, candidates, idsSorted]);

  if (leadList.status === 'drafts') {
    return (
      <DraftList
        candidates={leadListReviewsWithProfile}
        onUpdate={{
          leadList: onUpdate,
          candidate: (profile: ExternalAPI.Profile) => {
            candidates.splice(
              candidates.findIndex(c => c.id === profile.id),
              1,
              profile
            );
            applyLocalUpdates([...candidates]);
          },
        }}
      />
    );
  }

  const reviewedCandidatesDict = groupBy(leadListReviewsWithProfile, l => l.review?.status);
  if ('undefined' in reviewedCandidatesDict) {
    reviewedCandidatesDict.unreviewed = reviewedCandidatesDict.undefined;
    delete reviewedCandidatesDict.undefined;
  }

  const filteredCandidateList = (
    statusFilter ? reviewedCandidatesDict[statusFilter] : leadListReviewsWithProfile
  ).filter(c =>
    !!candidateInsightFilters.length && c.profile.insights?.filters
      ? Object.keys(c.profile.insights?.filters).some(filter =>
          candidateInsightFilters.some(f => f.id === filter)
        )
      : true
  );

  const onClose = () => {
    setCandidateInsightFilters([]);
    closeCarouselNotifs();
    setReviewing(false);
    setStartCarouselFrom(undefined);
  };

  return (
    <Column style={{ marginRight: 24 }}>
      <FilterTagsContainer style={{ padding: '8px 0', margin: 0 }}>
        {Object.keys(reviewedCandidatesDict).length > 1 &&
          Object.keys(reviewedCandidatesDict).map((s, idx) => (
            <Tag
              key={idx}
              style={{ textTransform: 'capitalize' }}
              onClick={() => {
                setFilter(prev =>
                  prev === s ? undefined : (s as LeadsAPI.CandidateReview['status'])
                );
              }}
              selected={statusFilter === s}
            >
              <div style={{ marginTop: 2 }}>{s}</div>
              <CharInCircle char={reviewedCandidatesDict[s].length} />
            </Tag>
          ))}
        <div style={{ flex: 1 }} />
        <InsightFiltersDropdown
          candidates={leadListReviewsWithProfile}
          candidateInsightFilters={candidateInsightFilters}
          setCandidateInsightFilters={setCandidateInsightFilters}
        />
      </FilterTagsContainer>
      <FastTable
        rowProps={p => ({
          style: { cursor: 'pointer' },
          onClick: () => {
            setStartCarouselFrom(p.profile.identifier);
            setReviewing(true);
          },
        })}
        rowKey={'externalId'}
        rowHeight={'measure'}
        dataSource={
          leadList.annotations
            ? filteredCandidateList.map(c => ({
                ...c,
                annotation: leadList.annotations?.[c.externalId],
              }))
            : filteredCandidateList
        }
        columns={columns}
      />
      <LeadListFooter
        key={leadList.id}
        leadList={leadList}
        onUpdate={onUpdate}
        profiles={filteredCandidateList}
        onStartCarousel={() => setReviewing(true)}
      />
      {!!filteredCandidateList.length && reviewing && (
        <LeadReviewCarousel
          leadList={leadList}
          candidates={filteredCandidateList}
          actions={{ onUpdate, onClose }}
          startFrom={startCarouselFrom}
        />
      )}
    </Column>
  );
};

const ProfileWithDetailsCol: React.FC<{
  profile: ExternalAPI.Profile;
}> = ({ profile }) => {
  const { name, identifier, links } = profile;

  const currRole = bestRole(profile);
  const title =
    currRole &&
    `${currRole.title} at ${currRole.company.name} (${new Date(
      currRole.startTimestamp
    ).getFullYear()} - ${
      currRole.endTimestamp ? new Date(currRole.endTimestamp).getFullYear() : 'Present'
    })`;

  return (
    <>
      <div style={{ fontWeight: 500, display: 'flex', alignItems: 'baseline' }}>{name}</div>
      <div>{profile.location}</div>
      <div style={{ color: Colors.Static.SEQUOIA_LIGHT_TEXT }}>{title}</div>
      <div style={{ display: 'flex', padding: '4px 0', gap: 4 }}>
        <SocialLinks identifier={identifier} links={links} />
      </div>
    </>
  );
};
