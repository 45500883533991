import { useCallback, useEffect, useRef } from 'react';

// basically same as useTimeout
export function useInterval(callback: () => void, delay: number | null) {
  const savedCallback = useRef(callback);
  const intervalIdRef = useRef<NodeJS.Timer>();
  const cancel = useCallback(() => {
    const intervalId = intervalIdRef.current;
    if (intervalId) {
      intervalIdRef.current = undefined;
      clearInterval(intervalId);
    }
  }, [intervalIdRef]);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    if (!delay && delay !== 0) {
      return;
    }

    intervalIdRef.current = setInterval(() => savedCallback.current(), delay);
    return cancel;
  }, [delay, cancel]);

  return cancel;
}
