import { Button, Modal } from 'antd';
import moment from 'moment';
import { useContext } from 'react';
import { OrganizationContext } from '../../contexts/Organization';
import { makeRequest } from '../../shared/Resource';

interface NewFeature {
  id: number;
  name: string;
  description: string;
  dateAdded: string; // Using 'YYYY-MM-DD' pattern for now
}

const newFeaturesData: NewFeature[] = [
  {
    id: 1,
    name: 'Improved "Leads" Tab',
    description:
      'The Leads tab now provides a unified view of candidates ready for your review. ' +
      'Lead lists can be sent from the Sequoia team, provided automatically by our data ' +
      'science algorithms, or curated manually by a member of your team. Candidate review ' +
      'takes place here!',
    dateAdded: '2022-03-20',
  },
  {
    id: 2,
    name: 'Easier Campaign Management',
    description:
      'Pipeline and Sourcing campaigns have merged into a single Campaigns tab. You can add ' +
      'pipeline candidates or leads to any campaign and manage email templates and delivery settings ' +
      ' at any time.',
    dateAdded: '2022-03-20',
  },
  {
    id: 3,
    name: 'Simpler Settings',
    description:
      'Role, tag, and export options that were previously hidden in the user settings modal ' +
      'now have a dedicated tab in the founder tools.',
    dateAdded: '2022-03-20',
  },
  {
    id: 4,
    name: 'Simplified Referrals',
    description:
      "Referral sheets make it easy to ask your connections about talent in their networks or filter their connections for your own outreach. We've simplified the referrals page - to build referral sheets, visit a connection's LinkedIn profile and open the Sequoia Chrome extension.",
    dateAdded: '2022-05-25',
  },
  {
    id: 5,
    name: 'Candidates outside LinkedIn',
    description:
      'From the Pipeline tab, you can now track and email candidates that don\'t have a LinkedIn profile by clicking "Add Candidates". More options to add candidates from GitHub, etc. are coming soon!',
    dateAdded: '2022-05-25',
  },
];

export const NewFeaturesModal = () => {
  const { me, actions } = useContext(OrganizationContext);

  const userLastViewed = me.settings.lastFeatureViewDate
    ? moment(me.settings.lastFeatureViewDate)
    : moment(me.createdAt);

  const features = newFeaturesData
    .sort((a, b) => Date.parse(b.dateAdded) - Date.parse(a.dateAdded))
    .filter(feat => moment(feat.dateAdded).isAfter(userLastViewed));

  const markNewFeatureView = async () => {
    await makeRequest<{ message: string }>(`/api/user/${me.id}/settings`, 'PUT', {
      lastFeatureViewDate: new Date(),
    });
    actions.refresh();
  };

  return (
    <Modal
      title="Explore What's New"
      styles={{ body: { padding: '12px 24px', paddingBottom: 0 } }}
      open={features.length > 0}
      footer={[
        <Button key="submit" type="primary" onClick={markNewFeatureView}>
          Got it!
        </Button>,
      ]}
    >
      {features.map(feature => (
        <div style={{ marginBottom: '2rem' }} key={feature.name}>
          <h3 style={{ margin: '8px 0' }}>{feature.name}</h3>
          <p>{feature.description}</p>
        </div>
      ))}
    </Modal>
  );
};
