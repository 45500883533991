import React, { useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router';
import { DownloadButton } from '../components/Button';
import { H1Serif, Subtitle2 } from '../components/Text';
import { ClickableItem } from '../shared/Common';
import { Colors } from '../shared/Theme';
import { SimpleSidebar } from './SimpleSidebar';

const { BLACK8ALPHA, SEQUOIA_DARK_GREEN } = Colors.Static;

export const PageExtensionDownload: React.FC<RouteComponentProps> = props => {
  const history = useHistory();
  const [downloading, setDownload] = useState(false);

  return (
    <SimpleSidebar>
      <div
        style={{
          marginTop: 'auto',
          marginBottom: 'auto',
          width: 600,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 12,
          textAlign: 'center',
        }}
      >
        <LogoWithExtension />
        <H1Serif style={{ color: BLACK8ALPHA }}>
          Get the most out of Sequoia with our Chrome Extension
        </H1Serif>
        <Subtitle2 style={{ color: BLACK8ALPHA, marginBottom: 24 }}>
          Get referrals from your network and add candidates to your pipeline as you're browsing
          LinkedIn.
        </Subtitle2>
        <DownloadButton
          downloading={downloading}
          file={'extension.zip'}
          onDownload={() => {
            setDownload(true);
            setTimeout(
              () => history.push(`/onboarding/extension-guide${props.location.search}`),
              2000
            );
          }}
        />
        {!downloading && (
          <ClickableItem
            onClick={() => history.push('/home')}
            style={{ fontSize: 12, color: SEQUOIA_DARK_GREEN, fontWeight: 500 }}
          >
            Not now
          </ClickableItem>
        )}
      </div>
    </SimpleSidebar>
  );
};

export const LogoWithExtension: React.FC = () => {
  return (
    <div style={{ paddingLeft: 24, paddingBottom: 12 }}>
      <img style={{ width: 72 }} src="/icons/logo.svg" alt="Logo" />
      <img
        style={{ position: 'relative', bottom: -24, left: -24 }}
        src="/icons/extension.svg"
        alt="puzzle piece"
      />
    </div>
  );
};
