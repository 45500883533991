import { Input, Popover } from 'antd';
import React, { ReactNode, useContext, useState } from 'react';
import styled from 'styled-components/macro';
import { HorizontalDivider } from '../../components/Common';
import { OrganizationContext } from '../../contexts/Organization';
import { onProfileUpsert } from '../../Helpers';
import { makeRequest } from '../../shared/Resource';
import { Colors, SequoiaPastel } from '../../shared/Theme';
import { SeqButton } from '../Button';
import { MultiProfileUpdateButtonProps } from '../MultiProfileUpdateButton';
import { FOOTER_ROW_POPOVER_WIDTH, PopoverDescription } from '../Popover';
import { SeqTag } from '../SeqTag';
import { H4Mono } from '../Text';

const { SEQUOIA_GREEN } = Colors.Static;
export const TAG_COLORS = Object.values(SequoiaPastel);

interface AddTagsProps<T> {
  disabled?: boolean;
  profile: T;
  onProfileSaved: (profile: T) => void;
  children: ReactNode;
}

const TAG_CREATION_DESCRIPTION = 'Press enter to create a new tag';

export const AddTagsPopover = ({
  disabled,
  profile,
  onProfileSaved,
  children,
}: AddTagsProps<ExternalAPI.Profile>) => {
  const [popoverVisible, setPopoverVisible] = useState<boolean>(false);

  const onAddProfileTag = async (newTags: string[]) => {
    newTags = newTags.filter(t => !profile.tags.includes(t));

    if (newTags.length) {
      const tags = [...profile.tags, ...newTags];
      await onProfileUpsert(profile, { tags }, onProfileSaved);
    }
  };

  const { matchingOptions, tagSearchInput } = useProfileTags({
    excludedTags: profile.tags,
    onSelectTag: tag => onAddProfileTag([tag]),
  });

  const PopoverContent = (
    <div style={{ width: 220 }}>
      <div style={{ padding: 0 }}>
        {tagSearchInput}
        {matchingOptions.length ? (
          <TagContainer style={{ padding: 6 }}>{matchingOptions}</TagContainer>
        ) : (
          <PopoverDescription style={{ padding: 12 }}>
            {TAG_CREATION_DESCRIPTION}
          </PopoverDescription>
        )}
      </div>
    </div>
  );

  return (
    <Popover
      overlayClassName={'seq-no-padding'}
      trigger={'click'}
      content={PopoverContent}
      placement={'bottomLeft'}
      open={popoverVisible}
      onOpenChange={() => setPopoverVisible(v => (disabled ? false : !v))}
    >
      {children}
    </Popover>
  );
};

export const BulkAddTagsPopover: React.FC<
  Pick<MultiProfileUpdateButtonProps, 'ids' | 'onUpdate' | 'disabled'>
> = ({ ids, disabled, onUpdate, children }) => {
  const { tags } = useContext(OrganizationContext);
  const [popoverVisible, setPopoverVisible] = useState<boolean>(false);
  const [bulkTagsSelection, setBulkTags] = useState<string[]>([]);

  const { matchingOptions, tagSearchInput } = useProfileTags({
    excludedTags: bulkTagsSelection,
    onSelectTag: (tag: string) => setBulkTags(prev => [...prev, tag]),
  });

  const bulkTags = bulkTagsSelection
    // in case someone deletes a tag right after they create it here
    .filter(t => !!tags[t])
    .map(t => (
      <SeqTag
        key={t}
        onClick={() => setBulkTags(prev => prev.filter(p => p !== t))}
        tagColor={tags[t].color}
        tagName={t}
        clickable
      />
    ));

  const PopoverContent = (
    <div style={{ width: FOOTER_ROW_POPOVER_WIDTH, padding: 16 }}>
      <H4Mono>bulk tag candidates</H4Mono>
      <div style={{ padding: '16px 0' }}>
        {tagSearchInput}
        {matchingOptions.length ? (
          <TagContainer style={{ padding: `12px 0 0 0` }}>{matchingOptions}</TagContainer>
        ) : (
          <PopoverDescription style={{ padding: `12px 0 0 0` }}>
            {TAG_CREATION_DESCRIPTION}
          </PopoverDescription>
        )}
      </div>
      {!!bulkTags.length && (
        <>
          <HorizontalDivider />
          <div style={{ padding: '12px 24px' }}>
            <PopoverDescription style={{ padding: '0 0 6px 0' }}>
              Add the following tags to {ids.length} candidates:
            </PopoverDescription>
            <TagContainer>{bulkTags}</TagContainer>
          </div>
          <HorizontalDivider />
          <div style={{ display: 'flex', padding: '12px 24px' }}>
            <div style={{ flex: 1 }} />
            <SeqButton
              size="small"
              onClick={() => {
                onUpdate({
                  type: 'tags',
                  tags: bulkTagsSelection,
                  name: bulkTagsSelection.join(' + '),
                });
              }}
            >
              Add
            </SeqButton>
          </div>
        </>
      )}
    </div>
  );

  return (
    <Popover
      overlayClassName={'seq-no-padding'}
      trigger={'click'}
      content={PopoverContent}
      placement={'bottomLeft'}
      open={popoverVisible}
      onOpenChange={() => setPopoverVisible(v => (disabled ? false : !v))}
    >
      {children}
    </Popover>
  );
};

export const TagContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 6px;
  column-gap: 4px;
`;

function useProfileTags({
  excludedTags,
  onSelectTag,
}: {
  excludedTags: string[];
  onSelectTag: (tag: string) => void;
}) {
  const { id, tags, actions } = useContext(OrganizationContext);
  const [inputValue, setInputValue] = useState('');

  const tagOptions = Object.keys(tags)
    .filter(t => !excludedTags.includes(t))
    .sort();

  const matchingOptions = tagOptions
    .filter(t => (inputValue.length ? t.toLowerCase().includes(inputValue.toLowerCase()) : true))
    .map(t => (
      <SeqTag
        key={t}
        onClick={() => onSelectTag(t)}
        tagName={t}
        clickable
        tagColor={tags[t].color}
      />
    ));

  const onCreateTag = async () => {
    if (matchingOptions.length) return;
    else if (!matchingOptions.length && inputValue) {
      const newTag = {
        [inputValue]: { color: TAG_COLORS[Math.floor(Math.random() * TAG_COLORS.length)] },
      };

      const response = await makeRequest<OrganizationAPI.Organization>(
        `/api/organization/${id}`,
        'PUT',
        // SATISFIES ERROR
        { tag: newTag } as OrganizationAPI.UpdateOrganizationRequest
      );
      if (response.id) {
        await actions.refresh();
        onSelectTag(inputValue);
        setInputValue('');
      }
    }
  };

  const tagSearchInput = (
    <Input
      autoFocus={true}
      style={{ border: '1px solid #F0F0F0', caretColor: SEQUOIA_GREEN }}
      placeholder="Search or create new"
      value={inputValue}
      onChange={e => setInputValue(e.currentTarget.value.toString())}
      onKeyDown={e => {
        if (e.key === 'Enter') onCreateTag();
      }}
    />
  );

  return { matchingOptions, tagSearchInput };
}
