import React, { useState } from 'react';
import { StyledInput } from '../components/Input';
import { Select, SelectDivider } from '../components/selects/Select';
import { makeRequest } from '../shared/Resource';

const CREATE_COHORT_ID = 'Create New Cohort';
const NO_COHORT_ID = 'No Cohort';

export const CohortSelector: React.FC<{
  orgId: number;
  cohorts: ConfigJSON.Cohorts;
  onCohortUpdated: (update: ConfigJSON.Cohorts) => void;
  currCohort?: string;
}> = ({ orgId, cohorts, onCohortUpdated, currCohort }) => {
  const [inputValue, setInputValue] = useState('');
  const [holdOpen, setHoldOpen] = useState(false);

  const updateCohort = async (newCohort?: string) => {
    const updatedCohorts = await makeRequest<ConfigJSON.Cohorts>(`/api/cohorts`, 'PUT', {
      prevCohort: currCohort,
      newCohort,
      orgId,
    });
    onCohortUpdated(updatedCohorts);
    setHoldOpen(false);
  };

  const cohortOptions = Object.keys(cohorts)
    .filter(c => c.toLowerCase().includes(inputValue))
    .map(c => ({ id: c, name: c }));

  const createOption = {
    id: CREATE_COHORT_ID,
    name: 'Create New Cohort',
    customRender: () => {
      return (
        <StyledInput
          onFocus={() => {
            setHoldOpen(true);
          }}
          onBlur={() => {
            setInputValue('');
            setHoldOpen(false);
          }}
          style={{ marginLeft: -10, marginTop: 4, paddingLeft: 5 }}
          value={inputValue}
          placeholder={`${cohortOptions.length ? 'Search or ' : ''}type to create a cohort`}
          onChange={e => {
            setInputValue(e.currentTarget.value);
          }}
          onKeyDown={async (keyEvent: React.KeyboardEvent<HTMLInputElement>) => {
            if (keyEvent.key === 'Enter' && inputValue.trim().length) {
              updateCohort(inputValue);
            }
          }}
        />
      );
    },
  };

  const noCohortOption = {
    id: NO_COHORT_ID,
    name: '—',
    customRender: () => <div style={{ fontStyle: 'italic' }}>No Cohort</div>,
  };

  let options = [createOption, SelectDivider, ...cohortOptions];
  if (currCohort !== NO_COHORT_ID) {
    options = options.concat([SelectDivider, noCohortOption]);
  }

  return (
    <div onClick={e => e.stopPropagation()}>
      <Select
        type="secondary"
        holdOpen={holdOpen}
        options={options}
        optionsContainerStyle={{ width: '100%' }}
        placeholder="—"
        selected={currCohort || NO_COHORT_ID}
        onSelect={id => {
          if (id && id !== CREATE_COHORT_ID && id !== currCohort) {
            updateCohort(id === NO_COHORT_ID ? undefined : id);
          }
        }}
      />
    </div>
  );
};
