import React, { useContext, useEffect, useMemo } from 'react';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components/macro';
import { ProfileDrawer } from '../components/profile-drawer/ProfileDrawer';
import { IconContainer } from '../components/Svgs';
import { Body1 } from '../components/Text';
import { NetworkProvider } from '../contexts/Network';
import { NotificationsProvider } from '../contexts/Notification';
import { OrganizationContext, OrganizationProvider } from '../contexts/Organization';
import { logActivity } from '../Helpers';
import { useRelatedProfiles } from '../hooks/useRelatedProfiles';
import { ClickableItem } from '../shared/Common';
import { EXTERNAL_WEB_APP } from '../shared/helpers';
import { makeRequest, useResource } from '../shared/Resource';
import { ArrowLeft } from '../shared/Svgs';
import { Colors } from '../shared/Theme';
import { useExtensionTabState } from './useExtensionTabState';

const { SEQUOIA_BLACK, BLACK1SOLID, SEQUOIA_PASTEL_GRAY, SEQUOIA_PASTEL_RED, SEQUOIA_DARK_YELLOW } =
  Colors.Static;

export const ExtensionApp: React.FC<RouteComponentProps> = props => (
  <OrganizationProvider>
    <NetworkProvider>
      <NotificationsProvider>
        <ExtensionDrawer {...props} />
      </NotificationsProvider>
    </NetworkProvider>
  </OrganizationProvider>
);

const recordView = async (identifier: string) => {
  await makeRequest(`/api/user/profile-view`, 'POST', { identifier });
};

const ExtensionDrawer: React.FC<RouteComponentProps> = props => {
  const { me, id: orgId } = useContext(OrganizationContext);
  const tabState = useExtensionTabState(props.location);

  // A profile may or may not exist in the pipeline
  const [_profile, profileOps] = useResource<ExternalAPI.Profile | { error: string }>(
    tabState.profile.identifier ? `/api/${orgId}/profile/${tabState.profile.identifier}` : ''
  );
  const profile = _profile && 'id' in _profile ? _profile : null;
  const profileArray = useMemo(() => (profile ? [profile] : []), [profile]);
  const { getRelatedProfileName } = useRelatedProfiles(profileArray);

  const logPipelineView = async () => {
    await logActivity(me, { type: 'page-view', pageName: 'pipeline' });
  };

  const updateSettings = async (settings: Partial<ExternalAPI.UserSettings>) =>
    await makeRequest(`/api/user/${me.id}/settings`, 'PUT', settings);

  useEffect(() => {
    if (tabState.profile.identifier) {
      void recordView(tabState.profile.identifier);
      void updateSettings({ ...me.settings, lastExtensionUse: new Date() });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabState.profile.identifier]);

  if (!tabState.profile.identifier) {
    return (
      <MessageContainer style={{ margin: 48, padding: '16px 24px' }}>
        Visit a LinkedIn or LinkedIn Recruiter profile in order to take action on or to view
        information about a candidate.
      </MessageContainer>
    );
  }

  return (
    <div style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Message isAdmin={!!me.internalTalentId} />
      <ProfileDrawer
        profile={
          profile || {
            id: -1,
            name: tabState.profile.name || '',
            identifier: tabState.profile.identifier || '',
            email: tabState.profile.email || '',
            organizationId: orgId,
            title: '',
            location: '',
            details: { version: 1, work: [], education: [] },
            activities: [],
            functions: [],
            createdAt: new Date(),
            updatedAt: new Date(),
            tags: [],
            endorsements: [],
            links: {},
            connectedTo: [],
          }
        }
        getRelatedProfileName={getRelatedProfileName}
        onProfileSaved={(profile?: ExternalAPI.Profile) => {
          profile ? profileOps.applyLocalUpdates(profile) : profileOps.refresh();
          logPipelineView();
        }}
        onDelete={
          profile
            ? () => {
                profileOps.refresh();
                logPipelineView();
              }
            : undefined
        }
        extensionConfig={{
          logPipelineView,
          miner: tabState.miner,
        }}
      />
    </div>
  );
};

const Message: React.FC<{ isAdmin: boolean }> = ({ isAdmin }) =>
  isAdmin ? (
    <MessageContainer style={{ background: SEQUOIA_PASTEL_RED, width: '100%' }}>
      For demo purposes only - the Sequoia team does not have a pipeline.
    </MessageContainer>
  ) : (
    <MessageContainer style={{ width: '100%' }}>
      <ClickableItem style={{ float: 'right' }}>
        <a
          href={`${EXTERNAL_WEB_APP}/home`}
          target="_blank"
          rel="noreferrer"
          style={{ display: 'flex', gap: 6 }}
        >
          The Hiring Tools are being sunset April 1, 2024
          <IconContainer style={{ width: 13 }}>
            <ArrowLeft style={{ transform: 'rotate(180deg)' }} />
          </IconContainer>
        </a>
      </ClickableItem>
    </MessageContainer>
  );

const MessageContainer = styled(Body1)`
  background: ${SEQUOIA_PASTEL_GRAY};
  border-bottom: 1px solid ${BLACK1SOLID};
  color: ${SEQUOIA_BLACK};
  padding: 6px 12px;
  a {
    color: ${SEQUOIA_BLACK};
    &:hover {
      color: ${SEQUOIA_DARK_YELLOW};
    }
  }
`;
