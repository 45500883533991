import * as QueryString from 'query-string';
import React from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';
import { ArrowLeft } from '../shared/Svgs';
import { Colors } from '../shared/Theme';
import { SeqButton } from './Button';
import { Label2 } from './Text';

const { SEQUOIA_GREEN, SEQUOIA_BLACK, BLACK1ALPHA, SEQUOIA_WHITE } = Colors.Static;

export const ChartLegend: React.FunctionComponent<{
  labelsAndColors: { label: string; color: string }[];
  onSelectFilter?: (filter?: string) => void;
  onLeftClick?: () => void;
  onRightClick?: () => void;
}> = ({ labelsAndColors, onSelectFilter, onLeftClick, onRightClick }) => {
  const location = useLocation();
  const { filter = 'Contacted' } = QueryString.parse(location.search) as { filter?: string };

  return (
    <GraphChartLegend>
      {labelsAndColors.map(l => (
        <LegendItem
          key={`chart-legend-${l.label}`}
          onClick={() => onSelectFilter && onSelectFilter(l.label)}
          style={{ cursor: onSelectFilter ? 'pointer' : 'default' }}
          selected={`"${l.label}"` === filter}
        >
          <LegendIcon style={{ background: l.color }} />
          <Label2 style={{ whiteSpace: 'nowrap' }}>{l.label}</Label2>
        </LegendItem>
      ))}
      <div style={{ flex: 1 }} />
      {(onLeftClick || onRightClick) && (
        <>
          <LeftRightButton disabled={!onLeftClick} onClick={onLeftClick}>
            <ArrowLeft />
          </LeftRightButton>
          <LeftRightButton disabled={!onRightClick} onClick={onRightClick}>
            <ArrowLeft style={{ transform: 'rotate(180deg)' }} />
          </LeftRightButton>
        </>
      )}
    </GraphChartLegend>
  );
};

const LegendItem = styled.div<{ selected?: boolean }>`
  padding: 12px 24px;
  ${({ selected }) => (selected ? `background: ${SEQUOIA_WHITE};` : '')}
  white-space: nowrap;

  &:hover {
    background: ${SEQUOIA_WHITE};
    -webkit-transition: background 0.3s;
    transition: background 0.4s;
  }
`;

export const GraphChartLegend = styled.div`
  max-width: 100%;
  display: flex;
  overflow-x: auto;
  overflow-y: clip;
  border-top: 1px solid ${SEQUOIA_BLACK};
  border-bottom: 1px solid ${SEQUOIA_BLACK};
  background: ${BLACK1ALPHA};
  align-items: center;
`;

const LegendIcon = styled.div`
  width: 8px;
  height: 8px;
  background: ${SEQUOIA_GREEN};
  display: inline-block;
  margin-right: 6px;
`;

const LeftRightButton = styled(SeqButton)`
  width: 60px;
  border: none;
  border-radius: 0px;
  min-height: 100%;

  &:hover,
  &:focus {
    background: ${SEQUOIA_WHITE};
    -webkit-transition: background 0.3s;
    transition: background 0.4s;
    & > svg > path {
      fill: ${SEQUOIA_BLACK};
    }
  }

  &:disabled {
    border: none;
    opacity: 0.3;
  }
`;
