export function sortUtil<T>(inFunc: (c: T) => string) {
  return (a: T, b: T) => {
    if (!a) {
      return -1;
    } else if (!b) {
      return 1;
    }
    return inFunc(a)
      .toLowerCase()
      .localeCompare(inFunc(b).toLowerCase());
  };
}

export function sortUtilNumber<T>(inFunc: (c: T) => number | null) {
  return (a: T, b: T) => {
    if (!a) {
      return -1;
    } else if (!b) {
      return 1;
    }
    return (inFunc(a) || 0) - (inFunc(b) || 0);
  };
}
