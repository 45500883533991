import { notification, Switch } from 'antd';
import React, { useEffect, useState } from 'react';
import { SEQUOIA_ORG_ID } from '../../App';
import { SeqButton } from '../../components/Button';
import { Dialog } from '../../components/Common';
import { SeqInput, SeqTextArea } from '../../components/Input';
import { SeqModal } from '../../components/Modal';
import { Select } from '../../components/selects/Select';
import { StarExplanatory } from '../../components/Star';
import { Body1Serif, Label2 } from '../../components/Text';
import { toLinkedinID, toLinkedinURL } from '../../shared/li-utils';
import { makeRequest } from '../../shared/Resource';
import { Colors } from '../../shared/Theme';
import { buildEmptyEndorsement, DaringFounderTooltip } from '../PageReferralsRecommendations';

const { BLACK1ALPHA } = Colors.Static;

type ProfileToCreate = ExternalAPI.Profile & { id: -1 };

const buildNewProfile = (
  sheetTarget: number,
  organizationId: number,
  name: string
): ProfileToCreate => {
  return {
    id: -1,
    name,
    title: '',
    identifier: '',
    location: '',
    email: '',
    status: 'Pool',
    details: {
      version: 1,
      work: [
        {
          company: { name: '' },
          title: '',
          location: '',
          startTimestamp: Date.now(),
          endTimestamp: null,
        },
      ],
    },
    links: {},
    functions: [],
    tags: [],
    endorsements: [buildEmptyEndorsement(sheetTarget)],
    createdAt: new Date(),
    updatedAt: new Date(),
    organizationId,
    origin: { type: 'referral' },
    connectedTo: [],
  };
};

export const AddPersonDialog: React.FunctionComponent<{
  profileSeed?: { name: string };
  onClose: (didAdd: boolean) => void;
  sheet: NetworkMinerAPI.MinerSheetWithProfiles;
}> = ({ profileSeed, onClose, sheet }) => {
  const [profile, setProfile] = useState<ProfileToCreate>(
    buildNewProfile(sheet.targetId, sheet.sender.organizationId, profileSeed?.name || '')
  );
  const [isDaring, setIsDaring] = useState<boolean>(false);

  useEffect(() => {
    setProfile(
      buildNewProfile(sheet.targetId, sheet.sender.organizationId, profileSeed?.name || '')
    );
  }, [profileSeed, sheet]);

  const saveDisabled =
    !profile.identifier ||
    !toLinkedinID(profile.identifier) ||
    !profile.endorsements.length ||
    (!profile.endorsements[0].score && !profile.endorsements[0].comment);

  const onAdd = async () => {
    if (sheet.target.identifier === profile.identifier) {
      notification.error({ message: `Sorry, you can't provide feedback about yourself!` });
      return;
    }

    const orgId = sheet.sender.organizationId;
    const result = await makeRequest<ExternalAPI.Profile>(
      `/api/${orgId}/miner-sheet/${sheet.hash}/profile`,
      'PUT',
      { profile, isDaring }
    );
    if (result.id) {
      notification.success({ message: `${result.name} added!` });
      onClose(true);
    }
  };

  const content = (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 12, margin: '16px 0' }}>
      <SeqInput
        title="Name"
        size="small"
        onChange={e => setProfile({ ...profile, name: e.currentTarget.value })}
        value={profile.name}
        placeholder={'Their name'}
        autoFocus
      />
      <SeqInput
        title="LinkedIn URL"
        size="small"
        onChange={e => setProfile({ ...profile, identifier: toLinkedinID(e.currentTarget.value) })}
        value={toLinkedinURL(profile.identifier)}
        placeholder={'https://linkedin.com/in/username/'}
        required
      />
      <div
        style={{
          background: BLACK1ALPHA,
          padding: 16,
          borderRadius: 16,
          display: 'flex',
          flexDirection: 'column',
          marginTop: 16,
          gap: 12,
        }}
      >
        <Body1Serif>What makes this person great?</Body1Serif>
        {profile.organizationId === SEQUOIA_ORG_ID && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Label2 style={{ fontWeight: 600 }}>is a potential founder</Label2>
            <DaringFounderTooltip />
            <Switch
              checked={isDaring}
              onChange={setIsDaring}
              style={{ width: 'fit-content', marginLeft: 12 }}
              checkedChildren="Yes"
              unCheckedChildren="No"
            />
          </div>
        )}
        <div style={{ display: 'flex', alignItems: 'center', gap: 12 }}>
          <Label2 style={{ fontWeight: 600 }}>Rating</Label2>
          <Select
            style={{ width: 'fit-content' }}
            optionsContainerStyle={{ maxWidth: 510 }}
            type="secondary"
            onSelect={id => {
              let score: CoreAPI.VouchScore = '';
              switch (id) {
                case 0:
                  score = 'A';
                  break;
                case 1:
                  score = 'B';
                  break;
              }
              setProfile({
                ...profile,
                endorsements: [{ ...profile.endorsements[0], score }],
              });
            }}
            placeholder={'Select…'}
            selected={
              profile.endorsements[0].score === 'A'
                ? 0
                : profile.endorsements[0].score === 'B'
                  ? 1
                  : undefined
            }
            options={[
              {
                id: 0,
                name: 'Top 2',
                customRender: () => (
                  <StarExplanatory
                    rating={2}
                    header={'Top 2'}
                    explanation={'Exceptional people who would succeed in nearly any environment.'}
                  />
                ),
              },
              {
                id: 1,
                name: 'Top 10',
                customRender: () => (
                  <StarExplanatory
                    rating={1}
                    header={'Top 10'}
                    explanation={
                      'Stars in the organization, but might not be ultra flexible across orgs and/or roles.'
                    }
                  />
                ),
              },
            ]}
          />
        </div>
        <SeqTextArea
          size="small"
          autoSize
          onChange={e =>
            setProfile({
              ...profile,
              endorsements: [{ ...profile.endorsements[0], comment: e.currentTarget.value }],
            })
          }
          value={profile.endorsements[0].comment}
          style={{ borderRadius: 6 }}
          placeholder={'What makes this person exceptional?'}
        />
      </div>
    </div>
  );

  return (
    <SeqModal width={550} open={!!profileSeed} onClose={() => onClose(false)}>
      <Dialog
        header="Add a person"
        content={content}
        footer={
          <SeqButton
            style={{ float: 'right' }}
            intent="primary"
            disabled={saveDisabled}
            onClick={onAdd}
          >
            ADD
          </SeqButton>
        }
      />
    </SeqModal>
  );
};
