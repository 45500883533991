import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { CSSProperties } from 'styled-components/macro';
import { makeRequest } from './Resource';

const UPDATE_CHECK_INTERVAL = 10 * 60 * 1000;
const UPDATE_BANNER_AFTER = 60 * 60 * 1000;

export const BrowserRouterWithReloader: React.FC<{
  basename?: string;
  productName: string;
  bannerStyle?: CSSProperties;
}> = ({ children, basename = '', productName, bannerStyle }) => {
  const [newVersion, setNewVersion] = React.useState(false);
  const [newVersionNotice, setNewVersionNotice] = React.useState(false);

  React.useEffect(() => {
    let lastWallTime = Date.now();

    const checkVersion = async () => {
      let manifest: { entrypoints: string[] } | null = null;
      try {
        manifest = await makeRequest<{ entrypoints: string[] }>(`${basename}/asset-manifest.json`);
      } catch (err) {
        return;
      }
      if (!manifest || !manifest.entrypoints) {
        console.warn('Could not check for React app updates.');
        return;
      }
      const scripts = Array.from(document.scripts).map(o => o.getAttribute('src'));
      const changed = manifest.entrypoints.filter(
        e => e.endsWith('.js') && !scripts.includes(`${basename}/${e}`) && !e.includes('hot-update')
      );

      if (changed.length) {
        console.warn(
          `An update is available, asset-manifest.json specifies scripts not on this page.`,
          scripts,
          changed
        );

        // Mark that an update is available and quietly make `<Link>` tags reload the page
        setNewVersion(true);

        // Display an update banner asking people to refresh the page when:
        // - It's been more than an hour and they haven't clicked a link
        // - It was more than an hour between checkVersion calls (laptop just woke from sleep / network loss)
        if (Date.now() - lastWallTime > UPDATE_BANNER_AFTER) {
          setNewVersionNotice(true);
        } else {
          setTimeout(() => setNewVersionNotice(true), UPDATE_BANNER_AFTER);
        }
      }

      lastWallTime = Date.now();
    };

    const interval = setInterval(checkVersion, UPDATE_CHECK_INTERVAL);
    return () => {
      clearInterval(interval);
    };
  }, [basename]);

  return (
    <BrowserRouter basename={basename} forceRefresh={newVersion}>
      {newVersionNotice && (
        <div
          style={{
            position: 'fixed',
            zIndex: 1000,
            top: 6,
            right: 16,
            padding: 8,
            borderRadius: 6,
            fontSize: 14,
            backgroundColor: '#137CBD',
            color: 'white',
            ...bannerStyle,
          }}
        >
          A new version of {productName} is available!{' '}
          <a
            href="/#"
            style={{ color: 'white', textDecoration: 'underline' }}
            onClick={e => {
              e.preventDefault();
              window.location.reload();
            }}
          >
            Reload the page
          </a>{' '}
          to update.
        </div>
      )}
      {children}
    </BrowserRouter>
  );
};
