import { EmailRegexp } from '../shared/helpers';

export interface MentionedItem {
  id: number;
  name: string;
}

export const buildCompletions = (
  myOrganization: OrganizationAPI.MyOrganization
): {
  team: MentionedItem[];
} => {
  return {
    team: myOrganization.team as MentionedItem[],
  };
};

export function findMentionsInText(text: string, completions: MentionedItem[]) {
  const mentioned: number[] = [];

  for (const item of completions) {
    if (!item.name) continue;

    // Plaintext mention
    if (text.includes(`@${item.name}`)) {
      mentioned.push(item.id);
    }
    // Quill HTML mention
    if (text.includes(`data-value="${item.name}"`)) {
      mentioned.push(item.id);
    }
  }
  return mentioned;
}

export function snippetAroundMention(text: string, mention: string) {
  const GOOD_BREAKPOINT = /[.!\n]/g;

  const pos = text.toLowerCase().indexOf(mention.toLowerCase());
  if (pos === -1) {
    return text;
  }

  const forwardOnly = pos === 0 || `${text[pos - 2]}${text[pos - 1]}`.match(GOOD_BREAKPOINT);
  const softEndMinPos = pos + (forwardOnly ? 300 : 200);
  const endOptions = [
    text.indexOf('\n\n', pos),
    text.indexOf('\r\n\r\n', pos),
    softEndMinPos + text.slice(softEndMinPos).search(GOOD_BREAKPOINT),
  ];

  const start = pos - (forwardOnly ? 0 : 300);
  const end = endOptions.find(e => e !== -1);

  return text.slice(start, end);
}

function startsWithWord(str: string, word: string) {
  // Don't link the startup "Found" in "Foundry376 is cool", require that a non-text
  // character comes after the end of the word.
  return str.startsWith(word) && !(str[word.length] || '').match(/[A-Za-z0-9]/);
}

export const linkifyMentions = (
  el: HTMLElement,
  myOrganization: OrganizationAPI.MyOrganization
) => {
  const completions = buildCompletions(myOrganization);
  const walker = document.createTreeWalker(el, NodeFilter.SHOW_TEXT, null);

  let node;
  while ((node = walker.nextNode())) {
    let p = node.parentNode;
    let text = node.nodeValue;
    const search = /@/g;
    let m: RegExpExecArray | null;

    if (!text || !p) continue;

    while ((m = search.exec(text))) {
      const substr = text.slice(m.index + 1);
      const { url, match } = findMentionMatchAndURL(myOrganization, completions, substr);

      if (url && match) {
        p.insertBefore(document.createTextNode(text.slice(0, m.index)), node);
        const a = p.insertBefore(document.createElement('a'), node);
        a.href = url;
        a.appendChild(document.createTextNode(`@${match.name}`));
        text = text.slice(m.index + match.name.length + 1);
      }
    }
    node.nodeValue = text;
  }
};

export const linkifyMentionsRich = (
  el: HTMLElement,
  myOrganization: OrganizationAPI.MyOrganization
) => {
  const completions = buildCompletions(myOrganization);
  const mentions: HTMLElement[] = Object.values(el.querySelectorAll('.mention'));

  for (const mention of mentions) {
    if (!mention.dataset.value) continue;
    const { url } = findMentionMatchAndURL(myOrganization, completions, mention.dataset.value);
    mention.innerHTML = `<a href="${url}">${mention.innerHTML}</a>`;
  }
};

const findMentionMatchAndURL = (
  session: OrganizationAPI.MyOrganization,
  completions: {
    team: MentionedItem[];
  },
  substr: string
) => {
  let match: MentionedItem | undefined;
  let url: string | undefined;

  match = completions.team.find(a => startsWithWord(substr, a.name));
  if (match) {
    const identifier = session.team.find(t => t.id === match!.id)?.identifier;
    if (identifier) {
      url = `/talent/people/${identifier}`;
    }
  }

  return { url, match };
};

export const linkifyLinks = (el: HTMLElement) => {
  const URLRegexp =
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*[-a-zA-Z0-9%_+~#&//=]+)?/g;

  // Correct any existing A tags in the HTML that are missing http:// or are emails without mailto:.
  // The recruiters can type links into the rich editor and they usually type in raw domains / emails.
  const atags: HTMLElement[] = Object.values(el.querySelectorAll('a'));
  for (const atag of atags) {
    let href = atag.getAttribute('href');
    if (!href) continue;
    if (href.startsWith('mailto:') || href.includes('://') || href.startsWith('/talent')) continue;

    if (EmailRegexp().test(href)) {
      atag.setAttribute('href', `mailto:${href}`);
    } else {
      atag.setAttribute('href', `https://${href}`);
    }
  }

  // Linkify plaintext that looks like an email address or URL
  for (const regexp of [URLRegexp, EmailRegexp()]) {
    const walker = document.createTreeWalker(el, NodeFilter.SHOW_TEXT, null);
    let node;
    while ((node = walker.nextNode())) {
      let p = node.parentNode;
      let text = node.nodeValue;
      if (!text || !p || p.nodeName === 'A') continue;
      let m: RegExpExecArray | null;

      while ((m = regexp.exec(text))) {
        p.insertBefore(document.createTextNode(text.slice(0, m.index)), node);
        const a = p.insertBefore(document.createElement('a'), node);
        a.href = m[0].startsWith('http') ? m[0] : `mailto:${m[0]}`;
        a.target = '_blank';
        a.appendChild(document.createTextNode(m[0]));
        text = text.slice(m.index + m[0].length);
      }
      node.nodeValue = text;
    }
  }
};
