import {
  EditableExcludeRulesMap,
  EditableRuleIds,
  invalid,
  onChangeWithValidation,
  RuleEditorProps,
  useDescription,
  valid,
  ValidationInfo,
} from '../rule-editors/RuleEditorCommon';
import { EditorInput, HeaderRow, Rule } from './RuleEditorCommon';

export const HeldCurrentTitleForXYearsEditor: React.FC<RuleEditorProps> = ({
  rule,
  onChange,
  style,
  title,
  ruleType,
}) => {
  const replaceParam = (value: string | null, index: number) => {
    if (value === null) {
      onChangeWithValidation(
        { ...rule, parameters: rule.parameters.filter((_, idx) => idx !== index) },
        onChange,
        validate
      );
    } else {
      const replaced = [...rule.parameters];
      replaced[index] = value;
      onChangeWithValidation({ ...rule, parameters: replaced }, onChange, validate);
    }
  };

  const description = useDescription(
    { rule, ruleType },
    {
      include: 'Person will receive a boost if in current job less than:',
      exclude: 'Person excluded if in current job less than:',
      invert: 'Keep only people in current job less than:',
    }
  );

  return (
    <div style={style}>
      {title && <HeaderRow label={title} onChange={onChange} rule={rule} />}
      <div
        style={{
          display: 'flex',
          marginTop: 25,
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div>{description}</div>
        <EditorInput
          style={{ width: 100 }}
          placeholder={'years'}
          value={rule.parameters[0]}
          onChange={e => replaceParam(e.currentTarget.value, 0)}
        />
      </div>
    </div>
  );
};

const validate = (rule: Rule): ValidationInfo => {
  if (!rule.parameters || isNaN(Number(rule.parameters[0])) || Number(rule.parameters[0]) <= 0) {
    const ruleLabel = EditableExcludeRulesMap[rule.id as EditableRuleIds]?.label || '';
    return invalid(`${ruleLabel} requires a minimum number of years of experience.`);
  }
  return valid();
};
