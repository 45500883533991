import { ColumnType } from 'antd/es/table';
import { SortOrder } from 'antd/es/table/interface';
import React, { CSSProperties, useEffect, useState } from 'react';
import { SeqCheckbox } from '../components/SeqCheckbox';
import { customSortableColumn } from '../components/SeqTable';
import { StarInput } from '../components/Star';
import { EXTERNAL_HOST } from '../shared/helpers';
import { MinimalExtProfile } from '../shared/profile-helpers';
import { Colors } from '../shared/Theme';
import { useQueryPersistedState } from '../shared/useQueryPersistedState';

export interface ProfileTableConfig<T> {
  checkedProfiles: T[];
  allChecked: boolean;
  checkedIds: number[];
  checkCol: ColumnType<T>;
  starCol: ColumnType<T>;
  actions: {
    onCheckAll: () => void;
    onCheckOne: (id: number) => void;
    onCheckSome: (ids: number[], uncheck?: boolean) => void;
    onDownload: (baseUrl: string, downloadAll: boolean) => void;
  };
}

export function useProfileTableConfig<T extends MinimalExtProfile>(
  profiles: T[],
  onChecked?: () => void
): ProfileTableConfig<T> {
  const [checkedIds, setCheckedIds] = useQueryPersistedState<number[]>({
    encode: ids => ({ ids: ids.join('-') }),
    decode: qs => (qs.ids ? qs.ids.split('-').map(Number).filter(Boolean) : []),
  });
  const [allChecked, setAllChecked] = useState<boolean>(false);

  useEffect(() => {
    if (checkedIds.length) setAllChecked(() => checkedIds.length === profiles.length);
  }, [checkedIds.length, profiles.length]);

  const profileRef = React.useRef<T[]>(profiles);
  profileRef.current = profiles;

  const result = React.useMemo(() => {
    const onCheckAll = () => {
      setAllChecked(s => !s);
      setCheckedIds(allChecked ? [] : profileRef.current.map(({ id }) => id));
    };

    const onCheckOne = (id: number) => {
      setCheckedIds(
        checkedIds.includes(id) ? checkedIds.filter(c => c !== id) : [...checkedIds, id]
      );
      onChecked && onChecked();
    };

    const onCheckSome = (ids: number[], uncheck?: boolean) => {
      setCheckedIds(
        uncheck
          ? checkedIds.filter(p => ids.indexOf(p) === -1)
          : Array.from(new Set([...checkedIds, ...ids]))
      );
    };

    const onDownload = (baseUrl: string, downloadAll: boolean) => {
      const qs = downloadAll ? '' : `ids=${checkedIds.join(',')}`;
      window.open(`${EXTERNAL_HOST}${baseUrl}${qs}`, '_blank');
    };

    const checkCol = {
      key: 'Checkbox',
      width: 45,
      title: customSortableColumn<T>(
        'Checkbox',
        <SeqCheckbox checked={allChecked} onChange={onCheckAll} />
      ),
      render: (p: T) => (
        <SeqCheckbox
          checked={checkedIds.some(c => c === p.id)}
          onChange={() => {
            onCheckOne(p.id);
          }}
        />
      ),
      sorter: (a: T, b: T) => Number(checkedIds.includes(b.id)) - Number(checkedIds.includes(a.id)),
    };

    const starCol = {
      key: 'Star',
      width: 56,
      defaultSortOrder: 'descend' as SortOrder,
      title: (
        <div style={{ display: 'flex', marginLeft: -12 }}>
          <StarInput
            value={'A'}
            onChange={s => {}}
            style={{ color: Colors.Static.SEQUOIA_LIGHT_TEXT, marginLeft: 6 }}
          />
        </div>
      ),
      render: (p: T) => <StarRatingWithCount endorsements={p.endorsements || []} />,
      sorter: (a: T, b: T) => {
        const scoreComparison = (score?: 'A' | 'B') =>
          (b.endorsements || []).filter(e => e.score === score).length -
          (a.endorsements || []).filter(e => e.score === score).length;
        return scoreComparison('A') || scoreComparison('B');
      },
    };

    return {
      allChecked,
      checkedIds,
      checkCol,
      starCol,
      actions: { onCheckAll, onCheckSome, onCheckOne, onDownload },
    };
  }, [allChecked, checkedIds, onChecked, setCheckedIds]);

  const checkedProfiles = profiles.filter(p => checkedIds.includes(p.id));

  return {
    ...result,
    checkedProfiles,
  };
}

const StarRatingWithCount: React.FC<{ endorsements: ExternalAPI.Endorsement[] }> = ({
  endorsements,
}) => {
  if (!endorsements.length || !endorsements.some(e => e.score === 'A' || e.score === 'B')) {
    return <div />;
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 4, alignItems: 'flex-end' }}>
      <RatingWithCount score="A" count={endorsements.filter(e => e.score === 'A').length} />
      <RatingWithCount score="B" count={endorsements.filter(e => e.score === 'B').length} />
    </div>
  );
};

const RatingWithCount: React.FC<{ score: CoreAPI.VouchScore; count: number }> = ({
  score,
  count,
}) =>
  count ? (
    <div style={{ width: 30, display: 'flex', gap: 2, justifyContent: 'flex-end' }}>
      <Star />
      {score === 'A' && <Star />}
      <div style={{ marginLeft: 4, fontSize: 12 }}>{count}</div>
    </div>
  ) : (
    <div />
  );

export const Star: React.FC<{ style?: CSSProperties }> = ({ style }) => (
  <div style={{ fontSize: 18, color: '#F2994A', ...style }}>★</div>
);
