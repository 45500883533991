import React, { useState } from 'react';
import {
  AddCandidateConfig,
  UpsertCandidateModal,
} from '../components/modals/UpsertCandidateModal';

export function AddCandidatesPopover({
  children,
  config,
}: {
  children: React.ReactElement;
  config: AddCandidateConfig<ExternalAPI.Profile>;
}) {
  const [modalVisible, setModalVisible] = useState<boolean>(false);

  const onClick = async () => {
    setModalVisible(true);
  };

  return (
    <>
      <UpsertCandidateModal visible={modalVisible} setVisible={setModalVisible} config={config} />
      <div onClick={onClick}>{children}</div>
    </>
  );
}
