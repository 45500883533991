import React, { CSSProperties, ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { Colors } from '../../shared/Theme';
import {
  CommonSelectWrapper,
  Option,
  OptionsContainer,
  OptionText,
  OptionType,
  SelectOption,
  SUB_OPTION_INDENT,
} from './Select';

const { SEQUOIA_BLACK, SEQUOIA_LIGHT_GRAY } = Colors.Static;

interface MultiSelectProps<T> {
  selected: number[] | string[];
  placeholder?: string;
  options: SelectOption<T>[];
  onSelect: (option: SelectOption<T>) => void;
  type?: OptionType;
  style?: CSSProperties;
  footer?: ReactNode;
  icon?: ReactNode;
  searchable?: boolean;
}

export function MultiSelect<T = number>({
  selected = [],
  options,
  onSelect,
  type = 'primary',
  style,
  placeholder = '',
  footer,
  icon,
  searchable,
}: MultiSelectProps<T>) {
  const inputRef = React.createRef<HTMLInputElement>();

  const [filterInputValue, setFilterInputValue] = React.useState<string>('');
  const [open, setOpen] = React.useState<boolean>(false);
  const [filteredOptions, setFilteredOptions] = useState<SelectOption<T>[]>(options);

  useEffect(() => {
    const lower = filterInputValue.toLowerCase();
    if (!lower) {
      return setFilteredOptions(options);
    }
    const optionMatchingFilter = (optIn: SelectOption<T>): SelectOption<T> | undefined => {
      const matchedChildren = optIn.children
        ? ([...optIn.children].map(optionMatchingFilter).filter(Boolean) as SelectOption<T>[])
        : [];
      if (optIn.name.toLowerCase().includes(lower) || matchedChildren.length) {
        return { ...optIn, children: matchedChildren };
      }
      return undefined;
    };
    setFilteredOptions(options.map(optionMatchingFilter).filter(Boolean) as SelectOption<T>[]);
  }, [options, setFilteredOptions, filterInputValue]);

  const selectedText =
    !!selected && selected.length > 0
      ? (selected.length === 1 &&
          filteredOptions.find(o => o.id === selected[0])?.name?.toUpperCase()) ||
        `${selected.length} SELECTED`
      : placeholder;

  return (
    <CommonSelectWrapper
      type={type}
      style={style}
      open={{ value: open, onChange: setOpen }}
      focusInputRefOnOpen={inputRef}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          maxWidth: '100%',
          paddingLeft: 12,
          paddingRight: 30,
        }}
      >
        {icon && <div style={{ marginRight: 8, verticalAlign: 'middle' }}>{icon}</div>}
        <div style={{ whiteSpace: 'nowrap', overflowX: 'hidden', textOverflow: 'ellipsis' }}>
          {selectedText}
        </div>
      </div>
      <OptionsContainer
        type={type}
        open={open}
        style={{ left: 'auto', minWidth: searchable ? 280 : 'unset' }}
      >
        {searchable && (
          <SelectSearchInput
            type={type}
            value={filterInputValue}
            ref={inputRef}
            placeholder="Type to filter"
            onClick={e => e.stopPropagation()}
            onChange={e => {
              setFilterInputValue(e.target.value);
            }}
          />
        )}
        <MultiSelectItems options={filteredOptions} selected={selected} onSelect={onSelect} />
        {footer}
      </OptionsContainer>
    </CommonSelectWrapper>
  );
}

type MultiSelectItemsProps<T> = Pick<MultiSelectProps<T>, 'options' | 'selected' | 'onSelect'> & {
  indent?: number;
};

function MultiSelectItems<T>({
  options,
  onSelect,
  selected,
  indent = 22,
}: MultiSelectItemsProps<T>) {
  const useNamesForSelected = selected.some((s: number | string) => !Number.isInteger(s as any));
  const selectedIds: T[] = useNamesForSelected ? [] : (selected as any).map((s: any) => Number(s));
  const selectedNames: string[] = useNamesForSelected ? (selected as string[]) : [];

  return (
    <>
      {options.map(o => (
        <div key={`option-group-${o.id}`}>
          <Option
            style={{ paddingLeft: indent }}
            key={`custom-option-${o.id}`}
            onClick={e => {
              e.stopPropagation();
              onSelect(o);
            }}
            selected={selectedIds.includes(o.id) || selectedNames.includes(o.name)}
          >
            <OptionText {...o} />
          </Option>
          {o.children && (
            <MultiSelectItems
              indent={indent + SUB_OPTION_INDENT}
              options={o.children}
              onSelect={onSelect}
              selected={selected}
            />
          )}
        </div>
      ))}
    </>
  );
}

const SelectSearchInput = styled.input<{ type: OptionType }>`
  &::placeholder {
    color: ${SEQUOIA_BLACK};
  }
  outline: none;
  width: 100%;
  border: none;
  padding: 6px 22px;
  border-bottom: 1px solid ${SEQUOIA_LIGHT_GRAY};
`;
