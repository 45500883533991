import { Checkbox, Tag, Tooltip } from 'antd';
import React, { CSSProperties } from 'react';
import { Colors } from '../shared/Theme';

export interface MentionedItem {
  id: number;
  name: string;
}

export const MentionsDisplay: React.FunctionComponent<{
  mentionedItems: MentionedItem[];
  notify: boolean;
  setNotify: (notify: boolean) => void;
  style?: CSSProperties;
}> = ({ mentionedItems, notify, setNotify, style }) => (
  <div
    style={{
      ...style,
      display: 'flex',
      alignItems: 'center',
      marginTop: 5,
      marginBottom: 10,
    }}
  >
    <Checkbox
      id="MentionsDisplayCheck"
      checked={notify}
      onChange={e => setNotify(e.target.checked)}
    />
    <label
      htmlFor="MentionsDisplayCheck"
      style={{ marginLeft: 10, marginRight: 10, color: Colors.Static.GRAY3 }}
    >
      Notify:
    </label>
    {mentionedItems.map((mentionedItem, idx) => {
      if (idx === 0) {
        return <Tag key={idx}>{mentionedItem.name}</Tag>;
      } else if (idx === 1) {
        return (
          <Tooltip
            key={idx}
            overlay={mentionedItems
              .slice(1)
              .map(a => `${a.name}`)
              .join(', ')}
          >
            <Tag>+ {mentionedItems.length - 1} more</Tag>
          </Tooltip>
        );
      }
      return <React.Fragment key={idx}></React.Fragment>;
    })}
  </div>
);
