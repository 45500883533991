import { isEqual } from 'lodash';
import { CSSProperties, ReactNode, useEffect, useRef, useState } from 'react';
import { SeqTextArea } from './Input';
import { Label } from './Text';

export const CCBCCRow: React.FC<{
  value: { cc: string[]; bcc: string[] };
  onChange: (newVal: { bcc: string[]; cc: string[] }) => void;
  headers?: { cc: ReactNode; bcc: ReactNode };
  style?: CSSProperties;
}> = ({ value, onChange: _onChange, headers, style }) => {
  const lastOnChange = useRef<any>();
  const onChange = (newVal: { bcc: string[]; cc: string[] }) => {
    lastOnChange.current = newVal;
    _onChange(newVal);
  };

  const [rawCCs, setRawCCs] = useState<{ cc: string; bcc: string }>({
    cc: value.cc.join(', '),
    bcc: value.bcc.join(', '),
  });

  // If CC or BCC are modified outside of this component, we want to update our
  // "rawCC" state. It's difficult to detect when this happens and avoid loops,
  // so we apply only if 1) our value prop is not the last thing we set it to,
  // and 2) our raw value doesn't match our expected raw value.
  useEffect(() => {
    const rawCCsExpected = { cc: value.cc.join(', '), bcc: value.bcc.join(', ') };
    if (!isEqual(value, lastOnChange.current) && !isEqual(rawCCs, rawCCsExpected)) {
      setRawCCs(rawCCsExpected);
    }
  }, [value, rawCCs]);

  return (
    <div style={{ display: 'flex', gap: 16, ...style }}>
      <div style={{ flex: 1 }}>
        {headers?.cc || <Label>CC</Label>}
        <SeqTextArea
          size="small"
          autoSize
          value={rawCCs.cc}
          onChange={e => {
            const newCc = e.currentTarget.value;
            setRawCCs(raw => ({ ...raw, cc: newCc }));
            onChange({ ...value, cc: rawToArray(newCc) });
          }}
        />
      </div>
      <div style={{ flex: 1 }}>
        {headers?.bcc || <Label>BCC</Label>}
        <SeqTextArea
          size="small"
          autoSize
          value={rawCCs.bcc}
          onChange={e => {
            const newBcc = e.currentTarget.value;
            setRawCCs(raw => ({ ...raw, bcc: newBcc }));
            onChange({ ...value, bcc: rawToArray(newBcc) });
          }}
        />
      </div>
    </div>
  );
};

const rawToArray = (raw: string) =>
  raw
    .split(',')
    .map(e => e.trim().toLowerCase())
    .filter(Boolean);
