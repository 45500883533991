import { isEqual } from 'lodash';
import React, { useState } from 'react';
import { SeqButton } from '../components/Button';
import { Column } from '../components/Common';
import { SeqTextArea } from '../components/Input';
import { PopoverDeleteButton, SeqPopover } from '../components/Popover';
import { RoleSelect } from '../components/selects/RoleSelect';
import { Body3 } from '../components/Text';
import { Colors } from '../shared/Theme';
import { LeadListWithReviewStatus } from './PageLeads';

type EditLeadListPopoverInput = Pick<LeadListWithReviewStatus, 'description' | 'roleId'>;

export const EditLeadListPopover: React.FC<{
  leadList: EditLeadListPopoverInput & Pick<LeadListWithReviewStatus, 'status' | 'profiles'>;
  actions: {
    updateList: (update: Omit<LeadsAPI.UpdateLeadList, 'id'>) => Promise<void>;
    deleteList?: () => Promise<void>;
  };
  children: React.ReactElement;
}> = ({ leadList, actions, children }) => {
  const { updateList, deleteList } = actions;
  const { status, roleId, description } = leadList;
  const [popoverVisible, setPopoverVisible] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<
    Pick<EditLeadListPopoverInput, 'roleId' | 'description'>
  >({ roleId, description });

  const content = (
    <Column style={{ gap: 12 }}>
      <div style={{ display: 'flex', alignItems: 'center', gap: 12 }}>
        <Body3>Role:</Body3>
        <RoleSelect
          role={inputValue?.roleId}
          opts={{ blockSelectNone: true, filterRoleOptions: r => !r.deletedAt }}
          setRole={r => {
            if (r) setInputValue(prev => ({ ...prev, roleId: r }));
          }}
        />
      </div>
      {status === 'drafts' && (
        <SeqTextArea
          style={{ background: Colors.Static.SEQUOIA_PAPER }}
          size="small"
          title="Description"
          autoSize
          placeholder="Describe this list of leads"
          value={inputValue?.description || ''}
          onChange={e => {
            const description = e.currentTarget.value;
            setInputValue(prev => ({ ...prev, description }));
          }}
        />
      )}
    </Column>
  );

  const footer = (
    <>
      {deleteList && <PopoverDeleteButton onDelete={deleteList} itemName="list" />}
      <SeqButton
        size="small"
        intent="primary"
        onClick={() => {
          updateList(inputValue);
          setPopoverVisible(false);
        }}
        disabled={isEqual({ roleId, description }, inputValue)}
      >
        Update
      </SeqButton>
    </>
  );

  return (
    <SeqPopover
      title="Edit List"
      content={content}
      footer={footer}
      placement="bottomRight"
      open={popoverVisible}
      onOpenChange={() => setPopoverVisible(v => !v)}
    >
      {children}
    </SeqPopover>
  );
};
