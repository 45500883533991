import { notification } from 'antd';
import { useContext, useState } from 'react';
import { SEQUOIA_ORG_ID } from '../App';
import { OrganizationContext } from '../contexts/Organization';
import { InviteMemberAdminPopover, MemberInviteInfo } from '../management/InviteMemberAdminPopover';
import { isValidEmail } from '../shared/helpers';
import { makeRequest } from '../shared/Resource';
import { SeqButton } from './Button';
import { PlainInput } from './Input';

const NO_SEQ_EMAIL_ACCOUNTS_MSG =
  'Sequoia capital email accounts are restricted to only the administrative organization. Use a personal gmail account if inviting someone to a test founder organization.';

export const InviteTeamInput: React.FC<{
  team: ExternalAPI.User[];
  organization: OrganizationAPI.Organization;
  refresh: () => void;
}> = ({ team, refresh, organization }) => {
  const { me } = useContext(OrganizationContext);
  const isAdmin = me.internalTalentId;

  const [inviteEmailInput, setInviteEmailInput] = useState<string>('');
  const [inviting, setInviting] = useState(false);

  const [invitePopoverInfo, setInvitePopoverInfo] = useState<MemberInviteInfo>({
    sendInvite: true,
    isFounder: !!isAdmin,
  });

  const inviteEmailArr = inviteEmailInput.split(',').map(e => e.trim());
  const inviteDisabled = inviteEmailArr.some(e => !isValidEmail(e));

  const onClickInvite = async (options: { sendInvite: boolean; isFounder: boolean }) => {
    setInviting(true);
    for (const email of inviteEmailArr) {
      if (!isValidEmail(email)) {
        notification.error({ message: `The following email is not valid: ${email}` });
      } else if (team.some(t => t.email.toLowerCase() === email.toLowerCase())) {
        notification.error({ message: `The following email has already been invited: ${email}` });
      } else if (email.toLowerCase().includes('sequoiacap') && organization.id !== SEQUOIA_ORG_ID) {
        notification.error({
          message: NO_SEQ_EMAIL_ACCOUNTS_MSG,
        });
      } else {
        const userUpdate: ExternalAPI.UpsertUserRequest = {
          id: -1,
          name: '',
          email: email,
          sendInvite: options.sendInvite,
          organizationId: organization.id,
          isFounder: options.isFounder,
        };
        const response = await makeRequest<ExternalAPI.User>('/api/user', 'PUT', userUpdate);
        if (response.id) {
          setInviteEmailInput('');
          notification.open({ message: options.sendInvite ? 'User Invited' : 'User Created' });
          refresh();
        } else {
          notification.error({
            message:
              'Sorry, this user cannot be invited at this time. Please contact a member of the Sequoia talent team for more details.',
          });
        }
      }
    }
    setInviting(false);
  };

  return (
    <div style={{ display: 'flex', gap: 12 }}>
      <PlainInput
        value={inviteEmailInput}
        onChange={e => setInviteEmailInput(e.currentTarget.value)}
        style={{ flex: 1 }}
        placeholder={`Enter team member emails (comma-separated)`}
        autoFocus
      />
      {isAdmin ? (
        <InviteMemberAdminPopover
          disabled={inviteDisabled}
          onCreateOrg={() => onClickInvite(invitePopoverInfo)}
          inviteInfo={invitePopoverInfo}
          onChange={setInvitePopoverInfo}
        />
      ) : (
        <SeqButton
          loading={inviting}
          disabled={inviteDisabled}
          onClick={() => onClickInvite({ sendInvite: true, isFounder: false })}
          intent="primary"
        >
          Invite
        </SeqButton>
      )}
    </div>
  );
};
