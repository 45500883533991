import { Radio, Rate } from 'antd';
import React from 'react';
import styled from 'styled-components/macro';
import { PlainInput } from '../components/Input';
import { Colors } from '../shared/Theme';
import { RemotePolicyEditor } from './RemotePolicyEditor';

type Info = InternalActionQueueAPI.RoleAudit;
type RoleAuditResponse = InternalActionQueueAPI.RoleAuditResponse;
type FilledRole = InternalActionQueueAPI.FilledRole;

const EMPTY_FILLED_ROLE: FilledRole = {
  identifier: '',
  baseSalary: null,
  bonus: null,
  equity: null,
};

export const RoleForm: React.FC<{
  position: Info['positions'][0];
  auditResponse: RoleAuditResponse;
  onChange: (response: RoleAuditResponse) => void;
  company: {
    locations: LocationAPI.Location[];
    remotePolicy: CompanyAPI.RemotePolicy;
  };
}> = ({ position, auditResponse, onChange, company }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 8, marginBottom: 32 }}>
      <PositionTitle>
        {position.title}
        {position.confidential && (
          <div
            style={{
              color: Colors.Static.CONFIDENTIAL_TINT,
              fontWeight: 600,
              fontSize: '0.8rem',
              marginTop: -2,
            }}
          >
            [CONFIDENTIAL]
          </div>
        )}
      </PositionTitle>
      <div style={{ display: 'flex', gap: 8, alignItems: 'baseline', flexWrap: 'wrap' }}>
        <Radio.Group
          value={
            auditResponse.response === 'open'
              ? 'open'
              : auditResponse.response === 'suspended'
                ? 'suspended'
                : 'filled'
          }
          onChange={e => {
            onChange(
              e.target.value === 'open' || e.target.value === 'suspended'
                ? { ...auditResponse, response: e.target.value }
                : { ...auditResponse, response: EMPTY_FILLED_ROLE }
            );
          }}
        >
          <Radio style={{ marginRight: 16 }} value={'open'}>
            Open
          </Radio>
          <Radio style={{ marginRight: 16 }} value={'suspended'}>
            Suspended
          </Radio>
          <Radio style={{ marginRight: 0 }} value={'filled'}>
            Filled
          </Radio>
        </Radio.Group>
      </div>
      {auditResponse.response === 'open' ? (
        <RemotePolicyEditor
          company={company}
          remotePolicy={auditResponse.remotePolicy}
          onChange={remotePolicy => onChange({ ...auditResponse, remotePolicy })}
        />
      ) : auditResponse.response === 'suspended' ? undefined : (
        <HireInfo
          response={auditResponse.response}
          externalRecruiterName={position.externalRecruiterName}
          onChange={response => onChange({ ...auditResponse, response })}
        />
      )}
    </div>
  );
};

const HireInfo: React.FC<{
  response: FilledRole;
  externalRecruiterName: string | null;
  onChange: (role: FilledRole) => void;
}> = ({ response, externalRecruiterName, onChange }) => {
  return (
    <HireInfoBox>
      <p style={{ marginBottom: 6 }}>Please provide the LinkedIn URL of the new hire:</p>
      <FormInput
        placeholder="New Hire LinkedIn URL"
        value={response.identifier}
        onChange={e => {
          onChange({ ...response, identifier: e.currentTarget.value });
        }}
      />
      <p style={{ marginBottom: 6, marginTop: 16 }}>
        Would you be willing to share a bit more info? (Don't worry, we won't share this with anyone
        externally.)
      </p>
      <div style={{ display: 'flex', gap: 8 }}>
        <FormInput
          style={{ flex: 1 }}
          placeholder="Base salary (USD)"
          value={response.baseSalary === null ? '' : response.baseSalary}
          onChange={e => {
            onChange({
              ...response,
              baseSalary: e.currentTarget.value || null,
            });
          }}
        />
        <FormInput
          style={{ flex: 0.7 }}
          placeholder="Bonus (USD)"
          value={response.bonus === null ? '' : response.bonus}
          onChange={e => {
            onChange({ ...response, bonus: e.currentTarget.value || null });
          }}
        />

        <FormInput
          style={{ flex: 0.7 }}
          placeholder="Equity (%)"
          value={response.equity === null ? '' : response.equity}
          onChange={e => {
            onChange({ ...response, equity: e.currentTarget.value || null });
          }}
        />
      </div>
      {externalRecruiterName && (
        <>
          <p style={{ marginBottom: 6, marginTop: 16 }}>
            How would you rate working with <strong>{externalRecruiterName}</strong> to fill this
            role?
          </p>
          <div
            style={{
              border: '1px solid #e1cdb2',
              padding: 8,
              background: 'white',
              width: 126,
            }}
          >
            <Rate
              count={4}
              style={{
                color: Colors.Static.SEQUOIA_GREEN,
              }}
              character={<div style={{ fontSize: 20 }}>★</div>}
              value={response.recruiterRating || 0}
              onChange={e => {
                onChange({ ...response, recruiterRating: e });
              }}
            />
          </div>
        </>
      )}
    </HireInfoBox>
  );
};

const PositionTitle = styled.div`
  font-weight: 500;
  font-size: 16px;
  flex: 1;
  min-width: 200px;
  margin-bottom: 4px;
  padding-bottom: 8px;
  border-bottom: 1px solid #e6e6e6;
  display: flex;
  justify-content: space-between;
`;

const HireInfoBox = styled.div`
  margin-bottom: 20px;
  background: antiquewhite;
  padding: 18px;
  border-radius: 7px;
  color: black;
`;

const FormInput = styled(PlainInput)`
  font-size: 14px;
  border: 1px solid #e1cdb2;
  padding-left: 10px;
  background: white;

  &:active {
    outline: none;
    border: 1px solid #e1cdb2;
  }
`;
