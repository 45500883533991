import { SettingFilled } from '@ant-design/icons';
import { pick } from 'lodash';
import moment from 'moment';
import { CSSProperties, default as React, useContext } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { IconButton, IconLinkButton } from '../components/Button';
import { LightTooltip } from '../components/Common';
import { NotificationButton } from '../components/NotificationButton';
import { Body1Serif, H2Mono, H5MonoCSS } from '../components/Text';
import { OrganizationContext } from '../contexts/Organization';
import { useCandidateFiltersPermission } from '../settings/PageSettings';
import { ClickableItem } from '../shared/Common';
import { EXTERNAL_HOST } from '../shared/helpers';
import { EditIconNoTrail, PieChartIcon } from '../shared/Svgs';
import { Colors } from '../shared/Theme';
import { EditLeadListPopover } from './EditLeadListPopover';
import { LeadListWithReviewStatus, LEAD_LIST_NAME_DATE_FORMAT } from './PageLeads';

const { SEQUOIA_BLACK, SEQUOIA_LIGHT_TEXT, BLACK2ALPHA, BLACK1ALPHA } = Colors.Static;

export const LeadListHeader: React.FC<{
  leadList: LeadListWithReviewStatus;
  actions: {
    updateList: (update: Omit<LeadsAPI.UpdateLeadList, 'id'>) => Promise<void>;
    deleteList?: () => Promise<void>;
    onClickStats: () => void;
  };
}> = ({ leadList, actions }) => {
  const { id, status, readyForReviewAt, createdAt, organizationId, description, roleId, sender } =
    leadList;
  const { updateList, deleteList, onClickStats } = actions;
  const { roles } = useContext(OrganizationContext);
  const roleForList = roleId ? roles.find(r => r.id === roleId) : undefined;
  const canViewAutoSourceFilters = useCandidateFiltersPermission();

  return (
    <Header style={{ marginRight: 24 }}>
      <div style={{ flex: 1 }}>
        <div style={{ display: 'flex' }}>
          <div style={{ display: 'flex', flex: 1, alignItems: 'center', gap: 12 }}>
            <H2Mono style={{ fontWeight: 600 }}>
              {moment(readyForReviewAt || createdAt).format(LEAD_LIST_NAME_DATE_FORMAT)}
            </H2Mono>
            {status === 'drafts' && <DraftTag>Draft</DraftTag>}
          </div>
          <div style={{ display: 'flex', gap: 4, alignItems: 'center' }}>
            {status !== 'drafts' && (
              <LightTooltip overlay="Stats" placement="bottomLeft">
                <IconButton onClick={onClickStats}>
                  <PieChartIcon />
                </IconButton>
              </LightTooltip>
            )}
            <LightTooltip overlay="Export CSV" placement="right">
              <IconLinkButton
                link={`${EXTERNAL_HOST}/api/${organizationId}/lead-list/${id}/csv`}
                type="download"
              />
            </LightTooltip>
            {(status === 'drafts' || status === 'reviewing') && (
              <EditLeadListPopover
                key={id}
                leadList={pick(leadList, ['roleId', 'description', 'status', 'profiles'])}
                actions={{ updateList, deleteList }}
              >
                <IconButton>
                  <EditIconNoTrail style={{ width: 20 }} />
                </IconButton>
              </EditLeadListPopover>
            )}
          </div>
        </div>
        <Body1Serif>{description}</Body1Serif>
      </div>
      <SubheaderSection>
        <div style={{ display: 'flex', gap: 6, alignItems: 'center' }}>
          Role:
          <span
            style={{
              fontWeight: 500,
              color: SEQUOIA_BLACK,
              gap: 6,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {roleForList ? (
              <>
                {roleForList.name}
                <NotificationButton roleId={roleId!} />
              </>
            ) : (
              '--'
            )}
          </span>
        </div>
        <SourcedNotice
          sender={sender}
          style={{ paddingLeft: 6, fontWeight: 500, color: SEQUOIA_BLACK }}
        />
        {leadList.generatedBySEModelId && roleForList && canViewAutoSourceFilters && (
          <Link
            to={`/${roleForList?.organizationId}/settings?tab=Candidate%20Filters&roleId=${roleForList?.id}`}
          >
            <ClickableItem style={{ color: SEQUOIA_LIGHT_TEXT, marginTop: 3 }}>
              Edit Candidate Filters
              <SettingFilled
                style={{ marginLeft: 6, color: Colors.Static.SEQUOIA_LIGHT_TEXT }}
                width={10}
                height={10}
              />
            </ClickableItem>
          </Link>
        )}
      </SubheaderSection>
    </Header>
  );
};

export const SourcedNotice: React.FC<{
  sender?: ExternalAPI.User | null;
  style?: CSSProperties;
}> = ({ sender, style }) => {
  return (
    <div>
      Sourced by: <span style={style}>{sender?.name || 'Sequoia'}</span>
    </div>
  );
};

const Header = styled.div`
  display: flex;
  gap: 16px;
  border-bottom: 1px solid ${SEQUOIA_BLACK};
  padding-bottom: 12px;
`;

const DraftTag = styled.span`
  ${H5MonoCSS}
  color: ${SEQUOIA_BLACK};
  border-radius: 5px;
  padding: 4px 8px;
  background: ${BLACK2ALPHA};
`;

export const SubheaderSection = styled.div`
  color: ${SEQUOIA_LIGHT_TEXT};
  background: ${BLACK1ALPHA};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: fit-content;
  padding: 10px 12px 12px 12px;
  margin-top: 12px;
  gap: 6px;
`;
