import Popover, { PopoverProps as AntdPopoverProps } from 'antd/lib/popover';
import React, { useState } from 'react';
import styled, { CSSProperties } from 'styled-components/macro';
import { Colors } from '../shared/Theme';
import { SeqButton } from './Button';
import { H4Mono } from './Text';

const { BLACK5ALPHA } = Colors.Static;

export const FOOTER_ROW_POPOVER_WIDTH = 300;

type PopoverProps = AntdPopoverProps & {
  contentStyle?: CSSProperties;
  footer?: React.ReactNode;
  footerStyle?: CSSProperties;
};

export const SeqPopover: React.FC<PopoverProps> = ({
  title,
  content,
  contentStyle,
  footer,
  footerStyle,
  ...rest
}) => (
  <Popover
    overlayClassName="seq-no-padding"
    trigger="click"
    placement="topLeft"
    content={() => (
      <PopoverContainer style={contentStyle}>
        {title && <H4Mono>{title}</H4Mono>}
        {content instanceof Function ? content() : content}
        {footer && <PopoverFooter style={footerStyle}>{footer}</PopoverFooter>}
      </PopoverContainer>
    )}
    {...rest}
  />
);

export const PopoverContainer = styled.div`
  width: 350px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-height: 55vh;
`;

const PopoverFooter = styled.div`
  display: flex;
  gap: 6px;
  justify-content: flex-end;
`;

export const LockPopover: React.FC<{ message?: string }> = ({ message }) => (
  <Popover
    content={
      <PopoverContent style={{ width: 220 }}>
        {message || 'Only the owner can update this field once the candidate has been hired.'}
      </PopoverContent>
    }
    placement="right"
  >
    <img src="/icons/lock.svg" alt="lock" style={{ height: 14 }} />
  </Popover>
);

interface PopoverDeleteButtonProps {
  onDelete: () => void;
  itemName: string;
  extraMsg?: string;
  children?: React.ReactNode;
}

export const PopoverDeleteButton = ({
  onDelete,
  itemName,
  extraMsg,
  children,
}: PopoverDeleteButtonProps) => {
  const [popoverVisible, setPopoverVisible] = useState(false);

  return (
    <SeqPopover
      trigger="click"
      content={
        <>
          Are you sure you want to delete this {itemName}
          {extraMsg && ` ${extraMsg}`}?
        </>
      }
      footer={
        <>
          <SeqButton size="small" onClick={() => setPopoverVisible(false)}>
            Cancel
          </SeqButton>
          <SeqButton
            size="small"
            onClick={() => {
              onDelete();
              setPopoverVisible(false);
            }}
          >
            Delete {itemName}
          </SeqButton>
        </>
      }
      placement="bottomRight"
      open={popoverVisible}
      onOpenChange={() => setPopoverVisible(v => !v)}
    >
      {children || <SeqButton size="small">Delete</SeqButton>}
    </SeqPopover>
  );
};

export const PopoverDescription = styled.div`
  color: ${BLACK5ALPHA};
  font-style: italic;
`;

export const PopoverContent = styled.div`
  font-size: 12px;
  width: 250px;
`;
