import { LoadingOutlined } from '@ant-design/icons';
import { notification, Spin } from 'antd';
import React, { useContext, useState } from 'react';
import { ActionButton, SeqButton } from '../components/Button';
import { FastTable } from '../components/FastTable';
import { SeqInput } from '../components/Input';
import { SeqPopover } from '../components/Popover';
import { Body2Serif, ExplanatoryText, H3Mono } from '../components/Text';
import { OrganizationContext } from '../contexts/Organization';
import { EXTERNAL_HOST } from '../shared/helpers';
import { makeRequest } from '../shared/Resource';

export const ExportTab: React.FC<{}> = () => {
  const {
    id,
    me,
    team,
    settings,
    features,
    actions: { refresh },
  } = useContext(OrganizationContext);

  const campaigns = [me, ...team]
    .map(user => user.campaigns)
    .flat()
    .filter(c => c.organizationId === id)
    .sort(
      (a, b) => (b.archivedAt ? -1 : 1) - (a.archivedAt ? -1 : 1) || a.name.localeCompare(b.name)
    );

  const columns = [
    {
      title: 'name',
      render: (campaign: CampaignAPI.Campaign) => (
        <span>
          {campaign.name}
          {campaign.archivedAt ? ' (Archived)' : ''}
        </span>
      ),
    },
    {
      title: ' ',
      width: 230,
      render: (campaign: CampaignAPI.Campaign) => {
        return (
          <a href={`${EXTERNAL_HOST}/api/sourcing/campaign/${campaign.id}/export`}>
            <ActionButton>Export Candidates (CSV)</ActionButton>
          </a>
        );
      },
    },
  ];

  return (
    <>
      <ExplanatoryText>
        Connecting to Gem adds each candidate from your Talent pipeline into Gem, syncing their
        name, LinkedIn handle, email, social links, location, company, title, candidate notes and
        email events. Candidates are de-duped by their LinkedIn handles.
      </ExplanatoryText>

      <div style={{ marginTop: 18, display: 'flex', gap: 12, alignItems: 'center' }}>
        <a href={`${EXTERNAL_HOST}/api/${me.organizationId}/profiles/csv`}>
          <SeqButton size="small" onClick={() => {}} style={{ marginTop: 0 }}>
            Export All Candidates (CSV)
          </SeqButton>
        </a>

        {settings.gemAPIKey ? (
          <Body2Serif style={{ marginTop: 12, display: 'flex', alignItems: 'center', gap: 8 }}>
            <img src="/icons/gem-logo.svg" alt="Gem logo" />
            This organization is currently in sync with Gem.
          </Body2Serif>
        ) : (
          <AddToGemButton orgId={id} refresh={refresh} />
        )}
      </div>

      {features.includes('campaigns') && (
        <div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginTop: 32,
              marginBottom: 4,
            }}
          >
            <H3Mono>Export Campaigns</H3Mono>
            <a href={`${EXTERNAL_HOST}/api/sourcing/${me.organizationId}/campaigns/export`}>
              <SeqButton size="small" style={{ marginTop: 0 }}>
                Export All
              </SeqButton>
            </a>
          </div>
          <FastTable<CampaignAPI.CampaignSummary>
            dataSource={campaigns}
            columns={columns}
            rowKey={campaign => campaign.id}
            rowHeight={44}
            rowProps={campaign => ({
              style: {
                opacity: campaign.archivedAt ? 0.55 : 1.0,
              },
            })}
          />
        </div>
      )}
    </>
  );
};

const AddToGemButton: React.FC<{ orgId: number; refresh: () => void }> = ({ orgId, refresh }) => {
  const [popoverVisible, setPopoverVisible] = useState<boolean>(false);
  const [gemAPIKey, setGemAPIKey] = useState('');
  const [loading, setLoading] = useState(false);

  const onConnect = async () => {
    setLoading(true);
    setGemAPIKey('');
    setPopoverVisible(false);
    refresh();

    const res = await makeRequest<{ message: string } | { error: string }>(
      `/api/gem/${orgId}`,
      'POST',
      { gemAPIKey }
    );

    if ('message' in res) {
      notification.success({ message: res.message });
    } else if ('error' in res) {
      notification.error({ message: res.error });
    }
    setLoading(false);
  };

  const popoverContent = (
    <div style={{ width: 300 }}>
      <SeqInput
        title="Gem API Key"
        autoFocus
        placeholder="Enter your Gem API key"
        value={gemAPIKey}
        onChange={e => {
          setGemAPIKey(e.currentTarget.value.toString());
        }}
      />
    </div>
  );

  return (
    <SeqPopover
      title="Connect To Gem"
      content={popoverContent}
      footer={
        <SeqButton
          size="small"
          intent="primary"
          onClick={onConnect}
          disabled={gemAPIKey.trim().length !== 40}
        >
          Connect
        </SeqButton>
      }
      placement={'bottomLeft'}
      open={popoverVisible}
      onOpenChange={() => setPopoverVisible(v => !v)}
    >
      <SeqButton size="small" style={{ marginTop: 0 }}>
        Connect{loading ? 'ing...' : ' To Gem'}
        {loading && (
          <Spin indicator={<LoadingOutlined style={{ fontSize: 14, marginLeft: 12 }} spin />} />
        )}
      </SeqButton>
    </SeqPopover>
  );
};
