import { useEffect, useMemo, useState } from 'react';
import { useLRULocalStorage } from './useLRULocalStorage';

interface UsePersistedFormOptions<T> {
  localStorageIdentifier: string;
  localStorageKey: string;
  initial: T | null;
  empty: T;
  shouldUseSaved: (saved: T) => boolean;
}

// Abstracts away the business logic for syncing form values to local storage and automatically
// restoring when re-opening the same `localStorageIdentifier`. Order of operations:
//
// - If initial is populated (non-null), use that
// - If local storage has saved state for `localStorageIdentifier`, use that
// - Use `empty`
//
export function usePersistedForm<T>({
  localStorageIdentifier,
  localStorageKey,
  initial,
  empty,
  shouldUseSaved,
}: UsePersistedFormOptions<T>) {
  const storage = useLRULocalStorage<T>(localStorageKey);
  const saved = storage.get(localStorageIdentifier);
  const [dirty, setDirty] = useState(false);

  // Reset the form when incoming `localStorageKey` or initial values are changed
  const initValues = useMemo(() => {
    if (initial) {
      setDirty(false);
      return initial;
    } else if (saved && shouldUseSaved(saved)) {
      setDirty(true);
      return saved;
    } else {
      setDirty(false);
      return empty;
    }
    // eslint-disable-next-line
  }, [localStorageIdentifier, JSON.stringify(initial)]);
  const [values, setValues] = useState<T>(initValues);

  // Sync our state continuously to local storage
  useEffect(() => {
    dirty && storage.set(localStorageIdentifier, values);
  });

  const form = { dirty, values };
  const actions = useMemo(
    () => ({
      update: (updates: Partial<T>) => {
        setValues(v => ({ ...v, ...updates }));
        setDirty(true);
      },
      empty: () => {
        setDirty(false);
        setValues(empty);
        storage.clear(localStorageIdentifier);
      },
    }),
    [localStorageIdentifier, empty, storage]
  );

  return [form, actions] as [typeof form, typeof actions];
}
