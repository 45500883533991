//Wrap pop confirm in some better styles that match the platform asthetic
import { Popconfirm } from 'antd';
import { PopconfirmProps } from 'antd/lib/popconfirm';
import React, { CSSProperties } from 'react';

export const SeqPopConfirm: React.FunctionComponent<
  PopconfirmProps & {
    width?: number;
  }
> = props => {
  const { width, title } = props;

  const buttonStyles: CSSProperties = {
    fontWeight: 500,
    height: 25,
    fontSize: 11,
    marginLeft: 6,
    padding: '0 8px',
    textTransform: 'uppercase',
  };

  return (
    <Popconfirm
      icon={null}
      {...props}
      cancelButtonProps={{ style: buttonStyles }}
      okButtonProps={{ style: { ...buttonStyles, ...(props?.okButtonProps?.style || {}) } }}
      title={<div style={{ width, marginLeft: -18, marginRight: 6 }}>{title}</div>}
    >
      {props.children}
    </Popconfirm>
  );
};
