import React, {
  CSSProperties,
  Dispatch,
  MouseEvent,
  ReactNode,
  SetStateAction,
  useEffect,
} from 'react';
import styled from 'styled-components/macro';
import { Label2CSS, LabelCSS } from '../../components/Text';
import { Colors } from '../../shared/Theme';
import { Label2 } from '../Text';

const { SEQUOIA_BLACK, SEQUOIA_GREEN, BLACK5ALPHA, BLACK1ALPHA, BLACK2ALPHA } = Colors.Static;

export const PREVENT_CLOSE_CLASS_NAME = 'select-keep-open';
export const SUB_OPTION_INDENT = 22;
const SELECT_HEIGHT = { primary: 36, secondary: 26 };

export type OptionType = 'primary' | 'secondary';

export interface SelectOption<T = number> {
  id: T;
  name: string;
  customRender?: () => ReactNode;
  extra?: string | ReactNode;
  children?: SelectOption<T>[];
  style?: CSSProperties;
  disabled?: boolean;
}

interface SelectOptionGroup<T> {
  name: string;
  options: SelectOption<T>[];
}

export interface SelectProps<T = number> {
  options: (SelectOption<T> | SelectOptionGroup<T>)[];
  onSelect: (id?: T) => void;
  selected?: T;
  placeholder?: string;
  disabled?: boolean;
  type?: OptionType;
  style?: CSSProperties;
  triggerStyle?: CSSProperties;
  footer?: ReactNode;
  icon?: ReactNode;
  optionsPlacement?: 'topRight' | 'bottomRight';
  optionsContainerStyle?: CSSProperties;
  optionStyle?: CSSProperties;
  holdOpen?: boolean; //Use if you need an input in your dropdown options
  open?: [boolean, Dispatch<SetStateAction<boolean>>];
  selectionTransformer?: (selected: SelectOption<T>) => string | ReactNode;
}

//Loosely based on https://andrejgajdos.com/custom-select-dropdown/
export function Select<T>({
  type = 'primary',
  selected,
  options,
  disabled,
  onSelect,
  style,
  placeholder,
  footer,
  icon,
  optionsContainerStyle,
  optionsPlacement,
  optionStyle,
  selectionTransformer,
  holdOpen,
  triggerStyle,
}: SelectProps<T>) {
  const [_open, setOpen] = React.useState<boolean>(false);
  const open = _open || !!holdOpen;

  const selectedOption = getFlattenedOptions(options).find(o => o.id === selected);
  const selectedOptionText = selectedOption?.name;
  const selectionText = selectedOptionText
    ? selectionTransformer
      ? selectionTransformer(selectedOption)
      : selectedOptionText
    : placeholder || '';

  return (
    <CommonSelectWrapper
      type={type}
      style={style}
      triggerStyle={triggerStyle}
      open={{ value: open || !!holdOpen, onChange: disabled ? () => {} : setOpen }}
    >
      <span
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          width: '100%',
          marginRight: 28,
          display: 'flex',
          alignItems: 'center',
          minWidth: 0,
        }}
      >
        <span style={{ marginRight: icon ? 8 : 12 }}>{icon || ''}</span>
        <span style={{ flex: 1, textOverflow: 'ellipsis', overflow: 'hidden', minWidth: 0 }}>
          {selectionText}
        </span>
      </span>
      <OptionsContainer
        type={type}
        style={optionsContainerStyle}
        open={open}
        optionsPlacement={optionsPlacement}
      >
        {options.map((o, idx) => {
          const baseProps = {
            lineHeight: Number(style?.height),
            style: optionStyle,
            onSelect,
            type,
            setOpen,
          };
          if ('options' in o)
            return (
              <React.Fragment key={`option-group-${o.name}`}>
                <Label2
                  style={{
                    flex: 1,
                    padding: '8px 0 0 12px',
                    cursor: 'default',
                    lineHeight: 2,
                    borderBottom: `1px solid ${BLACK1ALPHA}`,
                  }}
                >
                  {o.name}
                </Label2>
                {o.options.map(subOption => (
                  <OptionItem
                    key={`custom-option-${subOption.id}-${subOption.extra || 'no-extra'}`}
                    data={subOption}
                    selected={subOption.id === selected}
                    {...baseProps}
                  />
                ))}
              </React.Fragment>
            );
          return (
            <OptionItem
              key={`custom-option-${o.id}-${idx}-${o.extra || 'no-extra'}`}
              data={o}
              selected={o.id === selected}
              {...baseProps}
            />
          );
        })}
        {footer}
      </OptionsContainer>
    </CommonSelectWrapper>
  );
}

function getFlattenedOptions<T>(options: SelectProps<T>['options']) {
  return options.flatMap(o => ('options' in o ? o.options : o));
}

function OptionItem<T>({
  data,
  style,
  selected,
  onSelect,
  setOpen,
  type,
}: {
  data: SelectOption<T>;
  selected: boolean;
  onSelect: (id?: T) => void;
  setOpen: (o: boolean) => void;
  type: OptionType;
  style?: CSSProperties;
}) {
  return (
    <Option
      style={style}
      onClick={e => {
        e.stopPropagation();
        !selected && onSelect(data.id);
        setOpen(false);
      }}
      disabled={data.disabled}
      selected={selected}
      type={type}
    >
      {!!data.customRender ? data.customRender() : <OptionText {...data} />}
    </Option>
  );
}

export const OptionText: React.FunctionComponent<SelectOption<any>> = ({ name, extra, style }) => {
  return (
    <div
      key={`ok-${name} -${extra || 'no-extra'}`}
      style={{ display: 'flex', fontWeight: 400, gap: 16, width: '100%', ...style }}
    >
      <div style={{ flex: 1, flexBasis: 'auto' }}>{name}</div>
      {!!extra && (
        <span style={{ alignSelf: 'baseline', flexBasis: 'auto', textAlign: 'right' }}>
          {extra}
        </span>
      )}
    </div>
  );
};

export const CommonSelectWrapper: React.FunctionComponent<{
  open: { value: boolean; onChange: (newVal: boolean) => void };
  focusInputRefOnOpen?: React.RefObject<HTMLInputElement>;
  type: OptionType;
  style?: CSSProperties;
  triggerStyle?: CSSProperties;
}> = ({ open, focusInputRefOnOpen, type, style, triggerStyle, children }) => {
  useEffect(() => {
    const close = (event: any) => {
      const ev = event as MouseEvent;
      if (
        ev.currentTarget &&
        (ev.target as Element).className?.includes(PREVENT_CLOSE_CLASS_NAME)
      ) {
        return;
      }
      open.onChange(false);
    };
    open.value
      ? window.addEventListener<'click'>('click', close)
      : window.removeEventListener('click', close);

    open.value &&
      !!focusInputRefOnOpen &&
      setTimeout(() => focusInputRefOnOpen.current?.focus(), 50); //Add delay for re-render otherwise it wouldn't work

    return () => {
      window.removeEventListener('click', close);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open.value]);

  return (
    <SelectContainer type={type} style={style}>
      <CustomSelect height={Number(style?.height) || SELECT_HEIGHT[type]}>
        <SelectTrigger
          type={type}
          style={triggerStyle}
          onClick={() => {
            open.onChange(!open.value);
          }}
        >
          {children}
        </SelectTrigger>
      </CustomSelect>
    </SelectContainer>
  );
};

const SelectContainer = styled.div<{ type: OptionType }>`
  ${({ type }) => (type === 'primary' ? LabelCSS : Label2CSS)}
  border: 1px solid ${SEQUOIA_BLACK};
  min-width: 0;
  border-radius: 200px;
  color: ${SEQUOIA_BLACK};
  position: relative;
  user-select: none;
  &:hover {
    cursor: pointer;
    background-color: ${BLACK1ALPHA};
  }
`;

const CustomSelect = styled.div<{ height: number }>`
  width: 100%;
  height: ${({ height }) => `${height}px`};
  position: relative;
  display: flex;
  flex-direction: column;
  border: none;
`;

const SelectTrigger = styled.div<{ type: OptionType }>`
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  padding: ${({ type }) => (type === 'primary' ? '16px' : '12px')}
  line-height: ${({ type }) => `${SELECT_HEIGHT[type]}px`};
  cursor: pointer;
  background: url('/icons/caret.svg') no-repeat;
  background-position: right 12px top 50%;
`;

export const OptionsContainer = styled.div<{
  open: boolean;
  type: OptionType;
  optionsPlacement?: SelectProps['optionsPlacement'];
}>`
  min-width: 0;
  width: max-content;
  max-width: 35vw;
  margin: 4px 0;
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 100%;
  left: 0;
  right: 0;
  background: white;
  transition: all 0.5s;
  z-index: 2;
  opacity: ${({ open }) => (open ? 1 : 0)};
  visibility: ${({ open }) => (open ? 'visible' : 'hidden')};
  pointer-events: ${({ open }) => (open ? 'all' : 'none')};
  border: 1px solid ${BLACK5ALPHA};
  box-shadow: 0px 8px 16px rgba(140, 140, 140, 0.1);
  max-height: ${({ type }) => (type === 'secondary' ? '50vh' : '60vh')};
  overflow-x: hidden;
  overflow-y: auto;

  ${({ optionsPlacement }) => {
    switch (optionsPlacement) {
      case 'topRight':
        return 'top: unset; bottom: 100%; left: unset;';
      case 'bottomRight':
        return 'left: unset;';
      default:
        return '';
    }
  }}
`;

export const Option = styled.div<{
  selected: boolean;
  type?: OptionType;
  disabled?: boolean;
}>`
  ${({ type }) => (type === 'primary' ? LabelCSS : Label2CSS)};
  padding: ${({ type }) => (type === 'primary' ? `6px` : '4px')};
  padding-left: ${({ type }) => (type === 'primary' ? `${SUB_OPTION_INDENT}px` : '18px')};
  padding-right: 12px;
  position: relative;
  display: flex;
  cursor: pointer;
  transition: all 0.25s;
  min-width: 0;
  ${({ selected }) => (selected ? `background-color: ${BLACK1ALPHA};` : undefined)}

  &:hover {
    cursor: pointer;
    background-color: ${({ type }) => (type === 'primary' ? SEQUOIA_GREEN : BLACK1ALPHA)};
    color: ${({ type }) => (type === 'primary' ? '#ffffff' : 'unset')};
    img {
      filter: ${({ type }) => (type === 'primary' ? 'brightness(5)' : 'none')};
    }
  }

  ${({ disabled }) => disabled && `pointer-events: none; opacity: 0.4;`}
`;

export const SelectDivider: SelectOption<any> = {
  id: -10,
  name: '',
  disabled: true,
  customRender: () => (
    <div
      style={{
        pointerEvents: 'none',
        height: 1,
        flex: 1,
        marginLeft: -SUB_OPTION_INDENT,
        marginRight: -SUB_OPTION_INDENT,
        borderBottom: `1px solid ${BLACK2ALPHA}`,
      }}
    />
  ),
};
