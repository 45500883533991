import latinize from 'latinize';
import moment from 'moment';
import { isNever } from './helpers';

export type MinimalExtProfile = Pick<
  ExternalAPI.Profile,
  'id' | 'name' | 'identifier' | 'location' | 'title'
> & {
  title?: string;
  links?: CoreAPI.ProfileLinks;
  endorsements?: ExternalAPI.Profile['endorsements'];
} & ({ details: CoreAPI.ProfileDetails } | { bestRole: CoreAPI.WorkRole | null });

export const EPD_FUNCTIONS = ['Technical', 'Product Management', 'Design'];

export const sortWorks = (works: CoreAPI.WorkRole[] = []) => {
  return [...works].sort(
    (a: CoreAPI.WorkRole, b: CoreAPI.WorkRole): number =>
      (b.endTimestamp || Number.MAX_SAFE_INTEGER) - (a.endTimestamp || Number.MAX_SAFE_INTEGER) ||
      b.startTimestamp - a.startTimestamp ||
      0
  );
};

export const sortEducation = (education: CoreAPI.SchoolRole[] = []) => {
  return [...education].sort((a: CoreAPI.SchoolRole, b: CoreAPI.SchoolRole): number => {
    const { start: aStart, end: aEnd } = educationBounds(a.years);
    const { start: bStart, end: bEnd } = educationBounds(b.years);

    return bEnd - aEnd || bStart - aStart;
  });
};

export const educationBounds = (years?: string): { start: number; end: number } => {
  if (!years) return { start: 0, end: 0 };

  const split = years
    .split(/[-–]/)
    .map(y => Number(y.trim().slice(-4)))
    .filter(Number);

  return split.length ? { start: split[0], end: split[1] || split[0] } : { start: 0, end: 0 };
};

export const getYearsOfExperience = (profile: { details: CoreAPI.ProfileDetails }) => {
  const startOfCareerEdu =
    profile.details.education
      ?.map(e => e.years?.split('-').pop()?.trim())
      .map(year => (year && year.length === 4 ? new Date(`${year}-06-01`).getTime() : undefined))
      .sort()[0] || 0;
  const startOfCareerWork = profile.details.work?.map(w => w.startTimestamp).sort()[0] || 0;
  const startOfCareer = Math.max(startOfCareerWork, startOfCareerEdu);

  if (startOfCareer === 0) {
    return 0;
  }
  return Math.round((Date.now() - startOfCareer) / (365 * 24 * 60 * 60 * 1000));
};

export const hasTag = (profile: { tags: CoreAPI.ProfileWithXylem['tags'] }, tagName: string) =>
  tagName in profile.tags && !profile.tags[tagName].deletedAt;

export const hasCurrentSequoiaWork = (
  profile: { details: CoreAPI.Profile['details'] },
  seqCompanies: CompanyAPI.CompanyMinimal[]
) =>
  profile.details.work?.some(
    w =>
      !w.endTimestamp &&
      !w.title.includes('Scout') &&
      !w.title.includes('Builder') &&
      (w.company.name.toLowerCase() === 'sequoia capital' ||
        seqCompanies.some(
          s =>
            (s.lirID && s.lirID === w.company.lirID) ||
            w.company.name.toLowerCase() === s.name.toLowerCase()
        ))
  );

export const isVendor = (profile: { tags: CoreAPI.ProfileWithXylem['tags'] }) =>
  hasTag(profile, 'Vendor');

// Note: This is kept roughly in sync with the Postgres implementation in profile_works

export const inPortfolio = (
  profile: { id: number; details: CoreAPI.ProfileDetails },
  companies: CompanyAPI.CompanyMinimal[]
) =>
  (profile.details.work || []).some(
    w => nonIgnoredRole(w) && !w.endTimestamp && !!portfolioCompanyForRole(w, companies)
  ) ||
  companies.some(c => c.contacts.some(co => co.profileId === profile.id && !!co.responsibility));

export const portfolioCompanyForRole = (
  role: CoreAPI.WorkRole,
  companies: CompanyAPI.CompanyMinimal[]
) => {
  const active = companies.filter(c => c.activePortco);

  // This behavior is subtle. If the work entry is tied to a company page on LinkedIn,
  // it must be the right company, or one of the former names of a portfolio company,
  // or a name match if we do not have the LIR ID of the company on file.

  // If the work entry is a name-only (you forgot to choose an autocompletion on
  // LinkedIn and just typed a freeform string), then we fall back to a name match.

  if (role.company.lirID) {
    return active.find(
      c =>
        c.lirID === role.company.lirID ||
        (!c.lirID && c.name === role.company.name) ||
        c.nameAliases.includes(role.company.name)
    );
  } else if (role.company.name) {
    return active.find(
      c => c.name === role.company.name || c.nameAliases.includes(role.company.name)
    );
  }
  return false;
};

export function emailForPref(
  profile: { emails: PeopleAPI.Emails; tags: { [name: string]: CoreAPI.ProfileTagInfo } },
  pref: 'work' | 'personal'
) {
  if (isVendor(profile)) {
    return profile.emails.work?.address || '';
  }
  return pref === 'personal'
    ? profile.emails.personal?.address || ''
    : profile.emails.work?.address || '';
}

//Matches frontend functions in Helpers.tsx. Mod there if modifying here
export const ignoredRoleKeywords = [
  'investor',
  'angel',
  'adviser',
  'advisor',
  'board',
  'member',
  'mentor',
  'observer',
  'volunteer',
  'editor',
  'innovation partner',
  'adjunct',
  'guest lecturer',
  'author',
  'scout',

  // previous, more specific ignored roles:
  // 'board member',
  // 'member of the board',
  // 'board of directors',
  // 'board director',
  // 'founding member',
];

export const activeRole = (profile: Pick<CoreAPI.Profile, 'title' | 'details'>) =>
  bestRole(profile, { activeOnly: true });

export const nonIgnoredRole = (w: CoreAPI.WorkRole) =>
  w.company &&
  w.company.name &&
  !ignoredRoleKeywords.some(key => w.title?.toLowerCase().includes(key));

export const activeNonIgnored = (w: CoreAPI.WorkRole) => !w.endTimestamp && nonIgnoredRole(w);

export const bestRole = (
  profile: Pick<CoreAPI.Profile, 'title' | 'details'>,
  options: { activeOnly: boolean } = { activeOnly: false }
): CoreAPI.WorkRole | undefined => {
  const sorted = sortWorks(profile.details?.work);
  const active = sorted.filter(o => !o.endTimestamp);
  if (active.length === 1 || options.activeOnly) {
    return active[0];
  }
  const bestFromTitle =
    profile.title && !/(^|\s)(former|ex\s|ex-)/gim.test(profile.title)
      ? sorted.find(a => (profile.title || '').includes(a.company.name))
      : undefined;
  const best = bestFromTitle || sorted.find(activeNonIgnored);
  if (best) {
    return best;
  }
  return sorted.find(nonIgnoredRole) || sorted[0];
};

export const bestRoleTitleAndEmployer = (
  profile: MinimalExtProfile,
  options: { activeOnly: boolean } = { activeOnly: false }
): string => {
  const role = 'bestRole' in profile ? profile.bestRole : bestRole(profile, options);
  if (role) {
    return (role.endTimestamp ? '(Former) ' : '') + role.title + ' - ' + role.company.name;
  }
  return '';
};

// Try to keep in-line with shared-backends method of same name
export const getNameParts = (name: string | null | undefined) => {
  if (!name) {
    return { first: '', rest: '' };
  }

  const breakAtParensAndCertifications = (str: string) => {
    // Remove everything after parenthesis
    const toParens = str.split(/[([]/g)[0].trim();
    const parts = toParens.split(/[, ]/g).filter(Boolean);

    // Remove trailing all-uppercase words (MBA, etc.) with a few special cases
    while (parts.length) {
      const last = parts[parts.length - 1];
      if (last && last.toUpperCase() === last && last.length > 1) {
        parts.pop();
      } else if (last.replace(/\./g, '') === 'PhD') {
        parts.pop();
      } else {
        break;
      }
    }
    return parts.join(' ');
  };

  // "Dr. Ben Gotow (CPA)" => ["Dr.", "Ben", "Gotow"]
  // "Dr. Ben Gotow, LEED, AP, MD" => ["Dr.", "Ben", "Gotow"]
  const full = breakAtParensAndCertifications(name);
  const parts = full.split(' ');

  // "Dr. Ben Gotow" => "Ben"
  const titles = ['dr', 'miss', 'mr', 'mrs', 'ms', 'prof', 'sir', 'doctor', 'judge', 'officer'];
  const firstNonTitleIdx = parts.findIndex(p => !titles.includes(p.toLowerCase().replace('.', '')));
  const firstNonTitle = parts[firstNonTitleIdx];

  if (!firstNonTitle) {
    return { first: full, rest: '' };
  }

  // If the first usable component is also the last component, we need to return the full name.
  if (firstNonTitle === parts[parts.length - 1]) {
    return { first: full, rest: '' };
  }
  // If the name is only a first initial, eg. "B. Gotow", don't return "B.". In that case,
  // it'd be safer to return the entire name.
  if (firstNonTitle.replace('.', '').length <= 1) {
    return { first: full, rest: '' };
  }

  return { first: firstNonTitle, rest: parts.slice(firstNonTitleIdx + 1).join(' ') };
};

// Try to keep in-line with shared-backends method of same name
export const sanitizeProfileName = (name: string) => {
  if (!name) return name;

  let result = name;

  const removeMiscSuffix = () => {
    const lastMatch = result
      .match(/(?:[‘’']([^‘’']+)[‘’']|[“”"]([^“”"]+)[“”"]|\[([^\]]+)\]|\(([^)]+)\)),?/g)
      ?.reverse()[0]
      .toLowerCase();

    if (
      lastMatch?.includes('hiring') ||
      (lastMatch?.includes('he') && lastMatch?.includes('him' || 'his')) ||
      (lastMatch?.includes('she') && lastMatch?.includes('her')) ||
      (lastMatch?.includes('they') && lastMatch?.includes('them' || 'their'))
    ) {
      const strLenMinusMatch = lastMatch && result.length - lastMatch.length;

      if (strLenMinusMatch) result = result.slice(0, strLenMinusMatch);
    }

    return result.trim();
  };

  // remove emojis
  result = result.replace(/[^\p{L}\p{N}\p{P}\p{Z}{^$}]/gu, '').trim();

  // remove pronouns + hiring statements
  result = removeMiscSuffix();

  return result;
};

export const nameTitleOrCompanyMatches = (
  profile: MinimalExtProfile,
  search: string,
  onlyMatch?: 'title'
) => {
  const searchLower = search.toLowerCase();
  const terms: string[] = onlyMatch
    ? []
    : [profile.name, latinize(profile.name), profile.identifier];

  const co = 'bestRole' in profile ? profile.bestRole : bestRole(profile);
  if (co?.company.name && !onlyMatch) terms.push(co.company.name);
  if (onlyMatch === 'title' || !onlyMatch) terms.push(co ? co.title : profile.title);

  return terms
    .map(t => t.toLowerCase())
    .some(
      term =>
        term.startsWith(searchLower) || term.split(' ').some(part => part.startsWith(searchLower))
    );
};

export const sortTags = (a: string, b: string) => {
  if (a === 'Tier-3' || a === 'Scout') {
    return -1;
  } else if (b === 'Tier-3' || b === 'Scout') {
    return 1;
  }
  return a.localeCompare(b);
};

export function inside(point: [lat: number, lng: number], bounds: ConfigJSON.Region['bounds']) {
  // ray-casting algorithm based on
  // https://wrf.ecse.rpi.edu/Research/Short_Notes/pnpoly.html/pnpoly.html

  const x = point[0],
    y = point[1];
  let result = false;
  for (let i = 0, j = bounds.length - 1; i < bounds.length; j = i++) {
    const xi = bounds[i].lat,
      yi = bounds[i].lng;
    const xj = bounds[j].lat,
      yj = bounds[j].lng;
    const yigy = yi > y;
    const yjgy = yj > y;

    const intersect = yigy !== yjgy && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
    if (intersect) result = !result;
  }
  return result;
}

export const toUrl = (type: 'twitter' | 'github', linkOrHandle?: string) => {
  if (!linkOrHandle?.includes(`${type}.com/`)) return `https://${type}.com/${linkOrHandle}`;
  if (!linkOrHandle?.startsWith('https://') && !linkOrHandle?.startsWith('http://'))
    return `https://${linkOrHandle}`;
  return linkOrHandle;
};

export const initialsFrom = (person?: { name?: string }) => {
  if (!person?.name) {
    return '';
  }
  const { first, rest } = getNameParts(person.name);
  const initials = rest ? `${first.substring(0, 1)}${rest.substring(0, 1)}` : first.substring(0, 2);
  return initials;
};

export const interactionDescriptor = (
  interaction: PeopleAPI.SearchResult['lastInteractionTeam']
) => {
  if (!interaction) {
    return '';
  }
  const date = moment(interaction.date).format('MM/DD/YYYY');
  const type = interaction.type;
  switch (type) {
    case 'email-sent':
      return `${interaction.admin.name} sent an email on ${date}.`;
    case 'email-received':
      return `${interaction.admin.name} received an email on ${date}.`;
    case 'inmail-sent':
      return `${interaction.admin.name} sent inmail on ${date}.`;
    case 'inmail-received':
      return `${interaction.admin.name} received inmail on ${date}.`;
    case 'intro':
      return `${interaction.admin.name} made an intro on ${date}.`;
    case 'note':
      return `${interaction.admin.name} left a note on ${date}.`;
    case 'event':
      return `${interaction.admin.name} had an event on ${date}.`;
    default:
      isNever(type);
  }
};

export type WorkOverlap = {
  company: {
    name: string;
    lirID?: string;
  };
  targetJobTitle: string;
  overlapDates: {
    start: number;
    end: number;
  };
};

export const findWorkHistoryOverlap = (
  profileOneWork: CoreAPI.WorkRole[],
  profileTwoWork: CoreAPI.WorkRole[]
) => {
  const cleanProfileOneWork = profileOneWork.filter(j => j.company.lirID && nonIgnoredRole(j));
  mergePromotionsInWorkHistory(cleanProfileOneWork);
  const cleanProfileTwoWork = profileTwoWork.filter(j => j.company.lirID && nonIgnoredRole(j));
  mergePromotionsInWorkHistory(cleanProfileTwoWork);

  const allOverlaps: WorkOverlap[] = [];
  for (const work of cleanProfileOneWork) {
    const profileTwoCoOverlap = cleanProfileTwoWork.find(
      w => w.company.lirID === work.company.lirID
    );

    if (!profileTwoCoOverlap) continue;

    const hasWorkOverlap =
      moment(profileTwoCoOverlap.startTimestamp).isBetween(
        work.startTimestamp,
        work.endTimestamp,
        undefined,
        '[]'
      ) ||
      moment(profileTwoCoOverlap.endTimestamp).isBetween(
        work.startTimestamp,
        work.endTimestamp,
        undefined,
        '[]'
      );

    if (hasWorkOverlap) {
      const start = moment(work.startTimestamp).isAfter(profileTwoCoOverlap.startTimestamp)
        ? work.startTimestamp
        : profileTwoCoOverlap.startTimestamp;
      const end = moment(work.endTimestamp).isBefore(profileTwoCoOverlap.endTimestamp)
        ? work.endTimestamp || Date.now()
        : profileTwoCoOverlap.endTimestamp || Date.now();
      if (start && end) {
        allOverlaps.push({
          company: work.company,
          targetJobTitle: profileTwoCoOverlap.title,
          overlapDates: { start, end },
        });
      }
    }
  }
  return allOverlaps;
};

const MAX_MONTHS_BETWEEN_ROLES_ACCUMULATE = 7;

// copied from backend connection-utils
export const mergePromotionsInWorkHistory = (roles: CoreAPI.WorkRoleMinimal[]) => {
  const nextRoles = roles
    .sort((j1, j2) => {
      if (!j1.company.lirID || !j2.company.lirID) {
        throw new Error('Do not use this method for jobs with no LIR Id');
      }

      const diffJob = j1.company.lirID.localeCompare(j2.company.lirID);
      return diffJob ? diffJob : j1.startTimestamp - j2.startTimestamp;
    })
    .reduce((accumulated, current) => {
      const accumulatedRoleAtCo = [...accumulated]
        .reverse()
        .find(r => r.company.lirID === current.company.lirID); //Reverse = if multiple entries from sep jobs, use most recent
      if (!accumulatedRoleAtCo) {
        accumulated.push(current);
      } else if (!accumulatedRoleAtCo.endTimestamp) {
        return accumulated; //Still in the original role, no need to add more time to endTimestamp
      } else if (
        moment(current.startTimestamp).diff(accumulatedRoleAtCo.endTimestamp, 'months') <=
        MAX_MONTHS_BETWEEN_ROLES_ACCUMULATE
      ) {
        accumulatedRoleAtCo.title += `, ${current.title}`;
        accumulatedRoleAtCo.endTimestamp = current.endTimestamp;
      } else {
        //Worker left job and came back at least 2.5 months later
        accumulated.push(current);
      }
      return accumulated;
    }, [] as CoreAPI.WorkRoleMinimal[]);

  // Update the array in place, but with all the roles replaced
  roles.length = 0;
  roles.push(...nextRoles);
};

export const trimCompanyName = (name: string) => {
  const suffixes = [
    'agency',
    'assn',
    'assoc',
    'associates',
    'association',
    'bank',
    'bv',
    'co',
    'comp',
    'company',
    'corp',
    'corporation',
    'dmd',
    'enterprises',
    'gmbh',
    'group',
    'hotel',
    'hotels',
    'inc',
    'incorporated',
    'intl',
    'international',
    'limited',
    'llc',
    'llp',
    'lp',
    'ltd',
    'manufacturing',
    'mfg',
    'pa',
    'pc',
    'pharmacy',
    'plc',
    'pllc',
    'restaurant',
    'sa',
    'sales',
    'service',
    'services',
    'store',
    'svcs',
    'travel',
    'unlimited',
  ];

  let result = name.replace(/ \([\s\S]*?\)/g, ' ').trim();
  const lastWord = result
    .split(' ')
    .reverse()[0]
    .replace(/[^a-zA-Z0-9]/, '');

  if (suffixes.includes(lastWord.toLowerCase())) {
    result = result.split(' ').slice(0, -1).join(' ').replace(/,\s*$/, '');
  }
  return result;
};
