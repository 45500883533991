import { CommentOutlined } from '@ant-design/icons';
import { Menu, notification } from 'antd';
import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components/macro';
import { ActionButton, SeqButton, SeqDropdownButton } from '../components/Button';
import { HorizontalDivider, LightTooltip } from '../components/Common';
import { buildVariablesMap } from '../components/ExternalTemplateSupport';
import { InputTitle } from '../components/Input';
import { ScheduledSendButton } from '../components/ScheduledSendButton';
import { RoleSelect } from '../components/selects/RoleSelect';
import { IconContainer, TimeIcon } from '../components/Svgs';
import { H4Mono } from '../components/Text';
import { OrganizationContext } from '../contexts/Organization';
import { onStatusUpdate } from '../Helpers';
import { CampaignSummaryWithSender } from '../hooks/useVisibleCampaigns';
import { EXTERNAL_RECIPIENT_VARIABLES, fillInVariables, isValidEmail } from '../shared/helpers';
import { getNameParts } from '../shared/profile-helpers';
import { ScrollingContainer } from '../shared/ScrollableContainer';
import { Colors } from '../shared/Theme';
import { CarouselActions } from './CarouselItem';
import { CandidateWithDetails } from './PageLeads';
import { RejectionForm, RejectionPopover } from './RejectionPopover';

const { BLACK1ALPHA } = Colors.Static;
export const NOTIF_KEY_ALREADY_CONTACTED = 'notif-already-contacted';

export const ReviewFooter: React.FC<{
  alreadyContacted: boolean;
  settings: LeadsAPI.Settings;
  isReviewable: boolean;
  candidate: CandidateWithDetails;
  campaign?: CampaignSummaryWithSender;
  roleId?: number;
  setCustomizing: (c: boolean) => void;
  actions: CarouselActions;
  disableAllFooterActions: string | boolean;
}> = ({
  settings,
  candidate,
  isReviewable,
  actions,
  setCustomizing,
  campaign,
  roleId,
  alreadyContacted,
  disableAllFooterActions,
}) => {
  const { me } = useContext(OrganizationContext);
  const { onReject, onAccept, onLater, onCampaignUpdate } = actions;
  const { requireRejectionFeedback, campaignType } = settings;
  const { profile, review } = candidate;

  useEffect(() => {
    if (!alreadyContacted) {
      return;
    } else {
      if (candidate.profile.status !== 'Pool' || !candidate.review?.status) {
        const firstName = getNameParts(candidate.profile.name).first;
        onLater(true);
        if (!candidate.profile.status) {
          onStatusUpdate(candidate.profile, 'Contacted', me, {
            silent: true,
            onSaved: () => {
              notification.success({
                closeIcon: <div></div>,
                key: NOTIF_KEY_ALREADY_CONTACTED,
                message: `Someone on your team has already contacted ${firstName}. They have now been moved to 'Contacted' in your pipeline. Use the right arrow button to advance.`,
                duration: 0,
              });
            },
          });
        } else {
          notification.success({
            closeIcon: <div></div>,
            key: NOTIF_KEY_ALREADY_CONTACTED,
            message: `${firstName} is already in your pipeline. Use the right arrow button to advance.`,
            duration: 0,
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [candidate.profile.id, alreadyContacted]);

  const acceptDisabled = alreadyContacted
    ? 'Already Contacted'
    : review?.status === 'approved' || !campaign || !!disableAllFooterActions;

  return (
    <LightTooltip
      overlayStyle={{ display: actions.disabledReason ? undefined : 'none', maxWidth: 600 }}
      overlay={<div style={{ fontSize: 14 }}>{actions.disabledReason}</div>}
    >
      <Container $axis="x">
        <div style={{ display: 'flex', flex: 1, gap: 10 }}>
          {requireRejectionFeedback ? (
            <RejectionPopover
              candidate={candidate}
              disabled={!isReviewable}
              key={`${profile.id}-reject-pop`}
              onReject={onReject}
            >
              <SeqButton danger disabled={!isReviewable || !!disableAllFooterActions}>
                Reject (N)
              </SeqButton>
            </RejectionPopover>
          ) : (
            <SeqDropdownButton
              onClick={() => onReject()}
              danger
              disabled={!isReviewable || !!disableAllFooterActions}
              icon={<CommentOutlined />}
              trigger={['click']}
              overlay={
                <Menu>
                  <RejectionForm
                    candidate={candidate}
                    key={`${profile.id}-reject-pop`}
                    onReject={onReject}
                  />
                </Menu>
              }
            >
              Reject (N)
            </SeqDropdownButton>
          )}
          <LightTooltip
            overlay="Add this candidate to your pipeline to come back to later."
            placement="topLeft"
          >
            <SeqButton
              disabled={!isReviewable || !!disableAllFooterActions}
              onClick={() => onLater()}
            >
              <IconContainer>
                <TimeIcon />
              </IconContainer>
              (L)
            </SeqButton>
          </LightTooltip>
        </div>
        {campaignType === 'LinkedIn' ? (
          <LinkedInApprovalButton
            onClick={() => {
              onAccept();
              window.open(`https://www.linkedin.com/in/${profile.identifier}`, '_blank');
            }}
            profile={profile}
            roleId={roleId}
            onUpdateRole={id => {
              if (id) {
                onCampaignUpdate({ roleId: id, campaignType });
              }
            }}
            disabled={review?.status === 'approved' || !!disableAllFooterActions}
          />
        ) : (
          <>
            {!acceptDisabled && (
              <SeqButton
                disabled={
                  !isReviewable || review?.status === 'approved' || !!disableAllFooterActions
                }
                onClick={() => setCustomizing(true)}
              >
                Custom Email (E)
              </SeqButton>
            )}
            <ScheduledSendButton
              onSend={deliverAt => {
                if (!isValidEmail(profile.email)) {
                  setCustomizing(true);
                } else {
                  onAccept({ deliverAt });
                }
              }}
              extraButtonText="(Y)"
              campaign={campaign}
              disabled={acceptDisabled}
              defaultToLastUsed
            />
          </>
        )}
      </Container>
    </LightTooltip>
  );
};

const Container = styled(ScrollingContainer)`
  display: flex;
  margin: 16px 24px 0 24px;
  gap: 10px;
`;

const LinkedInApprovalButton: React.FC<{
  roleId?: number;
  disabled: boolean;
  onUpdateRole: (id?: number) => void;
  onClick: () => void;
  profile: ExternalAPI.Profile;
}> = ({ roleId, disabled, onUpdateRole, onClick, profile }) => {
  const history = useHistory();
  const { me, roles, id } = useContext(OrganizationContext);
  const variables = buildVariablesMap(
    me,
    EXTERNAL_RECIPIENT_VARIABLES,
    profile,
    me.templateVariables
  );

  const message = roleId && roles.find(r => roleId === r.id)?.messageForLinkedInOutreach;
  let noRoleMessage = '';
  if (!message) {
    noRoleMessage = !roleId
      ? 'Select a role in order to access the default LinkedIn campaign message set up for that role.'
      : `There is currently no default LinkedIn message for this role. Click the 'Edit' button to add a default message in 'Settings.'`;
  }

  const menu = (
    <Menu style={{ width: 350 }}>
      <H4Mono>LinkedIn Outreach Message</H4Mono>
      <HorizontalDivider />
      <div style={{ padding: '12px 24px' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <InputTitle size="small" title="Role:" />
          <RoleSelect
            role={roleId}
            setRole={onUpdateRole}
            style={{ flex: 1 }}
            optionsPlacement="topRight"
            opts={{
              filterRoleOptions: r => !r.deletedBy,
              blockSelectNone: true,
              defaultSelectOnSingleOption: true,
            }}
          />
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <InputTitle size="small" title="Message:" />
          <ActionButton onClick={() => history.push(`/${id}/settings`)}>Edit</ActionButton>
        </div>
        <div
          style={{
            fontSize: 12,
            background: BLACK1ALPHA,
            whiteSpace: 'pre-wrap',
            padding: 12,
            fontStyle: message ? undefined : 'italic',
          }}
        >
          {message ? fillInVariables(message, variables) : noRoleMessage}
        </div>
      </div>
      <HorizontalDivider />
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          padding: '12px 24px',
          justifyContent: 'flex-end',
        }}
      >
        <SeqButton
          intent="primary"
          disabled={!message}
          onClick={() => message && navigator.clipboard.writeText(message)}
        >
          Copy Message
        </SeqButton>
      </div>
    </Menu>
  );

  return (
    <SeqDropdownButton
      onClick={onClick}
      disabled={disabled}
      type="primary"
      overlay={menu}
      style={disabled ? { marginLeft: 8, pointerEvents: 'none', opacity: 0.4 } : { marginLeft: 8 }}
    >
      Approve (Y)
    </SeqDropdownButton>
  );
};

export const closeCarouselNotifs = () => {
  notification.destroy(NOTIF_KEY_ALREADY_CONTACTED);
};
