import { Link } from 'react-router-dom';
import { updateLeadList } from '../../leads/PageLeads';
import { AddCandidatesPopover } from '../../pipeline/AddCandidatesPopover';
import { useResource } from '../../shared/Resource';
import { ActionButton } from '../Button';
import { TicketIcon } from '../Svgs';

export function useLeadsRow({
  profile,
  linkToNewTab,
  onProfileSaved,
}: {
  profile: ExternalAPI.Profile;
  linkToNewTab?: boolean;
  onProfileSaved: () => void;
}) {
  const isInOrg = profile.id !== -1;
  const { organizationId } = profile;

  const [leadLists = [], { applyLocalUpdates }] = useResource<LeadsAPI.LeadList[]>(
    isInOrg && !profile.status ? `/api/${organizationId}/lead-lists` : '',
    { all: true }
  );

  if (!profile || profile.status) {
    return null;
  }

  const leadList = leadLists.find(b => b.profiles.find(p => p.externalId === profile.id));
  const candidateReview = leadList?.profiles.find(p => p.externalId === profile.id);

  let content = '--';
  if (candidateReview) {
    switch (candidateReview?.review?.status) {
      case 'approved':
        content = 'Approved';
        break;
      case 'rejected':
        content = 'Rejected';
        break;
      case 'later':
        content = `Added to Pool in pipeline`;
        break;
      case 'requested-intro':
        content = 'Requested Intro';
        break;
      default:
        content = 'Currently in a Lead List, but has not been reviewed yet.';
    }
  }

  const addToLeadList = async (list: LeadsAPI.UpdateLeadList) => {
    const update = await updateLeadList(organizationId, list, leadList);
    const updatedLists = [...leadLists];
    if (update) {
      if ('id' in list) {
        const idx = updatedLists.findIndex(l => l.id === list.id);
        updatedLists[idx] = update;
      } else {
        updatedLists.push(update);
      }
    }
    if (!isInOrg) {
      // We need to reload the profile to get the external ID or we won't show the
      // lead list they're now in.
      onProfileSaved();
    }
    applyLocalUpdates(updatedLists);
  };

  const actions = leadList ? (
    <ActionButton>
      <Link
        to={`/${profile.organizationId}/leads?listId=${leadList.id}`}
        {...(linkToNewTab ? { target: '_blank', rel: 'noopener noreferrer' } : {})}
      >
        View
      </Link>
    </ActionButton>
  ) : (
    <AddCandidatesPopover config={{ addToLeadList, profile }}>
      <ActionButton>Add</ActionButton>
    </AddCandidatesPopover>
  );

  return { icon: <TicketIcon />, title: 'Lead List', content, actions };
}
