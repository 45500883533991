import { LoadingOutlined } from '@ant-design/icons';
import { AutoComplete, Input } from 'antd';
import qs from 'query-string';
import React, { useState } from 'react';
import { CSSProperties } from 'styled-components/macro';
import { makeRequest } from './Resource';
import { Colors } from './Theme';

//Uses the superior AutoCompleteAPI from google, but isn't compatible with our company db model atm
export const LocationInputV2: React.FunctionComponent<{
  baseAPIPath: string;
  value: LocationAPI.Place | null;
  onChange: (newVal: LocationAPI.Place | null) => void;
  autoFocus?: boolean;
  style?: CSSProperties;
  placeholder?: string;
  clearAfterSelection?: boolean;
}> = ({
  baseAPIPath: basePath,
  autoFocus,
  value,
  onChange,
  style,
  placeholder,
  clearAfterSelection,
}) => {
  const [text, setText] = useState(value?.name || '');
  const [focused, setFocused] = useState(false);
  const [options, setOptions] = useState<LocationAPI.AutoCompletePrediction[]>([]);
  const [busy, setBusy] = useState(false);

  const fetchAutoCompletes = async (inputText: string) => {
    if (!inputText) {
      return [];
    }
    return await makeRequest<LocationAPI.AutoCompletePrediction[]>(
      `${basePath}/location/autoComplete?${qs.stringify({ q: inputText })}`
    );
  };

  const onSearch = async (inputText: string) => {
    if (!inputText) {
      setOptions([]);
      onChange(null);
      return [];
    }
    setBusy(true);
    const results = await fetchAutoCompletes(inputText);
    setOptions(results || []);
    setBusy(false);
    value && onChange(null); //Reset the value so that stale coords are not used while querying
    return options;
  };

  const onSelect = async (selectedDescription: string) => {
    const selectedPlace = options.find(p => p.description === selectedDescription);
    if (!selectedPlace) {
      return;
    }
    setBusy(true);
    const locDetails = await makeRequest<LocationAPI.Place>(
      `${basePath}/location/details?${qs.stringify({ placeId: selectedPlace?.place_id })}`
    );
    setBusy(false);

    if (locDetails?.name) {
      setText(selectedPlace.description);
      onChange({
        name: selectedPlace.description,
        latitude: locDetails.latitude,
        longitude: locDetails.longitude,
      });
      clearAfterSelection && setText('');
    } else {
      setText('');
      onChange(null);
    }
  };

  const invalid = !focused && text && (!value?.latitude || !value.longitude);

  const onBlur = async () => {
    setFocused(false);

    if (text.length && value?.name !== text) {
      if (options.length) {
        await onSelect(options[0].description);
      } else {
        setBusy(true);
        const [option] = await fetchAutoCompletes(text);
        setBusy(false);
        if (option) {
          await onSelect(option.description);
        } else {
          onChange(null);
        }
      }
    } else if (!text.length && value) {
      onChange(null);
    }
  };

  return (
    <AutoComplete
      value={text}
      autoFocus={autoFocus}
      defaultActiveFirstOption={true}
      onFocus={() => setFocused(true)}
      onBlur={onBlur}
      onSelect={onSelect}
      onSearch={onSearch}
      onChange={textNew => setText(textNew || '')}
      allowClear={!busy}
      options={options.map(o => ({ key: o.place_id, value: o.description }))}
      style={{
        width: '100%',
        border: invalid ? `1px solid ${Colors.Static.DESTRUCTIVE_TINT}` : '1px solid transparent',
        ...style,
      }}
    >
      <Input
        placeholder={placeholder || 'San Francisco, CA'}
        suffix={
          <div style={{ width: 0, marginLeft: -15, overflow: 'visible' }}>
            {busy ? <LoadingOutlined /> : undefined}
          </div>
        }
      />
    </AutoComplete>
  );
};
