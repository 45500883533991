import { MentionProps } from 'antd/lib/mentions';
import 'quill-mention';
import 'quill-mention/dist/quill.mention.min.css';
import React from 'react';
import ReactQuill from 'react-quill';
import styled from 'styled-components/macro';
import { SequoiaQuillCSS } from '../components/ExternalTemplateEditor';
import { OrganizationContext, OrganizationContextData } from '../contexts/Organization';
import { Colors } from '../shared/Theme';
import { MentionsDisplay } from './MentionsDisplay';
import { buildCompletions, findMentionsInText } from './MentionsSupport';

export type RichMentionsEditorValue = { text: string; mentions: ExternalAPI.NoteMentions };
interface RichMentionsEditorProps extends Omit<MentionProps, 'value' | 'onChange'> {
  value: RichMentionsEditorValue;
  onChange: (value: RichMentionsEditorValue) => void;
  onKeyDown?: React.EventHandler<any>;
  notificationsDisabled?: boolean;
  placeholder?: string;
  minHeight?: number;
  scrollableContainerName?: string; //Pass .className of scrolling container if you encounter unwanted scroll-up in quill.
  inExtension?: boolean;
  plain?: boolean;
}

interface RichMentionsEditorState {
  changed: boolean;
  notify: boolean;
}

export class RichMentionsEditor extends React.Component<
  RichMentionsEditorProps,
  RichMentionsEditorState
> {
  static contextType = OrganizationContext;

  modules: any | undefined;
  editorLastValue: string = '';
  editorKey: number = 1;

  constructor(props: RichMentionsEditorProps) {
    super(props);

    this.editorLastValue = props.value.text;
    this.modules = {
      clipboard: {
        matchVisual: false,
      },
      toolbar: props.plain
        ? []
        : [
            ['bold', 'italic', 'underline', 'clean'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['blockquote', 'link'],
            [{ indent: '-1' }, { indent: '+1' }],
          ],
      mention: {
        allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
        mentionDenotationChars: ['@'],
        source: this.mentionSource,
      },
    };
    this.state = {
      changed: false,
      notify: props.notificationsDisabled ? false : true,
    };
  }

  mentionSource = (
    searchTerm: string,
    renderList: (options: { id: number; value: string | undefined }[], search: string) => void
  ) => {
    const session = this.context as OrganizationContextData;
    // TODO: Figure out which team members should be included in the "matches" list.
    const matches = [...session.team]
      .map(t => ({ id: t.id, value: t.name || '' }))
      .sort((a, b) => a.value.localeCompare(b.value))
      .filter(
        t => !searchTerm.length || t.value?.toLowerCase().startsWith(searchTerm.toLowerCase())
      )
      .slice(0, 8);
    renderList(matches, searchTerm);
  };

  onChange = (text: string) => {
    this.editorLastValue = text;

    const myOrganization = this.context;
    const completions = buildCompletions(myOrganization);

    this.props.onChange({
      text,
      mentions: {
        teamIds: findMentionsInText(text, completions.team),
      },
    });
    if (!this.state.changed) {
      this.setState({ changed: true });
    }
  };

  render() {
    const { value, placeholder, onChange, notificationsDisabled, minHeight } = this.props;
    const { changed, notify } = this.state;
    const myOrganization = this.context;

    const completions = buildCompletions(myOrganization);
    const presentTeamIds = findMentionsInText(value.text, completions.team);

    if (value.text !== this.editorLastValue) {
      this.editorKey++;
    }

    return (
      <>
        <MinHeightQuill
          inExtension={!!this.props.inExtension}
          scrollingContainer={this.props.scrollableContainerName}
          theme="snow"
          minHeight={minHeight || 200}
          key={`${this.editorKey}`}
          modules={this.modules}
          formats={
            this.props.plain
              ? ['mention']
              : [
                  'bold',
                  'italic',
                  'underline',
                  'link',
                  'mention',
                  'list',
                  'blockquote',
                  'indent',
                  'strike',
                ]
          }
          placeholder={placeholder}
          defaultValue={value.text}
          onKeyDown={this.props.onKeyDown}
          onChange={this.onChange}
        />
        {presentTeamIds.length > 0 && changed && !notificationsDisabled && (
          <MentionsDisplay
            mentionedItems={completions.team.filter(t => presentTeamIds.includes(t.id))}
            notify={notify}
            setNotify={notify => {
              this.setState({ notify });
              onChange({
                text: value.text,
                mentions: {
                  teamIds: findMentionsInText(value.text, completions.team),
                },
              });
            }}
          />
        )}
      </>
    );
  }
}

//.Clipboard explanation: https://github.com/zenoamaro/react-quill/issues/394
const MinHeightQuill = styled(ReactQuill)<{ minHeight: number; inExtension: boolean }>`
  ${SequoiaQuillCSS};

  & > .ql-container > .ql-editor {
    min-height: ${({ minHeight }) => minHeight}px !important;
  }
  & .ql-mention-list .ql-mention-list-item {
    line-height: 30px;
    font-size: 14px;
    padding: 0 10px;
  }
  & .ql-mention-list-item.selected {
    background: ${Colors.Static.SEQUOIA_GREEN}22;
  }
  & .ql-clipboard {
    position: ${({ inExtension }) => (inExtension ? 'fixed' : '')};
  }
`;
