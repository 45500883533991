import { CloseOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import {
  EditableExcludeRulesMap,
  EditableRuleIds,
  EditorTooltip,
  invalid,
  NumberInputWithLabel,
  onChangeWithValidation,
  RuleEditorProps,
  useDescription,
  valid,
  ValidationInfo,
} from '../rule-editors/RuleEditorCommon';
import { Colors } from '../Theme';
import { EditorInput, Explanatory, HeaderRow, REGEX_EXPLANATION, Rule } from './RuleEditorCommon';

export const HeldTitleEditor: React.FC<RuleEditorProps> = ({
  rule,
  onChange,
  style,
  title,
  ruleType,
}) => {
  const replaceParam = (value: string | null, index: number) => {
    if (value === null) {
      onChangeWithValidation(
        { ...rule, parameters: rule.parameters.filter((_, idx) => idx !== index) },
        onChange,
        validate
      );
    } else {
      const replaced = [...rule.parameters];
      replaced[index] = value;
      onChangeWithValidation({ ...rule, parameters: replaced }, onChange, validate);
    }
  };

  const keywords = [...rule.parameters.slice(1)];
  if (keywords.every(word => !!word)) {
    keywords.push('');
  }

  const desc = useDescription(
    { rule, ruleType },
    {
      include: 'List job titles which should boost someone in the results of this model:',
      exclude: `Add job titles which are not relevant to your role in the field below. Often these are job
  titles parallel to the role you are searching for, but are too junior or too senior, or
  contain specific irrelevant keywords. eg: 'VP Engineering' when looking for engineers.`,
      invert: 'Only show people who match these exact job titles:',
    }
  );

  return (
    <div style={style}>
      {title && <HeaderRow label={title} onChange={onChange} rule={rule} />}
      <NumberInputWithLabel
        label={'Months (historically) of work history to search'}
        tooltipText={
          'A zero here indicates that only the people current title should be used. 12 would be mean any title which the person has held in the past year will be checked.'
        }
        value={rule.parameters[0]}
        onChange={val => replaceParam(`${val}`, 0)}
      />
      <Explanatory>{desc}</Explanatory>
      <div style={{ marginLeft: 15, marginTop: 20 }}>
        {keywords.map((keyword, index) => (
          <div key={`keyword-${index}`} style={{ display: 'flex', alignItems: 'center' }}>
            <EditorInput
              placeholder={'Enter a keyword'}
              style={{ width: 380, marginBottom: 10 }}
              value={keyword}
              onChange={e => replaceParam(e.currentTarget.value || null, index + 1)}
            />
            {!!keyword && (
              <Button
                tabIndex={-1}
                style={{ color: Colors.Static.SEQUOIA_BRIGHT_RED }}
                onClick={() => replaceParam(null, index + 1)}
                type={'link'}
              >
                <CloseOutlined style={{ marginLeft: 20 }} />
              </Button>
            )}
            {index === 0 && <EditorTooltip text={REGEX_EXPLANATION} />}
          </div>
        ))}
      </div>
    </div>
  );
};

const validate = (rule: Rule): ValidationInfo => {
  const ruleLabel = EditableExcludeRulesMap[rule.id as EditableRuleIds]?.label || '';
  if (
    !rule.parameters?.length ||
    rule.parameters?.length < 2 ||
    isNaN(Number(rule.parameters[0])) ||
    rule.parameters?.every(p => !p)
  ) {
    return invalid(`'${ruleLabel}' requires at least one job title to be entered.`);
  }
  return valid();
};
