import styled from 'styled-components/macro';
import { CampaignMemberFilter } from './pages/PageCampaignDetails';
import { Colors } from './shared/Theme';

const { SEQUOIA_BRIGHT_RED } = Colors.Static;

export const DESTRUCTIVE_CHART_COLORS = [SEQUOIA_BRIGHT_RED, '#ff8177', '#ffada2', '#ffd6d0'];

export const MAX_CHARTS_SLIDER = 4;
export const DEFAULT_CHART_HEIGHT = 255;

export const filterFromLabelClick = (clickedOn: string): CampaignMemberFilter | undefined => {
  if (clickedOn === 'Replied') {
    return {
      label: clickedOn,
      execute: (candidate: CampaignAPI.CampaignMember) => {
        return !!candidate.candidateRespondedAt;
      },
    };
  } else if (clickedOn === 'Replied Positively') {
    return {
      label: clickedOn,
      execute: (candidate: CampaignAPI.CampaignMember) => {
        return candidate.responseSentiment === 'Positive';
      },
    };
  }
};

export const ChartRowContainer = styled.div<{ height: number }>`
  display: flex;
  height: ${({ height }) => `${height}px`};
  padding-left: 28px;
  padding-top: 24px;
  padding-bottom: 24px;
`;
