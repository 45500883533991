import { isEqual } from 'lodash';

export const NETWORK_OWNER_SELECT_ALL_ID = -1;
export const NETWORK_OWNER_SELECT_FIRST_DEGREE_ID = -2;
export const NETWORK_OWNER_SELECT_SECOND_DEGREE_ID = -3;
export const NETWORK_OWNER_SELECT_SHARED_ID = -4;
export const NETWORK_OWNER_SELECT_MANAGE_COHORT = -5;
export const NETWORK_OWNER_ADD_TEAM_MEMBER_ID = -6;
export const NETWORK_OWNER_MANAGE_NETWORKS_ID = -7;

export const EMPTY_SEARCH: NetworkAPI.CreateSearchHistory = {
  createdAt: new Date(),
  isTab: false,
  data: {},
};

export function isEmptySearch(search: NetworkAPI.CreateSearchHistory) {
  return isEqual(search, EMPTY_SEARCH);
}
