import React from 'react';
import { InitialsView } from '../shared/ProfileImage';

export const AvatarWithStar: React.FunctionComponent<{
  endorsements: ExternalAPI.Endorsement[];
  name: string;
  size?: 'small' | 'medium' | 'large';
}> = ({ endorsements, name, size = 'medium' }) => {
  let avatarSize = 48;
  let fontSize = 16;
  if (size === 'small') {
    avatarSize = 32;
    fontSize = 12;
  }
  if (size === 'large') {
    avatarSize = 64;
    fontSize = 28;
  }

  return (
    <div style={{ display: 'flex', height: avatarSize }}>
      <InitialsView
        name={name}
        style={{ width: avatarSize, height: avatarSize, fontSize, fontFamily: 'Unica77' }}
      />
      {size !== 'small' && (
        <div style={{ width: 0, position: 'relative' }}>
          <img
            style={{
              visibility: endorsements.some(e => e.score === 'B' || e.score === 'A')
                ? 'visible'
                : 'hidden',
              position: 'absolute',
              top: size === 'medium' ? 18 : 28,
              left: size === 'medium' ? -20 : -22,
              height: size === 'medium' ? 32 : 40,
            }}
            alt="star"
            src="/icons/glowing-star.svg"
          />
          <img
            style={{
              visibility: endorsements.some(e => e.score === 'A') ? 'visible' : 'hidden',
              position: 'absolute',
              top: size === 'medium' ? 29 : 46,
              left: size === 'medium' ? -32 : -36,
              height: size === 'medium' ? 32 : 40,
            }}
            alt="star"
            src="/icons/glowing-star.svg"
          />
        </div>
      )}
    </div>
  );
};
