import { Popover } from 'antd';
import moment from 'moment';
import { default as React, useContext, useState } from 'react';
import styled from 'styled-components/macro';
import { IconLinkButton, InfoButton } from '../components/Button';
import { HorizontalDivider } from '../components/Common';
import { Body2, Body2Serif, H3Mono, Label2 } from '../components/Text';
import { OrganizationContext } from '../contexts/Organization';
import { pluralize } from '../shared/helpers';
import { useResource } from '../shared/Resource';
import { Colors } from '../shared/Theme';

const { BLACK7ALPHA } = Colors.Static;

export const CompanyInfoButton: React.FC<{
  initCompany: {
    name: string;
    lirID?: string;
  };
}> = ({ initCompany }) => {
  const [popoverVisible, setPopoverVisible] = useState<boolean>(false);
  const onClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    setPopoverVisible(v => !v);
  };

  return popoverVisible ? (
    <CompanyInfoPopover
      initCompany={initCompany}
      visible={popoverVisible}
      setPopoverVisible={setPopoverVisible}
    >
      <InfoButton onClick={onClick}>{initCompany.name}</InfoButton>
    </CompanyInfoPopover>
  ) : (
    <InfoButton onClick={onClick}>{initCompany.name}</InfoButton>
  );
};

const CompanyInfoPopover: React.FC<{
  initCompany: { name: string; lirID?: string };
  visible: boolean;
  setPopoverVisible: (v: boolean) => void;
}> = React.memo(({ visible, initCompany, setPopoverVisible, children }) => {
  const { id } = useContext(OrganizationContext);
  const [companyInfo] = useResource<NetworkAPI.Company>(
    initCompany.lirID ? `/api/${id}/company/${initCompany.lirID}` : ''
  );
  const company = companyInfo || {
    ...initCompany,
    linkedinURL: `https://linkedin.com/company/${initCompany.lirID}`,
    description: null,
    categories: [],
    domain: '',
    headcount: 0,
    lastRound: null,
    lastRoundDate: null,
    monthsSinceLastRound: null,
    region: '',
  };

  return (
    <Popover
      overlayClassName={'seq-no-padding'}
      trigger={'click'}
      content={() => (
        <div style={{ width: 350 }}>
          <div style={{ padding: 16 }}>
            <div
              style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}
            >
              <H3Mono style={{ lineHeight: 1.1 }}>{company.name}</H3Mono>
              <div style={{ display: 'flex', gap: 5 }}>
                {company.domain && (
                  <IconLinkButton size="small" link={`http://www.${company.domain}`} />
                )}
                {company.linkedinURL && (
                  <IconLinkButton size="small" link={company.linkedinURL} type="linkedIn" />
                )}
              </div>
            </div>
            <Body2 style={{ color: BLACK7ALPHA, marginTop: 4 }}>{company.region}</Body2>
          </div>
          <HorizontalDivider />
          <div style={{ padding: 16, display: 'flex', flexDirection: 'column', gap: 12 }}>
            {company.description && (
              <>
                <Body2Serif>{company.description}</Body2Serif>
                <HorizontalDivider />
              </>
            )}
            <SectionContainer>
              <Label2>Headcount:</Label2>
              <div>{company.headcount ? company.headcount.toLocaleString() : '--'}</div>
            </SectionContainer>
            <SectionContainer>
              <Label2>Last Round:</Label2>
              <div>{company.lastRound || '--'}</div>
            </SectionContainer>
            <SectionContainer>
              <Label2>Last Round Date:</Label2>
              <div>
                {company.monthsSinceLastRound ? (
                  <>
                    <div>{moment(company.lastRoundDate).format('MMM D, YYYY')}</div>
                    <div
                      style={{
                        fontSize: 12,
                        fontStyle: 'italic',
                        fontWeight: 400,
                        textTransform: 'none',
                      }}
                    >
                      {pluralize(company.monthsSinceLastRound, 'month')} ago
                    </div>
                  </>
                ) : (
                  '--'
                )}
              </div>
            </SectionContainer>
          </div>
        </div>
      )}
      placement={'right'}
      open={visible}
      onOpenChange={setPopoverVisible}
      arrowPointAtCenter
    >
      {children}
    </Popover>
  );
});

const SectionContainer = styled.div`
  display: flex;
  align-items: baseline;
  gap: 12px;
`;
