import { LoadingOutlined } from '@ant-design/icons';
import React, { ReactElement, useContext, useState } from 'react';
import { ProfileNotifs } from '../../contexts/Notification';
import { OrganizationContext } from '../../contexts/Organization';
import { getHireLock, onProfileUpsert, shortenedName } from '../../Helpers';
import { toLinkedinURL } from '../../shared/li-utils';
import { BriefcaseIcon, ChatIcon } from '../../shared/Svgs';
import { Colors } from '../../shared/Theme';
import { ActionButton } from '../Button';
import { WorkAndEducation } from '../CandidateProfile';
import { ListRow, RowProps } from '../ListRow';
import { UpsertCandidateModal } from '../modals/UpsertCandidateModal';
import { LockPopover } from '../Popover';
import { RoleSelect } from '../selects/RoleSelect';
import { StatusSelect } from '../selects/StatusSelect';
import { TeamMemberSelect } from '../selects/TeamMemberSelect';
import { HatIcon, PipelineIcon } from '../Svgs';
import { ExtensionConfig } from './ProfileDrawer';
import { useEmailRow } from './useEmailRow';
import { useLeadsRow } from './useLeadsRow';
import { useReminderRow } from './useReminderRow';
import { useTagsRow } from './useTagsRow';

const { SEQUOIA_GREEN, SEQUOIA_BLACK } = Colors.Static;

export interface EmailConfig {
  hasScheduledEmails: boolean;
  emailInfo: PipelineAPI.EmailInteraction | undefined;
  onClickSendEmail: () => void;
}

interface ProfileSummaryProps<T> {
  profile: T;
  reminder?: ProfileNotifs['reminder'];
  onProfileSaved: (profile?: T) => void;
  getRelatedProfileName: (id: number) => string;
  emailConfig: EmailConfig;
  extensionConfig?: ExtensionConfig;
  editModalVisible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  onDelete?: () => void;
}

export const ProfileSummary = ({
  profile,
  reminder,
  onProfileSaved,
  emailConfig,
  extensionConfig,
  editModalVisible,
  setVisible,
  onDelete,
  getRelatedProfileName,
}: ProfileSummaryProps<ExternalAPI.Profile>): ReactElement => {
  const { me } = useContext(OrganizationContext);
  const { ownerId, status, name, identifier, details } = profile;
  const { emailInfo } = emailConfig;
  const hireLock = getHireLock(me.id, ownerId, status);
  const shortenedProfileName = shortenedName(name);

  const emailRowProps = useEmailRow({ profile, onProfileSaved, emailConfig });
  const tagsRowProps = useTagsRow({ profile, onProfileSaved });
  const leadsRowProps = useLeadsRow({ profile, linkToNewTab: !!extensionConfig, onProfileSaved });
  const reminderRowProps = useReminderRow({ profile, reminder, onProfileSaved });
  const roleRowProps = useRoleRow({ profile, shortenedProfileName, onProfileSaved });
  const ownerRowProps = useOwnerRow({ profile, hireLock, shortenedProfileName, onProfileSaved });

  const stageRowProps = {
    icon: <PipelineIcon />,
    title: 'Stage',
    content: hireLock ? (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        Hired
        <LockPopover />
      </div>
    ) : (
      <StatusSelect profile={profile} onProfileSaved={onProfileSaved} placeholder="none" />
    ),
  };

  const primaryRows: RowProps[] = [roleRowProps, stageRowProps, emailRowProps];

  if (!!emailInfo?.teamSummary?.length) {
    primaryRows.push({
      icon: <ChatIcon />,
      title: 'Contact',
      content: (
        <div>
          {emailInfo.teamSummary.map(c => (
            <div key={`interaction-by-${c.teamMember}`}>
              {shortenedName(c.teamMember)} reached out
              {c.candidateResponded && ` and ${shortenedProfileName} responded`}
            </div>
          ))}
        </div>
      ),
    });
  }
  primaryRows.push(tagsRowProps);
  primaryRows.push(ownerRowProps);
  primaryRows.push(reminderRowProps);
  if (leadsRowProps) {
    primaryRows.push(leadsRowProps);
  }

  primaryRows[primaryRows.length - 1].hasDivider = false;

  return (
    <>
      <div style={{ padding: '16px 0' }}>
        {primaryRows.map((r, idx) => (
          <ListRow {...r} key={`${idx}-${r.title}`} />
        ))}
      </div>
      {!!details.work || !!details.education ? (
        <WorkAndEducation compactMode details={details} />
      ) : (
        <div style={{ marginTop: 40, color: SEQUOIA_BLACK, textAlign: 'center' }}>
          {details?.lirProfileUnavailable ? (
            <div>
              {shortenedProfileName}'s profile could not be retrieved&nbsp;from&nbsp;LinkedIn.
            </div>
          ) : toLinkedinURL(identifier) ? (
            <>
              <LoadingOutlined
                style={{ color: SEQUOIA_GREEN, fontSize: 30, marginBottom: 16 }}
                spin
              />
              <div>Retrieving Profile Details</div>
              <div>Come back soon...</div>
            </>
          ) : (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div>
                Add a LinkedIn identifier for this candidate in order to get
                additional&nbsp;profile&nbsp;details.
              </div>
              <UpsertCandidateModal
                config={{ onDelete, onUpsert: onProfileSaved, profile }}
                visible={editModalVisible}
                setVisible={setVisible}
              />
              <ActionButton
                onClick={() => setVisible(true)}
                style={{ marginTop: 15, alignSelf: 'center' }}
              >
                Add
              </ActionButton>
            </div>
          )}
        </div>
      )}
    </>
  );
};

function useRoleRow({
  profile,
  shortenedProfileName,
  onProfileSaved,
}: {
  profile: ExternalAPI.Profile;
  shortenedProfileName: string;
  onProfileSaved: (profile?: ExternalAPI.Profile) => void;
}) {
  const [role, setRole] = useState(profile.roleId);

  return {
    icon: <BriefcaseIcon />,
    title: 'Role',
    content: (
      <RoleSelect
        profile={profile}
        role={role || undefined}
        setRole={setRole}
        candidateName={shortenedProfileName}
        onProfileSaved={onProfileSaved}
      />
    ),
  };
}

function useOwnerRow({
  profile,
  hireLock,
  shortenedProfileName,
  onProfileSaved,
}: {
  hireLock: boolean;
  profile: ExternalAPI.Profile;
  shortenedProfileName: string;
  onProfileSaved: () => void;
}) {
  const { me, team } = useContext(OrganizationContext);
  const { ownerId } = profile;

  const onOwnerUpdate = async (owner: { id: number; name: string }) => {
    await onProfileUpsert(
      profile,
      {
        ownerId: owner.id,
        activities: [
          {
            user: me.name,
            explanation: `updated ${shortenedProfileName}'s owner to ${owner.name}`,
            createdAt: new Date(),
          },
        ],
      },
      onProfileSaved,
      `${owner.name} has been updated as owner for ${shortenedProfileName}.`
    );
  };

  return {
    icon: <HatIcon />,
    title: 'Owner',
    content: (
      <>
        {hireLock ? (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {[me, ...team].find(o => o.id === ownerId)?.name}
            <LockPopover />
          </div>
        ) : (
          <TeamMemberSelect onChange={onOwnerUpdate} value={ownerId} placeholder="none" />
        )}
        <div style={{ flex: 1 }} />
      </>
    ),
  };
}
