import { CloseOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import React from 'react';
import {
  EditableExcludeRulesMap,
  EditableRuleIds,
  invalid,
  onChangeWithValidation,
  RuleEditorProps,
  useDescription,
  valid,
  ValidationInfo,
} from '../rule-editors/RuleEditorCommon';
import { Colors } from '../Theme';
import { EditorInput, Explanatory, HeaderRow, REGEX_EXPLANATION, Rule } from './RuleEditorCommon';

export const MovedJobTitlesEditor: React.FC<RuleEditorProps> = ({
  rule,
  onChange,
  style,
  title,
  ruleType,
}) => {
  const fromTitles: string[] = JSON.parse(rule.parameters[0]);
  if (fromTitles.every(word => !!word)) {
    fromTitles.push('');
  }

  const toTitles: string[] = JSON.parse(rule.parameters[1]);
  if (toTitles.every(word => !!word)) {
    toTitles.push('');
  }

  const replaceParam = (value: string | null, index: number, paramArrIdx: number) => {
    const updatedParams = [...rule.parameters];
    if (value === null) {
      updatedParams[paramArrIdx] =
        paramArrIdx === 0
          ? JSON.stringify(fromTitles.filter((_, idx) => idx !== index))
          : JSON.stringify(toTitles.filter((_, idx) => idx !== index));
      onChangeWithValidation(
        {
          ...rule,
          parameters: updatedParams,
        },
        onChange,
        validate
      );
    } else {
      const updatedTitleArr = paramArrIdx === 0 ? [...fromTitles] : [...toTitles];
      updatedTitleArr[index] = value;
      updatedParams[paramArrIdx] = JSON.stringify(updatedTitleArr);
      onChangeWithValidation({ ...rule, parameters: updatedParams }, onChange, validate);
    }
  };

  const desc = useDescription(
    { rule, ruleType },
    {
      include: 'Boost people who have moved from any of job titles X to any of job titles Y',
      exclude:
        'Exclude people who stay who have moved from any of job titles X to any of job titles Y',
      invert: `People must have moved from any of job titles X to any of job titles Y to be included`,
    }
  );

  return (
    <div style={style}>
      {title && <HeaderRow label={title} onChange={onChange} rule={rule} />}
      <Explanatory>{desc}</Explanatory>
      <div style={{ display: 'flex' }}>
        <div style={{ marginLeft: 15, marginTop: 20 }}>
          <div style={{ marginBottom: 15 }}>Moved from Title of:</div>
          {fromTitles.map((keyword, index) => (
            <div key={`keyword-${index}`} style={{ display: 'flex', alignItems: 'center' }}>
              <EditorInput
                placeholder={'Enter a title'}
                style={{ minWidth: 200, marginBottom: 10 }}
                value={keyword}
                onChange={e => replaceParam(e.currentTarget.value || null, index, 0)}
              />
              {!!keyword && (
                <Button
                  tabIndex={-1}
                  style={{ color: Colors.Static.SEQUOIA_BRIGHT_RED }}
                  onClick={() => replaceParam(null, index, 0)}
                  type={'link'}
                >
                  <CloseOutlined style={{ marginLeft: 20 }} />
                </Button>
              )}
              {index === 0 && (
                <Tooltip overlayStyle={{ minWidth: 500 }} overlay={REGEX_EXPLANATION}>
                  <QuestionCircleOutlined
                    style={{ color: Colors.Static.SEQUOIA_LIGHT_TEXT, marginLeft: 5 }}
                  />
                </Tooltip>
              )}
            </div>
          ))}
        </div>
        <div style={{ marginLeft: 15, marginTop: 20 }}>
          <div style={{ marginBottom: 15 }}>To Title of:</div>
          {toTitles.map((keyword, index) => (
            <div key={`keyword-${index}`} style={{ display: 'flex', alignItems: 'center' }}>
              <EditorInput
                placeholder={'Enter a title'}
                style={{ minWidth: 200, marginBottom: 10 }}
                value={keyword}
                onChange={e => replaceParam(e.currentTarget.value || null, index, 1)}
              />
              {!!keyword && (
                <Button
                  tabIndex={-1}
                  style={{ color: Colors.Static.SEQUOIA_BRIGHT_RED }}
                  onClick={() => replaceParam(null, index, 1)}
                  type={'link'}
                >
                  <CloseOutlined style={{ marginLeft: 20 }} />
                </Button>
              )}
              {index === 0 && (
                <Tooltip overlayStyle={{ minWidth: 500 }} overlay={REGEX_EXPLANATION}>
                  <QuestionCircleOutlined
                    style={{ color: Colors.Static.SEQUOIA_LIGHT_TEXT, marginLeft: 5 }}
                  />
                </Tooltip>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const validate = (rule: Rule): ValidationInfo => {
  const ruleLabel = EditableExcludeRulesMap[rule.id as EditableRuleIds]?.label || '';
  const fromTitles: string[] = JSON.parse(rule.parameters[0]);
  const toTitles: string[] = JSON.parse(rule.parameters[1]);
  if (
    !rule.parameters ||
    !rule.parameters.length ||
    rule.parameters.some(p => typeof p !== 'string') ||
    !fromTitles.length ||
    !toTitles.length
  ) {
    return invalid(`${ruleLabel} requires at least one tag to be selected.`);
  }
  return valid();
};
