import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router';
import styled from 'styled-components/macro';
import { SEQUOIA_ORG_ID } from '../App';
import { Column, HorizontalDivider, LightTooltip, PageContainer } from '../components/Common';
import { FastTable } from '../components/FastTable';
import { DebouncedSearchInput } from '../components/Input';
import { customSortableColumn } from '../components/SeqTable';
import { TabPane, Tabs } from '../components/Tabs';
import { Body1Serif, H2Mono } from '../components/Text';
import { OrganizationContext } from '../contexts/Organization';
import { CharInCircle } from '../shared/Common';
import { useResource } from '../shared/Resource';
import { Colors } from '../shared/Theme';
import { TALENT_WEB_APP } from '../shared/helpers';
import { CohortSelector } from './CohortSelector';
import { DATE_FORMAT } from './CompanyMembersTable';
import { PageCompanyDetails } from './PageCompanyDetails';

const { SEQUOIA_LIGHT_TEXT, SEQUOIA_GREEN, SEQUOIA_PAPER } = Colors.Static;

export const PageCompaniesHome: React.FunctionComponent<
  RouteComponentProps<{
    organizationId: string;
  }>
> = props => {
  const session = useContext(OrganizationContext);

  const { organizationId } = props.match.params;
  const [organizations = [], { refresh }] =
    useResource<OrganizationAPI.OrganizationWithUsers[]>('/api/organizations');

  // When the global org context refreshes, refresh our data as well. That way we see
  // changes to user settings inside our OrganizationWithUsers
  useEffect(() => {
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session]);

  const [activeTab, setActiveTab] = useState<'Active' | 'Archived'>('Active');
  const [search, setSearch] = useState('');

  const seqUsers = [session.me, ...session.team].filter(u => u.organizationId === SEQUOIA_ORG_ID);
  const getSequoiaPOC = (o: OrganizationAPI.Organization) =>
    seqUsers.find(u => u.internalTalentId === o.managerTalentId);

  const organization = organizations.find(o => o.id === Number(organizationId));

  if (organizationId && organization) {
    return (
      <PageCompanyDetails
        organization={organization}
        sequoiaPOC={getSequoiaPOC(organization)}
        refresh={session.actions.refresh}
      />
    );
  }
  return (
    <PageContainer style={{ paddingRight: 24 }}>
      <H2Mono>Companies</H2Mono>
      <Body1Serif>
        Click a company below to edit their team and access to the founder tools.
        <div>
          To create a new company in this list, please visit the page for that company in the TRM by
          clicking on the company in the list{' '}
          <a
            style={{ color: SEQUOIA_GREEN, fontWeight: 500, textDecoration: 'underline' }}
            target="_blank"
            rel="noopener noreferrer"
            href={`${TALENT_WEB_APP}/companies?owner=all&search=&tab=companies`}
          >
            here
          </a>
          . Then, click{' '}
          <LightTooltip
            placement="bottomRight"
            overlayStyle={{ maxWidth: 420, padding: 0 }}
            overlay={
              <img
                style={{ width: 400 }}
                src="/add-org-instructions.png"
                alt="Highlight button to click to add org"
              />
            }
          >
            <span
              style={{
                textDecoration: 'underline',
                color: SEQUOIA_GREEN,
                fontWeight: 500,
                cursor: 'pointer',
              }}
            >
              Add&nbsp;To&nbsp;Founder&nbsp;Tools
            </span>
          </LightTooltip>
          .
        </div>
      </Body1Serif>

      <Column style={{ position: 'relative' }}>
        <div style={{ position: 'absolute', right: 10, top: 9, width: 250 }}>
          <DebouncedSearchInput
            style={{ background: SEQUOIA_PAPER }}
            placeholder="Search..."
            size="small"
            outline="rounded"
            autoFocus
            value={search}
            onChange={setSearch}
          />
        </div>

        <Tabs active={activeTab} onChange={setActiveTab as any}>
          <TabPane label="Active" overrideKey="Active">
            <OrganizationsTable
              refresh={refresh}
              getSequoiaPOC={getSequoiaPOC}
              organizations={organizations
                .filter(o => !o.inactive)
                .filter(o => !search || o.name.toLowerCase().includes(search.toLowerCase()))}
            />
          </TabPane>
          <TabPane label="Inactive" overrideKey="Inactive">
            <OrganizationsTable
              getSequoiaPOC={getSequoiaPOC}
              organizations={organizations
                .filter(o => !!o.inactive)
                .filter(o => !search || o.name.toLowerCase().includes(search.toLowerCase()))}
            />
          </TabPane>
        </Tabs>
      </Column>
      <HorizontalDivider />
    </PageContainer>
  );
};

const OrganizationsTable: React.FC<{
  organizations: OrganizationAPI.OrganizationWithUsers[];
  getSequoiaPOC: (o: OrganizationAPI.OrganizationWithUsers) => ExternalAPI.User | undefined;
  refresh?: () => void;
}> = ({ getSequoiaPOC, organizations, refresh }) => {
  const history = useHistory();
  const [cohorts = {}, { applyLocalUpdates }] =
    useResource<ConfigJSON.Cohorts>('/api/config/cohorts');

  const orgCohortMap = Object.entries(cohorts).reduce(
    (acc, [cohortKey, orgIds]) => {
      orgIds.forEach(id => (acc[id] = cohortKey));
      return acc;
    },
    {} as { [orgId: number]: string }
  );

  let columns: ColumnsType<OrganizationAPI.OrganizationWithUsers> = [
    {
      key: 'Name',
      title: customSortableColumn<OrganizationAPI.OrganizationWithUsers>('Name'),
      sorter: (a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
      render: (o: OrganizationAPI.OrganizationWithUsers) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {o.name}
          <CharInCircle char={o.users.length} style={{ marginLeft: 6 }} />
        </div>
      ),
    },
    {
      key: 'Sequoia POC',
      title: customSortableColumn<OrganizationAPI.OrganizationWithUsers>('Sequoia POC'),
      sorter: (a, b) => {
        const aPOC = getSequoiaPOC(a);
        const bPOC = getSequoiaPOC(b);
        return (getSequoiaUserTitle(aPOC) || '').localeCompare(getSequoiaUserTitle(bPOC) || '');
      },
      render: (o: OrganizationAPI.OrganizationWithUsers) => {
        const sourcer = getSequoiaPOC(o);
        return <SecondaryInfoCol>{getSequoiaUserTitle(sourcer) || '--'}</SecondaryInfoCol>;
      },
    },
    {
      key: 'First User',
      title: customSortableColumn<OrganizationAPI.OrganizationWithUsers>('First User'),
      sorter: (a, b) =>
        (oldestUser(a.users)?.name || '').localeCompare(oldestUser(b.users)?.name || ''),
      render: (o: OrganizationAPI.OrganizationWithUsers) => (
        <SecondaryInfoCol>{oldestUser(o.users)?.name || '--'}</SecondaryInfoCol>
      ),
    },
  ];

  if (refresh) {
    columns = columns.concat([
      {
        key: 'Last Activity',
        title: customSortableColumn<OrganizationAPI.OrganizationWithUsers>('Last Activity'),
        defaultSortOrder: 'descend',
        sorter: (a, b) => {
          const lastUserA = lastSeenUser(a);
          const lastUserB = lastSeenUser(b);
          return (
            moment(lastUserA?.lastActivity?.createdAt || 0).valueOf() -
            moment(lastUserB?.lastActivity?.createdAt || 0).valueOf()
          );
        },
        render: (o: OrganizationAPI.OrganizationWithUsers) => {
          const lastUser = lastSeenUser(o);
          return (
            <SecondaryInfoCol>
              {lastUser?.lastActivity?.createdAt
                ? `${lastUser.name} (${moment(lastUser.lastActivity?.createdAt).format(
                    DATE_FORMAT
                  )})`
                : '--'}
            </SecondaryInfoCol>
          );
        },
      },
      {
        key: 'Cohort',
        title: customSortableColumn<OrganizationAPI.OrganizationWithUsers>('Cohort'),
        render: (o: OrganizationAPI.OrganizationWithUsers) => (
          <CohortSelector
            orgId={o.id}
            cohorts={cohorts}
            currCohort={orgCohortMap?.[o.id]}
            onCohortUpdated={applyLocalUpdates}
          />
        ),
      },
    ]);
  }
  return (
    <FastTable
      columns={columns}
      dataSource={organizations}
      rowHeight={50}
      rowKey={k => k.id}
      rowProps={(o: OrganizationAPI.OrganizationWithUsers) => ({
        style: { cursor: 'pointer' },
        onClick: () => {
          history.push(`/companies/${o.id}`);
        },
      })}
    />
  );
};

const SecondaryInfoCol = styled.span`
  color: ${SEQUOIA_LIGHT_TEXT};
`;

function lastSeenUser(
  org: OrganizationAPI.OrganizationWithUsers
): ExternalAPI.UserWithLastActivity | undefined {
  return org.users
    .slice()
    .sort(
      (a, b) =>
        moment(b.lastActivity?.createdAt || 0).valueOf() -
        moment(a.lastActivity?.createdAt || 0).valueOf()
    )[0];
}

function oldestUser(users: ExternalAPI.User[]): ExternalAPI.User | undefined {
  return users
    .slice()
    .sort((a, b) => moment(a.createdAt).valueOf() - moment(b.createdAt).valueOf())[0];
}

export function getSequoiaUserTitle(sourcer: ExternalAPI.User | undefined) {
  return sourcer?.name || sourcer?.email;
}
