import { useContext, useEffect } from 'react';
import { SeqButton } from '../components/Button';
import { BulkEmailModal } from '../components/modals/BulkEmailModal';
import { OrganizationContext } from '../contexts/Organization';
import { MinimalExtProfile } from '../shared/profile-helpers';
import { useQueryPersistedState } from '../shared/useQueryPersistedState';

export function useBulkEmailModal(
  profiles: (MinimalExtProfile & { email: string })[],
  refreshProfiles: () => void,
  checkedIds: number[]
) {
  const { features } = useContext(OrganizationContext);

  const [actionModal, setActionModal] = useQueryPersistedState<'bulk-email' | null>({
    encode: actionModal => ({ actionModal }),
    decode: qs => qs.actionModal || null,
  });

  useEffect(() => {
    if (actionModal === 'bulk-email') {
      refreshProfiles();
    }
  }, [actionModal, refreshProfiles]);

  const checkedProfiles = profiles.filter(p => checkedIds.includes(p.id));

  const bulkEmailModal =
    features.includes('campaigns') && actionModal === 'bulk-email' ? (
      <BulkEmailModal
        visible={actionModal === 'bulk-email'}
        profiles={checkedProfiles}
        onClose={() => {
          setActionModal(null);
        }}
      />
    ) : (
      <div />
    );

  const bulkEmailButton = features.includes('campaigns') ? (
    <SeqButton onClick={() => setActionModal('bulk-email')} disabled={!checkedIds?.length}>
      bulk email
    </SeqButton>
  ) : (
    <div />
  );

  return { modal: bulkEmailModal, button: bulkEmailButton };
}
