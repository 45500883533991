import { LoadingOutlined } from '@ant-design/icons';
import { notification, Spin, Timeline } from 'antd';
import moment from 'moment';
import { parseFullName } from 'parse-full-name';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components/macro';
import { NotificationContext, ProfileNotifs } from '../../contexts/Notification';
import { OrganizationContext } from '../../contexts/Organization';
import { OnEditNoteEvent } from '../../events';
import { shortenedName } from '../../Helpers';
import { RichMentionsEditor, RichMentionsEditorValue } from '../../mentions/RichMentionsEditor';
import { ClickableItem, NotificationDot } from '../../shared/Common';
import { createEmailPreview, EXTERNAL_RECIPIENT_VARIABLES } from '../../shared/helpers';
import { MinimalExtProfile } from '../../shared/profile-helpers';
import { makeRequest } from '../../shared/Resource';
import { Colors } from '../../shared/Theme';
import { ActionButton, SeqButton } from '../Button';
import { DisplayOnHover, Empty, HoverableDiv } from '../Common';
import { buildVariablesMap } from '../ExternalTemplateSupport';
import { PopoverDeleteButton } from '../Popover';
import { SeqPopConfirm } from '../SeqPopConfirm';
import { EmailFrame } from './EmailFrame';

const { SEQUOIA_BLACK, SEQUOIA_GREEN, SEQUOIA_LIGHT_GRAY, DESTRUCTIVE_TINT } = Colors.Static;

type TimelineItem =
  | ExternalAPI.Endorsement
  | ExternalAPI.Note
  | PipelineAPI.Activity
  | (ExternalAPI.EmailMessage & { createdAt: Date; campaignMemberId?: number })
  | ExternalAPI.Notification<ExternalAPI.ReminderNotification>;

export const ActivityTimeline: React.FunctionComponent<{
  profileNotifs: ProfileNotifs;
  endorsements: ExternalAPI.Endorsement[];
  notes: ExternalAPI.Note[];
  getRelatedProfileName: (id: number) => string;
  activities: PipelineAPI.Activity[];
  emails: ExternalAPI.EmailMessage[];
  campaignEmail: string;
  candidate: MinimalExtProfile;
  emailClient: ExternalAPI.EmailClient | undefined;
  refresh: {
    notes: () => void;
    emails: () => void;
  };
}> = ({
  profileNotifs,
  endorsements,
  notes,
  getRelatedProfileName,
  candidate,
  activities,
  emails,
  campaignEmail,
  emailClient,
  refresh,
}) => {
  const { me } = useContext(OrganizationContext);
  const {
    actions: { markSeen },
  } = useContext(NotificationContext);
  const candidateShortenedName = shortenedName(candidate.name);

  const items: TimelineItem[] = useMemo(
    () =>
      [
        ...endorsements,
        ...notes,
        ...activities,
        ...emails.map(e => ({ ...e, createdAt: e.sentAt })),
        ...(profileNotifs.reminder && !profileNotifs.reminder.isActive
          ? [profileNotifs.reminder.notification].map(r => ({ ...r, createdAt: r.data.remindAt }))
          : []),
      ].sort((a, b) => moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf()),
    [endorsements, notes, activities, emails, profileNotifs]
  );

  useEffect(() => {
    profileNotifs.mentions.forEach(m => {
      markSeen(m);
    });
  }, [profileNotifs, markSeen]);

  return !items.length ? (
    <Empty imgProps={{ src: '/images/pocketwatch.svg', alt: 'pocketwatch' }} />
  ) : (
    <Timeline mode="left" style={{ padding: '24px 8px 0 32px' }}>
      {items.length &&
        items.map((item, idx) => {
          const { createdAt } = item;
          let icon, content;
          if (!createdAt) return <div />;
          if (('score' in item || 'comment' in item) && 'owner' in item) {
            icon = (
              <img
                src="/icons/vouch-icon.svg"
                alt="Quotes vouch icon"
                style={{ width: 36, height: 36 }}
              />
            );
            content = (
              <TimelineItemContent
                date={createdAt}
                headline={
                  <>
                    Endorsed by
                    <span style={{ color: SEQUOIA_GREEN, paddingLeft: 4 }}>
                      {getRelatedProfileName(item.owner) || (
                        <Spin indicator={<LoadingOutlined style={{ fontSize: 16 }} spin />} />
                      )}
                    </span>
                  </>
                }
                text={item.comment}
              />
            );
          } else if ('author' in item) {
            const hasNotification = profileNotifs.mentions.find(m => m.data.id === item.id);
            icon = (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {hasNotification && <NotificationDot style={{ marginLeft: -10, marginRight: 6 }} />}
                <img src="/icons/note.svg" alt="Sheet of paper" style={{ width: 36, height: 36 }} />
              </div>
            );
            content = (
              <TimelineNoteContent key={item.id} item={item} refreshNotes={refresh.notes} />
            );
          } else if ('plainText' in item) {
            const variables = buildVariablesMap(
              { ...me },
              EXTERNAL_RECIPIENT_VARIABLES,
              candidate,
              me.templateVariables
            );

            const emailText =
              item.html && emailClient === 'gmail' ? (
                <EmailFrame html={createEmailPreview(item.html, '', variables).body} />
              ) : item.plainText ? (
                <div style={{ whiteSpace: 'pre-wrap' }}>{item.plainText}</div>
              ) : null;

            const onRemoveFromCampaign = async (candidateId: number, name: string) => {
              const result = await makeRequest<{ success: boolean }>(
                `/api/sourcing/campaign-member/${candidateId}`,
                'DELETE'
              );
              if (result.success) {
                notification.success({ message: `${name} was removed from the email campaign.` });
                refresh.emails();
              } else {
                notification.success({
                  message: `Could not remove ${name} from campaign, please try again.`,
                });
              }
            };

            if (moment().isBefore(item.sentAt)) {
              icon = (
                <img
                  src="/icons/closed-envelope.svg"
                  alt="envelope"
                  style={{ width: 36, height: 36 }}
                />
              );
              content = (
                <TimelineItemContent
                  date={createdAt}
                  headline={
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                      }}
                    >
                      <div>
                        <span style={{ color: SEQUOIA_GREEN, paddingRight: 4 }}>
                          {candidateShortenedName}
                        </span>
                        will be emailed
                      </div>
                      {!!item.campaignMemberId && (
                        <div>
                          <SeqPopConfirm
                            placement={'left'}
                            width={350}
                            onConfirm={() => {
                              item.campaignMemberId &&
                                onRemoveFromCampaign(
                                  item.campaignMemberId,
                                  parseFullName(candidateShortenedName)?.first ||
                                    candidateShortenedName
                                );
                            }}
                            title={`Are you sure that you would like to remove ${candidateShortenedName} from this email campaign? This will cancel all future emails to ${candidateShortenedName} in this campaign.`}
                          >
                            <ClickableItem>
                              <img
                                src="/icons/trash.svg"
                                alt="trashcan"
                                style={{ width: 13, color: DESTRUCTIVE_TINT }}
                              />
                            </ClickableItem>
                          </SeqPopConfirm>
                        </div>
                      )}
                    </div>
                  }
                  text={emailText}
                />
              );
            } else {
              icon = (
                <img
                  src="/icons/open-envelope.svg"
                  alt="open envelope"
                  style={{ width: 36, height: 36 }}
                />
              );
              content = (
                <TimelineItemContent
                  date={createdAt}
                  headline={
                    <div
                      style={{ display: 'flex', flexWrap: 'wrap', gap: 4, alignItems: 'center' }}
                    >
                      <GreenText>{candidateShortenedName}</GreenText>
                      {item.toAddress === campaignEmail
                        ? `was emailed by ${item.fromAddress}`
                        : 'replied'}
                      {emailText !== null && <ActivityTag>Only visible to me</ActivityTag>}
                    </div>
                  }
                  text={emailText}
                />
              );
            }
          } else if ('seenAt' in item) {
            icon = (
              <div
                style={{
                  background: '#F0F0F0',
                  border: '2px solid white',
                  borderRadius: 50,
                  width: 36,
                  height: 36,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <img style={{ height: 18 }} src="/icons/later.svg" alt="clock" />
              </div>
            );
            content = (
              <TimelineItemContent
                date={createdAt}
                headline="You will be sent the following reminder"
                text={item.data.text}
              />
            );
          } else if ('explanation' in item) {
            icon = (
              <div
                style={{ background: SEQUOIA_GREEN, borderRadius: '50%', width: 10, height: 10 }}
              />
            );
            content = (
              <TimelineItemContent
                date={createdAt}
                headline={
                  <div style={{ display: 'inline-flex', gap: 4, flexWrap: 'wrap' }}>
                    <GreenText>{shortenedName(item.user)}</GreenText>
                    {item.explanation}
                  </div>
                }
              />
            );
          }

          return (
            <Timeline.Item key={`${candidateShortenedName}-activity-${idx}`} dot={icon}>
              {content}
            </Timeline.Item>
          );
        })}
    </Timeline>
  );
};

const TimelineItemContent: React.FC<{
  headline: React.ReactNode;
  text?: React.ReactNode;
  date: Date;
}> = ({ headline, date, text }) => {
  return (
    <div style={{ marginLeft: 10 }}>
      <div style={{ paddingTop: 2, display: 'flex' }}>{headline}</div>
      {text && (
        <div style={{ fontSize: 12, paddingTop: 8, paddingBottom: 2, whiteSpace: 'pre-wrap' }}>
          {text}
        </div>
      )}
      <div style={{ fontSize: 10, color: SEQUOIA_BLACK }}>
        {moment(date).format('MMMM D, YYYY')} ({moment(date).fromNow()})
      </div>
    </div>
  );
};

const TimelineNoteContent: React.FC<{ item: ExternalAPI.Note; refreshNotes: () => void }> = ({
  item,
  refreshNotes,
}) => {
  const [editing, setEditing] = React.useState(false);
  const [inputValue, setInputValue] = useState<RichMentionsEditorValue>({
    text: item.text,
    mentions: item.mentions || {
      teamIds: [],
    },
  });

  const onEditOrDeleteNote = async (isDelete?: boolean) => {
    if (isDelete || inputValue.text !== item.text) {
      const updated = await makeRequest<ExternalAPI.Note>(`/api/note/${item.id}`, 'PUT', {
        text: isDelete ? '' : inputValue.text,
        mentions: inputValue.mentions,
      });
      if (updated.id) {
        OnEditNoteEvent.dispatch(item.profileId);
        refreshNotes();
      }
    }
    setEditing(false);
  };

  return (
    <HoverableDiv>
      <TimelineItemContent
        date={item.createdAt}
        headline={
          <>
            <div style={{ flex: 1, display: 'flex', gap: 4, alignItems: 'center' }}>
              <GreenText>{shortenedName(item.author.name || '')}</GreenText>
              wrote a note
            </div>
            {!editing && (
              <DisplayOnHover>
                <ActionButton style={{ marginRight: -8 }} onClick={() => setEditing(true)}>
                  Edit
                </ActionButton>
              </DisplayOnHover>
            )}
          </>
        }
        text={
          !editing ? (
            <div
              dangerouslySetInnerHTML={{
                __html: item.text.replace(
                  /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi,
                  '<a href="$1">$1</a>'
                ),
              }}
            />
          ) : (
            <div>
              <RichMentionsEditor
                placeholder={`Add note`}
                autoSize
                minHeight={100}
                value={inputValue}
                onChange={setInputValue}
                style={{ fontSize: 12, padding: 0, lineHeight: '18px' }}
                onKeyDown={e => {
                  if (
                    inputValue.text.length &&
                    e.key === 'Enter' &&
                    !(e.metaKey || e.altKey || e.shiftKey)
                  ) {
                    onEditOrDeleteNote();
                  }
                }}
              />
              <div style={{ display: 'flex', paddingTop: 12, gap: 5, justifyContent: 'flex-end' }}>
                <PopoverDeleteButton
                  onDelete={() => {
                    onEditOrDeleteNote(true);
                  }}
                  itemName="note"
                />
                <SeqButton size="small" intent="primary" onClick={() => onEditOrDeleteNote()}>
                  Update
                </SeqButton>
              </div>
            </div>
          )
        }
      />
    </HoverableDiv>
  );
};

const GreenText = styled.span`
  font-weight: 700;
`;

const ActivityTag = styled.span`
  background: ${SEQUOIA_LIGHT_GRAY};
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 11px;
`;
