import { default as React } from 'react';
import styled from 'styled-components/macro';
import { CondensedTextCSS } from '../components/Text';
import { EPD_FUNCTIONS } from '../shared/profile-helpers';
import {
  DATASCIENCE_CATEGORY_LABELS,
  DATASCIENCE_ROUND_LABELS,
} from '../shared/rule-editors/DSConstants';
import { AndMoreSuffix } from './AndMoreSuffix';

export const SearchTitle: React.FC<{
  search: NetworkAPI.SearchHistoryData;
  labels: SearchTermLabels;
}> = ({ search, labels }) => {
  const searchTerms = compileSearchTerms({ search, labels });
  return (
    <SearchText>
      {searchTerms.slice(0, 4).join(', ')}
      {searchTerms[4] && <AndMoreSuffix amt={searchTerms.slice(4).length} />}
    </SearchText>
  );
};

// Helpers

export interface SearchTermLabels {
  insights: NetworkAPI.InsightFilter[];
  owners: ExternalAPI.UserWithCampaigns[];
  roles: OrganizationAPI.Role[];
}

export const compileSearchTerms = ({
  search,
  labels,
}: {
  search?: NetworkAPI.SearchHistoryData;
  labels: SearchTermLabels;
}) => {
  const { insights, owners, roles } = labels;
  let searchTerms: string[] = [];

  if (!search) return searchTerms;

  if (search.person) {
    Object.entries(search.person).forEach(([field, terms]) => {
      if (field === 'experience') {
        searchTerms = searchTerms.concat(`${terms[0] || 0}-${terms[1]} Years of Experience`);
      } else if (field === 'functions') {
        let functions = terms as string[];
        if (EPD_FUNCTIONS.every(f => functions.includes(f))) {
          searchTerms.push('EPD');
          functions = functions.filter(f => !EPD_FUNCTIONS.includes(f));
        }
        searchTerms = searchTerms.concat(functions.map(String));
      } else {
        searchTerms = searchTerms.concat(terms.map(String));
      }
    });
  }
  if (search.company) {
    Object.entries(search.company).forEach(([field, terms]) => {
      if (field === 'categories') {
        searchTerms = searchTerms.concat(terms.map(t => DATASCIENCE_CATEGORY_LABELS[t]));
      } else if (field === 'lastRound') {
        searchTerms = searchTerms.concat(terms.map(t => DATASCIENCE_ROUND_LABELS[t]));
      } else {
        searchTerms = searchTerms.concat(terms);
      }
    });
  }
  if (search.pipeline) {
    Object.entries(search.pipeline).forEach(([field, terms]) => {
      if (field === 'owner') {
        searchTerms = searchTerms.concat(terms.map(t => owners.find(o => t === o.id)!.name));
      } else if (field === 'role') {
        searchTerms = searchTerms.concat(terms.map(t => roles.find(r => t === r.id)!.name));
      } else {
        searchTerms = searchTerms.concat(terms.map(String));
      }
    });
  }
  if (search.insights && insights) {
    const currInsights = search.insights
      .map(i => insights.find(f => f.id === i)?.title!)
      .filter(Boolean);
    searchTerms = searchTerms.concat(currInsights);
  }
  return searchTerms;
};

const SearchText = styled.div`
  display: inline;
  align-items: baseline;
  gap: 4px;
  max-height: 40px;
  ${CondensedTextCSS}
`;
