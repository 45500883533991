import { LoadingOutlined } from '@ant-design/icons';
import { useVirtualizer } from '@tanstack/react-virtual';
import { ColumnType } from 'antd/lib/table';
import { CompareFn, SortOrder } from 'antd/lib/table/interface';
import React, { CSSProperties, useState } from 'react';
import styled from 'styled-components/macro';
import { Label2CSS } from '../components/Text';
import { useWindowSize } from '../shared/ScreenWidthControl';
import { Colors } from '../shared/Theme';
import { Empty, EmptyProps } from './Common';
import { ArrowDown } from './Svgs';

const { SEQUOIA_PAPER, BLACK1SOLID, BLACK2SOLID, SEQUOIA_BLACK, BLACK3ALPHA, BLACK5ALPHA } =
  Colors.Static;

interface FastTableProps<T> {
  rowKey: keyof T | ((item: T) => string | number);
  rowHeight: number | ((item: T) => number) | 'measure';
  rowProps?: (item: T) => {
    onClick?: React.MouseEventHandler<HTMLDivElement>;
    style?: CSSProperties;
  };
  size?: 'small';
  dataSource: T[];
  columns: ColumnType<T>[];
  loading?: boolean;
  emptyProps?: EmptyProps;
}

export function FastTable<T>(props: FastTableProps<T>) {
  const { rowKey, rowHeight, dataSource, rowProps, columns } = props;
  const parentRef = React.useRef<HTMLDivElement | null>(null);

  const [_sort, setSort] = useState<{ key: string | number; order: SortOrder } | null>(null);
  const defaultSortColumn = columns.find(c => c.defaultSortOrder);
  const sort = _sort || {
    key: defaultSortColumn?.key || '',
    order: defaultSortColumn?.defaultSortOrder || 'ascend',
  };
  const sortColumn = columns.find(c => c.key === sort.key);

  const sorted = React.useMemo(
    () =>
      sortColumn
        ? [...dataSource].sort(
            (a, b) =>
              (sortColumn.sorter as CompareFn<T>)!(a, b) * (sort.order === 'ascend' ? 1 : -1)
          )
        : dataSource,
    [dataSource, sortColumn, sort.order]
  );

  const size = useWindowSize();

  const getItemKey = React.useMemo(
    () => (ii: number) => {
      const dynamicHeightKey = rowHeight === 'measure' ? size.width : '';
      return typeof rowKey === 'function'
        ? `${dynamicHeightKey}-${rowKey(sorted[ii])}`
        : `${dynamicHeightKey}-${sorted[ii][rowKey]}`;
    },
    [sorted, size.width, rowHeight, rowKey]
  );

  const estimateSize = React.useMemo(() => {
    if (typeof rowHeight === 'number') {
      return () => rowHeight;
    } else if (typeof rowHeight === 'function') {
      return (ii: number) => rowHeight(sorted[ii]);
    } else {
      return () => 50; // just a guess
    }
  }, [sorted, rowHeight]);

  const rowVirtualizer = useVirtualizer({
    count: dataSource.length,
    getScrollElement: () => parentRef.current,
    overscan: 15,
    estimateSize,
    getItemKey,
  });

  const [stickyColumnsStuck, setStickyColumnsStuck] = React.useState(false);
  const onScroll = (e: React.WheelEvent<HTMLDivElement>) =>
    setStickyColumnsStuck(e.currentTarget.scrollLeft !== 0);

  const items = rowVirtualizer.getVirtualItems();

  if (props.loading) {
    return (
      <Container style={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
        <LoadingOutlined spin={true} style={{ fontSize: 48 }} />
      </Container>
    );
  }

  const fixedColumns = columns.filter(c => c.fixed);

  const renderHeaderCell = (c: ColumnType<T>) => (
    <HeaderCell
      key={c.key}
      style={c.width ? { width: c.width } : { flex: 1, minWidth: 150 }}
      $sortable={!!c.sorter}
      onClick={() => {
        if (!c.sorter) return;
        setSort({
          key: c.key!,
          order: sort.key === c.key && sort.order === 'ascend' ? 'descend' : 'ascend',
        });
      }}
    >
      {c.title instanceof Function ? (
        c.title({
          sortColumns: sortColumn ? [{ column: sortColumn, order: sort.order }] : [],
        })
      ) : (
        <>
          {c.title}
          {c.sorter && (
            <SortIndicator
              hasIcon={false}
              sortOrder={sort.key === c.key ? sort.order : undefined}
            />
          )}
        </>
      )}
    </HeaderCell>
  );

  return (
    <Container ref={parentRef} onScroll={onScroll} $stickyColumnsStuck={stickyColumnsStuck}>
      <Inner style={{ height: rowVirtualizer.getTotalSize() + HeaderCellHeight }}>
        <HeaderContainer>
          {fixedColumns.length > 0 ? (
            <Fixed>{fixedColumns.map(renderHeaderCell)}</Fixed>
          ) : undefined}
          {columns.filter(c => !c.fixed).map(renderHeaderCell)}
        </HeaderContainer>

        {items.length ? (
          <div
            style={{
              position: 'absolute',
              left: 0,
              minWidth: '100%',
              transform: `translateY(${items[0].start}px)`,
            }}
          >
            {items.map(({ index, key, size }) => {
              const item = sorted[index];
              const { style = {}, ...rest } = rowProps?.(item) || {};

              return (
                <RowContainer
                  key={key}
                  data-index={index}
                  ref={rowHeight === 'measure' ? rowVirtualizer.measureElement : undefined}
                  style={{
                    height: rowHeight === 'measure' ? 'unset' : size,
                    ...style,
                  }}
                  {...rest}
                >
                  <FastTableRow<T> item={item} columns={columns} />
                </RowContainer>
              );
            })}
          </div>
        ) : (
          <Empty {...props.emptyProps} />
        )}
      </Inner>
    </Container>
  );
}

function _FastTableRow<T>({ item, columns }: { item: T; columns: ColumnType<T>[] }) {
  const fixed = columns.filter(c => c.fixed);
  const renderCell = (c: ColumnType<T>) => (
    <Cell key={c.key} style={c.width ? { width: c.width } : { flex: 1, minWidth: 150 }}>
      {c.render
        ? c.render(item, item, 0)
        : c.dataIndex
          ? (item as any)[c.dataIndex as any]
          : undefined}
    </Cell>
  );

  return (
    <>
      {fixed.length > 0 && <Fixed key="fixed">{fixed.map(renderCell)}</Fixed>}
      {columns.filter(c => !c.fixed).map(renderCell)}
    </>
  );
}

const FastTableRow = React.memo(_FastTableRow) as typeof _FastTableRow;

export const SortIndicator: React.FunctionComponent<{
  sortOrder?: 'descend' | 'ascend' | null;
  hasIcon: boolean;
}> = ({ sortOrder, hasIcon }) => {
  return (
    <div
      style={{
        marginLeft: hasIcon ? 0 : 6,
        transform: sortOrder === 'descend' ? 'rotate(180deg)' : '',
        display: 'inline-block',
        width: 14,
        height: 14,
      }}
    >
      <ArrowDown color={!!sortOrder ? SEQUOIA_BLACK : BLACK5ALPHA} />
    </div>
  );
};

const Cell = styled.div`
  flex-shrink: 0;
  padding: 6px 8px;
  box-sizing: content-box;
  &:first-child {
    padding-left: 16px;
  }
`;

const Fixed = styled.div`
  left: 0;
  position: sticky;
  justify-content: stretch;
  align-self: stretch;
  display: flex;
  align-items: center;
  background: inherit;
  z-index: 1;
  &:after {
    content: '';
    opacity: 0;
    position: absolute;
    right: 0px;
    top: 0;
    width: 8px;
    bottom: 0;
    transition: opacity 200ms linear;
    background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.1) 5%,
      rgba(0, 0, 0, 0.05) 15%,
      transparent
    );
  }
`;

const HeaderContainer = styled.div`
  ${Label2CSS}
  position: sticky;
  display: flex;
  top: -1px;
  flex-shrink: 0;
  align-items: center;
  user-select: none;
  z-index: 1;
  background: ${BLACK1SOLID};
  border-bottom: 1px solid ${SEQUOIA_BLACK};
`;

// height + paddings + borders of cell below
const HeaderCellHeight = 20 + 12 + 12 + 1;

const HeaderCell = styled(Cell)<{ $sortable: boolean }>`
  white-space: nowrap;
  text-overflow: ellipsis;
  height: 20px;
  padding-top: 12px;
  padding-bottom: 12px;
  display: flex;
  align-items: center;

  // Following 3 styles are so that the background + border will continue when you scroll right or expand the window
  // Which is why HeaderContainer must contain background + border styles as well
  margin-bottom: -1px;
  background: ${BLACK1SOLID};
  border-bottom: 1px solid ${SEQUOIA_BLACK};

  ${p =>
    p.$sortable
      ? `
    &:hover {
      background: ${BLACK2SOLID};
    }`
      : ''}
`;

const Container = styled.div<{ $stickyColumnsStuck?: boolean }>`
  height: 100%;
  min-height: 0;
  overflow: auto;
  background: ${SEQUOIA_PAPER};
  border-top: 1px solid ${SEQUOIA_BLACK};

  ${p =>
    p.$stickyColumnsStuck &&
    `
    ${Fixed} {
      &:after {
        opacity: 1;
      }
    }
  `}
`;

const Inner = styled.div`
  position: relative;
  width: 100%;
`;

const RowContainer = styled.div<{ onClick?: React.MouseEventHandler<HTMLDivElement> }>`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${BLACK3ALPHA};
  min-width: 100%;
  background: ${SEQUOIA_PAPER};

  ${p =>
    p.onClick
      ? `
    &:hover {
      background: ${BLACK1SOLID};
    }`
      : ''}
`;
