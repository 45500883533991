import { Rate } from 'antd';
import React, { CSSProperties } from 'react';
import styled from 'styled-components/macro';
import { Colors } from '../shared/Theme';
import { IconContainer, SequoiaLogo } from './Svgs';
import { Label } from './Text';

export const StarInput: React.FunctionComponent<{
  disabled?: boolean;
  value: CoreAPI.VouchScore;
  onChange: (score: CoreAPI.VouchScore) => void;
  style?: CSSProperties;
}> = ({ disabled, value, onChange, style }) => {
  const onToggle = (rating: number) => {
    switch (rating) {
      case 1:
        return onChange('B');
      case 2:
        return onChange('A');
      default:
        return onChange('');
    }
  };

  return (
    <Rate
      style={{ color: '#F2994A', display: 'flex', flexWrap: 'nowrap', ...style }}
      value={value === 'A' ? 2 : value === 'B' ? 1 : 0}
      count={2}
      character={<div style={{ fontSize: style?.fontSize || 20 }}>★</div>}
      onChange={onToggle}
      disabled={disabled}
      tooltips={['Exceptional in the right place', 'Exceptional anywhere']}
    />
  );
};

export const StarExplanatory: React.FunctionComponent<{
  rating: number;
  header: string;
  explanation: string;
  extraStyles?: {
    container?: CSSProperties;
    header?: CSSProperties;
    body?: CSSProperties;
  };
}> = ({ rating, header, explanation, extraStyles }) => {
  return (
    <Container style={extraStyles?.container}>
      <Label style={{ display: 'flex', gap: 12, ...extraStyles?.header }}>
        {rating === 2 ? (
          <span style={{ width: 32, fontSize: '26px', marginTop: -1 }}>★★</span>
        ) : rating === 1 ? (
          <span style={{ width: 32, fontSize: '26px', marginTop: -1 }}>★</span>
        ) : undefined}
        {header}
      </Label>
      <div style={{ paddingLeft: 44 }}>{explanation}</div>
    </Container>
  );
};

export const UnicornExplanatory = ({ explanation }: { explanation?: string }) => {
  return (
    <Container>
      <div style={{ flex: 1, display: 'flex', gap: 12 }}>
        <div style={{ width: 32, flexShrink: 0, marginTop: 4 }}>
          <IconContainer>
            <SequoiaLogo color={Colors.Static.BLACK7ALPHA} />
          </IconContainer>
        </div>
        <div>
          {explanation ||
            "Daring person who has or will start a special company? Check the Sequoia icon. We'realways looking to meet potential founders early."}
        </div>
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  flex-direction: column;
`;
