import { notification } from 'antd';
import { pick } from 'lodash';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';
import { SeqButton } from '../components/Button';
import { BOX_SHADOW, Column, Dialog, LightTooltip } from '../components/Common';
import { SeqInput } from '../components/Input';
import { SequoiaBrand } from '../components/navigation/FeatureSidebar';
import { OrganizationContext } from '../contexts/Organization';
import { toLinkedinID, toLinkedinURL } from '../shared/li-utils';
import { makeRequest } from '../shared/Resource';
import { Colors } from '../shared/Theme';

const { SEQUOIA_DARK_GRAY, SEQUOIA_PAPER } = Colors.Static;

export const PageOnboardingForm: React.FunctionComponent = () => {
  const history = useHistory();
  const {
    me,
    actions: { refresh },
  } = useContext(OrganizationContext);

  const [message, setMessage] = useState('');
  const [updating, setUpdating] = useState(false);
  const [accountState, setAccountState] = useState<{
    name: string;
    identifier: string;
  }>({
    ...pick(me, ['name', 'email']),
    identifier: toLinkedinURL(me.identifier) || '',
  });

  const onClickUpdate = async () => {
    if (!accountState.name || accountState.name.split(' ').length < 2) {
      setMessage('You must provide a first name and last name');
      return;
    }
    if (!accountState.identifier) {
      setMessage('You must provide your LinkedIn url.');
      return;
    }
    if (accountState.identifier && !toLinkedinID(accountState.identifier)) {
      setMessage('Invalid LinkedIn URL, please try again.');
      return;
    }
    setUpdating(true);
    const userUpdate: ExternalAPI.UpsertUserRequest = {
      id: me.id,
      email: me.email,
      sendInvite: false,
      name: accountState.name,
      identifier: toLinkedinID(accountState.identifier),
    };
    const response = await makeRequest<ExternalAPI.User>('/api/user', 'PUT', userUpdate);
    setUpdating(false);
    if (response.id) {
      refresh();
      history.push(`onboarding/setup`);
    } else {
      notification.error({ message: 'Could not update user profile, please try again.' });
    }
  };

  return (
    <Container style={{ alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
      <SequoiaBrand />
      <Dialog
        style={{ width: 400, background: 'white', boxShadow: BOX_SHADOW }}
        content={
          <Column style={{ gap: 24 }}>
            {message && <MessageContainer>{message}</MessageContainer>}
            <SeqInput
              title="Name"
              value={accountState.name}
              onChange={e => {
                setAccountState({ ...accountState, name: e.currentTarget.value });
              }}
              placeholder="Enter your name"
            />
            <SeqInput
              title="LinkedIn Profile"
              value={accountState.identifier}
              onChange={e => {
                setAccountState({ ...accountState, identifier: e.currentTarget.value });
              }}
              placeholder={'Url for your personal LinkedIn page'}
            />
            <LightTooltip
              placement={'topLeft'}
              overlay={
                'Email address is used to provide your authorization to use this platform and cannot be modified.'
              }
            >
              <div>
                <SeqInput
                  title="Email"
                  disabled={true}
                  value={me.email}
                  placeholder={'Enter your email address'}
                />
              </div>
            </LightTooltip>
          </Column>
        }
        footer={
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <SeqButton loading={updating} onClick={onClickUpdate} intent="primary">
              Set up account
            </SeqButton>
          </div>
        }
      />
    </Container>
  );
};

export const Container = styled.div`
  background: ${SEQUOIA_PAPER};
  display: flex;
  height: 100vh;
  color: ${SEQUOIA_DARK_GRAY};
  padding: 24px;
`;

export const MessageContainer = styled.div`
  padding: 12px;
  background: #e9e9e9;
  margin-top: 24px;
`;
