import { notification } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { CSSProperties } from 'styled-components/macro';
import { getRoleOptions, onProfileUpsert } from '../../Helpers';
import { OrganizationContext } from '../../contexts/Organization';
import { makeRequest } from '../../shared/Resource';
import { StyledInput } from '../Input';
import { Select, SelectOption, SelectProps } from './Select';

interface RoleSelectProps<T> {
  role: number | undefined;
  setRole: (id: number | undefined) => void;
  candidateName?: string;
  profile?: T;
  onProfileSaved?: (profile: T) => void;
  optionsPlacement?: SelectProps['optionsPlacement'];
  placeholder?: string;
  style?: CSSProperties;
  opts?: {
    blockCreateNew?: boolean;
    blockSelectNone?: boolean;
    filterRoleOptions?: (role: OrganizationAPI.Role) => boolean;
    defaultSelectOnSingleOption?: boolean;
  };
}

export const RoleSelect = ({
  candidateName,
  profile,
  onProfileSaved,
  setRole,
  role,
  placeholder,
  style,
  opts,
  optionsPlacement,
}: RoleSelectProps<ExternalAPI.Profile>) => {
  //No me.orgId, admins access this, org1 roles are useless
  const { id: orgId, me, roles, actions: orgActions } = useContext(OrganizationContext);

  const roleOptions = getRoleOptions(
    roles.filter(r => (opts?.filterRoleOptions ? opts.filterRoleOptions(r) : true))
  );
  if (!opts?.blockSelectNone) {
    roleOptions.unshift({ id: -1, name: 'None' });
  }

  useEffect(() => {
    if (
      opts?.defaultSelectOnSingleOption &&
      roleOptions.length === 1 &&
      !role &&
      'id' in roleOptions[0]
    ) {
      setRole(roleOptions[0].id);
    }
  }, [roleOptions, setRole, role, opts?.defaultSelectOnSingleOption]);

  const [holdOptionsOpen, setHoldOptionsOpen] = useState<boolean>(false);
  const [newRole, setNewRole] = useState<string>('');
  const currentRoleNamesLc = roleOptions.map(r => r.name.toLowerCase());

  const onCreateNewRole = async (newRoleName: string) => {
    const newRole = await makeRequest<OrganizationAPI.Role>(`/api/${orgId}/role`, 'POST', {
      name: newRoleName,
    });
    if (newRole.id) {
      await orgActions.refresh();
      onSelect(newRole);
      setHoldOptionsOpen(false);
    } else {
      notification.error({
        message: `New role: ${newRoleName} could not be created. Please try again.`,
      });
    }
    setNewRole('');
  };

  const onSelect = async (newRole: OrganizationAPI.Role | null) => {
    if (profile && onProfileSaved) {
      await onProfileUpsert(
        profile,
        {
          roleId: newRole ? newRole.id : null,
          activities: [
            {
              user: me.name,
              explanation: newRole
                ? `updated ${candidateName}'s role to ${newRole?.name}`
                : `removed ${candidateName} from all roles`,
              createdAt: new Date(),
            },
          ],
        },
        onProfileSaved,
        newRole
          ? `${candidateName} is now a candidate for the ${newRole.name} role.`
          : `${candidateName} has been removed as a candidate for all roles.`
      );
    }
    setRole(newRole ? newRole.id : undefined);
  };

  const newRoleOption: SelectOption = {
    id: -2,
    name: '',
    customRender: () => {
      return (
        <div style={{ paddingTop: 5, paddingBottom: 5 }}>
          <StyledInput
            onFocus={() => {
              setHoldOptionsOpen(true);
            }}
            onBlur={() => {
              setNewRole('');
              setHoldOptionsOpen(false);
            }}
            value={newRole}
            placeholder={'Create new role'}
            onChange={e => {
              setNewRole(e.currentTarget.value);
            }}
            onKeyDown={async (keyEvent: React.KeyboardEvent<HTMLInputElement>) => {
              if (keyEvent.key === 'Enter' && newRole.trim().length) {
                if (currentRoleNamesLc.includes(newRole.toLowerCase())) {
                  notification.warning({ message: `Role ${newRole} already exists.` });
                  setNewRole('');
                  return;
                }
                onCreateNewRole(newRole);
              }
            }}
          />
        </div>
      );
    },
  };
  !opts?.blockCreateNew && roleOptions.push(newRoleOption);

  return (
    <Select
      style={style}
      placeholder={placeholder || '--'}
      selected={opts?.blockSelectNone ? role : role || -1}
      options={roleOptions}
      optionsPlacement={optionsPlacement}
      optionsContainerStyle={{ maxHeight: '50vh' }}
      type="secondary"
      holdOpen={holdOptionsOpen}
      onSelect={id => {
        if (id === -1) {
          return onSelect(null);
        }
        const newRole = roles.find(o => o.id === id);
        if (!!newRole) {
          onSelect(newRole);
        }
      }}
    />
  );
};
