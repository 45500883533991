import { CloseOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import { Colors } from '../Theme';
import {
  EditableExcludeRulesMap,
  EditableRuleIds,
  RuleEditorProps,
  ValidationInfo,
  invalid,
  onChangeWithValidation,
  useDescription,
  valid,
} from '../rule-editors/RuleEditorCommon';
import { EditorInput, Explanatory, HeaderRow, REGEX_EXPLANATION, Rule } from './RuleEditorCommon';

export const YearsOfExperienceEditor: React.FC<RuleEditorProps> = ({
  rule,
  onChange,
  style,
  title,
  ruleType,
}) => {
  const replaceParam = (value: string | null, index: number) => {
    if (value === null) {
      onChangeWithValidation(
        { ...rule, parameters: rule.parameters.filter((_, idx) => idx !== index) },
        onChange,
        validate
      );
    } else {
      const replaced = [...rule.parameters];
      replaced[index] = value;
      onChangeWithValidation({ ...rule, parameters: replaced }, onChange, validate);
    }
  };

  const jobTitles = [...rule.parameters.slice(2)];
  if (jobTitles.every(j => !!j)) {
    jobTitles.push('');
  }

  const description = useDescription(
    { rule, ruleType },
    {
      include: 'Experience in these job titles should boost a profile within the model.',
      exclude: 'Experience in these job titles should remove a profile from the model.',
      invert: 'Give us some examples of job titles which would count as experience for your role.',
    }
  );

  return (
    <div style={style}>
      {title && <HeaderRow label={title} onChange={onChange} rule={rule} />}
      <Explanatory>{description}</Explanatory>
      {jobTitles.map((jt, jobIndex) => (
        <div key={`jt-input-${jobIndex}`} style={{ display: 'flex', alignItems: 'center' }}>
          <EditorInput
            placeholder={'Enter a job title which counts as work experience'}
            style={{ width: 380, marginBottom: 10 }}
            value={jt}
            onChange={e => replaceParam(e.currentTarget.value || null, jobIndex + 2)}
          />
          {!!jt && (
            <Button
              tabIndex={-1}
              style={{ color: Colors.Static.SEQUOIA_BRIGHT_RED }}
              onClick={() => replaceParam(null, 2 + jobIndex)}
              type={'link'}
            >
              <CloseOutlined style={{ marginLeft: 20 }} />
            </Button>
          )}
          {jobIndex === 0 && (
            <Tooltip overlayStyle={{ minWidth: 500 }} overlay={REGEX_EXPLANATION}>
              <QuestionCircleOutlined
                style={{ color: Colors.Static.SEQUOIA_LIGHT_TEXT, marginLeft: 5 }}
              />
            </Tooltip>
          )}
        </div>
      ))}

      <div style={{ display: 'flex', marginTop: 25 }}>
        <div>
          <div>Minimum Experience (Years)</div>
          <EditorInput
            value={rule.parameters[0]}
            onChange={e => replaceParam(e.currentTarget.value, 0)}
          />
        </div>
        <div style={{ width: 40 }} />
        <div>
          <div>Maximum Experience (Years)</div>
          <EditorInput
            value={rule.parameters[1]}
            onChange={e => replaceParam(e.currentTarget.value, 1)}
          />
        </div>
      </div>
    </div>
  );
};

const nanOrEmpty = (value: string) => isNaN(Number(value)) || value === '';

const validate = (rule: Rule): ValidationInfo => {
  if (
    !rule.parameters ||
    rule.parameters.filter(Boolean).length < 3 ||
    nanOrEmpty(rule.parameters[0]) ||
    nanOrEmpty(rule.parameters[1])
  ) {
    const ruleLabel = EditableExcludeRulesMap[rule.id as EditableRuleIds]?.label || '';
    return invalid(
      `${ruleLabel} requires at least 1 title and a minimum and maxmimum number of years of experience.`
    );
  }
  if (Number(rule.parameters[0]) > Number(rule.parameters[1])) {
    return invalid('Minimum experience must be less than maximum experience.');
  }
  return valid();
};
