import React, { useContext } from 'react';
import { SEQUOIA_ORG_ID } from '../App';
import { ResourceOps, useResource } from '../shared/Resource';
import { OrganizationContext } from './Organization';

export interface NetworkContextData {
  network?: NetworkAPI.NetworkRes;
  networkOps: ResourceOps<NetworkAPI.NetworkRes, Partial<NetworkAPI.NetworkRes>>;
  loadingMsg: string;
}

export const NetworkContext = React.createContext<NetworkContextData>(
  new Error('No network context data available, outside of scope?') as any as NetworkContextData
);

export const NetworkProvider: React.FunctionComponent = ({ children }) => {
  const { id } = useContext(OrganizationContext);
  const network = useNetwork(id);

  return <NetworkContext.Provider value={network}>{children}</NetworkContext.Provider>;
};

const useNetwork = (orgId: number) => {
  const [network, networkOps] = useResource<NetworkAPI.NetworkRes>(
    orgId !== SEQUOIA_ORG_ID ? `/api/${orgId}/network` : ''
  );

  return React.useMemo(() => {
    if (!network) {
      return {
        network: undefined,
        networkOps,
        loadingMsg: network
          ? "We're building your network - check back in a few minutes!"
          : "Hold on. We're gathering your team's networks.",
      };
    }

    return { network, networkOps, loadingMsg: '' };
  }, [network, networkOps]);
};

export const EMPTY_NETWORK_OWNERS: NetworkAPI.NetworkRes['owners'] = {
  ids: [],
  firstDegreeIds: [],
  secondDegreeIds: [],
  sharedIds: [],
  profiles: {},
};
