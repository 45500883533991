import { CloseOutlined } from '@ant-design/icons';
import { Button, Select } from 'antd';
import React, { useContext, useState } from 'react';
import { LocationInputV2 } from '../LocationInput';
import { Colors } from '../Theme';
import {
  EditableExcludeRulesMap,
  EditableRuleIds,
  HeaderRow,
  InputNumberNoArrows,
  Row,
  Rule,
  RuleEditorContext,
  RuleEditorProps,
  ValidationInfo,
  invalid,
  onChangeWithValidation,
  valid,
} from '../rule-editors/RuleEditorCommon';

export const KM_PER_MILE = 0.621371;
export const MILES_KM_LAST_USED = 'distance_last_used';
export type DistanceUnit = 'km' | 'miles';

export const LocationEditor: React.FC<RuleEditorProps> = ({ rule, onChange, style, title }) => {
  const locations = rule.parameters.slice(1);

  const [newLocation, setNewLocation] = useState<LocationAPI.Place | null>(null);

  const { locationInputBasePath } = useContext(RuleEditorContext);

  const [distanceUnit, _setDistanceUnit] = useState<DistanceUnit>(
    (window.localStorage.getItem(MILES_KM_LAST_USED) as DistanceUnit) || 'miles'
  );

  const distanceNumber =
    distanceUnit === 'miles'
      ? Number(rule.parameters[0])
      : Math.round(Number(rule.parameters[0]) / KM_PER_MILE);

  const setDistanceUnit = (unit: DistanceUnit) => {
    _setDistanceUnit(unit);
    window.localStorage.setItem(MILES_KM_LAST_USED, unit);
  };

  const onNewLocation = (location: LocationAPI.Place | null) => {
    if (!location || rule.parameters.includes(location.name)) {
      return;
    }
    onChangeWithValidation(
      { ...rule, parameters: [...rule.parameters, location.name] },
      onChange,
      validate
    );
    setNewLocation(null);
  };

  return (
    <div style={style}>
      {title && <HeaderRow label={title} onChange={onChange} rule={rule} />}
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div>Distance: </div>
        <InputNumberNoArrows
          onKeyDown={e => {
            if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
              e.preventDefault();
              e.stopPropagation();
            }
          }}
          addonAfter={
            <Select
              style={{ minWidth: 75 }}
              value={distanceUnit}
              onChange={setDistanceUnit}
              options={[
                { value: 'miles', label: 'miles' },
                { value: 'km', label: 'km' },
              ]}
            />
          }
          type={'number'}
          style={{ marginLeft: 10, width: 150 }}
          value={distanceNumber}
          onChange={e => {
            let newVal: number = Number(e.target.value) || 0;
            if (distanceUnit === 'km') {
              newVal = Math.round(newVal * KM_PER_MILE);
            }
            const newParamArr = [...rule.parameters];
            newParamArr[0] = `${newVal}`;
            onChangeWithValidation({ ...rule, parameters: newParamArr }, onChange, validate);
          }}
        />
      </div>
      <div style={{ marginTop: 20 }}>
        Show only people who are within {distanceNumber} {distanceUnit} of any the locations below:
      </div>
      <div style={{ marginTop: 10 }}>
        {locations.map(location => {
          return (
            <Row style={{ marginBottom: 10 }} key={location}>
              <div style={{ minWidth: 250 }}>{location}</div>
              <Button
                style={{ color: Colors.Static.SEQUOIA_BRIGHT_RED }}
                onClick={() => {
                  onChangeWithValidation(
                    {
                      ...rule,
                      parameters: rule.parameters.filter(p => p !== location),
                    },
                    onChange,
                    validate
                  );
                }}
                type={'link'}
              >
                <CloseOutlined style={{ marginLeft: 20 }} />
              </Button>
            </Row>
          );
        })}
        <LocationInputV2
          baseAPIPath={locationInputBasePath}
          value={newLocation}
          onChange={onNewLocation}
          style={{ width: 300 }}
          clearAfterSelection={true}
        />
      </div>
    </div>
  );
};

const validate = (rule: Rule): ValidationInfo => {
  const ruleLabel = EditableExcludeRulesMap[rule.id as EditableRuleIds]?.label || '';
  if (
    !rule.parameters ||
    rule.parameters.filter(Boolean).length < 2 ||
    !Number(rule.parameters[0])
  ) {
    return invalid(`${ruleLabel} requires at least 1 place.`);
  }
  return valid();
};
