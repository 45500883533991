//This was a shared comonent, but the external style has diverged too much to justify forking, perhaps share some code @ finish
import { Empty } from 'antd';
import Quill from 'quill';
import React, { ReactNode } from 'react';
import ReactQuill from 'react-quill';
import styled, { css } from 'styled-components/macro';
import { ClickableItem } from '../shared/Common';
import { createEmailPreview } from '../shared/helpers';
import { EyeIcon } from '../shared/Svgs';
import { Colors } from '../shared/Theme';
import { InsertVariableButton, TemplateContext } from './ExternalTemplateSupport';
import { ArrowDown, IconContainer } from './Svgs';
import { Label } from './Text';

const { SEQUOIA_BLACK } = Colors.Static;

/**
 * Register the quill library to use <div> as default container for text instead of <p>, which
 * has formatting side-effects in email clients, see : https://github.com/quilljs/quill/issues/1074
 */
const Block = Quill.import('blots/block');
Block.tagName = 'DIV';
Quill.register(Block, true);

export interface CursorBodyState {
  text: string;
  insertFunction?: (toInsert: string) => void;
}

export const ExternalTemplateEditor: React.FunctionComponent<{
  value: CursorBodyState;
  setValue: (newVal: CursorBodyState) => void;
  sender: { name: string; email: string };
  config: {
    subject: string;
    title?: ReactNode;
    titleStyle?: React.CSSProperties;
    previewOnly?: boolean;
    additionalMenuItems?: ReactNode[];
    noVariables?: boolean;
  };
}> = ({ value, setValue, config, sender }) => {
  const { recipient, quillScrollingContainer, variables } = React.useContext(TemplateContext);
  const [previewMode, setPreviewMode] = React.useState<boolean>(false);
  const quillElement = React.useRef<ReactQuill | null>(null);
  const { title, titleStyle, previewOnly, subject, additionalMenuItems, noVariables } = config;

  const InlineFormLabelValuePair: React.FunctionComponent<{
    label: string;
    value: string;
    html?: string;
  }> = ({ label, value, html }) => {
    return (
      <div style={{ display: 'flex' }}>
        <div style={{ marginRight: 10, fontWeight: 500, color: SEQUOIA_BLACK }}>{label}</div>
        {html ? <div dangerouslySetInnerHTML={{ __html: html }} /> : <div>{value}</div>}
      </div>
    );
  };

  const preview = createEmailPreview(value.text, subject, variables);

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {title ? (
          title
        ) : (
          <Label style={{ margin: 0, ...titleStyle }}>{previewMode ? 'Preview' : 'Body'}</Label>
        )}
        <div style={{ flex: 1 }} />
        {!previewOnly && !noVariables && (
          <ClickableItem onClick={e => setPreviewMode(!previewMode)}>
            <IconContainer>
              <EyeIcon color={previewMode ? Colors.Static.SEQUOIA_GREEN : undefined} />
            </IconContainer>
          </ClickableItem>
        )}
        {!previewOnly && !noVariables && (
          <InsertVariableButton
            style={{
              textTransform: 'uppercase',
              fontFamily: 'Pitch Sans',
              fontWeight: 500,
              color: SEQUOIA_BLACK,
              display: 'flex',
              alignItems: 'center',
              gap: 6,
            }}
            compactMode={false}
            value={value}
          >
            Variables
            <IconContainer style={{ width: 16, marginBottom: 2 }}>
              <ArrowDown />
            </IconContainer>
          </InsertVariableButton>
        )}
        {!previewMode &&
          !previewOnly &&
          additionalMenuItems?.map((m, idx) => <div key={idx}>{m}</div>)}
      </div>
      {previewMode || previewOnly ? (
        <div
          style={{
            maxWidth: '100%',
            border: '1px solid #F0F0F0',
            borderRadius: 0,
            padding: 10,
          }}
        >
          <InlineFormLabelValuePair
            label={'From: '}
            value={sender.name ? `${sender.name} <${sender.email}>` : sender.email}
          />
          <InlineFormLabelValuePair
            label={'To: '}
            value={recipient.name ? `${recipient.name} <${recipient.email}>` : recipient.email}
          />
          <InlineFormLabelValuePair label={'Subject: '} value={preview.subject} />
          <div
            style={{
              marginTop: 24,
            }}
          >
            {value.text ? (
              <TextDisplayArea dangerouslySetInnerHTML={{ __html: preview.body }}></TextDisplayArea>
            ) : (
              <Empty />
            )}
          </div>
        </div>
      ) : (
        <div style={{ position: 'relative' }}>
          <StyledQuill
            ref={quillElement}
            preserveWhitespace={true}
            scrollingContainer={quillScrollingContainer}
            bounds={'.editorContainer'}
            modules={{
              clipboard: {
                matchVisual: false,
              },
              toolbar: {
                container: [['bold', 'italic', 'underline', 'link', 'clean']],
              },
            }}
            formats={['bold', 'italic', 'underline', 'link', 'image']}
            theme="snow"
            onBlur={range => {
              if (range) {
                const insertFunction = (insertText: string) => {
                  quillElement?.current?.getEditor()?.insertText(range.index, insertText, 'user');
                };
                setValue({ ...value, insertFunction });
              }
            }}
            placeholder={
              'Type the body of your email here, enclose variables in curly brackets, ex: {{last_name}}.'
            }
            onChange={(newValue, _, source) => {
              if (source === 'user') {
                //Ignore Initial call indicating text is dirty but is just Quill reformatting text (source is 'api')
                setValue({ insertFunction: value.insertFunction, text: newValue });
              }
            }}
            value={value.text}
          />
        </div>
      )}
    </>
  );
};

const TextDisplayArea = styled.div`
  border-radius: 3px;
  white-space: break-spaces;
`;

export const SequoiaQuillCSS = css`
  margin-top: 0px;
  border: none;
  margin-bottom: 50px; // This provides space for the toolbar to render below
  overflow: visible;
  position: relative;

  & > .ql-container {
    overflow: visible;
    font-family: inherit;
    font-size: inherit;
    border: none;
  }
  & > .ql-container > .ql-editor {
    min-height: 200px !important;
    border: 1px solid ${Colors.Static.BLACK3ALPHA};
    border-bottom: none;
    background: white;
  }

  & > .ql-toolbar {
    position: absolute;
    bottom: 0;
    width: 100%;
    transform: translateY(100%);
    background: white;
    min-height: 43px;
    border: 1px solid ${Colors.Static.BLACK3ALPHA};
    & > .ql-formats > button {
      stroke: '#5c5c5c';
      & > svg {
        stroke: #5c5c5c;
        & > path,
        line {
          stroke: #5c5c5c;
        }
      }
    }
  }
`;

const StyledQuill = styled(ReactQuill)`
  flex: 20;

  ${SequoiaQuillCSS}
`;
