import { CSSProperties, default as React } from 'react';
import { SEQUOIA_ORG_ID } from '../../App';
import { OrganizationContext } from '../../contexts/Organization';
import { Select } from './Select';

export const TeamMemberSelect: React.FunctionComponent<{
  value: number | null | undefined;
  placeholder?: string;
  style?: CSSProperties;
  onChange: (user: ExternalAPI.UserWithCampaigns) => void;
}> = ({ onChange, value, placeholder, style }) => {
  const { team, me, id } = React.useContext(OrganizationContext);
  const orgSenders = [me, ...team].filter(t => t.organizationId === id);
  const selected = orgSenders.find(s => s.id === value);

  return (
    <Select
      type="secondary"
      placeholder={placeholder || '--'}
      selected={selected?.id}
      options={orgSenders.map(o => ({ id: o.id, name: o.name }))}
      style={style}
      onSelect={id => {
        const sender = orgSenders.find(o => o.id === id);
        if (!sender) return;
        onChange(sender);
      }}
    />
  );
};

export const SourcingPOCSelect: React.FunctionComponent<{
  value: number | null | undefined;
  style?: CSSProperties;
  onChange: (user: ExternalAPI.UserWithCampaigns) => void;
}> = ({ onChange, value, style }) => {
  const { team, me } = React.useContext(OrganizationContext);
  const orgSenders = [me, ...team].filter(
    t => t.organizationId === SEQUOIA_ORG_ID && t.internalTalentId
  );
  const selected = orgSenders.find(s => s.internalTalentId === value);

  return (
    <Select
      type="secondary"
      placeholder="--"
      selected={selected?.internalTalentId}
      options={orgSenders.map(o => ({ id: o.internalTalentId!, name: o.name }))}
      style={style}
      onSelect={internalTalentId => {
        const sender = orgSenders.find(o => o.internalTalentId === internalTalentId);
        if (!sender) return;
        onChange(sender);
      }}
    />
  );
};
