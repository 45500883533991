import { SettingFilled } from '@ant-design/icons';
import moment from 'moment';
import { default as React, useContext } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { CountWithIcon, HorizontalDivider, ListSubheader } from '../components/Common';
import { NotificationContext } from '../contexts/Notification';
import { OrganizationContext } from '../contexts/Organization';
import { NotificationDot } from '../shared/Common';
import { useScrollToItem } from '../shared/hooks';
import { InitialsView } from '../shared/ProfileImage';
import { useResource } from '../shared/Resource';
import { Colors } from '../shared/Theme';
import { SourcedNotice } from './LeadListHeader';
import { LeadListWithReviewStatus } from './PageLeads';

const { BLACK5ALPHA, BLACK1ALPHA } = Colors.Static;
export const LEAD_LIST_NAME_DATE_FORMAT = 'MMM D, YYYY';

const SectionLabels: Record<LeadListWithReviewStatus['status'], string> = {
  drafts: 'Drafts',
  reviewing: 'Ready for Review',
  reviewed: 'Reviewed',
};

export const LeadListsSection: React.FC<{
  roles: OrganizationAPI.Role[];
  leadListsByReviewStatus: LeadsAPI.LeadListDict;
  currLeadList?: LeadListWithReviewStatus;
  setList: (b: LeadListWithReviewStatus) => void;
}> = ({ roles, leadListsByReviewStatus, currLeadList, setList }) => {
  const {
    notifications: { features: featureNotifs },
    actions: { markSeen },
  } = useContext(NotificationContext);
  const { id: orgId } = useContext(OrganizationContext);

  const notifAboutCurrent =
    currLeadList && featureNotifs.leads.find(n => n.data.id === currLeadList.id);

  React.useEffect(() => {
    if (notifAboutCurrent) {
      markSeen(notifAboutCurrent);
    }
  }, [notifAboutCurrent, markSeen]);

  const [engineModels = []] = useResource<SourcingEngineAPI.EngineModel[]>(
    `/api/sourcing/${orgId}/engine-models`
  );

  return (
    <>
      {(Object.keys(leadListsByReviewStatus) as LeadListWithReviewStatus['status'][]).map(
        (s, idx) => {
          const leadLists = leadListsByReviewStatus[s];

          if (!leadLists.length) return <div key={`empty-${idx}`} />;

          return (
            <div key={`${idx}-${s}`}>
              <ListSubheader>{SectionLabels[s]}</ListSubheader>
              {leadLists.map((l, idx) => (
                <React.Fragment key={`list-${l.id}`}>
                  <LeadListRow
                    roles={roles}
                    leadList={l}
                    unread={featureNotifs.leads.some(n => n.data.id === l.id)}
                    selected={currLeadList?.id === l.id}
                    setList={(l: LeadsAPI.LeadList) => setList({ ...l, status: s })}
                    engineModels={engineModels}
                  />
                  {idx < leadLists.length - 1 && <HorizontalDivider />}
                </React.Fragment>
              ))}
            </div>
          );
        }
      )}
      <HorizontalDivider />
    </>
  );
};

const LeadListRow: React.FC<{
  roles: OrganizationAPI.Role[];
  leadList: LeadsAPI.LeadList;
  setList: (b: LeadsAPI.LeadList) => void;
  selected: boolean;
  unread: boolean;
  engineModels: SourcingEngineAPI.EngineModel[];
}> = ({ roles, leadList, setList, selected, unread, engineModels }) => {
  const { sender, createdAt, description } = leadList;
  const ref = useScrollToItem(selected);
  const role = roles.find(r => r.id === leadList.roleId);
  const engineModel = engineModels.find(
    em => em.target?.id === leadList.roleId && em.target?.type === 'role'
  );

  return (
    <RowContainer selected={selected} ref={ref} onClick={() => setList(leadList)}>
      {unread && <NotificationDot style={{ position: 'absolute', marginLeft: -12 }} />}

      {sender ? (
        <InitialsView name={sender.name} style={{ minWidth: 42, height: 42, fontSize: 15 }} />
      ) : (
        <img style={{ minWidth: 42 }} src="/icons/auto-sourcing.svg" alt="wand" />
      )}
      <div style={{ paddingLeft: 12, flex: 1 }}>
        <LeadListTitle>
          {moment(createdAt).format(LEAD_LIST_NAME_DATE_FORMAT)} ({moment(createdAt).fromNow()}){' '}
          <MemberCount leadList={leadList} />
        </LeadListTitle>
        {description && <div style={{ color: BLACK5ALPHA, marginBottom: 4 }}>{description}</div>}
        {leadList.roleId && (
          <div>
            Role:
            <span style={{ fontWeight: 500, marginLeft: 6 }}>
              {roles.find(r => r.id === leadList.roleId)?.name || 'Role Not Found'}
            </span>
            {!!engineModel && (
              <Link
                to={`/${role?.organizationId}/settings?tab=Candidate%20Filters&roleId=${role?.id}`}
              >
                <SettingFilled
                  style={{ marginLeft: 6, color: BLACK5ALPHA }}
                  width={10}
                  height={10}
                />
              </Link>
            )}
          </div>
        )}
        <SourcedNotice sender={sender} style={{ fontWeight: 500, marginLeft: 4 }} />
      </div>
      {selected && (
        <img
          style={{ width: 24, opacity: 0.7, userSelect: 'none', position: 'absolute', right: 4 }}
          src="/icons/arrow-right.svg"
          alt="right-arrow"
        />
      )}
    </RowContainer>
  );
};

const MemberCount: React.FC<{ leadList: LeadsAPI.LeadList }> = ({ leadList }) => {
  const approvedCount = leadList.profiles.filter(b => b.review?.status === 'approved').length;

  return (
    <CountWithIcon
      icon={{ src: '/icons/person-filled.svg', alt: 'person' }}
      count={
        approvedCount
          ? `${approvedCount} / ${leadList.profiles.length}`
          : `${leadList.profiles.length}`
      }
    />
  );
};

export const RowContainer = styled.div<{ selected?: boolean }>`
  display: flex;
  align-items: center;
  padding: 16px 12px;
  font-size: 12px;
  user-select: none;
  &:hover {
    background: ${BLACK1ALPHA};
  }

  ${({ selected }) => selected && `background: ${BLACK1ALPHA};`}
`;

export const LeadListTitle = styled.div`
  font-size: 14px;
  fontweight: 500;
  color: ${Colors.Static.SEQUOIA_GREEN};
  margin-bottom: 4px;
  display: flex;
  gap: 12;
  justify-content: space-between;
`;
