import { omit } from 'lodash';
import React, { CSSProperties } from 'react';
import styled from 'styled-components/macro';
import { ClickableItem } from '../shared/Common';
import { Colors, hexWithOpacity } from '../shared/Theme';
import { DisplayOnHover, HoverableDiv, LightTooltip } from './Common';
import { Body3 } from './Text';

const { BLACK1ALPHA, WARNING_TINT } = Colors.Static;

export interface SeqTagProps {
  tagName: React.ReactNode | string;
  tagColor?: string;
  description?: React.ReactNode | string;
  clickable?: boolean;
  editEl?: (editButton: React.ReactElement) => React.ReactNode;
  onRemove?: () => void;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  style?: CSSProperties;
}

export const SeqTag = React.memo((props: SeqTagProps) => {
  const { description, tagName, onRemove, editEl, clickable, onClick } = props;
  const limitedProps = {
    ...omit(props, ['children', 'ref', 'as', 'onRemove']),
    clickable: !!description || !!onClick || clickable,
  };

  const tag = (
    <HoverableDiv style={{ display: 'flex' }}>
      <StyledTag {...limitedProps}>{tagName}</StyledTag>
      {(onRemove || editEl) && (
        <DisplayOnHover>
          <EditContainer deleteOnly={!editEl}>
            {editEl?.(
              <ClickableItem style={{ marginRight: 2 }}>
                <img
                  src="/icons/vertical-ellipsis.svg"
                  alt="Ellipsis"
                  style={{ transform: 'rotate(90deg)', height: 16 }}
                />
              </ClickableItem>
            )}
            <ClickableItem onClick={onRemove}>
              <img src="/icons/small-x.svg" alt="A close icon" />
            </ClickableItem>
          </EditContainer>
        </DisplayOnHover>
      )}
    </HoverableDiv>
  );

  return description ? (
    <LightTooltip placement="topLeft" overlay={description}>
      {tag}
    </LightTooltip>
  ) : (
    tag
  );
});

export const StyledTag = styled(Body3)<{ tagColor?: string; clickable?: boolean }>`
  background: ${({ tagColor }) => tagColor || BLACK1ALPHA};
  padding: 2px 10px 1px 6px;
  border-radius: 5px 15px 15px 5px;
  font-weight: ${({ tagColor }) => (tagColor ? 500 : 400)};
  white-space: nowrap;
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'default')};
  &:hover {
    opacity: ${({ clickable }) => (clickable ? 0.7 : 1)};
  }
`;

const EditContainer = styled.div<{ deleteOnly?: boolean }>`
  height: 22px;
  border-radius: 15px;
  display: flex;
  margin-left: ${({ deleteOnly }) => (deleteOnly ? -28 : -41)}px;
  padding: 3px 3px 3px 6px;
  position: relative;
  z-index: 2;
  background: ${hexWithOpacity('#ffffff', 0.8)};
`;

export const InnerTag = styled.span`
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
  color: ${WARNING_TINT};
  background: ${hexWithOpacity('#ffffff', 0.5)};
  border-radius: 10px;
  padding: 2px 3px;
  margin-left: 4px;
  margin-right: -6px;
`;
