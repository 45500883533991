import React from 'react';
import { SeqButton } from './Button';
import { SeqInput } from './Input';
import { SeqModal } from './Modal';
import { H3Mono, Label } from './Text';

export const TemplateOverrideFromModal: React.FC<{
  visible: boolean;
  fromDefault: string;
  fromUserWithEmail: string | undefined;
  onClose: () => void;
  onReset: () => void;
  onChange: (fromUserWithEmail: string | undefined) => void;
}> = ({ visible, fromDefault, fromUserWithEmail, onClose, onReset, onChange }) => {
  return (
    <SeqModal width={600} open={visible} onClose={onClose}>
      <div style={{ padding: 24 }}>
        <H3Mono style={{ marginBottom: 12 }}>Follow-up from another address</H3Mono>
        <p>
          Create an eye-catching email campaign by sending the first email from one account and a
          follow-up from a co-founder or an email alias. To use this option:
          <ul>
            <li style={{ marginTop: 16 }}>
              The follow-up sender address must be CC'd on the campaign email thread.
            </li>
            <li style={{ marginTop: 8 }}>
              The address must be the primary email or alias of a user in your Sequoia Talent Tools
              organization.
            </li>
            <li style={{ marginTop: 8 }}>
              The user owning the address must choose "Allow teammates to send emails on my behalf"
              under "My Account". (Enabled by default.)
            </li>
          </ul>
        </p>
        <p style={{ marginTop: 24 }}>
          Note: When using this option, you may want to create a mail rule marking follow-up
          messages as read. Sequoia Talent Tools does not have write access to your mailbox, and
          follow-ups will be visible unread emails in your inbox.
        </p>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 12,
            marginTop: 24,
            marginBottom: 24,
          }}
        >
          <div style={{ width: 200, flexShrink: 0 }}>
            <Label>Follow-up From</Label>
          </div>
          <SeqInput
            autoFocus
            outline="rect"
            size="small"
            placeholder={fromDefault}
            value={fromUserWithEmail || ''}
            style={{ marginLeft: 12 }}
            onKeyDown={e => (e.key === 'Enter' || e.key === 'Return' ? onClose() : undefined)}
            onChange={e => onChange(e.currentTarget.value ? e.currentTarget.value : undefined)}
          />
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <SeqButton style={{ marginRight: 12 }} onClick={onReset}>
            Reset
          </SeqButton>
          <SeqButton onClick={onClose} intent="primary">
            Update
          </SeqButton>
        </div>
      </div>
    </SeqModal>
  );
};
