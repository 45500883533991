import { CommentOutlined } from '@ant-design/icons';
import qs from 'query-string';
import { default as React, useContext } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { CandidateProfile } from '../components/CandidateProfile';
import { FlexFillingColCSS, FlexFillingRowCSS, LightTooltip } from '../components/Common';
import { MissingPermissionHeader } from '../components/MissingPermissionsHeader';
import { LabelCSS } from '../components/Text';
import { OrganizationContext } from '../contexts/Organization';
import { CampaignConfig, CAMPAIGN_SELECT_LINKED_IN_OPTION } from '../hooks/useCampaignConfig';
import { encodePipelineFilters } from '../pipeline/PagePipeline';
import { useCandidateFiltersPermission } from '../settings/PageSettings';
import { ClickableItem, FadeInContainer } from '../shared/Common';
import { useResource } from '../shared/Resource';
import { Colors } from '../shared/Theme';
import { CustomizeEmail } from './CustomizeEmail';
import { ReviewFeedback } from './LeadReviewCarousel';
import { CandidateWithDetails, LeadListWithReviewStatus } from './PageLeads';
import { ReviewFooter } from './ReviewFooter';

const {
  SEQUOIA_PASTEL_YELLOW,
  SEQUOIA_PASTEL_GREEN,
  SEQUOIA_PASTEL_RED,
  SEQUOIA_GREEN,
  SEQUOIA_BLACK,
  BLACK5ALPHA,
  BLACK1ALPHA,
} = Colors.Static;

export interface CarouselActions {
  disabledReason?: string;
  onAccept: (overrides?: CampaignAPI.CandidateOverride) => void;
  onReject: (feedback?: ReviewFeedback) => void;
  onLater: (blockCarouselAdvance?: boolean) => void;
  onCampaignUpdate: (update: LeadsAPI.CampaignUpdate) => void;
  onRequestIntro: (kind: 'talent' | 'network', id?: number) => void;
}

export const CarouselItem: React.FunctionComponent<{
  leadList: LeadListWithReviewStatus;
  campaignConfig: CampaignConfig;
  customizing: boolean;
  setCustomizing: (c: boolean) => void;
  numOfRemainingCandidates: number;
  candidate: CandidateWithDetails;
  isReviewable: boolean;
  actions: CarouselActions;
  showingFinishedScreen: boolean;
}> = ({
  leadList,
  campaignConfig,
  customizing,
  setCustomizing,
  numOfRemainingCandidates,
  candidate,
  isReviewable,
  actions,
  showingFinishedScreen,
}) => {
  const { onAccept, onReject, onCampaignUpdate } = actions;
  const {
    profiles,
    settings: { campaignType },
    generatedBySEModelId,
  } = leadList;
  const { profile, review, comment: sourcerComment } = candidate;
  const { campaign, campaignSelect } = campaignConfig;
  const numOfCandidates = profiles.length;
  const percentOfRemaining = Math.floor(
    ((numOfCandidates - numOfRemainingCandidates) / numOfCandidates) * 100
  );
  const disableFooterReason = actions.disabledReason;
  const disableAllFooterActions = disableFooterReason || customizing;

  const contactPoints = useContactPoints(candidate);

  const campaignHeaderEl = campaignSelect({
    onSelect: (campaignId, roleId) =>
      onCampaignUpdate({
        campaignType: campaignId === CAMPAIGN_SELECT_LINKED_IN_OPTION ? 'LinkedIn' : 'email',
        campaignId: campaignId > 0 ? campaignId : undefined,
        roleId,
      }),
  });

  return (
    <CarouselItemContainer style={{ minWidth: '45vw', maxWidth: '90vw' }}>
      <CarouselPanel style={{ width: '45vw', minWidth: 600 }}>
        {!!campaign?.sender?.warning ? (
          <MissingPermissionHeader warning={campaign.sender.warning} />
        ) : !!review && !showingFinishedScreen ? (
          <ReviewedHeader
            profile={profile}
            review={review}
            sentCampaign={
              contactPoints && 'campaign' in contactPoints ? contactPoints.campaign : undefined
            }
            campaignHeaderEl={campaignHeaderEl}
          />
        ) : (
          <>
            <Header>
              {campaignHeaderEl}
              {(!!campaign || campaignType === 'LinkedIn') && (
                <div style={{ flex: '1 0 auto', textAlign: 'right' }}>
                  {percentOfRemaining}% complete ({numOfRemainingCandidates} remaining)
                </div>
              )}
            </Header>
            <ProgressBar percent={percentOfRemaining} customizing={customizing} />
          </>
        )}
        {!showingFinishedScreen ? (
          <>
            <CandidateProfile
              sourcerComment={
                sourcerComment ? { comment: sourcerComment, author: leadList.sender } : undefined
              }
              annotation={leadList.annotations ? leadList.annotations?.[profile.id] : undefined}
              profile={profile}
              generatedBySEModelId={generatedBySEModelId}
              onReject={onReject}
              hasCampaign={!!campaign}
            />
            {!customizing && (
              <ReviewFooter
                disableAllFooterActions={disableAllFooterActions}
                alreadyContacted={
                  !!contactPoints &&
                  'emailThreadCount' in contactPoints &&
                  contactPoints.emailThreadCount > 0
                }
                roleId={leadList.roleId}
                settings={leadList.settings}
                isReviewable={isReviewable}
                actions={actions}
                candidate={candidate}
                campaign={campaign}
                setCustomizing={setCustomizing}
              />
            )}
          </>
        ) : (
          <AllReviewedPage leadList={leadList} />
        )}
      </CarouselPanel>
      {customizing && campaign?.sender && (
        <CarouselPanel style={{ width: '40vw' }}>
          <CustomizeEmail
            key={profile.email}
            candidate={profile}
            footer={{
              disabled: disableFooterReason,
              onCancel: () => setCustomizing(false),
              onSubmit: (overrides?: CampaignAPI.CandidateOverride) => onAccept(overrides),
            }}
            campaign={campaign}
          />
        </CarouselPanel>
      )}
    </CarouselItemContainer>
  );
};

const ReviewedHeader: React.FunctionComponent<{
  review: LeadsAPI.CandidateReview;
  campaignHeaderEl: JSX.Element;
  profile: ExternalAPI.Profile;
  sentCampaign?: { id: number; name: string };
}> = ({ review, campaignHeaderEl, profile, sentCampaign }) => {
  const { status, categories, comment } = review;
  const { id: orgId } = useContext(OrganizationContext);

  let backgroundColor;
  switch (review.status) {
    case 'approved':
      backgroundColor = SEQUOIA_PASTEL_GREEN;
      break;
    case 'rejected':
      backgroundColor = SEQUOIA_PASTEL_RED;
      break;
    default:
      backgroundColor = SEQUOIA_PASTEL_YELLOW;
  }

  const categoryString = categories?.length ? (
    <div>
      {categories[0]} {categories.length > 1 ? ` + ${categories.length - 1} more` : ''}
    </div>
  ) : undefined;

  return (
    <Header
      style={{
        backgroundColor,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: 24,
        padding: 12,
      }}
    >
      <FadeInContainer>{sentCampaign?.name || campaignHeaderEl}</FadeInContainer>
      <div
        style={{
          minWidth: '30%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
          justifyContent: 'center',
        }}
      >
        <div style={{ display: 'flex', gap: 8 }}>
          {status}
          {comment && (
            <LightTooltip overlay={comment}>
              <ClickableItem>
                <CommentOutlined />
              </ClickableItem>
            </LightTooltip>
          )}
        </div>
        {categoryString ? (
          categoryString
        ) : status !== 'rejected' ? (
          <Link
            to={`/${orgId}/pipeline/search?${qs.stringify(
              encodePipelineFilters({ searchTerm: profile.name })
            )}`}
          >
            View In Pipeline
          </Link>
        ) : undefined}
      </div>
    </Header>
  );
};

const AllReviewedPage: React.FC<{ leadList: LeadsAPI.LeadList }> = ({ leadList }) => {
  const { id: orgId } = useContext(OrganizationContext);
  const { profiles, generatedBySEModelId } = leadList;
  const percentRejected = Math.round(
    (profiles.filter(p => p.review?.status === 'rejected').length || 0 / profiles.length) * 100
  );
  const canViewCandidateFilters = useCandidateFiltersPermission(leadList.roleId);

  return (
    <div style={{ textAlign: 'center', margin: 'auto' }}>
      <img
        src="/icons/null-state-green.svg"
        alt="plant, paperwork and coffee"
        style={{ width: '75%' }}
      />
      <div style={{ padding: 24 }}>All candidates have been reviewed!</div>
      <div>
        Approved candidates will now appear in your{' '}
        <Link style={{ color: SEQUOIA_GREEN }} to={`/${orgId}/pipeline`}>
          pipeline
        </Link>
        .
      </div>
      {!!generatedBySEModelId && canViewCandidateFilters && percentRejected > 65 && (
        <div
          style={{
            background: SEQUOIA_PASTEL_RED,
            padding: 24,
            maxWidth: 600,
          }}
        >
          Most of the candidates in this list didn't seem to match what you're searching for. If
          your hiring goals have changed,{' '}
          <Link style={{ color: SEQUOIA_GREEN }} to={`/${orgId}/settings?tab=Candidate%20Filters`}>
            update your candidate filters
          </Link>{' '}
          to surface better leads.
        </div>
      )}
    </div>
  );
};

function useContactPoints(candidate: CandidateWithDetails) {
  const { id: orgId } = useContext(OrganizationContext);
  const { id: profileId } = candidate.profile;
  const [contactPoints] = useResource<ExternalAPI.ContactPoints>(
    `/api/${orgId}/profile/${profileId}/contact-points${
      candidate.review?.status === 'approved' ? '?status=approved' : ''
    }`
  );

  return contactPoints;
}

const CarouselItemContainer = styled.div`
  ${FlexFillingRowCSS}
  min-height: 0;
`;

const CarouselPanel = styled.div`
  ${FlexFillingColCSS};
`;

export const Header = styled.div`
  ${LabelCSS}
  width: 100%;
  border-bottom: 1px solid ${SEQUOIA_BLACK};
  color: ${SEQUOIA_BLACK};
  gap: 12px;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${BLACK1ALPHA};
`;

const ProgressBar = styled.div<{ percent: number; customizing: boolean }>`
  width: ${({ percent, customizing }) => `${percent / (customizing ? 2 : 1)}%`};
  border-top: 4px solid ${BLACK5ALPHA};
  margin-top: -4px;
`;
