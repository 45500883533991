import { Collapse, Table } from 'antd';
import { DrawerProps } from 'antd/es/drawer';
import Tooltip, { TooltipPropsWithOverlay, TooltipPropsWithTitle } from 'antd/lib/tooltip';
import { CSSProperties, default as React, ReactNode } from 'react';
import { useHistory } from 'react-router-dom';
import styled, { css, keyframes } from 'styled-components/macro';
import { ClickableItem, StyledDrawer } from '../shared/Common';
import { Colors, hexWithOpacity } from '../shared/Theme';
import { Body2Serif, H3Mono, Label } from './Text';

const { Panel } = Collapse;

export const SIDEBAR_WIDTH = 210;
export const FOOTER_HEIGHT = 68;
export const BOX_SHADOW = '0px 8px 16px rgba(140, 140, 140, 0.05)';

const {
  SEQUOIA_LIGHT_GRAY,
  SEQUOIA_PAPER,
  SEQUOIA_PASTEL_GRAY,
  BLACK7ALPHA,
  SEQUOIA_GREEN,
  SEQUOIA_BLACK,
  SEQUOIA_LIGHT_TEXT,
  BLACK2ALPHA,
  BLACK5ALPHA,
  BLACK1ALPHA,
} = Colors.Static;

export interface EmptyProps {
  imgProps?: React.ImgHTMLAttributes<HTMLImageElement>;
  description?: React.ReactNode;
  style?: CSSProperties;
}

/* Containers */

/** wraps sidebar + page*/
export const BaseContainer = styled.div`
  display: flex;
  background-color: ${SEQUOIA_PAPER};
  max-width: 100%;
  height: 100vh;
  color: ${SEQUOIA_BLACK};
`;

export const FlexFillingColCSS = css`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;
`;

export const Column = styled.div`
  ${FlexFillingColCSS}
`;

export const FlexFillingRowCSS = css`
  display: flex;
  flex-direction: row;
  flex: 1;
  min-width: 0;
`;

export const Row = styled.div`
  ${FlexFillingRowCSS}
`;

export const PageContainer = styled(Column)`
  padding: 24px;
  height: inherit;
`;

export const WhitePageContainer = styled.div`
  background: white;
  flex: 1;
  margin: 24px;
`;

interface BackNavProps {
  pageName: string;
  link?: string;
}

export const PageContainerWithBackNav: React.FC<{
  backTo: BackNavProps;
  style?: CSSProperties;
  additionalNavItem?: JSX.Element | undefined;
  children: React.ReactNode;
}> = ({ backTo, style, additionalNavItem, children }) => {
  const history = useHistory();

  return (
    <PageContainer>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <ClickableItem onClick={() => (backTo.link ? history.push(backTo.link) : history.goBack())}>
          <img
            style={{ height: 12, margin: '0 8px 2px 0' }}
            src="/icons/arrow-left.svg"
            alt="Left Arrow"
          />
          <Label>Back to {backTo.pageName}</Label>
        </ClickableItem>
        {additionalNavItem ? additionalNavItem : ''}
      </div>
      <div style={{ flex: 1, ...style }}>{children}</div>
    </PageContainer>
  );
};

export const SeqDrawer = (props: DrawerProps & { children?: ReactNode }) => (
  <StyledDrawer
    placement={'right'}
    closable={false}
    closeIcon={false}
    {...props}
    bodyStyle={{ padding: 0, height: '100vh', ...props.bodyStyle }}
  />
);

export const SeqFullPageOverlay: React.FC<{ width?: number }> = ({ children, width = 390 }) => {
  return (
    <div
      style={{
        position: 'absolute',
        width: `calc(100vw - ${SIDEBAR_WIDTH}px)`,
        height: '100vh',
        zIndex: 2,
        transition: 'filter 300ms linear',
        backdropFilter: 'blur(4px)',
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: SEQUOIA_PAPER,
          width,
          boxShadow: '0px 16px 32px rgba(140, 140, 140, .3)',
          zIndex: 4,
        }}
      >
        {children}
      </div>
    </div>
  );
};

export const Dialog: React.FC<{
  header?: string;
  content?: React.ReactNode;
  footer?: React.ReactNode;
  style?: CSSProperties;
}> = ({ header, content, footer, style, children }) => {
  return (
    <div style={{ padding: 24, ...style }}>
      {header && <H3Mono>{header}</H3Mono>}
      {content}
      {children}
      {footer && <div style={{ marginTop: 24 }}>{footer}</div>}
    </div>
  );
};

/* Header & Footer */

export const HeaderRow = styled.div`
  display: flex;
  min-width: 0;
  align-items: center;
  margin-bottom: 24px;
  gap: 12px;
`;

export const FooterRow = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: 24px;
`;

export const HeaderShadowedBoxContainer = styled.div`
  display: flex;
  box-shadow: ${BOX_SHADOW};
  height: 56px;
  background: white;
`;

export const HeaderButton = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  padding: 0 20px;
  transition: all 0.25s;
  cursor: pointer;
  user-select: none;
  background: #ffffff;
  color: ${SEQUOIA_BLACK};
  &:hover {
    background: ${SEQUOIA_PASTEL_GRAY};
  }
`;

export const HeaderInput = styled.input`
  &::placeholder {
    color: ${SEQUOIA_LIGHT_TEXT};
  }
  color: ${SEQUOIA_BLACK};
  outline: none;
  width: 100%;
  border: none;
  font-size: 16px;
  line-height: 50px;
`;

export const MessageRowContainer = styled.div<{ size?: 'small' | 'medium'; color?: string }>`
  display: flex;
  align-items: center;
  background-color: ${({ color = BLACK1ALPHA }) => color};
  height: fit-content;
  color: ${SEQUOIA_BLACK};

  ${({ size = 'medium' }) =>
    size === 'small'
      ? 'font-size: 14px; padding: 12px 16px;'
      : 'font-size: 16px; padding: 16px 24px;'}
`;

export const FooterRowContainer = styled.div`
  position: fixed;
  bottom: 0px;
  left: ${SIDEBAR_WIDTH + 24}px;
  height: ${FOOTER_HEIGHT}px;
  width: calc(100% - ${SIDEBAR_WIDTH + 24 * 2}px);
  padding: 36px 0;
  display: flex;
  align-items: center;
  gap: 10px;
`;

/* Tooltip */

export const LightTooltip: React.FunctionComponent<
  | (TooltipPropsWithOverlay & React.RefAttributes<unknown>)
  | (TooltipPropsWithTitle & React.RefAttributes<unknown>)
> = props => {
  return (
    <Tooltip
      color={'white'}
      {...props}
      overlayStyle={{ display: props.overlay ? undefined : 'none', ...props.overlayStyle }}
      overlay={<TooltipOverlay>{props.overlay}</TooltipOverlay>}
    >
      {props.children}
    </Tooltip>
  );
};

const TooltipOverlay = styled.div`
  color: ${BLACK7ALPHA};
  font-size: 12px;
`;

/* Misc */

interface Icon {
  src: string;
  alt: string;
}

export const CountWithIcon: React.FC<{
  count: string | number;
  icon: Icon;
  style?: CSSProperties;
}> = ({ count, icon, style }) => {
  return (
    <div
      style={{
        fontWeight: 500,
        display: 'flex',
        gap: 5,
        opacity: 0.6,
        alignItems: 'baseline',
        ...style,
      }}
    >
      {count}
      <img src={icon.src} alt={icon.alt} style={{ height: 11, width: 11 }} />
    </div>
  );
};

export const Empty: React.FC<EmptyProps> = ({ imgProps, description, style }) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      width: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 100,
      margin: 'auto',
      gap: 16,
      ...style,
    }}
  >
    <img
      src="/images/tree-07.svg"
      alt="tree"
      {...imgProps}
      style={{
        objectFit: 'contain',
        maxWidth: '45%',
        maxHeight: '40vh',
        ...imgProps?.style,
      }}
    />
    <Body2Serif>{description}</Body2Serif>
  </div>
);

const loaderLineAnimation = keyframes`
  0% { left: -40% }
  50% { left: 20%; width: 80% }
  100% { left: 100%; width: 100% }
`;

export const LoaderLine = styled.div`
  width: 100%;
  height: 3px;
  margin-top: -3px;
  z-index: 1;
  position: relative;
  overflow: hidden;
  background-color: ${hexWithOpacity(SEQUOIA_LIGHT_TEXT, 0.15)};

  &:before {
    content: '';
    position: absolute;
    left: -50%;
    height: 2px;
    width: 40%;
    background-color: ${SEQUOIA_GREEN};
    animation-name: ${loaderLineAnimation};
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
  }
`;

export const TagsContainer = styled.div`
  display: flex;
  gap: 6px;
`;

export const Tag = styled.div<{ selected?: boolean }>`
  display: flex;
  gap: 6px;
  align-items: center;
  background: ${({ selected = false }) => (selected ? BLACK5ALPHA : BLACK1ALPHA)};
  padding: 6px 8px;
  min-height: 24px;
  border-radius: 5px;
  white-space: nowrap;
  user-select: none;
  font-weight: 500;
  ${({ onClick }) =>
    !!onClick
      ? `cursor: pointer;
        &:hover {
          opacity: 0.5;
        }`
      : ''}
`;

export const HorizontalDivider = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${BLACK2ALPHA};
`;

export const VerticalDivider = styled.div`
  width: 1px;
  height: 100%;
  margin: 0 4px;
  border-right: 1px solid ${BLACK2ALPHA};
`;

export const DisplayOnHover = styled.div<{ disabled?: boolean }>`
  visibility: ${({ disabled = false }) => (disabled ? 'visible' : 'hidden')};
`;

export const HoverableDiv = styled.div`
  &:hover ${DisplayOnHover} {
    visibility: visible;
  }
`;

export const SourcingTable = styled(Table)`
  & .ant-table-tbody td {
    vertical-align: center;
  }
  & .ant-table-tbody > tr.ant-table-row-level-0:hover > td {
    &:hover ${DisplayOnHover} {
      display: initial;
    }
  }
  & .ant-table-tbody > tr.ant-table-row-level-0:hover:not(.hoverable-rows) > td {
    background: unset;
  }
  .ant-table-thead tr th.ant-table-column-has-sorters .ant-table-filter-column {
    margin-left: 0;
    margin-right: 0;
  }
  .ant-table-filter-column-title {
    padding: 16px 0px 16px 0px;
  }
  & td.ant-table-column-sort {
    background: unset;
  }
  th.ant-table-column-sort {
    background: ${SEQUOIA_LIGHT_GRAY};
  }
` as typeof Table;

export const SeqGhostCollapse = styled(Collapse)`
  & .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
  }
`;

export const SeqGhostPanel = styled(Panel)<{ active?: boolean }>`
  & .ant-collapse-header {
    ${({ active }) => active && `border-bottom: 1px solid ${SEQUOIA_BLACK};`}
    padding: 0;
    margin-left: -14px;
  }

  & .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
  }
`;

export const ListSubheader = styled(Label)`
  ${FlexFillingRowCSS}
  border-top: 1px solid ${SEQUOIA_BLACK};
  border-bottom: 1px solid ${SEQUOIA_BLACK};
  background: ${BLACK2ALPHA};
  padding: 12px;
`;
