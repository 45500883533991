import {
  DeleteOutlined,
  PauseCircleOutlined,
  PlayCircleOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import { notification, Popconfirm } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { groupBy } from 'lodash';
import moment from 'moment';
import React, { useContext, useEffect } from 'react';
import { OrganizationContext } from '../contexts/Organization';
import { isInternal, isStoppedBounce, isStoppedNoEmail } from '../Helpers';
import { ClickableItem, FadeInContainer } from '../shared/Common';
import { TALENT_WEB_APP } from '../shared/helpers';
import { makeRequest } from '../shared/Resource';
import { Colors } from '../shared/Theme';
import { IconLinkButton } from './Button';
import { LightTooltip } from './Common';
import { FastTable } from './FastTable';
import { EditEmailModalButton } from './modals/EditEmailModal';
import { Profile } from './Profile';
import { SocialLinks } from './profile-drawer/ProfileDrawer';
import { COHORT_DATE_FORMAT } from './selects/CohortSelector';
import { customSortableColumn } from './SeqTable';

const { LIGHT_GRAY4, SEQUOIA_BRIGHT_RED, SEQUOIA_LIGHT_TEXT, WARNING_TINT } = Colors.Static;

const DATE_FORMAT = 'MMM D';
// If changed, must update in external-profile model on backend as well.
const MANUAL_STOP_REASON = 'Stopped from dashboard.';

const consumeClick = (e?: React.MouseEvent) => {
  e?.stopPropagation();
};

export const CandidatesTable: React.FunctionComponent<{
  campaign: CampaignAPI.CampaignWithContents;
  onRefresh: () => void;
  candidates: CampaignAPI.CampaignMember[];
  onCandidateClicked: (candidate: CampaignAPI.CampaignMember) => void;
}> = ({ campaign, candidates, onRefresh, onCandidateClicked }) => {
  const { me } = useContext(OrganizationContext);

  const [campaignEmails, setCampaignEmails] = React.useState<
    { [candidateId: number]: CampaignAPI.BodylessCampaignEmail[] } | undefined
  >();

  const loadEmailsAsync = async (candidates: CampaignAPI.CampaignMember[]) => {
    if (!candidates.length) {
      setCampaignEmails({});
      return;
    }
    const bodylessEmails = await makeRequest<CampaignAPI.BodylessCampaignEmail[]>(
      '/api/sourcing/campaign-member/emails?omitBody=true',
      'POST',
      { forCandidates: candidates.map(c => c.id) }
    );
    const emailsByCandidate = groupBy(bodylessEmails, c => Number(c.campaignMemberId));
    const newCampaignEmails: { [candidateId: number]: CampaignAPI.BodylessCampaignEmail[] } = {};
    for (const candidate of candidates) {
      //Fill any with no emails so that table knows the emails arent still downloading
      newCampaignEmails[candidate.id] = emailsByCandidate[candidate.id]
        ? emailsByCandidate[candidate.id]
        : [];
    }

    setCampaignEmails(newCampaignEmails);
  };

  useEffect(() => {
    loadEmailsAsync(candidates);
  }, [candidates]);

  const columns: ColumnsType<CampaignAPI.CampaignMember> = [
    {
      key: 'Person',
      title: customSortableColumn<CampaignAPI.CampaignMember>('Person'),
      sorter: (r1: CampaignAPI.CampaignMember, r2: CampaignAPI.CampaignMember) => {
        return r1.profile.name.toLowerCase().localeCompare(r2.profile.name.toLowerCase());
      },
      render: (candidate: CampaignAPI.CampaignMember) => (
        <Profile profile={candidate.profile} size="small" />
      ),
    },
    {
      title: 'email',
      render: (candidate: CampaignAPI.CampaignMember) => <div>{candidate.email}</div>,
    },

    {
      title: 'links',
      render: (candidate: CampaignAPI.CampaignMember) => (
        <div style={{ display: 'flex', gap: 4, marginTop: 4 }}>
          {isInternal(me) && (
            <IconLinkButton
              link={`${TALENT_WEB_APP}/people/${candidate.profile.identifier}`}
              type="sequoia"
              size="small"
            />
          )}
          <SocialLinks identifier={candidate.profile.identifier} links={candidate.profile.links} />
        </div>
      ),
    },
    {
      title: 'status',
      render: (candidate: CampaignAPI.CampaignMember) =>
        campaignEmails ? (
          <CandidateStatus candidate={candidate} emailsMap={campaignEmails} />
        ) : (
          <div />
        ),
    },
    {
      title: customSortableColumn<CampaignAPI.CampaignMember>('Cohort'),
      key: 'Cohort',
      width: 150,
      defaultSortOrder: 'descend',
      sorter: (r1: CampaignAPI.CampaignMember, r2: CampaignAPI.CampaignMember) =>
        moment(r1.createdAt).diff(r2.createdAt, 'days'),
      render: (candidate: CampaignAPI.CampaignMember) => cohortStringFor(candidate),
    },
    {
      title: '',
      width: 150,
      render: (member: CampaignAPI.CampaignMember) => (
        <CampaignMemberActions
          member={member}
          campaignStopped={campaign.stopped}
          onRefresh={onRefresh}
        />
      ),
    },
  ];

  return (
    <FastTable
      rowProps={c => ({
        style: { cursor: 'pointer' },
        onClick: e => onCandidateClicked(c),
      })}
      rowKey={'id'}
      rowHeight={72}
      dataSource={candidates}
      columns={columns}
      emptyProps={{
        imgProps: { style: { maxHeight: '25vh' } },
        description: 'No candidates',
        style: { padding: 0, marginTop: '7vh' },
      }}
    />
  );
};

const NoIcon = () => <div style={{ display: 'inline-block', width: 22 }} />;

const CandidateStatus: React.FunctionComponent<{
  candidate: CampaignAPI.CampaignMember;
  emailsMap: { [candidateId: number]: CampaignAPI.BodylessCampaignEmail[] };
}> = ({ candidate, emailsMap }) => {
  if (candidate.stoppedReason) {
    return (
      <div>
        <NoIcon />
        Paused
      </div>
    );
  }

  const sentimentIcon = candidate.responseSentiment ? (
    <SentimentIcon sentiment={candidate.responseSentiment} />
  ) : (
    <NoIcon />
  );

  const emails = emailsMap[candidate.id];
  if (!emails || !emails.length) {
    return <div></div>;
  }
  const emailsSent = emails.filter(e => e.sentAt);

  if (emailsSent.length) {
    const mostRecentEmail = emailsSent.sort((e1, e2) =>
      moment(e2.sentAt!).diff(moment(e1.sentAt!))
    )[0];
    const mostRecentTime = moment(mostRecentEmail.sentAt!).format(DATE_FORMAT);

    if (mostRecentEmail.direction === 'outbound') {
      return (
        <FadeInContainer>
          {sentimentIcon}
          {emailsSent.length > 1
            ? `Followed up on ${mostRecentTime}`
            : `Emailed on ${mostRecentTime}`}
        </FadeInContainer>
      );
    } else {
      return (
        <FadeInContainer>
          {sentimentIcon || (
            <img
              src="/icons/reply.svg"
              alt="Reply arrow"
              style={{ width: 20, verticalAlign: 'middle', marginRight: 6, marginBottom: 4 }}
            />
          )}
          Replied on {mostRecentTime}
        </FadeInContainer>
      );
    }
  }

  const deliverAt = moment(emails[0].deliverAt!);
  const deliverAtSoon = moment(deliverAt).isBefore(moment().add(2, 'minutes'));

  return (
    <div>
      <NoIcon />
      Emailing {deliverAtSoon ? 'soon' : deliverAt.fromNow()}
    </div>
  );
};

export const SentimentIcon: React.FunctionComponent<{
  sentiment: 'Positive' | 'Negative';
}> = ({ sentiment }) => {
  return (
    <img
      alt={sentiment === 'Positive' ? 'Handshake icon' : 'Wall icon'}
      src={sentiment === 'Positive' ? '/icons/handshake.svg' : '/icons/wall.svg'}
      style={{ verticalAlign: 'middle', marginRight: 8, marginBottom: 4 }}
    />
  );
};

const CampaignMemberActions: React.FC<{
  member: CampaignAPI.CampaignMember;
  campaignStopped: boolean;
  onRefresh: () => void;
}> = ({ member, campaignStopped, onRefresh }) => {
  const onToggleStopped = async (e: React.MouseEvent) => {
    e.stopPropagation();
    const resp = await makeRequest<CampaignAPI.CampaignMember>(
      `/api/sourcing/campaign-member/${member.id}/stop`,
      'PUT',
      { stoppedReason: member.stoppedReason ? null : MANUAL_STOP_REASON }
    );

    if (resp.id) {
      notification.success({
        message: `Campaign ${resp.stoppedReason ? 'stopped' : 'resumed'} for ${
          member.profile.name
        }.`,
      });
      onRefresh();
    } else {
      notification.error({ message: 'Request failed, please try again.' });
    }
  };

  const onDelete = async (e?: React.MouseEvent<any>) => {
    e?.stopPropagation();
    const resp = await makeRequest<{ success: boolean }>(
      `/api/sourcing/campaign-member/${member.id}`,
      'DELETE'
    );
    if (resp.success) {
      notification.success({ message: `${member.profile.name} removed.` });
      onRefresh();
    }
  };

  const pauseDisabled =
    !!member.stoppedReason || !!campaignStopped || !!member.candidateRespondedAt;
  const resumeDisabled = campaignStopped || member.stoppedReason !== MANUAL_STOP_REASON;
  const hasWarning = !!member.stoppedReason && member.stoppedReason !== MANUAL_STOP_REASON;

  return (
    <div
      onClick={consumeClick}
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        gap: 8,
      }}
    >
      {!campaignStopped && (isStoppedNoEmail(member) || isStoppedBounce(member)) && (
        <EditEmailModalButton
          onClose={modifiedProfile => {
            !!modifiedProfile && onRefresh();
          }}
          profile={member.profile}
          bouncingAddress={member.email !== 'pending' ? member.email : undefined}
        />
      )}
      <div style={{ display: 'flex', gap: 12, flexWrap: 'wrap' }}>
        <ClickableItem disabled={resumeDisabled} onClick={onToggleStopped}>
          <PlayCircleOutlined
            style={{ color: resumeDisabled ? LIGHT_GRAY4 : SEQUOIA_LIGHT_TEXT, fontSize: 18 }}
          />
        </ClickableItem>
        <ClickableItem disabled={pauseDisabled} onClick={onToggleStopped}>
          <PauseCircleOutlined
            style={{ color: pauseDisabled ? LIGHT_GRAY4 : SEQUOIA_LIGHT_TEXT, fontSize: 18 }}
          />
        </ClickableItem>
        <LightTooltip
          placement={'left'}
          overlayStyle={{ maxWidth: 700, display: hasWarning ? 'default' : 'none' }}
          overlay={<div>{member.stoppedReason}</div>}
        >
          <ClickableItem>
            <WarningOutlined
              style={{ color: hasWarning ? WARNING_TINT : LIGHT_GRAY4, fontSize: 18 }}
            />
          </ClickableItem>
        </LightTooltip>
        <Popconfirm
          icon={<DeleteOutlined style={{ color: SEQUOIA_BRIGHT_RED }} />}
          placement="left"
          title={`Remove ${member.profile.name}?`}
          onCancel={consumeClick}
          onConfirm={onDelete}
          okText={'Delete'}
          okButtonProps={{ danger: true }}
        >
          <ClickableItem onClick={consumeClick}>
            <DeleteOutlined style={{ fontSize: 18, color: SEQUOIA_BRIGHT_RED }} />
          </ClickableItem>
        </Popconfirm>
      </div>
    </div>
  );
};

const cohortStringFor = (candidate: CampaignAPI.CampaignMember) => {
  return moment(candidate.createdAt).startOf('day').format(COHORT_DATE_FORMAT);
};
