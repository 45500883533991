import { useContext, useMemo } from 'react';
import { OrganizationContext } from '../contexts/Organization';

export type CampaignSummaryWithSender = CampaignAPI.CampaignSummary & {
  sender: ExternalAPI.UserWithCampaigns;
};

export function useVisibleCampaigns({
  includeArchived,
  includeActiveOrganizationOnly = true,
}: {
  includeArchived: boolean;
  includeActiveOrganizationOnly?: boolean;
}) {
  const sortByName = (c1: { name: string }, c2: { name: string }) => {
    return c1.name.toLowerCase().localeCompare(c2.name.toLowerCase());
  };
  const { me, team, id } = useContext(OrganizationContext);
  const campaigns: CampaignSummaryWithSender[] = useMemo(
    () =>
      [me, ...team]
        .reduce(
          (acc, sender) =>
            acc.concat(
              sender.campaigns
                .map(c => ({ ...c, sender }))
                .filter(c => (includeActiveOrganizationOnly ? c.organizationId === id : true))
                .filter(c => includeArchived || !c.archivedAt)
            ),
          [] as CampaignSummaryWithSender[]
        )
        .sort(sortByName),
    [me, team, id, includeArchived, includeActiveOrganizationOnly]
  );

  return campaigns;
}
