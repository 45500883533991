import { default as React } from 'react';
import { CSSProperties } from 'styled-components/macro';
import { Colors } from '../shared/Theme';

export const AndMoreSuffix: React.FC<{ amt: number; style?: CSSProperties }> = ({ amt, style }) => (
  <span style={{ color: Colors.Static.SEQUOIA_LIGHT_TEXT, fontWeight: 500, ...style }}>
    {' '}
    + {amt} more
  </span>
);
