import { Dropdown, Menu } from 'antd';
import { DropDownProps } from 'antd/lib/dropdown';
import { capitalize } from 'lodash';
import React from 'react';
import { SeqCheckbox } from '../components/SeqCheckbox';
import { Colors } from '../shared/Theme';

const { SEQUOIA_LIGHT_TEXT, SEQUOIA_DARK_GRAY, SEQUOIA_LIGHT_GRAY } = Colors.Static;

export const FEATURE_OPTIONS: { feature: ExternalAPI.Feature; optional: boolean }[] = [
  { feature: 'pipeline', optional: false },
  { feature: 'network', optional: false },
  { feature: 'campaigns', optional: false },
  { feature: 'resources', optional: false },
  { feature: 'referrals', optional: true },
  { feature: 'leads', optional: true },
];

export const FeatureDropdown: React.FunctionComponent<
  Omit<DropDownProps, 'overlay'> & {
    features: ExternalAPI.Feature[];
    onChange: (newFeatures: ExternalAPI.Feature[]) => void;
  }
> = props => {
  const { features, onChange } = props;

  const featuresUc = features.sort().map(f => f.split(' ').map(capitalize).join(' '));

  let displayText = '';
  switch (featuresUc.length) {
    case FEATURE_OPTIONS.length:
      displayText = 'All';
      break;
    case 0:
      displayText = 'None';
      break;
    case 1:
      displayText = `${featuresUc[0]} Only`;
      break;
    case 2:
      displayText = `${featuresUc[0]} and ${featuresUc[1]}`;
      break;
    default:
      displayText = featuresUc.join(', ');
  }

  const menu = (
    <Menu style={{ width: 150 }}>
      {FEATURE_OPTIONS.map(({ feature, optional }, idx) => {
        const onClick = () => {
          features.includes(feature)
            ? onChange(features.filter(f => f !== feature))
            : onChange([...features, feature]);
        };

        return (
          <Menu.Item
            key={`feature-item-${idx}`}
            style={{ borderBottom: `1px solid ${SEQUOIA_LIGHT_GRAY}`, opacity: optional ? 1 : 0.5 }}
            disabled={!optional}
            onClick={onClick}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                color: SEQUOIA_DARK_GRAY,
                padding: '6px 0',
              }}
            >
              <SeqCheckbox
                color={SEQUOIA_LIGHT_TEXT}
                onChange={onClick}
                checked={features.includes(feature)}
                onClick={e => e.stopPropagation()}
              />
              <div style={{ marginLeft: 4, textTransform: 'capitalize' }}>{feature}</div>
            </div>
          </Menu.Item>
        );
      })}
    </Menu>
  );

  return (
    <Dropdown {...props} placement={'bottomRight'} overlayStyle={{ maxWidth: 50 }} overlay={menu}>
      <div
        style={{
          color: SEQUOIA_LIGHT_TEXT,
          display: 'flex',
          alignItems: 'center',
          cursor: props.disabled ? 'default' : 'pointer',
        }}
      >
        <div style={{ flex: 1 }}>{displayText}</div>
        {!props.disabled && (
          <img style={{ marginLeft: 12 }} src={'/icons/arrow-down.svg'} alt="a down arrow" />
        )}
      </div>
    </Dropdown>
  );
};
