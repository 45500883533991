import { TreeSelect } from 'antd';
import { capitalize, intersection, without } from 'lodash';
import React, { useContext } from 'react';
import { useResource } from '../Resource';
import { buildTagTreeNodes, flattenTagsAndSubTags } from '../tags-helpers';
import {
  EditableExcludeRulesMap,
  EditableRuleIds,
  Explanatory,
  HeaderRow,
  Rule,
  RuleEditorContext,
  RuleEditorProps,
  ValidationInfo,
  invalid,
  onChangeWithValidation,
  useDescription,
  valid,
} from './RuleEditorCommon';

export const HasTagEditor: React.FunctionComponent<RuleEditorProps> = ({
  title,
  rule,
  onChange,
  style,
  ruleType,
}) => {
  const { tagsPath } = useContext(RuleEditorContext);

  const [tags] = useResource<ConfigJSON.PeopleTags>(tagsPath);

  const description = useDescription(
    { rule, ruleType },
    {
      include: 'Boost people who are tagged with any of the following:',
      exclude: `Exclude people who tagged with any of the following`,
      invert: `Only include people who are tagged with 1+ of these:`,
    }
  );

  return (
    <div style={style}>
      {title && <HeaderRow label={title} onChange={onChange} rule={rule} />}
      <Explanatory>{description}</Explanatory>
      <div style={{ display: 'flex', width: '100%' }}>
        <GroupTagSelect
          tagType={'industries'}
          tags={tags}
          allSelected={rule.parameters}
          onChange={(tagsSelected: string[]) => {
            onChangeWithValidation({ ...rule, parameters: tagsSelected }, onChange, validate);
          }}
        />
        <div style={{ width: 30 }} />
        <GroupTagSelect
          tagType={'roles'}
          tags={tags}
          allSelected={rule.parameters}
          onChange={(tagsSelected: string[]) => {
            onChangeWithValidation({ ...rule, parameters: tagsSelected }, onChange, validate);
          }}
        />
      </div>
    </div>
  );
};

const GroupTagSelect: React.FunctionComponent<{
  tagType: 'roles' | 'industries';
  allSelected: string[];
  tags?: ConfigJSON.PeopleTags;
  onChange: (newTags: string[]) => void;
}> = ({ tagType, allSelected, tags, onChange }) => {
  const selectedFromThisType = intersection(
    flattenTagsAndSubTags(tags?.[tagType] || {}),
    allSelected
  );
  const otherTags = without(allSelected, ...flattenTagsAndSubTags(tags?.[tagType] || {}));

  return (
    <div style={{ width: '100%' }}>
      <div>{tagType === 'roles' ? 'Functions' : capitalize(tagType)}</div>
      <TreeSelect
        style={{ width: '100%' }}
        multiple={true}
        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
        allowClear={true}
        value={selectedFromThisType}
        treeDefaultExpandAll={true}
        onChange={(strs: string[]) => {
          onChange([...otherTags, ...strs]);
        }}
      >
        {buildTagTreeNodes('US', tags?.[tagType])}
      </TreeSelect>
    </div>
  );
};

const validate = (rule: Rule): ValidationInfo => {
  const ruleLabel = EditableExcludeRulesMap[rule.id as EditableRuleIds]?.label || '';
  if (!rule.parameters || rule.parameters.filter(Boolean).length < 1) {
    return invalid(`${ruleLabel} requires at least 1 tag.`);
  }
  return valid();
};
