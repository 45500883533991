import React, { createContext } from 'react';
import { addToDict } from '../shared/helpers';
import { makeRequest, useResource } from '../shared/Resource';

export interface ProfileNotifs {
  mentions: ExternalAPI.Notification<ExternalAPI.NoteMentionNotification>[];
  reminder?: {
    notification: ExternalAPI.Notification<ExternalAPI.ReminderNotification>;
    isActive: boolean;
  };
}

type NotificationContextType = {
  notifications: ExternalAPI.NotificationRes;
  actions: {
    refresh: () => Promise<ExternalAPI.NotificationRes>;
    markSeen: (n: ExternalAPI.Notification<ExternalAPI.NotificationData>) => Promise<void>;
    createReminder: (reminder: ExternalAPI.ReminderNotification) => Promise<void>;
    deleteReminder: (
      n: ExternalAPI.Notification<ExternalAPI.ReminderNotification>
    ) => Promise<void>;
    getNotifsForProfile: (profileId: number) => ProfileNotifs;
  };
};

export const NotificationContext = createContext<NotificationContextType>(
  new Error(
    'No notification context data available, outside of scope?'
  ) as any as NotificationContextType
);

const emptyNotifications: ExternalAPI.NotificationRes = {
  profiles: {},
  features: {
    leads: [],
    referrals: [],
    campaigns: [],
    network: [],
    resources: [],
    pipeline: [],
  },
};

export const NotificationsProvider: React.FunctionComponent = ({ children }) => {
  const [notifications = emptyNotifications, { refresh, applyLocalUpdates }] =
    useResource<ExternalAPI.NotificationRes>('/api/notifications');

  const getNotifsForProfile = React.useCallback(
    (profileId: number) =>
      (notifications.profiles[profileId] || []).reduce(
        (acc, n) => {
          if ('remindAt' in n.data) {
            acc.reminder = {
              notification: n as ExternalAPI.Notification<ExternalAPI.ReminderNotification>,
              isActive: !!n.sentAt && !n.seenAt,
            };
          } else
            acc.mentions.push(n as ExternalAPI.Notification<ExternalAPI.NoteMentionNotification>);
          return acc;
        },
        { reminder: undefined, mentions: [] } as ProfileNotifs
      ),
    [notifications.profiles]
  );

  const markSeen = React.useCallback(
    async (notif: ExternalAPI.Notification<ExternalAPI.NotificationData>) => {
      const { id } = notif;
      await makeRequest(`/api/notifications/${id}`, 'PUT');
      const updatedNotifs = { ...notifications };

      if ('feature' in notif.data) {
        const { feature } = notif.data;
        updatedNotifs.features[feature] = notifications.features[feature].filter(n => n.id !== id);
      } else {
        const profileId = notif.data.profile.id;
        updatedNotifs.profiles[profileId] = notifications.profiles[profileId].filter(
          n => n.id !== id
        );
      }

      setTimeout(() => applyLocalUpdates(updatedNotifs), 500);
    },
    [notifications, applyLocalUpdates]
  );

  const createReminder = React.useCallback(
    async (reminder: ExternalAPI.ReminderNotification) => {
      const notif = await makeRequest<ExternalAPI.Notification<ExternalAPI.ReminderNotification>>(
        `/api/notifications`,
        'POST',
        reminder
      );

      const updatedNotifs = {
        features: { ...notifications.features },
        profiles: { ...notifications.profiles },
      };
      addToDict(notif, notif.data.profile.id, updatedNotifs.profiles);
      applyLocalUpdates(updatedNotifs);
    },
    [applyLocalUpdates, notifications]
  );

  const deleteReminder = React.useCallback(
    async (notification: ExternalAPI.Notification<ExternalAPI.ReminderNotification>) => {
      const {
        id,
        data: {
          profile: { id: profileId },
        },
      } = notification;
      await makeRequest(`/api/notifications/${id}`, 'DELETE');
      const updatedNotifs = { ...notifications };
      updatedNotifs.profiles[profileId] = notifications.profiles[profileId].filter(
        n => n.id !== id
      );
      applyLocalUpdates(updatedNotifs);
    },
    [applyLocalUpdates, notifications]
  );

  return (
    <NotificationContext.Provider
      value={{
        notifications: { ...notifications },
        actions: { refresh, markSeen, createReminder, deleteReminder, getNotifsForProfile },
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};
