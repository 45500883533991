import React from 'react';
import { OrganizationContext } from '../contexts/Organization';
import { Select, SelectDivider, SelectOption } from './selects/Select';

export const CampaignSenderInput: React.FC<{
  disabled: boolean;
  campaign: CampaignAPI.Campaign;
  onChange?: (info: CampaignAPI.Campaign) => void;
}> = ({ disabled, campaign, onChange }) => {
  const { me, team, id } = React.useContext(OrganizationContext);
  const orgSenders = [me, ...team.filter(t => t.organizationId === id)];

  const senderOptions = orgSenders.reduce((acc, s) => {
    acc.push({ id: `${s.id}//${s.email}`, name: `${s.name} <${s.email}>` });
    if (s.settings.gmailAliases?.length) {
      acc = acc.concat(
        s.settings.gmailAliases.map(a => ({
          id: `${s.id}//${a}`,
          name: `${s.name} <${a}>`,
          extra: 'Alias',
        }))
      );
    }
    acc.push(SelectDivider);
    return acc;
  }, [] as SelectOption<string>[]);

  const sender = campaign.sender || orgSenders.find(s => s.id === campaign.senderId);

  return (
    <div style={{ flex: 1 }}>
      <Select
        placeholder="--"
        disabled={disabled}
        selected={
          campaign
            ? `${campaign.senderId}//${campaign.settings.sendAs || sender?.email}`
            : undefined
        }
        options={senderOptions}
        style={{ marginLeft: 12, height: 36, background: 'white', opacity: disabled ? 0.6 : 1 }}
        type="secondary"
        onSelect={id => {
          if (typeof id === 'string' && onChange) {
            const [senderId, email] = id.split('//');
            const update = {
              ...campaign,
              senderId: Number(senderId),
              settings: { ...campaign.settings, sendAs: email },
            };
            onChange(update);
          }
        }}
      />
      {sender?.allowOthersToSend === false && sender.id !== me.id ? (
        <div style={{ margin: 12, marginBottom: 0, maxWidth: '100%', opacity: 0.7, fontSize: 12 }}>
          {sender.name} does not allow others to send emails on their behalf through the Founder
          Tools. Only they can add or review candidates in this campaign.
        </div>
      ) : disabled ? (
        <div style={{ margin: 12, marginBottom: 0, maxWidth: '100%', opacity: 0.7, fontSize: 12 }}>
          The sender of the campaign cannot be modified after it has started.
        </div>
      ) : null}
    </div>
  );
};
