import { EditFilled, SettingOutlined } from '@ant-design/icons';
import { Button, Checkbox, Input, Row, Select, Tooltip } from 'antd';
import { isEqual } from 'lodash';
import React from 'react';
import styled from 'styled-components/macro';
import { Colors } from '../shared/Theme';

export const REMOTE_POLICY_TYPES = [
  'In person',
  'Hybrid',
  'Remote optional',
  'Remote first',
] as CompanyAPI.RemotePolicyType[];

export const remotePolicySortValue = (policy: CompanyAPI.RemotePolicy) => {
  return `${
    REMOTE_POLICY_TYPES.indexOf(policy.type) +
    (policy.type === 'Hybrid' ? policy.daysInOffice * -0.1 : 0)
  }`;
};

export const RemotePolicySummary: React.FC<{
  position?: CompanyAPI.RemotePolicy | null;
  company: CompanyAPI.RemotePolicy;
}> = ({ position, company }) => {
  const policy = position || company;
  const overridden = position && !isEqual(position, company);

  return (
    <>
      {'daysInOffice' in policy ? (
        <>
          {policy.type} <span style={{ opacity: 0.6 }}>({policy.daysInOffice}d in office)</span>
        </>
      ) : 'continents' in policy && policy.continents.length ? (
        <>
          {policy.type} <span style={{ opacity: 0.6 }}>({policy.continents.join(', ')})</span>
        </>
      ) : !policy.asOf ? (
        <></>
      ) : (
        <>{policy.type}</>
      )}
      {overridden && (
        <Tooltip
          overlay={<span>Overrides the company default - {remotePolicyToString(company)}</span>}
        >
          <SettingOutlined style={{ marginLeft: 4, opacity: 0.6, marginTop: 4 }} />
        </Tooltip>
      )}
    </>
  );
};

export const remotePolicyToString = (policy: CompanyAPI.RemotePolicy) =>
  'daysInOffice' in policy
    ? `${policy.type} (${policy.daysInOffice}d in office)`
    : 'continents' in policy && policy.continents.length
    ? `${policy.type} (${policy.continents.join(', ')})`
    : !policy.asOf
    ? ''
    : policy.type;

export const RemotePolicyEditor: React.FC<{
  company: {
    locations: LocationAPI.Location[];
    remotePolicy: CompanyAPI.RemotePolicy;
  };
  remotePolicy: CompanyAPI.RemotePolicy | null;
  onChange: (remotePolicy: CompanyAPI.RemotePolicy) => void;
  allowUnverified?: boolean;
}> = ({ remotePolicy: policy, onChange, company, allowUnverified }) => {
  return (
    <>
      {policy === null ? (
        <Row>
          <Column>
            <div>
              Company Default - <RemotePolicySummary company={company.remotePolicy} />
              <Button
                size={'small'}
                type={'link'}
                style={{ color: Colors.Static.SEQUOIA_LIGHT_TEXT }}
                onClick={() => onChange({ ...company.remotePolicy })}
              >
                <EditFilled />
              </Button>
            </div>
          </Column>
        </Row>
      ) : (
        <Row style={{ alignItems: 'flex-start' }}>
          <Column style={{ padding: 0 }}>
            <div style={{ display: 'flex', gap: 6, alignItems: 'center' }}>
              <Select
                style={{ minWidth: '33%' }}
                value={
                  policy.type === 'In person' && policy.asOf === 0 ? 'Unverified' : policy.type
                }
                optionLabelProp={'label'}
                onChange={(type: CompanyAPI.RemotePolicyType | 'Unverified') => {
                  const continentsBasedOnOffices = [
                    company.locations.some(l => l.continent === 'North America') && 'US',
                    company.locations.some(l => l.continent === 'Europe') && 'Europe',
                  ].filter((c): c is CompanyAPI.Continent => !!c);

                  const continents =
                    'continents' in policy && policy.continents.length
                      ? policy.continents
                      : continentsBasedOnOffices;

                  onChange(
                    type === 'In person'
                      ? { type: 'In person', asOf: Date.now() }
                      : type === 'Unverified'
                      ? { type: 'In person', asOf: 0 }
                      : type === 'Hybrid'
                      ? { type: 'Hybrid', daysInOffice: 1, asOf: Date.now() }
                      : { type: type, continents, asOf: Date.now() }
                  );
                }}
              >
                {(allowUnverified
                  ? [...REMOTE_POLICY_TYPES, 'Unverified']
                  : REMOTE_POLICY_TYPES
                ).map((type, idx) => (
                  <Select.Option key={idx} value={type}>
                    {type}
                  </Select.Option>
                ))}
              </Select>
              {policy.type === 'Hybrid' && (
                <Input
                  min={1}
                  max={5}
                  style={{ width: 210 }}
                  value={policy.daysInOffice || 0}
                  onChange={e => onChange({ ...policy, daysInOffice: Number(e.target.value) })}
                  type="number"
                  suffix={<span style={{ opacity: 0.6 }}>days/week in office</span>}
                />
              )}

              {'continents' in policy && (
                <>
                  <Checkbox
                    style={{ marginLeft: 12 }}
                    value={policy.continents.includes('US')}
                    checked={policy.continents.includes('US')}
                    onChange={e => {
                      const checked = e.target.checked;
                      onChange({
                        ...policy,
                        continents: checked
                          ? [...policy.continents, 'US']
                          : policy.continents.filter(c => c !== 'US'),
                      });
                    }}
                  >
                    US
                  </Checkbox>
                  <Checkbox
                    value={policy.continents.includes('Europe')}
                    checked={policy.continents.includes('Europe')}
                    onChange={e => {
                      const checked = e.target.checked;
                      onChange({
                        ...policy,
                        continents: checked
                          ? [...policy.continents, 'Europe']
                          : policy.continents.filter(c => c !== 'Europe'),
                      });
                    }}
                  >
                    Europe
                  </Checkbox>
                </>
              )}
            </div>
          </Column>
        </Row>
      )}
    </>
  );
};

const Column = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 3px;
  flex: 1;
  & .ant-input-number {
    width: 100%;
  }
`;
