import { CloseOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import { uniq } from 'lodash';
import React from 'react';
import { Colors } from '../Theme';
import {
  EditableExcludeRulesMap,
  EditableRuleIds,
  RuleEditorProps,
  ValidationInfo,
  invalid,
  onChangeWithValidation,
  useDescription,
  valid,
} from '../rule-editors/RuleEditorCommon';
import { EditorInput, Explanatory, HeaderRow, REGEX_EXPLANATION, Rule } from './RuleEditorCommon';

export const SummaryOrWorkDescriptionEditor: React.FC<RuleEditorProps> = ({
  rule,
  onChange,
  style,
  title,
  ruleType,
}) => {
  const replaceParam = (value: string | null, index: number) => {
    if (value === null) {
      onChangeWithValidation(
        { ...rule, parameters: rule.parameters.filter((_, idx) => idx !== index) },
        onChange,
        validate
      );
    } else if (value?.includes(',')) {
      const newValues = value
        .split(',')
        .map(v => v.trim())
        .filter(Boolean);
      const replaced = [...rule.parameters];
      replaced[index] = newValues[0];
      onChangeWithValidation(
        { ...rule, parameters: uniq([...replaced, ...newValues.slice(1)]) },
        onChange,
        validate
      );
    } else {
      const replaced = [...rule.parameters];
      replaced[index] = value;
      onChangeWithValidation({ ...rule, parameters: uniq(replaced) }, onChange, validate);
    }
  };

  const keywords = [...rule.parameters];
  if (keywords.every(word => !!word)) {
    keywords.push('');
  }

  const desc = useDescription(
    { rule, ruleType },
    {
      include:
        'Mentioning any of these things in a linkedin summary or work description will boost a profile within this model',
      exclude: 'Remove people who mention any of these things in their profile',
      invert: `In their LinkedIn bio or work history, what does your ideal person mention? Try to
  provide at least 7 keywords. Frameworks, software products, industry standards, conferences,
  and goal metrics are all valuable for finding the best people.`,
    }
  );

  return (
    <div style={style}>
      {title && <HeaderRow label={title} onChange={onChange} rule={rule} />}
      <Explanatory>{desc}</Explanatory>
      <div style={{ marginLeft: 15, marginTop: 20 }}>
        {keywords.map((keyword, index) => (
          <div key={`keyword-${index}`} style={{ display: 'flex', alignItems: 'center' }}>
            <EditorInput
              placeholder={'Enter a keyword'}
              style={{ width: 380, marginBottom: 10 }}
              value={keyword}
              onChange={e => replaceParam(e.currentTarget.value || null, index)}
            />
            {!!keyword && (
              <Button
                tabIndex={-1}
                style={{ color: Colors.Static.SEQUOIA_BRIGHT_RED }}
                onClick={() => replaceParam(null, index)}
                type={'link'}
              >
                <CloseOutlined style={{ marginLeft: 20 }} />
              </Button>
            )}
            {index === 0 && (
              <Tooltip overlayStyle={{ minWidth: 500 }} overlay={REGEX_EXPLANATION}>
                <QuestionCircleOutlined
                  style={{ color: Colors.Static.SEQUOIA_LIGHT_TEXT, marginLeft: 5 }}
                />
              </Tooltip>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

const validate = (rule: Rule): ValidationInfo => {
  const ruleLabel = EditableExcludeRulesMap[rule.id as EditableRuleIds]?.label || '';
  if (!rule.parameters || rule.parameters.filter(Boolean).length < 1) {
    return invalid(`${ruleLabel} requires at least one tag to be selected.`);
  }
  return valid();
};
