import { Input } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { InputProps as AntdInputProps, TextAreaProps } from 'antd/lib/input';
import { default as React, useEffect, useState } from 'react';
import styled, { css } from 'styled-components/macro';
import { BaseUnica, Body1CSS, Body2CSS, Body3CSS } from '../components/Text';
import { useDebouncedCall } from '../shared/hooks';
import { Colors, hexWithOpacity } from '../shared/Theme';
import { XButton } from './Button';
import { IconContainer, SearchIcon } from './Svgs';
import { Body3 } from './Text';

const {
  SEQUOIA_DARK_GREEN,
  SEQUOIA_DARK_GRAY,
  SEQUOIA_GREEN,
  SEQUOIA_BLACK,
  BLACK1ALPHA,
  BLACK3ALPHA,
  BLACK5ALPHA,
  WHITE,
  BLACK,
} = Colors.Static;

type Outline = 'rounded' | 'underline' | 'rect';

export type InputProps = AntdInputProps & {
  helperText?: React.ReactNode;
  outline?: Outline;
};

const UnderlineCSS = css`
  border-bottom: 1px solid ${BLACK1ALPHA};
  background: transparent;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;

  &:active,
  &:hover,
  &:focus {
    border-bottom: 1px solid ${SEQUOIA_DARK_GREEN};
  }
`;

const RectCSS = css`
  border: 1px solid ${BLACK3ALPHA};
  background: white;

  &:active,
  &:hover,
  &:focus {
    border: 1px solid ${SEQUOIA_DARK_GREEN};
  }
`;

const RoundedCSS = css`
  ${RectCSS}
  border-radius: 100px;
`;

const BaseInput = styled(Input)<{ outline: Outline }>`
  ${({ size }) => {
    if (size === 'small') return Body2CSS;
    return Body1CSS;
  }}

  flex: 1;
  border: none;
  outline: none;
  border-radius: 0;
  padding: 8px;
  width: 100%;

  &:active,
  &:hover,
  &:focus {
    outline: none;
    box-shadow: none;
    border: none;
  }
  &::placeholder {
    color: ${BLACK5ALPHA};
  }
  &:disabled {
    color: ${BLACK3ALPHA};
    background: none;
  }

  ${({ outline = 'underline' }) => {
    if (outline === 'underline') return UnderlineCSS;
    if (outline === 'rect') return RectCSS;
    return RoundedCSS;
  }}
`;

const StyledInputTitle = styled.label<{ size: SizeType; disabled?: boolean }>`
  ${({ size }) => {
    if (size === 'small') return Body3CSS;
    return Body2CSS;
  }}

  ${({ disabled = false }) => disabled && `color: ${BLACK3ALPHA};`}
`;

export const RequiredText = () => (
  <span style={{ color: BLACK5ALPHA, paddingLeft: 6 }}>(required)</span>
);

export const InputTitle: React.FC<{
  title: string;
  size: SizeType;
  required?: boolean;
  disabled?: boolean;
}> = ({ title, size, required, disabled }) => (
  <StyledInputTitle size={size} disabled={disabled}>
    {title}
    {required && <RequiredText />}
  </StyledInputTitle>
);

const InputContainer = styled.div<{
  size?: SizeType;
  outline?: Outline;
  focused: boolean;
}>`
  flex:1;
  
  ${({ outline }) => {
    if (outline === 'rounded') return `border-radius: 100px;`;
  }}

  > .ant-input-affix-wrapper {
    background: transparent;
    outline: none;
    box-shadow: none;
    padding: 8px 6px;

    ${({ focused, outline }) => {
      if (focused) {
        if (outline === 'underline') {
          return `border-bottom: 1px solid ${SEQUOIA_DARK_GREEN}; padding-left: 0; padding-right: 0;`;
        } else return `border: 1px solid ${SEQUOIA_DARK_GREEN};`;
      }
    }}

    > .ant-input-prefix {
      margin-right: 8px;
    }

    > .ant-input {
      padding: unset;
      background: unset;

      ${({ size }) => {
        if (size === 'small') return Body2CSS;
        return Body1CSS;
      }}
    }
`;

export const SeqInput: React.FC<InputProps> = ({
  title,
  required,
  helperText,
  outline = 'underline',
  style,
  ...rest
}) => {
  const [focused, setFocus] = useState<boolean>(false);

  const onFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    rest.onFocus?.(e);
    setFocus(true);
  };

  const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    rest.onFocus?.(e);
    setFocus(false);
  };

  return (
    <InputContainer outline={outline} focused={focused} size={rest.size} style={style}>
      {title && (
        <InputTitle size={rest.size} title={title} required={required} disabled={rest.disabled} />
      )}
      <BaseInput
        outline={outline}
        {...rest}
        style={title ? { marginTop: 6 } : {}}
        onFocus={onFocus}
        onBlur={onBlur}
      />
      {helperText && <Body3 style={{ color: BLACK5ALPHA, marginTop: 6 }}>{helperText}</Body3>}
    </InputContainer>
  );
};

export const SeqTextArea: React.FC<TextAreaProps & { size?: SizeType }> = ({
  title,
  required,
  size = 'middle',
  ...rest
}) => {
  return (
    <div>
      {title && <InputTitle size={size} title={title} required={required} />}
      <PlainTextArea size={size} {...rest} />
    </div>
  );
};

export const PlainInput = styled.input`
  font-size: 16px;
  line-height: 24px;
  width: 100%;
  padding-left: 0px;
  border: none;
  background: transparent;
  color: ${SEQUOIA_DARK_GRAY};
  border-bottom: thin solid ${SEQUOIA_BLACK};
  &:focus {
    outline: none;
  }
  &:active {
    border: none;
    border-bottom: thin solid ${SEQUOIA_BLACK};
    outline: none;
  }
  &::placeholder {
    color: ${hexWithOpacity(SEQUOIA_BLACK, 0.5)};
  }
  &:disabled {
    color: #909090;
    background: none;
  }
`;

export const StyledInput = styled(Input)`
  ${BaseUnica}
  &::placeholder {
    color: ${SEQUOIA_DARK_GRAY};
  }
  border: 1px solid ${hexWithOpacity(SEQUOIA_GREEN, 0.4)};
  &:focus {
    border: 1px solid ${SEQUOIA_GREEN};
  }
`;

export const DebouncedSearchInput: React.FC<
  Omit<InputProps, 'onChange'> & { onChange: (v: string) => void }
> = ({ value, onChange, ...rest }) => {
  const [searchInputVal, setSearchInputVal] = useState(value);
  const runSearch = useDebouncedCall(async (searchFor: string) => {
    onChange(searchFor);
  }, 200);

  useEffect(() => {
    setSearchInputVal(value);
  }, [value]);

  return (
    <SeqInput
      {...rest}
      value={searchInputVal}
      onChange={e => {
        setSearchInputVal(e.currentTarget.value);
        runSearch(e.currentTarget.value);
      }}
      prefix={
        <IconContainer style={rest.size === 'small' ? { paddingLeft: 2 } : {}}>
          <SearchIcon />
        </IconContainer>
      }
      suffix={
        <XButton
          style={{ visibility: searchInputVal ? 'visible' : 'hidden' }}
          onClick={() => onChange('')}
        />
      }
    />
  );
};

const { TextArea } = Input;

export const PlainTextArea = styled(TextArea)<{ size?: SizeType; outline?: 'rounded' | 'ghost' }>`
  max-height: 100px;
  overflow: hidden;
  flex: 1;
  outline: none;
  resize: none;
  width: 100%;
  color: ${BLACK};
  background: ${WHITE};
  border-radius: ${({ outline }) => (outline ? 8 : 0)}px;
  ${({ outline, theme }) =>
    outline === 'ghost'
      ? 'border: none; padding: 0 !important;'
      : `border: 1px solid ${BLACK1ALPHA}; padding: 12px; margin-top: 6px;`}

  ${({ size }) => size === 'small' && 'padding: 6px;'}

  &:placeholder-shown {
    border: ${({ outline, theme }) => (outline === 'ghost' ? 'none' : `1px solid ${BLACK1ALPHA}`)};
  }

  &:active,
  &:hover,
  &:focus {
    outline: none;
    box-shadow: none;
    ${({ outline }) =>
      outline === 'ghost' ? `border: none;` : `border: 1px solid ${SEQUOIA_DARK_GREEN}`};
  }

  &::placeholder {
    color: ${({ theme }) => BLACK5ALPHA};
  }

  &:disabled {
    color: #909090;
    background: none;
  }
`;
