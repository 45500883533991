import React from 'react';
import styled, { CSSProperties } from 'styled-components/macro';
import { Colors } from '../shared/Theme';

const { SEQUOIA_BLACK, SEQUOIA_PASTEL_GRAY } = Colors.Static;

type LabelRef = HTMLLabelElement;
type Props = React.InputHTMLAttributes<HTMLInputElement> & {
  containerStyle?: CSSProperties;
  checkboxStyle?: CSSProperties;
  onFocus?: () => void; //Override blur & focus: Expected is an InputElementEvent but ours will be LabelElement
  onBlur?: () => void;
};

/**
 * Maintains a native checkbox element to that attributes dont have to be defined in here, but hides that, and uses a div and some custom svg's instead
 * to display an entirely custom ux. Loosely based on design here https://medium.com/@colebemis/building-a-checkbox-component-with-react-and-styled-components-8d3aa1d826dd
 * @param props
 *
 * On click handles the click on the entire label instead of just the checkbox in case you want that
 */

export const SeqCheckbox = React.forwardRef<LabelRef, Props>((props, ref) => {
  const { checked } = props;

  const checkSize = props.checkboxStyle?.width ? Number(props.checkboxStyle.width) - 4 : 12;

  return (
    <CheckboxLabel
      ref={ref}
      tabIndex={props.tabIndex}
      style={props.containerStyle}
      onFocus={e => props.onFocus?.()}
      onBlur={e => props.onBlur?.()}
      onClick={e => e.stopPropagation()} //A click on the label (which is much wider than cb) should NOT be considered a trigger by default
    >
      <CheckboxContainer id={'cbContainer'}>
        <HiddenCheckbox checked={checked} {...{ ...props, children: null, type: 'checkbox' }} />
        <StyledCheckbox
          id={'visibleCheckbox'}
          style={{ ...(!!props.color ? { borderColor: props.color } : {}), ...props.checkboxStyle }}
          onClick={e => {
            e.stopPropagation();
          }}
          disabled={props.disabled}
        >
          <img
            width={checkSize}
            height={checkSize}
            style={{ display: checked ? '' : 'none', padding: 1 }}
            src="/icons/check.svg"
            alt="Checkmark"
          />
        </StyledCheckbox>
      </CheckboxContainer>
      {props.children}
    </CheckboxLabel>
  );
});

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  // Hide checkbox visually but remain accessible to screen readers.
  // Source: https://polished.js.org/docs/#hidevisually
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  display: none;
  visibility: hidden;
`;

const StyledCheckbox = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  border: 1px solid ${SEQUOIA_BLACK};
  border-radius: 2px;
  transition: all 150ms;
  margin-right: 8px;
  position: relative;
  background-color: ${SEQUOIA_PASTEL_GRAY};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  text-align: center;
`;

const CheckboxContainer = styled.div`
  height: 16px;
  width: 16px;
  margin-right: 8px;
`;

const CheckboxLabel = styled.label`
  border: none !important;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 12px;
  margin: -12px;
  line-height: 17px;
  user-select: none;
  &:focus-visible {
    outline: none;
  }
`;
