//Started as a shared component, but redesign ux is significantly re-styled to justify rebuilding
import { ArrowDownOutlined } from '@ant-design/icons';
import React, { CSSProperties } from 'react';
import { TemplateDelayConfigurationProps } from '../shared/TemplateDelayConfiguration';
import { Colors } from '../shared/Theme';
import { Select, SelectOption } from './selects/Select';

const { SEQUOIA_DARK_GRAY } = Colors.Static;

export const ExternalTemplateDelayConfiguration: React.FunctionComponent<
  TemplateDelayConfigurationProps
> = ({ delay, disabled, onSetDelay }) => {
  const maxDelay = delay.unit === 'days' ? 31 : 25;

  const SELECT_STYLE: CSSProperties = {
    marginRight: 25,
    minWidth: 125,
    textAlignLast: 'center',
    background: Colors.Static.SEQUOIA_PAPER,
    fontSize: 14,
  };

  const numberOptions: SelectOption[] = Array.from(Array(maxDelay).keys())
    .filter(Boolean)
    .map(k => ({
      id: k,
      name: `${k}`,
    }));

  const unitOptions: SelectOption[] = [
    { id: 0, name: 'days' },
    { id: 1, name: 'months' },
  ];

  return (
    <div style={{ alignSelf: 'center', padding: 15, display: 'flex', alignItems: 'center' }}>
      <ArrowDownOutlined
        style={{ fontSize: 24, display: 'block', marginRight: 25, color: SEQUOIA_DARK_GRAY }}
      />
      <Select
        placeholder={'N/A'}
        disabled={disabled}
        style={SELECT_STYLE}
        optionsContainerStyle={{ fontSize: 14 }}
        selected={delay.value}
        options={numberOptions}
        onSelect={val => {
          val && onSetDelay({ ...delay, value: val });
        }}
      />
      <div />
      <Select
        placeholder={'N/A'}
        disabled={disabled}
        optionsContainerStyle={{ fontSize: 14 }}
        style={SELECT_STYLE}
        selected={delay?.unit === 'days' ? 0 : delay?.unit === 'months' ? 1 : undefined}
        options={unitOptions}
        onSelect={val => {
          const unit = val === 0 ? 'days' : val === 1 ? 'months' : undefined;
          unit && onSetDelay({ ...delay, unit });
        }}
      />
    </div>
  );
};
