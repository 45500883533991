import { LoadingOutlined } from '@ant-design/icons';
import { Dropdown } from 'antd';
import { DropdownButtonProps } from 'antd/lib/dropdown';
import { CSSProperties, default as React } from 'react';
import styled, { css } from 'styled-components/macro';
import { ClickableItem, FadeInContainer } from '../shared/Common';
import { EXTERNAL_HOST } from '../shared/helpers';
import { toLinkedinURL } from '../shared/li-utils';
import { toUrl } from '../shared/profile-helpers';
import { PlusIcon } from '../shared/Svgs';
import { Colors } from '../shared/Theme';
import {
  DesignPortfolioIcon,
  DownloadIcon,
  GitHubIcon,
  IconContainer,
  LinkedInIcon,
  LinkIcon,
  SequoiaLogo,
  TwitterIcon,
} from './Svgs';
import { CondensedTextCSS, Label2CSS, LabelCSS } from './Text';

const {
  SEQUOIA_PASTEL_RED,
  SEQUOIA_BRIGHT_RED,
  SEQUOIA_DARK_RED,
  SEQUOIA_PASTEL_GRAY,
  SEQUOIA_DARK_GRAY,
  SEQUOIA_DARK_GREEN,
  SEQUOIA_GREEN,
  SEQUOIA_BLACK,
  BLACK1ALPHA,
  BLACK3ALPHA,
  BLACK5ALPHA,
  SEQUOIA_PAPER,
} = Colors.Static;

export type ButtonIntent = 'primary' | 'secondary' | 'inverted';

interface BaseButtonProps {
  intent?: ButtonIntent;
  size?: 'small';
  danger?: boolean;
}

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> &
  BaseButtonProps & { loading?: boolean; icon?: React.ReactNode };

export const FloatingButton = styled(ClickableItem)`
  background: ${SEQUOIA_PAPER};
  margin: 12px;
  align-self: center;
  border-radius: 4px;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 8px 16px rgba(140, 140, 140, 0.25);
`;

export const ActionButton = styled(ClickableItem)<{ color?: string; hoverColor?: string }>`
  ${Label2CSS}
  border: 1px solid ${SEQUOIA_BLACK};
  border-radius: 200px;
  padding: 4px 12px;
  color: ${({ color = SEQUOIA_BLACK }) => color};
  &:hover {
    opacity: 1;
    background: ${BLACK1ALPHA};
  }
`;

export const BaseButtonCSS = css`
  display: flex;
  gap: 8px;
  border-radius: 200px;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
  &:hover,
  &:focus {
    & > svg > path,
    & > a > svg > path {
      fill: white;
    }
  }
  &:disabled {
    pointer-events: none;
    & > svg > path,
    & > a > svg > path {
      fill: ${BLACK3ALPHA};
    }
  }
`;

export const SmallButtonCSS = css`
  ${BaseButtonCSS}
  ${Label2CSS}
          padding: 4px 16px;
`;

export const DefaultButtonCSS = css`
  ${BaseButtonCSS}
  ${LabelCSS}
          padding: 8px 16px;
`;

export const PrimaryButtonCSS = css`
  background: ${SEQUOIA_GREEN};
  color: ${SEQUOIA_PASTEL_GRAY};
  border: 1px solid ${SEQUOIA_GREEN};
  &:hover,
  &:focus {
    background: ${SEQUOIA_DARK_GREEN};
    border: 1px solid ${SEQUOIA_DARK_GREEN};
  }
  &:disabled {
    background: ${BLACK3ALPHA};
    border: 1px solid transparent;
    &:hover,
    &:focus {
      background: ${BLACK3ALPHA};
      border: 1px solid transparent;
    }
  }

  & > svg > path,
  & > a > svg > path,
  & > div > svg > path {
    fill: white;
  }
`;

export const SecondaryButtonCSS = css`
  background: transparent;
  color: ${SEQUOIA_BLACK};
  border: 1px solid ${SEQUOIA_BLACK};
  &:hover,
  &:focus {
    background: ${SEQUOIA_BLACK};
    color: ${SEQUOIA_PASTEL_GRAY};

    & > svg > path,
    & > a > svg > path,
    & > div > svg > path {
      fill: ${SEQUOIA_PASTEL_GRAY};
    }
    img {
      filter: invert(1);
    }
  }
  &:disabled {
    color: ${BLACK3ALPHA};
    border: 1px solid ${BLACK3ALPHA};
    & > svg > path,
    & > a > svg > path,
    & > div > svg > path {
      fill: ${BLACK3ALPHA};
    }
    &:hover,
    &:focus {
      background: transparent;
    }
  }
`;

export const InvertedButtonCSS = css`
  background: ${SEQUOIA_BLACK};
  color: ${SEQUOIA_PASTEL_GRAY};
  border: 1px solid ${SEQUOIA_BLACK};
  &:hover,
  &:focus {
    color: ${SEQUOIA_PASTEL_GRAY};
    background: ${SEQUOIA_DARK_GRAY};
    border: 1px solid ${SEQUOIA_DARK_GRAY};
  }
  &:disabled {
    background: ${BLACK3ALPHA};
    border: 1px solid transparent;
    &:hover,
    &:focus {
      background: ${BLACK3ALPHA};
      border: 1px solid transparent;
    }
  }

  & > svg > path {
    fill: white;
  }
  & > a > svg > path {
    fill: white;
  }
  & > div > svg > path {
    fill: white;
  }
`;

const DangerButtonCSS = css`
  ${PrimaryButtonCSS}
  background: ${SEQUOIA_BRIGHT_RED};
  border-color: ${SEQUOIA_BRIGHT_RED};
  &:hover,
  &:focus {
    background: ${SEQUOIA_DARK_RED};
    border-color: ${SEQUOIA_DARK_RED};
  }
`;

const BaseButton = styled.button<BaseButtonProps>`
  ${({ size }) => (size === 'small' ? SmallButtonCSS : DefaultButtonCSS)}
  ${({ intent }) => {
    if (intent === 'primary') return PrimaryButtonCSS;
    if (intent === 'inverted') return InvertedButtonCSS;
    return SecondaryButtonCSS;
  }}
  ${({ danger }) => danger && DangerButtonCSS}
`;

export const SeqButton = (props: ButtonProps) => {
  const { disabled, icon, loading, children, ...rest } = props;
  return (
    <BaseButton disabled={loading || disabled} {...rest}>
      {loading ? (
        <LoadingOutlined spin style={{ marginLeft: -6 }} />
      ) : icon ? (
        <IconContainer
          style={
            props.size === 'small'
              ? { width: 16, marginLeft: -4 }
              : { width: 20, marginLeft: -2, marginTop: -2 }
          }
        >
          {icon}
        </IconContainer>
      ) : null}
      {children}
    </BaseButton>
  );
};

export const IconButton = styled.button<BaseButtonProps>`
  cursor: pointer;

  ${({ intent }) => {
    if (intent === 'primary') return PrimaryButtonCSS;
    if (intent === 'inverted') return InvertedButtonCSS;
    return SecondaryButtonCSS;
  }}

  ${({ size }) =>
    size === 'small'
      ? 'width: 26px; height: 26px; padding: 3px;'
      : 'width: 40px; height: 40px; padding: 7px; border-radius: 100%;'}

  border: none;
  &:disabled {
    border: none;
    opacity: 0.4;
  }

  & svg > path {
    fill: ${({ intent }) => (intent === 'primary' ? 'white' : BLACK5ALPHA)};
  }
`;

interface BaseIconLinkProps {
  size?: 'small';
  link?: string;
}

type IconLinkProps = BaseIconLinkProps &
  (
    | { type: 'twitter' | 'github' | 'linkedIn' | 'designPortfolio' | 'download' | 'sequoia' }
    | { icon?: React.ReactElement }
  );

export const IconLinkButton: React.FC<IconLinkProps> = props => {
  const { size, link } = props;

  let icon = 'icon' in props ? props.icon : <LinkIcon />;
  let url = link;
  if ('type' in props) {
    const { type } = props;

    switch (type) {
      case 'designPortfolio':
        icon = <DesignPortfolioIcon />;
        break;
      case 'twitter':
        icon = <TwitterIcon />;
        if (link) url = toUrl(type, link);
        break;
      case 'github':
        icon = <GitHubIcon />;
        if (link) url = toUrl(type, link);
        break;
      case 'linkedIn':
        icon = <LinkedInIcon />;
        if (link) url = toLinkedinURL(link);
        break;
      case 'download':
        icon = <DownloadIcon />;
        break;
      case 'sequoia':
        icon = <SequoiaLogo />;
    }
  }

  return (
    <IconButton size={size} disabled={!link}>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={url}
        style={{ pointerEvents: link ? 'unset' : 'none' }}
        onClick={e => e.stopPropagation()}
      >
        {icon}
      </a>
    </IconButton>
  );
};

export const SeqDropdownButton: React.FC<DropdownButtonProps & { danger?: boolean }> = ({
  disabled,
  ...rest
}) => (
  <div style={disabled ? { pointerEvents: 'none', opacity: 0.4 } : {}}>
    <StyledDropdownButton type="primary" {...rest} />
  </div>
);

const StyledDropdownButton = styled(Dropdown.Button)<{ danger?: boolean }>`
  > .ant-btn-primary {
    ${({ size }) => (size === 'small' ? SmallButtonCSS : DefaultButtonCSS)}
    ${({ danger }) => (danger ? DangerButtonCSS : PrimaryButtonCSS)}
      border-radius: unset;
    ${({ size }) => (size === 'small' ? '' : `height: 38px;`)}
  }

  > .ant-btn:first-child:not(:last-child) {
    border-top-left-radius: 200px;
    border-bottom-left-radius: 200px;
    ${({ danger }) => danger && `border-right: 1px solid ${SEQUOIA_PASTEL_RED};`}
  }

  > .ant-btn:last-child:not(:first-child) {
    border-top-right-radius: 200px;
    border-bottom-right-radius: 200px;
    padding-left: 6px;
    ${({ danger }) => danger && `border-left: 1px solid ${SEQUOIA_PASTEL_RED};`}
  }
`;

export const ToggleButton: React.FC<{
  selected: string;
  options: string[];
  onClick: () => void;
  size?: 'small';
}> = ({ onClick, selected, options, size }) => {
  return (
    <ToggleContainer
      onClick={onClick}
      style={{ display: 'flex', borderRadius: 200, border: `1px solid ${SEQUOIA_BLACK}` }}
    >
      {options.map(o => (
        <StyledToggleButton size={size} selected={o === selected}>
          {o}
        </StyledToggleButton>
      ))}
    </ToggleContainer>
  );
};

const ToggleContainer = styled.div`
  display: flex;
  border-radius: 200px;
  border: 1px solid ${SEQUOIA_BLACK};
`;

const StyledToggleButton = styled.button<{ selected: boolean; size?: 'small' }>`
  ${({ size }) => (size === 'small' ? SmallButtonCSS : DefaultButtonCSS)}
  ${({ selected }) => (selected ? InvertedButtonCSS : SecondaryButtonCSS)}
  ${({ selected }) =>
    selected
      ? 'pointer-events: none;'
      : `border: none; &:hover {background: none; color: ${SEQUOIA_BLACK}; opacity: 0.3;} `}
  &:focus {
    background: ${SEQUOIA_BLACK};
    border: 1px solid ${SEQUOIA_BLACK};
  }
`;

export const DownloadButton: React.FC<{
  onDownload: () => void;
  file: string;
  downloading: boolean;
  intent?: ButtonIntent;
  size?: 'small';
}> = ({ onDownload, file, downloading, intent = 'primary', size }) => {
  return (
    <a
      href={`${EXTERNAL_HOST}/${file}`}
      style={{ color: intent === 'primary' ? 'white' : '#333' }}
      download
    >
      <SeqButton
        intent={intent}
        onClick={onDownload}
        disabled={downloading}
        loading={downloading}
        icon={<DownloadIcon />}
        size={size}
      >
        Download
      </SeqButton>
    </a>
  );
};

export const InfoButton = styled(ClickableItem)`
  border: 1px solid ${BLACK1ALPHA};
  padding: 6px 8px 6px 6px;
  font-size: 12px;
  border-radius: 5px;
  font-weight: 500;
  gap: 8px;
  max-width: fit-content;
  ${CondensedTextCSS}
`;

export const PlusButton: React.FC<
  BaseButtonProps & { onClick?: () => void; style?: CSSProperties }
> = props => (
  <IconButton intent="primary" {...props} style={{ display: 'flex', padding: 0, ...props.style }}>
    <PlusIcon />
  </IconButton>
);

export const XButton: React.FC<{
  onClick?: () => void;
  style?: React.CSSProperties;
}> = ({ onClick, style }) => (
  <FadeInContainer>
    <PlusButton
      intent="inverted"
      onClick={onClick}
      style={{ transform: 'rotate(45deg)', width: 20, height: 20, ...style }}
    />
  </FadeInContainer>
);
