import { CopyOutlined, MinusOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons';
import { Empty, notification } from 'antd';
import React, { useContext, useEffect } from 'react';
import styled from 'styled-components/macro';
import { v4 as uuidv4 } from 'uuid';
import { SeqButton } from '../components/Button';
import { Column, HorizontalDivider } from '../components/Common';
import { TemplateArea } from '../components/TemplateArea';
import { Body3 } from '../components/Text';
import { OrganizationContext } from '../contexts/Organization';
import { errorForCampaign } from '../Helpers';
import { useVisibleCampaigns } from '../hooks/useVisibleCampaigns';
import { EditCampaignForm } from '../pages/EditCampaignForm';
import { EXTERNAL_RECIPIENT_VARIABLES } from '../shared/helpers';
import { makeRequest } from '../shared/Resource';
import { ScrollingContainer } from '../shared/ScrollableContainer';
import { StyledList, StyledListItem } from '../shared/SharedEditors';
import { Colors } from '../shared/Theme';
import { useQueryPersistedState } from '../shared/useQueryPersistedState';
import { PromptOnDirtyForm } from './PromptOnDirtyForm';

const UNSAVED_ID = -1;

const { SEQUOIA_PAPER, SEQUOIA_BLACK, BLACK5ALPHA } = Colors.Static;

export const CampaignEditor: React.FunctionComponent<{
  height: number | string;
  isNew: boolean;
  roleId: string | null;
}> = ({ height, isNew, roleId }) => {
  const org = useContext(OrganizationContext);
  const { me, actions } = org;
  const campaigns = useVisibleCampaigns({ includeArchived: false });

  const [updating, setUpdating] = React.useState<boolean>(false);
  const [unsaved, setUnsaved] = React.useState<CampaignAPI.Campaign | null>(null);
  const [selectedId, setSelectedId] = useQueryPersistedState<number | undefined>({
    encode: id => ({ id }),
    decode: qs => Number(qs.id) || 0,
  });

  const selected = unsaved || campaigns.find(c => c.id === selectedId);
  useEffect(() => {
    if (!selectedId) {
      return;
    }
    setUnsaved(null);
  }, [selectedId]);

  useEffect(() => {
    if (isNew) {
      onAddCampaign(
        buildDefaultCampaign(org, campaigns.length, roleId ? Number(roleId) : undefined)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSaveSelected = async () => {
    if (updating || !selected) {
      return;
    }

    const nameCollision = campaigns
      .filter(c => c.id !== selected.id)
      .some(c => c.name.trim().toLowerCase() === selected.name.trim().toLowerCase());
    if (nameCollision) {
      notification.warning({ message: `Campaigns must each have a unique name` });
      return;
    }

    const error = errorForCampaign(
      selected,
      Object.assign({}, EXTERNAL_RECIPIENT_VARIABLES, me.templateVariables)
    );

    if (error) {
      notification.warning({ message: error });
      return;
    }

    setUpdating(true);

    const response = await makeRequest<{ id: number }>(
      `/api/sourcing/campaign/${selected.id < 0 ? 'new' : selected.id}`,
      'PUT',
      selected
    );

    await actions.refresh();

    if (!response.id) {
      notification.warning({ message: `${selected.name} could not be saved. Please try again.` });
      return;
    }

    notification.success({ message: 'Campaign Saved' });
    setUnsaved(null);
    setSelectedId(response.id);
    setUpdating(false);
  };

  const onAddCampaign = (campaign: CampaignAPI.Campaign) => {
    setSelectedId(undefined);
    setUnsaved({ ...campaign, id: UNSAVED_ID });
  };

  const onArchiveSelected = async () => {
    if (!selected) {
      return;
    }
    if (selected.id === UNSAVED_ID) {
      setUnsaved(null);
      return;
    }
    const response = await makeRequest<{ id: number }>(
      `/api/sourcing/campaign/${selected.id}/archive`,
      'PUT',
      {}
    );
    if (!response.id) {
      notification.warning({ message: `Could not find campaign to remove, please try again.` });
      setUpdating(false);
    }
    actions.refresh();
    setUnsaved(null);
  };

  const onDuplicateSelected = () => {
    if (!selected) {
      return;
    }
    onAddCampaign({
      ...selected,
      id: UNSAVED_ID,
      name: `${selected.name} Copy`,
      templates: selected.templates.map(t => ({ ...t, id: uuidv4() })),
    });
  };

  const onUpdateSelected = (updates: Partial<CampaignAPI.Campaign>) => {
    if (!selected) return;
    setUnsaved({ ...selected, ...updates });
  };

  return (
    <PromptOnDirtyForm dirty={!!unsaved}>
      <div style={{ height }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', height: '100%' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              borderRight: `1px solid ${SEQUOIA_BLACK}`,
            }}
          >
            <StyledList style={{ flex: 1, width: 250, background: SEQUOIA_PAPER }} size="small">
              {campaigns
                .sort((c1, c2) => {
                  if (c1.senderId === me.id && c2.senderId !== me.id) {
                    return -1;
                  } else if (c2.senderId === me.id && c1.senderId !== me.id) {
                    return 1;
                  }
                  return c1.name.toLowerCase().localeCompare(c2.name.toLowerCase());
                })
                .map(c => (
                  <React.Fragment key={c.id}>
                    <SeqStyledListItem
                      style={{ height: 55, maxWidth: 250, overflow: 'ellipsis', paddingLeft: 24 }}
                      selected={c.id === selectedId}
                      onClick={() => setSelectedId(c.id)}
                    >
                      {c.name}
                      <Body3 style={{ color: BLACK5ALPHA }}>{c.sender.email}</Body3>
                    </SeqStyledListItem>
                    <HorizontalDivider />
                  </React.Fragment>
                ))}
            </StyledList>
            <div style={{ display: 'flex', marginTop: 16 }}>
              <SeqButton
                disabled={!!unsaved}
                onClick={() => {
                  onAddCampaign(buildDefaultCampaign(org, campaigns.length));
                }}
              >
                <PlusOutlined /> Add
              </SeqButton>
            </div>
          </div>
          {selected ? (
            <Column style={{ margin: '24px 0 0 24px' }}>
              <ScrollingContainer>
                <div style={{ minWidth: 325 }}>
                  <EditCampaignForm
                    key={selected.id}
                    campaign={selected}
                    onChange={updates => onUpdateSelected(updates)}
                  />
                  <TemplateArea
                    campaign={selected}
                    onChange={updates => onUpdateSelected(updates)}
                    sender={selected.sender || me}
                  />
                </div>
              </ScrollingContainer>
              <div style={{ display: 'flex', gap: 8, justifyContent: 'flex-end', marginTop: 24 }}>
                <SeqButton onClick={onArchiveSelected} disabled={!selected}>
                  <MinusOutlined /> {selected.id === UNSAVED_ID ? 'Discard' : 'Archive'}
                </SeqButton>
                <SeqButton
                  onClick={onDuplicateSelected}
                  disabled={!selected || selected.id === UNSAVED_ID}
                >
                  <CopyOutlined /> Duplicate
                </SeqButton>
                <SeqButton
                  onClick={onSaveSelected}
                  disabled={!unsaved}
                  loading={updating}
                  intent="primary"
                >
                  <SaveOutlined /> Save
                </SeqButton>
              </div>
            </Column>
          ) : (
            <EditorContainer>
              <Empty
                style={{ marginTop: 80, height: 'calc(100vh - 400px)' }}
                description={
                  campaigns.length ? (
                    'Select a campaign or click "+" to create one.'
                  ) : (
                    <div style={{ display: 'inline-block', margin: 'auto' }}>
                      <SeqButton
                        onClick={() => {
                          onAddCampaign(buildDefaultCampaign(org, campaigns.length));
                        }}
                      >
                        Add a Campaign
                      </SeqButton>
                    </div>
                  )
                }
              />
            </EditorContainer>
          )}
        </div>
      </div>
    </PromptOnDirtyForm>
  );
};

export const EditorContainer = styled.div`
  flex: 1;
`;

const SeqStyledListItem = styled(StyledListItem)<{ selected: boolean }>`
  background: ${({ selected }) => (selected ? Colors.Static.SEQUOIA_GREEN + 22 : 'transparent')};
  height: 40px;
  max-width: 200px;
  overflow: ellipsis;
`;

const buildDefaultCampaign = (org: OrganizationAPI.Organization, idx: number, roleId?: number) => {
  const isFirst = idx === 0;
  const campaign: CampaignAPI.Campaign = {
    id: UNSAVED_ID,
    name: idx === 0 ? `New Campaign` : `New Campaign ${idx + 1}`,
    createdAt: new Date(),
    updatedAt: new Date(),
    stopped: false,
    roleId: roleId || org.roles[0]?.id || 0,
    organizationId: org.id,
    senderId: UNSAVED_ID,
    settings: {
      version: 1,
      subject: isFirst ? 'Looking to move?' : '',
      weekdaysOnly: true,
      cc: [],
      bcc: [],
    },
    templates: [
      {
        id: '',
        createdAt: new Date(),
        updatedAt: new Date(),
        requiredVariables: isFirst ? ['signature'] : [],
        body: isFirst
          ? "Hello {{first_name}},\n\nI'm reaching out to see if you might interested in a position at {{my_company}}? I have some great opportunities open right now and thought you might be a great fit.\n\n{{signature}}"
          : '',
        delay: {
          value: 0,
          unit: 'days',
        },
      },
    ],
  };
  isFirst &&
    campaign.templates.push({
      id: '',
      createdAt: new Date(),
      updatedAt: new Date(),
      requiredVariables: isFirst ? ['signature'] : [],
      body: 'Hey {{first_name}},\n\nDid you happen to see my first email? Would love to catch up sometime soon.\n\nLooking forward to it,\n{{signature}}',
      delay: {
        value: 3,
        unit: 'days',
      },
    });
  return campaign;
};
