import Chart from 'bizcharts/lib/components/Chart';
import Coordinate from 'bizcharts/lib/components/Coordinate';
import Legend from 'bizcharts/lib/components/Legend';
import ChartToolTip from 'bizcharts/lib/components/Tooltip';
import Interval from 'bizcharts/lib/geometry/Interval';
import { capitalize, uniq } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { ChartRowContainer, DESTRUCTIVE_CHART_COLORS } from '../ChartHelpers';
import { ChartLegend } from '../components/ChartLegend';
import { Dialog } from '../components/Common';
import { SeqModal } from '../components/Modal';
import { OrganizationContext } from '../contexts/Organization';
import { PieChartData } from '../shared/ChartHelpers';
import { makeRequest } from '../shared/Resource';
import { Colors, hexWithOpacity } from '../shared/Theme';

export interface LeadListStatsModalProps {
  visible: boolean;
  onClose: () => void;
  leadList?: LeadsAPI.LeadList;
  role?: OrganizationAPI.Role;
}

const { SEQUOIA_BRIGHT_RED, BLACK1ALPHA, SEQUOIA_GREEN, SEQUOIA_BRIGHT_YELLOW } = Colors.Static;
const height = 300;

/**
 * This is also used (with Role param passed in) by PageCampaignStats where OrganizationContext will
 * yield 'Sequoia Capital'. Consume org carefully
 */
export const LeadListStatsModal: React.FC<LeadListStatsModalProps> = ({
  visible,
  onClose,
  leadList,
  role,
}) => {
  const { id, name, all } = useContext(OrganizationContext);
  const orgId = role ? role.organizationId : id;
  const orgName = all.find(o => o.id === role?.organizationId)?.name || name;

  const leadListLabel =
    leadList?.description ||
    all.find(o => o.id === role?.organizationId)?.roles.find(r => r.id === role?.id)?.name;

  const [_leadLists, _setLeadLists] = useState<LeadsAPI.LeadList[]>(leadList ? [leadList] : []);

  useEffect(() => {
    if (!visible) {
      return;
    }

    if (leadList) {
      _setLeadLists([leadList]);
      return;
    }

    _setLeadLists([]);
    const loadLeadList = async () => {
      let leadLists = await makeRequest<LeadsAPI.LeadList[]>(`/api/${orgId}/lead-lists?all=true`);
      if (role) {
        leadLists = leadLists.filter(leadList => leadList.roleId === role.id);
      }
      _setLeadLists(leadLists);
    };
    loadLeadList();
  }, [_setLeadLists, leadList, visible, orgId, role]);

  const allCandidates = _leadLists.map(b => b.profiles).flat();
  const allRejected = allCandidates.filter(p => p.review?.status === 'rejected');

  return (
    <SeqModal open={visible} onClose={onClose} style={{ maxWidth: '80vw' }}>
      <Dialog
        header={`Leads at ${orgName} ${leadListLabel ? ` - ${leadListLabel}` : ''}`}
        content={
          <ChartRowContainer style={{ padding: 0, paddingTop: 16, gap: 24 }} height={height}>
            <ApprovedVsRejectedChart allCandidates={allCandidates} />
            <RejectedReasonsChart allRejectedCandidates={allRejected} />
          </ChartRowContainer>
        }
      />
    </SeqModal>
  );
};

const RejectedReasonsChart: React.FC<{
  allRejectedCandidates: LeadsAPI.Candidate[];
}> = ({ allRejectedCandidates }) => {
  const rejectCategories: string[] = uniq(
    allRejectedCandidates.map(r => r.review?.categories || []).flat()
  );

  if (!rejectCategories.length) return <></>;

  const data: PieChartData[] = rejectCategories.map((reason, idx) => {
    const count = allRejectedCandidates.filter(p => p.review?.categories?.includes(reason)).length;
    return {
      label: reason,
      count,
      percent: count / allRejectedCandidates.length || 0,
      color: DESTRUCTIVE_CHART_COLORS[idx],
    };
  });
  const labelsAndColorsRejected = rejectCategories.map((label, i) => {
    return {
      label,
      color: hexWithOpacity(SEQUOIA_BRIGHT_RED, (i + 1) / (rejectCategories.length + 1)),
    };
  });

  return (
    <div style={{ flex: 1, minWidth: 0 }}>
      <ChartLegend labelsAndColors={labelsAndColorsRejected || []} />
      <Chart
        height={height - 105}
        data={data}
        scale={{ count: { formatter: (val: number) => val } }}
        autoFit
      >
        <Coordinate type="theta" radius={0.75} />
        <Legend visible={false} />
        <ChartToolTip showTitle={false} />
        <Interval
          position="count"
          adjust="stack"
          color={['label', labelsAndColorsRejected.map(l => l.color)]}
        />
      </Chart>
    </div>
  );
};

const ApprovedVsRejectedChart: React.FC<{
  allCandidates: LeadsAPI.Candidate[];
}> = ({ allCandidates }) => {
  const statuses = [
    'unreviewed',
    'approved',
    'rejected',
    'later',
  ] as (LeadsAPI.CandidateReview['status'] & 'unreviewed')[];

  const labelsAndColors = [
    { label: 'UnReviewed', color: BLACK1ALPHA },
    { label: 'Approved', color: SEQUOIA_GREEN },
    { label: 'Rejected', color: SEQUOIA_BRIGHT_RED },
    { label: 'Later', color: SEQUOIA_BRIGHT_YELLOW },
  ];

  const data: PieChartData[] = statuses.map((status, idx) => {
    const count = allCandidates.filter(c =>
      status === 'unreviewed' ? !c.review : c.review?.status === status
    ).length;
    return {
      label: capitalize(status),
      count,
      percent: count / allCandidates.length || 0,
      color: labelsAndColors.find(lc => lc.label.toLowerCase() === status)?.color || '',
    };
  });

  return (
    <div>
      <ChartLegend labelsAndColors={labelsAndColors} />
      <Chart
        height={height - 105}
        data={data}
        scale={{ count: { formatter: (val: number) => val } }}
        autoFit
      >
        <Coordinate type="theta" radius={0.75} />
        <Legend visible={false} />
        <ChartToolTip showTitle={false} />
        <Interval
          position="count"
          adjust="stack"
          color={['label', labelsAndColors.map(l => l.color)]}
        />
      </Chart>
    </div>
  );
};
