import { Checkbox, Collapse } from 'antd';
import { intersection, startCase, uniq } from 'lodash';
import { useContext } from 'react';
import styled from 'styled-components/macro';
import { useResource } from '../Resource';
import { Colors } from '../Theme';
import {
  COMMON_DATASCIENCE_CATEGORIES,
  DATASCIENCE_CATEGORY_LABELS,
  SOFTWARE_DATASCIENCE_CATEGORIES,
} from '../rule-editors/DSConstants';
import {
  EditableExcludeRulesMap,
  EditableRuleIds,
  EditorButton,
  HeaderRow,
  Rule,
  RuleEditorContext,
  RuleEditorProps,
  ValidationInfo,
  invalid,
  onChangeWithValidation,
  useDescription,
  valid,
} from '../rule-editors/RuleEditorCommon';

const { SEQUOIA_GREEN, SEQUOIA_LIGHT_GRAY } = Colors.Static;

export const CurrentEmployerDSCategoriesEditor: React.FC<RuleEditorProps> = ({
  rule,
  onChange,
  style,
  title,
  ruleType,
}) => {
  const { dsCategoriesPath } = useContext(RuleEditorContext);
  const [categories = []] = useResource<string[]>(dsCategoriesPath);

  const { aboveFold, belowFold } = splitCategories(categories);

  const desc = useDescription(
    { ruleType, rule },
    {
      include:
        'If a person works for any of the following industries, boost their position in the model:',
      exclude: 'Remove people whose current employer matches any of these industries:',
      invert: 'What industries does your ideal person currently work in?',
    }
  );

  return (
    <div style={style}>
      {title && (
        <HeaderRow
          label={desc}
          rule={rule}
          onChange={onChange}
          extra={
            <EditorButton
              ghost={true}
              style={{ marginRight: 10 }}
              disabled={
                intersection(rule.parameters, SOFTWARE_DATASCIENCE_CATEGORIES).length ===
                SOFTWARE_DATASCIENCE_CATEGORIES.length
              }
              onClick={() => {
                const toAdd = SOFTWARE_DATASCIENCE_CATEGORIES.filter(
                  swd => !rule.parameters.includes(swd)
                );
                onChangeWithValidation(
                  {
                    ...rule,
                    parameters: [...rule.parameters, ...toAdd],
                  },
                  onChange,
                  validate
                );
              }}
            >
              Add Software Defaults
            </EditorButton>
          }
        />
      )}
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {aboveFold.sort().map(category => (
          <CheckboxForCategory
            key={category}
            label={DATASCIENCE_CATEGORY_LABELS[category] || category}
            onChange={checked =>
              onChangeWithValidation(
                {
                  ...rule,
                  parameters: checked
                    ? uniq([...rule.parameters, category])
                    : rule.parameters.filter(p => p !== category),
                },
                onChange,
                validate
              )
            }
            checked={rule.parameters.includes(category)}
          />
        ))}
      </div>
      <Collapse expandIconPosition={'right'} ghost>
        <CustomCollapsePanel header={'Less Common Tags'} key={'more-categories'}>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {belowFold.sort().map(category => (
              <CheckboxForCategory
                label={DATASCIENCE_CATEGORY_LABELS[category] || category}
                onChange={checked =>
                  onChangeWithValidation(
                    {
                      ...rule,
                      parameters: checked
                        ? uniq([...rule.parameters, category])
                        : rule.parameters.filter(p => p !== category),
                    },
                    onChange,
                    validate
                  )
                }
                checked={rule.parameters.includes(category)}
              />
            ))}
          </div>
        </CustomCollapsePanel>
      </Collapse>
    </div>
  );
};

const splitCategories = (allCategories: string[]) =>
  allCategories.reduce<{ aboveFold: string[]; belowFold: string[] }>(
    (prev, cur) => {
      if ([...SOFTWARE_DATASCIENCE_CATEGORIES, ...COMMON_DATASCIENCE_CATEGORIES].includes(cur)) {
        prev.aboveFold.push(cur);
      } else {
        prev.belowFold.push(cur);
      }
      return prev;
    },
    { aboveFold: [], belowFold: [] }
  );

const CheckboxForCategory: React.FC<{
  checked: boolean;
  onChange: (newVal: boolean) => void;
  label: string;
}> = ({ label, checked, onChange }) => {
  return (
    <UnIndentedCheckbox
      onChange={e => onChange(e.target.checked)}
      checked={checked}
      key={`cb-${label}`}
    >
      {startCase(label).replace('And', 'and')}
    </UnIndentedCheckbox>
  );
};

const UnIndentedCheckbox = styled(Checkbox)`
  width: 30%;
  padding: 5px;
  margin-left: 0px !important;
`;

const CustomCollapsePanel = styled(Collapse.Panel)`
  .ant-collapse-header {
    margin-top: 10px;
    margin-bottom: 5px;
    padding: 0px 0px 5px 0px !important;
    color: ${SEQUOIA_GREEN} !important;
    border-bottom: 1px solid ${SEQUOIA_LIGHT_GRAY};
  }
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0px !important;
  }
`;

const validate = (rule: Rule): ValidationInfo => {
  const ruleLabel = EditableExcludeRulesMap[rule.id as EditableRuleIds]?.label || '';
  if (!rule.parameters || rule.parameters.filter(Boolean).length < 1) {
    return invalid(`${ruleLabel} requires at least one tag to be selected.`);
  }
  return valid();
};
