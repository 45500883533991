import { notification } from 'antd';
import React from 'react';
import ReactDOM from 'react-dom';

export function renderToClipboard(root: React.ReactElement<any>, notify?: string | true) {
  // Note: You must call this method within an onClick handler (or some sort of event
  // handler), or the DOM will not allow the copy to clipboard action.

  const el = document.createElement('div');
  document.body.appendChild(el);

  ReactDOM.render(root, el);
  copyToClipboard(el);
  el.remove();

  if (notify) {
    notification.info({ message: typeof notify === 'string' ? notify : 'Copied to clipboard' });
  }
}

export function copyToClipboard(el: HTMLElement) {
  const selection = window.getSelection();

  const range = document.createRange();
  range.selectNodeContents(el);
  if (selection) {
    selection.removeAllRanges();
    selection.addRange(range);
    document.execCommand('copy');
    selection.removeAllRanges();
  }
}
