import { Input, notification } from 'antd';
import { isEqual } from 'lodash';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { Column, FlexFillingColCSS, FlexFillingRowCSS } from '../../components/Common';
import { OrganizationContext } from '../../contexts/Organization';
import { useCampaignConfig } from '../../hooks/useCampaignConfig';
import { CampaignSummaryWithSender } from '../../hooks/useVisibleCampaigns';
import { EXTERNAL_RECIPIENT_VARIABLES, isValidEmail, pluralize } from '../../shared/helpers';
import { MinimalExtProfile, sanitizeProfileName } from '../../shared/profile-helpers';
import { InitialsView } from '../../shared/ProfileImage';
import { makeRequest } from '../../shared/Resource';
import { ScrollingContainer } from '../../shared/ScrollableContainer';
import { Colors } from '../../shared/Theme';
import { InfoButton } from '../Button';
import { CCBCCRow } from '../CCBCCRow';
import { ExternalTemplateEditor } from '../ExternalTemplateEditor';
import { buildVariablesMap, TemplateContext } from '../ExternalTemplateSupport';
import { SeqModal } from '../Modal';
import { CandidatePopover } from '../popovers/CandidatePopover';
import { ChooseCampaignMsgContainer } from '../profile-drawer/EmailView';
import { RemovableProfileRow } from '../RemovableProfileRow';
import { ScheduledSendButton } from '../ScheduledSendButton';
import { H3Mono, Label, SectionHeader } from '../Text';

const { SEQUOIA_BLACK } = Colors.Static;
const DEFAULT_OVERRIDES = { subject: '', introBody: '' };

interface EmailState {
  profileId: number;
  overrides: Pick<CampaignAPI.CandidateOverride, 'introBody' | 'subject' | 'cc' | 'bcc'>;
}

interface BulkEmailModalProps<T> {
  profiles: T[];
  visible: boolean;
  onClose: () => void;
}

interface BulkEmailViewProps<T> {
  candidate: T;
  campaign: CampaignSummaryWithSender;
  emails: EmailState[];
  setEmails: (value: React.SetStateAction<EmailState[]>) => void;
}

export const BulkEmailModal = <T extends MinimalExtProfile & { email: string }>({
  profiles,
  visible,
  onClose,
}: BulkEmailModalProps<T>): ReactElement => {
  const { id: orgId } = useContext(OrganizationContext);
  const [profileIdsInCampaigns, setProfilesInCampaigns] = useState<number[]>([]);
  const [selectedProfile, setSelectedProfile] = useState<T>();
  const [emails, setEmails] = useState<EmailState[]>([]);
  const [sending, setSending] = useState<boolean>(false);

  const { campaignSelect, campaign, chooseCampaignMessage } = useCampaignConfig({
    feature: 'pipeline',
    organizationId: orgId,
  });

  useEffect(() => {
    if (visible) {
      const loadProfileIdsInCampaigns = async () => {
        const ids = await makeRequest<number[]>(
          `/api/${orgId}/campaign/for-candidates?ids=${encodeURIComponent(
            JSON.stringify(profiles.map(p => p.id))
          )}`
        );
        setEmails(
          profiles.reduce((acc, p) => {
            if (isValidEmail(p.email) && !ids.includes(p.id))
              acc.push({ profileId: p.id, overrides: { ...DEFAULT_OVERRIDES } });
            return acc;
          }, [] as EmailState[])
        );
        setProfilesInCampaigns(ids || []);

        if (profiles.length) {
          setSelectedProfile(profiles.find(p => isValidEmail(p.email) && !ids.includes(p.id)));
        }
      };

      loadProfileIdsInCampaigns();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, orgId]);

  const onSend = async (deliverAt: Date) => {
    if (campaign && (emails.length || !sending)) {
      setSending(true);
      const emailsToSend: PipelineAPI.BulkEmailReq = emails.map(e => ({
        ...e,
        overrides: {
          introBody: e.overrides.introBody || undefined,
          subject: e.overrides.subject || undefined,
          cc: e.overrides.cc || undefined,
          bcc: e.overrides.bcc || undefined,
          deliverAt,
        },
      }));

      const foundInvalidBCC = emailsToSend.find(em =>
        em.overrides.bcc?.some(bcc => !isValidEmail(bcc))
      );
      if (!!foundInvalidBCC) {
        setSending(false);
        const name = profiles.find(p => p.id === foundInvalidBCC.profileId)?.name || 'an email';
        return notification.warning({
          message: `The bcc field contains an invalid address for ${name}.`,
        });
      }

      const foundInvalidCC = emailsToSend.find(em =>
        em.overrides.cc?.some(cc => !isValidEmail(cc))
      );
      if (!!foundInvalidCC) {
        setSending(false);
        const name = profiles.find(p => p.id === foundInvalidCC.profileId)?.name || 'an email';
        return notification.warning({
          message: `The cc field contains an invalid address for ${name}.`,
        });
      }

      const { message } = await makeRequest<{ message: string }>(
        `/api/${orgId}/campaign/${campaign.id}/profiles`,
        'PUT',
        emailsToSend
      );
      message && setSending(false);
      onClose();
    }
  };

  return (
    <SeqModal
      height="90vh"
      open={visible}
      onClose={() => {
        if (window.confirm("Are you sure? Emails you've edited will not be saved.")) {
          onClose();
        }
      }}
    >
      <Container>
        <Column style={{ width: 350, flex: 'unset', borderRight: `1px solid ${SEQUOIA_BLACK}` }}>
          <H3Mono style={{ marginBottom: 12 }}>
            Email {pluralize(emails.length, 'person', 'people')}
          </H3Mono>
          <ContentContainer>
            {profiles.map(p => (
              <RemovableProfileRow
                key={`profile-row-${p.id}`}
                profile={p}
                selected={p.id === selectedProfile?.id}
                onSelect={() => {
                  setSelectedProfile(p);
                }}
                onAddOrRemove={() => {
                  setEmails(prev => {
                    let next: EmailState[] = [];
                    if (prev.map(e => e.profileId).includes(p.id)) {
                      next = prev.filter(e => e.profileId !== p.id);
                      setSelectedProfile(
                        profiles.find(p => next.map(e => e.profileId).includes(p.id))
                      );
                    } else {
                      next = [{ profileId: p.id, overrides: { ...DEFAULT_OVERRIDES } }, ...prev];
                    }
                    return next;
                  });
                }}
                added={emails.some(e => e.profileId === p.id)}
                alreadyContacted={profileIdsInCampaigns.includes(p.id)}
              />
            ))}
          </ContentContainer>
        </Column>
        <ContentContainer style={{ width: '45vw' }}>
          <div style={{ display: 'flex', alignItems: 'center', gap: 12 }}>
            <Label>Campaign:</Label>
            {campaignSelect({})}
          </div>
          {selectedProfile && campaign ? (
            <BulkEmailView
              candidate={selectedProfile}
              campaign={campaign}
              emails={emails}
              setEmails={setEmails}
            />
          ) : (
            <div style={{ flex: 1 }} />
          )}
          {chooseCampaignMessage && (
            <ChooseCampaignMsgContainer>{chooseCampaignMessage}</ChooseCampaignMsgContainer>
          )}
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <ScheduledSendButton
              campaign={campaign}
              onSend={onSend}
              disabled={sending ? 'Sending' : !campaign || !emails.length}
              style={{ marginTop: 16 }}
            />
          </div>
        </ContentContainer>
      </Container>
    </SeqModal>
  );
};

const BulkEmailView = <T extends MinimalExtProfile & { email: string }>({
  candidate,
  campaign,
  emails,
  setEmails,
}: BulkEmailViewProps<T>): ReactElement => {
  const { me } = useContext(OrganizationContext);
  const variables = buildVariablesMap(
    me,
    EXTERNAL_RECIPIENT_VARIABLES,
    candidate,
    me.templateVariables
  );
  const currCampaignEmailsIdx = emails.findIndex(e => e.profileId === candidate.id);

  if (!emails[currCampaignEmailsIdx]) return <div />;

  const currEmail = emails[currCampaignEmailsIdx];

  return (
    <TemplateContext.Provider value={{ variables, recipient: candidate }}>
      <ScrollingContainer
        style={{
          marginTop: 12,
          marginRight: -24,
          flex: 1,
          gap: 16,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Label style={{ margin: '16px 12px 16px 0' }}>To:</Label>
            <CandidatePopover candidate={candidate}>
              <InfoButton>
                <InitialsView
                  name={candidate.name}
                  style={{ width: 24, minWidth: 24, height: 24 }}
                />
                <div>{sanitizeProfileName(candidate.name)}</div>
              </InfoButton>
            </CandidatePopover>
            <div style={{ marginLeft: 6 }}>{`<${candidate.email}>`}</div>
          </div>
          <CCBCCRow
            key={`ccbcc-campaign-${campaign.id}`} //Pull new settings on campaign change
            value={{
              cc: currEmail.overrides.cc || campaign.settings.cc,
              bcc: currEmail.overrides.bcc || campaign.settings.bcc,
            }}
            onChange={({ bcc, cc }) => {
              const next: EmailState[] = [...emails];
              next[currCampaignEmailsIdx].overrides.cc = isEqual(cc, campaign.settings.cc)
                ? undefined
                : cc;
              next[currCampaignEmailsIdx].overrides.bcc = isEqual(bcc, campaign.settings.bcc)
                ? undefined
                : bcc;
              setEmails(next);
            }}
          />
          <Label>Subject</Label>
          <Input
            style={{ border: `1px solid #F0F0F0`, marginLeft: 2 }}
            value={currEmail.overrides.subject || campaign.settings.subject}
            onChange={e => {
              const next: EmailState[] = [...emails];
              next[currCampaignEmailsIdx].overrides.subject = e.target.value.toString();
              setEmails(next);
            }}
          />
        </div>
        {campaign.templates.map((template, idx) => (
          <div key={`template-${idx}`}>
            <SectionHeader style={{ marginBottom: 12 }}>
              {idx === 0
                ? 'Introductory Email'
                : `Followup - ${template.delay.value} ${template.delay.unit} later`}
            </SectionHeader>
            <ExternalTemplateEditor
              value={{
                text:
                  idx === 0
                    ? emails[currCampaignEmailsIdx].overrides.introBody || template.body
                    : template.body,
              }}
              setValue={val => {
                if (idx === 0) {
                  setEmails(prev => {
                    const next: EmailState[] = [...prev];
                    next[currCampaignEmailsIdx].overrides.introBody = val.text;
                    return next;
                  });
                }
              }}
              sender={me}
              config={{
                subject: campaign.settings.subject,
                previewOnly: !!idx,
              }}
            />
          </div>
        ))}
      </ScrollingContainer>
    </TemplateContext.Provider>
  );
};

const Container = styled.div`
  ${FlexFillingRowCSS}
  min-height: 0;
  gap: 24px;
  padding: 24px 0 24px 24px;
`;

const ContentContainer = styled(ScrollingContainer)`
  ${FlexFillingColCSS}
`;
