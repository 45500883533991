import { ColumnsType, ColumnType } from 'antd/es/table';
import { isEqual } from 'lodash';
import { default as React, useContext } from 'react';
import { SeqButton } from '../components/Button';
import { FastTable } from '../components/FastTable';
import { Profile } from '../components/Profile';
import { ConnectedToTags } from '../components/profile-drawer/ConnectedToTags';
import {
  EditEndorsementPopover,
  ReferredByTags,
} from '../components/profile-drawer/ReferredBySection';
import { CondensingTagsContainer } from '../components/profile-drawer/useTagsRow';
import { STATUS_SORT_ORDERS } from '../components/selects/StatusSelect';
import { SeqTag } from '../components/SeqTag';
import { OrganizationContext } from '../contexts/Organization';
import { buildEmptyEndorsement } from '../referrals/PageReferralsRecommendations';
import { sanitizeProfileName } from '../shared/profile-helpers';
import { DATASCIENCE_CATEGORY_LABELS } from '../shared/rule-editors/DSConstants';
import { CompanyInfoButton } from './CompanyInfoButton';
import { InsightTags } from './InsightTags';

interface NetworkTableProps {
  checkCol: ColumnType<NetworkAPI.NetworkProfile>;
  profiles: NetworkAPI.NetworkProfile[];
  initSearch?: NetworkAPI.SearchHistoryData;
  onSearchWithoutFilters: () => void;
  onSaveSearch: (s: NetworkAPI.SearchHistoryData, isNew: boolean) => void;
  onUpdateProfile: (update: Partial<NetworkAPI.NetworkProfile> & { id: number }) => void;
  applyLocalUpdates: (change: Partial<NetworkAPI.NetworkRes>) => void;
  setDrawerCandidateId: (id: number, ids?: number[]) => void;
  getRelatedProfileName: (id: number) => string;
}

export const NetworkTable = ({
  profiles,
  checkCol,
  onUpdateProfile,
  initSearch = {},
  onSaveSearch,
  applyLocalUpdates,
  setDrawerCandidateId,
  getRelatedProfileName,
  onSearchWithoutFilters,
}: NetworkTableProps) => {
  const { me } = useContext(OrganizationContext);

  const onSearch = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    callback: (search: NetworkAPI.SearchHistoryData) => NetworkAPI.SearchHistoryData
  ) => {
    e.stopPropagation();

    const cloned = JSON.parse(JSON.stringify(initSearch));
    const updated = callback(cloned) || cloned;
    const isNew = !isEqual(initSearch, updated);

    onSaveSearch(isNew ? updated : initSearch, isNew);
  };

  const columns: ColumnsType<NetworkAPI.NetworkProfile> = React.useMemo(() => {
    return [
      { ...checkCol, fixed: true },
      {
        key: 'Person',
        fixed: true,
        width: 200,
        title: 'Person',
        render: (_, profile) => <Profile profile={profile} size="small" />,
        sorter: (a: NetworkAPI.NetworkProfile, b: NetworkAPI.NetworkProfile) =>
          sanitizeProfileName(a.name.toLowerCase()).localeCompare(
            sanitizeProfileName(b.name.toLowerCase())
          ),
      },
      {
        ...networkCoCol,
        sorter: (a: NetworkAPI.NetworkProfile, b: NetworkAPI.NetworkProfile) =>
          (b.bestRole?.company?.name || '')
            .toLowerCase()
            .localeCompare((a.bestRole?.company?.name || '').toLowerCase()),
      },
      {
        key: 'Connected To',
        width: 150,
        title: 'Connected To',
        render: (_, profile) => {
          return (
            <ConnectedToTags
              profile={profile}
              onSearch={onSearch}
              myExternalId={me.externalProfileId}
            />
          );
        },
      },
      {
        key: 'Insights',
        width: 235,
        title: 'Insights',
        render: (_, { insights }) => <InsightTags insights={insights} onSearch={onSearch} />,
      },
      {
        key: 'Functions',
        width: 200,
        title: 'Functions',
        render: (_, profile) => (
          <CondensingTagsContainer
            condensed
            tags={profile.functions.map(t => (
              <SeqTag
                key={t}
                tagName={t}
                onClick={e => {
                  onSearch(e, (s: NetworkAPI.SearchHistoryData) => {
                    s.person = {
                      ...(s.person || {}),
                      functions: Array.from(new Set([...(s.person?.functions || []), t])),
                    };
                    return s;
                  });
                }}
              />
            ))}
          />
        ),
      },
      {
        key: 'Industry',
        width: 200,
        title: 'Industry',
        render: (_, profile) => (
          <CondensingTagsContainer
            condensed
            tags={(profile.industryTags || [])
              .filter(t => t in DATASCIENCE_CATEGORY_LABELS)
              .map(t => (
                <SeqTag
                  key={`seq-${t}`}
                  tagName={DATASCIENCE_CATEGORY_LABELS[t]}
                  onClick={e => {
                    onSearch(e, (s: NetworkAPI.SearchHistoryData) => {
                      s.company = {
                        ...(s.company || {}),
                        categories: Array.from(new Set([...(s.company?.categories || []), t])),
                      };
                      return s;
                    });
                  }}
                />
              ))}
          />
        ),
      },
      {
        key: 'Status',
        width: 200,
        title: 'Status',
        render: (_, profile) => <div>{profile.status || '-'}</div>,
        sorter: (a, b) =>
          (STATUS_SORT_ORDERS[`${b.status}`] || 0) - (STATUS_SORT_ORDERS[`${a.status}`] || 0),
      },
      {
        key: 'Vouched By',
        width: 130,
        title: 'Vouched By',
        render: (_, profile) => {
          return (
            <div onClick={e => e.stopPropagation()}>
              <ReferredByTags
                profile={profile}
                myExtProfileId={me.externalProfileId}
                onUpdateProfile={onUpdateProfile}
                inlineEditing
                getRelatedProfileName={getRelatedProfileName}
              />
              {me.externalProfileId &&
                profile.connectedTo.includes(me.externalProfileId) &&
                !profile.endorsements.find(e => e.owner === me.externalProfileId) && (
                  <EditEndorsementPopover
                    profile={profile}
                    endorsement={buildEmptyEndorsement(me.externalProfileId)}
                    onUpdateProfile={onUpdateProfile}
                    isNew
                  >
                    + Add Endorsement
                  </EditEndorsementPopover>
                )}
            </div>
          );
        },
        sorter: (a: NetworkAPI.NetworkProfile, b: NetworkAPI.NetworkProfile) =>
          sortScoreForEndorsements(b) - sortScoreForEndorsements(a),
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkCol, me.externalProfileId]);

  return (
    <FastTable
      rowProps={p => ({
        style: { cursor: 'pointer' },
        onClick: () =>
          setDrawerCandidateId(
            p.id,
            profiles.map(p => p.id)
          ),
      })}
      rowKey={'id'}
      rowHeight={'measure'}
      dataSource={profiles}
      columns={columns}
      emptyProps={
        Object.keys(initSearch).length > 1 && initSearch.searchTerm
          ? {
              imgProps: { style: { maxHeight: '25vh' } },
              description: (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    gap: 12,
                  }}
                >
                  No profiles found. Try this search without filters.
                  <SeqButton intent="inverted" size="small" onClick={onSearchWithoutFilters}>
                    Search
                  </SeqButton>
                </div>
              ),
            }
          : undefined
      }
    />
  );
};

const networkCoCol: ColumnType<NetworkAPI.NetworkProfile> = {
  key: 'Company',
  width: 180,
  title: 'Company',
  render: (_, { bestRole }) => {
    const company = bestRole?.company;
    if (company) {
      return <CompanyInfoButton initCompany={company} />;
    }
    return '--';
  },
};

const sortScoreForEndorsements = (b: NetworkAPI.NetworkProfile) =>
  b.endorsements.some(x => x.score === 'A')
    ? 3
    : b.endorsements.some(x => x.score === 'B')
    ? 2
    : b.endorsements.length
    ? 1
    : 0;
