import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { SeqButton } from '../components/Button';
import { Dialog, SeqFullPageOverlay } from '../components/Common';
import { ExplanatoryText } from '../components/Text';
import { OrganizationContext } from '../contexts/Organization';
import { ClickableItem } from '../shared/Common';
import { makeRequest } from '../shared/Resource';
import { Colors, hexWithOpacity } from '../shared/Theme';

const { SEQUOIA_DARK_GRAY } = Colors.Static;

export const ShareYourNetworkOverlay: React.FC<{ onDismiss: () => void }> = ({ onDismiss }) => {
  const { me, actions } = useContext(OrganizationContext);

  const onApprove = async () => {
    await makeRequest(`/api/user/${me.id}/settings`, 'PUT', {
      minerSheetsShared: true,
    });
    await actions.refresh();
    window.location.reload();
  };

  return (
    <SeqFullPageOverlay width={510}>
      <Dialog
        header="Explore your Network"
        content={
          <>
            <ExplanatoryText>
              The Network tab gives you a bird's-eye view of your team's collective network, so you
              can identify great potential hires, find connections to customers / companies and stay
              on top of events like work anniversaries that might signal that someone is open to a
              move.
            </ExplanatoryText>
            <ExplanatoryText style={{ marginBottom: 12 }}>
              If you're using the Sequoia Chrome Extension to{' '}
              <Link to={`/${me.organizationId}/referrals`}>collect your 1st-degree network</Link> or
              have completed a referral sheet for Sequoia's Talent team in the past, you can
              contribute your network to this team-wide view by opting in below.
            </ExplanatoryText>
          </>
        }
        footer={
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <ClickableItem
              onClick={onDismiss}
              style={{
                fontSize: 12,
                fontWeight: 500,
                color: hexWithOpacity(SEQUOIA_DARK_GRAY, 0.5),
              }}
            >
              Not now
            </ClickableItem>
            <SeqButton intent="primary" size="small" onClick={onApprove} style={{ margin: 0 }}>
              Share My Network
            </SeqButton>
          </div>
        }
      />
    </SeqFullPageOverlay>
  );
};
