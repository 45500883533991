import React, { CSSProperties, ReactNode } from 'react';
import styled from 'styled-components/macro';
import { Colors } from './Theme';

interface CoverBarOpts {
  whiteBg?: true;
  alwaysShow?: true;
}

const { SEQUOIA_PAPER } = Colors.Static;

export const ScrollingContainer = styled.div<{
  $alwaysShow?: boolean;
  $axis?: 'x' | 'y';
  minHeight?: string;
  backgroundColor?: string;
}>`
  position: relative;
  overflow-${p => p.$axis || 'y'}: scroll;
  min-height: ${p => p.minHeight || undefined};
  overscroll-behavior: contain;

  ${({ $axis = 'y', backgroundColor = SEQUOIA_PAPER, theme }) => {
    const background = backgroundColor;

    return $axis === 'x'
      ? ''
      : `
      background: 
        linear-gradient(${background} 30%, ${background}) center top,
        linear-gradient(${background}, ${background} 70%) center bottom,
        radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) center top,
        radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) center bottom;
      background-repeat: no-repeat;
      background-size: 100% 60px, 100% 60px, 100% 14px, 100% 14px;
      background-attachment: local, local, scroll, scroll;
  `;
  }}
  
  ${({ $alwaysShow, $axis }) =>
    $alwaysShow
      ? ''
      : `
    mask-image: linear-gradient(to ${$axis !== 'x' ? 'top' : 'right'}, transparent, black),
      linear-gradient(to ${$axis !== 'x' ? 'left' : 'top'}, transparent 16px, black 16px);
    mask-size: ${$axis !== 'x' ? '100% 20000px' : '20000px 100%'};
    mask-position: ${$axis !== 'x' ? 'left bottom' : 'left top'};
    transition: mask-position 0.5s, -webkit-mask-position 0.5s;
 
    &:hover {
      mask-position: ${$axis !== 'x' ? 'left top' : 'right bottom'};
    }
  `}

  &::-webkit-scrollbar {
    ${p => (p.$axis !== 'x' ? 'width' : 'height')}: 16px;
  }
  &::-webkit-scrollbar-thumb {
    height: 6px;
    border: 6px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    -webkit-border-radius: 16px;
    background-color: rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
      inset 1px 1px 0px rgba(0, 0, 0, 0.05);
  }
  &::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
  }
  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }
`;

// V1
export const ScrollableContainer: React.FunctionComponent<{
  children: ReactNode;
  height: number | string;
  coverBarOpts?: CoverBarOpts;
  style?: CSSProperties;
  classNameForScrollableContent?: string;
}> = ({ height, children, coverBarOpts, style, classNameForScrollableContent }) => {
  return (
    <ScrollContainer style={style}>
      <ScrollableContent className={classNameForScrollableContent} style={{ height }}>
        {children}
      </ScrollableContent>
      <CoverBar {...coverBarOpts} className="cover-bar" />
    </ScrollContainer>
  );
};

const ScrollContainer = styled.div`
  width: 100%;
  position: relative;
  margin: 24px auto;
  &:hover .cover-bar {
    opacity: 0;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
  }
`;

const ScrollableContent = styled.div`
  width: 100%;
  overflow-y: scroll;
  overscroll-behavior: contain;
  &::-webkit-scrollbar {
    width: 24px;
  }
  &::-webkit-scrollbar-thumb {
    height: 6px;
    border: 10px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    -webkit-border-radius: 24px;
    background-color: rgba(0, 0, 0, 0.15);
    -webkit-box-shadow:
      inset -1px -1px 0px rgba(0, 0, 0, 0.05),
      inset 1px 1px 0px rgba(0, 0, 0, 0.05);
  }
  &::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
  }
  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }
`;

const CoverBar = styled.div<CoverBarOpts>`
  position: absolute;
  height: 100%;
  top: 0;
  right: 0;
  width: 23px;
  -webkit-transition: all 0.5s;
  opacity: ${({ alwaysShow }) => (alwaysShow ? 0 : 1)};
  background: ${Colors.Static.SEQUOIA_PAPER};
`;
