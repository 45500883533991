import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';

export const EmailFrame = ({ html }: { html: string }) => {
  const [lastFitSize, setLastFitSize] = React.useState<string>();
  const iframeComponent = React.useRef<HTMLIFrameElement | null>(null);
  const iframeWrapper = React.useRef<HTMLDivElement | null>(null);

  const getIframeEl = () =>
    iframeComponent.current && (ReactDOM.findDOMNode(iframeComponent.current) as HTMLIFrameElement);

  const setHeightQuietly = (height: number) => {
    const el = getIframeEl();
    if (el && el.style.height !== `${height}px`) {
      el.style.height = `${height}px`;
    }
  };

  const onReevaluateContentSize = (entry: ResizeObserverEntry) => {
    const size = `${entry.contentRect.width}:${entry.contentRect.height}`;
    if (size === lastFitSize || !iframeComponent.current) return;

    setLastFitSize(size);
    const el = getIframeEl();
    const doc = el?.contentDocument;

    // We must set the height to zero in order to get a valid scrollHeight
    // if the document is wider and has a lower height now.
    setHeightQuietly(0);

    // If documentElement has a scroll height, prioritize that as height
    // If not, fall back to body scroll height by setting it to auto
    let height = 0;
    let width = 0;
    if (doc && doc.documentElement && doc.documentElement.scrollHeight > 0) {
      width = doc.documentElement.scrollWidth;
      height = doc.documentElement.scrollHeight;
    } else if (doc && doc.body) {
      const style = window.getComputedStyle(doc.body);
      if (style.height === '0px') {
        doc.body.style.height = 'auto';
      }
      width = doc.body.scrollWidth;
      height = doc.body.scrollHeight;
    }

    if (el && width > el.clientWidth) {
      // the message will scroll horizontally, and we need to add 20px to the height of
      // the iframe to allow for it's scrollbar. Otherwise it covers the last line of text.
      height += 20;
    }

    setHeightQuietly(height);
    if (iframeWrapper.current) {
      iframeWrapper.current.style.height = `${height}px`;
    }
  };

  const iframeDocObserver = new window.ResizeObserver(entries =>
    window.requestAnimationFrame(() => {
      onReevaluateContentSize(entries[0]);
    })
  );

  const onChange = () => {
    if (iframeDocObserver) iframeDocObserver.disconnect();
    const el = getIframeEl();
    const doc = el?.contentDocument;
    if (!doc) return;

    doc.open();
    doc.write(
      `<!DOCTYPE html>` +
        // (styles ? `<style>${styles}</style>` : '') +
        `<div id='inbox-html-wrapper' class="${process.platform}">${html}</div>`
    );
    doc.close();

    // Notify the EventedIFrame that we've replaced it's document (with `open`)
    // so it can attach event listeners again.
    setLastFitSize('');

    // Observe the <html> element within the iFrame for changes to it's content
    // size. We need to disconnect the observer before the HTML element is deleted
    // or Chrome gets into a "maximum call depth" Observer error.
    const observedEl = el.contentDocument.firstElementChild;
    observedEl && iframeDocObserver.observe(observedEl);
    el?.contentWindow?.addEventListener('beforeunload', () => {
      iframeDocObserver.disconnect();
    });
  };

  useEffect(() => {
    onChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [html]);

  return (
    <div ref={iframeWrapper}>
      <iframe title="iframe" ref={iframeComponent} style={{ width: '100%', border: 'none' }} />
    </div>
  );
};
