import moment, { Moment } from 'moment';
import React from 'react';
import styled from 'styled-components/macro';
import { SelectCohortEvent } from '../../events';
import { Colors } from '../../shared/Theme';
import { Option as CustomOption, PREVENT_CLOSE_CLASS_NAME, Select, SelectOption } from './Select';

const { SEQUOIA_DARK_GRAY, SEQUOIA_LIGHT_GRAY } = Colors.Static;

export const COHORT_DATE_FORMAT = 'MMM DD, YYYY';
const VALUE_DATE_PICKER_OPTION = -1;
const ALL_KEY = 'All Cohorts';

interface CohortSelectorProps {
  allCandidates: CampaignAPI.CampaignMember[];
  onCohortSelected: (selected: CampaignAPI.CampaignMember[], label?: string) => void;
}

const DateSelectionFooter: React.FunctionComponent<{
  onSelected: (start: Moment, end: Moment) => void;
}> = ({ onSelected }) => {
  const [startDate, setStartDate] = React.useState<Moment | undefined>();
  const [endDate, setEndDate] = React.useState<Moment | undefined>();

  React.useEffect(() => {
    if (startDate && endDate) {
      const start = startDate.startOf('day');
      const end = endDate.endOf('day');
      if (start.isValid() && end.isValid()) {
        onSelected(start, end);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  return (
    <CustomDatePickerContainer selected={false}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          height: 56,
          background: 'white',
          borderTop: `1px solid ${SEQUOIA_LIGHT_GRAY}`,
          color: SEQUOIA_DARK_GRAY,
        }}
      >
        <div
          style={{
            width: 68,
            flexShrink: 0,
            height: '100%',
            borderRight: `1px solid  ${SEQUOIA_LIGHT_GRAY}`,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <img
            style={{
              marginRight: 'auto',
              marginLeft: 'auto',
            }}
            width={19}
            height={19}
            alt="Calendar icon"
            src="/icons/calendar.svg"
          />
        </div>
        <DatePickerInput onSelect={setStartDate} placeholder={'Start Date'} />
        <DatePickerInput onSelect={setEndDate} placeholder={'End Date'} />
      </div>
    </CustomDatePickerContainer>
  );
};

const CustomDatePickerContainer = styled(CustomOption)`
  padding: 0;
  &:hover {
    background-color: undefined;
  }
`;

const ACCEPTABLE_DATE_FORMATS = ['MM/DD/YY', 'MM/DD/YYYY', 'M/D/YYYY', 'M/D/YY'];
const DatePickerInput: React.FunctionComponent<{
  placeholder: string;
  selected?: Moment;
  onSelect: (date: Moment) => void;
}> = ({ placeholder, selected, onSelect }) => {
  const [warn, setWarn] = React.useState<boolean>(false);

  const momentFromEvent = (event: { currentTarget?: HTMLInputElement }): Moment | false => {
    if (!event.currentTarget || !event.currentTarget.value) {
      return false;
    }
    const m = moment(event.currentTarget.value, ACCEPTABLE_DATE_FORMATS, true);
    if (!m.isValid()) {
      return false;
    }
    return m;
  };

  return (
    <CohortDateSelectorInput
      className={PREVENT_CLOSE_CLASS_NAME}
      warn={warn}
      onKeyDown={e => {
        if (e.key === 'Enter' || e.key === 'Return' || e.key === 'Escape') {
          e.currentTarget.parentElement?.click();
        }
      }}
      onChange={e => {
        if (e.currentTarget.value) {
          const m = momentFromEvent(e);
          if (m) {
            setWarn(false);
            onSelect(m); //true = Maintain local time at conversion
          }
        } else {
          setWarn(false);
        }
      }}
      onBlur={e => {
        e.currentTarget.placeholder = placeholder;
        e.currentTarget.value === '' || momentFromEvent(e) ? setWarn(false) : setWarn(true);
      }}
      onFocus={e => {
        e.currentTarget.placeholder = ACCEPTABLE_DATE_FORMATS[0];
        const moment = momentFromEvent(e);
        if (moment) {
          setWarn(false);
          onSelect(moment);
        }
      }}
      placeholder={placeholder}
      value={selected && moment(selected).format(ACCEPTABLE_DATE_FORMATS[0])}
    />
  );
};

const CohortDateSelectorInput = styled.input<{ warn: boolean }>`
  flex: 1;
  flex-shrink: 200;
  font-size: 14px;
  padding-left: 12px;
  border: ${({ warn }) => (warn ? `1px solid red` : 'none')};
  min-width: 0px;
  height: 56px;
  &:focus {
    outline: none;
    border: none;
  }
  -webkit-appearance: none;
  ::-webkit-calendar-picker-indicator {
    display: none;
  }
`;

export const CohortSelector: React.FunctionComponent<CohortSelectorProps> = ({
  allCandidates,
  onCohortSelected,
}) => {
  const [dateRangeLabel, setDateRangeLabel] = React.useState<string | undefined>(undefined);
  const [selectedDate, setSelectedDate] = React.useState<number | undefined>(0);

  const candidatesByDay = React.useMemo(() => {
    const byDay = cutCandidatesByDayCreated(allCandidates); // { [dayYear: string]: CampaignAPI.CampaignMember[] } = {};
    byDay[0] = allCandidates;
    return byDay;
  }, [allCandidates]);

  React.useEffect(() => {
    selectedDate
      ? onCohortSelected(
          candidatesByDay[selectedDate],
          moment.unix(selectedDate).format(COHORT_DATE_FORMAT)
        )
      : onCohortSelected(allCandidates);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allCandidates]);

  React.useEffect(() => {
    const onRequestCohortSelection = (event: SelectCohortEvent) => {
      const selected = options.find(o => o.id === event.cohortUnix);
      selected && onSelection(selected.id);
    };
    window.addEventListener(SelectCohortEvent.EventKey, onRequestCohortSelection as any);
    return () => {
      window.removeEventListener(SelectCohortEvent.EventKey, onRequestCohortSelection as any);
    };
  });

  const onSelection = (value: number | [Moment, Moment] | undefined) => {
    if (!value) {
      setDateRangeLabel(undefined);
      setSelectedDate(0);
      onCohortSelected(allCandidates);
      return;
    }
    if (value === VALUE_DATE_PICKER_OPTION) {
      return;
    }
    if (value instanceof Array) {
      setSelectedDate(undefined);
      onCohortSelected(
        allCandidates.filter(r => moment(r.createdAt).isBetween(value[0], value[1])),
        `${value[0].format(COHORT_DATE_FORMAT)} - ${value[1].format(COHORT_DATE_FORMAT)}`
      );
    } else {
      setSelectedDate(value);
      onCohortSelected(
        candidatesByDay[value] || [],
        value !== 0 ? moment.unix(value / 1000).format(COHORT_DATE_FORMAT) : undefined
      );
    }
  };

  const options: SelectOption[] = Object.entries(candidatesByDay)
    .sort((r1, r2) => Number(r2[0]) - Number(r1[0]))
    .map(([time]) => {
      const timeOfDay = Number(time);
      const name = timeOfDay ? moment.unix(timeOfDay / 1000).format(COHORT_DATE_FORMAT) : ALL_KEY;
      return {
        name,
        id: Number(timeOfDay),
      };
    });

  return (
    <Select
      style={{ minWidth: 147 }}
      optionsPlacement="bottomRight"
      options={options}
      placeholder={dateRangeLabel || 'All Cohorts'}
      selected={selectedDate}
      onSelect={onSelection}
      footer={
        <DateSelectionFooter
          onSelected={(start, end) => {
            setDateRangeLabel(
              `${start.format(COHORT_DATE_FORMAT)} - ${end.format(COHORT_DATE_FORMAT)}`
            );
            onSelection([start, end]);
          }}
        />
      }
    />
  );
};

export const cutCandidatesByDayCreated = (
  candidates: CampaignAPI.CampaignMember[]
): { [unixStartOfDay: number]: CampaignAPI.CampaignMember[] } => {
  candidates.sort((r1, r2) => moment(r2.createdAt).diff(moment(r1.createdAt), 'millisecond')); //Most recent first
  const candidatesByDay: { [unixStartOfDay: number]: CampaignAPI.CampaignMember[] } = {};
  candidates.forEach(r => {
    const key = moment(r.createdAt).startOf('day').unix() * 1000;
    if (!candidatesByDay[key]) {
      candidatesByDay[key] = [];
    }
    candidatesByDay[key].push(r);
  });
  return candidatesByDay;
};
