import { pick } from 'lodash';
import moment from 'moment';
import { default as React, ReactElement, useContext, useState } from 'react';
import styled from 'styled-components/macro';
import { NotificationContext } from '../../contexts/Notification';
import { OrganizationContext } from '../../contexts/Organization';
import { OnEditNoteEvent } from '../../events';
import { isStoppedBounce, isStoppedNoEmail, onProfileUpsert } from '../../Helpers';
import { RichMentionsEditor, RichMentionsEditorValue } from '../../mentions/RichMentionsEditor';
import { ClickableItem, NotificationDot } from '../../shared/Common';
import { FriendlyErrorBoundary } from '../../shared/FriendlyErrorBoundary';
import { useStateWithStorage } from '../../shared/hooks';
import { makeRequest, useResource } from '../../shared/Resource';
import { ScrollingContainer } from '../../shared/ScrollableContainer';
import { Colors } from '../../shared/Theme';
import { IconLinkButton, SeqButton } from '../Button';
import { FlexFillingColCSS } from '../Common';
import { UpsertCandidateModal } from '../modals/UpsertCandidateModal';
import { Profile } from '../Profile';
import { EditIcon } from '../Svgs';
import { TabPane, Tabs } from '../Tabs';
import { ActivityTimeline } from './ActivityTimeline';
import { EmailView } from './EmailView';
import { ProfileSummary } from './ProfileSummary';

const { SEQUOIA_PAPER, BLACK5ALPHA } = Colors.Static;
const LOCAL_STORAGE_TAB_KEY = 'pipeline_cand_summ_tab_v3';

export type ExtensionConfig = {
  logPipelineView: () => Promise<void>;
  miner: Extension.TabState['miner'];
};
type TabType = 'summary' | 'notes' | 'email';

interface ProfileDrawerProps<T> {
  profile: T;
  getRelatedProfileName: (id: number) => string;
  onProfileSaved: (profile?: T) => void;
  onDelete?: () => void;
  extensionConfig?: ExtensionConfig;
}

export const ProfileDrawer = ({
  profile,
  getRelatedProfileName,
  onProfileSaved,
  onDelete,
  extensionConfig,
}: ProfileDrawerProps<ExternalAPI.Profile>): ReactElement => {
  const { me, id: orgId, features } = useContext(OrganizationContext);
  const {
    actions: { getNotifsForProfile },
  } = useContext(NotificationContext);

  const profileNotifs = getNotifsForProfile(profile.id);

  const { id, endorsements, email, activities } = profile;
  const isInOrg = id !== -1;

  const [activeTab, setActiveTab] = useStateWithStorage<TabType>(LOCAL_STORAGE_TAB_KEY, 'summary');
  const [notes = [], { refresh: _refreshNotes }] = useResource<ExternalAPI.Note[]>(
    isInOrg ? `/api/notes/${profile.id}` : ''
  );
  const [emailInfo, { refresh: _refreshEmailInfo }] = useResource<PipelineAPI.EmailInteraction>(
    isInOrg ? `/api/${orgId}/profile/${profile.id}/emails` : '',
    { email }
  );
  const [editModalVisible, setVisible] = useState(false);

  const refreshNotes = async () => {
    _refreshNotes();
    extensionConfig?.logPipelineView();
  };

  const refreshEmailInfo = () => {
    if (isInOrg) {
      _refreshEmailInfo();
      extensionConfig?.logPipelineView();
    }
  };

  const hasScheduledEmails =
    (emailInfo?.scheduledEmails || []).some(e => moment(e.sentAt).isAfter(moment())) ||
    emailInfo?.stoppedCampaigns.some(isStoppedBounce) ||
    emailInfo?.stoppedCampaigns.some(isStoppedNoEmail) ||
    false;

  const renderedTab: TabType = activeTab === 'email' && hasScheduledEmails ? 'notes' : activeTab;

  return (
    <DrawerContainer>
      <FriendlyErrorBoundary description="profile drawer">
        <UpsertCandidateModal
          config={{ onDelete, onUpsert: onProfileSaved, profile }}
          visible={editModalVisible}
          setVisible={setVisible}
        />
        <Profile
          style={{ flex: 'unset', padding: '16px 24px', alignItems: 'flex-start' }}
          profile={profile}
          size="large"
          extra={{
            header: (
              <ClickableItem onClick={() => setVisible(s => !s)}>
                <EditIcon style={{ width: 20 }} color={BLACK5ALPHA} />
              </ClickableItem>
            ),
          }}
        />
        <Tabs
          active={renderedTab}
          onChange={c => {
            setActiveTab(c as TabType);
          }}
        >
          <TabPane
            label={
              <>
                {profileNotifs.reminder?.isActive && (
                  <NotificationDot style={{ marginLeft: -12 }} />
                )}
                summary
              </>
            }
            overrideKey="summary"
          >
            <ScrollingContainer style={{ paddingLeft: 24, paddingBottom: 24, flex: 1 }}>
              <ProfileSummary
                profile={profile}
                reminder={profileNotifs.reminder}
                editModalVisible={editModalVisible}
                onDelete={onDelete}
                setVisible={setVisible}
                emailConfig={{
                  hasScheduledEmails,
                  emailInfo,
                  onClickSendEmail: () => setActiveTab('email'),
                }}
                onProfileSaved={onProfileSaved}
                extensionConfig={extensionConfig}
                getRelatedProfileName={getRelatedProfileName}
              />
            </ScrollingContainer>
          </TabPane>
          <TabPane
            label={
              <>
                {!!profileNotifs.mentions.length && <NotificationDot style={{ marginLeft: -12 }} />}
                notes
              </>
            }
            overrideKey="notes"
          >
            <AddNoteSection
              profile={profile}
              onAddNote={profile => {
                if (!isInOrg) onProfileSaved(profile);
                OnEditNoteEvent.dispatch(profile.id);
                refreshNotes();
                setActiveTab('notes');
              }}
            />
            <ScrollingContainer>
              <ActivityTimeline
                profileNotifs={profileNotifs}
                endorsements={endorsements}
                notes={notes}
                getRelatedProfileName={getRelatedProfileName}
                activities={activities || []}
                emails={
                  emailInfo
                    ? [...emailInfo.myEmails, ...emailInfo.scheduledEmails, ...emailInfo.teamEmails]
                    : []
                }
                refresh={{ notes: refreshNotes, emails: refreshEmailInfo }}
                campaignEmail={email}
                candidate={profile}
                emailClient={me.emailClient}
              />
            </ScrollingContainer>
          </TabPane>
          {!hasScheduledEmails && features.includes('campaigns') ? (
            <TabPane label={'email'} overrideKey="email">
              <EmailView
                onClose={async (didAddToCampaign, ownerId) => {
                  if (didAddToCampaign) {
                    if (profile.status === 'Pool' || !profile.roleId) {
                      onProfileUpsert(
                        profile,
                        {
                          status: profile.status === 'Pool' ? 'Contacted' : profile.status,
                          ownerId: profile.status === 'Pool' ? ownerId : profile.ownerId,
                          roleId: didAddToCampaign.roleId,
                        },
                        onProfileSaved
                      );
                    }
                    refreshEmailInfo();
                    setActiveTab('notes');
                  } else {
                    setActiveTab('summary');
                  }
                }}
                candidate={profile}
                isExtensionView={!!extensionConfig}
              />
            </TabPane>
          ) : (
            <></>
          )}
        </Tabs>
      </FriendlyErrorBoundary>
    </DrawerContainer>
  );
};

const AddNoteSection: React.FunctionComponent<{
  profile: ExternalAPI.Profile;
  onAddNote: (profile: ExternalAPI.Profile) => void;
}> = ({ onAddNote, profile }) => {
  const [inputValue, setInputValue] = useState<RichMentionsEditorValue>({
    text: '',
    mentions: { teamIds: [] },
  });

  const onCreateNote = async () => {
    let updatedProfile = profile;
    if (profile.id === -1) {
      await onProfileUpsert(
        profile,
        pick(profile, ['name', 'email', 'identifier']),
        p => (updatedProfile = p)
      );
    }
    if (inputValue.text.length) {
      const text =
        inputValue.text.endsWith('<div><br></div>') && inputValue.text.length > 15
          ? inputValue.text.slice(0, -15)
          : inputValue.text;
      const note = await makeRequest<ExternalAPI.Note>(`/api/note/${updatedProfile.id}`, 'POST', {
        text,
        mentions: inputValue.mentions,
      });
      if (note.id) {
        onAddNote(profile);
        setInputValue({ text: '', mentions: { teamIds: [] } });
      }
    }
  };

  return (
    <AddNoteContainer>
      <img src="/icons/note.svg" alt="Sheet of paper" />
      <div style={{ flex: 1, display: 'flex', flexDirection: 'column', gap: 8 }}>
        <RichMentionsEditor
          value={inputValue}
          autoFocus
          placeholder={`Add a note for ${profile.name}...`}
          minHeight={100}
          onChange={setInputValue}
          onKeyDown={e => {
            if (
              e.key === 'Enter' &&
              (e.metaKey || e.ctrlKey) &&
              !(e.altKey || e.shiftKey) &&
              inputValue.text.length
            ) {
              onCreateNote();
            }
          }}
        />
        <SeqButton
          style={{ marginLeft: 'auto' }}
          disabled={!inputValue.text.length}
          onClick={onCreateNote}
          intent="primary"
        >
          Add Note
        </SeqButton>
      </div>
    </AddNoteContainer>
  );
};

export const SocialLinks: React.FC<{ identifier: string; links?: CoreAPI.ProfileLinks }> = ({
  identifier,
  links,
}) => (
  <>
    <IconLinkButton size="small" link={identifier} type="linkedIn" />
    {!!links && (
      <>
        <IconLinkButton size="small" link={links.twitter} type="twitter" />
        <IconLinkButton size="small" link={links.github} type="github" />
        {links.designPortfolio && (
          <IconLinkButton size="small" link={links.designPortfolio} type="designPortfolio" />
        )}
      </>
    )}
  </>
);

const DrawerContainer = styled.div`
  background: ${SEQUOIA_PAPER};
  ${FlexFillingColCSS}
`;

const AddNoteContainer = styled.div`
  padding: 24px 24px 16px 20px;
  display: flex;
  align-items: flex-start;
  gap: 12px;
`;
