import { TreeSelect } from 'antd';

export const getUndeletedTags = (tags: { [name: string]: CoreAPI.ProfileTagInfo }): string[] => {
  return Object.entries(tags)
    .map(([k, v]) => (v.deletedAt ? null : k.toLowerCase()))
    .filter(Boolean) as string[];
};

export const extractKeysAndSubKeys = (inp: ConfigJSON.PeopleTagSet) => {
  const keys: string[] = [];
  for (const key of Object.keys(inp)) {
    keys.push(key);
    if (inp[key].subtags) {
      keys.push(...extractKeysAndSubKeys(inp[key].subtags as ConfigJSON.PeopleTagSet));
    }
  }
  return keys;
};

export function flattenTagsAndSubTags(tags: ConfigJSON.PeopleTagSet) {
  const result = Object.keys(tags);
  for (const val of Object.values(tags)) {
    if (val.subtags) {
      result.push(...flattenTagsAndSubTags(val.subtags));
    }
  }
  return result;
}

const { TreeNode } = TreeSelect;
export const buildTagTreeNodes = (
  realm: string,
  options?: { [name: string]: ConfigJSON.PeopleTag }
) => {
  if (!options) {
    return;
  }

  return Object.entries(options).map(([tagName, peopleTag]) => (
    <TreeNode title={tagName} key={tagName} value={tagName}>
      {buildTagTreeNodes(realm, peopleTag.subtags)}
    </TreeNode>
  ));
};
