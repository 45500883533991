import { SafetyOutlined, WarningOutlined } from '@ant-design/icons';
import React, { CSSProperties, ReactNode, useContext } from 'react';
import { Link, NavLink, useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';
import { MISSING_PERMISSIONS } from '../../App';
import { NotificationContext } from '../../contexts/Notification';
import { OrganizationContext } from '../../contexts/Organization';
import { isInternal } from '../../Helpers';
import { ClickableItem, Dot, NotificationDot } from '../../shared/Common';
import { EXTERNAL_HOST } from '../../shared/helpers';
import { InitialsOrProfileImage } from '../../shared/ProfileImage';
import { makeRequest } from '../../shared/Resource';
import { EnvelopeIcon } from '../../shared/Svgs';
import { Colors, hexWithOpacity } from '../../shared/Theme';
import { BaseContainer, HorizontalDivider, LightTooltip, SIDEBAR_WIDTH } from '../Common';
import { AccountSettingsModal, showAccountSettingsModal } from '../modals/AccountSettingsModal';
import { SunsetModal } from '../modals/SunsetModal';
import { NewFeaturesModal } from '../popovers/NewFeaturesModal';
import {
  BookIcon,
  DiscoveryIcon,
  IconContainer,
  PipelineIcon,
  ScanIcon,
  SettingsIcon,
  TeamIcon,
  TicketIcon,
  WorkIcon,
} from '../Svgs';
import { CampaignsSubNavigation } from './CampaignsSubNavigation';
import { FeedbackButton } from './FeedbackButton';
import { OrganizationSelect } from './OrganizationSelect';
import { SettingsButton } from './SettingsButton';

const { BLACK7ALPHA, SEQUOIA_BLACK, SEQUOIA_GREEN, SEQUOIA_DARK_GREEN, WARNING_TINT } =
  Colors.Static;

interface SequoiaTool {
  name: string;
  icon: (selected: boolean) => ReactNode;
}

const TOOLS: SequoiaTool[] = [
  {
    name: 'referrals',
    icon: (selected: boolean) => (
      <DiscoveryIcon color={selected ? SEQUOIA_DARK_GREEN : undefined} />
    ),
  },
  {
    name: 'network',
    icon: (selected: boolean) => <ScanIcon color={selected ? SEQUOIA_DARK_GREEN : undefined} />,
  },
  {
    name: 'pipeline',
    icon: (selected: boolean) => <PipelineIcon color={selected ? SEQUOIA_DARK_GREEN : undefined} />,
  },
  {
    name: 'campaigns',
    icon: (selected: boolean) => <EnvelopeIcon color={selected ? SEQUOIA_DARK_GREEN : undefined} />,
  },
  {
    name: 'leads',
    icon: (selected: boolean) => <TicketIcon color={selected ? SEQUOIA_DARK_GREEN : undefined} />,
  },
  {
    name: 'resources',
    icon: (selected: boolean) => <BookIcon color={selected ? SEQUOIA_DARK_GREEN : undefined} />,
  },
  {
    name: 'team',
    icon: (selected: boolean) => <TeamIcon color={selected ? SEQUOIA_DARK_GREEN : undefined} />,
  },
  {
    name: 'companies',
    icon: (selected: boolean) => <WorkIcon color={selected ? SEQUOIA_DARK_GREEN : undefined} />,
  },
  {
    name: 'campaign statistics',
    icon: (selected: boolean) => <SettingsIcon color={selected ? SEQUOIA_DARK_GREEN : undefined} />,
  },
];

export const FeatureSidebar: React.FunctionComponent<{
  children: ReactNode;
}> = ({ children }) => {
  const { id: orgId, me, features } = useContext(OrganizationContext);
  const myTools = features;
  const missingPermissions = window.localStorage.getItem(MISSING_PERMISSIONS);

  return (
    <BaseContainer>
      <SidebarContainer>
        <SidebarPadding>
          <Link style={{ cursor: 'pointer' }} to={`/${orgId}/home`}>
            <ClickableItem style={{ paddingTop: 12 }}>
              <SequoiaBrand />
            </ClickableItem>
          </Link>
        </SidebarPadding>

        {missingPermissions === 'true' && <MissingPermissions />}

        <SunsetModal />

        {isInternal(me) ? (
          <>
            <SidebarPadding>
              <FeatureSection
                title="companies"
                linkTo={`/companies`}
                matchPath={new RegExp('/companies')}
              />
            </SidebarPadding>
            <SidebarPadding>
              <FeatureSection
                title="campaign statistics"
                linkTo={`/campaign-statistics`}
                matchPath={new RegExp('/campaign-statistics')}
              />
            </SidebarPadding>
            <AdminOrgControlContainer>
              <div
                style={{
                  padding: '6px 0 6px 2px',
                  border: `1px solid ${hexWithOpacity(SEQUOIA_GREEN, 0.2)}`,
                  background: 'white',
                }}
              >
                <OrganizationSelect />
              </div>
              {orgId !== me.organizationId && (
                <AdminOrgControlScrollWrap>
                  <OrganizationNavigation />
                  <div
                    style={{
                      margin: '6px 12px 12px 0',
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 12,
                    }}
                  >
                    <HorizontalDivider />
                    <SettingsButton />
                  </div>
                </AdminOrgControlScrollWrap>
              )}
            </AdminOrgControlContainer>
          </>
        ) : (
          <OrgScrollWrap>
            <OrganizationNavigation />
          </OrgScrollWrap>
        )}
        <SidebarPadding style={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
          <SandBoxEmailNotice />
          <FeedbackButton />
          {orgId === me.organizationId && <SettingsButton />}
          <HorizontalDivider />
          <UserInfo />
        </SidebarPadding>
      </SidebarContainer>
      <div style={{ flex: 1, overflowY: 'auto', height: '100%', maxHeight: '100vh' }}>
        {!isInternal(me) && myTools.length === 0 ? <NoFeaturesLanding /> : children}
      </div>
    </BaseContainer>
  );
};

const MissingPermissions = () => (
  <SidebarPadding>
    <LightTooltip
      overlay="Gmail permissions will need to be granted in order to start emailing candidates. Click here to grant permissions."
      overlayStyle={{ width: 300 }}
      placement="right"
    >
      <ClickableItem
        onClick={() => window.location.assign(`${EXTERNAL_HOST}/api/sourcing/auth/google?email=`)}
        style={{
          background: hexWithOpacity('#ffffff', 0.7),
          border: `1.5px solid ${WARNING_TINT}`,
          fontWeight: 500,
          padding: '8px 12px',
          borderRadius: 5,
          color: WARNING_TINT,
        }}
      >
        <WarningOutlined style={{ color: WARNING_TINT, fontSize: 18, marginRight: 8 }} />
        Missing Permissions
      </ClickableItem>
    </LightTooltip>
  </SidebarPadding>
);

export const SequoiaBrand = (props: { style?: CSSProperties }) => {
  return (
    <div style={{ ...props.style, display: 'flex', marginBottom: 24, gap: 6 }}>
      <img src="/sequoia-brand-text.svg" alt="Sequoia" height={20} />
      <img height={20} src={'/icons/logo.svg'} alt="Logo" />
    </div>
  );
};

const OrganizationNavigation = () => {
  const {
    notifications: { features: featureNotifs },
  } = useContext(NotificationContext);
  const { id: orgId, me, features } = useContext(OrganizationContext);
  const myTools = features;

  return (
    <>
      {myTools.includes('network') && (
        <FeatureSection
          title="network"
          linkTo={`/${orgId}/network`}
          matchPath={new RegExp('network/*')}
          hasNotifications={!!featureNotifs['network'].length}
        />
      )}
      <FeatureSection
        title="pipeline"
        linkTo={`/${orgId}/pipeline`}
        matchPath={new RegExp('pipeline/*')}
      />
      {myTools.includes('leads') && (
        <FeatureSection
          title="leads"
          linkTo={`/${orgId}/leads`}
          matchPath={new RegExp('leads/*')}
          hasNotifications={!!featureNotifs['leads'].length}
        />
      )}
      {myTools.includes('campaigns') && (
        <FeatureSection
          title="campaigns"
          linkTo={`/${orgId}/campaigns`}
          matchPath={new RegExp(`campaigns/*`)}
          hasNotifications={!!featureNotifs['campaigns'].length}
        >
          <CampaignsSubNavigation />
        </FeatureSection>
      )}
      {myTools.includes('resources') && (
        <FeatureSection
          title="resources"
          linkTo={`/${orgId}/resources`}
          matchPath={new RegExp('resources/*')}
          hasNotifications={!!featureNotifs['resources'].length}
        />
      )}
      {!me.internalTalentId && (
        <FeatureSection title="team" linkTo={`/${orgId}/team`} matchPath={new RegExp('team/*')} />
      )}
      {myTools.length === 0 && (
        <div style={{ opacity: 0.5, lineHeight: '40px' }}>No product features</div>
      )}
      <div style={{ flex: 1 }} />
    </>
  );
};

const NoFeaturesLanding: React.FunctionComponent = () => {
  return (
    <LandingContainer>
      <img
        style={{ width: '30%', maxWidth: 400 }}
        src="/icons/null-state-green.svg"
        alt="plant, paperwork and coffee"
      />
      <div style={{ padding: 24 }}>
        Looks like there is nothing for you to do at this time. Come back soon!
      </div>
    </LandingContainer>
  );
};

const FeatureSection: React.FunctionComponent<{
  matchPath: RegExp;
  linkTo: string;
  title: string;
  hasNotifications?: boolean;
  children?: ReactNode;
}> = ({ matchPath, linkTo, title, hasNotifications, children }) => {
  const history = useHistory();
  const selected = history.location.pathname.match(matchPath);

  const { icon } = TOOLS.filter(tool => tool.name === title)[0];

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <ClickableItem style={{ lineHeight: '40px' }}>
        <NavLink to={linkTo} style={{ display: 'flex', width: '100%' }}>
          <IconContainer>{icon(!!selected)}</IconContainer>
          <div
            style={{
              color: selected ? SEQUOIA_DARK_GREEN : SEQUOIA_BLACK,
              marginLeft: 12,
              fontWeight: selected ? 700 : 400,
              textTransform: 'capitalize',
              whiteSpace: 'nowrap',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {title}
            {hasNotifications && <NotificationDot />}
          </div>
          <div style={{ flex: 1 }} />
        </NavLink>
      </ClickableItem>
      {selected ? children : <></>}
    </div>
  );
};

export const logout = async () => {
  await makeRequest('/api/sourcing/auth/logout');
  window.location.assign('/sourcing/auth');
};

const UserInfo: React.FunctionComponent<{}> = props => {
  const { me } = React.useContext(OrganizationContext);

  return (
    <div style={{ display: 'flex', width: '100%', paddingTop: 4, alignItems: 'center' }}>
      <NewFeaturesModal />
      <InitialsOrProfileImage widthHeight={40} user={me} />
      <div style={{ flex: 1, marginLeft: 8 }}>
        <div>{me.name}</div>
        <div style={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <AccountSettingsModal />
          <UserInfoLink onClick={() => showAccountSettingsModal(me.id)}>My Account</UserInfoLink>
          <Dot color={SEQUOIA_BLACK} />
          <UserInfoLink onClick={logout}>Sign Out</UserInfoLink>
        </div>
      </div>
    </div>
  );
};

const SandBoxEmailNotice: React.FunctionComponent<{}> = props => {
  const { settings } = React.useContext(OrganizationContext);

  if (!settings.sandboxEmails) {
    return <></>;
  }

  return (
    <LightTooltip
      placement="right"
      overlayStyle={{ maxWidth: 400 }}
      overlay={
        'Emails sent to candidates from this organization will be sent to a developers email account, not to the candidate. BCCs and CCs will not be sent.'
      }
    >
      <SecondarySidebarItemContainer style={{ color: WARNING_TINT }}>
        <SafetyOutlined style={{ fontSize: 22 }} />
        Emails sandboxed
      </SecondarySidebarItemContainer>
    </LightTooltip>
  );
};

const UserInfoLink = styled.a`
  font-size: 12px;
  line-height: 17px;
  color: ${BLACK7ALPHA};
  &:hover {
    color: ${SEQUOIA_GREEN};
  }
`;

const SidebarContainer = styled.div`
  width: ${SIDEBAR_WIDTH}px;
  max-width: ${SIDEBAR_WIDTH}px;
  height: 100%;
  display: flex;
  padding: 24px 0;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  overflow: hidden;
  user-select: none;
`;

const SidebarPadding = styled.div`
  padding-left: 24px;
  width: 100%;
`;

const OrgScrollWrap = styled.div`
  padding-left: 24px;
  width: 100%;
  margin: 36px 0;
  flex: 1;
  flex-basis: content;
  min-height: 0;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 0.4em;
  }
  &::-webkit-scrollbar-thumb {
    overflow: visible;
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.25);
  }
`;

const LandingContainer = styled.div`
  background: white;
  height: calc(100vh - 48px);
  margin: 24px;
  text-align: center;
  padding-top: 20%;
`;

const AdminOrgControlContainer = styled.div`
  width: calc(${SIDEBAR_WIDTH}px - 24px);
  margin-left: 24px;
  margin-top: 12px;
  min-height: 0;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const AdminOrgControlScrollWrap = styled.div`
  padding: 2px 0 2px 12px;
  margin-bottom: 12px;
  border: 1px solid ${hexWithOpacity(SEQUOIA_GREEN, 0.2)};
  border-top: none;
  min-height: 0;
  flex: 1;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  flex-basis: content;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 0.4em;
  }
  &::-webkit-scrollbar-thumb {
    overflow: visible;
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.25);
  }
`;

export const SecondarySidebarItemContainer = styled.div`
  display: flex;
  gap: 12px;
  width: 100%;
  align-items: center;
  font-size: 12px;
`;
