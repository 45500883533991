import { message, Select, Select as SelectAntd, Tag } from 'antd';
import React, { useContext } from 'react';
import { SeqButton } from '../components/Button';
import { HorizontalDivider } from '../components/Common';
import { InputTitle, SeqTextArea } from '../components/Input';
import { SeqModal } from '../components/Modal';
import { H3Mono } from '../components/Text';
import { OrganizationContext } from '../contexts/Organization';

export const RecommendationButton: React.FC<{
  options: ConfigJSON.ContentfulTitleData[];
  onSave: (
    changes: OrganizationAPI.ContentRecommendation
  ) => Promise<OrganizationAPI.ContentRecommendation>;
  currentViewedOrgId: number;
}> = ({ options, onSave, currentViewedOrgId }) => {
  const { me, team } = useContext(OrganizationContext);
  const initialState: OrganizationAPI.ContentRecommendation = {
    id: -1,
    articles: [],
    senderId: me.id,
    recipientIds: [],
    comment: '',
    organizationId: currentViewedOrgId,
  };

  const [open, setOpen] = React.useState<boolean>(false);
  const [recommendation, setRecommendation] =
    React.useState<OrganizationAPI.ContentRecommendation>(initialState);

  const tagRender = (label: string, value: string) => {
    return (
      <Tag
        closable={true}
        onClose={() =>
          setRecommendation({
            ...recommendation,
            articles: recommendation.articles.filter(({ url }) => url !== value),
          })
        }
        style={{ fontSize: 12 }}
      >
        {label}
      </Tag>
    );
  };

  const onClose = () => {
    setOpen(o => !o);
    setRecommendation(initialState);
  };

  const onSend = async () => {
    const saved = await onSave(recommendation);
    if ('id' in saved) {
      message.success('Recommendation sent to founder.');
      onClose();
    } else {
      message.error('We had issues sending this recommendation. Please try again later.');
    }
  };

  const disabled = !recommendation.recipientIds.length || !recommendation.articles.length;

  return (
    <>
      <SeqButton intent="primary" onClick={() => setOpen(true)}>
        Send a Recommendation
      </SeqButton>
      <SeqModal open={open} width={525} footer={null} closable={false} onClose={onClose}>
        <div style={{ padding: 24, display: 'flex', flexDirection: 'column', gap: 12 }}>
          <H3Mono>Send a Recommendation</H3Mono>
          <HorizontalDivider />
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <InputTitle title="Recipient" size="middle" />
            <Select
              mode="multiple"
              style={{ width: 200, marginLeft: 18, flex: 1 }}
              value={recommendation.recipientIds}
              onChange={recipientIds =>
                setRecommendation({
                  ...recommendation,
                  recipientIds,
                })
              }
            >
              {team.map(user => (
                <Select.Option key={user.name} value={user.id}>
                  {user.isFounder ? `${user.name} (Founder)` : user.name}
                </Select.Option>
              ))}
            </Select>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: 20 }}>
            <InputTitle title="Resources" size="middle" />
            <SelectAntd
              mode="multiple"
              allowClear
              onClear={() => setRecommendation({ ...recommendation, articles: [] })}
              tagRender={tagProps =>
                tagRender(tagProps.label?.toString() || '', tagProps.value.toString())
              }
              style={{ width: 220, marginLeft: 12, flex: 1 }}
              value={recommendation.articles.map(({ url }) => url)}
              filterOption={(input, option: any) =>
                !!option && option.label.toLowerCase().includes(input.toLowerCase())
              }
              options={options
                .sort((a, b) => a.title.localeCompare(b.title))
                .filter(o =>
                  recommendation.recipientIds.every(
                    id => team.find(member => member.id === id)?.isFounder
                  )
                    ? true
                    : !o.foundersOnly
                )
                .map(resource => ({ value: resource?.url, label: resource?.title }))}
              onChange={articleURLs =>
                setRecommendation({
                  ...recommendation,
                  articles: articleURLs.map(url => ({
                    url,
                    title: options.find(o => o.url === url)?.title || '',
                  })),
                })
              }
            />
          </div>
          <SeqTextArea
            style={{ width: '100%' }}
            title="Comment"
            onChange={e => setRecommendation({ ...recommendation, comment: e.target.value })}
            value={recommendation.comment}
          />
          <HorizontalDivider />
          <div style={{ display: 'flex' }}>
            <div style={{ flex: 1 }} />
            <SeqButton intent="primary" disabled={disabled} onClick={onSend}>
              Send
            </SeqButton>
          </div>
        </div>
      </SeqModal>
    </>
  );
};
