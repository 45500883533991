import { notification } from 'antd';
import { CSSProperties, useContext } from 'react';
import { SEQUOIA_ORG_ID } from '../App';
import { OrganizationContext } from '../contexts/Organization';
import { makeRequest } from '../shared/Resource';
import { IconButton } from './Button';
import { LightTooltip } from './Common';
import { NotificationIcon, NotificationSilentIcon } from './Svgs';

export const NotificationButton: React.FunctionComponent<{
  roleId: number;
  style?: CSSProperties;
}> = ({ roleId, style }) => {
  const { me, actions, roles, team } = useContext(OrganizationContext);
  const roleName = roles.find(r => r.id === roleId)?.name || 'this role';
  const roleReviewerId = roles.find(r => r.id === roleId)?.reviewerId;
  const reviewer = [me, ...team].find(({ id }) => id === roleReviewerId);
  const currentlyPausedForRole = !!reviewer?.settings.pauseNotifsForRoles?.includes(roleId);

  const onClick = async () => {
    if (!reviewer) {
      return;
    }

    const newSettings: Partial<ExternalAPI.UserSettings> = {
      pauseNotifsForRoles: currentlyPausedForRole
        ? (reviewer.settings?.pauseNotifsForRoles || []).filter(rId => rId !== roleId)
        : [...(reviewer.settings.pauseNotifsForRoles || []), roleId],
    };
    const resp = await makeRequest<{ message: string }>(
      `/api/user/${roleReviewerId}/settings`,
      'PUT',
      newSettings
    );
    if (resp.message) {
      notification.success({
        key: 'lead-list-notifs',
        message: (
          <span>
            Email notifications about leads for <b>{roleName}</b> have been{' '}
            {currentlyPausedForRole ? 'enabled' : 'disabled'}.
          </span>
        ),
      });
      actions.refresh();
    }
  };

  return (
    <LightTooltip
      placement={'right'}
      overlayStyle={{ maxWidth: 250 }}
      overlay={
        <div>
          Toggle whether{' '}
          {me.organizationId === SEQUOIA_ORG_ID
            ? 'the founder'
            : me.id === roleReviewerId
              ? 'you'
              : `${reviewer?.name || 'your teammate'}`}{' '}
          will receive notification emails about leads for <b>{roleName}</b>.
        </div>
      }
    >
      <IconButton
        size="small"
        intent={currentlyPausedForRole ? 'secondary' : 'primary'}
        disabled={!reviewer}
        style={style}
        onClick={onClick}
      >
        {currentlyPausedForRole ? <NotificationSilentIcon /> : <NotificationIcon />}
      </IconButton>
    </LightTooltip>
  );
};
