import { default as React, useContext } from 'react';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components/macro';
import { SEQUOIA_ORG_ID } from '../App';
import { Column, PageContainer } from '../components/Common';
import { OrganizationContext } from '../contexts/Organization';
import { useResource } from '../shared/Resource';
import { Colors } from '../shared/Theme';
import { RecommendedSection } from './RecommendedSection';
import { ResourceTableView } from './ResourceTableView';

interface ContentfulAPIResponse {
  contentfulTitles: ConfigJSON.ContentfulTitleData[];
  contentful: any;
}

export const PageResources: React.FC<
  RouteComponentProps<{
    organizationId: string;
  }>
> = props => {
  const { me } = useContext(OrganizationContext);
  const { organizationId } = props.match.params;
  const [recommendations = [], ops] = useResource<OrganizationAPI.ContentRecommendation[]>(
    `/api/content-recommendations/${organizationId}`
  );
  const [contentfulResponse] = useResource<ContentfulAPIResponse>(
    `/api/founder-resources/contentful`
  );
  const { tabNames, contentful, contentfulTitles } = React.useMemo(() => {
    const contentful = contentfulResponse?.contentful;
    return {
      tabNames: !!contentful
        ? Object.keys(contentful)?.sort((a, b) => contentful[b].length - contentful[a].length)
        : [],
      contentful: (!!contentful ? Object.values(contentful).flat() : []) as any[],
      contentfulTitles: contentfulResponse?.contentfulTitles || [],
    };
  }, [contentfulResponse]);

  const isAdmin = me.organizationId === SEQUOIA_ORG_ID;

  return (
    <PageContainer style={{ display: 'flex', minHeight: 0, flexDirection: 'row', paddingRight: 0 }}>
      <ResourceTableView
        tabNames={tabNames}
        contentful={contentful}
        contentfulTitles={contentfulTitles}
      />
      <SectionContainer>
        <RecommendedSection
          organizationId={Number(organizationId)}
          contentfulTitles={contentfulTitles}
          recommendations={recommendations}
          recommendationOps={ops}
          isAdmin={isAdmin}
        />
      </SectionContainer>
    </PageContainer>
  );
};

const SectionContainer = styled(Column)`
  flex: 1;
  max-width: 650px;
  min-width: 250px;
  border-left: 1px solid ${Colors.Static.SEQUOIA_BLACK};
  padding-left: 24px;
`;

// Duplicates of both below in founder-resources.ts
export const getNotionIdFromUrl = (url: string, altUrl?: boolean) =>
  altUrl ? url.split('notion.site/')[1] : url.split('https://www.notion.so/')[1];

export const getLoomIdFromUrl = (url: string) => url.split('https://www.loom.com/share/')[1];
