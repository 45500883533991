import React, { CSSProperties } from 'react';
import { Colors } from '../shared/Theme';
import { ActionButton } from './Button';
import { MessageRowContainer } from './Common';
import { logout } from './navigation/FeatureSidebar';

export const MissingPermissionHeader: React.FunctionComponent<{
  warning: ExternalAPI.UserWarning;
  style?: CSSProperties;
}> = ({ warning, style }) => {
  let message = '';
  switch (warning) {
    case 'Missing email permission':
      message = "It looks like we're missing permission to send emails on your behalf.";
      break;
    case 'No refresh token':
      message = `Google Authentication info must be updated before continuing.`;
      break;
    case 'Not yet registered':
      message = 'Founder has not yet logged into this platform.';
      break;
  }

  return (
    <MessageRowContainer
      style={{
        background: Colors.Static.SEQUOIA_BRIGHT_RED,
        color: 'white',
        justifyContent: 'space-between',
        ...style,
      }}
    >
      {message}
      {warning !== 'Not yet registered' && (
        <ActionButton color="white" hoverColor={Colors.Static.BLACK8ALPHA} onClick={logout}>
          Update
        </ActionButton>
      )}
    </MessageRowContainer>
  );
};
