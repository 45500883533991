import { Spin } from 'antd';
import { RouteComponentProps } from 'react-router';
import { PageContainerWithBackNav } from '../components/Common';
import { PageLost } from '../components/navigation/PageLost';
import { useResource } from '../shared/Resource';

interface LoomResourceResponse {
  loomHtml: string;
  error?: string;
}

const LOST_RESOURCE_MESSAGE = `Oops! Looks like this resource isn't currently available. Try coming back later!`;

export const PageLoomResource = (
  props: RouteComponentProps<{
    organizationId: string;
    loomId: string;
    resourceId: string;
  }>
) => {
  const { loomId } = props.match.params;
  const qs = new URLSearchParams(props.location.search);
  const from = qs.get('from');

  const [loomResponse] = useResource<LoomResourceResponse>(
    `/api/founder-resources/loom-videos/${loomId}`
  );

  return (
    <PageContainerWithBackNav backTo={{ pageName: from || 'Resources' }}>
      {loomResponse && 'error' in loomResponse ? (
        <PageLost alternateTitle={LOST_RESOURCE_MESSAGE} />
      ) : loomResponse ? (
        <div
          style={{ margin: '25px 200px 25px 200px' }}
          dangerouslySetInnerHTML={{ __html: loomResponse.loomHtml }}
        />
      ) : (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            height: '100%',
            justifyContent: 'center',
          }}
        >
          <Spin size="large" />
        </div>
      )}
    </PageContainerWithBackNav>
  );
};
