import { Select } from 'antd';
import React, { useContext } from 'react';
import { useHistory } from 'react-router';
import { SEQUOIA_ORG_ID } from '../../App';
import { OrganizationContext } from '../../contexts/Organization';

const { Option } = Select;

export const OrganizationSelect: React.FunctionComponent<{}> = () => {
  const { all, me, ...selected } = useContext(OrganizationContext);
  const history = useHistory();

  const onSelection = (newSelected?: number) => {
    if (newSelected && newSelected !== selected.id) {
      routePageForNewSelection(newSelected);
    }
  };

  const orgOptions = all.filter(a => !a.inactive).sort((a, b) => a.name.localeCompare(b.name));

  const routePageForNewSelection = (newOrgId: number) => {
    const path = history.location.pathname;
    const pathSplit = path.split('/');
    const feature: ExternalAPI.Feature | string = pathSplit[2];
    if (!feature) {
      history.push(`/${newOrgId}/home`);
      return;
    }

    switch (feature.toLowerCase()) {
      case 'pipeline':
        history.push(`/${newOrgId}/pipeline`);
        break;
      case 'referrals':
        history.push(`/${newOrgId}/referrals`);
        break;
      case 'campaigns':
        history.push(`/${newOrgId}/campaigns`);
        break;
      case 'leads':
        history.push(`/${newOrgId}/leads`);
        break;
      default:
        history.push(`/${newOrgId}/home`);
    }
  };

  return (
    <Select
      showSearch
      style={{ width: '100%' }}
      dropdownStyle={{ fontFamily: 'Unica77' }}
      bordered={false}
      placeholder="Explore a company"
      onChange={e => {
        onSelection(orgOptions.find(o => o.name === e)?.id);
      }}
      value={selected.id === SEQUOIA_ORG_ID ? undefined : selected.name}
    >
      {orgOptions.map(o => (
        <Option value={o.name} key={o.name}>
          {o.name}
        </Option>
      ))}
    </Select>
  );
};
