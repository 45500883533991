import { FilterOutlined } from '@ant-design/icons';
import * as QueryString from 'query-string';
import React, { useContext, useMemo } from 'react';
import { RouteComponentProps, useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';
import { filterFromLabelClick } from '../ChartHelpers';
import { BarChartSlider } from '../components/BarChartSlider';
import { CandidatesTable } from '../components/CandidatesTable';
import { HorizontalDivider, PageContainer, SeqDrawer } from '../components/Common';
import { ConversationDrawerContent } from '../components/ConversationDrawerContent';
import { MissingPermissionHeader } from '../components/MissingPermissionsHeader';
import { CohortSelector } from '../components/selects/CohortSelector';
import { Select } from '../components/selects/Select';
import { OrganizationContext } from '../contexts/Organization';
import { canManageCampaign } from '../Helpers';
import { useListInteractions } from '../hooks/useListInteractions';
import { PrevNextArrows } from '../hooks/useProfileDrawer';
import { useVisibleCampaigns } from '../hooks/useVisibleCampaigns';
import { usePageTitle } from '../shared/Common';
import { activeRole } from '../shared/profile-helpers';
import { useResource } from '../shared/Resource';
import { Colors } from '../shared/Theme';
import { useQueryPersistedState } from '../shared/useQueryPersistedState';
import { CampaignHeaderRow } from './PageListCampaigns';

const { SEQUOIA_BLACK, SEQUOIA_LIGHT_GRAY, LIGHT_GRAY1, SEQUOIA_PAPER } = Colors.Static;

export interface CampaignMemberFilter {
  label: string;
  execute: (referral: CampaignAPI.CampaignMember) => boolean;
}

export const PageCampaignDetails = (props: RouteComponentProps<{ campaignId?: string }>) => {
  const history = useHistory();
  const location = useLocation();

  const { me, actions, all, id } = useContext(OrganizationContext);
  const { campaignId } = props.match.params;

  const [searchTerm, setSearchTerm] = useQueryPersistedState<string>({
    queryKey: 'q',
    defaults: { q: '' },
  });
  const [candidates, setCandidates] = React.useState<CampaignAPI.CampaignMember[]>([]);

  const [filterString, setFilterString] = useQueryPersistedState<
    CampaignMemberFilter['label'] | undefined
  >({
    encode: qs => ({ filter: JSON.stringify(qs) }),
    decode: qs => (qs.filter ? JSON.parse(qs.filter) : undefined),
  });

  const filter = useMemo(
    () => (filterString ? filterFromLabelClick(filterString) : undefined),
    [filterString]
  );

  const {
    focusedId: drawerCandidateId,
    setFocusedId: setDrawerCandidateId,
    shiftFocusedId,
    setItemIds,
    onKeyDown,
    itemPos,
  } = useListInteractions();

  // We have the campaign in the `me` context, but we fetch it again to load it's candidates
  const [campaign, { refresh }] = useResource<CampaignAPI.CampaignWithContents | { error: string }>(
    `/api/sourcing/campaign/${campaignId}`
  );

  usePageTitle(
    campaign ? `${(campaign as CampaignAPI.Campaign).name}` : 'Campaign Details',
    false,
    { all, id }
  );

  React.useEffect(() => {
    const query = QueryString.parse(location.search) as { openConversation?: number };
    if (query.openConversation && campaign && !('error' in campaign)) {
      const candidate = campaign.candidates.find(r => r.id === Number(query.openConversation));
      if (candidate) {
        setDrawerCandidateId(candidate.id);
      }
    }
  }, [campaign, location.search, setDrawerCandidateId]);

  React.useEffect(() => {
    if (campaign && !('error' in campaign)) {
      setItemIds(campaign?.candidates.map(c => c.id) || []);
    }
  }, [campaign, setItemIds]);

  const onCampaignChanged = () => {
    refresh();
    actions.refresh();
  };

  const onClose = () => {
    setDrawerCandidateId(0);
  };

  if (!campaign) {
    return <div></div>;
  }

  if ('error' in campaign) {
    return (
      <div>
        Sorry, you do not have permission to view this campaign or it could not be loaded.
        <div>Error: {campaign.error}</div>
      </div>
    );
  }

  const filteredCandidates = candidates.filter(
    c =>
      (!filter || filter.execute(c)) &&
      (!searchTerm ||
        c.profile.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        activeRole(c.profile)?.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        activeRole(c.profile)?.company.name.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  return (
    <PageContainer>
      <CampaignDetailsHeader
        search={{
          value: searchTerm,
          onChange: value => setSearchTerm(value),
        }}
        selectedCampaign={campaign}
        onCohortChange={setCandidates}
        onCampaignSelected={campaign => {
          history.push(
            `/${campaign.organizationId}/campaigns/sender/${campaign.senderId}/campaigns/${campaign.id}`
          );
        }}
      />
      {me.warning && <MissingPermissionHeader warning={me.warning} />}
      <BarChartSlider
        candidates={candidates}
        onSelectFilter={setFilterString}
        extra={
          <StatsTable
            candidates={candidates}
            filterLabel={filter?.label}
            setFilter={setFilterString}
          />
        }
      />
      <CandidatesTable
        candidates={filteredCandidates}
        onRefresh={onCampaignChanged}
        campaign={campaign}
        onCandidateClicked={candidate => {
          setDrawerCandidateId(
            candidate.id,
            filteredCandidates.map(c => c.id)
          );
        }}
      />
      <div
        onKeyDown={e => {
          if (e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
            onClose();
          }
        }}
      >
        <SeqDrawer
          width={650}
          open={!!drawerCandidateId}
          onClose={onClose}
          styles={{ body: { background: SEQUOIA_PAPER, overflow: 'visible' } }}
        >
          {drawerCandidateId ? (
            <>
              <PrevNextArrows itemPos={itemPos} onClick={shiftFocusedId} />
              <div onKeyDown={onKeyDown}>
                <ConversationDrawerContent
                  key={drawerCandidateId}
                  canManage={canManageCampaign(me, campaign)}
                  candidate={campaign.candidates.find(c => c.id === drawerCandidateId)!}
                  onReload={closeDrawer => {
                    onCampaignChanged();
                    closeDrawer && onClose();
                  }}
                />
              </div>
            </>
          ) : (
            <div />
          )}
        </SeqDrawer>
      </div>
    </PageContainer>
  );
};

const StatsTable: React.FunctionComponent<{
  candidates: CampaignAPI.CampaignMember[];
  filterLabel: CampaignMemberFilter['label'] | undefined;
  setFilter: (filter?: CampaignMemberFilter['label']) => void;
}> = ({ candidates, setFilter, filterLabel }) => {
  const rawCandidatesReplied = candidates.filter(c => c.candidateRespondedAt).length;
  const rawCandidatesRepliedPositively = candidates.filter(
    c => c.responseSentiment === 'Positive'
  ).length;

  const percentFrom = (raw: number) => {
    return candidates.length > 0 ? Math.round((raw * 100) / candidates.length) : 0;
  };

  const entries: { label: string; rawVal: number; percentage?: string }[] = [];
  entries.push({
    label: 'Contacted',
    rawVal: candidates.length,
  });
  entries.push({
    label: 'Replied',
    rawVal: rawCandidatesReplied,
    percentage: `${percentFrom(rawCandidatesReplied)}%`,
  });
  entries.push({
    label: 'Replied Positively',
    rawVal: rawCandidatesRepliedPositively,
    percentage: `${percentFrom(rawCandidatesRepliedPositively)}%`,
  });

  return (
    <div
      style={{
        minWidth: 350,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        padding: '0 24px',
      }}
    >
      {entries.map((e, idx) => {
        const fontWeight =
          e.label === filterLabel || (e.label === 'Contacted' && !filterLabel) ? 500 : 400;

        return (
          <div key={`stats-row-${e.label}`}>
            <StatsLabel
              key={`stats-row-${e.label}`}
              onClick={() => {
                setFilter(e.label || undefined);
              }}
            >
              <FilterOutlined style={{ fontSize: 12, color: LIGHT_GRAY1 }} />
              <div style={{ flex: 1, paddingLeft: 8, fontWeight }}>{e.label}</div>
              <div style={{ color: SEQUOIA_BLACK, marginRight: 4 }}>{e.percentage || ''}</div>
              <div style={{ width: 60, textAlign: 'right' }}>{e.rawVal}</div>
            </StatsLabel>
            {idx === 3 && <HorizontalDivider style={{ marginTop: 5, marginBottom: 5 }} />}
          </div>
        );
      })}
    </div>
  );
};

const CampaignDetailsHeader: React.FunctionComponent<{
  selectedCampaign: CampaignAPI.CampaignWithContents;
  onCampaignSelected: (campaign: CampaignAPI.Campaign) => void;
  onCohortChange: (includedReferrals: CampaignAPI.CampaignMember[], label?: string) => void;
  search: {
    value: string;
    onChange: (val: string) => void;
  };
}> = ({ selectedCampaign, onCampaignSelected, onCohortChange, search }) => {
  const campaigns = useVisibleCampaigns({ includeArchived: false });

  return (
    <CampaignHeaderRow
      manageConfig={{
        organizationId: selectedCampaign.organizationId,
        templateId: selectedCampaign.id,
      }}
      search={{ ...search, placeholder: 'Search for a candidate by name, title, or company' }}
      extra={
        <>
          <CohortSelector
            allCandidates={selectedCampaign.candidates}
            onCohortSelected={onCohortChange}
          />
          <Select
            optionsPlacement="bottomRight"
            selected={selectedCampaign?.id}
            options={campaigns}
            onSelect={campaignId => {
              onCampaignSelected(campaigns.find(c => c.id === campaignId) as CampaignAPI.Campaign);
            }}
          />
        </>
      }
    />
  );
};

const StatsLabel = styled.div`
  display: flex;
  flex: 1;
  cursor: pointer;
  padding: 12px;
  align-items: center;
  &:hover {
    background: ${SEQUOIA_LIGHT_GRAY};
    -webkit-transition: background 0.3s;
    transition: background 0.4s;
  }
`;
