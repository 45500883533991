import moment from 'moment';
import { CSSProperties, default as React } from 'react';
import { CondensingTagsContainer } from '../components/profile-drawer/useTagsRow';
import { InnerTag, SeqTag } from '../components/SeqTag';
import { Colors, hexWithOpacity } from '../shared/Theme';
import { INSIGHT_FILTERS } from './NetworkFilterDrawer';

const { WARNING_TINT, SEQUOIA_GREEN } = Colors.Static;
const EMPHASIZED_INSIGHTS = ['vouched', 'exploring-opps', 'upcoming-anniversary'];
const SEQUOIA_INSIGHT = 'sequoia-contact';

export const InsightTags: React.FC<{
  onSearch?: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    callback: (search: NetworkAPI.SearchHistoryData) => NetworkAPI.SearchHistoryData
  ) => void;
  insights?: NetworkAPI.Insights;
  style?: CSSProperties;
}> = ({ onSearch, insights, style }) => {
  const filters = Object.entries(insights?.filters || {}).sort(
    (a, b) => new Date(a[1]).valueOf() - new Date(b[1]).valueOf()
  );

  return (
    <CondensingTagsContainer
      style={style}
      condensed
      tags={filters.map(f => {
        const insightFilter = INSIGHT_FILTERS.find(fi => fi.id === f[0]);
        if (!insightFilter) {
          // maybe this insight filter existed for a while and was saved to
          // profile.networkUpdates, but is no longer being offered. Just hide it
          return <span />;
        }
        const img =
          insightFilter.type === 'profile'
            ? { src: '/icons/person-filled.svg', alt: 'person' }
            : { src: '/icons/briefcase-filled.svg', alt: 'briefcase' };
        const recentlyAdded = moment(f[1]).isAfter(moment().subtract(1, 'w'));

        let tagColor: string | undefined;
        if (EMPHASIZED_INSIGHTS.includes(insightFilter.id))
          tagColor = hexWithOpacity(WARNING_TINT, 0.2);
        if (insightFilter.id === SEQUOIA_INSIGHT) tagColor = hexWithOpacity(SEQUOIA_GREEN, 0.1);

        return (
          <SeqTag
            key={insightFilter.title}
            tagName={
              <>
                <img
                  src={img.src}
                  alt={img.alt}
                  style={{ height: 10, width: 10, opacity: 0.4, marginRight: 4 }}
                />
                {insightFilter?.title}
                {recentlyAdded && <InnerTag>NEW</InnerTag>}
              </>
            }
            tagColor={tagColor}
            onClick={
              onSearch
                ? e => {
                    onSearch(e, (s: NetworkAPI.SearchHistoryData) =>
                      Object.assign(s, {
                        insights: Array.from(new Set([...(s.insights || []), insightFilter.id])),
                      })
                    );
                  }
                : undefined
            }
          />
        );
      })}
    />
  );
};
