import { Button, notification } from 'antd';
import Quill from 'quill';
import React, { useContext, useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import { RouteComponentProps } from 'react-router';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';
import { organizationIdFromRoute } from '../Helpers';
import { SeqButton } from '../components/Button';
import { FooterRowContainer, HeaderInput, PageContainer } from '../components/Common';
import { MissingPermissionHeader } from '../components/MissingPermissionsHeader';
import { OrganizationContext } from '../contexts/Organization';
import { usePageTitle } from '../shared/Common';
import { makeRequest, useResource } from '../shared/Resource';
import { Colors } from '../shared/Theme';
import { EXTERNAL_WEB_APP, isValidEmail } from '../shared/helpers';

const FULL_STAR =
  '<img src="https://f376-test-bucket.s3.amazonaws.com/seq-email-assets/full-star-16.png" alt="A star" />';
const NETWORK_MINER_EMAIL_DEFAULT: NetworkMinerAPI.NetworkMinerEmailTemplate = {
  body: `Hey there! 
  \nThank you for the time to help us build this company! We need great people to be a great company and appreciate your offer to share your network with us!  Instead of asking you to recall all the names of all great people you know, I took the liberty of digging into your LinkedIn connections to make a list that you can just scan through. Hopefully, making the walk through your memory bank will take <15 mins.
  \nIn general, I'd love to know who you think are stars regardless of whether they are looking for a role or not. Your high opinion is enough for me to want to know they exist and to get to know them.
  \nTo help think through the set...there are two ways I think about stars...
  \n${FULL_STAR}${FULL_STAR}\tThe true 1% (at whatever they do). Exceptional people who would succeed in nearly any environment.
  \n${FULL_STAR}\t\tPeople who can be exceptional in the right situation or who happen to be just a bit early in their career to know for sure.
  \nThank you for helping us build a great company!  Happy to hop on the phone to discuss live as well!`,
  subject: 'People Recommendations',
};

const { SEQUOIA_LIGHT_GRAY, SEQUOIA_DARK_GRAY } = Colors.Static;

/**
 * Register the quill library to use <div> as default container for text instead of <p>, which
 * has formatting side-effects in email clients, see : https://github.com/quilljs/quill/issues/1074
 */
const Block = Quill.import('blots/block');
Block.tagName = 'DIV';
Quill.register(Block, true);

const buildDefaultState = (sheetHash: string, sheetName: string, organizationId: number) => {
  return {
    emailAddress: '',
    subject: NETWORK_MINER_EMAIL_DEFAULT.subject,
    body: NETWORK_MINER_EMAIL_DEFAULT.body,
    linkToReview: `${EXTERNAL_WEB_APP}/${organizationId}/singleuse/sheet/${sheetHash}/review/${sheetName}`,
  };
};

export const PageReferralsEmail = (
  props: RouteComponentProps<{ sheetHash: string; sheetName: string; organizationId: string }>
) => {
  const organizationId = organizationIdFromRoute(props);
  const { me, team } = useContext(OrganizationContext);
  const { sheetHash, sheetName } = props.match.params;
  const history = useHistory();
  usePageTitle(`Referral Email to ${sheetName}`, false);

  const [sending, setSending] = React.useState(false);
  const [editingHeader, setEditingHeader] = useState<'subject' | 'email' | undefined>(undefined);
  const [sheet] = useResource<NetworkMinerAPI.MinerSheetWithProfiles>(
    `/api/${organizationId}/miner-sheets/${sheetHash || 'missing'}`
  );
  const [sendPayload, setSendPayload] = useState<NetworkMinerAPI.SendRequest | undefined>();

  useEffect(() => {
    if (!sheet) {
      return;
    }
    const defaultState = buildDefaultState(sheetHash, sheetName, organizationId);
    setSendPayload({
      emailInfo: {
        address: sheet.target.email || '',
        body: sheet.emailInfo?.body || defaultState.body,
        subject: sheet.emailInfo?.subject || defaultState.subject,
        linkToReview: sheet.emailInfo?.linkToReview || defaultState.linkToReview,
      },
    });
  }, [sheet, sheetHash, sheetName, organizationId]);

  const emailInputRef = React.createRef<HTMLInputElement>();
  const subjectEmailRef = React.createRef<HTMLInputElement>();
  useEffect(() => {
    if (editingHeader === 'subject') {
      subjectEmailRef.current?.focus();
    } else if (editingHeader === 'email') {
      emailInputRef.current?.focus();
    } else {
      subjectEmailRef.current?.blur();
      emailInputRef.current?.blur();
    }
  }, [subjectEmailRef, emailInputRef, editingHeader]);

  if (!sendPayload) {
    return <div></div>;
  }

  const onClickSend = async () => {
    if (!sheet) {
      return;
    }
    setSending(true);
    const sendResp = await makeRequest<{ success?: boolean }>(
      `/api/${organizationId}/miner-sheets/${sheet.hash}/send`,
      'PUT',
      sendPayload
    );
    setSending(false);
    if (sendResp.success) {
      history.push(`/${organizationId}/referrals/home`);
    } else {
      notification.error({ message: 'Email failed to send, please try again.' });
    }
  };

  const onClickPreview = async () => {
    if (!sheet) {
      return;
    }
    history.push(
      `/${organizationId}/referrals/sheet/${sheetHash}/review/${sheetName}?preview=true`
    );
  };

  const isValid =
    isValidEmail(sendPayload?.emailInfo?.address) &&
    !!sendPayload?.emailInfo?.subject &&
    !!sendPayload?.emailInfo?.body;

  const hasPermissionToSend =
    me.id === sheet?.senderId || team.find(tm => tm.id === sheet?.senderId)?.allowOthersToSend;

  const hasWarning = !!me.warning;

  return (
    <PageContainer style={{ paddingRight: 24 }}>
      {me.warning && <MissingPermissionHeader warning={me.warning} />}
      <div
        style={{
          background: 'white',
          width: '100%',
          height: 'calc(100vh - 24px)',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div style={{ display: 'flex' }}>
          <StyledRow>
            <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
              To:{` ${sheet?.target.name} `}
              <HeaderInput
                ref={emailInputRef}
                onClick={() => {
                  if (editingHeader !== 'email') {
                    setEditingHeader('email');
                  }
                }}
                style={{ flex: 1, marginLeft: 10 }}
                readOnly={editingHeader !== 'email'}
                placeholder={'Email Address'}
                value={sendPayload.emailInfo.address || ''}
                onChange={e =>
                  setSendPayload({
                    emailInfo: { ...sendPayload.emailInfo, address: e.currentTarget.value.trim() },
                  })
                }
                onKeyDown={e => {
                  if (e.key === 'Tab') {
                    setEditingHeader('subject');
                  } else if (e.key === 'Enter') {
                    setEditingHeader(undefined);
                  }
                }}
              />
            </div>
            <Button
              style={{
                fontWeight: 500,
                color: editingHeader === 'email' ? undefined : SEQUOIA_DARK_GRAY,
              }}
              type="link"
              onClick={() => {
                if (editingHeader === 'email') {
                  setEditingHeader(undefined);
                } else {
                  setEditingHeader('email');
                }
              }}
            >
              {editingHeader === 'email' ? 'Done' : 'Edit'}
            </Button>
          </StyledRow>
          <StyledRow>
            <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
              Subject:{' '}
              <HeaderInput
                ref={subjectEmailRef}
                onClick={() => {
                  if (editingHeader !== 'subject') {
                    setEditingHeader('subject');
                  }
                }}
                style={{ marginLeft: 10 }}
                onFocus={e => e.currentTarget.select()}
                readOnly={editingHeader !== 'subject'}
                placeholder={'Subject'}
                value={sendPayload.emailInfo.subject || ''}
                onChange={e =>
                  setSendPayload({
                    ...sendPayload,
                    emailInfo: { ...sendPayload.emailInfo, subject: e.target.value },
                  })
                }
                onKeyDown={e => {
                  if (e.shiftKey && e.key === 'Tab') {
                    setEditingHeader('email');
                  } else if (e.key === 'Enter' || e.key === 'Tab') {
                    setEditingHeader(undefined);
                  }
                }}
              />
            </div>
            <Button
              style={{
                fontWeight: 500,
                color: editingHeader === 'subject' ? undefined : SEQUOIA_DARK_GRAY,
              }}
              type="link"
              onClick={() => {
                if (editingHeader === 'subject') {
                  setEditingHeader(undefined);
                } else {
                  setEditingHeader('subject');
                  subjectEmailRef.current?.focus();
                }
              }}
            >
              {editingHeader === 'subject' ? 'Done' : 'Edit'}
            </Button>
          </StyledRow>
        </div>
        <EmbedContainer style={{ flex: 1 }}>
          <div style={{ display: 'flex', height: '100%', alignItems: 'center' }}>
            <div style={{ flex: 1 }} />
            <div style={{ flex: 3 }}>
              <CustomQuill
                style={{ margin: 'auto', border: 'none' }}
                formats={['bold', 'italic', 'underline', 'link', 'image']}
                preserveWhitespace={true}
                theme="snow"
                tabIndex={-1}
                onChange={e => {
                  setSendPayload({ emailInfo: { ...sendPayload.emailInfo, body: e } });
                }}
                value={sendPayload.emailInfo.body || ''}
              />
            </div>
            <div style={{ flex: 1 }} />
          </div>
        </EmbedContainer>
        {!hasWarning && (
          <FooterRowContainer
            style={{
              position: 'static',
              right: 'unset',
              bottom: 'unset',
              left: 'unset',
              width: '100%',
            }}
          >
            <div style={{ flex: 1 }} />
            {!hasPermissionToSend ? (
              <div style={{ maxWidth: 500, textAlign: 'right' }}>
                {sheet?.sender.name} needs to send this sheet or update their settings to allow you
                to send emails on their behalf.
              </div>
            ) : !isValid || hasWarning ? (
              <div>Please fix the validation errors above.</div>
            ) : undefined}
            <SeqButton onClick={onClickPreview}>PREVIEW</SeqButton>
            <SeqButton
              loading={sending}
              onClick={onClickSend}
              disabled={!isValid || !hasPermissionToSend || hasWarning}
              intent="primary"
              style={{ marginLeft: 10, width: 144 }}
            >
              send
            </SeqButton>
          </FooterRowContainer>
        )}
      </div>
    </PageContainer>
  );
};

const StyledRow = styled.div`
  height: 56px;
  align-items: center;
  display: flex;
  margin-bottom: 24;
  display: flex;
  height: 65px;
  flex: 1;
  border: 1px solid ${SEQUOIA_LIGHT_GRAY};
  padding: 24px;
`;

const EmbedContainer = styled.div`
  padding: 10px;
  font-size: 16px;
  font-weight: 400;
`;

const CustomQuill = styled(ReactQuill)`
  & > .ql-container {
    border: none;
    min-height: 300px;
    font-size: 16px;
  }
  & > .ql-toolbar {
    border: none;
    border-bottom: 1px solid ${SEQUOIA_LIGHT_GRAY};
  }
  & > .ql-container > .ql-editor {
    border: none;
  }
`;
