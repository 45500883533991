import { DownOutlined, FunctionOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu } from 'antd';
import Quill from 'quill';
import React, { CSSProperties } from 'react';
import styled from 'styled-components/macro';
import { RECIPIENT_PLACEHOLDER, RECIPIENT_VARIABLES } from '../shared/helpers';
import {
  bestRole,
  bestRoleTitleAndEmployer,
  getNameParts,
  MinimalExtProfile,
} from '../shared/profile-helpers';
import { Colors } from '../shared/Theme';

/**
 * Register the quill library to use <div> as default container for text instead of <p>, which
 * has formatting side-effects in email clients, see : https://github.com/quilljs/quill/issues/1074
 */
const Block = Quill.import('blots/block');
Block.tagName = 'DIV';
Quill.register(Block, true);

export interface CursorBodyState {
  text: string;
  insertFunction?: (toInsert: string) => void;
}

export const TemplateContext = React.createContext<{
  quillScrollingContainer?: string;
  variables: { [key: string]: string };
  recipient: { name: string; email: string };
  senderOverride?: { name: string; email: string };
}>({ variables: RECIPIENT_VARIABLES, recipient: RECIPIENT_PLACEHOLDER });

const insertBodyState = (value: CursorBodyState, variable: string) => {
  value.insertFunction && value.insertFunction(`{{${variable}}}`);
};

export const InsertVariableButton: React.FunctionComponent<{
  value: CursorBodyState;
  compactMode?: boolean;
  style?: CSSProperties;
}> = ({ compactMode, value, style, children }) => {
  const { variables } = React.useContext(TemplateContext);

  const templateMenu = (
    <Menu
      onClick={e => {
        const variable = e.key as string;
        insertBodyState(value, variable);
      }}
    >
      <Menu.Item disabled={true} key={-1}>
        Insert Variable
      </Menu.Item>
      {Object.keys(variables)
        .sort((v1, v2) => v1.toLowerCase().localeCompare(v2.toLowerCase()))
        .map(v => (
          <Menu.Item key={v}>{`{{${v}}}`}</Menu.Item>
        ))}
    </Menu>
  );

  return (
    <Dropdown overlay={templateMenu}>
      <CardActionButton style={style} type={'link'}>
        {compactMode ? (
          <FunctionOutlined />
        ) : (
          children || (
            <span style={{ color: Colors.Static.GRAY3 }}>
              Variables <DownOutlined style={{ fontSize: 10 }} />
            </span>
          )
        )}
      </CardActionButton>
    </Dropdown>
  );
};

export const buildVariablesMap = (
  sender: { name: string },
  base: { [key: string]: string },
  recipient?: MinimalExtProfile,
  additionalVariables?: { [key: string]: string }
): { [key: string]: string } => {
  return Object.assign(
    {},
    base,
    recipient
      ? {
          first_name: getNameParts(recipient.name).first,
          last_name: getNameParts(recipient.name).rest,
          company:
            ('bestRole' in recipient ? recipient.bestRole : bestRole(recipient))?.company.name ||
            'work',
          signature: sender.name || '',
          job_title: bestRoleTitleAndEmployer(recipient) || '',
        }
      : {},
    additionalVariables
  );
};

export const CardActionButton = styled(Button)`
  padding-left: 5px;
  padding-right: 5px;
  color: ${Colors.Static.LIGHT_GRAY1};
`;
