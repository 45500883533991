import moment from 'moment';
import React, { useContext, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import styled from 'styled-components/macro';
import { getHireLock, lastStaleUpdateFor, onStatusUpdate } from '../Helpers';
import {
  FlexFillingColCSS,
  FlexFillingRowCSS,
  HorizontalDivider,
  LightTooltip,
} from '../components/Common';
import { LockPopover } from '../components/Popover';
import { Profile } from '../components/Profile';
import { SeqCheckbox } from '../components/SeqCheckbox';
import { Flag } from '../components/Svgs';
import { Label2 } from '../components/Text';
import { OrganizationContext } from '../contexts/Organization';
import { ARCHIVED_STATUS_COLUMNS } from '../settings/StatusesTab';
import { ClickableItem } from '../shared/Common';
import { ScrollingContainer } from '../shared/ScrollableContainer';
import { Colors } from '../shared/Theme';
import { StaleBatchMessage } from './StaleBatchMessage';

const { SEQUOIA_BRIGHT_BLUE, SEQUOIA_DARK_BLUE, BLACK1ALPHA, SEQUOIA_BLACK } = Colors.Static;
const PROFILE_ROW_HEIGHT = 72;
const COL_WIDTH = 296;

interface RowData {
  profiles: NetworkAPI.NetworkProfile[];
  checkedIds: number[];
  onCheckOne: (id: number) => void;
  setDrawerCandidateId: (id: number, ids?: number[] | undefined) => void;
}

interface PipelineCRMViewProps {
  isFiltering: boolean;
  profilesFiltered: NetworkAPI.NetworkProfile[];
  setDrawerCandidateId: (id: number, ids?: number[] | undefined) => void;
  checkedIds: number[];
  actions: {
    onCheckOne: (id: number) => void;
    onCheckSome: (ids: number[], uncheck?: boolean) => void;
    onProfileSaved: (profile: ExternalAPI.Profile) => void;
    onMultiProfileUpdate: (profiles: NetworkAPI.NetworkProfile[]) => void;
  };
}

export const PipelineCRMView: React.FunctionComponent<PipelineCRMViewProps> = props => {
  const { statuses } = useContext(OrganizationContext);
  const storedCollapsed = (JSON.parse(window.localStorage.getItem('collapsedCols') as string) ||
    []) as PipelineAPI.ProfileStatus[];
  const [checkedCols, setCheckedCols] = useState<PipelineAPI.ProfileStatus[]>([]);
  const [collapsedCols, setCollapsedCols] = useState<PipelineAPI.ProfileStatus[]>(
    Array.from(new Set([...storedCollapsed, ...ARCHIVED_STATUS_COLUMNS]))
  );

  return (
    <Container $axis="x">
      <div style={{ display: 'flex', fontSize: 12, gap: 12 }}>
        {statuses.map(c => (
          <Column
            key={c}
            statusOption={c}
            checkedCols={checkedCols}
            setCheckedCols={setCheckedCols}
            collapsedCols={collapsedCols}
            setCollapsedCols={setCollapsedCols}
            {...props}
          />
        ))}
      </div>
    </Container>
  );
};

const Container = styled(ScrollingContainer)`
  ${FlexFillingRowCSS}
`;

type ColumnProps = PipelineCRMViewProps & {
  statusOption: PipelineAPI.ProfileStatus;
  checkedCols: PipelineAPI.ProfileStatus[];
  setCheckedCols: (cols: PipelineAPI.ProfileStatus[]) => void;
  collapsedCols: PipelineAPI.ProfileStatus[];
  setCollapsedCols: (cols: PipelineAPI.ProfileStatus[]) => void;
};

const Column: React.FunctionComponent<ColumnProps> = ({
  checkedCols,
  setCheckedCols,
  collapsedCols,
  setCollapsedCols,
  statusOption,
  profilesFiltered,
  isFiltering,
  checkedIds,
  actions,
  setDrawerCandidateId,
}) => {
  const { me } = useContext(OrganizationContext);

  const [{ isOver }, drop] = useDrop({
    accept: 'profile',
    drop: (item: { type: string; profile: ExternalAPI.Profile }) => {
      if (item.profile.status !== statusOption) {
        onStatusUpdate(
          item.profile,
          statusOption,
          { id: me.id, name: me.name },
          { onSaved: actions.onProfileSaved }
        );
      }
    },
    collect: monitor => ({ isOver: !!monitor.isOver() }),
  });

  const profiles = profilesFiltered
    .filter(p => p.status === statusOption)
    .sort((a, b) => a.name.localeCompare(b.name));

  const isCollapsed = isFiltering && profiles.length ? false : collapsedCols.includes(statusOption);

  const onColClicked = () => {
    const newCols = checkedCols.includes(statusOption)
      ? checkedCols.filter(c => c !== statusOption)
      : [...checkedCols, statusOption];

    setCheckedCols(newCols);
    actions.onCheckSome(
      profiles.map(p => p.id),
      checkedCols.includes(statusOption)
    );
  };

  const onCollapse = () => {
    const newCollapsed = isCollapsed
      ? collapsedCols.filter(c => c !== statusOption)
      : [...collapsedCols, statusOption];
    setCollapsedCols(newCollapsed);
    window.localStorage.setItem('collapsedCols', JSON.stringify(newCollapsed));
  };

  const itemData: RowData = {
    checkedIds,
    onCheckOne: actions.onCheckOne,
    setDrawerCandidateId,
    profiles,
  };

  return (
    <ColContainer
      ref={drop}
      isOver={isOver}
      isCollapsed={isCollapsed}
      onClick={() => {
        isCollapsed && onCollapse();
      }}
    >
      <ColHeader
        isCollapsed={isCollapsed}
        onClick={e => {
          isCollapsed ? e.stopPropagation() : onCollapse();
        }}
      >
        <SeqCheckbox checked={checkedCols.includes(statusOption)} onChange={() => onColClicked()} />
        {statusOption} ({profiles.length})
        <CollapsibleArrow
          isCollapsed={isCollapsed}
          onClick={e => {
            onCollapse();
            e.preventDefault();
          }}
        >
          <img
            style={{ height: 12 }}
            src="/icons/arrow-left.svg"
            alt={`${isCollapsed ? 'right' : 'left'} arrow`}
          />
        </CollapsibleArrow>
      </ColHeader>
      {!isCollapsed && (
        <>
          <StaleBatchMessage
            status={statusOption}
            profiles={profiles}
            onSaved={actions.onMultiProfileUpdate}
            onCheckSome={actions.onCheckSome}
          />
          <ListContainer>
            <AutoSizer>
              {size => (
                <FixedSizeList
                  overscanCount={5}
                  className="List"
                  itemKey={idx => `${profiles[idx].id}-${profiles[idx].name}`}
                  itemCount={(profiles || []).length}
                  itemSize={PROFILE_ROW_HEIGHT}
                  itemData={itemData}
                  {...size}
                >
                  {Row}
                </FixedSizeList>
              )}
            </AutoSizer>
          </ListContainer>
        </>
      )}
    </ColContainer>
  );
};

const ProfileRow: React.FunctionComponent<{
  profile: NetworkAPI.NetworkProfile;
  checkedIds: number[];
  onCheck: (id: number) => void;
  setDrawerCandidateId: (profileId: number) => void;
  myId: number;
}> = ({ profile, checkedIds, onCheck, setDrawerCandidateId, myId }) => {
  const { id, ownerId, status } = profile;
  const [{ isDragging }, drag] = useDrag({
    type: 'profile',
    item: { profile },
    collect: monitor => ({ isDragging: monitor.isDragging() }),
  });
  const hireLock = getHireLock(myId, ownerId, status);

  return (
    <ProfileContainer
      ref={hireLock ? null : drag}
      hireLock={hireLock}
      isDragging={isDragging}
      onClick={() => setDrawerCandidateId(profile.id)}
    >
      <SeqCheckbox checked={checkedIds.some(c => c === id)} onChange={() => onCheck(profile.id)} />
      <Profile
        style={{ flex: 1 }}
        profile={profile}
        extra={{
          header: (
            <>
              <StaleFlag profile={profile} style={{ paddingBottom: 4, width: 14 }} tooltip />
              {hireLock && (
                <LockPopover message="Only the owner can change the status of this candidate." />
              )}
            </>
          ),
        }}
      />
    </ProfileContainer>
  );
};

const StaleFlag: React.FunctionComponent<{
  profile: NetworkAPI.NetworkProfile;
  tooltip?: boolean;
  style?: React.CSSProperties;
}> = ({ profile, tooltip = false, style }) => {
  const lastStaleUpdate = lastStaleUpdateFor(profile);
  if (!lastStaleUpdate) return <></>;

  const numOfDaysAgo = moment().diff(lastStaleUpdate.createdAt, 'days', false);
  let color = SEQUOIA_DARK_BLUE;
  if (numOfDaysAgo >= 14) color = SEQUOIA_BRIGHT_BLUE;

  return tooltip ? (
    <LightTooltip
      overlay={`Status updated ${moment(lastStaleUpdate.createdAt).fromNow()}`}
      placement="topLeft"
    >
      <div>
        <Flag color={color} style={style} />
      </div>
    </LightTooltip>
  ) : (
    <div>
      <Flag color={color} style={style} />
    </div>
  );
};

const Row: React.FunctionComponent<ListChildComponentProps> = ({ index, data, ...rest }) => {
  const { me } = useContext(OrganizationContext);
  const { profiles, checkedIds, onCheckOne, setDrawerCandidateId }: RowData = data;
  const p: NetworkAPI.NetworkProfile = profiles[index];

  return (
    <div {...rest}>
      <ProfileRow
        onCheck={onCheckOne}
        setDrawerCandidateId={(id: number) =>
          setDrawerCandidateId(
            id,
            profiles.map(p => p.id)
          )
        }
        checkedIds={checkedIds}
        profile={p}
        myId={me.id}
      />
      <HorizontalDivider />
    </div>
  );
};

const ColContainer = styled.div<{ isOver: boolean; isCollapsed: boolean }>`
  min-width: ${COL_WIDTH}px;
  max-width: ${COL_WIDTH}px;
  background: ${({ isOver }) => (isOver ? BLACK1ALPHA : 'unset')};
  ${FlexFillingColCSS}

  ${({ isCollapsed }) =>
    isCollapsed &&
    `min-width: 46px;
    max-width: 46px; 
    border-left: 1px solid ${SEQUOIA_BLACK};
    border-right: 1px solid ${SEQUOIA_BLACK};
    background: ${BLACK1ALPHA};`}
`;

const ListContainer = styled.div`
  flex: 1;
`;

const ColHeader = styled(Label2)<{ isCollapsed: boolean }>`
  display: flex;
  padding: 12px;
  white-space: nowrap;
  border-top: 1px solid ${SEQUOIA_BLACK};
  border-bottom: 1px solid ${SEQUOIA_BLACK};
  background: ${BLACK1ALPHA};
  user-select: none;

  ${({ isCollapsed }) =>
    isCollapsed &&
    `padding: 24px 16px;
    transform: translateX(55px) rotate(90deg);
    transform-origin: top left;
    border-top: none;
    border-bottom: none;
    background: none;
    `}
`;

const CollapsibleArrow = styled(ClickableItem)<{ isCollapsed: boolean }>`
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  margin: -12px;
  padding: 12px;
  transform: ${({ isCollapsed }) => (isCollapsed ? 'translateX(24px) rotate(90deg)' : 'none')};
`;

const ProfileContainer = styled.div<{ hireLock?: boolean; isDragging: boolean }>`
  display: flex;
  align-items: center;
  padding: 8px 12px;
  cursor: ${({ hireLock }) => (hireLock ? 'default' : 'move')};
  opacity: ${({ isDragging }) => (isDragging ? 0.5 : 1)};
  height: ${PROFILE_ROW_HEIGHT};
  &:hover {
    background: ${BLACK1ALPHA};
  }
`;
